export const amazon = {
  type: 'FeatureCollection' as const,
  name: 'amazonia',
  features: [
    {
      type: 'Feature' as const,
      properties: {
        ID: 0,
        AREA: 548.00977,
        PERIMETER: 241.442,
        FORMA: null,
        POLYAREA: 6725344.2,
        AREAPROJ: 6725344251419.7,
      },
      geometry: {
        type: 'MultiPolygon' as const,
        coordinates: [
          [
            [
              [-77.69491340232193, 0.561293454712047],
              [-77.69448053701007, 0.561169465856494],
              [-77.69265764070578, 0.560924546645481],
              [-77.69093365085904, 0.561826439427989],
              [-77.68978164208553, 0.563659394016099],
              [-77.68955800670041, 0.565538114809665],
              [-77.69491340232193, 0.561293454712047],
            ],
          ],
          [
            [
              [-77.88693126160418, 0.208998248854796],
              [-77.88663449401959, 0.20883800956733],
              [-77.88649860320625, 0.208884880241834],
              [-77.88693126160418, 0.208998248854796],
            ],
          ],
          [
            [
              [-49.07966739184303, -8.007107370918838],
              [-49.07678250560082, -8.012179403582763],
              [-49.080154545251446, -8.053490619249771],
              [-49.080107729994644, -8.05351372584124],
              [-49.08015441894531, -8.054085731506348],
              [-49.04887008666992, -8.069527626037598],
              [-49.04886831771921, -8.06952792102756],
              [-49.04314041137695, -8.072355270385742],
              [-48.98415756225586, -8.091711044311523],
              [-48.96916961669922, -8.0859375],
              [-48.9576416015625, -8.074430465698242],
              [-48.95420837402344, -8.059511184692383],
              [-48.96236801147461, -8.033140182495117],
              [-48.967041015625, -8.00677490234375],
              [-48.967374488141104, -7.996378922071318],
              [-48.96735759773384, -7.996325704263882],
              [-48.96775288105619, -7.984001469124057],
              [-48.9682925152149, -7.967176630115773],
              [-48.96023549459903, -7.947666074076096],
              [-48.94524362256547, -7.938455871220299],
              [-48.932548559642186, -7.93727972256562],
              [-48.91866259591464, -7.944127234552127],
              [-48.90825646311262, -7.949830784392191],
              [-48.899009547520876, -7.953249762700636],
              [-48.890930566318644, -7.954363717633726],
              [-48.8816905238875, -7.953207853184722],
              [-48.87476355145881, -7.946297309286308],
              [-48.88174450334401, -7.931406355367074],
              [-48.881782557184465, -7.914206354758903],
              [-48.872581574422156, -7.89926645052509],
              [-48.84370457685134, -7.900340675237032],
              [-48.814796566238755, -7.908291078044442],
              [-48.79745457619069, -7.918575170336055],
              [-48.78123458770273, -7.934581923207077],
              [-48.76425955737599, -7.952341834947845],
              [-48.74702452041254, -7.920178125500854],
              [-48.717109507951534, -7.883397663780499],
              [-48.69754748702633, -7.853526740130235],
              [-48.68836947067874, -7.831706466849369],
              [-48.68380753605223, -7.815642717036781],
              [-48.68038956357216, -7.794990042869358],
              [-48.68040448335984, -7.770676823943802],
              [-48.677047531134946, -7.753700117236406],
              [-48.67359955144147, -7.742230823834632],
              [-48.66555761825134, -7.727292093067263],
              [-48.64711759888627, -7.718071496651532],
              [-48.61064156788953, -7.700470002880877],
              [-48.57680998500207, -7.687389791559074],
              [-48.56521359145394, -7.670157162040613],
              [-48.55841123769665, -7.646834806301345],
              [-48.55283978604783, -7.615867900625315],
              [-48.54830488354297, -7.583087033947343],
              [-48.54940621700843, -7.566955737894348],
              [-48.55867037498264, -7.557821148563134],
              [-48.56586143466892, -7.546030402050503],
              [-48.57376512189167, -7.524651575956173],
              [-48.570493513656025, -7.492777689779171],
              [-48.56871194481482, -7.479302550907593],
              [-48.56571566994555, -7.459008862198353],
              [-48.56305649768984, -7.446901338333475],
              [-48.5555645849866, -7.415149515608903],
              [-48.53602553047611, -7.387584621162432],
              [-48.50839961577444, -7.364588199226944],
              [-48.50611454132863, -7.362304298247523],
              [-48.50609567999917, -7.36227302767804],
              [-48.50483442723828, -7.361565609155324],
              [-48.48883819580078, -7.345598220825195],
              [-48.479652404785156, -7.330666542053223],
              [-48.48085403442383, -7.311185836791992],
              [-48.502830505371094, -7.276844024658203],
              [-48.52021026611328, -7.243648529052734],
              [-48.527191162109375, -7.208123207092285],
              [-48.5261116027832, -7.17144250869751],
              [-48.52042238835475, -7.156480786635603],
              [-48.52011258240923, -7.155140401728458],
              [-48.51694446315557, -7.146815122775877],
              [-48.51232955490093, -7.145660934707507],
              [-48.49964957940338, -7.149067675437323],
              [-48.48724250288604, -7.154637292491413],
              [-48.48601022855695, -7.155641751398321],
              [-48.48528055641326, -7.156396158190955],
              [-48.48002624511719, -7.159860610961914],
              [-48.47079849243164, -7.160985946655273],
              [-48.46504211425781, -7.155241012573242],
              [-48.46355437218209, -7.152016884166895],
              [-48.463300029104566, -7.150935926087445],
              [-48.45816049975082, -7.139797458154703],
              [-48.45127862196125, -7.123731529047234],
              [-48.431674523882066, -7.119093822015941],
              [-48.43166829837203, -7.119081154813834],
              [-48.429483523791035, -7.118888745688803],
              [-48.417877197265625, -7.115005016326904],
              [-48.41443634033203, -7.109265327453613],
              [-48.41099548339844, -7.094360828399658],
              [-48.417938232421875, -7.081772804260254],
              [-48.42948532104492, -7.072632789611816],
              [-48.45716857910156, -7.064664840698242],
              [-48.48598861694336, -7.061301708221436],
              [-48.505592346191406, -7.057900428771973],
              [-48.52751159667969, -7.046483039855957],
              [-48.54000404907058, -7.032462708852333],
              [-48.54051554837332, -7.031360441396885],
              [-48.54483759293072, -7.026507822367989],
              [-48.54948452005553, -7.004743372562359],
              [-48.54491453880195, -6.981798918426591],
              [-48.53458451368084, -6.951973089415446],
              [-48.520797624048924, -6.920992234145672],
              [-48.51046759892782, -6.899203812096914],
              [-48.50588253024853, -6.885442906364858],
              [-48.489784582270886, -6.865920280384614],
              [-48.475997525000906, -6.841819291247646],
              [-48.467971517426804, -6.820029695732444],
              [-48.465705553720255, -6.787931029854178],
              [-48.47037561489782, -6.747819096894602],
              [-48.472717518647215, -6.71802528675363],
              [-48.46814753739366, -6.697381497403569],
              [-48.46126549196603, -6.671002474619456],
              [-48.45899148163241, -6.645788536377779],
              [-48.45556646835365, -6.627438873929009],
              [-48.45442150037883, -6.618268401294301],
              [-48.45180550839535, -6.551227257425637],
              [-48.43462361269806, -6.475566834875622],
              [-48.421981523402906, -6.453769528009502],
              [-48.43577562147158, -6.436503645642347],
              [-48.43469251194222, -6.409084596311232],
              [-48.428985609340884, -6.357501358293121],
              [-48.4057385685002, -6.351965949430905],
              [-48.39495055618917, -6.340607800065357],
              [-48.38302662835875, -6.315040480785513],
              [-48.39306261631617, -6.288957506821816],
              [-48.41086561103933, -6.273416452491688],
              [-48.42598904426275, -6.256206046840581],
              [-48.4278155321578, -6.177059701587348],
              [-48.40237045288086, -6.134884834289551],
              [-48.36830520629883, -6.115564346313477],
              [-48.338199615478516, -6.118144035339355],
              [-48.29340744018555, -6.109979629516602],
              [-48.272281646728516, -6.083003997802734],
              [-48.27235053762369, -6.082641906173819],
              [-48.272281570740404, -6.082552638020104],
              [-48.28029249088874, -6.039138570482464],
              [-48.29345660511416, -6.004404131329181],
              [-48.290000578793666, -5.982638675695171],
              [-48.23481748099641, -5.960822425727883],
              [-48.20951855025649, -5.937897082331347],
              [-48.19917259951933, -5.921844228992882],
              [-48.194572611052365, -5.906950257588505],
              [-48.19688450758838, -5.897787999218849],
              [-48.215286473113, -5.880605432969332],
              [-48.23140755514342, -5.855413287675958],
              [-48.2475125439197, -5.823348484686505],
              [-48.25327258778722, -5.80271391542999],
              [-48.262481617176604, -5.762611873116157],
              [-48.25788162870964, -5.74313434177509],
              [-48.247535510334416, -5.732824097945468],
              [-48.230285553583286, -5.725945572917169],
              [-48.16821253136942, -5.709882828932905],
              [-48.15786758646067, -5.691551103756979],
              [-48.14981861247185, -5.682375937256438],
              [-48.137184569803736, -5.657170716194116],
              [-48.13027955796139, -5.636544361202709],
              [-48.114189488972755, -5.617071523727418],
              [-48.10729252375745, -5.601021855512101],
              [-48.10614755578263, -5.580405223528373],
              [-48.10960358210315, -5.560937918109175],
              [-48.13028760458843, -5.547196793668604],
              [-48.14868957011308, -5.539181347292526],
              [-48.163441664519375, -5.533516124522587],
              [-48.14868927001953, -5.507513523101807],
              [-48.152130126953125, -5.489174842834473],
              [-48.152122497558594, -5.465120315551758],
              [-48.14177703857422, -5.441054344177246],
              [-48.12223815917969, -5.410129547119141],
              [-48.10729217529297, -5.395232200622559],
              [-48.083160400390625, -5.383779525756836],
              [-48.069366455078125, -5.383781433105469],
              [-48.055580139160156, -5.387219429016113],
              [-48.03605651855469, -5.402105331420898],
              [-48.02226257324219, -5.418140411376953],
              [-48.00962829589844, -5.430734634399414],
              [-47.991241455078125, -5.436458110809326],
              [-47.98088836669922, -5.439889907836914],
              [-47.97053527832031, -5.447917938232422],
              [-47.965946197509766, -5.461657524108887],
              [-47.96363830566406, -5.473105430603027],
              [-47.961334228515625, -5.476539611816406],
              [-47.95674133300781, -5.481120586395264],
              [-47.942955017089844, -5.491418838500977],
              [-47.93605422973633, -5.495999336242676],
              [-47.898128509521484, -5.50628662109375],
              [-47.85673522949219, -5.526877403259277],
              [-47.83258819580078, -5.561217308044434],
              [-47.806114196777344, -5.61043643951416],
              [-47.78886413574219, -5.639049053192139],
              [-47.77501486912311, -5.643421173778722],
              [-47.76355743408203, -5.647039413452148],
              [-47.76355569090118, -5.647038759488311],
              [-47.763553619384766, -5.647039413452148],
              [-47.75665497831724, -5.644449919397356],
              [-47.754356384277344, -5.643587112426758],
              [-47.74402618408203, -5.640140056610107],
              [-47.74000549316406, -5.632949829101562],
              [-47.736549377441406, -5.626720905303955],
              [-47.73486328125, -5.612651824951172],
              [-47.731666564941406, -5.59440803527832],
              [-47.741783142089844, -5.576032638549805],
              [-47.746376037597656, -5.562289237976074],
              [-47.756988525390625, -5.550083637237549],
              [-47.759544372558594, -5.547121047973633],
              [-47.76824951171875, -5.537124633789062],
              [-47.771690368652344, -5.519957542419434],
              [-47.76800537109375, -5.515889644622803],
              [-47.76081848144531, -5.507976531982422],
              [-47.755653381347656, -5.506405830383301],
              [-47.749977111816406, -5.506585121154785],
              [-47.74756622314453, -5.507235527038574],
              [-47.743282318115234, -5.50917911529541],
              [-47.738304138183594, -5.513235569000244],
              [-47.72364807128906, -5.523148536682129],
              [-47.72029113769531, -5.526010513305664],
              [-47.715370178222656, -5.530216217041016],
              [-47.70826721191406, -5.527302742004395],
              [-47.7059326171875, -5.527554512023926],
              [-47.701324462890625, -5.524848937988281],
              [-47.696983337402344, -5.519904136657715],
              [-47.696998596191406, -5.510735511779785],
              [-47.70734405517578, -5.498156547546387],
              [-47.72344207763672, -5.482142925262451],
              [-47.745277404785156, -5.463841438293457],
              [-47.75390625, -5.45404052734375],
              [-47.76139831542969, -5.445523738861084],
              [-47.76139831542969, -5.432937622070312],
              [-47.75775146484375, -5.428021907806396],
              [-47.75623321533203, -5.425493240356445],
              [-47.75450134277344, -5.422627449035645],
              [-47.73832702636719, -5.423515319824219],
              [-47.73456954956055, -5.425333499908447],
              [-47.71888732910156, -5.43293571472168],
              [-47.71669006347656, -5.434238910675049],
              [-47.7073860168457, -5.439774513244629],
              [-47.696754455566406, -5.438414096832275],
              [-47.69331359863281, -5.439144134521484],
              [-47.69086456298828, -5.437528610229492],
              [-47.682098388671875, -5.431747436523438],
              [-47.68234019689796, -5.431546904881301],
              [-47.682338911394766, -5.43154679406206],
              [-47.628797195992156, -5.426931128941145],
              [-47.61267852783203, -5.400677680969238],
              [-47.61078643798828, -5.397605419158936],
              [-47.624576568603516, -5.389603614807129],
              [-47.647560119628906, -5.378177642822266],
              [-47.68779754638672, -5.358732223510742],
              [-47.749855041503906, -5.327842712402344],
              [-47.798118591308594, -5.326704025268555],
              [-47.81738097101401, -5.322442880753614],
              [-47.83057052804699, -5.304529632401398],
              [-47.83464877805424, -5.300847578574353],
              [-47.83833312988281, -5.293500900268555],
              [-47.85097122192383, -5.268304824829102],
              [-47.86705017089844, -5.246551513671875],
              [-47.90035629272461, -5.235091209411621],
              [-47.91881518037815, -5.234007698115938],
              [-47.939430236816406, -5.232797622680664],
              [-47.946319580078125, -5.230507850646973],
              [-47.946319580078125, -5.214465141296387],
              [-47.945152282714844, -5.196131706237793],
              [-47.942567442027084, -5.178129933149648],
              [-47.94272257193367, -5.177057816428601],
              [-47.94230283044752, -5.176287081621344],
              [-47.94169998168945, -5.172088623046875],
              [-47.92675018310547, -5.140019416809082],
              [-47.86698913574219, -5.070167541503906],
              [-47.83773164152388, -5.060455921680862],
              [-47.83251953125, -5.058725833892822],
              [-47.81300354003906, -5.059876441955566],
              [-47.81414031982422, -5.046141624450684],
              [-47.81296920776367, -5.019801616668701],
              [-47.80489589251358, -4.999712428001158],
              [-47.80376434326172, -4.996896743774414],
              [-47.79113006591797, -4.987744331359863],
              [-47.78080749511719, -4.992328643798828],
              [-47.765869140625, -5.009502410888672],
              [-47.74406814575195, -5.030120372772217],
              [-47.73487854003906, -5.040436744689941],
              [-47.73344800567287, -5.042578088202769],
              [-47.721107482910156, -5.061050415039062],
              [-47.70706191641887, -5.063853074716755],
              [-47.703880310058594, -5.064487934112549],
              [-47.68894958496094, -5.050742149353027],
              [-47.6671142578125, -5.072502136230469],
              [-47.63381576538086, -5.082812309265137],
              [-47.59821319580078, -5.098830699920654],
              [-47.576385498046875, -5.124006748199463],
              [-47.53388214111328, -5.158332824707031],
              [-47.48676300048828, -5.177774429321289],
              [-47.46009972094968, -5.192836227383516],
              [-47.45911760366394, -5.193272775774631],
              [-47.45755985393178, -5.194270970775571],
              [-47.444252014160156, -5.201788425445557],
              [-47.40863037109375, -5.22007942199707],
              [-47.3822021484375, -5.240660190582275],
              [-47.35346221923828, -5.264667510986328],
              [-47.33821953829182, -5.279823226429465],
              [-47.332996615208174, -5.283515027654516],
              [-47.30338653638495, -5.308983105200696],
              [-47.28446154010169, -5.324454086820197],
              [-47.273641777911784, -5.326680426475904],
              [-47.26493835449219, -5.324074745178223],
              [-47.24427032470703, -5.310315608978271],
              [-47.22819519042969, -5.300005912780762],
              [-47.218494516956895, -5.294475619607008],
              [-47.21218857990414, -5.274305662989036],
              [-47.205696460612586, -5.222632570968813],
              [-47.20022892293307, -5.213400395137072],
              [-47.199562072753906, -5.192452907562256],
              [-47.19384002685547, -5.17186450958252],
              [-47.18003845214844, -5.172994613647461],
              [-47.164836497551036, -5.167305325550988],
              [-47.15949573181433, -5.162648728506307],
              [-47.14446258544922, -5.138651847839355],
              [-47.137569427490234, -5.12263011932373],
              [-47.12955093383789, -5.099746704101562],
              [-47.12037658691406, -5.08373498916626],
              [-47.107974553203974, -5.068274102058765],
              [-47.089122627520595, -5.037391169514478],
              [-47.08172415683125, -5.035184671808421],
              [-47.08135223388672, -5.034525394439697],
              [-47.06873321533203, -5.015072822570801],
              [-47.053802490234375, -5.007059574127197],
              [-47.03313446044922, -5.008184432983398],
              [-47.021629333496094, -5.032190322875977],
              [-47.01817321777344, -5.044769763946533],
              [-47.01603991836275, -5.059584097912928],
              [-47.00580952736425, -5.085316880259541],
              [-46.978931613702656, -5.097118767579616],
              [-46.94681953677923, -5.091615209949509],
              [-46.92926062307677, -5.100252593541768],
              [-46.920070536788614, -5.136575068072204],
              [-46.922363490223404, -5.174373092839005],
              [-46.92475853523899, -5.208748618898028],
              [-46.92341256922583, -5.252160339502723],
              [-46.93030148781409, -5.290096498034018],
              [-46.91526753862655, -5.357415753450255],
              [-46.91306360100353, -5.354602954380027],
              [-46.91083552349937, -5.349879416660144],
              [-46.910278462213796, -5.340160097004969],
              [-46.91472657059509, -5.324048067429999],
              [-46.91500049119111, -5.31099325322208],
              [-46.91611461376223, -5.305439404172887],
              [-46.916946601472205, -5.293216577674912],
              [-46.919448599572405, -5.282107203195835],
              [-46.92000549321989, -5.275995622308812],
              [-46.919174511338326, -5.255165084155863],
              [-46.91972352599683, -5.249054844373347],
              [-46.91861761769081, -5.242942257657944],
              [-46.914451476532605, -5.233500714274271],
              [-46.90277850288351, -5.224057662148027],
              [-46.89861252936336, -5.221554825857481],
              [-46.896118577890206, -5.216834976174994],
              [-46.897506621057374, -5.211280624211611],
              [-46.90028354558197, -5.207111800844359],
              [-46.902229488225004, -5.202112163233608],
              [-46.901946515173535, -5.195448550202968],
              [-46.90028354558197, -5.189891180754387],
              [-46.89583560483874, -5.180447793352016],
              [-46.89250161902859, -5.169615692230252],
              [-46.89028158815145, -5.1504498000495],
              [-46.891113575861425, -5.131008478530134],
              [-46.893615573961625, -5.119896254203979],
              [-46.89527854355319, -5.115174560502794],
              [-46.898055468077814, -5.111007916430424],
              [-46.89916959064891, -5.105453396828921],
              [-46.90666954997923, -5.085733628485798],
              [-46.907226611264775, -5.079623053427156],
              [-46.9063946235548, -5.059067776974757],
              [-46.906951517202316, -5.05268026383493],
              [-46.90861549262226, -5.047957564305364],
              [-46.91722856869529, -5.03601553156409],
              [-46.91750349511972, -5.017403850821779],
              [-46.92333947903006, -4.989627900053108],
              [-46.923889499516946, -4.983518330822847],
              [-46.92750562018827, -4.9735190556014],
              [-46.9322284873559, -4.964631723656169],
              [-46.93500557951859, -4.954075387149146],
              [-46.93889662661431, -4.944354055837209],
              [-46.941390578087464, -4.940188417593163],
              [-46.94194747173495, -4.934077842534521],
              [-46.93500557951859, -4.912968354643624],
              [-46.93472260646712, -4.906024115494404],
              [-46.93194551430443, -4.887415284599172],
              [-46.93805659227729, -4.859915433721426],
              [-46.93944547363475, -4.847972395151714],
              [-46.93695051633321, -4.836585076763072],
              [-46.93472260646712, -4.817419519858447],
              [-46.94167355113893, -4.797142019677551],
              [-46.942779627082984, -4.791587500076048],
              [-46.94194747173495, -4.77853436224882],
              [-46.940961406048736, -4.772337327819893],
              [-46.940002534920296, -4.766311200474718],
              [-46.93666854911015, -4.755200987805324],
              [-46.93805659227729, -4.749646468203878],
              [-46.94583851883067, -4.73714636834859],
              [-46.953613572223446, -4.704650903173615],
              [-46.953895539446535, -4.691594915499252],
              [-46.952224523227926, -4.686040228259742],
              [-46.95083648006079, -4.67298625224214],
              [-46.95444455410504, -4.669375495988845],
              [-46.95916758891073, -4.667151609436246],
              [-46.9625015747209, -4.663540853183008],
              [-46.963615529653964, -4.65826327166269],
              [-46.961944513435355, -4.652709590251504],
              [-46.957504619319195, -4.643265364658816],
              [-46.95667246397116, -4.637154957238238],
              [-46.95694755803362, -4.63076643827003],
              [-46.96639262181665, -4.599657675157687],
              [-46.96805559140819, -4.587713463121531],
              [-46.960006617419396, -4.575214369094681],
              [-46.95777853991521, -4.57021573731231],
              [-46.95472752715651, -4.566327037149449],
              [-46.9397276084959, -4.553827943122599],
              [-46.937499530991715, -4.549105243593033],
              [-46.93583656140018, -4.536884261113812],
              [-46.93361653052304, -4.532161393946183],
              [-46.93055747113729, -4.5279959233402],
              [-46.92333947903006, -4.52133130448118],
              [-46.920562554505466, -4.517442604318319],
              [-46.919448599572405, -4.505774492173032],
              [-46.920562554505466, -4.500220978399966],
              [-46.92250061815949, -4.495220167322771],
              [-46.93000057748981, -4.488832654182943],
              [-46.93111453242287, -4.48327914040982],
              [-46.92806251383578, -4.479389602056699],
              [-46.921951603500986, -4.47883337896144],
              [-46.917777583353796, -4.476334733622537],
              [-46.909728609364976, -4.463556689857739],
              [-46.90528150681209, -4.460779765333143],
              [-46.89556151660466, -4.457169009079848],
              [-46.8827814611831, -4.449389764735486],
              [-46.87583956896674, -4.442724307686092],
              [-46.87277950375258, -4.438557328337538],
              [-46.86833960963642, -4.429114108573231],
              [-46.865280550250674, -4.418283013279847],
              [-46.86167147037801, -4.393838869026524],
              [-46.85916846644943, -4.382173606728372],
              [-46.85305755611466, -4.369396736430019],
              [-46.850280463951975, -4.365230930547909],
              [-46.844726614902754, -4.364119825461955],
              [-46.83972949186298, -4.366340694529413],
              [-46.833618581528185, -4.367175699724498],
              [-46.82750649772697, -4.366620817733747],
              [-46.817222573073366, -4.363841881552389],
              [-46.80778455008905, -4.359120355489267],
              [-46.802223492603076, -4.358008412212996],
              [-46.78945148380856, -4.359119349660887],
              [-46.78472861664093, -4.361341224556725],
              [-46.77833557144501, -4.362453000194932],
              [-46.77222449347218, -4.3618971123758],
              [-46.76167251555478, -4.358842243941638],
              [-46.75500454393443, -4.359120355489267],
              [-46.74945052724715, -4.360509404484787],
              [-46.73638951043088, -4.360508398656407],
              [-46.72444948934637, -4.351898842982735],
              [-46.71361554420588, -4.341900741227676],
              [-46.7058336176525, -4.336065930783775],
              [-46.7008364946127, -4.334121329245249],
              [-46.694450490215445, -4.333845061716318],
              [-46.68917056176224, -4.332455342168544],
              [-46.68000059204172, -4.328010754186607],
              [-46.67444657535444, -4.328287692267736],
              [-46.67028060183429, -4.327176587181782],
              [-46.66750350967163, -4.323010948937792],
              [-46.66083553805129, -4.315790442259583],
              [-46.65111554784386, -4.311899898078082],
              [-46.64805548262973, -4.308012203743601],
              [-46.643890514937965, -4.298569822169611],
              [-46.638061571826256, -4.290514310296317],
              [-46.62750255311019, -4.287179821571954],
              [-46.623336579590074, -4.284681846785304],
              [-46.619728505545794, -4.281348196251258],
              [-46.60806257269536, -4.26523918416143],
              [-46.60305757066658, -4.256627952107067],
              [-46.598892602974814, -4.253850189392153],
              [-46.587783563771865, -4.25107242667724],
              [-46.58167248579903, -4.250516371220044],
              [-46.57639356317421, -4.248572440233772],
              [-46.57222758965409, -4.246071615600044],
              [-46.56889360384392, -4.24274098255114],
              [-46.559448540060885, -4.238294382912443],
              [-46.555557492965164, -4.23551846421617],
              [-46.54361747188062, -4.233851471311141],
              [-46.53527848404167, -4.228851833700389],
              [-46.53139447774461, -4.225519356632788],
              [-46.52806049193447, -4.222186544289059],
              [-46.52305548990569, -4.213297033049002],
              [-46.515006515916866, -4.207465575366371],
              [-46.511672530106694, -4.211075158153221],
              [-46.5063936074819, -4.212741145229927],
              [-46.49583458876583, -4.209407494695881],
              [-46.48333750639571, -4.208575506985881],
              [-46.47277848767965, -4.198576231764434],
              [-46.46861251415953, -4.196076580597094],
              [-46.465003601924934, -4.192744774081746],
              [-46.460555493543666, -4.18329920738455],
              [-46.4588925239521, -4.177744687783104],
              [-46.45806154207051, -4.171632268705707],
              [-46.45861860335606, -4.165523537665763],
              [-46.46334046469531, -4.150245675095618],
              [-46.466117556857995, -4.146080204489635],
              [-46.46916957544508, -4.135525041449057],
              [-46.4705586244406, -4.116915372363565],
              [-46.46805562051202, -4.105249104236975],
              [-46.46583558963491, -4.100805522083419],
              [-46.464446540639386, -4.095251002481916],
              [-46.46139552788068, -4.091083184943102],
              [-46.452506519554845, -4.086639435151483],
              [-46.439170576314154, -4.087751546065817],
              [-46.43444854733684, -4.08608354733235],
              [-46.43083946746421, -4.082751740817002],
              [-46.429168618883665, -4.077196383025239],
              [-46.42694858800655, -4.074419290862522],
              [-46.40250360556291, -4.064975065269834],
              [-46.376396491718054, -4.064698294826712],
              [-46.37083459604179, -4.063586183912378],
              [-46.36111460583436, -4.059697651387637],
              [-46.35500352786153, -4.060531483116279],
              [-46.347778494955605, -4.066918828618043],
              [-46.341949551843925, -4.065807220617899],
              [-46.33306154934647, -4.061365482482984],
              [-46.3275065268308, -4.060253539206713],
              [-46.295562590885254, -4.05858671393969],
              [-46.290000527570896, -4.057475608853736],
              [-46.27555850838618, -4.03719810867284],
              [-46.269172503988926, -4.02303319520729],
              [-46.26611361224121, -4.018867389325237],
              [-46.259727607843956, -3.997202181253328],
              [-46.2569505156813, -3.993312307624024],
              [-46.24417146608812, -3.985258136855236],
              [-46.22944647385191, -3.979702611425409],
              [-46.22389262480269, -3.97859351799616],
              [-46.21472952824277, -3.974148930014223],
              [-46.205558552693844, -3.9627606057972],
              [-46.19861548701104, -3.93470671111902],
              [-46.18917059086607, -3.91026273450376],
              [-46.18861352958052, -3.904154003463816],
              [-46.1866685927659, -3.898875416115118],
              [-46.18389150060321, -3.894709610233065],
              [-46.17555955356292, -3.889432028712747],
              [-46.17250049417717, -3.885544502016387],
              [-46.17028046330006, -3.88082062902032],
              [-46.168617493708496, -3.875267282885318],
              [-46.16806060006101, -3.848046214107399],
              [-46.165000534846854, -3.843879570035028],
              [-46.15805847499243, -3.83721193369081],
              [-46.14666746856639, -3.834992070451733],
              [-46.14194460139876, -3.833048307103525],
              [-46.13806160093009, -3.829714656569479],
              [-46.131668555734166, -3.815547731447225],
              [-46.12995160668612, -3.804413378896697],
              [-46.12416859640388, -3.801937699972484],
              [-46.11083952632379, -3.773606867213118],
              [-46.10527746300946, -3.765551355339824],
              [-46.098617538016185, -3.758331854489995],
              [-46.09083561146278, -3.752497379322222],
              [-46.075561604568065, -3.74749774171147],
              [-46.056945565236134, -3.745831922272828],
              [-46.04917151767174, -3.739998117657308],
              [-46.044448482866045, -3.738054521947163],
              [-46.03889446617876, -3.736943584499272],
              [-46.0255585229381, -3.736664467123262],
              [-46.009170561110324, -3.733054884336411],
              [-46.00305948313749, -3.732777610979099],
              [-45.98472557866671, -3.72361049110566],
              [-45.97917156197943, -3.723888267377163],
              [-45.9669495736718, -3.732498828879216],
              [-45.960555522647496, -3.739722353042566],
              [-45.95555856724576, -3.741943054471903],
              [-45.9502794769829, -3.743331935829417],
              [-45.9441685666481, -3.74305298609147],
              [-45.93861354413244, -3.741664272372077],
              [-45.929450615210584, -3.737220354942338],
              [-45.915840583735815, -3.723332547196094],
              [-45.908058489544345, -3.717499916047018],
              [-45.89639255669391, -3.701391071595253],
              [-45.89222758900215, -3.698613308880283],
              [-45.88667256648648, -3.697502371432392],
              [-45.88111150900053, -3.697779477151641],
              [-45.86361646540033, -3.701112960047624],
              [-45.85334058737379, -3.704724889767363],
              [-45.84694653634946, -3.704445772391352],
              [-45.82805657642152, -3.696114328265253],
              [-45.82389060290137, -3.693334721531642],
              [-45.80861659600666, -3.688336089749271],
              [-45.79084059101177, -3.686391152934618],
              [-45.76944745951715, -3.693057615812393],
              [-45.763336549182384, -3.692780845369271],
              [-45.753616558974954, -3.688891139378086],
              [-45.74806254228767, -3.687781040120456],
              [-45.743896568767525, -3.685003277405542],
              [-45.731391607408455, -3.684169278038837],
              [-45.717781575933685, -3.684724495305659],
              [-45.705284493563596, -3.683614396048085],
              [-45.68305954199707, -3.678891696518519],
              [-45.678337513019756, -3.67694692734193],
              [-45.672500523281, -3.67583598989404],
              [-45.653892530576115, -3.667503707577623],
              [-45.64833851388883, -3.66778265731557],
              [-45.631950552061085, -3.679169808066149],
              [-45.628890486846956, -3.682780396681324],
              [-45.6269455500323, -3.687503263848953],
              [-45.62583947408825, -3.693057615812393],
              [-45.62611356232233, -3.700000346219099],
              [-45.62389353144522, -3.705001827848491],
              [-45.6200024843495, -3.707778752373144],
              [-45.6069485083319, -3.707501646653895],
              [-45.59584047495733, -3.705279771758057],
              [-45.581390576783605, -3.699447308247045],
              [-45.57500457238635, -3.698890246961469],
              [-45.570281537580655, -3.699724078690167],
              [-45.56472752089337, -3.69861431470872],
              [-45.560562553201606, -3.696113490074936],
              [-45.55000353448554, -3.692780845369271],
              [-45.537506619753515, -3.691947851830946],
              [-45.53139453595227, -3.692780845369271],
              [-45.518615486359096, -3.700835183776121],
              [-45.50055650831274, -3.710278571178492],
              [-45.495559552911004, -3.708334472554156],
              [-45.48694647683797, -3.703333829115081],
              [-45.4836124910278, -3.70000235787586],
              [-45.47861452979768, -3.691114020102248],
              [-45.47695156020612, -3.685837109134241],
              [-45.47611253169748, -3.679447584337595],
              [-45.4702835885858, -3.671394251759182],
              [-45.46305855567991, -3.665004894600656],
              [-45.46028146351722, -3.660838082890166],
              [-45.45667255128265, -3.65750443235612],
              [-45.452506577762506, -3.655006625207534],
              [-45.44972261243922, -3.650839813497043],
              [-45.446113532566585, -3.647506162962998],
              [-45.44194755904644, -3.64500634415765],
              [-45.4369506036447, -3.647227045587044],
              [-45.432502495263435, -3.646672163596293],
              [-45.42361449276598, -3.634451181117072],
              [-45.41944851924583, -3.631951194673604],
              [-45.416389459860085, -3.628063500339181],
              [-45.4150005785026, -3.622507807271234],
              [-45.416389459860085, -3.616951276013026],
              [-45.415840612839645, -3.603343256194989],
              [-45.41361253533546, -3.598619718475106],
              [-45.41250645939141, -3.592510149244845],
              [-45.41222348633994, -3.578621335670221],
              [-45.40861557993375, -3.575289529154873],
              [-45.40444960641361, -3.576955180955451],
              [-45.39889558972632, -3.576956186783832],
              [-45.391944477416445, -3.570289723906058],
              [-45.38777950972468, -3.56917895409623],
              [-45.38111153810436, -3.576121516864816],
              [-45.36972857830537, -3.585565574819441],
              [-45.360000541470896, -3.59612191132652],
              [-45.34889250809633, -3.599176612122619],
              [-45.33861562424141, -3.603065479923487],
              [-45.33250454626858, -3.603897467633487],
              [-45.32167060112809, -3.601398654656464],
              [-45.31139354963511, -3.590844329806202],
              [-45.30722858194335, -3.588342499344037],
              [-45.30416851672922, -3.584177699290365],
              [-45.30805956382494, -3.57445452395973],
              [-45.30583953294783, -3.569734003724989],
              [-45.29722561868448, -3.57473364133574],
              [-45.29111454071162, -3.57584357295525],
              [-45.2872234936159, -3.572511766439902],
              [-45.28083748921864, -3.558345679507966],
              [-45.27916747882841, -3.552791159906519],
              [-45.276390554303816, -3.548623342367648],
              [-45.27500150530827, -3.543346766675768],
              [-45.275283472531356, -3.53695925353594],
              [-45.276672521526876, -3.531680833825305],
              [-45.27305556266526, -3.528348021481577],
              [-45.27222458078367, -3.523347378042445],
              [-45.27611546024133, -3.520571626984292],
              [-45.276672521526876, -3.515849765645044],
              [-45.27111850483959, -3.507793415581432],
              [-45.26667056409639, -3.505017832161343],
              [-45.26167260286627, -3.503349833427876],
              [-45.252227539083265, -3.506406545880736],
              [-45.24694861645844, -3.506406545880736],
              [-45.24278247530026, -3.503906727075332],
              [-45.22666960753497, -3.499461133265015],
              [-45.22306052766231, -3.496128320921287],
              [-45.21556056833202, -3.475851826568771],
              [-45.206672565834566, -3.480850625989206],
              [-45.200561487861705, -3.481963407455794],
              [-45.19639551434159, -3.479185477102817],
              [-45.18666848333547, -3.47529694457802],
              [-45.18167152793376, -3.473629951672933],
              [-45.17555961177058, -3.473073225663541],
              [-45.16667160927315, -3.478072527998108],
              [-45.15555552927151, -3.48751708886698],
              [-45.139167567443764, -3.4989060836362],
              [-45.12833446049359, -3.501961790260737],
              [-45.125556530140614, -3.501960784432356],
              [-45.1219486237344, -3.493349552377992],
              [-45.11611146635758, -3.485296219799523],
              [-45.11111451095587, -3.476406876197586],
              [-45.10806249236879, -3.472519181863106],
              [-45.09750347365272, -3.46251973900354],
              [-45.09333750013258, -3.460019920198192],
              [-45.09027860838489, -3.455853276125765],
              [-45.08583854663064, -3.446408882895014],
              [-45.08722658979781, -3.440855536760012],
              [-45.092506518250985, -3.432520907510707],
              [-45.09305553290949, -3.42613440019926],
              [-45.08944662067492, -3.423077855384463],
              [-45.08528148534509, -3.4244677425703],
              [-45.08194749953492, -3.428077492995214],
              [-45.07722446472923, -3.430298362062615],
              [-45.07305949703746, -3.427522443366399],
              [-45.06278261318255, -3.41002287353848],
              [-45.06278261318255, -3.404469024489288],
              [-45.061668490611424, -3.398356773049954],
              [-45.05889156608683, -3.394468743439404],
              [-45.05472559256668, -3.391690980724491],
              [-45.04861451459385, -3.391413036814924],
              [-45.043060497906566, -3.390024323095531],
              [-45.03666661452033, -3.382802642950878],
              [-45.03166949148053, -3.383636809955703],
              [-45.031394565056104, -3.393912855620272],
              [-45.02806057924596, -3.397523611873567],
              [-45.0219495012731, -3.398358617068652],
              [-45.017784533581334, -3.395580854353739],
              [-45.013893486485614, -3.385579399837411],
              [-45.01111555613264, -3.381414935059865],
              [-45.009048578807636, -3.380236942386489],
              [-45.006568541293774, -3.380767852134113],
              [-45.003734619827526, -3.380590826338903],
              [-45.00178951537481, -3.379175961081557],
              [-44.99789461260363, -3.373343497570545],
              [-44.99810047214581, -3.3700628206646],
              [-44.996265505900936, -3.367311880039836],
              [-44.99261853982591, -3.364884983792194],
              [-44.9877585447222, -3.360033538229743],
              [-44.98654551569351, -3.357606474343982],
              [-44.9877585447222, -3.35517991337241],
              [-44.99018845845501, -3.351540155734142],
              [-44.9938355921681, -3.350329138362213],
              [-44.99626952921446, -3.349116109333522],
              [-44.99991247197596, -3.345475513504937],
              [-44.99869558727181, -3.34426265211431],
              [-44.9938355921681, -3.340623900304422],
              [-44.9877585447222, -3.340623900304422],
              [-44.98046846824758, -3.341834750038231],
              [-44.97925158354346, -3.343047779066922],
              [-44.974395611753266, -3.344260640457549],
              [-44.97074847804018, -3.345473501848176],
              [-44.9683155468222, -3.347900398095817],
              [-44.95859555661477, -3.347898386439056],
              [-44.95616547524389, -3.346685357410422],
              [-44.95252253248239, -3.343044593943716],
              [-44.94887556640734, -3.338194154209646],
              [-44.94887958972089, -3.300594948186358],
              [-44.95009261874955, -3.296957034566731],
              [-44.94887958972089, -3.293318282756843],
              [-44.947662537378676, -3.292105421366216],
              [-44.94401557130365, -3.292105421366216],
              [-44.93915959951346, -3.293318282756843],
              [-44.93794254717125, -3.295745179004541],
              [-44.93065649401018, -3.312722891540261],
              [-44.92943960930603, -3.315148781959522],
              [-44.92579247559294, -3.316360637521768],
              [-44.9197196190986, -3.316360637521768],
              [-44.914859623994914, -3.315146770302761],
              [-44.913646594966224, -3.313933741274127],
              [-44.91000348456666, -3.312719874055119],
              [-44.905143489462944, -3.309081122245175],
              [-44.90271357573013, -3.304231520701421],
              [-44.90028349435923, -3.294531311785533],
              [-44.90271357573013, -3.290892727613652],
              [-44.90271357573013, -3.287253808165701],
              [-44.90028349435923, -3.283617235650638],
              [-44.89785760393994, -3.283617235650638],
              [-44.89542752256904, -3.289678692756638],
              [-44.88814146940797, -3.296955190548033],
              [-44.884494503332945, -3.295742161519399],
              [-44.882064589600134, -3.290890715956891],
              [-44.88085156057144, -3.286041952603455],
              [-44.87842147920057, -3.28240336843163],
              [-44.87599558878125, -3.276339732030806],
              [-44.872352478381686, -3.275128714658877],
              [-44.86384954051647, -3.279976304545869],
              [-44.861419459145594, -3.287249952490242],
              [-44.861419459145594, -3.29331325361494],
              [-44.862632488174256, -3.300587739749631],
              [-44.85655946404188, -3.29695016140613],
              [-44.848060549490185, -3.293311577234306],
              [-44.84077449632912, -3.290884513348544],
              [-44.837131553567616, -3.288458622929284],
              [-44.82863247137786, -3.288456778910529],
              [-44.821346585854855, -3.284820206395466],
              [-44.82013355682619, -3.273909818297966],
              [-44.81649346391177, -3.26299959783853],
              [-44.81406355017893, -3.271484095516712],
              [-44.8128475036651, -3.278755564166261],
              [-44.810417589932285, -3.283604998071951],
              [-44.80798750856141, -3.289663772968936],
              [-44.80555759482857, -3.290875628531182],
              [-44.803127513457696, -3.295724224246555],
              [-44.799484570696194, -3.298146091352351],
              [-44.79705448932529, -3.300569802476787],
              [-44.78977162128743, -3.304203525144771],
              [-44.78976860380229, -3.305416386535398],
              [-44.787139536049835, -3.306069504431434],
              [-44.78676654135816, -3.304764442105807],
              [-44.78237157424306, -3.294435255175017],
              [-44.76500661778027, -3.299574870568961],
              [-44.76064249606887, -3.295456338620852],
              [-44.75878556923766, -3.288793731418536],
              [-44.76240152227092, -3.278659507555801],
              [-44.77836250995051, -3.266626447345914],
              [-44.783321579149856, -3.248665872842935],
              [-44.791953598324085, -3.235287684810203],
              [-44.79781757779111, -3.215941246434852],
              [-44.798000470918566, -3.205363116979527],
              [-44.793395620947734, -3.198025431294866],
              [-44.77573059235098, -3.186378273907565],
              [-44.75971260772974, -3.185302372814931],
              [-44.74437355726633, -3.181921615985004],
              [-44.72034850817218, -3.180191926444138],
              [-44.71759052674875, -3.177676014384645],
              [-44.71642661567273, -3.172160554451978],
              [-44.71796754475395, -3.158815558755862],
              [-44.71473347122972, -3.150780163450236],
              [-44.69021254874326, -3.139394018528037],
              [-44.677127559683896, -3.128412719529592],
              [-44.66654557455308, -3.114203717253361],
              [-44.62404647556693, -3.068857956308136],
              [-44.61092360030517, -3.048219531376105],
              [-44.60837550173747, -3.040413129665467],
              [-44.60881454582622, -3.036040458412799],
              [-44.61563858848382, -3.026813156474475],
              [-44.62019348231166, -3.022192548525709],
              [-44.62817758036985, -3.017328027194253],
              [-44.63343454240831, -3.01615606949116],
              [-44.64899856315316, -3.019307832726156],
              [-44.65473161729244, -3.023191503747057],
              [-44.662277509452196, -3.023386634453175],
              [-44.672790595338796, -3.021500706236964],
              [-44.67666655500881, -3.018492776460619],
              [-44.68165546378347, -3.0083525176276],
              [-44.68503957337467, -2.997298463709512],
              [-44.6863474855474, -2.982347663001576],
              [-44.685092547002796, -2.9554454418207],
              [-44.682044551729234, -2.937753926409925],
              [-44.68770552150113, -2.924161832207972],
              [-44.685840548042876, -2.915891408337018],
              [-44.67963056361248, -2.906722109168754],
              [-44.665573609059976, -2.89262827424227],
              [-44.659175534722124, -2.882437053437855],
              [-44.652023592012, -2.866602297220197],
              [-44.649455544514694, -2.854655905889217],
              [-44.64895648599918, -2.844311966808846],
              [-44.65926756801909, -2.794597557924476],
              [-44.65829459669757, -2.780343628647188],
              [-44.652992540019966, -2.770250308471987],
              [-44.643581506763894, -2.760634924416308],
              [-44.63137846155746, -2.740224654888948],
              [-44.632492584128585, -2.73309115236583],
              [-44.62902448786747, -2.72413877685193],
              [-44.629890506104374, -2.712177633371368],
              [-44.629169494792535, -2.703904192015329],
              [-44.62595352617919, -2.700009624520249],
              [-44.623207614696355, -2.698870523877645],
              [-44.620220472039904, -2.695435955229243],
              [-44.61235053550311, -2.672246749520582],
              [-44.60267262244966, -2.653206082973384],
              [-44.58498362160978, -2.631439621510992],
              [-44.58082955039217, -2.62180043285025],
              [-44.57779647490628, -2.607098574666793],
              [-44.57113654991301, -2.599079272615256],
              [-44.56517450217876, -2.594506609152688],
              [-44.55641960430384, -2.577300741212298],
              [-44.5463145494642, -2.565617709279365],
              [-44.543544498100204, -2.559652141145762],
              [-44.54827457370456, -2.541467434551635],
              [-44.55054858403818, -2.538698556654083],
              [-44.556259509953065, -2.537984586140965],
              [-44.56472757910103, -2.540706357742636],
              [-44.57435955932502, -2.54917191231965],
              [-44.59115253471461, -2.575309871568209],
              [-44.59574145906936, -2.579658570577749],
              [-44.60947453688286, -2.583277373458088],
              [-44.61656948265136, -2.585775012968611],
              [-44.624603536852504, -2.594246602515966],
              [-44.63353361650394, -2.597886192516171],
              [-44.645648484088866, -2.598062044844937],
              [-44.6529804700794, -2.602399009190037],
              [-44.65895056444069, -2.608119322836501],
              [-44.660362579850926, -2.617999575032741],
              [-44.664031506512316, -2.620973474282152],
              [-44.666084569878024, -2.61981341888162],
              [-44.665348471140476, -2.608091494917915],
              [-44.66143060195455, -2.600520959962807],
              [-44.65523956062535, -2.59526047752496],
              [-44.64196748748691, -2.592559493043211],
              [-44.63074462203875, -2.586401979326752],
              [-44.623855535812424, -2.579304518987271],
              [-44.606456548822706, -2.572483158538716],
              [-44.59293352150297, -2.563116884645638],
              [-44.590396487047485, -2.558298128505555],
              [-44.5866625168174, -2.539230639868151],
              [-44.58273660100443, -2.528672459342431],
              [-44.57883046648297, -2.523861582191387],
              [-44.5733336143754, -2.520895897207083],
              [-44.5660135306874, -2.519548087175224],
              [-44.560310483761526, -2.52210188543711],
              [-44.54888561444662, -2.522841672212053],
              [-44.54087854997374, -2.521726879088646],
              [-44.53561052382307, -2.51899018769933],
              [-44.53101757615477, -2.513261492149695],
              [-44.520412624609264, -2.490315361633293],
              [-44.5162734731793, -2.484585660255334],
              [-44.51032248955727, -2.481621819289728],
              [-44.50549652498049, -2.475895135396854],
              [-44.50411250512684, -2.471992688912792],
              [-44.50477551366865, -2.466241026948467],
              [-44.510444530067616, -2.455640769268712],
              [-44.513843559446485, -2.44757955770126],
              [-44.511295460878785, -2.439313995334203],
              [-44.49294261330678, -2.421458362259045],
              [-44.49108551883751, -2.414569778946941],
              [-44.499484521103255, -2.400508968718952],
              [-44.51086848673063, -2.389654739372816],
              [-44.52457859812938, -2.389366737179387],
              [-44.54656952695902, -2.402146960239065],
              [-44.55298956188321, -2.407636436271787],
              [-44.55320749136598, -2.404876610829717],
              [-44.55021649303407, -2.400061878003157],
              [-44.54151557461566, -2.395731116266461],
              [-44.526390597959534, -2.384990210251317],
              [-44.54694352747907, -2.377231585388756],
              [-44.52622262461975, -2.352192493647522],
              [-44.52971251382914, -2.333900833968755],
              [-44.56856950824411, -2.331754899115822],
              [-44.59366962024052, -2.344433198232707],
              [-44.6267545008474, -2.362864668056545],
              [-44.63358659013204, -2.370902242657053],
              [-44.656375476144746, -2.392733412411985],
              [-44.67458750774324, -2.417986745598625],
              [-44.69279451019983, -2.444388567159422],
              [-44.70876354450647, -2.45589977007711],
              [-44.73162049157301, -2.460566478493377],
              [-44.75221248076136, -2.460643424364605],
              [-44.770561472657874, -2.446967511130822],
              [-44.79236246756139, -2.426428830846703],
              [-44.80615656563006, -2.404713498993772],
              [-44.81997647996053, -2.373831885457662],
              [-44.85317250460358, -2.365919368852644],
              [-44.839477480630535, -2.354421409342024],
              [-44.837234483338705, -2.338377608458927],
              [-44.846431610425554, -2.320077902153173],
              [-44.88190851856285, -2.316744251619127],
              [-44.910476559182314, -2.330575230061811],
              [-44.92764252926355, -2.329477368382868],
              [-44.90598251797158, -2.296189813357046],
              [-44.89230761056618, -2.274385298054199],
              [-44.87517952668733, -2.260593379280351],
              [-44.852367506621874, -2.231897598456328],
              [-44.81010846534289, -2.207738438911292],
              [-44.783832539967904, -2.197363989703319],
              [-44.778190513297204, -2.164146675026132],
              [-44.75652647869171, -2.136617487487229],
              [-44.726833586665066, -2.123954611072179],
              [-44.70973249251446, -2.104455957335006],
              [-44.71091450850136, -2.08614736621189],
              [-44.715595466153076, -2.024359999258479],
              [-44.679084566439116, -1.994535176075715],
              [-44.68369662484665, -1.969374043824132],
              [-44.7020146037014, -1.943092083478859],
              [-44.69292862065075, -1.904179097950589],
              [-44.7078014696591, -1.882463430821531],
              [-44.76150147350711, -1.874532138753409],
              [-44.79919858015958, -1.881449723450544],
              [-44.85406450674034, -1.866636888868982],
              [-44.88264847297586, -1.85064103247214],
              [-44.912383609794574, -1.815178708846361],
              [-44.90782955415705, -1.778539398375642],
              [-44.895267595856325, -1.736177427325572],
              [-44.91469550633056, -1.713292317064315],
              [-44.947837551517125, -1.705290952285623],
              [-44.976413471125596, -1.7236183188719],
              [-45.0072784884934, -1.721336764825423],
              [-45.022140608665694, -1.708742620016437],
              [-45.050712504960615, -1.68011355914183],
              [-45.083866620087775, -1.672095262918674],
              [-45.11244555718136, -1.658344415470424],
              [-45.12731957965616, -1.668651641814847],
              [-45.15478556764509, -1.694996634072083],
              [-45.179954578885685, -1.704159060079746],
              [-45.195968540193434, -1.704156042594605],
              [-45.20589053426755, -1.693578751329596],
              [-45.210460515521135, -1.694474609141821],
              [-45.22390760279802, -1.689349075345262],
              [-45.23373052277654, -1.68998811164397],
              [-45.2412796000595, -1.692706865760442],
              [-45.25223960902383, -1.692420875223831],
              [-45.26002455306238, -1.69697643960393],
              [-45.27260562210236, -1.701736187145571],
              [-45.27489857553718, -1.703563106763397],
              [-45.27583349301824, -1.707925719732202],
              [-45.27315547495118, -1.721038704348246],
              [-45.275283472531356, -1.735506539794756],
              [-45.27233153386828, -1.738738266386122],
              [-45.280590558350866, -1.746968792397865],
              [-45.28446551219247, -1.745799684541851],
              [-45.29475061031252, -1.747125701625464],
              [-45.31738661041115, -1.753441130397903],
              [-45.32469160667341, -1.752943412986895],
              [-45.33771155216408, -1.753334344951327],
              [-45.34819748068438, -1.750060541206039],
              [-45.354118624731086, -1.746581883746899],
              [-45.36592051205116, -1.732270957527987],
              [-45.371120477148, -1.722130698694912],
              [-45.36435661655523, -1.693439779374785],
              [-45.365001520186155, -1.685852312975271],
              [-45.36818647575771, -1.683307399530747],
              [-45.355743540482194, -1.660393287884517],
              [-45.35272253493687, -1.649836951377495],
              [-45.35194754416855, -1.632376441218412],
              [-45.34668354133143, -1.629875448946564],
              [-45.34574862385037, -1.6255158534629],
              [-45.34977361375894, -1.60825801772279],
              [-45.34792356008833, -1.603901272086205],
              [-45.34219352343422, -1.599566654673993],
              [-45.33920252510231, -1.594296952142713],
              [-45.34358257242974, -1.585589831115954],
              [-45.359378604254715, -1.590719388226034],
              [-45.36773653519484, -1.593989168657799],
              [-45.38272455155294, -1.604019289283031],
              [-45.39981458159133, -1.625043952313661],
              [-45.40376648130416, -1.632470318534047],
              [-45.39429459543098, -1.676614952753141],
              [-45.39299355641887, -1.685543356023913],
              [-45.388771591785456, -1.721415722353413],
              [-45.38640957146842, -1.730118987704714],
              [-45.373733619284394, -1.773455606456935],
              [-45.37625456048579, -1.781189253247987],
              [-45.38719545871089, -1.795564217207243],
              [-45.39458846495657, -1.800151800457513],
              [-45.42852058833719, -1.812605967483307],
              [-45.43698446653357, -1.812406813463667],
              [-45.47074861657441, -1.799482756945395],
              [-45.478034502097415, -1.794680261697522],
              [-45.49482362181152, -1.773396597858493],
              [-45.49859950155752, -1.765378301635337],
              [-45.50586359413225, -1.739265320458287],
              [-45.5077475106917, -1.730489970739598],
              [-45.50761457370723, -1.712421437323599],
              [-45.507221462447916, -1.703417094009978],
              [-45.50256347121092, -1.676717044333884],
              [-45.50186961726541, -1.667740696576914],
              [-45.50173148350095, -1.649673839541549],
              [-45.502189470690894, -1.640663628895652],
              [-45.50633650110984, -1.595690024451756],
              [-45.51166152420217, -1.590520401844458],
              [-45.52914449786178, -1.585933656784505],
              [-45.537692530366115, -1.583008037297418],
              [-45.54634449846992, -1.580672168518333],
              [-45.57345961999161, -1.580455077225906],
              [-45.5808415621251, -1.57647249974741],
              [-45.58417152462175, -1.568293941535387],
              [-45.59140762163983, -1.542172075540918],
              [-45.59445561691339, -1.533707694430348],
              [-45.602405516806584, -1.517477815296616],
              [-45.60435447693476, -1.508749571873807],
              [-45.60626253337537, -1.494569570982605],
              [-45.61117147879369, -1.477113754689299],
              [-45.61104960592141, -1.462687828758646],
              [-45.60884047151842, -1.43000729116136],
              [-45.60582348928665, -1.414645441921323],
              [-45.60375248864813, -1.398315818139679],
              [-45.60653662160948, -1.386750635765509],
              [-45.614292564262996, -1.393413242967824],
              [-45.61633255185973, -1.40686016260662],
              [-45.62134157720206, -1.428936586848749],
              [-45.62438554916207, -1.44718415771672],
              [-45.63032546867191, -1.466369831189013],
              [-45.63768746187583, -1.482921575404987],
              [-45.6455035865942, -1.496704274085289],
              [-45.653297583088175, -1.499751095892407],
              [-45.67115757475298, -1.502570935761355],
              [-45.680091510079876, -1.503709030575578],
              [-45.71594258637526, -1.498916090697321],
              [-45.72455549481026, -1.496742495563808],
              [-45.74769558256631, -1.482587472744058],
              [-45.75247159100013, -1.475196142879042],
              [-45.7602845305953, -1.458903231833347],
              [-45.76306950174697, -1.450301722786719],
              [-45.76523555316757, -1.441605665872146],
              [-45.76507545881677, -1.423527744724595],
              [-45.76474353545072, -1.414506469966511],
              [-45.75993349648999, -1.378673163305905],
              [-45.75917057566224, -1.369684075056057],
              [-45.758834461344605, -1.333532758988611],
              [-45.76262660198276, -1.326059118834337],
              [-45.768959465113824, -1.319607735592228],
              [-45.775371621049004, -1.313889098326399],
              [-45.79051252332118, -1.323773373836218],
              [-45.79495962587407, -1.331525796090432],
              [-45.799079498926716, -1.339573261336682],
              [-45.80341746910017, -1.347488460150657],
              [-45.8136864739661, -1.3623582916739],
              [-45.8330495085097, -1.381357886895501],
              [-45.84064451862213, -1.386076730749608],
              [-45.856872553737134, -1.393955216827635],
              [-45.86109552419893, -1.376369481034999],
              [-45.86608560644004, -1.36956404620247],
              [-45.873561593527256, -1.364476398608303],
              [-45.87994759792451, -1.358366661739979],
              [-45.89368855472699, -1.334973608145845],
              [-45.90353360292991, -1.3198035368506],
              [-45.910739525096545, -1.314740867327885],
              [-45.918788499085366, -1.310619485532698],
              [-45.92689162016873, -1.306696587204897],
              [-45.93593552606538, -1.306608577221482],
              [-45.94429748031905, -1.304905877408828],
              [-45.95696253602895, -1.291989699879537],
              [-45.96126161417163, -1.284182292340461],
              [-45.97170261569087, -1.259138171457323],
              [-45.976020469296714, -1.252286636157294],
              [-46.00284155365472, -1.256477252472791],
              [-46.01152051148679, -1.258834076009805],
              [-46.02838858872889, -1.265383862795261],
              [-46.03625852526571, -1.26953743109874],
              [-46.049186605097475, -1.282200475151853],
              [-46.057258545501014, -1.285874263317055],
              [-46.0831034734143, -1.294197493178046],
              [-46.118984556923095, -1.299016081680008],
              [-46.14582860769582, -1.303149030501629],
              [-46.15472448918226, -1.304135748144347],
              [-46.179836503481226, -1.293791473787849],
              [-46.179397459392476, -1.285689358532807],
              [-46.17566248333404, -1.267975379621475],
              [-46.17522846838719, -1.258961313300176],
              [-46.175037528632686, -1.240860593375942],
              [-46.1766964749107, -1.232870125071315],
              [-46.184753495526564, -1.228736338059434],
              [-46.19214650177227, -1.23291806955757],
              [-46.206745597822646, -1.243628297807049],
              [-46.21125053550753, -1.251366638463878],
              [-46.223655584580655, -1.275527977303796],
              [-46.22929358793786, -1.28257648732864],
              [-46.24690262542134, -1.303259839261671],
              [-46.253284606505076, -1.309415508959489],
              [-46.30455050566866, -1.327451185314999],
              [-46.31204660932349, -1.328336984843418],
              [-46.31481951053456, -1.319716532695566],
              [-46.315422504649575, -1.311356422460562],
              [-46.30119757675729, -1.288208120439378],
              [-46.29841260560562, -1.279658746830535],
              [-46.29611948453274, -1.270897311071167],
              [-46.293224542811316, -1.262367886391871],
              [-46.28081547042464, -1.238202691876495],
              [-46.27732457538687, -1.229909469228971],
              [-46.267024557479175, -1.185827531645657],
              [-46.269351541440926, -1.177373208818892],
              [-46.27978147884795, -1.152294051580441],
              [-46.28455748728177, -1.145857588126034],
              [-46.33889350994332, -1.145254761649085],
              [-46.34789248883891, -1.144942787212585],
              [-46.39510355851854, -1.118021790568662],
              [-46.435363515888326, -1.09726819828694],
              [-46.44376754729598, -1.094001602978381],
              [-46.49604748910667, -1.079093549976619],
              [-46.50448957435478, -1.075839024608626],
              [-46.56275553614282, -1.050756849885033],
              [-46.57175853835193, -1.0503647444541],
              [-46.68960558253747, -1.048948873368374],
              [-46.69812059034325, -1.046620045387954],
              [-46.71424452222075, -1.038311567676601],
              [-46.73744160691845, -1.024111785493858],
              [-46.744956486036415, -1.019059174255005],
              [-46.75933446748084, -1.008001097023396],
              [-46.76500348387981, -1.001336478164319],
              [-46.77153047188861, -0.984415594932159],
              [-46.774242520482574, -0.975857504144017],
              [-46.77412752077089, -0.966791134746813],
              [-46.775253545644546, -0.957945712317553],
              [-46.784709505901674, -0.933190431818048],
              [-46.78888352604889, -0.915542837579949],
              [-46.79491061735183, -0.910431385380718],
              [-46.798404529874745, -0.909384318035052],
              [-46.82097246891951, -0.902622972013262],
              [-46.82973859854468, -0.90084885838553],
              [-46.874877494119346, -0.905273497437861],
              [-46.929145623365116, -0.909529324960147],
              [-46.938167568675425, -0.910426356238816],
              [-46.982772537017354, -0.918740701282388],
              [-46.99170647234425, -0.920230668392264],
              [-47.02801151251603, -0.919753570463058],
              [-47.03705960936429, -0.920029502715863],
              [-47.06844346653915, -0.92408818787527],
              [-47.07710649875514, -0.9265410680228],
              [-47.11067149477634, -0.94039987438407],
              [-47.13743256470755, -0.945384759845183],
              [-47.146026530041354, -0.942654941616468],
              [-47.16317758033492, -0.936683170874517],
              [-47.17153551127507, -0.933292691036911],
              [-47.18592053351816, -0.922204271315763],
              [-47.19190957098064, -0.916077938279159],
              [-47.19153959377414, -0.88884077624715],
              [-47.18854859544223, -0.88067261159506],
              [-47.17312958162245, -0.858218163519382],
              [-47.17045961018246, -0.850641593593991],
              [-47.18415446651744, -0.827097330466415],
              [-47.19216555430387, -0.825143676472464],
              [-47.210323606445854, -0.824893560481485],
              [-47.21928754898616, -0.826140620037108],
              [-47.237144523165824, -0.829454321641549],
              [-47.24545652127651, -0.826932374611772],
              [-47.25352460600459, -0.822758689740681],
              [-47.261379622753736, -0.8184081467125],
              [-47.264133580863614, -0.809754837504158],
              [-47.26646458813889, -0.801037322917466],
              [-47.26607851767827, -0.773797646314449],
              [-47.26910756985063, -0.765775997330024],
              [-47.28582762268249, -0.744263675172135],
              [-47.29022946295822, -0.736367419459327],
              [-47.29417448951045, -0.728187855418923],
              [-47.29833560152673, -0.720348596647796],
              [-47.3164975093442, -0.720087584182636],
              [-47.32530957179881, -0.720372568890866],
              [-47.335792482833966, -0.745521463563819],
              [-47.33315251860736, -0.753737907978234],
              [-47.32920447456999, -0.761919316037336],
              [-47.32559556233542, -0.770209856475901],
              [-47.32228454293997, -0.788071524521342],
              [-47.32079658748688, -0.797015685770134],
              [-47.32092650698624, -0.806097142593046],
              [-47.321708538553224, -0.815089415966099],
              [-47.331012618724685, -0.850210931408412],
              [-47.33200855646092, -0.859063059360267],
              [-47.32789957988243, -0.886005010762176],
              [-47.32760252523363, -0.895058472028438],
              [-47.32822446244981, -0.940475982064925],
              [-47.32704546394805, -0.94944931233681],
              [-47.31873648332251, -0.994108092497186],
              [-47.31313351632065, -1.030013148249054],
              [-47.28002952497451, -1.044855319491774],
              [-47.27289149622368, -1.043932136675153],
              [-47.26179855027482, -1.029541414737878],
              [-47.255836502540575, -1.022715863337737],
              [-47.24932862527103, -1.016378306340869],
              [-47.2418325216162, -1.011343800012867],
              [-47.22612751725981, -1.002204508058014],
              [-47.22106149497586, -1.000815459062437],
              [-47.23140761335108, -1.025989667083024],
              [-47.22641350779642, -1.052776720914096],
              [-47.210460566743876, -1.074836046073756],
              [-47.17878351059568, -1.107378449906548],
              [-47.17258459027747, -1.113976181178202],
              [-47.162788492389154, -1.139400002275579],
              [-47.16955956141865, -1.142270804115867],
              [-47.18742357639698, -1.145606801582801],
              [-47.1963615350374, -1.144753859114871],
              [-47.21424449311692, -1.141540069796349],
              [-47.22320960912367, -1.14012503690094],
              [-47.259551529669466, -1.139661182378802],
              [-47.26822260851253, -1.141133044577771],
              [-47.29177860630455, -1.154854890640991],
              [-47.29550553573594, -1.162826248206329],
              [-47.31341162786825, -1.214327678849372],
              [-47.31803860606348, -1.220937982975784],
              [-47.344993465596275, -1.225080822443147],
              [-47.34822854494891, -1.219842971143919],
              [-47.3477635169603, -1.183490992314319],
              [-47.34669851234182, -1.174475584888455],
              [-47.341766600508805, -1.138466090622899],
              [-47.346767579224064, -1.111665122832562],
              [-47.352935486500485, -1.105065715180217],
              [-47.365619485311555, -1.092045602051485],
              [-47.369903476028526, -1.084300220595992],
              [-47.38921756025755, -1.023686655364372],
              [-47.394203619185134, -0.996883843555338],
              [-47.39317650076907, -0.915116031069829],
              [-47.39543961462854, -0.906980555840164],
              [-47.40811154349905, -0.893953569550831],
              [-47.41112148493215, -0.900122985569851],
              [-47.420429588417136, -0.935261264818507],
              [-47.426490542608946, -0.941863019403741],
              [-47.43952155221183, -0.954536959930977],
              [-47.44712058563775, -0.959065534582805],
              [-47.45578361785377, -0.96182234253979],
              [-47.463024576375716, -0.958906613698446],
              [-47.475704551873264, -0.94587560409559],
              [-47.480094489846465, -0.938851233951937],
              [-47.47544052192299, -0.921277568099867],
              [-47.47477751338121, -0.912263166502385],
              [-47.47401056923994, -0.857737209914717],
              [-47.478237563015256, -0.850899588573952],
              [-47.4857294757185, -0.845752094191084],
              [-47.49274060245517, -0.840038486067158],
              [-47.50541353715403, -0.8270024473224],
              [-47.51288248344258, -0.830474399258947],
              [-47.52051554739543, -0.835407819834529],
              [-47.52611952022568, -0.842335295177463],
              [-47.53569752099318, -0.857789345352501],
              [-47.540351488916656, -0.865592561939991],
              [-47.54871361080839, -0.88173845440383],
              [-47.55556849886972, -0.885342169858461],
              [-47.57375354073997, -0.885084174878443],
              [-47.582679597077856, -0.883741226350423],
              [-47.591472548793206, -0.881410386713242],
              [-47.5997615804892, -0.878556516317417],
              [-47.61065654588484, -0.843859125176152],
              [-47.620143519183785, -0.808747835655709],
              [-47.626403627395206, -0.814132370260609],
              [-47.63293061540401, -0.820468083238779],
              [-47.63691352815863, -0.827897299306244],
              [-47.637699583039165, -0.882452257278942],
              [-47.63570753992863, -0.891264822647713],
              [-47.60831447449738, -0.987475492508281],
              [-47.60691050571418, -0.996310689015672],
              [-47.61206051466803, -1.041491494106253],
              [-47.61537555737701, -1.04849692114874],
              [-47.633087524631605, -1.052665576877928],
              [-47.63660859452082, -1.045804989122416],
              [-47.64374159412978, -1.01946603183552],
              [-47.64653058859497, -1.010821607444541],
              [-47.6530495299767, -0.993837859938537],
              [-47.65705457095572, -0.985820569543762],
              [-47.682411504465676, -0.959726531467879],
              [-47.710761615602394, -0.913088113413664],
              [-47.730407623197465, -0.882480252835535],
              [-47.73989459649641, -0.847352031870685],
              [-47.74177549557072, -0.838458497317106],
              [-47.75003854336683, -0.793734841226126],
              [-47.75301361608271, -0.785220503972596],
              [-47.75752660039461, -0.777421310698628],
              [-47.76386248101085, -0.770894825604046],
              [-47.77120955442709, -0.770063843722426],
              [-47.779472602223194, -0.77386738374895],
              [-47.80496549020279, -0.783623583778137],
              [-47.81363254573233, -0.786381397563446],
              [-47.82231854436307, -0.788684409282098],
              [-47.83990847110732, -0.784006636753588],
              [-47.84760255531535, -0.779532209196304],
              [-47.86866359580583, -0.762160211934827],
              [-47.877273486755655, -0.760097425561469],
              [-47.92247759353711, -0.754884049419502],
              [-47.924686560302035, -0.761629302187202],
              [-47.92482352060003, -0.770729702111339],
              [-47.92578157213387, -0.779715940514109],
              [-47.93046554727076, -0.797306705448648],
              [-47.927745619687784, -0.805834956661499],
              [-47.92123757478018, -0.822836809078353],
              [-47.92224457662863, -0.831398252627821],
              [-47.93728254912969, -0.874354835889733],
              [-47.95449847535389, -0.868410557790185],
              [-47.972095610534865, -0.86373060596685],
              [-47.977798489822675, -0.857644338427463],
              [-47.98964647761025, -0.83303590887175],
              [-47.9944685865116, -0.825354565156545],
              [-48.00517646782819, -0.810625884882938],
              [-48.01158158296471, -0.80424826238891],
              [-48.03264647913065, -0.786860507149413],
              [-48.03912753431001, -0.780477017323108],
              [-48.05180750980756, -0.767404936394655],
              [-48.058856522746595, -0.761823259426876],
              [-48.08310352463704, -0.749231461550778],
              [-48.09069048812239, -0.744314804781595],
              [-48.11115658112493, -0.726215090685741],
              [-48.11697747760957, -0.730347871869242],
              [-48.13031358848832, -0.746168714127634],
              [-48.132747525534654, -0.751839071631139],
              [-48.131664583643385, -0.756427660709733],
              [-48.13640253823675, -0.760031543802427],
              [-48.13969746437809, -0.762783322617508],
              [-48.14392848146693, -0.775581315311854],
              [-48.14846459983164, -0.778765097416965],
              [-48.15861558750075, -0.78138075412437],
              [-48.16127751231369, -0.784962676630698],
              [-48.15923651888855, -0.801878530720899],
              [-48.162425497773654, -0.815083548633879],
              [-48.15990857988578, -0.81777631885052],
              [-48.16814447031558, -0.824760791135077],
              [-48.17929860415765, -0.830941271266283],
              [-48.185115477328765, -0.830791235199285],
              [-48.193031514333086, -0.828364506589651],
              [-48.21338646329943, -0.828366518246412],
              [-48.22538348132559, -0.833299938822051],
              [-48.22678761774685, -0.835002973910775],
              [-48.20074856495586, -0.87343886150984],
              [-48.19618646269126, -0.881313324274402],
              [-48.174667602648924, -0.92146448454082],
              [-48.16981850401933, -0.929153707245007],
              [-48.143032623654705, -0.966001224190848],
              [-48.13715355676197, -0.972932555209184],
              [-48.111762592725086, -0.999076549428082],
              [-48.108306566404565, -1.006759904799992],
              [-48.10844050921742, -1.015868518989237],
              [-48.109027577716375, -1.024594750755284],
              [-48.13590951469152, -1.0295622018578],
              [-48.16827053413945, -1.012796551472604],
              [-48.18329660433801, -1.002482451967524],
              [-48.20497555873115, -0.985829621999187],
              [-48.21359651379319, -0.983286720211481],
              [-48.23141861925561, -0.979445126344501],
              [-48.24048247408186, -0.978909019816911],
              [-48.267826589198506, -0.978499144751254],
              [-48.27659657449914, -0.976543311462478],
              [-48.31067253133838, -0.963568292972866],
              [-48.31612060076941, -0.981863808327091],
              [-48.313354572718936, -0.988319382520729],
              [-48.30684250449781, -0.995571740431046],
              [-48.30846457040184, -0.999770738649715],
              [-48.303146588108206, -1.012056597059598],
              [-48.30217361678669, -1.026057728136891],
              [-48.2984124891903, -1.028319500891826],
              [-48.299610598431315, -1.033141945069303],
              [-48.29892747332187, -1.039408591165227],
              [-48.29681355733905, -1.050096691190333],
              [-48.26708546131903, -1.054260485415625],
              [-48.25844556315582, -1.056751251765547],
              [-48.23416553656682, -1.069337517585495],
              [-48.22707746395895, -1.074739989463239],
              [-48.21030862845052, -1.096337304119402],
              [-48.21010561875545, -1.104128786042395],
              [-48.23345961268072, -1.153582014823598],
              [-48.23884951170368, -1.160269432459302],
              [-48.28077646197855, -1.178180218457385],
              [-48.28859761583885, -1.18270275813893],
              [-48.31695560596452, -1.205644027151493],
              [-48.32043057538624, -1.213721499611154],
              [-48.32978846737615, -1.248976957866319],
              [-48.33123753079846, -1.257970237067752],
              [-48.336276563354176, -1.294098419082388],
              [-48.3536566072427, -1.315227855902833],
              [-48.35998159138478, -1.320919503440393],
              [-48.39612955469093, -1.315883991284068],
              [-48.405067513331346, -1.314164024750824],
              [-48.44001753503454, -1.303641718770734],
              [-48.48339455456011, -1.289469764506521],
              [-48.491920626478105, -1.31682678775411],
              [-48.49072654055064, -1.33271267358117],
              [-48.49488061176825, -1.375429366773972],
              [-48.49962661298866, -1.400163692515491],
              [-48.500457594870255, -1.420700025866665],
              [-48.507163620331056, -1.437057980481029],
              [-48.507934587785826, -1.443341558021416],
              [-48.5053824659046, -1.47051334120863],
              [-48.50118246185755, -1.474865057703312],
              [-48.49555552261256, -1.476481759189255],
              [-48.47481953232753, -1.471463346115399],
              [-48.458148597448286, -1.475480121758892],
              [-48.43175851055196, -1.474798170115889],
              [-48.40404559169525, -1.460500990218179],
              [-48.39574851337221, -1.458744310948987],
              [-48.38680653141827, -1.459072211001569],
              [-48.37873056770121, -1.456060425549765],
              [-48.37311150744523, -1.45663156843267],
              [-48.3693505474869, -1.458685134712539],
              [-48.34920849886143, -1.456598376096053],
              [-48.33634948591188, -1.453539484348369],
              [-48.32434458889671, -1.448396013278966],
              [-48.31956053383581, -1.448764984657089],
              [-48.31714252240553, -1.462130096920873],
              [-48.31873357526777, -1.478083876163737],
              [-48.33025349536476, -1.4815105658231],
              [-48.33703948418193, -1.488694024489234],
              [-48.36413147165081, -1.502776795303532],
              [-48.374027481825095, -1.510828451501311],
              [-48.38275153429626, -1.511752137232122],
              [-48.39744953680423, -1.517758776691323],
              [-48.409728522053484, -1.516208795154682],
              [-48.41638559719971, -1.51585792868741],
              [-48.424266597848714, -1.517611758109467],
              [-48.43500850969224, -1.524414343094918],
              [-48.44599953169836, -1.527245247076053],
              [-48.448890617744325, -1.529366874409789],
              [-48.45408655952764, -1.529417836381128],
              [-48.46028547984582, -1.533037645089848],
              [-48.46340555948677, -1.532858607637877],
              [-48.46197862428886, -1.530125939562083],
              [-48.46410762769739, -1.525124122656507],
              [-48.469131572827365, -1.521408424975334],
              [-48.47518146290699, -1.519377825110212],
              [-48.481899558308356, -1.512541377235891],
              [-48.488235606562625, -1.50214194995641],
              [-48.49052051337037, -1.502374128674603],
              [-48.490913624629684, -1.50488970545797],
              [-48.49002849565352, -1.510320508168434],
              [-48.492340559827596, -1.528963872504733],
              [-48.48771659911753, -1.534148750175859],
              [-48.479083574114895, -1.545150836294169],
              [-48.48569454879359, -1.549610511701871],
              [-48.493167518395666, -1.550731507433568],
              [-48.49585358308977, -1.552432195589461],
              [-48.49837854760469, -1.549319324385237],
              [-48.50171253341486, -1.548305617014307],
              [-48.50778958086076, -1.543554921928092],
              [-48.511779534414046, -1.539201361414712],
              [-48.515357601244915, -1.534842436483302],
              [-48.51819252853957, -1.521480509342723],
              [-48.52256352341155, -1.507910543365199],
              [-48.517860605173496, -1.509630509898386],
              [-48.50977357734425, -1.49937508371562],
              [-48.517425584398296, -1.451144774608906],
              [-48.51715853696288, -1.415606510940279],
              [-48.52040853610316, -1.391503342508486],
              [-48.523796501369816, -1.383441795664908],
              [-48.53344356901954, -1.379487213743062],
              [-48.523281517238246, -1.361487411933126],
              [-48.524803503218266, -1.354599163897149],
              [-48.52279654032006, -1.347257622537029],
              [-48.52429153657181, -1.343088799169777],
              [-48.52351352831835, -1.337643244309675],
              [-48.52563448509983, -1.333270740695127],
              [-48.533828466013716, -1.324357257212],
              [-48.53303151465906, -1.320792601426206],
              [-48.534744608031644, -1.315369845342786],
              [-48.53726957254656, -1.312258315243071],
              [-48.54164559656047, -1.311255169070137],
              [-48.55395157153799, -1.306566667705567],
              [-48.55899060409371, -1.301386986814407],
              [-48.56315657761385, -1.300382164260839],
              [-48.56668854397719, -1.300417032978032],
              [-48.57392447335724, -1.304462810006555],
              [-48.580562605402235, -1.305782792119828],
              [-48.58738748625018, -1.309198585305069],
              [-48.59762162239886, -1.303650938864223],
              [-48.60113548385132, -1.305777595339862],
              [-48.60296257110721, -1.310187314604605],
              [-48.60114654796354, -1.326278054145462],
              [-48.60231749983825, -1.334028632380921],
              [-48.61351052571109, -1.35882381073958],
              [-48.61302957210643, -1.365513072393981],
              [-48.60450752350195, -1.386977450065672],
              [-48.598278595970356, -1.386706714592833],
              [-48.59345246375551, -1.391261440782614],
              [-48.59382646427554, -1.395657078449972],
              [-48.59004253790249, -1.400013656448493],
              [-48.58720056980917, -1.41441963344954],
              [-48.59163258493635, -1.428899371198611],
              [-48.597633524701365, -1.431678139741905],
              [-48.605960610237815, -1.430504338020114],
              [-48.61119058254803, -1.426999529023078],
              [-48.62382546340646, -1.409970016325701],
              [-48.63321654773296, -1.384329774488151],
              [-48.63573447144921, -1.381844037280132],
              [-48.642620540190364, -1.379192170750969],
              [-48.645153551332356, -1.37503340566758],
              [-48.648940495190544, -1.370050867139355],
              [-48.65248453149448, -1.369037997958685],
              [-48.656001578070175, -1.37053768807624],
              [-48.65800854096838, -1.378297318767125],
              [-48.662784549402204, -1.378763184946081],
              [-48.66484046261499, -1.380874083443757],
              [-48.66537858079934, -1.390085459765999],
              [-48.67379350868114, -1.401045468730331],
              [-48.68239552064199, -1.414517701716818],
              [-48.68900247200713, -1.418975365467702],
              [-48.693675550669866, -1.43010938274216],
              [-48.70005049095491, -1.437701878283576],
              [-48.708709499857406, -1.444900424375419],
              [-48.70907562138845, -1.450132743618553],
              [-48.70593257533278, -1.452822831626179],
              [-48.70628360943812, -1.459520307545745],
              [-48.70706547336704, -1.464758661759106],
              [-48.70572252483902, -1.474996988859402],
              [-48.70691661076651, -1.480448578689789],
              [-48.711433618391965, -1.486559321386494],
              [-48.7267646222283, -1.491728776355728],
              [-48.73416852494813, -1.499960140557789],
              [-48.736392579138794, -1.506675553750142],
              [-48.73749949327316, -1.521749568434927],
              [-48.741188536502165, -1.527017259309446],
              [-48.76118054906067, -1.545202636455826],
              [-48.7641755707061, -1.55841268351071],
              [-48.76724653239438, -1.563254573703546],
              [-48.77157947342593, -1.567063310510036],
              [-48.780277541997265, -1.57028497881754],
              [-48.79692450463338, -1.590740510622027],
              [-48.796241547162, -1.597218883592404],
              [-48.79950361624287, -1.603736316231561],
              [-48.80342047960039, -1.607542203190917],
              [-48.81233262197901, -1.610345949805719],
              [-48.81851561668117, -1.61605637280644],
              [-48.82546656135298, -1.628676669153322],
              [-48.82957855541662, -1.633528114715773],
              [-48.839088495130255, -1.639267706739531],
              [-48.84863648868438, -1.641033606102155],
              [-48.85338953070345, -1.644008343541941],
              [-48.85562850468176, -1.649050896496931],
              [-48.855174540805365, -1.653231622166686],
              [-48.85467950560337, -1.661805135656721],
              [-48.86026352950404, -1.664997802579194],
              [-48.868946510649636, -1.670520303310525],
              [-48.877838536460615, -1.675627061643979],
              [-48.88566555765314, -1.683233638782781],
              [-48.88705846232412, -1.689942011176413],
              [-48.88385758113651, -1.698909306478015],
              [-48.883586510387545, -1.705602759084002],
              [-48.885391469419034, -1.712944300444178],
              [-48.89543550400353, -1.72852424680508],
              [-48.89906352697736, -1.740695273141341],
              [-48.89841460003291, -1.743199953450528],
              [-48.90464051007936, -1.744932828114656],
              [-48.90776846870929, -1.743706890955082],
              [-48.91233861760094, -1.744377778485841],
              [-48.92020453082421, -1.748009657135128],
              [-48.92221451120756, -1.748285757025997],
              [-48.92178351374588, -1.742466201645868],
              [-48.9239195579531, -1.721796428395976],
              [-48.91106456831707, -1.686323208296074],
              [-48.89945261492312, -1.662310061504456],
              [-48.88904547629275, -1.648613193512745],
              [-48.8843765885816, -1.63492822782348],
              [-48.88544461068523, -1.62684757024067],
              [-48.88058461558151, -1.589014509118556],
              [-48.88854960290041, -1.582075131473118],
              [-48.89656052304878, -1.583162096677938],
              [-48.9034496092751, -1.585403920503325],
              [-48.91152154967864, -1.593373098773839],
              [-48.92072253244095, -1.599054688027593],
              [-48.92645256909506, -1.600158752314883],
              [-48.94240551014764, -1.589707357235682],
              [-48.95497149176188, -1.587322705780025],
              [-48.96442460217196, -1.595867888437283],
              [-48.97111554020702, -1.593441159827705],
              [-48.97318654084552, -1.594089080943775],
              [-48.986122499666294, -1.611785960772636],
              [-49.00283852918466, -1.62533296033547],
              [-49.01311859816278, -1.637773883954196],
              [-49.014537486733644, -1.641972714534802],
              [-49.0103185395854, -1.648420074463388],
              [-49.01148949146008, -1.656799295437622],
              [-49.01038760646762, -1.663902790747329],
              [-49.01241652995216, -1.669153550177441],
              [-49.021060619066986, -1.678859794063669],
              [-49.020004499265895, -1.680523769483614],
              [-49.02809152709514, -1.682900541950232],
              [-49.03244357886595, -1.684615479341517],
              [-49.03614854771101, -1.688626220014726],
              [-49.04929355119717, -1.70653264742316],
              [-49.05194860284951, -1.711789441823555],
              [-49.06000562346537, -1.717722823448923],
              [-49.07070160247946, -1.730169111485736],
              [-49.07121658661103, -1.735549622777114],
              [-49.073478527004056, -1.733902746439696],
              [-49.08838658000579, -1.73608841151372],
              [-49.11252562298321, -1.750841566944644],
              [-49.118438552764815, -1.7771924265341],
              [-49.114005531809255, -1.796743886261254],
              [-49.11865949973273, -1.808179987326412],
              [-49.1371234913409, -1.826416829358323],
              [-49.153758551674514, -1.851613836155593],
              [-49.154331538576116, -1.853092906791289],
              [-49.157684467487485, -1.861756106645373],
              [-49.16180048486464, -1.866398675180506],
              [-49.17030359036792, -1.868987677435769],
              [-49.17403756059801, -1.870276478869187],
              [-49.17815760128869, -1.874708326358302],
              [-49.1791495157114, -1.87974165921986],
              [-49.18366250002333, -1.886896116500964],
              [-49.190036602118056, -1.895116751866965],
              [-49.19252049530738, -1.896185444522814],
              [-49.19803662579227, -1.907327843700443],
              [-49.201637491399794, -1.923475580182981],
              [-49.205387554884, -1.931743992397116],
              [-49.213371485304094, -1.93697564108794],
              [-49.21648050083283, -1.93805036871413],
              [-49.22667658314106, -1.960953583886237],
              [-49.24166862281268, -1.982645949324478],
              [-49.26062362630935, -2.00269965269041],
              [-49.26152753074862, -2.005101403228537],
              [-49.26410261904459, -2.009219096986328],
              [-49.26357657080081, -2.021770158812899],
              [-49.27554659909873, -2.032134382099002],
              [-49.28040659420245, -2.047247121176497],
              [-49.2914505898367, -2.068693561575344],
              [-49.29413648689274, -2.071020377899004],
              [-49.31063056361481, -2.087703215080751],
              [-49.324058540152436, -2.098288552972804],
              [-49.328418470912226, -2.098955417190041],
              [-49.33340050652629, -2.100465333229465],
              [-49.33935149014832, -2.10972750388504],
              [-49.34347555415255, -2.11374025621501],
              [-49.347751498242445, -2.124453501949631],
              [-49.35562562573085, -2.128081189647332],
              [-49.35740258920569, -2.127873318448394],
              [-49.3589555882275, -2.127692436977725],
              [-49.363449629438236, -2.13598180394979],
              [-49.382610492477056, -2.158670107124294],
              [-49.38472759358311, -2.154504133604121],
              [-49.388050515281066, -2.155160101347292],
              [-49.39218162008396, -2.158338016120183],
              [-49.39360855528187, -2.161907701047824],
              [-49.39881153786385, -2.161535879822623],
              [-49.40278657162946, -2.159059027431965],
              [-49.41036649431615, -2.148661779447366],
              [-49.412490468582774, -2.143239023363947],
              [-49.41219358157204, -2.129840215849356],
              [-49.40868759910853, -2.126041537326728],
              [-49.39968862021294, -2.108590750175324],
              [-49.389827478755905, -2.094269933310613],
              [-49.38575756184625, -2.083978632582216],
              [-49.38269062347149, -2.077671920989076],
              [-49.38341850794393, -2.065748831348969],
              [-49.380260542100615, -2.046049514849642],
              [-49.38220262906816, -2.038321903028873],
              [-49.37734246632641, -2.01560878942098],
              [-49.372707609142196, -2.005798442297248],
              [-49.373146485592855, -2.003500459720499],
              [-49.37647661572757, -2.002900650728748],
              [-49.3807215467757, -1.992893496518263],
              [-49.37969962513958, -1.991001365693648],
              [-49.38055457926427, -1.988705730049844],
              [-49.38369360200642, -1.986431719716222],
              [-49.38710051037427, -1.977254541558921],
              [-49.382408488610366, -1.966748161194857],
              [-49.37623957550554, -1.958530878590125],
              [-49.37342057382696, -1.948041764946595],
              [-49.363727573347774, -1.938539536583903],
              [-49.362930621993115, -1.934346573335517],
              [-49.36444456134609, -1.928485276077538],
              [-49.36263256151594, -1.917218992370863],
              [-49.363609556150976, -1.89424972780165],
              [-49.362110536585675, -1.890325153093215],
              [-49.358985595440885, -1.88617829031233],
              [-49.33483850583639, -1.865035945360944],
              [-49.326839487990554, -1.852198892997762],
              [-49.32476848735203, -1.849698068364034],
              [-49.31912260500587, -1.845844236918424],
              [-49.30364257093092, -1.833832131466522],
              [-49.28865053125929, -1.817861420779195],
              [-49.281562626289485, -1.793438398921921],
              [-49.27604649580459, -1.78728859655638],
              [-49.2688214628987, -1.781365273214817],
              [-49.26719252383404, -1.777583358498532],
              [-49.26931348061555, -1.772580703402639],
              [-49.27018352216595, -1.768194285828827],
              [-49.26855860641487, -1.764203326447102],
              [-49.27006550496915, -1.758358960633586],
              [-49.273048624312054, -1.750224491232302],
              [-49.26628157859611, -1.739491296568076],
              [-49.27015250912419, -1.724878454196471],
              [-49.26688356688271, -1.718362865576012],
              [-49.27153049400752, -1.710035612401498],
              [-49.27634857959529, -1.706311868093337],
              [-49.2859265803628, -1.704514788050858],
              [-49.30645754929597, -1.710559313712395],
              [-49.3099864981742, -1.711218466578714],
              [-49.31812247631805, -1.707942483538602],
              [-49.33224849775277, -1.709744425084921],
              [-49.34531756119608, -1.713208330394423],
              [-49.34819456564472, -1.716791091091068],
              [-49.35627354684692, -1.720210739951767],
              [-49.3583415300003, -1.721694504453296],
              [-49.37287558248198, -1.724125256376396],
              [-49.38655853651446, -1.728642096363785],
              [-49.39653752753023, -1.728730106347257],
              [-49.41291861619737, -1.734316644818875],
              [-49.42246660975147, -1.736074665192518],
              [-49.44566352681113, -1.746532765794257],
              [-49.455417547545466, -1.748710551879356],
              [-49.46036957594612, -1.753148266700691],
              [-49.467410542258136, -1.756350153716653],
              [-49.480884619263264, -1.761490774938977],
              [-49.484649602535114, -1.758592983370477],
              [-49.50102247693715, -1.765015365227498],
              [-49.531455491014896, -1.775806395023722],
              [-49.54620361730386, -1.78277611515864],
              [-49.554908559035795, -1.786199787332862],
              [-49.559478540289376, -1.786447891667081],
              [-49.58322950114942, -1.781005186654056],
              [-49.591136485698314, -1.780654320186841],
              [-49.615806606061454, -1.789446601349937],
              [-49.63798160148497, -1.798215748460223],
              [-49.64480262665745, -1.803296355255725],
              [-49.65346549123541, -1.811741793265128],
              [-49.65547161594327, -1.820548323663616],
              [-49.667633589824106, -1.833417394896969],
              [-49.67074159952446, -1.834490278504461],
              [-49.675678540499376, -1.841227819921187],
              [-49.678127564971476, -1.846690138587689],
              [-49.67644447881227, -1.848977057052196],
              [-49.68014559198187, -1.854240724613192],
              [-49.68717147086812, -1.859114466038136],
              [-49.68527548436808, -1.862236054421658],
              [-49.688976597537675, -1.867499721982654],
              [-49.702896591792154, -1.869082728217847],
              [-49.71596548759743, -1.873170414762228],
              [-49.721343484317856, -1.876354196867396],
              [-49.72608562986281, -1.881417704580372],
              [-49.734180536681066, -1.883159631699925],
              [-49.738094550191505, -1.887798344559599],
              [-49.744796552338784, -1.889178005823567],
              [-49.7928696169418, -1.888823116042772],
              [-49.81811557405359, -1.896673606564207],
              [-49.821399603720806, -1.899919247114838],
              [-49.8230595558272, -1.899183483653417],
              [-49.83053956622794, -1.899874152475718],
              [-49.83532747696427, -1.899704335117178],
              [-49.84422654357394, -1.904591990501444],
              [-49.866188471018546, -1.889288982221728],
              [-49.87059047893234, -1.885349487725591],
              [-49.875461538148244, -1.874508166510282],
              [-49.87921562494597, -1.873283402817151],
              [-49.88604352327903, -1.877315936438606],
              [-49.893352542854814, -1.873400414185596],
              [-49.89739262018915, -1.862551046343242],
              [-49.90095157628076, -1.859860287783363],
              [-49.91450159332874, -1.855577470532864],
              [-49.92452651717397, -1.850427964493178],
              [-49.93103757956672, -1.842320484820164],
              [-49.937705551187065, -1.840281670689876],
              [-49.94441962327491, -1.833014225353907],
              [-49.94963450815939, -1.831173391776758],
              [-49.96382557316281, -1.832492368061708],
              [-49.97553257733887, -1.82310597760096],
              [-49.993835468767855, -1.820672040554598],
              [-50.008727596153534, -1.822855023419663],
              [-50.02362056172953, -1.823888847358262],
              [-50.03396651246669, -1.829554510995933],
              [-50.05001852761487, -1.83172827376751],
              [-50.069473595455435, -1.832728402455302],
              [-50.079944604188086, -1.839896773695727],
              [-50.09764450150209, -1.837813032564327],
              [-50.106315580345154, -1.845835352101062],
              [-50.14421049991276, -1.861481012582885],
              [-50.14653061071388, -1.861851157427452],
              [-50.1579705674545, -1.861942184896009],
              [-50.1872596193858, -1.866359950787796],
              [-50.20075649516758, -1.868767568658143],
              [-50.20260654883819, -1.8717114606942],
              [-50.22009253998294, -1.869547420930303],
              [-50.23052549487514, -1.864816842411756],
              [-50.23636248461389, -1.862978188129489],
              [-50.2567295035208, -1.864602936242534],
              [-50.259826616747006, -1.867765595951653],
              [-50.261043501451155, -1.871752532019798],
              [-50.26438151057485, -1.870523577375025],
              [-50.274269474122065, -1.882737519055638],
              [-50.28565561904426, -1.889312954464799],
              [-50.29645955697134, -1.891070807200379],
              [-50.303298519416614, -1.894263641760972],
              [-50.31449154528946, -1.901897208627986],
              [-50.32760654156223, -1.90315700867643],
              [-50.34601554788554, -1.914504261567856],
              [-50.36042353654335, -1.937479393469289],
              [-50.365566504698535, -1.938063444483078],
              [-50.381366559837005, -1.938603406686127],
              [-50.39241759626995, -1.934920733703564],
              [-50.40115757435717, -1.934360654932846],
              [-50.40429659709929, -1.931663861402683],
              [-50.40707754493744, -1.921221854055034],
              [-50.40511349738355, -1.906137948724563],
              [-50.40578857586593, -1.899029256634833],
              [-50.40084459409232, -1.892921699061333],
              [-50.399421514569866, -1.888516003110169],
              [-50.40113460794245, -1.882042659281694],
              [-50.40448753685382, -1.878510860556446],
              [-50.40681854412907, -1.872670350418332],
              [-50.418369477267845, -1.857481503659983],
              [-50.416305517428015, -1.855372449181004],
              [-50.418617581602064, -1.852250693159419],
              [-50.41240357385814, -1.848856190008291],
              [-50.416797535144866, -1.845121381587887],
              [-50.41516457276671, -1.840924730302106],
              [-50.41309357212819, -1.839653698503412],
              [-50.40807348267367, -1.843799387817853],
              [-50.402667490396595, -1.84354943946488],
              [-50.397247584160255, -1.845392452336853],
              [-50.39393254145128, -1.843692434733214],
              [-50.40041359663064, -1.839347926675259],
              [-50.39691146984259, -1.834507042310804],
              [-50.37672449421606, -1.836863865847874],
              [-50.36905656154596, -1.833038197596977],
              [-50.35637256273489, -1.832940129329756],
              [-50.34767549999194, -1.828269565237974],
              [-50.325244521245196, -1.824330070741837],
              [-50.318817613160405, -1.822187991564306],
              [-50.30887952583214, -1.816042715426477],
              [-50.29579151928763, -1.814684511834685],
              [-50.28918054460894, -1.808565219596744],
              [-50.29376561328826, -1.807345317407453],
              [-50.28528262435262, -1.801838574654084],
              [-50.28219959272374, -1.797211093544718],
              [-50.27352147308201, -1.789819428403518],
              [-50.26817650106011, -1.78182611025187],
              [-50.25616053993272, -1.775872612058834],
              [-50.25428047904873, -1.77732335186181],
              [-50.24991652497539, -1.777079438479177],
              [-50.24847751983691, -1.774348782060144],
              [-50.22290450086288, -1.774148622212124],
              [-50.20787055167534, -1.782400102981853],
              [-50.20537157106028, -1.78279807574495],
              [-50.20247260602531, -1.780891025132746],
              [-50.19974848749075, -1.783799880813433],
              [-50.191833623952874, -1.785411888433657],
              [-50.186431487351285, -1.784949710292096],
              [-50.18600853651665, -1.785992921962304],
              [-50.17851662381341, -1.786770762577703],
              [-50.17231753585713, -1.78148999593418],
              [-50.16482947882935, -1.782059127160323],
              [-50.15818749110889, -1.780331281638155],
              [-50.142085519817726, -1.765138411566227],
              [-50.137309511383904, -1.76447255317737],
              [-50.12335246911735, -1.767709141272576],
              [-50.09418847518157, -1.748224569132788],
              [-50.07593151658202, -1.742845901860107],
              [-50.07305149464824, -1.739057449259292],
              [-50.06849257750687, -1.736926601832067],
              [-50.06541859833345, -1.731253897395732],
              [-50.06337358159479, -1.726843339940672],
              [-50.05263552542675, -1.717339938111536],
              [-50.04744360695696, -1.716671062237481],
              [-50.03475960814589, -1.716566958999977],
              [-50.01717756039065, -1.731490769979644],
              [-50.014171474633, -1.743603122993648],
              [-50.011436627262384, -1.74755653144905],
              [-50.00684351195602, -1.750029360526185],
              [-49.9987294943985, -1.750591450953664],
              [-49.993755505411485, -1.748249547204239],
              [-49.98283053280244, -1.747901530584102],
              [-49.96924547939918, -1.749611438833483],
              [-49.92124952830545, -1.761474681684831],
              [-49.916606624494165, -1.762216480116592],
              [-49.897273597163945, -1.77403362250044],
              [-49.89223054129471, -1.78089706010303],
              [-49.88391150238533, -1.781038043714545],
              [-49.86495951637383, -1.784437743645697],
              [-49.85266158802335, -1.788311691658919],
              [-49.84468855407741, -1.797034067749451],
              [-49.833305594278414, -1.815982700999712],
              [-49.80289856410053, -1.821587009106111],
              [-49.79341846396224, -1.811253966499862],
              [-49.779914547381765, -1.809676995234952],
              [-49.7704046076681, -1.802899388320952],
              [-49.74287458193885, -1.787600738630886],
              [-49.736270480420785, -1.781477255441359],
              [-49.734641541356154, -1.777068542005054],
              [-49.723293617912475, -1.766509188012833],
              [-49.713573627705046, -1.760149670429655],
              [-49.70195748335951, -1.756703031840686],
              [-49.69592251306756, -1.75727903622743],
              [-49.691745475435226, -1.759545670486261],
              [-49.686988577740664, -1.781477255441359],
              [-49.6797025245796, -1.782251072743236],
              [-49.6755595174742, -1.780333293294916],
              [-49.67144350009701, -1.775275485276097],
              [-49.663661573543635, -1.761189696976601],
              [-49.655151594879726, -1.759442908353208],
              [-49.6514585283372, -1.753551100967684],
              [-49.64688854708362, -1.748741732559154],
              [-49.625709489396314, -1.732707822321856],
              [-49.606395572805354, -1.717779988028667],
              [-49.595420476415285, -1.71624291462291],
              [-49.56895847278963, -1.72291842995611],
              [-49.55876557560461, -1.723667436824542],
              [-49.549240548465235, -1.718771902451351],
              [-49.530673627085946, -1.721189578605504],
              [-49.50333353528282, -1.737831512099717],
              [-49.49959151842569, -1.737798319763101],
              [-49.48077749854059, -1.725289335090508],
              [-49.466323577053316, -1.713444197150011],
              [-49.46262749302562, -1.708390747720841],
              [-49.4622345494044, -1.705665958634029],
              [-49.46603356320318, -1.699003351431713],
              [-49.47033347953618, -1.682510615814181],
              [-49.473079558657076, -1.677721866887509],
              [-49.466915507056115, -1.669089680075217],
              [-49.4556886182944, -1.669200656473379],
              [-49.45109550298807, -1.671042328240787],
              [-49.44760561377868, -1.665990722830315],
              [-49.44306547210044, -1.662183997680586],
              [-49.442474547926025, -1.65841247652429],
              [-49.45112249271631, -1.644052264714617],
              [-49.44785355047483, -1.637326960876464],
              [-49.43641661121936, -1.637644802645184],
              [-49.42415254575775, -1.628899460139905],
              [-49.4074475803516, -1.632148285813741],
              [-49.37087247525926, -1.638162971899987],
              [-49.35024259986852, -1.636024077845661],
              [-49.339881561705624, -1.629215625527991],
              [-49.33637960255567, -1.618901358384846],
              [-49.33861555904883, -1.613145673106999],
              [-49.340866602967736, -1.608540990774259],
              [-49.34743449466407, -1.605188397138988],
              [-49.34438750521889, -1.605443206995801],
              [-49.33542658016373, -1.608081159565643],
              [-49.32458458839619, -1.611542717942257],
              [-49.31872949374659, -1.615465616270058],
              [-49.313739579143515, -1.615419683440621],
              [-49.31019554283958, -1.616434564278052],
              [-49.3066484890505, -1.618076243835503],
              [-49.30166259776098, -1.617403344647983],
              [-49.29181654372968, -1.626102586685704],
              [-49.28121947117316, -1.625379898993287],
              [-49.27897261820584, -1.62075711174964],
              [-49.26650252556402, -1.620435079029335],
              [-49.26128059988085, -1.623107900316427],
              [-49.25154853973285, -1.621573006205494],
              [-49.24827557417785, -1.623014861191109],
              [-49.234542496364355, -1.625410744396959],
              [-49.22494152918213, -1.619726305296183],
              [-49.22305258348075, -1.620250174245143],
              [-49.21161648241559, -1.620355283311028],
              [-49.2026636039875, -1.62194700672552],
              [-49.18249154814862, -1.622601130449937],
              [-49.17814251386295, -1.620679160050031],
              [-49.176757488180954, -1.618960199345224],
              [-49.16467262780941, -1.619050388623464],
              [-49.157062530271276, -1.605111283629753],
              [-49.15639851590112, -1.603434400078868],
              [-49.155364524324426, -1.59845269974096],
              [-49.15758556102992, -1.589258590139252],
              [-49.16208647540125, -1.580034138048006],
              [-49.16167056536531, -1.576526311565829],
              [-49.160671610143936, -1.575115637260069],
              [-49.141200616687314, -1.566986029362681],
              [-49.1351586055967, -1.568813116618571],
              [-49.12954356865424, -1.568761987009111],
              [-49.12165049806464, -1.566078269247896],
              [-49.108230568154056, -1.570112814526112],
              [-49.08191658893864, -1.572601904495457],
              [-49.06348461620061, -1.557829805963365],
              [-49.05654959714485, -1.547554766127121],
              [-49.05677456742629, -1.545894814020699],
              [-49.053653481956985, -1.547347733118499],
              [-49.04785152857352, -1.545200624799065],
              [-49.04538758431377, -1.541830093891008],
              [-49.031494579787534, -1.538143229956859],
              [-49.0295635569322, -1.531389930562113],
              [-49.01178755193729, -1.530670930907036],
              [-48.9839014629608, -1.521047667862376],
              [-48.98258248667588, -1.521995493474321],
              [-48.97884348730392, -1.521750574263308],
              [-48.97099651718182, -1.516027075493639],
              [-48.96495048277768, -1.517853995111466],
              [-48.957294620048145, -1.514225133947321],
              [-48.948123476861184, -1.516858895565576],
              [-48.93738156501766, -1.509435379192269],
              [-48.93351348433663, -1.500402202131681],
              [-48.93000062871255, -1.498277222036677],
              [-48.92523551675288, -1.496557590779616],
              [-48.9175305360707, -1.497949154346088],
              [-48.910808584993845, -1.505627815852279],
              [-48.904575466510664, -1.505149712094692],
              [-48.89696151329707, -1.49712621909157],
              [-48.90016557960789, -1.487743013754027],
              [-48.89794152541725, -1.480818555896292],
              [-48.89092654300512, -1.475311142590726],
              [-48.8899195411567, -1.47195435800387],
              [-48.8906055161132, -1.465057728064721],
              [-48.88874858928199, -1.463573795925129],
              [-48.882297541316035, -1.46435079835021],
              [-48.871746569227014, -1.459229120229111],
              [-48.85557553105366, -1.454681602476114],
              [-48.84268952837584, -1.45455972960383],
              [-48.83730650251351, -1.451996878886519],
              [-48.832797541515106, -1.445468214497055],
              [-48.833667583065534, -1.441083640941883],
              [-48.827171608098524, -1.424703893379274],
              [-48.82847952027123, -1.418021169609347],
              [-48.82334862205664, -1.411277760860344],
              [-48.82276155355768, -1.407297027400546],
              [-48.825527581608156, -1.400629726332454],
              [-48.82450850981914, -1.398108952769121],
              [-48.812812569755295, -1.382097841308507],
              [-48.80354352593912, -1.373223752770343],
              [-48.80736952946614, -1.364055459430404],
              [-48.799827492981876, -1.348292452304008],
              [-48.795383575552165, -1.334861625919302],
              [-48.79008051304618, -1.302178070836874],
              [-48.78268448931536, -1.292902656774288],
              [-48.78480946941036, -1.288112063828919],
              [-48.789836599663545, -1.283977438626664],
              [-48.78874946682069, -1.267022692505691],
              [-48.78077358302764, -1.253349629118986],
              [-48.77560848664805, -1.250161991338359],
              [-48.77020651768453, -1.249900978873256],
              [-48.766487467242115, -1.24777298129311],
              [-48.757667525798496, -1.235346809823909],
              [-48.753517477894405, -1.234889157910118],
              [-48.7465435668079, -1.225198839639972],
              [-48.74146648041173, -1.187001668643575],
              [-48.73749949327316, -1.17801811244982],
              [-48.72853856821803, -1.169174031125067],
              [-48.71926851857347, -1.155491915282937],
              [-48.682456540897164, -1.132841665948888],
              [-48.664100508201955, -1.126109153674065],
              [-48.63294950029754, -1.095395345839677],
              [-48.61914852906827, -1.087481991044342],
              [-48.60424852269358, -1.084160242812857],
              [-48.58928649023537, -1.073951084735654],
              [-48.58792862191967, -1.04758614354887],
              [-48.59576452792956, -1.026877645906268],
              [-48.605964465913274, -1.014188450315203],
              [-48.618492561325155, -1.007208168982288],
              [-48.621047533053456, -1.00546506839629],
              [-48.62442359601761, -1.002099566630136],
              [-48.625534533465526, -1.00240735011505],
              [-48.628723512350604, -1.000231575686712],
              [-48.626296616102934, -0.983056888426177],
              [-48.62968860468314, -0.982348785245279],
              [-48.627380563822584, -0.97515141261988],
              [-48.621837611247514, -0.968404986385735],
              [-48.61554749582268, -0.952867955369129],
              [-48.616611494612755, -0.950368136563782],
              [-48.631099614265, -0.955948975341244],
              [-48.63505553729138, -0.954732928827411],
              [-48.6354945813801, -0.952436957907423],
              [-48.63302946365391, -0.949484348692124],
              [-48.62680053612232, -0.949423328436922],
              [-48.61914852906827, -0.945793629082459],
              [-48.611450589184756, -0.946971621755836],
              [-48.59427254916295, -0.940111034000381],
              [-48.58681148186341, -0.938573122404307],
              [-48.57068252084403, -0.931095794212581],
              [-48.56196953248502, -0.929544806847559],
              [-48.54460960516414, -0.920381542649579],
              [-48.53760551922616, -0.914665922868892],
              [-48.528079486258406, -0.890104934885244],
              [-48.52155652156313, -0.877284646328405],
              [-48.52228557950201, -0.866417508851271],
              [-48.518093622082006, -0.849229745821788],
              [-48.517345621041926, -0.840440482143833],
              [-48.520954533276495, -0.81120373328838],
              [-48.52391451856664, -0.805796064630727],
              [-48.52504758423896, -0.796188727202036],
              [-48.530719618123044, -0.789554450832497],
              [-48.52083249276615, -0.781300958406064],
              [-48.51676157002811, -0.772479508219874],
              [-48.51102046926178, -0.764895226943509],
              [-48.49573556589294, -0.756379716223535],
              [-48.47861452281276, -0.744499541927723],
              [-48.47720753654443, -0.739677097750302],
              [-48.483028600667126, -0.717991773110725],
              [-48.47911458715669, -0.714396942473456],
              [-48.47626155495118, -0.709142327367886],
              [-48.47468558951465, -0.700137145863948],
              [-48.47561648368219, -0.690110880914233],
              [-48.47358655436926, -0.685282066490345],
              [-48.47447553902089, -0.679226476716565],
              [-48.47950753077791, -0.674469914298129],
              [-48.485763615675836, -0.671814192093564],
              [-48.48933447406995, -0.667668502779122],
              [-48.48688159392242, -0.663671843703298],
              [-48.48357761532563, -0.661757752292374],
              [-48.47467050208891, -0.659578122188577],
              [-48.4722405883561, -0.653072591851924],
              [-48.47437646492526, -0.647031083675529],
              [-48.48192554220822, -0.639371532908626],
              [-48.471019512700366, -0.629436295427411],
              [-48.46453057853202, -0.613483522012928],
              [-48.4591215687698, -0.572245731817816],
              [-48.46383655694845, -0.560337561965355],
              [-48.46268857148846, -0.551027279185519],
              [-48.46041456115486, -0.546338610182886],
              [-48.46126549196603, -0.539549100966383],
              [-48.451194467653295, -0.45824816048821],
              [-48.43846151852762, -0.441173217875587],
              [-48.415359484612, -0.417303066352247],
              [-48.386527581680326, -0.39348907358027],
              [-48.37029250576663, -0.369569804104287],
              [-48.3585665584894, -0.339864842137047],
              [-48.3719175891558, -0.299631874495503],
              [-48.4013514803741, -0.266100406087048],
              [-48.44236748541101, -0.247380934069838],
              [-48.53264661766488, -0.236204839641402],
              [-48.53660958148993, -0.236378847951528],
              [-48.539237475775934, -0.233620028337782],
              [-48.54544846603471, -0.235353741192171],
              [-48.57369246227714, -0.233751959493873],
              [-48.5934445847665, -0.230185292051317],
              [-48.602554540060254, -0.232365927983494],
              [-48.61236153442272, -0.227029337864792],
              [-48.62337451701521, -0.225674654672332],
              [-48.63956449828976, -0.22541548622587],
              [-48.64183850862338, -0.226482502501085],
              [-48.6374286217206, -0.231872904438262],
              [-48.63928253106667, -0.233143936236957],
              [-48.65174457708147, -0.232222094524843],
              [-48.657737470219445, -0.235206722610371],
              [-48.66117455343874, -0.244433186358378],
              [-48.66364654432556, -0.246755811730452],
              [-48.67698248756622, -0.241245213301625],
              [-48.70108062685614, -0.238972376434447],
              [-48.708412612846644, -0.231938953835311],
              [-48.71152447822246, -0.231968961048722],
              [-48.713962606220406, -0.237634624686393],
              [-48.71707950073812, -0.237038671370101],
              [-48.723552509290414, -0.232921815802626],
              [-48.74374049074535, -0.227266545724888],
              [-48.74859646253552, -0.218535955369191],
              [-48.754238489206244, -0.214412226641116],
              [-48.76293152863565, -0.217004246381464],
              [-48.76999261151528, -0.216235290583484],
              [-48.77613453489184, -0.218536961197572],
              [-48.78541162533506, -0.213716361038848],
              [-48.78831059037, -0.21077045734603],
              [-48.79969757348252, -0.208349931344799],
              [-48.81610162856438, -0.203706189343222],
              [-48.841323613433104, -0.208042818412139],
              [-48.86959459940377, -0.219286135704124],
              [-48.88515057352154, -0.219637840361656],
              [-48.88790151414631, -0.222350894784029],
              [-48.89565661860951, -0.223334762579668],
              [-48.90135949789732, -0.217700950174105],
              [-48.908237520011426, -0.214632167780621],
              [-48.92371353077286, -0.201823278612096],
              [-48.931415493969865, -0.199180464538415],
              [-48.9347685905193, -0.195867768762355],
              [-48.93668754343406, -0.190454065134361],
              [-48.94002957587131, -0.188186425047149],
              [-48.94707858881034, -0.188670396136956],
              [-48.96485559963364, -0.174003406670806],
              [-48.97919854472275, -0.165163181021455],
              [-48.97946156884464, -0.167471892434264],
              [-49.01138354420385, -0.163974291873956],
              [-49.033126536337335, -0.164894121929308],
              [-49.07208260484791, -0.170271783373607],
              [-49.09723652866279, -0.167729719776162],
              [-49.125808592595774, -0.165155134394411],
              [-49.15434260268833, -0.158000509475244],
              [-49.18060260244724, -0.152015160050155],
              [-49.21495851485281, -0.155116631866008],
              [-49.26073460270749, -0.154677755415378],
              [-49.303123563485826, -0.161161325165665],
              [-49.36415857080948, -0.201904247796847],
              [-49.3701935411014, -0.233999896190028],
              [-49.365722466305385, -0.245512104936097],
              [-49.363998476458676, -0.248168832969043],
              [-49.3670996129984, -0.247269790033613],
              [-49.39247146629603, -0.238830554632614],
              [-49.40994656096666, -0.21068563248582],
              [-49.41172050695636, -0.21757706564506],
              [-49.41773955163225, -0.21763003927316],
              [-49.42518653733444, -0.22041299876804],
              [-49.4296145291481, -0.212511378637203],
              [-49.43645449742175, -0.21361728694319],
              [-49.4417835438276, -0.220977939042598],
              [-49.442573622021655, -0.22579066021234],
              [-49.44606351123102, -0.230209264294444],
              [-49.458354566420866, -0.225093453505565],
              [-49.47992355024428, -0.226746532451386],
              [-49.50786261284887, -0.236603650594873],
              [-49.51487357194745, -0.241888272913855],
              [-49.53720061509475, -0.234477832309551],
              [-49.54625659093199, -0.236520837391424],
              [-49.552696574785756, -0.235948688680139],
              [-49.556045480383574, -0.232634987075699],
              [-49.559371587204765, -0.231827139246889],
              [-49.56039853798276, -0.233298833807794],
              [-49.567230627267435, -0.235447786145926],
              [-49.57660662416822, -0.231141164290364],
              [-49.60449958630531, -0.246009822347105],
              [-49.6096765849874, -0.247516720901388],
              [-49.60989753195531, -0.245639174588348],
              [-49.61843450034746, -0.242368388328202],
              [-49.63084759604766, -0.24728102178392],
              [-49.63874452231272, -0.246304865339198],
              [-49.65580756262287, -0.241017393173138],
              [-49.666805625427656, -0.241528353991157],
              [-49.68511958096886, -0.235207728438752],
              [-49.69035357659263, -0.230027041719211],
              [-49.71859757283502, -0.227760407460437],
              [-49.745235596427506, -0.219210028023213],
              [-49.79272058670318, -0.198922637196517],
              [-49.79687449028273, -0.198540422411384],
              [-49.810951561402874, -0.203255075313905],
              [-49.841896542127046, -0.200378238503333],
              [-49.857082539038345, -0.19632659414259],
              [-49.88249546366157, -0.188766285109352],
              [-49.88119559811591, -0.1866045922784],
              [-49.90972156158139, -0.178286894473558],
              [-49.95020246591909, -0.171115505748048],
              [-49.9650454753521, -0.165247000053284],
              [-49.97314859643549, -0.163641195041464],
              [-49.990169559591635, -0.163363251131898],
              [-49.99704758170574, -0.159657611734588],
              [-50.0020675035222, -0.154891829222663],
              [-50.012886528875015, -0.151638142044987],
              [-50.03032256387681, -0.150942946994974],
              [-50.042140544451, -0.152292936321658],
              [-50.04504755611302, -0.151897981043646],
              [-50.05005658145532, -0.148387137076327],
              [-50.08892447234442, -0.140759940455723],
              [-50.1006165567328, -0.13165970816965],
              [-50.10911949459802, -0.12954277470169],
              [-50.11845760529641, -0.143586653485215],
              [-50.16558854398161, -0.133296358585199],
              [-50.268363585139156, -0.112762036890786],
              [-50.27003862467129, -0.113192866714371],
              [-50.28008249161772, -0.110304295239359],
              [-50.291980603186346, -0.100786979450902],
              [-50.29739346862402, -0.098738945227126],
              [-50.304023554041976, -0.099835801077688],
              [-50.30524848537317, -0.100888903393638],
              [-50.30820461498783, -0.09167752707134],
              [-50.31501055273458, -0.085868029975074],
              [-50.32302046705456, -0.085790078275465],
              [-50.32875855033575, -0.088031902100852],
              [-50.35317956053626, -0.128025817863602],
              [-50.368400593802846, -0.163508258056993],
              [-50.36968252207569, -0.177287101061836],
              [-50.366340489638475, -0.186524293645903],
              [-50.357315526842996, -0.200411430839949],
              [-50.35668151968622, -0.203294302620805],
              [-50.36296861762591, -0.204728949169635],
              [-50.37405754026122, -0.204502973059789],
              [-50.389671517149054, -0.208620834455644],
              [-50.396324568981726, -0.208484712347968],
              [-50.41189563052524, -0.210386733818325],
              [-50.42374462414119, -0.205515004050142],
              [-50.41334553213787, -0.196417789249267],
              [-50.407539555440906, -0.187276485637597],
              [-50.40741751493056, -0.174626182077304],
              [-50.42908456702119, -0.167516987073384],
              [-50.473617618633796, -0.157897579704183],
              [-50.49660448519967, -0.168021745283113],
              [-50.50693551614913, -0.178771033201429],
              [-50.51098648995759, -0.172847542221803],
              [-50.51174555510988, -0.166611070977353],
              [-50.52806847336893, -0.166733949678019],
              [-50.531787523811346, -0.168642844308977],
              [-50.538227507665084, -0.168271693636029],
              [-50.54504752700919, -0.171875409090603],
              [-50.55168549141612, -0.172343454564384],
              [-50.554992487498026, -0.174248493519883],
              [-50.56655850806254, -0.182065791704701],
              [-50.57131557339517, -0.184190604161643],
              [-50.58490749995906, -0.198708395751169],
              [-50.60647547795406, -0.229585147783439],
              [-50.611011596318775, -0.234215981654074],
              [-50.62340558127971, -0.24204015299955],
              [-50.64153647605539, -0.260561979739691],
              [-50.65222155859533, -0.275685782929372],
              [-50.65465147232817, -0.284272204550291],
              [-50.65419348513822, -0.290537677179771],
              [-50.65472758000908, -0.302453726021156],
              [-50.65780256501088, -0.307909506803185],
              [-50.6620946023549, -0.317344512302327],
              [-50.665523471309086, -0.331581677773272],
              [-50.676250631003, -0.340645867875651],
              [-50.6794774960905, -0.354253887693631],
              [-50.68112554589439, -0.35593781204318],
              [-50.684616608570224, -0.360979359169846],
              [-50.692172559013784, -0.378381028368551],
              [-50.69170753102517, -0.385692394877253],
              [-50.6935275774824, -0.392185017083023],
              [-50.7023775261394, -0.432377081036975],
              [-50.70049260375154, -0.434662826035094],
              [-50.70062654656442, -0.444906182277236],
              [-50.718833549021014, -0.482869330536744],
              [-50.72295358971169, -0.487707029777994],
              [-50.72436560512193, -0.493360623475098],
              [-50.72306456610983, -0.501502971865364],
              [-50.719272593109736, -0.509000248986695],
              [-50.71049456118206, -0.517298836052248],
              [-50.70880862517578, -0.520838346128471],
              [-50.711048604982466, -0.52712594698238],
              [-50.70850754721343, -0.534005310200996],
              [-50.69950454500429, -0.544601712205235],
              [-50.693874588274184, -0.547695305032164],
              [-50.69112063016428, -0.55582776277663],
              [-50.69378657829074, -0.560446359068692],
              [-50.68584858070008, -0.596342865279325],
              [-50.68624152432133, -0.599481552745317],
              [-50.684341514507736, -0.604066118510445],
              [-50.69245553206525, -0.601826473979884],
              [-50.69353059496754, -0.601861342697134],
              [-50.70647460041536, -0.578842121984962],
              [-50.718318564889415, -0.573035642373782],
              [-50.72472351238787, -0.56004453063008],
              [-50.72911060051399, -0.556730661387576],
              [-50.73284959988595, -0.556548774088469],
              [-50.73882254409435, -0.562863197032527],
              [-50.74272147017908, -0.569788828356707],
              [-50.74800458375546, -0.585714444404914],
              [-50.77361649476026, -0.635025851384228],
              [-50.78505762496735, -0.661658007644462],
              [-50.7848284637343, -0.664582621303168],
              [-50.79395250062541, -0.666321363299517],
              [-50.79708448256886, -0.664043832566563],
              [-50.80076262932374, -0.635245792523733],
              [-50.79038952122028, -0.61946417757224],
              [-50.78569749945635, -0.587448324897366],
              [-50.784351533443186, -0.584513485316791],
              [-50.78354251214793, -0.581371780365657],
              [-50.785842506381414, -0.57908821466242],
              [-50.786903487686345, -0.579296924051675],
              [-50.79206858406593, -0.553893890074221],
              [-50.81376648156029, -0.550227142707683],
              [-50.84703056725721, -0.559127382783743],
              [-50.88259850286306, -0.569147948039358],
              [-50.920532482099475, -0.586049385256104],
              [-50.94466347844988, -0.598475556725248],
              [-50.948352521678885, -0.603172272354982],
              [-50.96004058275372, -0.610840540301183],
              [-50.962303528975156, -0.614200510011244],
              [-50.96770046879678, -0.614448279069336],
              [-50.97246155744293, -0.616780292172962],
              [-50.97842461100555, -0.624974776001068],
              [-50.98467248163834, -0.622508820084533],
              [-50.999217598232235, -0.651667784878441],
              [-51.01303047176404, -0.666397638618491],
              [-51.02420857784921, -0.671910248704023],
              [-51.03025058893982, -0.673863902697974],
              [-51.041480495186676, -0.672340240337348],
              [-51.05084660144172, -0.672254074372631],
              [-51.050811565086406, -0.668537203225014],
              [-51.0479925634078, -0.655348446204243],
              [-51.04135158151573, -0.654885429872422],
              [-51.038043579605414, -0.652563642690666],
              [-51.032093601811766, -0.641859281773407],
              [-51.0242464640516, -0.635534968183549],
              [-51.018482564508616, -0.627969462370345],
              [-51.01008255641449, -0.610978674065677],
              [-50.99915356049192, -0.599614657367852],
              [-50.99712748685445, -0.592284180119918],
              [-50.98846847795198, -0.583234909805242],
              [-50.97133653839768, -0.571854632215263],
              [-50.96739151184545, -0.581009011595881],
              [-50.95597854483307, -0.583413611981086],
              [-50.942184614402464, -0.577787175650315],
              [-50.92832949607862, -0.564126014566114],
              [-50.92252351938163, -0.556127834910626],
              [-50.90869153511056, -0.54474671913033],
              [-50.903366512018266, -0.541004031720945],
              [-50.89815162713376, -0.539377104313076],
              [-50.89035058984112, -0.540321074249562],
              [-50.869300613462826, -0.5303808076265],
              [-50.86508954530356, -0.529067698673828],
              [-50.86348357265365, -0.527632884486877],
              [-50.85931357581998, -0.525664143067218],
              [-50.848472589880856, -0.521526500379821],
              [-50.847896585494084, -0.5233011169218],
              [-50.845176490273076, -0.526208966774107],
              [-50.832176493711984, -0.515305787113391],
              [-50.81222555247908, -0.507692504451995],
              [-50.776428623278235, -0.473529878733871],
              [-50.77372747115842, -0.457317266320672],
              [-50.77072557635236, -0.455027330371024],
              [-50.76555662429732, -0.452272701708864],
              [-50.750568607939215, -0.428546383644175],
              [-50.75713750546393, -0.409364733485461],
              [-50.76659061587401, -0.393339875703589],
              [-50.774578569607655, -0.34992731690852],
              [-50.77085851333686, -0.347392294109795],
              [-50.76717349342138, -0.340052093854126],
              [-50.76687962389576, -0.337446160154457],
              [-50.75665252854574, -0.318462658187002],
              [-50.74739857215533, -0.309350188322298],
              [-50.73817462297828, -0.301377992566643],
              [-50.73800648200043, -0.284137088270938],
              [-50.73895246359368, -0.263429596456717],
              [-50.73654149296206, -0.25080460624406],
              [-50.73875448304051, -0.242723110470934],
              [-50.7393145618112, -0.239749378859528],
              [-50.73635055320756, -0.23680464863321],
              [-50.73369952486874, -0.230097114429839],
              [-50.71009860007567, -0.192733104609886],
              [-50.70023762625672, -0.180331743574129],
              [-50.692405575922294, -0.183371692220646],
              [-50.67977957988123, -0.178889050398254],
              [-50.66129262185831, -0.161817292908779],
              [-50.65203849782981, -0.150410193228652],
              [-50.65079462339742, -0.140081844488179],
              [-50.650745505444775, -0.135430223497622],
              [-50.64619061161693, -0.132716498522996],
              [-50.64395549331408, -0.126328985383168],
              [-50.6426395345143, -0.125218047935277],
              [-50.62297056050451, -0.103554516243946],
              [-50.62505346344554, -0.082829087156938],
              [-50.631660582448774, -0.056314277541219],
              [-50.64714849551271, -0.003264038828036],
              [-50.65967558509621, 0.003753290517011],
              [-50.6630855109492, 0.006084130154193],
              [-50.66764861904218, 0.007295985716439],
              [-50.67678053492227, 0.008533992816581],
              [-50.69053657915049, 0.007520955997848],
              [-50.698547499298826, 0.006432984964704],
              [-50.70946459291889, -0.000001299194878],
              [-50.71222659765584, -2.93366497e-7],
              [-50.718341531304134, -0.000844351017065],
              [-50.74619660723883, -0.017910073536257],
              [-50.76047853207277, -0.022818683678395],
              [-50.7727055495223, -0.024786419269731],
              [-50.80353955384834, -0.036914697899761],
              [-50.8046535087814, -0.037504616245769],
              [-50.81114160475943, -0.039603277164815],
              [-50.83864547895075, -0.045079174514399],
              [-50.85931357581998, -0.051780506109424],
              [-50.89257062071823, -0.059497892008324],
              [-50.91546662745358, -0.060423924671966],
              [-50.933784606308336, -0.061402930963766],
              [-50.957862629030586, -0.066912523564213],
              [-50.97053556372947, -0.075999680081281],
              [-50.98097958273388, -0.089697721539494],
              [-50.98683149226028, -0.103448736625808],
              [-50.987903537677425, -0.108897308971052],
              [-50.988960495668834, -0.110508981315093],
              [-51.004814530263815, -0.129003818327021],
              [-51.00970050926739, -0.143453716500744],
              [-51.01752048966125, -0.153120062527762],
              [-51.01955058661221, -0.159819382466026],
              [-51.025886467228446, -0.174280512389998],
              [-51.02793450145222, -0.178474146190695],
              [-51.028278494758865, -0.18913307719265],
              [-51.03235662593366, -0.200025528017363],
              [-51.03168456493643, -0.207333877040924],
              [-51.03390861912706, -0.21612615820402],
              [-51.03220357238152, -0.222801673537219],
              [-51.029697550967796, -0.224873512366059],
              [-51.03084955974131, -0.238672471938571],
              [-51.0329054729541, -0.242030429991814],
              [-51.04253762081615, -0.260068956194459],
              [-51.046455490002074, -0.264273654107285],
              [-51.06900063027018, -0.307059414182334],
              [-51.07080458347329, -0.316892727720756],
              [-51.07508455087671, -0.328625715796704],
              [-51.07817059999073, -0.332617513368689],
              [-51.08229851967042, -0.336407139435892],
              [-51.087066481477166, -0.336857247636829],
              [-51.10889061043346, -0.364383082414463],
              [-51.12170754622903, -0.371993682866787],
              [-51.129550493037584, -0.378943956986348],
              [-51.1363445284818, -0.387767251191235],
              [-51.14142647638181, -0.403895038744167],
              [-51.145324564276194, -0.411236747742407],
              [-51.15019260600698, -0.416832171031388],
              [-51.16070552425552, -0.427408288829952],
              [-51.16503947111545, -0.428452338690477],
              [-51.17063556495674, -0.43640978229655],
              [-51.181014540392425, -0.445126123416799],
              [-51.21232648083796, -0.467111520190315],
              [-51.226081519237795, -0.470439303392141],
              [-51.236473570442456, -0.479554455465859],
              [-51.23796856669421, -0.483063287776417],
              [-51.24365250288082, -0.486509088175069],
              [-51.259757491657126, -0.499993055825939],
              [-51.278678464626864, -0.495937388151731],
              [-51.2800904800371, -0.494096554574583],
              [-51.269699602298914, -0.48383107010801],
              [-51.26391558618826, -0.477095707986052],
              [-51.25785060868293, -0.473852917282557],
              [-51.25412351161347, -0.467039603460989],
              [-51.24545260040847, -0.461051739464949],
              [-51.23965450270049, -0.453059594779745],
              [-51.24305755539288, -0.449562832409754],
              [-51.2461436045069, -0.448365896635153],
              [-51.241836479737145, -0.445948891033254],
              [-51.243099464908795, -0.443448066399526],
              [-51.25990249858219, -0.445439941871939],
              [-51.30692648418278, -0.46163394646004],
              [-51.33201251458189, -0.467022336740456],
              [-51.34442862776723, -0.472747847166886],
              [-51.352466537643835, -0.482415199022284],
              [-51.35992056414469, -0.485388092443316],
              [-51.36510460362547, -0.486884094523475],
              [-51.373187608141194, -0.488818973054322],
              [-51.37672058033294, -0.488005928445546],
              [-51.39599962820668, -0.492728627975112],
              [-51.39609551717908, -0.477469540868071],
              [-51.399032536054506, -0.472472082552144],
              [-51.389285556118836, -0.470737196231255],
              [-51.38563154924512, -0.469054277710143],
              [-51.38284255477993, -0.470107044749966],
              [-51.36908751638009, -0.466787475813248],
              [-51.346092603187174, -0.4554921907216],
              [-51.32299761007022, -0.433851793083079],
              [-51.30224653235945, -0.417938079337432],
              [-51.27471952411534, -0.410135868578323],
              [-51.25749957457762, -0.404559053114383],
              [-51.24595652042785, -0.394306979692885],
              [-51.21008247771772, -0.352081968940809],
              [-51.18814854582973, -0.331575475164925],
              [-51.163993577236255, -0.319162379464728],
              [-51.15245856971353, -0.308919358498656],
              [-51.133239536266586, -0.2147664458696],
              [-51.127796495977464, -0.195654868421514],
              [-51.12474062171492, -0.195223703321744],
              [-51.114650486662924, -0.18366271189916],
              [-51.11592453594676, -0.178866083983507],
              [-51.11110661799705, -0.154177020519171],
              [-51.112171622615506, -0.150423101359536],
              [-51.12446552765243, -0.137764080619945],
              [-51.1357955138233, -0.124992910015749],
              [-51.138084611582656, -0.124970111239122],
              [-51.150772466069185, -0.136349047724536],
              [-51.15539558858893, -0.139389834561371],
              [-51.16949462029541, -0.143288760646101],
              [-51.17633458856909, -0.144796497390701],
              [-51.18840050583944, -0.140280830869756],
              [-51.20226652063741, -0.145805343257848],
              [-51.20783948042589, -0.150230149948243],
              [-51.221214483335416, -0.1680789098628],
              [-51.224708563496364, -0.173744238224344],
              [-51.23748761308954, -0.187202389613446],
              [-51.24465950472927, -0.201666369384554],
              [-51.25125455379191, -0.208605747029935],
              [-51.260345565984466, -0.215143296236761],
              [-51.26755551146462, -0.223131585246506],
              [-51.27247954430865, -0.231939959663748],
              [-51.27674459192443, -0.246802918026333],
              [-51.28105959568316, -0.253728381712449],
              [-51.28531257335834, -0.270055155646958],
              [-51.29875161400818, -0.296502239484937],
              [-51.29747756472432, -0.27875557115118],
              [-51.29737849062869, -0.268402244339256],
              [-51.301933552094596, -0.266042570955108],
              [-51.315418525573875, -0.276388689330304],
              [-51.31517461219124, -0.274639721412086],
              [-51.31687546798517, -0.267964206078886],
              [-51.31978650296074, -0.267146467604334],
              [-51.324539544979814, -0.27073123995774],
              [-51.33277560304768, -0.281023211238391],
              [-51.3460845565601, -0.309740114458407],
              [-51.355483519875605, -0.333416308742017],
              [-51.3560635475759, -0.340106911500982],
              [-51.353286623051275, -0.352418921448759],
              [-51.35203554018216, -0.353248059311682],
              [-51.33916848060554, -0.343945655520827],
              [-51.334777536803955, -0.349741238657884],
              [-51.32942954729694, -0.350390165602334],
              [-51.33105848636157, -0.355409752142634],
              [-51.33041358273064, -0.358749437646964],
              [-51.3277245005514, -0.357268690630633],
              [-51.32570261786552, -0.350842285460033],
              [-51.32449361215038, -0.350990142232149],
              [-51.3164065843211, -0.343001685584341],
              [-51.308200533466646, -0.324685047834123],
              [-51.31676851490056, -0.3500921051251],
              [-51.32498562986723, -0.36393917682193],
              [-51.343547522104586, -0.382659822305584],
              [-51.3573835296892, -0.394244785971239],
              [-51.36190053731465, -0.401797383653559],
              [-51.39145646904328, -0.422888766633548],
              [-51.40940849400505, -0.439933534394754],
              [-51.41133448771848, -0.430958192466164],
              [-51.41934155219138, -0.411990616114679],
              [-51.400497525092874, -0.404136102279722],
              [-51.39595453356753, -0.40055350922114],
              [-51.39207052727048, -0.390706281723396],
              [-51.375175627938205, -0.365189086224575],
              [-51.37035351903688, -0.360906268974077],
              [-51.36769846738457, -0.34339261754883],
              [-51.365844558038475, -0.335990223571571],
              [-51.36070661902522, -0.328224893186473],
              [-51.35948956668301, -0.323619205025352],
              [-51.35232153071877, -0.308107487356324],
              [-51.35361049979028, -0.301011871035598],
              [-51.34974258674734, -0.289073693969783],
              [-51.34175848868918, -0.271469182713986],
              [-51.330268575805576, -0.25049766094952],
              [-51.32652253563492, -0.244402173316587],
              [-51.321166499500805, -0.244006044572131],
              [-51.305030497682736, -0.231499909746617],
              [-51.291000532858504, -0.201729233658398],
              [-51.268989487461226, -0.173177286293026],
              [-51.267715605815425, -0.159377320892133],
              [-51.26230961353835, -0.143095809234808],
              [-51.25772856817258, -0.137815042591285],
              [-51.25424555212382, -0.130479703839512],
              [-51.248302615128836, -0.119158434847975],
              [-51.24110054863766, -0.109708341923067],
              [-51.23904748527195, -0.106022483817242],
              [-51.23626754326219, -0.104464455653556],
              [-51.23705259231434, -0.10243989075866],
              [-51.23350956183879, -0.096078193880658],
              [-51.22050051282224, -0.086170784318028],
              [-51.21947456787262, -0.084285023739881],
              [-51.22032549868379, -0.080947349892313],
              [-51.21266946831622, -0.06904840013334],
              [-51.20956062042555, -0.066335345711025],
              [-51.204940515390945, -0.0598968705998],
              [-51.20267857499789, -0.057569216085824],
              [-51.19464452079677, -0.050800661627306],
              [-51.16503159212647, -0.059285997495806],
              [-51.18540548419398, -0.059814895586669],
              [-51.19118162131562, -0.063621788374462],
              [-51.193851592755635, -0.067818439660243],
              [-51.19340550786822, -0.072410884414353],
              [-51.1864735062976, -0.085319015316543],
              [-51.182506519159034, -0.089052650270503],
              [-51.17440054822859, -0.091295647562333],
              [-51.16796861100187, -0.091043519914592],
              [-51.15969047578005, -0.087645831640202],
              [-51.1468965063992, -0.076904590348931],
              [-51.127048494937384, -0.065906527544144],
              [-51.12396261346143, -0.061916909266984],
              [-51.12533556920286, -0.059198825702708],
              [-51.11478862042739, -0.056432294738045],
              [-51.102214592186044, -0.055136284867842],
              [-51.083343575359294, -0.052410489952649],
              [-51.07067147885073, -0.046414914605691],
              [-51.0659365417425, -0.044950093205387],
              [-51.05960049348823, -0.041952389350911],
              [-51.056400618129004, -0.038886624442625],
              [-51.05156358944001, -0.032721902289438],
              [-51.04829447956047, -0.026522311418944],
              [-51.03821155294523, 0.004605394794623],
              [-51.03490858017682, 0.012369719351341],
              [-51.031501504170905, 0.024833777022877],
              [-51.026527515183886, 0.03726380416748],
              [-51.021621587250735, 0.046558999521608],
              [-51.016754551348356, 0.054288622999138],
              [-51.010280536967684, 0.063549787826275],
              [-51.00708049397036, 0.06661370871592],
              [-50.994239585931695, 0.080435467065058],
              [-50.98296357921734, 0.094291591217313],
              [-50.94750561418124, 0.128859062859192],
              [-50.938377553976636, 0.13813816495923],
              [-50.91340249997569, 0.146593661252382],
              [-50.8961795329528, 0.152171147268575],
              [-50.87435959494812, 0.161154368186203],
              [-50.84904457095408, 0.174691644741358],
              [-50.828899504843434, 0.184571729299591],
              [-50.810752516813665, 0.185073637662185],
              [-50.79085555503727, 0.181956072592186],
              [-50.78490457141521, 0.183397759939737],
              [-50.77484159372952, 0.193513208339311],
              [-50.769706504563345, 0.202186466477258],
              [-50.76805862239752, 0.210658894214873],
              [-50.76967649734996, 0.216461350512475],
              [-50.76536551690475, 0.221230988699915],
              [-50.75964352687765, 0.212716819084392],
              [-50.747791515776555, 0.224995804333673],
              [-50.74213456931818, 0.224227686725953],
              [-50.72831750483479, 0.232149255786396],
              [-50.71559947549679, 0.245817122393134],
              [-50.69696046975011, 0.277824760802844],
              [-50.66571055538813, 0.313158170757674],
              [-50.62521355779634, 0.356438798396596],
              [-50.598663544187275, 0.379169178725078],
              [-50.57078550183786, 0.419117329296455],
              [-50.53346256334348, 0.486587291331944],
              [-50.52293355184082, 0.509469551746065],
              [-50.50077062635785, 0.549479560762961],
              [-50.46910061100834, 0.622723145349141],
              [-50.45638258167034, 0.635243026495857],
              [-50.42183656770058, 0.656719306470052],
              [-50.388481622173146, 0.672465382152041],
              [-50.356124626038735, 0.702009076302033],
              [-50.33876050776624, 0.720217587501224],
              [-50.32440951605008, 0.736552408062778],
              [-50.32233851541156, 0.73616952272539],
              [-50.31308355319274, 0.74711377371176],
              [-50.3106075389924, 0.756001105656935],
              [-50.31093258919785, 0.757159987591081],
              [-50.30387854711691, 0.771569820267587],
              [-50.29194657265941, 0.816274533257342],
              [-50.28678147627983, 0.830496443664515],
              [-50.28154362498063, 0.840731585641549],
              [-50.27494857591799, 0.860365188019955],
              [-50.27412061152154, 0.865533972436936],
              [-50.26383953671504, 0.873582275873503],
              [-50.261791502491235, 0.871304242226358],
              [-50.25727047155226, 0.87233136064242],
              [-50.24432361625733, 0.880789706782707],
              [-50.24336254723835, 0.881277701186036],
              [-50.22715747853806, 0.881102687047587],
              [-50.20987650874514, 0.892397972139236],
              [-50.17176852119863, 0.923022428885702],
              [-50.15794357772626, 0.932057952879177],
              [-50.134918489681866, 0.945622889714798],
              [-50.10731554139491, 0.956789093497491],
              [-50.07846050441046, 0.978314491424328],
              [-50.071433619695824, 0.993181137824308],
              [-50.04579958046662, 1.034246260813859],
              [-50.03653757744911, 1.044494310921834],
              [-50.0158005813357, 1.055753721467909],
              [-49.9721675784869, 1.069061501513886],
              [-49.954742607597325, 1.091852902097514],
              [-49.9430235334807, 1.117002970236911],
              [-49.93132055261819, 1.138691479999693],
              [-49.91855659045072, 1.154626148503326],
              [-49.897705600454, 1.176228492301391],
              [-49.8894425526579, 1.19911896698062],
              [-49.89253262508544, 1.229024759348135],
              [-49.903770577959364, 1.246388207068378],
              [-49.915145491131284, 1.252266771046891],
              [-49.938194551418775, 1.238744749555565],
              [-49.95140862178715, 1.223838037658368],
              [-49.95398354244506, 1.222766998069574],
              [-49.96508755250608, 1.215135778135448],
              [-49.98461554290435, 1.208553134289502],
              [-50.006797579126584, 1.209629203020199],
              [-50.010589552126675, 1.207562393333262],
              [-50.015235473423076, 1.207448231811895],
              [-50.07235361738921, 1.200932810829499],
              [-50.072956611504196, 1.203750471403566],
              [-50.072338529963474, 1.206025319927505],
              [-50.06616257605998, 1.214700589722156],
              [-50.04724847625087, 1.222114215449665],
              [-50.04041655460426, 1.22971073430466],
              [-50.01844758636096, 1.227009749822912],
              [-49.994293623595865, 1.237085970915587],
              [-49.98863952698457, 1.241114816499646],
              [-49.98208957256105, 1.242678544357545],
              [-49.96851759492674, 1.251134040650754],
              [-49.94507961433155, 1.261638241719993],
              [-49.934997525906596, 1.270513503724601],
              [-49.91770549200149, 1.289303048452439],
              [-49.9092555277644, 1.30314609683569],
              [-49.90678051939244, 1.310587382843721],
              [-49.906173501963934, 1.317007920682045],
              [-49.88550557273274, 1.341541080747163],
              [-49.87373352498798, 1.37012907029623],
              [-49.87603754253499, 1.46666294634349],
              [-49.89061350453261, 1.46838073358191],
              [-49.89775052745509, 1.469220767918898],
              [-49.90119146634987, 1.469261503968369],
              [-49.907974605319936, 1.476236085607184],
              [-49.91471851698313, 1.486655294178149],
              [-49.92018150620183, 1.508555866091456],
              [-49.92887856894478, 1.547713938468746],
              [-49.9457095981748, 1.575488883409037],
              [-49.96730758338322, 1.588388800046118],
              [-49.98791449235921, 1.588631707600371],
              [-50.01206258779209, 1.578574429608864],
              [-50.02934255175663, 1.569584167892572],
              [-50.049911574530256, 1.572121034709994],
              [-50.068138525916424, 1.58038944692413],
              [-50.09303261073259, 1.604819677218188],
              [-50.10871146355106, 1.633734225715216],
              [-50.13925947734046, 1.66397395310554],
              [-50.21542348463376, 1.708551931719228],
              [-50.25834654028293, 1.757334105331552],
              [-50.270534498063626, 1.808869231225344],
              [-50.271430523513914, 1.812665059900951],
              [-50.28965747490008, 1.819789509968643],
              [-50.3023915974922, 1.819941557692403],
              [-50.30673962594946, 1.821908287455301],
              [-50.322223515699875, 1.821747187276117],
              [-50.32968860631294, 1.822544138630803],
              [-50.35369152718269, 1.826292861010472],
              [-50.38236249757324, 1.822038374592694],
              [-50.409904593243084, 1.816613774490577],
              [-50.43738550101969, 1.815780613314132],
              [-50.463561514108704, 1.82874171784448],
              [-50.472534509104435, 1.843799639275119],
              [-50.49281351802793, 1.871633760451857],
              [-50.50956760138675, 1.906326960641536],
              [-50.51506462113238, 1.91323851036833],
              [-50.52987661752363, 1.931867122555047],
              [-50.54798152839945, 1.949330817837335],
              [-50.5671695488046, 1.9725429899608],
              [-50.57495851615667, 1.991042520838505],
              [-50.57685852597024, 2.000498984009823],
              [-50.584686552991144, 2.039459914024235],
              [-50.588096478844164, 2.053898915723835],
              [-50.59121655848509, 2.067135952506987],
              [-50.6127245220533, 2.088081322733501],
              [-50.641052504965586, 2.112582631566511],
              [-50.66052249259383, 2.111667830653118],
              [-50.68235047722558, 2.10616611704171],
              [-50.71932254925272, 2.101162456117436],
              [-50.726028574713496, 2.091744046786516],
              [-50.72613552779811, 2.08253585558748],
              [-50.727477470497746, 2.06645718598719],
              [-50.7346035969461, 2.045842565660223],
              [-50.74048651951429, 2.032116696283481],
              [-50.7450945546083, 2.029873698991651],
              [-50.755340593059515, 2.034590363550933],
              [-50.75980362122846, 2.043853875310958],
              [-50.75502057199597, 2.06103929140761],
              [-50.75595062797319, 2.079461205861776],
              [-50.751037491603284, 2.106986873001404],
              [-50.74285155731647, 2.12185284884913],
              [-50.72557058752358, 2.129688251944856],
              [-50.71292849822839, 2.1341509448377],
              [-50.70140857813141, 2.13975759987693],
              [-50.68527257631334, 2.148763787209305],
              [-50.68158755639786, 2.169409420578006],
              [-50.6847306024535, 2.193600598993271],
              [-50.70322057796156, 2.249572433880019],
              [-50.705997502486184, 2.257821902992987],
              [-50.72905762688586, 2.314880367808428],
              [-50.74349260527194, 2.35186668907096],
              [-50.74697159800718, 2.366612636065156],
              [-50.7483675201633, 2.372538138701486],
              [-50.74893162224754, 2.374938883411232],
              [-50.748203570137036, 2.4342745436835],
              [-50.74816853378172, 2.437037386610768],
              [-50.75705754210756, 2.458994787827692],
              [-50.78169262611539, 2.505309329142847],
              [-50.81206562576634, 2.548241101971314],
              [-50.816215506032364, 2.566596799390368],
              [-50.81858054383454, 2.577066802294667],
              [-50.83184859365943, 2.616342891868783],
              [-50.84050760256193, 2.656702929162577],
              [-50.855148608128246, 2.676435605636584],
              [-50.862907568266905, 2.697233957281298],
              [-50.860069623487135, 2.740908702007914],
              [-50.86909860959611, 2.763598178648863],
              [-50.8734055667278, 2.774426088819041],
              [-50.87734958745165, 2.777072088015927],
              [-50.87909352622796, 2.784029570572216],
              [-50.88731349104171, 2.800184347853417],
              [-50.89225361713983, 2.803293028105998],
              [-50.905624596735834, 2.803937931736925],
              [-50.90847762894134, 2.808223598834559],
              [-50.91529060748675, 2.827169382237685],
              [-50.91844957915845, 2.835955796068561],
              [-50.938499594486984, 2.881067199000825],
              [-50.95874759036869, 2.911228136501222],
              [-50.96537750814858, 2.930873473544068],
              [-50.99527760082191, 3.01177459724039],
              [-51.026191568504316, 3.104194635205317],
              [-51.05142260582846, 3.191940085764998],
              [-51.077262504599844, 3.322274321674854],
              [-51.095748624432446, 3.399596707988053],
              [-51.097221492459795, 3.462882926868645],
              [-51.103691483526944, 3.582613049423344],
              [-51.09783957400052, 3.678022074063449],
              [-51.10697953650768, 3.681588741505948],
              [-51.11581456537701, 3.677301230389673],
              [-51.12098653491719, 3.662224198219747],
              [-51.13620756818378, 3.638258828276321],
              [-51.160438476820104, 3.625912787801553],
              [-51.159202481376724, 3.605127344287723],
              [-51.17747150991687, 3.599862670898347],
              [-51.182868617376556, 3.599213743953896],
              [-51.18429555257447, 3.643481592149783],
              [-51.19342746845456, 3.645898430113618],
              [-51.20677162596036, 3.678292138983977],
              [-51.209945517419726, 3.699039361019288],
              [-51.20450549461572, 3.76340081044026],
              [-51.1948394838648, 3.801241918189419],
              [-51.20012662075473, 3.83468051511062],
              [-51.20629855134467, 3.888837500320165],
              [-51.215927514083546, 3.940734556793416],
              [-51.23155255508357, 3.973169001713245],
              [-51.23700749767522, 3.993948577894855],
              [-51.24111949173886, 4.030805817848375],
              [-51.239364488850356, 4.075665428408968],
              [-51.24114949895224, 4.113664618852169],
              [-51.25332656025881, 4.145897395181407],
              [-51.2555615109236, 4.151824741836492],
              [-51.27230050685668, 4.186557169333071],
              [-51.29145852004842, 4.213279514871545],
              [-51.31066850103991, 4.234252713016701],
              [-51.34020247218223, 4.256503983759217],
              [-51.39254762519968, 4.289421729216599],
              [-51.44934859794935, 4.332744434009498],
              [-51.47442658172136, 4.344589739588059],
              [-51.48478661405588, 4.342417820835237],
              [-51.48522951382006, 4.34054480074991],
              [-51.489654488148574, 4.321769337619401],
              [-51.48497051301172, 4.289255599895455],
              [-51.49879847396923, 4.2851769658065],
              [-51.499271548584915, 4.271375994577227],
              [-51.50928457012762, 4.248685679745961],
              [-51.50068255816677, 4.229970231042273],
              [-51.49224047291867, 4.20665278221486],
              [-51.49255362082158, 4.197450458348044],
              [-51.50271651079322, 4.170154622993721],
              [-51.5028725818305, 4.16555379633644],
              [-51.52930860155627, 4.1710355610183],
              [-51.53506847778573, 4.170997507177844],
              [-51.54001648287286, 4.175759098738183],
              [-51.54434959154247, 4.18176456473094],
              [-51.55383656484142, 4.205163820933421],
              [-51.56023061586572, 4.212203278502841],
              [-51.56497561125775, 4.227112337332926],
              [-51.5717815490045, 4.240158266723483],
              [-51.57569858000008, 4.242643333379249],
              [-51.58518253581386, 4.240985225291524],
              [-51.58703963028316, 4.236222627902805],
              [-51.587448499520434, 4.229181158676624],
              [-51.589508603684806, 4.225454396883265],
              [-51.5967215666501, 4.215513124431823],
              [-51.60723448489867, 4.198533567877405],
              [-51.61505848860605, 4.190611998816962],
              [-51.6179505804804, 4.186314261778818],
              [-51.62145656294388, 4.184243428778359],
              [-51.634342565621694, 4.191272828063916],
              [-51.634036626155506, 4.19645854392536],
              [-51.635688531634855, 4.201221308952142],
              [-51.643524605282806, 4.214680130893498],
              [-51.64971162329849, 4.220683752867501],
              [-51.65321760576197, 4.222340687488781],
              [-51.656928609577335, 4.218818779409219],
              [-51.6624905052536, 4.204736846785238],
              [-51.66352449683029, 4.210949681062743],
              [-51.66208247420664, 4.218403875201659],
              [-51.657344519613275, 4.230207941816559],
              [-51.657138492433035, 4.236006542438759],
              [-51.6596185299469, 4.245739105500945],
              [-51.66498160687968, 4.254434156587138],
              [-51.69014760063513, 4.283213253528743],
              [-51.7035564664335, 4.30143182301174],
              [-51.71036558930342, 4.313854809357679],
              [-51.71656048630811, 4.334147564602404],
              [-51.716720580658915, 4.342765669817311],
              [-51.727012551939566, 4.355469785196078],
              [-51.748435523009505, 4.37930406217373],
              [-51.793601575950504, 4.427043694668157],
              [-51.83238950348323, 4.453694626391552],
              [-51.87702548486692, 4.479351799673509],
              [-51.922592528056214, 4.513178311074057],
              [-51.93025559922245, 4.522766370125339],
              [-51.93092749258162, 4.516079958318016],
              [-51.930301532051914, 4.50800466515318],
              [-51.933395627792976, 4.506346724703576],
              [-51.944534506571244, 4.508204657363137],
              [-51.95320558541431, 4.520623620395611],
              [-51.958377554954524, 4.541950199578935],
              [-51.961681533551285, 4.548779439016471],
              [-51.96210062871046, 4.562031563225332],
              [-51.966861549718544, 4.585634667313229],
              [-51.976707603749844, 4.606039237146433],
              [-52.011180527523834, 4.588918529342379],
              [-52.030357483816786, 4.569697148962575],
              [-52.045909602259115, 4.555023118697704],
              [-52.094421543312805, 4.568023115258768],
              [-52.13055056351776, 4.566719058761521],
              [-52.19752448452286, 4.586063653118174],
              [-52.22597149046037, 4.608973070898628],
              [-52.23826556313536, 4.626770701203725],
              [-52.255466569571894, 4.676119156195114],
              [-52.2560155842304, 4.697073578877109],
              [-52.240932517090215, 4.73502465719605],
              [-52.21701056540522, 4.756434049582822],
              [-52.171737559379594, 4.791176535363206],
              [-52.21167347237231, 4.779539603897774],
              [-52.259311516200114, 4.783242225809943],
              [-52.30510755298437, 4.810157187483583],
              [-52.33930555033396, 4.835577488181627],
              [-52.37921950274034, 4.864658165999799],
              [-52.400611628406566, 4.889713350995123],
              [-52.43104162499915, 4.924342848720585],
              [-52.452640616035936, 4.94242747539073],
              [-52.48016762428004, 4.957197394627997],
              [-52.51013158705565, 4.968549509023262],
              [-52.544231516138, 4.99748132424088],
              [-52.587406531796844, 5.034831587739632],
              [-52.61254151251052, 5.0518684765118],
              [-52.654552617093344, 5.089194767767481],
              [-52.6864315094702, 5.114585899442432],
              [-52.71704456682831, 5.143433560352094],
              [-52.7589565973155, 5.18426449296669],
              [-52.82233451421695, 5.247867883063748],
              [-52.85323356211167, 5.267426383589623],
              [-52.89434059461715, 5.296610494093045],
              [-52.94813162593371, 5.330842857245671],
              [-52.99642546986658, 5.354432215012366],
              [-53.06112253069098, 5.376194653161235],
              [-53.10917262887928, 5.407942284934222],
              [-53.10950857555886, 5.434744090914933],
              [-53.095535607676254, 5.47376520299423],
              [-53.1550675721211, 5.494040859156428],
              [-53.15713153196094, 5.493415904455105],
              [-53.176559610073255, 5.501450461570414],
              [-53.195980479748854, 5.505964116434598],
              [-53.202793625932316, 5.505329103449469],
              [-53.2048495391451, 5.501598318342587],
              [-53.1967816220551, 5.494576798045955],
              [-53.19863553140118, 5.493125052414655],
              [-53.211437547409076, 5.49206390347166],
              [-53.22445263139589, 5.495968864526674],
              [-53.223201548526774, 5.489141133831652],
              [-53.23435551473078, 5.491601892968163],
              [-53.243251563855296, 5.497999464391853],
              [-53.25585962262353, 5.502734066223923],
              [-53.25999860641542, 5.508107871992763],
              [-53.270961632864925, 5.515742277050151],
              [-53.26993552027723, 5.518436053095172],
              [-53.260837467285995, 5.513901443473003],
              [-53.25856748026591, 5.514320370994142],
              [-53.257125625280324, 5.516393383289426],
              [-53.25899160456699, 5.518873085527161],
              [-53.27884246587587, 5.530423180475566],
              [-53.2854495848791, 5.530201060041236],
              [-53.2866745162103, 5.5237816956693],
              [-53.29018753947247, 5.525016517646236],
              [-53.291229577676205, 5.528325190108831],
              [-53.290756503060535, 5.53405002998295],
              [-53.29455551685932, 5.537840494240527],
              [-53.3028145413419, 5.538029757614368],
              [-53.306934582032596, 5.532638182210803],
              [-53.30527161244103, 5.527881284516241],
              [-53.3091924991121, 5.527252474139402],
              [-53.31621955146481, 5.529099342686834],
              [-53.3819045033984, 5.535576709828774],
              [-53.40855459693147, 5.539655511555793],
              [-53.43315548277431, 5.550154683483129],
              [-53.46579360794149, 5.554838323343859],
              [-53.48563357277625, 5.560794839022037],
              [-53.5079805648531, 5.5772998122182],
              [-53.5133666082006, 5.583910116344668],
              [-53.55536648103316, 5.612992135267348],
              [-53.56301848808721, 5.616698780493039],
              [-53.57666053843214, 5.62038966774071],
              [-53.592601577182165, 5.634632700543875],
              [-53.61064161212734, 5.644220759595214],
              [-53.63187448163309, 5.619756498774279],
              [-53.65575452380216, 5.604164650110874],
              [-53.67221859331083, 5.601171137207984],
              [-53.696571542457505, 5.607751601759105],
              [-53.74389258270692, 5.627870013417578],
              [-53.77708860734998, 5.651056369279218],
              [-53.81850057349327, 5.673332618093241],
              [-53.871311592689636, 5.705298514625099],
              [-53.91267058520481, 5.692572271021959],
              [-53.92472862348616, 5.681273968445169],
              [-53.95107260991499, 5.662245539476601],
              [-53.97599452264974, 5.651340180521004],
              [-53.999229493549834, 5.64995046097323],
              [-54.00420348253684, 5.627690975965606],
              [-54.00378053170222, 5.624587324854929],
              [-54.0099675497179, 5.621878461384142],
              [-54.01161559952179, 5.62096952780297],
              [-54.01408759040859, 5.618553695667458],
              [-54.014079543781534, 5.617105805711617],
              [-54.02165259330759, 5.615305037631686],
              [-54.027103512585725, 5.61996252595452],
              [-54.028999499085785, 5.623583340491621],
              [-54.03229861617871, 5.629881837819653],
              [-54.03168455795151, 5.631747649468252],
              [-54.03667849586809, 5.643115857117607],
              [-54.03777350769994, 5.662568410387223],
              [-54.03263456285829, 5.670656779321007],
              [-54.02378059088777, 5.679377143754778],
              [-54.02029757483899, 5.688080744382205],
              [-54.01759357287207, 5.702488397763886],
              [-54.07608450494155, 5.706948240809652],
              [-54.10378652732413, 5.719476168583469],
              [-54.12904354854814, 5.735432294759221],
              [-54.18011062896822, 5.749851012253089],
              [-54.20884362544233, 5.76708588157851],
              [-54.22730661122213, 5.775830050617287],
              [-54.27955654581942, 5.790291180541317],
              [-54.32848758203228, 5.798810379298686],
              [-54.37738760520335, 5.808498853550134],
              [-54.41579449141737, 5.81669434320662],
              [-54.46218162001411, 5.832146884639144],
              [-54.49818055308165, 5.84260733217377],
              [-54.600639596489174, 5.864471526627256],
              [-54.67523149402142, 5.878465784543948],
              [-54.69280247766446, 5.879008596594076],
              [-54.78993247466613, 5.885514294568793],
              [-54.84141948843569, 5.889441886762427],
              [-54.88920957998724, 5.899101024352717],
              [-54.957042478430566, 5.905868572982854],
              [-55.01104356024091, 5.905191650481811],
              [-55.08135247288456, 5.90852446282554],
              [-55.09592860252023, 5.908172590529887],
              [-55.11337653982454, 5.91254928509602],
              [-55.10419852347695, 5.902612874148417],
              [-55.094886564316496, 5.898721156500471],
              [-55.09197653516934, 5.895009482132821],
              [-55.08966447099526, 5.886327003901442],
              [-55.093158551156236, 5.881965396761018],
              [-55.0929335808748, 5.877827754073621],
              [-55.102130540323586, 5.8770066628378],
              [-55.10549956248906, 5.849594654305349],
              [-55.11223559516324, 5.821718958888823],
              [-55.12931053777591, 5.801176255291182],
              [-55.14946348287552, 5.794768793221806],
              [-55.16826258297297, 5.794169990058379],
              [-55.2312276073236, 5.807790918007299],
              [-55.28482049044891, 5.821126023057673],
              [-55.3265766175369, 5.837613561895239],
              [-55.42451463000543, 5.860495989947424],
              [-55.483009585388444, 5.868129556814495],
              [-55.541473527729664, 5.876932734451714],
              [-55.58053252601135, 5.867577524670821],
              [-55.63856161521542, 5.852936686742567],
              [-55.6810075729354, 5.848361843985174],
              [-55.71993262840421, 5.85064876244968],
              [-55.71910851968323, 5.847846021663258],
              [-55.71706048545944, 5.844909002787858],
              [-55.7085996247482, 5.840305326283499],
              [-55.70221747602642, 5.837924530503358],
              [-55.69226849222403, 5.834696827225514],
              [-55.68226653479351, 5.832898908992718],
              [-55.67869953207486, 5.832052169133135],
              [-55.67588053039627, 5.83051828085064],
              [-55.66872758185775, 5.829539274558783],
              [-55.65875261415549, 5.827027553450932],
              [-55.65736758847349, 5.825545465330038],
              [-55.65324352446926, 5.820386068644609],
              [-55.65047850224718, 5.817423065869377],
              [-55.649173607559604, 5.813798228018754],
              [-55.6493605240006, 5.808797919855749],
              [-55.648933549852444, 5.800913398807381],
              [-55.6497844806636, 5.797367686122811],
              [-55.653587517775904, 5.791783997498271],
              [-55.65731059153184, 5.788343226241523],
              [-55.66241450001819, 5.786384543105669],
              [-55.67619351066112, 5.783312743227043],
              [-55.68412061177766, 5.782886774907297],
              [-55.69417554283628, 5.78325574628542],
              [-55.70500160898776, 5.782221587070694],
              [-55.708671541477514, 5.780209930306683],
              [-55.71516047564586, 5.779731826549096],
              [-55.72239657266395, 5.778565903816343],
              [-55.727580612144735, 5.774462124017816],
              [-55.73412654325473, 5.772555408681683],
              [-55.74000561014745, 5.769192589124543],
              [-55.74303449468175, 5.765011025264528],
              [-55.75045348482732, 5.758842279797761],
              [-55.7580065854238, 5.749099155537579],
              [-55.764202488256856, 5.737162151938151],
              [-55.76696348716543, 5.720803191495406],
              [-55.76919558798312, 5.71873738763685],
              [-55.77841953716016, 5.70261462922582],
              [-55.813960483037775, 5.694605217820026],
              [-55.859935557274014, 5.696276904590889],
              [-55.8758735785389, 5.693276350889391],
              [-55.88587955928293, 5.695072089827363],
              [-55.91595047514316, 5.699027845215653],
              [-55.92316058826138, 5.698573713701194],
              [-55.93534854604208, 5.699732595635282],
              [-55.94392055078953, 5.701474690392899],
              [-55.96183049859731, 5.703557425695863],
              [-55.97689462263631, 5.704819237401068],
              [-55.98046162535496, 5.70566446851808],
              [-56.03441660669779, 5.7089262023228],
              [-56.07528659898125, 5.718348467329179],
              [-56.12884159590418, 5.736357153956419],
              [-56.19322550882566, 5.746478302050207],
              [-56.21783863224714, 5.749554125242355],
              [-56.258834520716405, 5.755462528796215],
              [-56.3300785184792, 5.772823461945507],
              [-56.39884147289975, 5.794806679424198],
              [-56.48536249504238, 5.813796384000057],
              [-56.52752262986381, 5.820912955078711],
              [-56.56622657072664, 5.82557748420021],
              [-56.62607553874987, 5.832041105020949],
              [-56.67060456704894, 5.839224563687026],
              [-56.72198060442035, 5.85365350710282],
              [-56.78530856517881, 5.862563805462742],
              [-56.83199358661467, 5.876855788580485],
              [-56.867088615242935, 5.884935105058844],
              [-56.930942624245176, 5.916172781842192],
              [-56.97022256949478, 5.941992899322031],
              [-57.01068453073127, 5.967850064814002],
              [-57.01075762092704, 5.964491436208448],
              [-57.00885057031478, 5.957674769625612],
              [-57.001354634297996, 5.949240563366516],
              [-57.008178509317545, 5.950229460304115],
              [-57.01419051319476, 5.953293045917576],
              [-57.01526658192543, 5.959494816082838],
              [-57.01756657615891, 5.963410338335848],
              [-57.02066754506059, 5.963804455423542],
              [-57.02230453075228, 5.961103470941794],
              [-57.03342061075389, 5.955234127056769],
              [-57.06416358761136, 5.94902548373085],
              [-57.06887455247647, 5.9429920221815],
              [-57.07578258180398, 5.925767211139885],
              [-57.075714520750125, 5.915834823505804],
              [-57.079383615049565, 5.909186465538937],
              [-57.08068850973713, 5.888275964029617],
              [-57.082103542632524, 5.883920224221413],
              [-57.08951549197941, 5.880350539293715],
              [-57.09366252239835, 5.882185337900523],
              [-57.09881957215087, 5.881322504786851],
              [-57.10272553903427, 5.878605762327084],
              [-57.10805156795497, 5.872153205618588],
              [-57.113750591567324, 5.859697026936033],
              [-57.11697762429287, 5.848498971921231],
              [-57.11985362291311, 5.845995967992678],
              [-57.125598579354914, 5.840576397032464],
              [-57.132480624782545, 5.837602497783053],
              [-57.13631048398504, 5.835949921751421],
              [-57.13844652819226, 5.816480269399335],
              [-57.14112856957284, 5.81584039491031],
              [-57.14260847839887, 5.820796949538703],
              [-57.13899252536562, 5.835101840787331],
              [-57.14053747776035, 5.849372869147146],
              [-57.14262054833945, 5.851842513101076],
              [-57.136959578567556, 5.869679370713072],
              [-57.134777601530885, 5.882318442523115],
              [-57.13405659021906, 5.897846253446176],
              [-57.139690570262715, 5.935473455026113],
              [-57.14550358775837, 5.998551970345829],
              [-57.14622459907018, 5.999539861454991],
              [-57.14886456329678, 6.005979342394596],
              [-57.15658161391954, 6.016479687788376],
              [-57.16113650774737, 6.047282343796496],
              [-57.164562526854496, 6.064021004453423],
              [-57.173427562937235, 6.090654166542095],
              [-57.182041477200585, 6.110873496314923],
              [-57.21253148821998, 6.155566307002175],
              [-57.22585653498652, 6.169544471664722],
              [-57.23629351319224, 6.184162343178286],
              [-57.24482360842376, 6.1919665655941],
              [-57.25645852823243, 6.200575115439449],
              [-57.29179361456785, 6.228053005730885],
              [-57.29533748323374, 6.232373709183832],
              [-57.307807575875586, 6.242217081006061],
              [-57.31552160901319, 6.251267524787238],
              [-57.32777058704906, 6.258835712809457],
              [-57.33650553599439, 6.266222348808697],
              [-57.34955951201198, 6.270474823569714],
              [-57.35870752114617, 6.277652414903571],
              [-57.36845047776832, 6.281514292976226],
              [-57.38986255236412, 6.298328055485683],
              [-57.397746570498285, 6.302408701231457],
              [-57.40540662417939, 6.304009644739438],
              [-57.41368459176317, 6.305398526096894],
              [-57.44460258275909, 6.321727311688164],
              [-57.4537205846799, 6.324556874564792],
              [-57.493617605641845, 6.324682100198345],
              [-57.50516853878061, 6.321493121313267],
              [-57.5131534750291, 6.311091011824772],
              [-57.51594951029297, 6.298034856512345],
              [-57.51549152310304, 6.291832248156766],
              [-57.51318750555602, 6.288124764740758],
              [-57.51624656494178, 6.282309232674152],
              [-57.51850850533481, 6.28084307016934],
              [-57.5203245284785, 6.274830731015982],
              [-57.53067752001432, 6.277444543704632],
              [-57.53908959804903, 6.296210116189343],
              [-57.54849258467806, 6.310004214258015],
              [-57.556827549203476, 6.319047449602465],
              [-57.562496565602444, 6.330591677218649],
              [-57.565299474026915, 6.345880436262973],
              [-57.56846263665021, 6.354339955869705],
              [-57.5911024924243, 6.395963648887403],
              [-57.60173460133612, 6.407678029138253],
              [-57.60903557428486, 6.416519260615928],
              [-57.61262152010471, 6.425595688296937],
              [-57.616420533903494, 6.435911967096843],
              [-57.62977257039827, 6.451950738837979],
              [-57.63985063550969, 6.472356146861443],
              [-57.64692362069184, 6.477681002315705],
              [-57.69240952705832, 6.505060991977984],
              [-57.71573653125536, 6.527021410680049],
              [-57.73983349707882, 6.54152512067219],
              [-57.74420147446567, 6.54480210954074],
              [-57.769233525408254, 6.57315775273355],
              [-57.7813105067908, 6.584236952361152],
              [-57.78941748354964, 6.589346728179692],
              [-57.791343477263084, 6.589119746241522],
              [-57.794513513046994, 6.585222999451616],
              [-57.80724361232558, 6.585686015783494],
              [-57.81565049358032, 6.588101847918949],
              [-57.83026450941837, 6.59496226803634],
              [-57.86539457440195, 6.621558214474874],
              [-57.89188759106936, 6.652062139453562],
              [-57.91571449197225, 6.697238753592558],
              [-57.91394456929608, 6.700449525425938],
              [-57.917671498727486, 6.699847704777369],
              [-57.92313348211782, 6.710766977692231],
              [-57.940875624223864, 6.73131152530857],
              [-57.97668462336527, 6.760814315771029],
              [-57.987945542653904, 6.772100548407309],
              [-57.999999557621734, 6.78036577549824],
              [-58.00622563530625, 6.796015626931705],
              [-58.01882547980938, 6.802777308229622],
              [-58.030013476540304, 6.813896238078314],
              [-58.03987461799734, 6.818108982618241],
              [-58.10311155128723, 6.81995601880368],
              [-58.12309652304705, 6.823551687631209],
              [-58.13412056975173, 6.82593164522109],
              [-58.14877347762055, 6.835465892453954],
              [-58.156188612090645, 6.827933411339302],
              [-58.16138053056041, 6.809348720325261],
              [-58.170822576858285, 6.807347792397366],
              [-58.17171860230857, 6.811262644098122],
              [-58.208770637692055, 6.840500398781955],
              [-58.21536652494501, 6.851352784109451],
              [-58.227088616546794, 6.852355595006259],
              [-58.235561547198614, 6.857706099084282],
              [-58.24489261709833, 6.872256077181987],
              [-58.257953633914596, 6.879016752651523],
              [-58.271064606873836, 6.879109456500771],
              [-58.29284649103808, 6.889151814704576],
              [-58.30779662119379, 6.889716922617197],
              [-58.333064538891946, 6.894494439793618],
              [-58.33792855730918, 6.889931834614856],
              [-58.34669150181114, 6.886545210452653],
              [-58.35705555745916, 6.885009310513396],
              [-58.371303619404245, 6.886719051124714],
              [-58.386070521156356, 6.879925686232752],
              [-58.40334662944542, 6.876598741221244],
              [-58.41235348733001, 6.871144133905716],
              [-58.44868451140168, 6.838753442520499],
              [-58.455673509913936, 6.82569862831258],
              [-58.464225565731795, 6.819779831198787],
              [-58.47325555766918, 6.810646741852224],
              [-58.480701537542984, 6.798283602294987],
              [-58.48585154649683, 6.784523367115185],
              [-58.496211578831335, 6.748499120700046],
              [-58.521709495952834, 6.718555442130139],
              [-58.53657547180059, 6.696815802757953],
              [-58.5472675951392, 6.679184134135824],
              [-58.55361152238247, 6.659222464066886],
              [-58.56385756083367, 6.639978117272335],
              [-58.57052251496887, 6.605532518502685],
              [-58.56740947612661, 6.589415627423875],
              [-58.56962162801473, 6.567816301110952],
              [-58.572212474288676, 6.558404932578753],
              [-58.57353262404004, 6.532888742908312],
              [-58.5702284778052, 6.517904917501824],
              [-58.571857584507896, 6.507812770793066],
              [-58.57607250834262, 6.496343645029356],
              [-58.574596622830114, 6.475635985577071],
              [-58.57770563835885, 6.457719164608704],
              [-58.585903474948196, 6.434548063810837],
              [-58.58500661130759, 6.431091366938062],
              [-58.59053749394208, 6.428831438201826],
              [-58.604862501758376, 6.418119030657465],
              [-58.609462490225354, 6.41768920666226],
              [-58.61451761603517, 6.418414241287621],
              [-58.615650514069415, 6.420720941043612],
              [-58.620201552221786, 6.427880595104682],
              [-58.61420463577031, 6.431059180429827],
              [-58.61299847990223, 6.439561783018917],
              [-58.613681605011664, 6.440485468749728],
              [-58.61737450391614, 6.438670619072468],
              [-58.62201355205194, 6.432262318812775],
              [-58.62813552649892, 6.445412183802773],
              [-58.62418362678609, 6.451824842652172],
              [-58.62179159925566, 6.46606754017921],
              [-58.614330531956114, 6.482115364375773],
              [-58.613601474017244, 6.487858141522736],
              [-58.61516553715123, 6.494998517206511],
              [-58.613532574773075, 6.498436270978061],
              [-58.6075364965119, 6.501615191579333],
              [-58.60173454312846, 6.509394603561816],
              [-58.597999567070005, 6.518108933025303],
              [-58.59998356355348, 6.530998791378579],
              [-58.6127545665196, 6.546951900069189],
              [-58.613182546496134, 6.551784402530416],
              [-58.60821962162133, 6.572675793300505],
              [-58.606277534653785, 6.588068655582333],
              [-58.61299563005515, 6.615938818942766],
              [-58.60590755744727, 6.644632588109971],
              [-58.601364565921955, 6.671273629187624],
              [-58.60396161480425, 6.695892787579396],
              [-58.601844513698225, 6.703237346424658],
              [-58.5962796005368, 6.70986692892842],
              [-58.591598475247025, 6.722480016838574],
              [-58.590869584946205, 6.728223464537848],
              [-58.59332648840727, 6.73950667968893],
              [-58.59177348938549, 6.765707000297198],
              [-58.5920294727087, 6.796976528312598],
              [-58.58712354477555, 6.808437775087327],
              [-58.58613163035284, 6.819005846258847],
              [-58.581035600855486, 6.824489454959348],
              [-58.56907261335623, 6.860040962034986],
              [-58.55016756600254, 6.935775480609152],
              [-58.542213475157766, 6.955719045767239],
              [-58.53456448558886, 6.964402529827055],
              [-58.51171457932101, 6.975968550391542],
              [-58.48835756791057, 6.994197345796408],
              [-58.48370762330062, 7.001290615184246],
              [-58.47949554931297, 7.011605049965453],
              [-58.479904586188326, 7.019652515211703],
              [-58.476146476077076, 7.030660468662234],
              [-58.47675349350561, 7.043536580694308],
              [-58.47471652339402, 7.072713482761003],
              [-58.47225559661942, 7.096369895753128],
              [-58.46683451691662, 7.115409556471946],
              [-58.46608752170492, 7.123907800471386],
              [-58.47024159292255, 7.156343418857659],
              [-58.4789805651814, 7.190877027610725],
              [-58.479804506264315, 7.20582095515806],
              [-58.47858058076149, 7.216382991359296],
              [-58.47658149685229, 7.240269403774789],
              [-58.46926862160103, 7.266417421307153],
              [-58.46818953538522, 7.290078863441238],
              [-58.46812851513005, 7.299270123195868],
              [-58.472717607122874, 7.335378523918962],
              [-58.476528523224175, 7.350800387585821],
              [-58.48540160593396, 7.365797456399378],
              [-58.53091450202868, 7.409537244694661],
              [-58.54026753251473, 7.421778176103487],
              [-58.57238748842714, 7.472543675647046],
              [-58.59064863797829, 7.495873026776962],
              [-58.606197571297415, 7.511598650615213],
              [-58.61029850124885, 7.518289421012184],
              [-58.618911577321896, 7.539024740744992],
              [-58.634666537821246, 7.55865733729496],
              [-58.64701458995275, 7.571374528442675],
              [-58.6624835599155, 7.60019335560537],
              [-58.67209257372477, 7.609675635038514],
              [-58.68355549688012, 7.617103174725344],
              [-58.701915552888835, 7.625954632124888],
              [-58.71267657547162, 7.634983785871952],
              [-58.71912360012405, 7.635025695387867],
              [-58.72190052464866, 7.632976990611837],
              [-58.72331958085758, 7.62724427174868],
              [-58.72154261738274, 7.617125135311653],
              [-58.7111545894916, 7.585815541799036],
              [-58.71438950120614, 7.583539519808653],
              [-58.7261965853062, 7.609575555114532],
              [-58.730979634538684, 7.617647160241916],
              [-58.73826552006169, 7.624494504590359],
              [-58.74534252855737, 7.589421101272308],
              [-58.75509654929172, 7.600769192354051],
              [-58.84394857870963, 7.714813199427283],
              [-58.922809547171155, 7.764904458678757],
              [-59.04027152672444, 7.890277446094444],
              [-59.09817857541816, 7.947809153163632],
              [-59.125678593934026, 7.98780541585927],
              [-59.13470053924435, 7.993008063165121],
              [-59.18651562070448, 8.022879825005703],
              [-59.247058610311285, 8.066150729636945],
              [-59.368633589756584, 8.140400310243194],
              [-59.44515651014419, 8.200679269899751],
              [-59.55054452580006, 8.266124499402281],
              [-59.635627548632584, 8.320559428521392],
              [-59.6887285816791, 8.343031813869857],
              [-59.76071957465358, 8.360034839753212],
              [-59.7658955675073, 8.359848593864456],
              [-59.77481860636006, 8.345585612131742],
              [-59.77952957122517, 8.339420051788181],
              [-59.77783558859183, 8.33791516489066],
              [-59.7610474747061, 8.328397849102203],
              [-59.74874451721374, 8.324981217726645],
              [-59.73563756756802, 8.320262373872538],
              [-59.72233548721621, 8.309177306912716],
              [-59.720210507121195, 8.306586628276818],
              [-59.73659863658702, 8.311604203160414],
              [-59.77153357086448, 8.323683196199738],
              [-59.82476452341034, 8.34896251328621],
              [-59.83688760526039, 8.351997265152704],
              [-59.84376948304997, 8.354842083093104],
              [-59.856929573961864, 8.36526129166407],
              [-59.86284652705697, 8.370664937008257],
              [-59.86819451656402, 8.379504324467234],
              [-59.87409554404309, 8.385337961444748],
              [-59.880756474864754, 8.394225293389923],
              [-59.889148603969886, 8.403608666365528],
              [-59.89411152884469, 8.411137124166657],
              [-59.900497533241946, 8.415692856184819],
              [-59.9092715418561, 8.42095920595483],
              [-59.925811551407605, 8.425256104802713],
              [-59.94176063678469, 8.4311256163258],
              [-59.96553456405947, 8.444176742496325],
              [-59.98730454592119, 8.45290515355714],
              [-59.998340494928385, 8.460729492540679],
              [-60.016677584522384, 8.475183581665988],
              [-60.03090251241467, 8.484180884180944],
              [-60.04614651209597, 8.494809137417292],
              [-60.062431544152645, 8.50653508469452],
              [-60.09415050981676, 8.523579852455725],
              [-60.121700484475596, 8.536760898125578],
              [-60.15036357587715, 8.548917172312258],
              [-60.18075954194282, 8.557942638021927],
              [-60.20043153343778, 8.56553295426852],
              [-60.203140564546615, 8.56509608947465],
              [-60.1884455795238, 8.553957881248607],
              [-60.17041057372053, 8.54589247872957],
              [-60.14998655787197, 8.529239481123113],
              [-60.12664463388727, 8.518864361362887],
              [-60.095420535787085, 8.502898344541393],
              [-60.07732350390026, 8.481543937439483],
              [-60.0746035763173, 8.46710091242636],
              [-60.09863248108691, 8.457837735942462],
              [-60.13405255992062, 8.461721406963363],
              [-60.17908450241069, 8.468061981445373],
              [-60.199809596221584, 8.476219249623284],
              [-60.24068847332242, 8.493575153630673],
              [-60.255996510744055, 8.502607324862879],
              [-60.274612550075986, 8.509627839331074],
              [-60.30874248637173, 8.519839344341165],
              [-60.332370568531104, 8.522246124021194],
              [-60.34683253664542, 8.524869994993708],
              [-60.37443548493238, 8.521563166549868],
              [-60.39065949673386, 8.519993236083621],
              [-60.42230956315382, 8.508847148868597],
              [-60.431144592023145, 8.502768592680127],
              [-60.43444857061992, 8.500754588983227],
              [-60.44207761125925, 8.498357867587004],
              [-60.454013609030255, 8.496105985477811],
              [-60.46858554771434, 8.495540039374873],
              [-60.500076525611874, 8.50458830386117],
              [-60.519832503776726, 8.51004609629996],
              [-60.55812053460362, 8.525172749336662],
              [-60.59004552744797, 8.53742407430542],
              [-60.606700536711145, 8.53905200754167],
              [-60.63845856204409, 8.540659656572245],
              [-60.66471856180303, 8.545270541513332],
              [-60.69879552447064, 8.542166722764591],
              [-60.74199250071584, 8.540430662977258],
              [-60.77499758560438, 8.537285940540983],
              [-60.801483561473134, 8.535516017864836],
              [-60.825851598045546, 8.532609173840854],
              [-60.86093556256161, 8.531656486725069],
              [-60.89721260717677, 8.52754868361302],
              [-60.94329463449763, 8.520572090317444],
              [-60.97711159052851, 8.525419680204436],
              [-60.98992655003069, 8.530707032050813],
              [-60.99178108530552, 8.515870749852166],
              [-61.00462631598335, 8.507307262733608],
              [-61.02175329022047, 8.507307262733608],
              [-61.03888026445758, 8.507307262733608],
              [-61.05172549513542, 8.49874377561505],
              [-61.0667115975929, 8.490180288496493],
              [-61.083838571830015, 8.488039416716854],
              [-61.10096554606713, 8.488039416716854],
              [-61.12023339208388, 8.488039416716854],
              [-61.13521949454136, 8.492321160276132],
              [-61.15020559699883, 8.490180288496493],
              [-61.16947344301559, 8.490180288496493],
              [-61.18445954547306, 8.483757673157575],
              [-61.19944564793054, 8.475194186039017],
              [-61.214431750388016, 8.470912442479738],
              [-61.23155872462513, 8.470912442479738],
              [-61.25296744242152, 8.470912442479738],
              [-61.267953544879, 8.473053314259378],
              [-61.28293964733648, 8.477335057818657],
              [-61.297925749793954, 8.479475929598296],
              [-61.31505272403107, 8.479475929598296],
              [-61.33003882648854, 8.475194186039017],
              [-61.34502492894602, 8.477335057818657],
              [-61.360011031403495, 8.481616801377935],
              [-61.37499713386097, 8.483757673157575],
              [-61.38998323631844, 8.485898544937214],
              [-61.40496933877592, 8.488039416716854],
              [-61.42423718479267, 8.488039416716854],
              [-61.43922328725015, 8.494462032055772],
              [-61.45420938970763, 8.50088464739469],
              [-61.471336363944744, 8.50302551917433],
              [-61.48846333818186, 8.505166390953969],
              [-61.499167697080054, 8.518011621631805],
              [-61.50773118419861, 8.532997724089281],
              [-61.52271728665609, 8.5394203394282],
              [-61.533421645554284, 8.550124698326396],
              [-61.54840774801176, 8.554406441885675],
              [-61.56339385046924, 8.558688185444954],
              [-61.580520824706355, 8.565110800783872],
              [-61.595506927163825, 8.57153341612279],
              [-61.614774773180585, 8.577956031461708],
              [-61.629760875638055, 8.582237775020987],
              [-61.64474697809553, 8.588660390359905],
              [-61.66187395233265, 8.586519518580268],
              [-61.676860054790126, 8.588660390359905],
              [-61.69612790080688, 8.590801262139545],
              [-61.711114003264356, 8.592942133919186],
              [-61.726100105721834, 8.590801262139545],
              [-61.74322707995895, 8.588660390359905],
              [-61.7624949259757, 8.588660390359905],
              [-61.77962190021282, 8.588660390359905],
              [-61.79674887444993, 8.586519518580268],
              [-61.80959410512777, 8.577956031461708],
              [-61.82458020758524, 8.56939254434315],
              [-61.83742543826308, 8.560829057224593],
              [-61.85241154072056, 8.547983826546757],
              [-61.858940100050326, 8.5451858703565],
              [-61.88380049804752, 8.540331924157783],
              [-61.92880260096226, 8.549783022911072],
              [-62.00050760340012, 8.584723489244652],
              [-62.0346415630094, 8.599786439817194],
              [-62.067592500803386, 8.614815527500866],
              [-62.117690633215474, 8.61258560597804],
              [-62.16075551066649, 8.650305008492978],
              [-62.167549546110706, 8.663468955080305],
              [-62.20738554681748, 8.644434491141453],
              [-62.264903507565435, 8.588070383185709],
              [-62.2918545114228, 8.560426195935122],
              [-62.338794510353495, 8.542738703837813],
              [-62.37492353055845, 8.528311604440717],
              [-62.403816621383356, 8.517238272145335],
              [-62.44458049877251, 8.50764618978053],
              [-62.492675523961864, 8.491148425021038],
              [-62.52174748460071, 8.472984673184897],
              [-62.54028657806141, 8.447467310048012],
              [-62.57656060519143, 8.427116719671346],
              [-62.609199568548945, 8.409033266467645],
              [-62.6528285480842, 8.380589278015293],
              [-62.68341461571407, 8.3494525193463],
              [-62.69009348380854, 8.321265520045529],
              [-62.68593555691545, 8.297536184495698],
              [-62.71663260094347, 8.26167085896492],
              [-62.7436986045125, 8.228074011711612],
              [-62.76090262843418, 8.207229559599398],
              [-62.770816575881256, 8.192112797208381],
              [-62.78664747642347, 8.178321884262914],
              [-62.81608153527982, 8.144773987324243],
              [-62.8822325210304, 8.119201974178566],
              [-62.912731584505224, 8.090396055146755],
              [-62.94438550660064, 8.062795956706907],
              [-62.97380062235581, 8.029231295961836],
              [-62.99157360986557, 7.98474769757803],
              [-63.0022315350392, 7.93890908072558],
              [-63.0080415350497, 7.896500003379629],
              [-63.00069848494698, 7.856139798447771],
              [-62.98595857292311, 7.8262420527073],
              [-62.96364561137317, 7.765432518579303],
              [-62.944320630670006, 7.728333209261848],
              [-62.90451849285209, 7.701383211232894],
              [-62.86054250252505, 7.69915127805325],
              [-62.814994570075015, 7.712241128616483],
              [-62.75426064071371, 7.761595786216219],
              [-62.717719566148276, 7.794981409509262],
              [-62.672790553429294, 7.830520846644333],
              [-62.6364786400969, 7.85444816274736],
              [-62.5942496060313, 7.877044264986807],
              [-62.53747159969636, 7.90991104847285],
              [-62.53286356460234, 7.911959920886943],
              [-62.53100948761819, 7.910138030411019],
              [-62.52766058202036, 7.910455033989422],
              [-62.522808633543704, 7.912288826767849],
              [-62.51788761818483, 7.918617331309292],
              [-62.48208951551754, 7.934532386159447],
              [-62.43164453995175, 7.952179812759482],
              [-62.37308856431356, 7.961349279565866],
              [-62.319595593474205, 7.958826494345772],
              [-62.2546465726401, 7.939474021000137],
              [-62.20726048882199, 7.931190353722229],
              [-62.16182755608362, 7.939487767321395],
              [-62.118831577876776, 7.945480325183212],
              [-62.06995351529204, 7.948956132795217],
              [-62.01723050607909, 7.918084409904907],
              [-61.961948501824295, 7.894228004702768],
              [-61.93298349427006, 7.86514213010463],
              [-61.90721148891447, 7.805436660263922],
              [-61.87931852677738, 7.736229464974656],
              [-61.877944565207585, 7.69840528866996],
              [-61.882789640523626, 7.650111780013219],
              [-61.89476050701188, 7.601998146998767],
              [-61.9212645877915, 7.543623555745512],
              [-61.93812947991039, 7.489722386221104],
              [-61.95440663297806, 7.458243645902201],
              [-61.974338631109774, 7.423313237852483],
              [-62.01197857318253, 7.370208852044698],
              [-62.01728448553558, 7.370157051883041],
              [-62.032928637274864, 7.370923828386253],
              [-62.04677554133359, 7.375389706402245],
              [-62.07543963856354, 7.389369715083546],
              [-62.09162157321106, 7.397492114544207],
              [-62.107337474041586, 7.404697533796707],
              [-62.12248659057886, 7.402250688619404],
              [-62.13109262585323, 7.390207402487647],
              [-62.131374593076316, 7.373646773454311],
              [-62.13074460923306, 7.358014021103372],
              [-62.12641552387697, 7.34057714791129],
              [-62.1239664994049, 7.326802998772166],
              [-62.11917858866856, 7.30937031653167],
              [-62.11349163499679, 7.293786682133373],
              [-62.109664625641386, 7.28002544112519],
              [-62.09844963918219, 7.26357679431834],
              [-62.0863414771198, 7.249895851942654],
              [-62.082988548208434, 7.246836792556905],
              [-62.08175657607856, 7.243265095972447],
              [-62.071197557362495, 7.223651442523646],
              [-62.06666948562484, 7.194985668913091],
              [-62.0877915463705, 7.152740876869473],
              [-62.104389558692034, 7.125582840003517],
              [-62.10891763042969, 7.096916731116835],
              [-62.11495260072162, 7.074285592522017],
              [-62.12356953247014, 7.061361536003801],
              [-62.13589059487339, 7.069108761477992],
              [-62.15021157937616, 7.074949271616106],
              [-62.16450859163582, 7.078950121643516],
              [-62.18149954757858, 7.076485171555362],
              [-62.19574358621014, 7.075426201907192],
              [-62.212375629058585, 7.082623909808717],
              [-62.22355658499086, 7.095854743983466],
              [-62.22787058292121, 7.11191162063551],
              [-62.23079251437089, 7.12706174300115],
              [-62.23145652874106, 7.145913146174507],
              [-62.232288516451035, 7.15070977409016],
              [-62.23113650767753, 7.178388830057997],
              [-62.23264357386988, 7.214599154723828],
              [-62.24471250862537, 7.255335371832473],
              [-62.29903461732762, 7.29003678628726],
              [-62.353351529249906, 7.28852804371428],
              [-62.369949541571444, 7.280984498487385],
              [-62.382018476326934, 7.267405312416315],
              [-62.42427047680725, 7.22214286758873],
              [-62.469535603843866, 7.207055777134997],
              [-62.49065749695147, 7.223651442523646],
              [-62.48827351604808, 7.249883614364023],
              [-62.48103758666805, 7.260314892875556],
              [-62.470218561315235, 7.280657436625177],
              [-62.457534562504165, 7.298259265671959],
              [-62.450920570340315, 7.324541393655295],
              [-62.454292609990944, 7.338768333204314],
              [-62.466384511161166, 7.351068440849645],
              [-62.48073952619086, 7.360128607638444],
              [-62.494583580402505, 7.364593312188049],
              [-62.51025354840358, 7.367660082924715],
              [-62.53010558317892, 7.374826610146386],
              [-62.5421376375604, 7.381610252030669],
              [-62.55515255390917, 7.39436113842919],
              [-62.57865155475953, 7.398730959834722],
              [-62.59194156517076, 7.394463230009933],
              [-62.60745261228749, 7.38281305513749],
              [-62.61699255921454, 7.372140880728409],
              [-62.62973053748212, 7.359138704872521],
              [-62.644306499479725, 7.346117585915408],
              [-62.65940448640758, 7.339072093375705],
              [-62.67448855937616, 7.330645933743654],
              [-62.69417161498332, 7.322174344196299],
              [-62.70885855337907, 7.319273535142656],
              [-62.728660632011426, 7.321839570983173],
              [-62.74752460803953, 7.322576507911037],
              [-62.76599849029347, 7.329755943263649],
              [-62.782665569497226, 7.340173140177853],
              [-62.80258952100188, 7.353777304320374],
              [-62.81734854376501, 7.357773963396198],
              [-62.83722656244021, 7.367238305556555],
              [-62.85979450148497, 7.370239026896172],
              [-62.88007753372199, 7.374641705362194],
              [-62.901199594467656, 7.37167602037789],
              [-62.92358061707144, 7.357658963684571],
              [-62.93898052015196, 7.335891496393799],
              [-62.94619750643079, 7.322942461804018],
              [-62.954284534260054, 7.305385727396413],
              [-62.95591749663822, 7.286051191323622],
              [-62.95437254424348, 7.270888160827042],
              [-62.94632357025468, 7.249807841959239],
              [-62.93694656752551, 7.233340922603475],
              [-62.92893547973908, 7.215480092748351],
              [-62.922740582734406, 7.195301666663056],
              [-62.933021489902856, 7.168064504631047],
              [-62.94068959021102, 7.154191616672449],
              [-62.95620348717483, 7.143001440646685],
              [-62.97128253100149, 7.134114946891827],
              [-62.98501963212853, 7.128463364851484],
              [-63.00428057509137, 7.123675789391257],
              [-63.02399062042679, 7.117504361715476],
              [-63.04090848617379, 7.108601104154275],
              [-63.0605395739812, 7.095070030207467],
              [-63.070548572210384, 7.085313494902152],
              [-63.081020586771416, 7.075552265731062],
              [-63.098396607346416, 7.066183980181222],
              [-63.13829748398382, 7.056137598663838],
              [-63.153854631568066, 7.049086406429979],
              [-63.167545632227586, 7.03929483476935],
              [-63.182636578356764, 7.031328506345972],
              [-63.19402657895442, 7.02155839235752],
              [-63.205882613369056, 7.012242912797717],
              [-63.21540462302329, 6.999732922296687],
              [-63.228568569610644, 6.98396689768515],
              [-63.24678060120915, 6.966770249838248],
              [-63.26184053429655, 6.956044095972686],
              [-63.273246628148314, 6.947654649076583],
              [-63.29472357867476, 6.93502647374072],
              [-63.32217447923796, 6.921880632064244],
              [-63.34178561811581, 6.906971573234102],
              [-63.360526547805165, 6.896210383013283],
              [-63.38285459678083, 6.877134680110771],
              [-63.446811535554176, 6.837876695447505],
              [-63.47146254517804, 6.821076846897313],
              [-63.48922748606074, 6.805265057094402],
              [-63.4987184826732, 6.789995241151303],
              [-63.50080859405098, 6.770656346488806],
              [-63.499168590874135, 6.746755181923731],
              [-63.496940513369964, 6.735540195464523],
              [-63.49857749906165, 6.721238154062974],
              [-63.51668157174716, 6.683519757376416],
              [-63.542331536592414, 6.659379708570611],
              [-63.56496451920587, 6.648818510559693],
              [-63.56914155683822, 6.646314668440823],
              [-63.57764851801696, 6.647098376388442],
              [-63.591850479494525, 6.641900758224494],
              [-63.604164501099106, 6.632582428817614],
              [-63.61597057937078, 6.618668469533418],
              [-63.62632357090659, 6.597870117888704],
              [-63.62984061748229, 6.583117130095843],
              [-63.63976261155642, 6.565082627206777],
              [-63.646926624207154, 6.547074778769854],
              [-63.65692154083898, 6.535937576372191],
              [-63.670562585355526, 6.521546686796853],
              [-63.68753057488357, 6.517240903131608],
              [-63.70555451657464, 6.525345365319538],
              [-63.71865459305974, 6.545915896835709],
              [-63.73043049647994, 6.571561000177098],
              [-63.736148630831565, 6.589902783636887],
              [-63.74319462628546, 6.603633514517526],
              [-63.7456585705452, 6.618786654368307],
              [-63.75135759415757, 6.63529028646002],
              [-63.747363617290745, 6.648668474492695],
              [-63.73929553256268, 6.668064869011005],
              [-63.73532049879705, 6.683282717154384],
              [-63.74421654792157, 6.697914670265277],
              [-63.76038356278141, 6.704197241977226],
              [-63.78197450719114, 6.702146525544435],
              [-63.79676856630958, 6.709360997252304],
              [-63.80614456321035, 6.72582992826483],
              [-63.806301640076015, 6.740086875027259],
              [-63.79549753451087, 6.761810085869229],
              [-63.78276860869872, 6.775272260571853],
              [-63.7636146188205, 6.790176960812289],
              [-63.74528155254002, 6.795874810958196],
              [-63.727786508939815, 6.794205638758285],
              [-63.71548455727583, 6.804904132343324],
              [-63.71149058040902, 6.818281985099929],
              [-63.71118547913315, 6.832542955175882],
              [-63.713668534132154, 6.849538604984446],
              [-63.70694356557017, 6.865241765322139],
              [-63.69560955608577, 6.8805303567284],
              [-63.685844638877285, 6.870505265245072],
              [-63.67692160002453, 6.85357549719555],
              [-63.6671186289756, 6.839871588405117],
              [-63.64965459841717, 6.840962409285396],
              [-63.63970561461478, 6.856697085579071],
              [-63.63668058575594, 6.874663862690397],
              [-63.621299625776615, 6.898272331196381],
              [-63.60954652113307, 6.916784937843033],
              [-63.593109608990716, 6.927984166324222],
              [-63.58825263137216, 6.946429885383452],
              [-63.58620459714835, 6.969448100267243],
              [-63.583183591603046, 6.987874708587242],
              [-63.57644655310048, 7.002659715250218],
              [-63.567829621351976, 7.013780824393734],
              [-63.55138348911612, 7.024062234476389],
              [-63.54743963603035, 7.042037896405134],
              [-63.54944961641368, 7.057657572987125],
              [-63.53346247719615, 7.067930936442679],
              [-63.517364529218526, 7.067627846823598],
              [-63.505023517885704, 7.074648528929856],
              [-63.499225587815786, 7.091262299229413],
              [-63.50951755909644, 7.107261173111397],
              [-63.527095583538156, 7.116749487514824],
              [-63.540969477325135, 7.123972844040054],
              [-63.55033457775177, 7.139521777359221],
              [-63.53204761193882, 7.149358108382785],
              [-63.521209475846746, 7.167861662574012],
              [-63.536121552162, 7.18565543720365],
              [-63.55536254619528, 7.179029710375403],
              [-63.57923152425215, 7.174657206760799],
              [-63.593948637499366, 7.174974042701137],
              [-63.61707648767677, 7.187166359071455],
              [-63.63241151482666, 7.201737291927202],
              [-63.64832254636329, 7.227340318114614],
              [-63.658214533224026, 7.248861860365992],
              [-63.65434662018109, 7.273737840271281],
              [-63.64573253827966, 7.285320289366041],
              [-63.63385756076383, 7.292794432434505],
              [-63.60864664000732, 7.300399165554609],
              [-63.57830850907361, 7.301614038601997],
              [-63.54887361202694, 7.301440030291928],
              [-63.52311350897385, 7.30077199260819],
              [-63.49411748837785, 7.298753295045572],
              [-63.458286528650106, 7.302321974144888],
              [-63.442707588117585, 7.307533673906164],
              [-63.43133954810628, 7.319602776299689],
              [-63.446643562214376, 7.331412710246809],
              [-63.46823148913896, 7.328903000795719],
              [-63.504032609291386, 7.322575502082657],
              [-63.522865572277695, 7.320552445930389],
              [-63.550075576943414, 7.327647224060797],
              [-63.58322147780544, 7.330544009800917],
              [-63.60074250530552, 7.334972672166828],
              [-63.62662548705926, 7.346680849809331],
              [-63.64784662190054, 7.352913130102195],
              [-63.66357459267165, 7.361499551723114],
              [-63.677440607469634, 7.367804251659493],
              [-63.695758586324374, 7.360725566783174],
              [-63.7047915957469, 7.345459774153596],
              [-63.71918147949384, 7.315421882991927],
              [-63.732967530935454, 7.314368948314041],
              [-63.75101058336578, 7.324312400060307],
              [-63.76544958506537, 7.34119120613849],
              [-63.756961566987826, 7.364270441277426],
              [-63.75530647638526, 7.38130531839289],
              [-63.76965763573949, 7.38990448050663],
              [-63.78288662589554, 7.380116429245334],
              [-63.79311355360748, 7.390136659224822],
              [-63.79242355533745, 7.411300964762518],
              [-63.79903453001613, 7.427335880828196],
              [-63.811534629871375, 7.43503331779749],
              [-63.82233454448493, 7.455626815728408],
              [-63.82678248522815, 7.484100811394171],
              [-63.83438453613924, 7.506564982477528],
              [-63.84604259000069, 7.521170113498215],
              [-63.85942061039535, 7.525178842514606],
              [-63.86339162084744, 7.509501833714864],
              [-63.86775959823429, 7.488301318355411],
              [-63.862384618999, 7.459375370470013],
              [-63.85892456936496, 7.436872307355884],
              [-63.85876849832769, 7.422614354765074],
              [-63.86045460197202, 7.408339135453673],
              [-63.86075953560983, 7.394078165377721],
              [-63.86653148177989, 7.375164736120837],
              [-63.87325661797993, 7.358998894727392],
              [-63.8841934928915, 7.349694479279833],
              [-63.90291547947966, 7.337553460156926],
              [-63.93370858011815, 7.335873391482892],
              [-63.948509512397194, 7.343549035503941],
              [-63.9621125030733, 7.368254024584303],
              [-63.962791604869196, 7.388485759573825],
              [-63.96665549459861, 7.405466321956624],
              [-63.97146955687289, 7.425198160240313],
              [-63.98664448967196, 7.425050974020451],
              [-64.0025255139952, 7.405117299508106],
              [-64.01439663583557, 7.397182989954842],
              [-64.03655252051985, 7.404786549608502],
              [-64.04315963952308, 7.4208204598458],
              [-64.04554747610193, 7.428616635634569],
              [-64.0420606043777, 7.446589447716235],
              [-64.03398162317549, 7.464606013332514],
              [-64.02415451224539, 7.491379320842384],
              [-64.02571052875233, 7.50792318606932],
              [-64.0450594816986, 7.510954752812665],
              [-64.06153159783432, 7.503434341638581],
              [-64.07793447944974, 7.489016294696967],
              [-64.10622457615965, 7.468502927760483],
              [-64.12328359315626, 7.47247662042156],
              [-64.12985249068097, 7.484831545713689],
              [-64.1263575723297, 7.50142335542688],
              [-64.11453255095682, 7.513957653447108],
              [-64.10270652375559, 7.526031784982536],
              [-64.08450354461252, 7.543687425847736],
              [-64.0704425667465, 7.561763000062456],
              [-64.07244148301763, 7.576462176036841],
              [-64.08411462430482, 7.592446800683433],
              [-64.07878859538411, 7.610436711847569],
              [-64.07393647926939, 7.629343267943796],
              [-64.07779651332335, 7.646325003793095],
              [-64.09187358444349, 7.671484124387916],
              [-64.11133552544467, 7.685553148881013],
              [-64.13207252155809, 7.68903197397816],
              [-64.13600162249428, 7.669675309680997],
              [-64.13165258820861, 7.650859781053271],
              [-64.13235448878117, 7.630614132104483],
              [-64.14081551713048, 7.605233896903655],
              [-64.15263349770467, 7.592239767674812],
              [-64.17008260847543, 7.589770961911199],
              [-64.19351153661518, 7.587703314033945],
              [-64.21185248188463, 7.582463618716019],
              [-64.23380250702674, 7.571212590073173],
              [-64.25119059754232, 7.562764134578629],
              [-64.2658765301097, 7.560321145076841],
              [-64.28754458802871, 7.564710077221662],
              [-64.30175761361848, 7.560892287959746],
              [-64.32102157406648, 7.556564376070128],
              [-64.34490949522454, 7.553572874823999],
              [-64.35997764257706, 7.54376755684217],
              [-64.36235055936825, 7.50832685852663],
              [-64.35522460055797, 7.487238660669846],
              [-64.34577953677497, 7.464332260374533],
              [-64.34100352834115, 7.44782125220803],
              [-64.33259564125804, 7.43594308956898],
              [-64.33105454453876, 7.421239219728818],
              [-64.33364857593591, 7.406036459011148],
              [-64.34264353151798, 7.387090675608022],
              [-64.36270159347356, 7.370796926372009],
              [-64.3868946159075, 7.354463279276899],
              [-64.42361456028686, 7.347666561623669],
              [-64.44065848985775, 7.350258581364074],
              [-64.45919054251978, 7.362958505791198],
              [-64.48783854649562, 7.375558685570411],
              [-64.50452456880058, 7.387354705558266],
              [-64.5138625118609, 7.400602806453605],
              [-64.52228548636975, 7.41385995980437],
              [-64.53266949094736, 7.438136130717851],
              [-64.54703557008925, 7.448115289371742],
              [-64.57286054907297, 7.454763982614736],
              [-64.60417148369012, 7.458598703321059],
              [-64.62894453382434, 7.452839162367752],
              [-64.6513676335821, 7.442500755343474],
              [-64.67239363771728, 7.431257438051489],
              [-64.68762254997286, 7.435708228641829],
              [-64.70836658688495, 7.440106883794328],
              [-64.72299149919715, 7.432144411046352],
              [-64.73854847914332, 7.424634896346447],
              [-64.76611354122788, 7.422066345934923],
              [-64.79381556361045, 7.431916423279745],
              [-64.81264450328324, 7.429893702403604],
              [-64.81795460658789, 7.452839162367752],
              [-64.80200149789728, 7.466332014835984],
              [-64.78281347749213, 7.477559238873823],
              [-64.7599025509691, 7.485141508493427],
              [-64.7457505456345, 7.494938109295902],
              [-64.73446649229308, 7.514366690322447],
              [-64.75666847744488, 7.526108730853764],
              [-64.77736658152752, 7.526827730508842],
              [-64.81455255972388, 7.520485982560388],
              [-64.83206956391042, 7.524915650754679],
              [-64.85092951662499, 7.525191750645547],
              [-64.87345152284031, 7.524052817641007],
              [-64.89740750505223, 7.527499623868039],
              [-64.92041750567088, 7.529115654801728],
              [-64.93462349046197, 7.52437669438001],
              [-64.94179554973975, 7.507750015949568],
              [-64.9457395704636, 7.489772174725999],
              [-64.94965358397405, 7.469036687355185],
              [-64.9536515841544, 7.455659840426961],
              [-64.94567150940975, 7.441017996670325],
              [-64.94400049319114, 7.413895666711937],
              [-64.9461216176107, 7.397316932767694],
              [-64.94044455458469, 7.383113965461746],
              [-64.93753050212399, 7.368884008427528],
              [-64.9441755073296, 7.345359694229558],
              [-64.96491954424171, 7.349758517020121],
              [-64.96183047764255, 7.319430779646382],
              [-64.96624757298206, 7.302829749839702],
              [-64.9729995312723, 7.289425913183265],
              [-64.99131751012703, 7.281888235288591],
              [-65.0044246274108, 7.303378764498234],
              [-65.00504254131347, 7.317630849756824],
              [-65.02439149425975, 7.320661578309796],
              [-65.04543258582065, 7.310339264539664],
              [-65.07503562384518, 7.326149042685813],
              [-65.09264348786223, 7.338396344341049],
              [-65.10343150017327, 7.357609845731872],
              [-65.11549356176816, 7.3676113002482],
              [-65.12715161562961, 7.382217269459204],
              [-65.14844550539053, 7.394889365967742],
              [-65.15858459075712, 7.406374417347536],
              [-65.1633455117652, 7.411761969437578],
              [-65.16709155193585, 7.415188659096941],
              [-65.17607158773026, 7.419678341718054],
              [-65.19533554817825, 7.429311830684583],
              [-65.23502352447481, 7.440649025292203],
              [-65.26336659481284, 7.429311830684583],
              [-65.30304753031072, 7.434980176531326],
              [-65.33139060064875, 7.463322408679005],
              [-65.36540251974768, 7.480327949133311],
              [-65.39942147964527, 7.480327949133311],
              [-65.42209653941272, 7.463322408679005],
              [-65.45610862614971, 7.434980176531326],
              [-65.4957885558192, 7.434980176531326],
              [-65.54680651828662, 7.440649025292203],
              [-65.58082547818422, 7.457653559918128],
              [-65.63751262468865, 7.480327949133311],
              [-65.73954754379511, 7.485995624427801],
              [-65.75656163379065, 7.474659268010498],
              [-65.76789849312213, 7.423642981923706],
              [-65.76222964436123, 7.344284966603425],
              [-65.73387953322454, 7.315942063903435],
              [-65.71120464109514, 7.281931318270949],
              [-65.67719255435816, 7.27059429130145],
              [-65.64318063525923, 7.225246351061344],
              [-65.6318436082897, 7.145888335741063],
              [-65.63751262468865, 7.123213778887816],
              [-65.67719255435816, 7.09487104382589],
              [-65.72254150042662, 7.09487104382589],
              [-65.77356750952109, 7.089203200893394],
              [-65.85292854232654, 7.077866341561901],
              [-65.85859655289711, 7.055191784708654],
              [-65.85859655289711, 7.026849887837102],
              [-65.85859655289711, 7.018308560855303],
              [-65.86040452941373, 7.019075337358458],
              [-65.86108363120964, 6.996991034127404],
              [-65.86586751863244, 6.971646841110271],
              [-65.86600447893046, 6.941747921903357],
              [-65.86977348551584, 6.93093476388276],
              [-65.87581650243483, 6.913594953129518],
              [-65.8921204775927, 6.889978773272674],
              [-65.90759263267866, 6.875109947577812],
              [-65.92741348677416, 6.879516649357413],
              [-65.94824251618452, 6.891272603848051],
              [-65.9596554831969, 6.896210383013283],
              [-66.07772608156375, 6.850594426585969],
              [-66.09101851390348, 6.84545896506711],
              [-66.17716251804093, 6.856226357896276],
              [-66.1817246203055, 6.856796159674673],
              [-66.2384105933435, 6.947491537240637],
              [-66.26108548547289, 7.015512693229482],
              [-66.29510461300855, 7.100540563139077],
              [-66.36312861884446, 7.145888335741063],
              [-66.42548360828141, 7.145888335741063],
              [-66.48217762794646, 7.089203200893394],
              [-66.52185856344434, 7.032518401321852],
              [-66.55586964435294, 6.987170796357873],
              [-66.56153849311384, 6.919148802178768],
              [-66.57287552008337, 6.885138056546282],
              [-66.5842135528813, 6.794442846618381],
              [-66.57287552008337, 6.777437976716328],
              [-66.57287552008337, 6.709415814899103],
              [-66.5672075095128, 6.675404566352427],
              [-66.58988156345187, 6.641393820719998],
              [-66.61822563961827, 6.567704318884466],
              [-66.65791361591484, 6.556366956638783],
              [-66.72026860535179, 6.556366956638783],
              [-66.85632349018421, 6.539361416184477],
              [-66.88466656052223, 6.488346135926065],
              [-66.88466656052223, 6.448665870980449],
              [-66.86199150075478, 6.386313563752537],
              [-66.86766051715375, 6.357970493414484],
              [-66.89600358749178, 6.323960083058125],
              [-66.92434649019174, 6.306955213156073],
              [-66.97536462029721, 6.29561801854851],
              [-67.02072161299273, 6.244601732461717],
              [-67.03205863996227, 6.159574197828249],
              [-67.03772748872316, 6.136900814441447],
              [-67.03772748872316, 6.10288906298058],
              [-67.077392498605, 6.083056809496782],
              [-67.08515950537073, 6.083056809496782],
              [-67.11101549739622, 6.087249269830977],
              [-67.14036556958268, 6.087249269830977],
              [-67.15364049256819, 6.08794832055645],
              [-67.17111156392528, 6.082357758771309],
              [-67.19068163147753, 6.069081829957383],
              [-67.20185856409627, 6.061395289462268],
              [-67.207450634624, 6.05370891660516],
              [-67.21234164276949, 6.039034886340289],
              [-67.21024348476466, 6.020168395741223],
              [-67.1976696241614, 5.98802463522378],
              [-67.1927796218443, 5.977543232931225],
              [-67.18796555957003, 5.966911291657425],
              [-67.18511152153613, 5.955510059309518],
              [-67.20779462793064, 5.910162454345596],
              [-67.24180554120119, 5.887488065130412],
              [-67.28148664433714, 5.842140460166434],
              [-67.29849252006755, 5.81946607095125],
              [-67.30004579606647, 5.811692139326162],
              [-67.30601501464844, 5.807250499725342],
              [-67.33695983886719, 5.777050971984863],
              [-67.36225891113281, 5.735404968261719],
              [-67.38198900055193, 5.695561030679937],
              [-67.38880920410156, 5.681787967681885],
              [-67.40827941894531, 5.653078079223633],
              [-67.43252563476562, 5.599024295806885],
              [-67.44306182861328, 5.552922248840332],
              [-67.4509506225586, 5.516965866088867],
              [-67.46488189697266, 5.486931324005127],
              [-67.48285675048828, 5.448576927185059],
              [-67.4905014038086, 5.432403087615967],
              [-67.495849609375, 5.416709899902344],
              [-67.52368927001953, 5.354800224304199],
              [-67.53740692138672, 5.304528713226318],
              [-67.55927276611328, 5.243136882781982],
              [-67.58723449707031, 5.192265510559082],
              [-67.63676452636719, 5.137044429779053],
              [-67.69094848632812, 5.087298393249512],
              [-67.71595764160156, 5.061295032501221],
              [-67.75304412841797, 5.04621410369873],
              [-67.789794921875, 5.020211219787598],
              [-67.7898969098787, 5.019517232768852],
              [-67.80175280354439, 5.023885193593051],
              [-67.83855005978145, 5.069564546163191],
              [-67.85560560388144, 5.160653931188604],
              [-67.87259969576935, 5.160850365569728],
              [-67.92467028756747, 5.161452248508972],
              [-67.92641915085099, 5.15665662052965],
              [-67.92827203108804, 5.154623362947365],
              [-67.93574101810034, 5.146427272246155],
              [-67.93790628883768, 5.132014746957961],
              [-67.93294981069644, 5.108609612011769],
              [-67.92587223857943, 5.094120697463777],
              [-67.92423904295524, 5.08379997669935],
              [-67.9311990607841, 5.06873989498192],
              [-67.93421736387627, 5.056290697091999],
              [-67.9354388053342, 5.051252778114477],
              [-67.93744981700605, 5.047536108798736],
              [-67.93821525348582, 5.04612146048122],
              [-67.94653456751934, 5.032101608518873],
              [-67.93996073977296, 5.024789267020474],
              [-67.9390677266737, 5.023795931478941],
              [-67.93906725902401, 5.023795451268096],
              [-67.93880251475635, 5.023536309327066],
              [-67.93057345628115, 5.0154806914249],
              [-67.92926578305308, 5.007224456448584],
              [-67.92995545371511, 5.00692554100233],
              [-67.94093927852295, 5.002164957326046],
              [-67.95120970159019, 5.001904079044586],
              [-67.96243921023755, 5.010583716551285],
              [-67.96243955178664, 5.010584152202655],
              [-67.97386927848412, 5.011801653373509],
              [-67.97988143796375, 5.012442282129739],
              [-67.97988184810363, 5.012442115804365],
              [-67.98249585321845, 5.008484262427594],
              [-67.98507397143999, 5.004580745233412],
              [-67.98507438545336, 5.00458056680286],
              [-67.9878804358115, 4.995325648221948],
              [-67.99406401498982, 4.992282587918082],
              [-67.99999998966273, 4.994820574536991],
              [-68.00225275757569, 4.995783768474564],
              [-68.00196487428967, 4.99809261691818],
              [-68.0015251424517, 5.001619303665596],
              [-68.00282666188181, 5.010907753437236],
              [-68.00829544003851, 5.011981651861543],
              [-68.01077666022564, 5.010956033363713],
              [-68.0148191888946, 5.009285044144979],
              [-68.01747382767073, 5.004450692595254],
              [-68.01932810806429, 5.001073870340936],
              [-68.02111813004726, 4.998342333168289],
              [-68.02313660097452, 4.995262187548207],
              [-68.0254854410979, 4.99276059581966],
              [-68.02762030294457, 4.990486897969831],
              [-68.02762080902832, 4.990486766310734],
              [-68.03263991616996, 4.991222107805915],
              [-68.03753677906053, 4.99193933871599],
              [-68.03753702322241, 4.991939575259623],
              [-68.04135635083944, 4.984409702671035],
              [-68.04488258939372, 4.978472595163946],
              [-68.04724768606528, 4.974490495543884],
              [-68.0492344396385, 4.972197966226982],
              [-68.05138924850061, 4.969711516696403],
              [-68.05510813869756, 4.965385314582824],
              [-68.05725760079133, 4.962884835417616],
              [-68.059480908663, 4.960917420406832],
              [-68.06380238371122, 4.957093327941299],
              [-68.07154775441968, 4.955945452230181],
              [-68.07922306175627, 4.954807960016144],
              [-68.10696380302437, 4.952960453047013],
              [-68.13400380951525, 4.953511884919627],
              [-68.13400413547504, 4.95351183846176],
              [-68.15081184409694, 4.948828539231645],
              [-68.15527713906718, 4.946800370097971],
              [-68.17757649008087, 4.940095684467548],
              [-68.19438012356963, 4.936098003440568],
              [-68.22011565425832, 4.927698187399212],
              [-68.24069548405906, 4.922351618172175],
              [-68.26243131730718, 4.92221446358127],
              [-68.2656906742701, 4.922193896815975],
              [-68.26569118478844, 4.922193859536925],
              [-68.26916971056743, 4.922630841788674],
              [-68.27732313735369, 4.923654860665953],
              [-68.29109200307455, 4.923756686978311],
              [-68.30471532799893, 4.923857436955847],
              [-68.3047154863531, 4.923857193570216],
              [-68.31431272411383, 4.922552521117749],
              [-68.3153508177199, 4.92250927896263],
              [-68.32835576715333, 4.921967553208272],
              [-68.34581515054828, 4.922782863166077],
              [-68.34581546697846, 4.92278300380533],
              [-68.3564149560416, 4.920927544287448],
              [-68.35712906288231, 4.920802551814297],
              [-68.35712956420738, 4.920802450849881],
              [-68.3718903949676, 4.915408030126632],
              [-68.37886422907226, 4.909944377115023],
              [-68.38015218595014, 4.908935326803461],
              [-68.3801524012656, 4.908935049603055],
              [-68.38062651525085, 4.908393312137856],
              [-68.38463777460726, 4.903809658132606],
              [-68.3925609272385, 4.896645754999356],
              [-68.40834105169532, 4.892632741580377],
              [-68.41166483069132, 4.892895025817008],
              [-68.41792542585364, 4.893389058490122],
              [-68.43469530091649, 4.895227528814252],
              [-68.4590503880299, 4.889210412955433],
              [-68.47449773666014, 4.883817039090394],
              [-68.48858275077075, 4.877037980614007],
              [-68.50507754512047, 4.868555307496999],
              [-68.50621106317637, 4.86801846023652],
              [-68.52225358193927, 4.860420537814613],
              [-68.53292677672417, 4.851552277804654],
              [-68.5338357111934, 4.850292276091944],
              [-68.53465461560869, 4.84915707768023],
              [-68.54770412591958, 4.843743902891402],
              [-68.553961070141, 4.842392099342517],
              [-68.55696389001544, 4.841743344547771],
              [-68.56139876891233, 4.840351568040973],
              [-68.56554401942122, 4.839050684204358],
              [-68.58337196090676, 4.836420241652033],
              [-68.58337196146049, 4.83642023754278],
              [-68.58376049131572, 4.83644538701333],
              [-68.60117763505153, 4.8375726099063],
              [-68.61521334681791, 4.839043450808688],
              [-68.63134615423076, 4.833648679850718],
              [-68.63134621022155, 4.833648430044962],
              [-68.64098280905961, 4.826145634878473],
              [-68.64098305953904, 4.82614539230208],
              [-68.64827887812015, 4.823351582643827],
              [-68.65334340609697, 4.821412105975324],
              [-68.65334380701694, 4.821412054252795],
              [-68.65409176949468, 4.820485130149009],
              [-68.65472449082229, 4.819701396768534],
              [-68.65850433391871, 4.817662697258037],
              [-68.65850468454954, 4.817662476910193],
              [-68.6643668277792, 4.811165594174175],
              [-68.66436693644908, 4.811165478850082],
              [-68.66818760968155, 4.802246590433279],
              [-68.67167035905646, 4.792637729279434],
              [-68.67171111559557, 4.785758084748021],
              [-68.67307507060782, 4.782724228890308],
              [-68.67448670989802, 4.779584308518058],
              [-68.67828337561689, 4.774793185645749],
              [-68.67828338858511, 4.774793256467384],
              [-68.68443346310646, 4.777585449430884],
              [-68.6868114642214, 4.781041190950307],
              [-68.69294144721536, 4.787273704966592],
              [-68.70252511443427, 4.788712215812547],
              [-68.71526212839724, 4.778130856016323],
              [-68.71526168173448, 4.778130579925603],
              [-68.70811154006566, 4.77051567381824],
              [-68.70574916184692, 4.764307797377788],
              [-68.70784427060163, 4.757441032562115],
              [-68.71387117879412, 4.756573202321229],
              [-68.71470162770777, 4.756453623814656],
              [-68.71482431675962, 4.755288714862488],
              [-68.7154258052869, 4.749577696971691],
              [-68.71682377537411, 4.744770525541012],
              [-68.71786964050857, 4.743215611421182],
              [-68.71820601425466, 4.742715516074199],
              [-68.71820603008908, 4.742715362468853],
              [-68.72849483298498, 4.740717367359627],
              [-68.73258510784508, 4.744184112870104],
              [-68.73195995388144, 4.747085038482511],
              [-68.73117840848677, 4.750711673174653],
              [-68.72854151693612, 4.755559678293371],
              [-68.72633043301155, 4.759624823172036],
              [-68.72422386785094, 4.768555866045628],
              [-68.7242240607984, 4.768556006932726],
              [-68.73241817815544, 4.773425157068361],
              [-68.74150215721019, 4.770701457483114],
              [-68.74477288445367, 4.769720777278446],
              [-68.75167922217334, 4.760132231004442],
              [-68.75167927844251, 4.760132053573329],
              [-68.75169318176007, 4.757684038038634],
              [-68.75181065427508, 4.737082039519826],
              [-68.74810768818615, 4.725361013754949],
              [-68.75605693796788, 4.713026300090939],
              [-68.76911688019437, 4.705539750078986],
              [-68.77179887431859, 4.705142694355496],
              [-68.77803081733309, 4.704220086576223],
              [-68.7900204899465, 4.704295239303262],
              [-68.79002050435729, 4.704295157866379],
              [-68.79863847503962, 4.702338802155741],
              [-68.8047702448848, 4.700946780450058],
              [-68.81578907150251, 4.690693655410461],
              [-68.81578954520242, 4.690693404652727],
              [-68.81959140722857, 4.684523482022223],
              [-68.82818105458267, 4.680103627062879],
              [-68.83708732391804, 4.680158787465529],
              [-68.83708783824392, 4.680158827928175],
              [-68.85011880329792, 4.677830145600307],
              [-68.85974558345087, 4.671695571733113],
              [-68.86388824809838, 4.665871616145381],
              [-68.86492667421263, 4.663813088145892],
              [-68.87061616597443, 4.662520578489572],
              [-68.87967676511722, 4.660462237889445],
              [-68.88112771625369, 4.65854129409173],
              [-68.88174674316555, 4.657721751739749],
              [-68.88232071844902, 4.656665366183617],
              [-68.88416928479624, 4.653263131200686],
              [-68.88795572853242, 4.649844901775619],
              [-68.88795626460978, 4.649844629611224],
              [-68.8930732089087, 4.654005026037052],
              [-68.89646778477432, 4.659875725041962],
              [-68.89953340858646, 4.663335417094269],
              [-68.90085550173278, 4.664069913856469],
              [-68.90704813192409, 4.667510266631645],
              [-68.91665387573481, 4.670979508233592],
              [-68.91867616797175, 4.671709885859498],
              [-68.919667692456, 4.6722152767661],
              [-68.92004218288389, 4.672406158645347],
              [-68.92004248393462, 4.672406257112095],
              [-68.92688605296071, 4.674167917330843],
              [-68.93755184604623, 4.676108799948427],
              [-68.940578280491, 4.676659528240196],
              [-68.94057855119627, 4.676659501500582],
              [-68.94919505458894, 4.667075285622152],
              [-68.94919515627409, 4.667075266138045],
              [-68.94377061153567, 4.656030175377646],
              [-68.92808093652033, 4.642515407268643],
              [-68.92197127542069, 4.631466815759165],
              [-68.92406836000532, 4.623564761138763],
              [-68.92993901434853, 4.614652104109158],
              [-68.93339001522314, 4.609854652478324],
              [-68.94505579425947, 4.606482343446206],
              [-68.94505598333863, 4.606482375223245],
              [-68.94916765404243, 4.606506487534077],
              [-68.95435618711248, 4.609313699613802],
              [-68.96010047557971, 4.612421598466253],
              [-68.96763649185722, 4.612809887393936],
              [-68.96810480815226, 4.610074547897689],
              [-68.96940236185787, 4.602495802641062],
              [-68.96940244263328, 4.602495713555517],
              [-68.96956992975649, 4.601837278535936],
              [-68.97045174660639, 4.598371831652639],
              [-68.97390248831255, 4.593574350891978],
              [-68.97536553382845, 4.591912402082512],
              [-68.97631412199028, 4.590834851897544],
              [-68.97940103525318, 4.590164549751834],
              [-68.99415000000923, 4.58715294625221],
              [-68.9887186424279, 4.577141085939171],
              [-68.9887180902538, 4.577140937427552],
              [-68.98187595059122, 4.575036270894352],
              [-68.96575500650596, 4.578728178806764],
              [-68.96216592822, 4.579630574648346],
              [-68.95237640448747, 4.58209193792928],
              [-68.95237592205045, 4.582091705688322],
              [-68.95046990200996, 4.580294208416178],
              [-68.9476021948171, 4.577590114444906],
              [-68.94760246475352, 4.57759003399141],
              [-68.95004932745636, 4.567968538217543],
              [-68.94790268168424, 4.564147750074056],
              [-68.94597571967222, 4.56071797432612],
              [-68.94397772335066, 4.549349862774165],
              [-68.94460715656767, 4.544200593448667],
              [-68.94540692731924, 4.537657826261816],
              [-68.94990718573005, 4.52839244958121],
              [-68.94990721461568, 4.528392418442377],
              [-68.95002262528463, 4.528294133629089],
              [-68.95954008704273, 4.520188434347242],
              [-68.96676529964334, 4.514035567153877],
              [-68.96676548671529, 4.514035321759125],
              [-68.97845694392932, 4.505154389432589],
              [-68.98844378106034, 4.494542804768412],
              [-68.98844416749581, 4.494542718239296],
              [-68.99185600534017, 4.491717371834321],
              [-68.99463624682926, 4.489415241251944],
              [-69.00151786449847, 4.483259260091724],
              [-69.00805847436945, 4.476757287497556],
              [-69.01461355683924, 4.467157630772664],
              [-69.01461366737614, 4.467157423212924],
              [-69.01759993540239, 4.464095493818111],
              [-69.02563048099557, 4.455861229504546],
              [-69.03214605697286, 4.454520816272068],
              [-69.03214654982516, 4.454520584562808],
              [-69.04082559568117, 4.454121751888882],
              [-69.04551025820408, 4.453906475173823],
              [-69.05750327734326, 4.453284394154098],
              [-69.06115895411722, 4.452896395754235],
              [-69.06367272999283, 4.452629593988708],
              [-69.06882508829192, 4.451623772564594],
              [-69.07396109304516, 4.450621143609834],
              [-69.07810727502525, 4.443071350741357],
              [-69.07659166327659, 4.442160734871427],
              [-69.07059166242185, 4.438555790529471],
              [-69.07059164763739, 4.438555927576923],
              [-69.06442349301513, 4.438866218830311],
              [-69.05554431344918, 4.432966436563341],
              [-69.04976468525962, 4.423642144095291],
              [-69.05163871661648, 4.420152433809119],
              [-69.05252897668164, 4.418494644201936],
              [-69.06007485390116, 4.41647060897653],
              [-69.0713716507941, 4.412836768998142],
              [-69.0758579863221, 4.411393649717904],
              [-69.08013277985296, 4.409724356723479],
              [-69.0888994003698, 4.40630101987457],
              [-69.10011127252055, 4.402438294106299],
              [-69.10365396589341, 4.401217761727883],
              [-69.10365433137711, 4.401217557312071],
              [-69.10949613161951, 4.397462363982959],
              [-69.10989038190684, 4.397266849577493],
              [-69.1300975030707, 4.387245846655239],
              [-69.14337153998406, 4.381636019144818],
              [-69.14370093134161, 4.381496812910007],
              [-69.15747168510568, 4.374870498179932],
              [-69.1602151092105, 4.372170912309856],
              [-69.16574264162315, 4.366732139401039],
              [-69.16624462743482, 4.363988204721564],
              [-69.16371896460814, 4.360933871491255],
              [-69.19129600055695, 4.347974041140466],
              [-69.20056416167446, 4.342158391489901],
              [-69.20435366386256, 4.336322210818066],
              [-69.2098421086565, 4.333933328405673],
              [-69.22461477315274, 4.321598340568213],
              [-69.2331917203242, 4.317499966093187],
              [-69.23966487174238, 4.326817657978723],
              [-69.24168588882587, 4.336118701183663],
              [-69.24815371160095, 4.346813019491753],
              [-69.25913125731273, 4.342035521027411],
              [-69.27282865627626, 4.343118976169448],
              [-69.27693140194769, 4.345199813058382],
              [-69.2786158660318, 4.352779094830257],
              [-69.27618886881946, 4.360686870208385],
              [-69.28302767290181, 4.364154536290402],
              [-69.29399201067973, 4.363163067816434],
              [-69.30394139990122, 4.358724671073361],
              [-69.30906280804228, 4.363562890126924],
              [-69.31519081664503, 4.373913305162688],
              [-69.32268278438255, 4.386333509677709],
              [-69.33465789038405, 4.39016521571866],
              [-69.34700121260379, 4.387456224265807],
              [-69.35384849872264, 4.388514201805611],
              [-69.35003416472017, 4.401238015904718],
              [-69.35171270111691, 4.410884127704212],
              [-69.35476366015756, 4.419846227721954],
              [-69.36847714713102, 4.417143263292816],
              [-69.38047595714121, 4.415121340341495],
              [-69.40514715995684, 4.41452339698189],
              [-69.41231987274045, 4.420746679232647],
              [-69.42223169501264, 4.427668949739519],
              [-69.4246366910624, 4.425956383483445],
              [-69.44280340522066, 4.423955959618841],
              [-69.45926991763078, 4.41850614356786],
              [-69.459956785196, 4.418163968707407],
              [-69.47126288448248, 4.418548907353765],
              [-69.48290139373808, 4.422033785353898],
              [-69.47670327903023, 4.43061991153375],
              [-69.47361242775187, 4.4326749468796],
              [-69.46774510511668, 4.444705614199978],
              [-69.47596077052806, 4.447145284883629],
              [-69.47663942111842, 4.449213749731523],
              [-69.49177542388794, 4.432395029120694],
              [-69.50100222716551, 4.439659378790222],
              [-69.50406569707943, 4.445868492498132],
              [-69.5119210608938, 4.453472062595384],
              [-69.51844669643341, 4.44936260659542],
              [-69.52533994374582, 4.437679061342742],
              [-69.54318593991687, 4.430165529599241],
              [-69.54967134205734, 4.43776386894795],
              [-69.55480720721603, 4.439503805134088],
              [-69.56509875913363, 4.436439909552634],
              [-69.5661557636536, 4.427489849255672],
              [-69.57679364385987, 4.423393749665784],
              [-69.58502449349368, 4.421699988108093],
              [-69.59390903159236, 4.429651559293232],
              [-69.59387576120972, 4.439983098130275],
              [-69.60137870703075, 4.45102950001982],
              [-69.6068908451575, 4.442437968210108],
              [-69.6130791251473, 4.436948954196574],
              [-69.62441118965852, 4.43009908294013],
              [-69.62819784835612, 4.424945700679649],
              [-69.63746251368251, 4.421876902134864],
              [-69.67552590105069, 4.416147760836279],
              [-69.68993485308651, 4.41171720474127],
              [-69.6913542116645, 4.396222584637906],
              [-69.69580783946344, 4.396925694435276],
              [-69.70745276753267, 4.400062875892719],
              [-69.72046699290999, 4.40354953721933],
              [-69.72908408031485, 4.387732250587015],
              [-69.75381050902419, 4.371965119015236],
              [-69.76890463445002, 4.367878346175527],
              [-69.77348831103349, 4.368293077938051],
              [-69.78506234150885, 4.351737607364605],
              [-69.79366860464121, 4.339018033728704],
              [-69.8005093737503, 4.344206214074989],
              [-69.8111287767227, 4.346649046902353],
              [-69.82143233099582, 4.339790534188397],
              [-69.82965146733144, 4.342570667310959],
              [-69.84162978865014, 4.349496222464477],
              [-69.84674570579357, 4.358813012460872],
              [-69.85600320228194, 4.358151085008251],
              [-69.8656485570258, 4.341298645146196],
              [-69.86944618408867, 4.331663617937217],
              [-69.87900562666556, 4.345816263890409],
              [-69.88171676459218, 4.35753795933732],
              [-69.89746701339577, 4.364818722859838],
              [-69.91363070230777, 4.345916531117938],
              [-69.91945533624632, 4.34731127735819],
              [-69.92403597570596, 4.347616789811136],
              [-69.94105084917044, 4.348750776815404],
              [-69.95373895600758, 4.347752865440119],
              [-69.97742622409439, 4.336444079619173],
              [-69.98054359706008, 4.324053423025484],
              [-69.99357585303547, 4.322366216823333],
              [-69.99999999992164, 4.323579913206602],
              [-70.00282829398985, 4.324114255017616],
              [-70.01207800431472, 4.32724063286442],
              [-70.03539650689814, 4.325924868847586],
              [-70.04499538444209, 4.326639804985958],
              [-70.04775896241307, 4.318721912233424],
              [-70.04802366593063, 4.318157268483491],
              [-70.06964118546148, 4.27193957979337],
              [-70.07222860961713, 4.266408009434469],
              [-70.09076573509022, 4.271713466623496],
              [-70.09895444000514, 4.27405714764278],
              [-70.12432575058715, 4.275154750727334],
              [-70.13288431841299, 4.281034369997792],
              [-70.1788609057479, 4.268740620593465],
              [-70.22100367192148, 4.28572966969729],
              [-70.2422491689666, 4.294051695379979],
              [-70.24674065135194, 4.27855206439346],
              [-70.24846325113943, 4.274420300319907],
              [-70.2642387559508, 4.274456919727091],
              [-70.26729819072095, 4.287561398166967],
              [-70.27861221328979, 4.289311475753739],
              [-70.28893680920604, 4.27210064149644],
              [-70.30952109002354, 4.269045269972045],
              [-70.30784461163486, 4.249738960306664],
              [-70.31233184790608, 4.234927250242141],
              [-70.31816813243896, 4.232182638880667],
              [-70.33531850941736, 4.23049678077884],
              [-70.34009329669864, 4.244294920680163],
              [-70.35655633945312, 4.244330734439442],
              [-70.36308032845606, 4.240208334851143],
              [-70.36475836635198, 4.228798602458975],
              [-70.36551312406989, 4.223667476482526],
              [-70.37821472294864, 4.216799954649175],
              [-70.38920948933836, 4.206136475819876],
              [-70.4132588499722, 4.183089709413741],
              [-70.43592191888224, 4.167276999377314],
              [-70.45788298678804, 4.161114779956769],
              [-70.476069270312, 4.155633811893271],
              [-70.48133798898924, 4.15705687510259],
              [-70.4914973842304, 4.159799680487457],
              [-70.50555154548191, 4.164997632146396],
              [-70.52203421662324, 4.152961709288078],
              [-70.54226990071581, 4.153688168166854],
              [-70.55151607680075, 4.1633582178659],
              [-70.56010397953416, 4.155099528843317],
              [-70.57314722275956, 4.14960610553433],
              [-70.58424224708467, 4.146091164738562],
              [-70.58721742691358, 4.145148689586568],
              [-70.5999083773297, 4.145170975221689],
              [-70.61089305152613, 4.139673114481738],
              [-70.63525183308303, 4.137645523338555],
              [-70.64623250717429, 4.134905152894887],
              [-70.65824328620135, 4.131477326593764],
              [-70.66890005315834, 4.114598580013952],
              [-70.67816815846551, 4.10944146929275],
              [-70.69429370354702, 4.107397892800737],
              [-70.71488097834285, 4.103981421309978],
              [-70.71752365309361, 4.104360462159717],
              [-70.71763514378293, 4.095710226205319],
              [-70.72142186822843, 4.085026160697686],
              [-70.73480027332933, 4.085046117449209],
              [-70.7519657238472, 4.073691757396331],
              [-70.75574833569176, 4.065766221942042],
              [-70.76330385465016, 4.058535517508847],
              [-70.77600385766726, 4.051311617813072],
              [-70.78630610317214, 4.042015734275802],
              [-70.79077360912888, 4.034090163161087],
              [-70.80485263891272, 4.01997035377539],
              [-70.8086281155681, 4.018941081294241],
              [-70.82098513133968, 4.011025831788651],
              [-70.83049238678761, 3.999999999883542],
              [-70.83197543284828, 3.998280069598513],
              [-70.83884131167153, 3.992081464153197],
              [-70.85840667362099, 3.979001358185844],
              [-70.86733577833905, 3.96866599872765],
              [-70.87728078385018, 3.971092376447192],
              [-70.89682658521592, 3.981116765424474],
              [-70.91191650703995, 3.986308356489978],
              [-70.92357875152577, 3.988046380312674],
              [-70.93010107111711, 3.982880071680297],
              [-70.9373201144545, 3.964954114619422],
              [-70.94384160714317, 3.960823067029859],
              [-70.94727655032372, 3.957110249403155],
              [-70.97711022593722, 3.971895992275],
              [-70.99288742983445, 3.979156269875621],
              [-71.00146083866242, 3.983649347830613],
              [-71.01620906924738, 3.991252743671071],
              [-71.02193361229749, 3.999999999894107],
              [-71.02614818088723, 4.006439974404055],
              [-71.0285423634595, 4.0161001335721],
              [-71.0432855699711, 4.031982908616448],
              [-71.05563568556357, 4.036480041020682],
              [-71.06764501067511, 4.036837506847847],
              [-71.07690918660656, 4.037881682326001],
              [-71.09097853202816, 4.037206221895925],
              [-71.10882639028748, 4.029979952781646],
              [-71.11015805250157, 4.019553988808357],
              [-71.11363992234644, 4.014116455681949],
              [-71.1150179253273, 4.004804229321974],
              [-71.11840766418268, 3.999999999897961],
              [-71.12085576816528, 3.996530336396431],
              [-71.12223730493064, 3.98238862459534],
              [-71.13561940530104, 3.981710871792222],
              [-71.13974618852173, 3.964811772675653],
              [-71.14695114554272, 3.965508024983285],
              [-71.15930568396719, 3.961033934143871],
              [-71.16444948319803, 3.969662519410975],
              [-71.1651345564587, 3.972078045830814],
              [-71.1744019560358, 3.965531341425705],
              [-71.1788686549297, 3.952425863684854],
              [-71.18127287890651, 3.947943325797331],
              [-71.18848233311836, 3.938289976194857],
              [-71.19260311920293, 3.932428636527944],
              [-71.20050158978091, 3.914151463390393],
              [-71.20702465924992, 3.904842368497389],
              [-71.20840060451143, 3.895873958213776],
              [-71.21526608535461, 3.887254479258946],
              [-71.22487767501119, 3.875532072751014],
              [-71.23620429306376, 3.862430964055083],
              [-71.2420366409596, 3.864159880188409],
              [-71.2506180195277, 3.85209116842819],
              [-71.25988339961532, 3.846922274652884],
              [-71.26748424984787, 3.835862012650298],
              [-71.26983673406089, 3.832439185990226],
              [-71.27395619236387, 3.82312684095684],
              [-71.28288120864761, 3.798293195990147],
              [-71.2955794828322, 3.773805983159015],
              [-71.31239341613963, 3.757944909880122],
              [-71.31891350410861, 3.739663659787749],
              [-71.33469513873239, 3.735531198951111],
              [-71.34155719117447, 3.731049253534943],
              [-71.35219301308909, 3.724498547789137],
              [-71.3772388907338, 3.718298608507533],
              [-71.38169887745998, 3.729685112565878],
              [-71.39542220507354, 3.722790278384355],
              [-71.4314468384899, 3.701066202914086],
              [-71.44071080951645, 3.701414084850351],
              [-71.45752240926362, 3.701419035716145],
              [-71.47776284135836, 3.682792327988152],
              [-71.48771109579903, 3.672788667019072],
              [-71.5024618436746, 3.659680742109321],
              [-71.51069409163124, 3.648986034525916],
              [-71.53196407198499, 3.642778729769576],
              [-71.53335440591036, 3.643195999051943],
              [-71.55152333096265, 3.648647190050714],
              [-71.55461385900043, 3.657273649206731],
              [-71.57468897267036, 3.650899820711872],
              [-71.58068765520126, 3.648995045660289],
              [-71.58995058901066, 3.643820265020193],
              [-71.59784513381204, 3.65106657041919],
              [-71.60149960290501, 3.649788682490657],
              [-71.60573577127082, 3.648306838633132],
              [-71.61675220107412, 3.64646134946212],
              [-71.61808413190234, 3.636575198269825],
              [-71.63215068871513, 3.634505105352361],
              [-71.64622128545348, 3.639336428650428],
              [-71.65651781612877, 3.644857658602613],
              [-71.66132393671788, 3.649688650398347],
              [-71.67230829381221, 3.658315870868127],
              [-71.68260573223573, 3.66452712207127],
              [-71.6966791549729, 3.672118460900097],
              [-71.7049171100562, 3.677639700072067],
              [-71.71266802637082, 3.674370336836537],
              [-71.71555365336094, 3.673153137284171],
              [-71.7224100870947, 3.659004124470303],
              [-71.73133068090328, 3.657623161541637],
              [-71.73552340395605, 3.653916003533364],
              [-71.74265017101578, 3.647614752465401],
              [-71.7471120859058, 3.649339788706792],
              [-71.76255197679015, 3.644851813301591],
              [-71.77524977293079, 3.644850108664687],
              [-71.7839676789518, 3.644524601012023],
              [-71.79515161783341, 3.6417412778311],
              [-71.81574172646215, 3.641738144170321],
              [-71.81711553571091, 3.641737657722545],
              [-71.83564184431717, 3.633105971800572],
              [-71.83613326394476, 3.633053545328489],
              [-71.84502382843071, 3.632100327516558],
              [-71.85176992947144, 3.63137721200222],
              [-71.86446009067056, 3.61998475894199],
              [-71.87922073513725, 3.625848334863652],
              [-71.89603989912443, 3.629985133468258],
              [-71.90908245412737, 3.631016946901286],
              [-71.92040886660239, 3.631358801502352],
              [-71.93927980465229, 3.625830950656075],
              [-71.95231335842959, 3.61547209947227],
              [-71.95334225809474, 3.614436395657644],
              [-71.96397323946194, 3.604423423306932],
              [-71.97083194103709, 3.59786265376742],
              [-71.9831811276453, 3.591990542162432],
              [-72.0000000000146, 3.581894836971142],
              [-72.00101872428517, 3.581283337003665],
              [-72.00914041376753, 3.576376465130917],
              [-72.01989810645779, 3.584727060391717],
              [-72.02950162810785, 3.577819256115103],
              [-72.038761356982, 3.571256749168271],
              [-72.04424632478155, 3.564351304536565],
              [-72.05248041089074, 3.561931106416664],
              [-72.06242795557746, 3.556403370994874],
              [-72.07854510916368, 3.543278570127664],
              [-72.07990403089259, 3.529816416894695],
              [-72.08469642010988, 3.518423359037695],
              [-72.10870764813899, 3.506673983564138],
              [-72.11591791099936, 3.509776237806784],
              [-72.12861636397871, 3.50942335598508],
              [-72.1378663284064, 3.495611083173935],
              [-72.1392373025817, 3.49319398891385],
              [-72.14986550943347, 3.484212320902007],
              [-72.17695982079506, 3.469006596756816],
              [-72.18587843272502, 3.465203632273096],
              [-72.20371110014544, 3.454145213317019],
              [-72.2074797121787, 3.448964622819159],
              [-72.214676155002, 3.440329572640019],
              [-72.21073690781394, 3.432155015868696],
              [-72.2087463416171, 3.423232417567326],
              [-72.20746172572089, 3.423403065501449],
              [-72.20393559078276, 3.423871476054693],
              [-72.203935042877, 3.423871690886836],
              [-72.20388620570722, 3.423890654623672],
              [-72.19360497961222, 3.427883342731486],
              [-72.17957902018085, 3.435979516528944],
              [-72.17843072237358, 3.436642345953519],
              [-72.17843050211528, 3.436642517077527],
              [-72.16464542431915, 3.443359992891499],
              [-72.1529360893678, 3.448042326128118],
              [-72.14603779825703, 3.453350678756836],
              [-72.14326609398097, 3.455483552456075],
              [-72.14326575406486, 3.455483596959058],
              [-72.12396734124718, 3.46488681749124],
              [-72.1088736150891, 3.468839273800305],
              [-72.10883034288484, 3.468850605097487],
              [-72.10883031699454, 3.468850612327837],
              [-72.09789934515422, 3.461897319412628],
              [-72.09789924710756, 3.461897309355369],
              [-72.08906614054243, 3.450170244193031],
              [-72.08090812655713, 3.439819500443501],
              [-72.08090792014481, 3.43981945933895],
              [-72.07755596163507, 3.429514491513529],
              [-72.08104745095628, 3.422014833713497],
              [-72.08697438458664, 3.416205563007518],
              [-72.08935042359066, 3.413876693706254],
              [-72.10106978328808, 3.407825296816669],
              [-72.11276187152097, 3.405197864505818],
              [-72.12442682894142, 3.40599390636747],
              [-72.14980229691933, 3.409660017739314],
              [-72.14980264527006, 3.40966003326389],
              [-72.15273942660237, 3.40910295184246],
              [-72.16011727251245, 3.407703644766401],
              [-72.17113915171711, 3.403013907896268],
              [-72.17809004587856, 3.392121881107788],
              [-72.18088909718183, 3.38529896572966],
              [-72.18202162035429, 3.383398068120105],
              [-72.18575806425669, 3.377126587363707],
              [-72.18575834998894, 3.377126507021309],
              [-72.18790278907034, 3.366188173133074],
              [-72.18800501742105, 3.365375248507216],
              [-72.18936524437808, 3.35455866012995],
              [-72.18941947998107, 3.347599517115603],
              [-72.18946665858452, 3.341545880192851],
              [-72.18884856878806, 3.33263625335929],
              [-72.18802780412791, 3.327927441639179],
              [-72.18753314208145, 3.325089514331806],
              [-72.1842118903939, 3.310674870622217],
              [-72.1850091823244, 3.296298659097818],
              [-72.18562986624922, 3.288469812623092],
              [-72.18576918916176, 3.28671663866215],
              [-72.18827393449945, 3.283034035613678],
              [-72.19271265573545, 3.276508003562056],
              [-72.1975758669312, 3.269019273364595],
              [-72.197576184, 3.269019132013882],
              [-72.20657362779926, 3.259514172053402],
              [-72.2104075929129, 3.255989602790881],
              [-72.21763731781974, 3.249343307003731],
              [-72.22655415757208, 3.247206156965714],
              [-72.23139276417602, 3.24604646041685],
              [-72.23873588350105, 3.245657312503338],
              [-72.25337253869284, 3.244881644346988],
              [-72.27947568813106, 3.243068952756795],
              [-72.27947577243955, 3.243068936230927],
              [-72.29734188769, 3.241179410792487],
              [-72.30452895345626, 3.240114772984609],
              [-72.34958106759159, 3.23344144611578],
              [-72.35604337492174, 3.233032153193758],
              [-72.38736353325837, 3.231048477941419],
              [-72.40521010259617, 3.231896848341841],
              [-72.42922506139158, 3.234171403655608],
              [-72.44671212939035, 3.238700641920445],
              [-72.45115636602883, 3.239851721486357],
              [-72.4533382523568, 3.240374867458249],
              [-72.468976930172, 3.244124518024254],
              [-72.48953530685289, 3.249791845637394],
              [-72.51078099831061, 3.255465025258036],
              [-72.52720439430905, 3.263149706504842],
              [-72.54499201799152, 3.272216637700916],
              [-72.56279441614255, 3.279227925617375],
              [-72.56279470326265, 3.279227999903638],
              [-72.57446333545164, 3.280018007292453],
              [-72.5744633596896, 3.280018236777708],
              [-72.58185323343227, 3.278612339151101],
              [-72.5882173392543, 3.277401791884325],
              [-72.58821753941933, 3.277401552202126],
              [-72.60474436065246, 3.271384093484031],
              [-72.62201294191448, 3.25783592021873],
              [-72.62914834183468, 3.252505533818283],
              [-72.63651376660583, 3.247003311031508],
              [-72.65652818835169, 3.233478989007499],
              [-72.65652874359898, 3.233478952814572],
              [-72.6702980101649, 3.228805269721527],
              [-72.67029812774095, 3.228805124070161],
              [-72.68956550747195, 3.223494998535441],
              [-72.68956601347087, 3.22349488718942],
              [-72.70950036013956, 3.220930873797628],
              [-72.72393234031458, 3.21968796634219],
              [-72.73502668761334, 3.219785506190527],
              [-72.74316105864554, 3.219857022368578],
              [-72.75238202072482, 3.219621126522158],
              [-72.76308113635498, 3.219347415718898],
              [-72.77366835196224, 3.219440143660202],
              [-72.78093625191482, 3.219503799433843],
              [-72.7861049972958, 3.219549210776131],
              [-72.79673174685622, 3.219642574818297],
              [-72.79673181497085, 3.21964230337505],
              [-72.80155426821169, 3.217629121023819],
              [-72.80264129830518, 3.212909603588494],
              [-72.80297183848242, 3.21147450980505],
              [-72.80297197654042, 3.211474386839241],
              [-72.80302617066378, 3.203937723832734],
              [-72.8030523300743, 3.200332170789196],
              [-72.80308582533165, 3.195715517553526],
              [-72.80550536305091, 3.191563921600453],
              [-72.80864827418935, 3.186171115751839],
              [-72.80864870396672, 3.186170934062057],
              [-72.82036749817571, 3.180105998165284],
              [-72.8368845592403, 3.175452970749663],
              [-72.84318440533733, 3.173818658635301],
              [-72.85477432623178, 3.170811990245222],
              [-72.86921587880228, 3.168195957372824],
              [-72.881567895696, 3.169673240018944],
              [-72.88377230378937, 3.170427319426577],
              [-72.89184461018598, 3.173188677472814],
              [-72.90043834615156, 3.176537898217136],
              [-72.9062284869225, 3.178794479017423],
              [-72.91856635156792, 3.182327327491265],
              [-72.93365524346271, 3.185198024202887],
              [-72.93365574890099, 3.185198225100763],
              [-72.95082096234358, 3.186030820523964],
              [-72.96388474881752, 3.184087063838172],
              [-72.97308093547497, 3.181622234340298],
              [-72.9762714863148, 3.180767010807714],
              [-72.97627201734376, 3.180766937137957],
              [-72.98522435990236, 3.177416983651052],
              [-72.99695360137629, 3.169979056841396],
              [-73.00240164341666, 3.168618880511957],
              [-73.01553166493562, 3.165340795588442],
              [-73.02770641734453, 3.162705524152055],
              [-73.03387963012523, 3.16136930878066],
              [-73.0358290579952, 3.160947176254496],
              [-73.03685627931215, 3.1607248376301],
              [-73.03685646197378, 3.160724700397071],
              [-73.04576887249054, 3.160187428495025],
              [-73.0567836509929, 3.159523418350356],
              [-73.06514659406022, 3.159594024114441],
              [-73.0767017311187, 3.15969158059361],
              [-73.08975227494652, 3.159801939297517],
              [-73.10689401502775, 3.164058367836605],
              [-73.10689404163244, 3.164058505806622],
              [-73.12328279848845, 3.177902953058974],
              [-73.13831736192344, 3.18899453876644],
              [-73.15472521417155, 3.200097416316665],
              [-73.1703998635998, 3.218047221039284],
              [-73.18400850681257, 3.231833429024327],
              [-73.18931028092854, 3.237204105461728],
              [-73.19222802156867, 3.240160092818523],
              [-73.20087228013257, 3.250057829322604],
              [-73.20790391038159, 3.258109095129222],
              [-73.22084608061314, 3.273979308766839],
              [-73.23035818681697, 3.289135009986181],
              [-73.23366353731694, 3.293950520339191],
              [-73.24123970452025, 3.304988112753109],
              [-73.25286104680109, 3.313308396738634],
              [-73.25286133758334, 3.313308400690129],
              [-73.27271191971681, 3.323753000898523],
              [-73.27938991143868, 3.327150746590879],
              [-73.29325042816946, 3.334202943265753],
              [-73.31037379043703, 3.341883438435453],
              [-73.32885673705196, 3.351630390632787],
              [-73.34527858136023, 3.361360256399675],
              [-73.35438985535788, 3.365398902010625],
              [-73.36103249120494, 3.368343303870972],
              [-73.38158702101462, 3.376736312415888],
              [-73.39804087986131, 3.386450921137102],
              [-73.41101322744841, 3.394109992615583],
              [-73.4301245760819, 3.412769694440585],
              [-73.44314111250252, 3.41835837019192],
              [-73.44314116778698, 3.418358438582659],
              [-73.4441119508978, 3.418960549616861],
              [-73.45545688427643, 3.425997442153227],
              [-73.4636467182271, 3.434287626638139],
              [-73.47869709758751, 3.44400411636737],
              [-73.48552462838724, 3.448778080039295],
              [-73.492157498631, 3.453415932655874],
              [-73.49432837760207, 3.454933614791964],
              [-73.49853026295425, 3.457871192806139],
              [-73.51027160684262, 3.46580884898438],
              [-73.5231542563614, 3.47451807712018],
              [-73.54569509547538, 3.494573551657679],
              [-73.56481984049849, 3.511860174044124],
              [-73.5784796327626, 3.524305033925135],
              [-73.59232652540452, 3.537835396618351],
              [-73.5956361368325, 3.541069471499167],
              [-73.5982795051289, 3.54365215684165],
              [-73.62357196473927, 3.563727975884281],
              [-73.64746659636457, 3.584195289452428],
              [-73.65022102138617, 3.58655463449282],
              [-73.65022116246654, 3.586554740235795],
              [-73.66997680081424, 3.612752296364781],
              [-73.67058333337178, 3.613707745076661],
              [-73.68221895216068, 3.632036912811234],
              [-73.69055284669668, 3.64292890502701],
              [-73.69857180372128, 3.653409289331544],
              [-73.717928614147, 3.67164349736235],
              [-73.71837951121815, 3.672068244560617],
              [-73.71837959439432, 3.672068316070684],
              [-73.73882116632926, 3.698954563975856],
              [-73.75451111906624, 3.716894031915472],
              [-73.76058370799747, 3.734073010947107],
              [-73.76180161997574, 3.744337578897342],
              [-73.76253674764516, 3.750533238394396],
              [-73.76253684716178, 3.750533383219281],
              [-73.76721676266315, 3.770441555352671],
              [-73.76989376781246, 3.781425890704939],
              [-73.77675852626759, 3.782850355442024],
              [-73.78364529609539, 3.780849143980648],
              [-73.78364536123695, 3.780848952859488],
              [-73.79192489500764, 3.776117894614141],
              [-73.79192515931491, 3.776117686837963],
              [-73.80363262397432, 3.772783689666785],
              [-73.80363263616724, 3.772783648343738],
              [-73.81668350333779, 3.77425670580861],
              [-73.82346128217543, 3.776728307533562],
              [-73.82628378652635, 3.77775756900543],
              [-73.82938725573396, 3.778815422240092],
              [-73.83862905996007, 3.781965597685918],
              [-73.84755135455146, 3.784090925174811],
              [-73.8723668736799, 3.791512612864636],
              [-73.88048262059839, 3.793939825395916],
              [-73.88048273818488, 3.793939951629345],
              [-73.89832775349431, 3.798189549494595],
              [-73.8983278418081, 3.798189590949039],
              [-73.9140754619541, 3.807904320197136],
              [-73.91407558147587, 3.807904530392344],
              [-73.91459310138015, 3.808550651238767],
              [-73.92566820980109, 3.822383107075487],
              [-73.9344848466507, 3.840951398759057],
              [-73.93634636045189, 3.845202710352112],
              [-73.93719423330984, 3.847139076178733],
              [-73.93859059625045, 3.858373880421483],
              [-73.93915482073773, 3.862913496505001],
              [-73.93915477056842, 3.862913521093983],
              [-73.9328263482193, 3.884790619271973],
              [-73.93136720500355, 3.897797796093088],
              [-73.93047520433238, 3.901677472791681],
              [-73.92995200134257, 3.903953097142432],
              [-73.92995215532561, 3.903953170962219],
              [-73.93046661854896, 3.930678493599],
              [-73.93046662236364, 3.930678510094372],
              [-73.93247119957971, 3.939601038325449],
              [-73.93515019758, 3.950584401148177],
              [-73.93990967902947, 3.958842738332552],
              [-73.93986518626023, 3.96569403490386],
              [-73.94256631024945, 3.973251160505543],
              [-73.94256645087034, 3.973251207327384],
              [-73.94320954036824, 3.980107657841299],
              [-73.9431477279401, 3.98969901721034],
              [-73.94668960474543, 3.999999999911626],
              [-73.94787172442946, 4.003438006111559],
              [-73.95262701139775, 4.012381290059835],
              [-73.95262673535288, 4.012381442691521],
              [-73.95259897800221, 4.016679091682369],
              [-73.95259126231332, 4.017861984430236],
              [-73.95115866107321, 4.02675793123955],
              [-73.95315039740585, 4.037734902782304],
              [-73.95579881754739, 4.053512736325118],
              [-73.9564363227364, 4.057252998383746],
              [-73.9577866269867, 4.06517527390725],
              [-73.96248017201256, 4.08370928098632],
              [-73.96794976886378, 4.088547007483476],
              [-73.97817090997398, 4.10301228511485],
              [-73.98084181535175, 4.115364953904821],
              [-73.99040606387672, 4.125029233202494],
              [-73.99040631054818, 4.125029329232332],
              [-73.99247756506601, 4.125266455923246],
              [-74, 4.126127904852629],
              [-74.00115568434876, 4.126260250702565],
              [-74.00964598492646, 4.12723206395718],
              [-74.00964604198676, 4.127231981688028],
              [-74.026644439364, 4.12420057338175],
              [-74.03662361835032, 4.123415875254421],
              [-74.04004648897708, 4.123146722834706],
              [-74.0524067784583, 4.117948596994104],
              [-74.0640701546629, 4.109296918367435],
              [-74.07230208599302, 4.102722130174561],
              [-74.0818914765183, 4.089581941392264],
              [-74.08977351214308, 4.080934961774267],
              [-74.09936690184671, 4.069521796352932],
              [-74.10726052075296, 4.065365689986518],
              [-74.10898911353544, 4.064172401375754],
              [-74.11288003162096, 4.061486143382647],
              [-74.12029638933059, 4.056366106062445],
              [-74.12029655773279, 4.0563658344707],
              [-74.12921165274432, 4.04875356032756],
              [-74.12921181543814, 4.0487533801966],
              [-74.13253918322552, 4.0470741311095],
              [-74.13538951886525, 4.045635545653504],
              [-74.14078187741657, 4.043177090624008],
              [-74.14603032612001, 4.040784246196243],
              [-74.1494492334709, 4.038713908938583],
              [-74.15289235434584, 4.036628909054565],
              [-74.15786713714499, 4.034009087647516],
              [-74.16078537701956, 4.032472283408429],
              [-74.16078547096754, 4.032472186022683],
              [-74.167617938855, 4.031026303518043],
              [-74.18379743053428, 4.027602226258542],
              [-74.1934155220844, 4.025860713973707],
              [-74.21675611558517, 4.015807977571059],
              [-74.2167561479377, 4.015807968450195],
              [-74.21840672172021, 4.014689920103607],
              [-74.24009368602928, 3.999999999911626],
              [-74.24282415010852, 3.99815048797258],
              [-74.24282417407429, 3.99815028248109],
              [-74.26236775282287, 3.982574786067046],
              [-74.26236787110716, 3.982574717901073],
              [-74.2945950605513, 3.956615540081704],
              [-74.30172838707107, 3.946929719103459],
              [-74.30554786614276, 3.94174352856306],
              [-74.31788069362321, 3.928942431075389],
              [-74.31788096671406, 3.92894226572613],
              [-74.32017236892766, 3.923915471464017],
              [-74.32402691456711, 3.915460239951848],
              [-74.3319432056495, 3.905862625624145],
              [-74.33258623424327, 3.905083025634637],
              [-74.33258648590188, 3.905082761781236],
              [-74.33873150101483, 3.891600744986453],
              [-74.34385825523408, 3.882265000157225],
              [-74.34927523501399, 3.854966747990242],
              [-74.35196786176085, 3.835962978641987],
              [-74.35832857665399, 3.815552716047673],
              [-74.36047672842463, 3.808659726428224],
              [-74.36293112013729, 3.804609422352695],
              [-74.36697239306, 3.797940403668938],
              [-74.36800499406836, 3.797020196278275],
              [-74.3703116921008, 3.794964571152708],
              [-74.37279740428791, 3.792749416696068],
              [-74.38513543797438, 3.782710734025249],
              [-74.3957510466226, 3.771293455653229],
              [-74.40394828191066, 3.755389915076738],
              [-74.41455106794577, 3.739827343208612],
              [-74.43031413671159, 3.727019630337348],
              [-74.43031432062995, 3.727019558950668],
              [-74.43990627248735, 3.718367225649441],
              [-74.45154457353105, 3.704875290509694],
              [-74.46593522100353, 3.692759970459709],
              [-74.487542658871, 3.682013454294571],
              [-74.4882377017409, 3.681630289321461],
              [-74.49508640961527, 3.677854716501023],
              [-74.5033195432766, 3.674385687453887],
              [-74.51772036017083, 3.666069385015521],
              [-74.52661484951777, 3.654654475242604],
              [-74.52910429125534, 3.647578537955942],
              [-74.53001962351603, 3.644976816624089],
              [-74.53411301051513, 3.635988395859387],
              [-74.53411321879011, 3.635988329903677],
              [-74.53818804281174, 3.621128145551926],
              [-74.54088421445795, 3.612218713893405],
              [-74.54090724775952, 3.612142600925365],
              [-74.54090732525125, 3.612142349410771],
              [-74.54498523656707, 3.598318386108833],
              [-74.5449852866693, 3.598318347578402],
              [-74.54771101050204, 3.591405359130531],
              [-74.54771113624936, 3.591405090436509],
              [-74.55592231801418, 3.58137306821244],
              [-74.55592233780608, 3.581372982845922],
              [-74.55760597111251, 3.579724432753281],
              [-74.56687859878934, 3.570644662815653],
              [-74.57509699744234, 3.563030657237694],
              [-74.57509719292105, 3.56303056394524],
              [-74.58493710648882, 3.552404608800712],
              [-74.59015644653448, 3.546768394824576],
              [-74.59015662918256, 3.546768135102276],
              [-74.60212935712381, 3.531892954773427],
              [-74.61343442418442, 3.523236457884335],
              [-74.62438935577839, 3.512508115580844],
              [-74.62638249055937, 3.491780179791934],
              [-74.62459837030181, 3.471059519286986],
              [-74.63036510419526, 3.448943016117792],
              [-74.63180386446584, 3.436862000350942],
              [-74.63303939092889, 3.426487502954818],
              [-74.6330394991687, 3.426487453283086],
              [-74.63227556073242, 3.402656632360971],
              [-74.6368493246356, 3.392669473508641],
              [-74.64114107200108, 3.383298119061749],
              [-74.64522537577324, 3.372237477172032],
              [-74.64379398080241, 3.354279782046364],
              [-74.64200682508248, 3.332523709281844],
              [-74.64194888159281, 3.314564064413749],
              [-74.64194912280244, 3.314563985449899],
              [-74.64533547491025, 3.300051176571451],
              [-74.65420977164267, 3.283801176507498],
              [-74.66037102818665, 3.277918069588482],
              [-74.66400907307987, 3.271248795288847],
              [-74.6675379001488, 3.264779739416956],
              [-74.67575049643827, 3.256474719599419],
              [-74.68499307451471, 3.248167907625854],
              [-74.69696507214479, 3.234675033796895],
              [-74.69723299072511, 3.234242995809602],
              [-74.7061858397295, 3.21980588264015],
              [-74.71236414828776, 3.209586173154517],
              [-74.71642896884377, 3.202862442087689],
              [-74.72940109972322, 3.180733490147726],
              [-74.73964276328458, 3.163444641781267],
              [-74.75434652509983, 3.146147221567817],
              [-74.77215124341527, 3.132642737520135],
              [-74.77215130340319, 3.132642664325214],
              [-74.781410250388, 3.129861032027969],
              [-74.79407949690646, 3.120511029980722],
              [-74.79407964237073, 3.120510857140367],
              [-74.81154472347065, 3.108387959188822],
              [-74.83140783052741, 3.094878518499364],
              [-74.84200581172918, 3.082424448905484],
              [-74.84200598826553, 3.082424261553043],
              [-74.84298361396397, 3.080654947018331],
              [-74.84678213054192, 3.073780875745071],
              [-74.8621722989467, 3.057517486548408],
              [-74.87140530396664, 3.047483154405081],
              [-74.88132672648219, 3.038138325904404],
              [-74.89277939978997, 3.024466480960438],
              [-74.89465756699906, 3.022224383401968],
              [-74.9064278744867, 3.010315110261086],
              [-74.91278457967366, 3.003883354894703],
              [-74.91278465130023, 3.003883256304968],
              [-74.91504177357184, 3.001951053610027],
              [-74.93057835382372, 2.988650754506674],
              [-74.94425580849065, 2.974117502250242],
              [-74.95193549635444, 2.962123573986474],
              [-74.95244813241486, 2.961322952887819],
              [-74.95244819467204, 2.961322818479011],
              [-74.9527116816946, 2.960580844776877],
              [-74.95686664283407, 2.948881209833213],
              [-74.9596172441465, 2.938864122615531],
              [-74.96161069267252, 2.931604419924852],
              [-74.96294265852366, 2.919860262842419],
              [-74.97010340117481, 2.906722391681204],
              [-74.97644821086041, 2.899766029275679],
              [-74.9783082202366, 2.897726740480644],
              [-74.9827526077381, 2.892882768161261],
              [-74.98822268541691, 2.886920078299144],
              [-74.9916407099717, 2.883194240832451],
              [-74.99839546263345, 2.8756247661546],
              [-75.00121185550842, 2.872468675085492],
              [-75.01283655336407, 2.860357392075675],
              [-75.01663541180432, 2.854888906921558],
              [-75.02254687728372, 2.846379308124436],
              [-75.02423781585505, 2.843945450836362],
              [-75.02581017799535, 2.841682266874721],
              [-75.02581017835713, 2.841682059303052],
              [-75.03361213481122, 2.833617607307939],
              [-75.04187907491757, 2.825072527362114],
              [-75.04846899088929, 2.818402449793352],
              [-75.05111043699219, 2.815728872394842],
              [-75.05111063172005, 2.815728763428633],
              [-75.06232969973709, 2.786352551938088],
              [-75.06385913621484, 2.779470093729909],
              [-75.0657076612026, 2.771151738466325],
              [-75.06910562192145, 2.761820903307952],
              [-75.08005742333637, 2.753270762850838],
              [-75.08108109826081, 2.752471573379196],
              [-75.08108117395038, 2.752471526839476],
              [-75.08280281220806, 2.737148271825512],
              [-75.08340956350892, 2.731748168534966],
              [-75.08340956042616, 2.731747974966371],
              [-75.0775329047333, 2.719329348249672],
              [-75.07666667074542, 2.717982405578514],
              [-75.06821888478126, 2.704846597527615],
              [-75.06165783435559, 2.692775135628156],
              [-75.06165762792745, 2.692774994585148],
              [-75.04823492166149, 2.680717534096905],
              [-75.03443618480917, 2.658992797203021],
              [-75.0344363316798, 2.658992516041031],
              [-75.03609414505675, 2.642414437330963],
              [-75.03609423725094, 2.642414296175141],
              [-75.04119934985263, 2.630663057884926],
              [-75.0528147967104, 2.61648108843881],
              [-75.06853380101441, 2.598837858510343],
              [-75.06853403849505, 2.598837837563079],
              [-75.07947568589081, 2.58845573714699],
              [-75.10205392107918, 2.570452613717277],
              [-75.12018047007741, 2.554875632611445],
              [-75.14072206667848, 2.543437190747014],
              [-75.15467189033204, 2.535831467110941],
              [-75.16742656016704, 2.528877365306953],
              [-75.18419508639282, 2.517792099805445],
              [-75.18419533203233, 2.51779200793128],
              [-75.19479051046132, 2.50706522701302],
              [-75.19479062611387, 2.507065270467996],
              [-75.2005508442702, 2.507487444816028],
              [-75.20851459262198, 2.508071167295374],
              [-75.21848206822222, 2.513573935172654],
              [-75.22054936505577, 2.516445286216733],
              [-75.22468679269987, 2.522191924528685],
              [-75.24050023276817, 2.531824635445636],
              [-75.25286501490098, 2.536285618811089],
              [-75.25286505366103, 2.536285625032531],
              [-75.25489398679589, 2.535411749942124],
              [-75.26656435458115, 2.530385376179878],
              [-75.2761018557439, 2.512064820582266],
              [-75.27632770163595, 2.50724506172866],
              [-75.27705649842969, 2.491691868497305],
              [-75.27705677529093, 2.491691649884529],
              [-75.28075948978656, 2.472348284352878],
              [-75.28167516739515, 2.462117093725594],
              [-75.28273752788695, 2.450246963229548],
              [-75.28402971889815, 2.428147408418937],
              [-75.28532433648522, 2.406738403570128],
              [-75.28765390604407, 2.396176853055692],
              [-75.29004819938152, 2.385321864873972],
              [-75.290048472624, 2.385321854786426],
              [-75.29583149323032, 2.372189195221264],
              [-75.29932657461833, 2.368933798653864],
              [-75.30437698240716, 2.364229736472102],
              [-75.30912129492579, 2.359425432062853],
              [-75.31223589252427, 2.356271450339105],
              [-75.32426005080364, 2.345125950501648],
              [-75.33650426926901, 2.333776471249546],
              [-75.35428467601089, 2.319236264127153],
              [-75.37068775317286, 2.303318510360836],
              [-75.37546354788121, 2.296403339003227],
              [-75.38502625658386, 2.285679762882436],
              [-75.38502628479809, 2.28567950649626],
              [-75.39528130489867, 2.276680151848681],
              [-75.41205148411908, 2.267666618341527],
              [-75.42984608511411, 2.257615172457091],
              [-75.43613142907886, 2.255534809753843],
              [-75.44560028247753, 2.252400749062247],
              [-75.45610468585731, 2.24627537683934],
              [-75.45689204302148, 2.245816249792454],
              [-75.45689216513199, 2.245816147750408],
              [-75.45971894640581, 2.239162040464437],
              [-75.46335127850107, 2.23061194347667],
              [-75.46639696894235, 2.222407536892561],
              [-75.46912320964829, 2.215063656209582],
              [-75.4691232990996, 2.215063623478978],
              [-75.47388410033386, 2.204350925665833],
              [-75.48207448518734, 2.193630475206773],
              [-75.487177016027, 2.182572238821764],
              [-75.49157910048719, 2.171294275103568],
              [-75.49634782735714, 2.159076988246015],
              [-75.50313805087971, 2.140765514793825],
              [-75.51096466607262, 2.124522893713412],
              [-75.52017717103783, 2.112420216139041],
              [-75.52017718796921, 2.112420110628412],
              [-75.53795342935614, 2.098226857942652],
              [-75.54953145556155, 2.076798474902967],
              [-75.55420447478049, 2.06565167297921],
              [-75.55836792428869, 2.055721400593216],
              [-75.56723206963235, 2.042238937014949],
              [-75.56969711477923, 2.037460603897776],
              [-75.57097624814709, 2.034981085281001],
              [-75.5763411433198, 2.026274769871847],
              [-75.57779214309073, 2.023920043216849],
              [-75.57779216211533, 2.023919928657526],
              [-75.58905334758252, 2.009741643643832],
              [-75.59588645010523, 1.999999999955729],
              [-75.59996607802589, 1.994183859856748],
              [-75.61738172825397, 1.975850413552641],
              [-75.61738200562752, 1.975850259194912],
              [-75.6341232132093, 1.960624695640082],
              [-75.65598925478658, 1.940900769018653],
              [-75.65598949215159, 1.940900646330542],
              [-75.66320003787379, 1.932704136485524],
              [-75.66998519391245, 1.924991322210512],
              [-75.68638633610446, 1.910802386954073],
              [-75.68772185597216, 1.909544044509225],
              [-75.70107434857475, 1.896963169755496],
              [-75.72053656026632, 1.876554940187038],
              [-75.72053664120138, 1.876554669269886],
              [-75.73522921699528, 1.864096161010403],
              [-75.7352292467287, 1.864095946280069],
              [-75.74083971622126, 1.860954601599486],
              [-75.74822689329332, 1.856818203978511],
              [-75.76122207020232, 1.84885041497588],
              [-75.7658573593719, 1.844845982204569],
              [-75.76820053239646, 1.842821711764249],
              [-75.77283841149793, 1.838815028034588],
              [-75.77453920586488, 1.835359738308137],
              [-75.78412290712023, 1.831886760971797],
              [-75.78653170788739, 1.831218896298948],
              [-75.7878893168314, 1.830842485320673],
              [-75.79056264907996, 1.829487935945187],
              [-75.79541541287591, 1.827029091492625],
              [-75.80670780315054, 1.822171407937839],
              [-75.82143764121275, 1.820066806124226],
              [-75.83170737462702, 1.816937127561613],
              [-75.83854911392855, 1.81347011214426],
              [-75.85874246850055, 1.806176025418865],
              [-75.86489853465925, 1.802710803464814],
              [-75.86864886721605, 1.79752544726488],
              [-75.86864905388657, 1.797525372400064],
              [-75.90009521076558, 1.775365204199482],
              [-75.9124735563558, 1.768457484260722],
              [-75.92369208574854, 1.762196998268872],
              [-75.92369214773119, 1.762196746964387],
              [-75.93564066746805, 1.750090651445635],
              [-75.9356408201595, 1.750090407193186],
              [-75.93785127329511, 1.74752233627748],
              [-75.94962957689485, 1.733838125632485],
              [-75.95678326361319, 1.722433276601436],
              [-75.96529138146079, 1.706884650959922],
              [-75.96529143933935, 1.706884475056868],
              [-75.97585190299048, 1.690295818957821],
              [-75.98436663751087, 1.676472651680371],
              [-75.98879665185505, 1.669905810689815],
              [-75.99866108220786, 1.650558430742715],
              [-75.9999999999878, 1.646739227515819],
              [-76.00205129824543, 1.640887990761559],
              [-76.00222956040535, 1.637208135934738],
              [-76.0023689424285, 1.634330881385426],
              [-76.0026932880724, 1.632154448587619],
              [-76.00303771994123, 1.629843232667343],
              [-76.0040422334979, 1.623629495210618],
              [-76.00882509215539, 1.619822577625708],
              [-76.01430087822196, 1.618084788229593],
              [-76.0184102387497, 1.617385455802592],
              [-76.01989260045384, 1.618059403710718],
              [-76.02218565152235, 1.619101927250352],
              [-76.02768237098488, 1.622885354779572],
              [-76.02768241270532, 1.622885470465249],
              [-76.03177512806118, 1.622558980955117],
              [-76.0321355604478, 1.622530238546448],
              [-76.03863813496085, 1.620445125442649],
              [-76.0444485970784, 1.61663608308719],
              [-76.0482005944859, 1.6121415887186],
              [-76.04820070190569, 1.612141315345174],
              [-76.05333145910444, 1.60971437125608],
              [-76.05424595789381, 1.607104704667791],
              [-76.05502590020551, 1.604879016219439],
              [-76.06220607788275, 1.600721849787597],
              [-76.0775966981846, 1.593440267062791],
              [-76.09640614992904, 1.584080592743421],
              [-76.10907044808943, 1.580601016633427],
              [-76.1134328446426, 1.578672663903201],
              [-76.12241028110498, 1.574704280033997],
              [-76.12498258725188, 1.57363010114517],
              [-76.12822554103214, 1.572275864017363],
              [-76.15082446083397, 1.568773394075307],
              [-76.15082458864796, 1.568773236856534],
              [-76.1597010954278, 1.560471458143871],
              [-76.17097240516681, 1.551474699696044],
              [-76.17097260013702, 1.551474575797116],
              [-76.17124491166321, 1.551284901795953],
              [-76.17643975428155, 1.547666748712117],
              [-76.17865666853645, 1.545246981672646],
              [-76.18087504722739, 1.542825616197891],
              [-76.18563832125584, 1.539754587550611],
              [-76.18839379372503, 1.537978050059988],
              [-76.1883938293313, 1.537977913758961],
              [-76.19401580210351, 1.53596397096884],
              [-76.1969379421963, 1.534917181307974],
              [-76.20584221130223, 1.531727171254738],
              [-76.20584231052617, 1.531727050155562],
              [-76.2137166337172, 1.530328692474735],
              [-76.22842273769322, 1.523739397721914],
              [-76.23870130951549, 1.524060606279787],
              [-76.24206713395436, 1.525406414464794],
              [-76.24213230174213, 1.525432471481072],
              [-76.25139676934637, 1.529206202305191],
              [-76.25600008951814, 1.53202212664832],
              [-76.25757734326756, 1.532986957914138],
              [-76.25757754663321, 1.53298703640901],
              [-76.25782005619249, 1.533086227457839],
              [-76.26512602331235, 1.536074366727723],
              [-76.26512616067369, 1.536074563952836],
              [-76.27749217713607, 1.544670900740462],
              [-76.28847866340016, 1.550855284072191],
              [-76.29980897657657, 1.557383678019926],
              [-76.31457417505311, 1.566318751184999],
              [-76.32899707175567, 1.575254474710805],
              [-76.33152988067131, 1.576884196848906],
              [-76.33861316543256, 1.581441897950915],
              [-76.34648615464891, 1.587132686435601],
              [-76.35098102569269, 1.590381688774258],
              [-76.37156738339228, 1.598611722923336],
              [-76.37156742609525, 1.598611875506871],
              [-76.38529723494383, 1.605478433564062],
              [-76.39262066474697, 1.608165719605221],
              [-76.39880651142003, 1.610435693629171],
              [-76.40210718278345, 1.611646737131722],
              [-76.41651268973379, 1.616096187929156],
              [-76.42439501437626, 1.617111495477405],
              [-76.4264454175068, 1.617575434509661],
              [-76.43020804418805, 1.618426793618134],
              [-76.43111647769211, 1.618632067233312],
              [-76.4319385901047, 1.61881783535642],
              [-76.44052566123524, 1.624661308529815],
              [-76.44171148810568, 1.627549247132505],
              [-76.44363888727135, 1.632243195795753],
              [-76.44430875646938, 1.636352846381772],
              [-76.44504405910398, 1.640863931399842],
              [-76.44706002043068, 1.649096413865016],
              [-76.44783024129084, 1.65224172704221],
              [-76.44854841592037, 1.654886051924815],
              [-76.45026461011963, 1.661205093057411],
              [-76.45476371706994, 1.672577886451399],
              [-76.4547637680488, 1.672578070600523],
              [-76.45650746607129, 1.680163497340234],
              [-76.46133536434964, 1.688085821378664],
              [-76.46895321690907, 1.708075076499075],
              [-76.46937602805447, 1.708723982715769],
              [-76.4755193771541, 1.718148769508684],
              [-76.47860601319258, 1.722884115994412],
              [-76.49306470074457, 1.740095802194096],
              [-76.49398930542463, 1.741352763254324],
              [-76.49677773910786, 1.745143521222991],
              [-76.50167696767268, 1.751802483184986],
              [-76.50603683281126, 1.755985461410089],
              [-76.51852996279086, 1.76797172451841],
              [-76.52505503644414, 1.77397686809904],
              [-76.52712693392367, 1.775883672912317],
              [-76.5525139289436, 1.784785345379331],
              [-76.5548860713228, 1.785120053833684],
              [-76.56930891882274, 1.78715511253691],
              [-76.56930898432735, 1.787155163305245],
              [-76.57355911837664, 1.787381481220856],
              [-76.58781114611281, 1.78814055446466],
              [-76.59783617923749, 1.788594016518123],
              [-76.60244524584904, 1.78880249830409],
              [-76.60939550543522, 1.789116989337266],
              [-76.6093955124332, 1.789117064224894],
              [-76.62244252562016, 1.796325367706978],
              [-76.6403031737758, 1.807659954348134],
              [-76.64030323802854, 1.807660149780352],
              [-76.65204028091121, 1.808433653083674],
              [-76.65537894066944, 1.808653734301605],
              [-76.65537914019515, 1.80865369306404],
              [-76.66220465974818, 1.802771123631495],
              [-76.66670537063754, 1.802971091663613],
              [-76.68413082618662, 1.803745310257202],
              [-76.68711154131259, 1.812236851403975],
              [-76.6893322689861, 1.818563319925347],
              [-76.68960360080591, 1.827665734199236],
              [-76.69201665095797, 1.827481750157972],
              [-76.69919558339636, 1.827695991603321],
              [-76.70961764181442, 1.834912307329887],
              [-76.70538360724044, 1.83996508620686],
              [-76.70487264642237, 1.846854507709281],
              [-76.7053985270281, 1.853429104928182],
              [-76.70626052195146, 1.860014598621149],
              [-76.70541361445382, 1.866547956876389],
              [-76.70064617401059, 1.871104072293814],
              [-76.70497826577567, 1.871983226337164],
              [-76.70497842890134, 1.871983499631078],
              [-76.7203803913987, 1.869180349637046],
              [-76.73163940729843, 1.859145946523822],
              [-76.73094162044796, 1.849878320162889],
              [-76.73091241985571, 1.849490493739495],
              [-76.73091239182556, 1.84949026896685],
              [-76.7313962763488, 1.845883173779413],
              [-76.73290261110296, 1.834652919555943],
              [-76.73814399437605, 1.822586036195815],
              [-76.74070248824974, 1.816695788232082],
              [-76.74134274166849, 1.806346158011222],
              [-76.74057358277061, 1.78703320144963],
              [-76.74836469369606, 1.767007183521986],
              [-76.74836481263412, 1.767007012586706],
              [-76.74943347687696, 1.762537474230842],
              [-76.75273864282461, 1.74871509078678],
              [-76.75845136390865, 1.736115825586355],
              [-76.75884537290283, 1.735246848484903],
              [-76.75884541081133, 1.735246809161865],
              [-76.75937757880529, 1.7348043279117],
              [-76.77590852747133, 1.721059112299355],
              [-76.77971699881925, 1.715646830651015],
              [-76.78441894704919, 1.708964814222018],
              [-76.78640393057606, 1.700526052758651],
              [-76.78677313804721, 1.698956440851358],
              [-76.78677323076066, 1.698956204154394],
              [-76.78585720348451, 1.692473122757716],
              [-76.78536058841523, 1.688957900606182],
              [-76.78440304672112, 1.685557771320662],
              [-76.78118739339631, 1.674139326454119],
              [-76.779855525323, 1.668650794506481],
              [-76.77909643225723, 1.665522626915174],
              [-76.7786893219528, 1.650348127470793],
              [-76.77487419498428, 1.638976800582251],
              [-76.76971537599826, 1.633817174981211],
              [-76.76080708681073, 1.632806106086515],
              [-76.76080689761649, 1.63280630583806],
              [-76.75952205606693, 1.633508062925229],
              [-76.75636521854436, 1.63523250702773],
              [-76.74716639098688, 1.646638561200627],
              [-76.74446615436051, 1.655958199518337],
              [-76.74484189365644, 1.663889746926233],
              [-76.7448420194411, 1.663889841798745],
              [-76.74420961400924, 1.676308088428022],
              [-76.73808269877401, 1.685292059697882],
              [-76.73021178399725, 1.686692931170519],
              [-76.7302116732299, 1.686692685774541],
              [-76.719559517525, 1.678443194480977],
              [-76.7188235421547, 1.666373226834787],
              [-76.72082789450884, 1.654296186788125],
              [-76.71903979240872, 1.636365749569896],
              [-76.71111683621837, 1.625349728632915],
              [-76.7075317095522, 1.624757617452883],
              [-76.70289167791792, 1.623991280622365],
              [-76.69680284769409, 1.621685130214935],
              [-76.69294373308861, 1.620223486780543],
              [-76.69158444733637, 1.61378425743008],
              [-76.69119723733219, 1.611949960248998],
              [-76.69118250982324, 1.611808275782897],
              [-76.69115293156025, 1.611523721184162],
              [-76.69085745342547, 1.60866684295323],
              [-76.69048377907761, 1.605053911964402],
              [-76.69262282149752, 1.592920213563485],
              [-76.69316047214353, 1.589870395232148],
              [-76.69443758089503, 1.586796998095564],
              [-76.69689085322065, 1.580893131152439],
              [-76.69837215861962, 1.57863721541616],
              [-76.70029385402925, 1.575710619105897],
              [-76.70229892894938, 1.571475230809009],
              [-76.70470773724566, 1.566387022700121],
              [-76.70725129427971, 1.558395728269798],
              [-76.7091026201801, 1.552579271174952],
              [-76.71654248450201, 1.540708752548297],
              [-76.7210106703579, 1.533579632831187],
              [-76.72101071228091, 1.533579371907737],
              [-76.7237456443577, 1.527252838240612],
              [-76.72474213475729, 1.524947664564348],
              [-76.72474228253147, 1.524947382462096],
              [-76.72540562367476, 1.523896728191353],
              [-76.73324815200287, 1.511474518778045],
              [-76.74352567689365, 1.500747990005206],
              [-76.74484190563113, 1.499374257946704],
              [-76.7448419830418, 1.499374117630634],
              [-76.74804745057527, 1.495507904875369],
              [-76.75745000766038, 1.484167054536907],
              [-76.76698862886002, 1.472071913440399],
              [-76.77652315374598, 1.458942592002009],
              [-76.78641401368294, 1.449260944656889],
              [-76.79253942700163, 1.439933966884456],
              [-76.79899897321788, 1.428537475883218],
              [-76.79899921494095, 1.428537305723893],
              [-76.80171746889886, 1.420838170096231],
              [-76.80509267506494, 1.411278933144431],
              [-76.80640366597409, 1.396790868421335],
              [-76.80838671216898, 1.382393913629854],
              [-76.80939725362758, 1.375057362554108],
              [-76.80939735347206, 1.37505726441956],
              [-76.80963735355314, 1.368531852072592],
              [-76.81032362601583, 1.349880125554681],
              [-76.81438371987221, 1.337800405053567],
              [-76.81674881930805, 1.329862665621574],
              [-76.8204792348535, 1.320887448601754],
              [-76.82369019012722, 1.319297923814996],
              [-76.82868280453692, 1.316826421200224],
              [-76.83633001419065, 1.31304120053086],
              [-76.84918273583644, 1.306679352244455],
              [-76.84918276132228, 1.306679080153195],
              [-76.85625427391095, 1.30219917966329],
              [-76.86284312008769, 1.298024816508959],
              [-76.86707687618086, 1.296814081792232],
              [-76.87378677078142, 1.294895241367863],
              [-76.88540914520823, 1.290384055350558],
              [-76.88803912048695, 1.286483088478328],
              [-76.8942579367723, 1.277258896711138],
              [-76.90838348799862, 1.264732056020957],
              [-76.91063325367354, 1.262736915742971],
              [-76.91063325958213, 1.262736869427064],
              [-76.9157382506219, 1.255138501360571],
              [-76.9157384965506, 1.255138434252158],
              [-76.92150879541771, 1.242710916836988],
              [-76.92450519928676, 1.239039317801697],
              [-76.92672078275933, 1.23632448545735],
              [-76.92783114770606, 1.234963776471583],
              [-76.92968497157248, 1.232691987542688],
              [-76.92968516952034, 1.232691900429513],
              [-76.933362863385, 1.232171899210192],
              [-76.94952937007639, 1.22988580382289],
              [-76.94952965071657, 1.229886024030132],
              [-76.95741683003267, 1.233315093807436],
              [-76.95741698877738, 1.233315239349388],
              [-76.96903183833528, 1.234831680336542],
              [-76.97556979569575, 1.235685349813346],
              [-76.9755699694489, 1.235685302241042],
              [-77.00907316966105, 1.224571012128494],
              [-77.01793472751875, 1.214895734569428],
              [-77.02190223906737, 1.211195881636763],
              [-77.03498618855295, 1.198994609307211],
              [-77.05407092318832, 1.17791785926132],
              [-77.0670133522597, 1.161682744225505],
              [-77.07789684981189, 1.144074006026731],
              [-77.07802019405024, 1.143844995628074],
              [-77.0877506971875, 1.125778595180229],
              [-77.09073284598661, 1.121376720294691],
              [-77.09523666256266, 1.114728749850854],
              [-77.10930831090484, 1.102998830408029],
              [-77.11638792060543, 1.097097371712102],
              [-77.11999131604671, 1.094333847276173],
              [-77.12901311459164, 1.087414828494769],
              [-77.1454101423405, 1.079447830765974],
              [-77.1492569406371, 1.076625063849084],
              [-77.15530674907676, 1.072185735919646],
              [-77.16623960279836, 1.067333809767549],
              [-77.17306669793679, 1.062836576455836],
              [-77.17478330166402, 1.061444966995162],
              [-77.18159529604443, 1.055922646569413],
              [-77.19284962145787, 1.045899703988063],
              [-77.20216081294686, 1.038211363218937],
              [-77.21126980103429, 1.030689983751954],
              [-77.21497031424242, 1.027356625805635],
              [-77.22047743247245, 1.022395909472277],
              [-77.23100518878904, 1.001689738616989],
              [-77.2316355364272, 0.999216706269919],
              [-77.23302720621017, 0.99375679148431],
              [-77.23435194440263, 0.982378545188092],
              [-77.23293914971875, 0.971006850314086],
              [-77.23293926833955, 0.971006807491677],
              [-77.23331402263851, 0.966667957451274],
              [-77.23377698850852, 0.96130945006529],
              [-77.23232251205337, 0.962777460407722],
              [-77.21825449338867, 0.970661813818026],
              [-77.2143475206769, 0.97774955114977],
              [-77.2065355869101, 0.986415768488996],
              [-77.20263649318731, 0.99507896834308],
              [-77.20498661120183, 1.002947396137301],
              [-77.19724257085083, 1.009975957232598],
              [-77.18857551532129, 1.010835102308874],
              [-77.18434852154599, 1.012969805411558],
              [-77.16937257512846, 0.997088110536083],
              [-77.1579896153295, 1.006892255051469],
              [-77.15396864873442, 1.011175910492284],
              [-77.14399753670763, 1.010091124582345],
              [-77.1361765504854, 1.010097997742946],
              [-77.12757856183809, 1.013255628310105],
              [-77.11975858144424, 1.017985368638392],
              [-77.10959652966294, 1.025080817321054],
              [-77.10256964494829, 1.032172242690194],
              [-77.09006451595114, 1.043205509488359],
              [-77.08302254381077, 1.044000449186285],
              [-77.08145160751617, 1.033767318866012],
              [-77.08692163753355, 1.028251523657218],
              [-77.10099049438857, 1.018791372448447],
              [-77.10646052440596, 1.011700952907688],
              [-77.11817155189553, 0.995158093509133],
              [-77.13615458989906, 0.988843502926954],
              [-77.14241050715891, 0.987262843624706],
              [-77.14553058679985, 0.979388380860144],
              [-77.14239458154286, 0.972305840308366],
              [-77.13223252976155, 0.971527832054903],
              [-77.1259766125017, 0.972320760096011],
              [-77.12519055762118, 0.966811335133684],
              [-77.12908965134397, 0.958148805831854],
              [-77.13143155509337, 0.954210317164097],
              [-77.13533064881615, 0.94239904211247],
              [-77.13533064881615, 0.936100377146374],
              [-77.13139350125292, 0.910913428632966],
              [-77.12747965538053, 0.90461878698045],
              [-77.1188655734791, 0.893604798559579],
              [-77.1188655734791, 0.886520413989103],
              [-77.12511461757835, 0.881791511851191],
              [-77.13449849346812, 0.877847994041531],
              [-77.14466054524942, 0.877838941586049],
              [-77.1493606136404, 0.881771562921585],
              [-77.14938358005512, 0.909323381599108],
              [-77.15407560181905, 0.914043231281596],
              [-77.16424553258933, 0.915608970796256],
              [-77.17519363925118, 0.915599080150457],
              [-77.17642963469457, 0.913377372892739],
              [-77.17958860636628, 0.905047772785338],
              [-77.18672965260228, 0.892841710093762],
              [-77.19364153760525, 0.889601936875408],
              [-77.20046960357638, 0.889810143350473],
              [-77.20549053122122, 0.894103521798968],
              [-77.21181879629539, 0.895260982801065],
              [-77.21449922572863, 0.891766417312451],
              [-77.23698666573084, 0.86586604063394],
              [-77.24687743266631, 0.85722760887318],
              [-77.25171610206039, 0.854461983156163],
              [-77.26019378323325, 0.849616416914107],
              [-77.27213227177427, 0.839595081458437],
              [-77.27436791455008, 0.838236164759899],
              [-77.27838163865104, 0.835796456250522],
              [-77.28578804084218, 0.831294324859335],
              [-77.29739941661829, 0.825410162386792],
              [-77.29831278582903, 0.82492295883034],
              [-77.30388715506498, 0.821949514482911],
              [-77.31378157017623, 0.814346294920934],
              [-77.31499337038026, 0.813691356194702],
              [-77.32402510442465, 0.808809996733765],
              [-77.32995007175998, 0.806198586040973],
              [-77.33188159108819, 0.805347274980239],
              [-77.33188181289636, 0.805347084679748],
              [-77.33244143499806, 0.804811599457719],
              [-77.33801853611237, 0.799475261059133],
              [-77.34961653385646, 0.790145459317267],
              [-77.35608896350531, 0.782549431546229],
              [-77.35802902162557, 0.780968644982594],
              [-77.37177894484822, 0.769765014712495],
              [-77.37848917991931, 0.764667600944189],
              [-77.38133172750966, 0.76250826657353],
              [-77.38609187948084, 0.758936659412158],
              [-77.39054333630935, 0.755596671076796],
              [-77.3905433864476, 0.755596392710783],
              [-77.39799467718686, 0.752633149513702],
              [-77.4079653977932, 0.748667628944692],
              [-77.41674675712432, 0.742199096667197],
              [-77.41922442220005, 0.740373996940506],
              [-77.4206837460631, 0.739407335090159],
              [-77.4230978788366, 0.737808204072772],
              [-77.42599196731044, 0.735891621317372],
              [-77.42809738656587, 0.734497327302424],
              [-77.42809753618779, 0.734497071776807],
              [-77.4363897056062, 0.732848977622825],
              [-77.44211348049863, 0.731711203923546],
              [-77.44666678502453, 0.730426580602442],
              [-77.454415993762, 0.728240297166372],
              [-77.4565201485275, 0.72758758030844],
              [-77.46411248061905, 0.725232147851559],
              [-77.46671696609971, 0.724424136615919],
              [-77.47799429910286, 0.721299419705237],
              [-77.48676896739099, 0.718848425135268],
              [-77.49166361976859, 0.717481220586268],
              [-77.4916636622188, 0.717481060795421],
              [-77.50053235233356, 0.710570863028619],
              [-77.50325096494763, 0.705741458541281],
              [-77.50936234348644, 0.700358107741649],
              [-77.5110928845797, 0.698833720122883],
              [-77.51688651193689, 0.692964015752236],
              [-77.5171904283983, 0.692573750050974],
              [-77.52199308664497, 0.686406552722854],
              [-77.52721687061583, 0.681575438954041],
              [-77.52983412640704, 0.679154921459451],
              [-77.55237270615928, 0.669117128101423],
              [-77.55237276867774, 0.669116990541315],
              [-77.56180952821813, 0.667512662478723],
              [-77.56467866675972, 0.667024846333834],
              [-77.56467886197746, 0.667024851736983],
              [-77.56484553158259, 0.667034390052106],
              [-77.57049490607773, 0.667357502123739],
              [-77.5790448630288, 0.666995957830907],
              [-77.58760454191253, 0.669390314056089],
              [-77.59855873938567, 0.671780053417393],
              [-77.60847853485367, 0.672104563686463],
              [-77.61342609206471, 0.672406014333578],
              [-77.6205838388552, 0.674563143503312],
              [-77.62206266090723, 0.675338401263473],
              [-77.62467470784604, 0.676713018680037],
              [-77.62728241319128, 0.677888945411363],
              [-77.62918958511695, 0.678522492000273],
              [-77.63110553852655, 0.67943602501149],
              [-77.6334800953977, 0.680467293997493],
              [-77.63552847868085, 0.681254483898672],
              [-77.63729887221132, 0.681851816441017],
              [-77.6389461863087, 0.683659303343293],
              [-77.6400971077147, 0.684861324389666],
              [-77.64286190465117, 0.685974172681455],
              [-77.6450181021664, 0.686571788446972],
              [-77.64712332738063, 0.687097116113383],
              [-77.64735599828988, 0.687151470961314],
              [-77.65016401300167, 0.687433440125083],
              [-77.65196174002678, 0.687470837725317],
              [-77.65361559784355, 0.687968753658582],
              [-77.65560001677778, 0.688873335048395],
              [-77.65792915647918, 0.689182063514561],
              [-77.6601384900798, 0.689219752562308],
              [-77.66179942514988, 0.689238989707727],
              [-77.66323928773797, 0.689456771244294],
              [-77.66591169148806, 0.691283099304154],
              [-77.66640983797522, 0.694209784985009],
              [-77.66612424932768, 0.696837862071397],
              [-77.66607294106005, 0.699619647431925],
              [-77.66551146442235, 0.701807176436853],
              [-77.66647200760721, 0.699841064605354],
              [-77.66647026094475, 0.699811763383313],
              [-77.70495047900405, 0.614965350744849],
              [-77.69525209455611, 0.609318977922838],
              [-77.68958398421778, 0.613339905568645],
              [-77.68949162823539, 0.614397568555262],
              [-77.68766051766598, 0.615064600410562],
              [-77.68595865604365, 0.613907562495172],
              [-77.68366955828431, 0.613887613565623],
              [-77.68013759192095, 0.612476603983737],
              [-77.67807765539463, 0.612927885651118],
              [-77.67806256796891, 0.614760504963101],
              [-77.67930560421098, 0.616378379915545],
              [-77.67849758874412, 0.618093484944893],
              [-77.6764376522178, 0.618309402770876],
              [-77.67460654164839, 0.619093278356559],
              [-77.67572753738011, 0.62105397314923],
              [-77.67742956664051, 0.622788859470063],
              [-77.67695649202484, 0.624621814058173],
              [-77.67706260691914, 0.626464659292083],
              [-77.67522462318912, 0.626905547399474],
              [-77.67339351261971, 0.627690596451657],
              [-77.67178351665628, 0.629513325117898],
              [-77.67130256305161, 0.631699325468048],
              [-77.66935762623697, 0.632709177163576],
              [-77.66819757083641, 0.634197970807008],
              [-77.66580151999244, 0.633718190668787],
              [-77.66386462980486, 0.633816258936008],
              [-77.66180452564049, 0.633454328356549],
              [-77.66019452967704, 0.635050913274938],
              [-77.65847758062898, 0.635728003414044],
              [-77.65826451265008, 0.63376831444981],
              [-77.6582796000758, 0.631934186395256],
              [-77.65624950312481, 0.628013635000343],
              [-77.65626559637893, 0.626062830853471],
              [-77.65583057560372, 0.623876998141327],
              [-77.65607448898635, 0.621466027509712],
              [-77.6551746078606, 0.619623182275859],
              [-77.65140560127521, 0.620171191105953],
              [-77.6495744907058, 0.619691075691605],
              [-77.64617160565147, 0.616799486731452],
              [-77.64282957321424, 0.620328100333552],
              [-77.64111362999458, 0.620887005637826],
              [-77.63928251942517, 0.62087728263009],
              [-77.63732953598347, 0.622122162890889],
              [-77.63516264637254, 0.622102884513538],
              [-77.63357561682382, 0.620828164677448],
              [-77.63129456569152, 0.6203480492631],
              [-77.62705952528916, 0.62042700679109],
              [-77.62319161224622, 0.619024379112432],
              [-77.62124650779351, 0.619231244482989],
              [-77.61918657126719, 0.619220348008866],
              [-77.61920149105485, 0.617378340965274],
              [-77.61898758488562, 0.615427369180338],
              [-77.61797354223852, 0.613584691584549],
              [-77.6175385214633, 0.611281679865954],
              [-77.619483625916, 0.61061481564866],
              [-77.62131456884735, 0.610851017680375],
              [-77.62337450537368, 0.610300829555399],
              [-77.62554960924973, 0.610085917557797],
              [-77.62771549303227, 0.610447848137255],
              [-77.62989059690833, 0.609772099102599],
              [-77.63195757423331, 0.608527051203794],
              [-77.63254564856065, 0.606351109137393],
              [-77.63095861901193, 0.60507722749162],
              [-77.62696061883159, 0.604930208909764],
              [-77.62045257392398, 0.603959249245065],
              [-77.61624150576473, 0.602087570264246],
              [-77.61511263104401, 0.600587544870564],
              [-77.61501355694838, 0.59875492555858],
              [-77.6151426382574, 0.596568925208373],
              [-77.61308253409302, 0.596666825837531],
              [-77.60870349259396, 0.601567556990744],
              [-77.60687254966261, 0.602126462295018],
              [-77.60526255369918, 0.603263551280804],
              [-77.60365255773574, 0.604861309665637],
              [-77.60121157989072, 0.60516842259824],
              [-77.59931157007713, 0.605048393744653],
              [-77.59725163355083, 0.604577498423851],
              [-77.59886950850326, 0.603322392241182],
              [-77.60058561936098, 0.602420499458674],
              [-77.6017376281345, 0.600940758270667],
              [-77.60278352201371, 0.599107636044494],
              [-77.60450751186043, 0.597979767152196],
              [-77.60623150170716, 0.596156870847892],
              [-77.60818465278692, 0.595020117138176],
              [-77.61001559571827, 0.594344032827394],
              [-77.61185458527667, 0.59275616508836],
              [-77.61324363427221, 0.591158406703585],
              [-77.61530256497014, 0.590717518596136],
              [-77.61759149509142, 0.590511323777832],
              [-77.61851451026996, 0.58856051963096],
              [-77.62208553630215, 0.585718886813765],
              [-77.62346653867063, 0.584130013246352],
              [-77.62359662580803, 0.581827001527756],
              [-77.62246657762086, 0.57998432393191],
              [-77.62214655655733, 0.577572347471914],
              [-77.6221775695991, 0.573328589890252],
              [-77.62111658829417, 0.572379590811806],
              [-77.6188585035766, 0.574102742468256],
              [-77.61724850761316, 0.57569161603567],
              [-77.61539459826709, 0.575753642119196],
              [-77.61301464067724, 0.576582277067928],
              [-77.60967260824002, 0.580112064136415],
              [-77.60843661279661, 0.580743221446141],
              [-77.60636862964324, 0.578936418395983],
              [-77.60465955958418, 0.577887507031619],
              [-77.60225663557961, 0.578445406507512],
              [-77.60043357163725, 0.578426295768224],
              [-77.59838050827155, 0.578181376557211],
              [-77.59427656083496, 0.576544558503599],
              [-77.59296462534871, 0.576254377015402],
              [-77.59152260272508, 0.578240217517532],
              [-77.59002660064492, 0.579603114975157],
              [-77.58785250259726, 0.580162020279431],
              [-77.58408349601187, 0.580250197900909],
              [-77.58213755336885, 0.580691253646421],
              [-77.57880356755868, 0.583190904813705],
              [-77.5776445179865, 0.585013968756073],
              [-77.57626351561804, 0.586493709944023],
              [-77.57487463426057, 0.588434455807089],
              [-77.57305157031821, 0.587738757842885],
              [-77.56928256373283, 0.588051570469702],
              [-77.56801555524765, 0.58941446792727],
              [-77.56890856321279, 0.591600468277477],
              [-77.56651251236883, 0.5913553814284],
              [-77.56503260354279, 0.590307475892416],
              [-77.56309554571715, 0.589836412933494],
              [-77.56182853723196, 0.591199310391119],
              [-77.56158462384934, 0.593384304912945],
              [-77.5608825556387, 0.595098907028103],
              [-77.5605236425444, 0.597158005364065],
              [-77.56200455719882, 0.59854990420672],
              [-77.5639345742258, 0.599941803049319],
              [-77.56311750630348, 0.60165657280254],
              [-77.56083662280926, 0.601293468756637],
              [-77.55889855915522, 0.600470533502119],
              [-77.55673250773462, 0.600343631487931],
              [-77.55502360531362, 0.599755724798626],
              [-77.55216252648104, 0.600078595709249],
              [-77.55055957131628, 0.600980488491757],
              [-77.54872158758626, 0.602577576324336],
              [-77.54483054049054, 0.603234549895831],
              [-77.54564660258445, 0.600941764099048],
              [-77.54656961776298, 0.599343670438145],
              [-77.54738651804723, 0.597393872119653],
              [-77.54933162249993, 0.59706999538065],
              [-77.55117061205834, 0.595471901719748],
              [-77.55221550010916, 0.593423029305654],
              [-77.55449655124146, 0.593894259902584],
              [-77.55574059331191, 0.595286158745182],
              [-77.55733449602124, 0.596560040391012],
              [-77.55894449198468, 0.595540968601995],
              [-77.5591815322067, 0.593590164455122],
              [-77.55841861137895, 0.589562324699443],
              [-77.56082958201058, 0.588326664532133],
              [-77.5628895185369, 0.587533568852962],
              [-77.56529261017954, 0.587101565562875],
              [-77.56723754699418, 0.585847632846651],
              [-77.56964851762581, 0.584611805041334],
              [-77.5725325628731, 0.581760113940334],
              [-77.57357057776332, 0.57981903280114],
              [-77.57574450817292, 0.579603114975157],
              [-77.5778124913263, 0.578476251911184],
              [-77.57885754701519, 0.576417488851348],
              [-77.58080265146789, 0.576202576853689],
              [-77.58332057518413, 0.575418533629943],
              [-77.58525863883817, 0.575897475577847],
              [-77.58743256924777, 0.575339576101953],
              [-77.58960750548576, 0.574437683319445],
              [-77.59339159949687, 0.573202526066382],
              [-77.59477260186534, 0.571956639977202],
              [-77.59513050913127, 0.57000684165871],
              [-77.59719061329565, 0.569104948876202],
              [-77.59902960285406, 0.567968195166486],
              [-77.60052459910581, 0.566488118702409],
              [-77.60364551693706, 0.561694675909962],
              [-77.60309549645018, 0.559626692756581],
              [-77.6017376281345, 0.5580089854422],
              [-77.6001435577871, 0.55696091226821],
              [-77.59889951571665, 0.555569013425554],
              [-77.59778556078359, 0.551658352676384],
              [-77.59677151813649, 0.549698496074086],
              [-77.59645049124457, 0.547521548179361],
              [-77.59669457226526, 0.545570911670552],
              [-77.59874763563097, 0.546041806991354],
              [-77.59998363107437, 0.548354709355749],
              [-77.60122649967838, 0.550080375583093],
              [-77.60292852893878, 0.551246465953966],
              [-77.60510262698645, 0.550687225373565],
              [-77.60295853615217, 0.547452648935177],
              [-77.60263063609965, 0.545619861985131],
              [-77.60333253667221, 0.543786907397021],
              [-77.60425555185074, 0.542188981374125],
              [-77.60586554781419, 0.541278203774255],
              [-77.61170153172455, 0.540179168628868],
              [-77.61353264229396, 0.539051299736514],
              [-77.6136625617933, 0.537101333779958],
              [-77.61322050021943, 0.535258656184169],
              [-77.61244165377563, 0.533298631943808],
              [-77.61188459249007, 0.531229978238173],
              [-77.61247249917935, 0.529397023650063],
              [-77.61351051406956, 0.527682253896842],
              [-77.61386858897355, 0.525731282111906],
              [-77.61399850847289, 0.523781483793414],
              [-77.61527255775674, 0.522074425391111],
              [-77.61731757449539, 0.522780349277241],
              [-77.61914063843776, 0.524054230923014],
              [-77.62130752804869, 0.52429931777209],
              [-77.62359662580803, 0.524093458229913],
              [-77.62598463002493, 0.524681364919161],
              [-77.62770861987167, 0.523896483505098],
              [-77.62886850763417, 0.521838558635523],
              [-77.62888359505989, 0.519652558285372],
              [-77.62686154473596, 0.514937905382851],
              [-77.62676263827841, 0.512978048780553],
              [-77.62859358120976, 0.512419143476279],
              [-77.63007349003578, 0.513928221325386],
              [-77.63119465340557, 0.516113886399467],
              [-77.63175154705307, 0.517956899271383],
              [-77.63196562086037, 0.520142564345463],
              [-77.6328425355714, 0.524622188682713],
              [-77.6330566093787, 0.526807183204482],
              [-77.63142364700055, 0.530698733214422],
              [-77.63027163822704, 0.532639646715552],
              [-77.62888359505989, 0.53389475289822],
              [-77.62566360313302, 0.537306355131818],
              [-77.62495449412371, 0.539717158125427],
              [-77.62493856850766, 0.541775921185263],
              [-77.6256106295049, 0.543853794984386],
              [-77.62742665264858, 0.54490170052037],
              [-77.62972262356854, 0.544000813566242],
              [-77.63133261953197, 0.542638083746738],
              [-77.63387250383457, 0.539442231701003],
              [-77.63536062692572, 0.538422154083605],
              [-77.63719961648412, 0.537403249932652],
              [-77.64097549623011, 0.536167589765398],
              [-77.64315764090483, 0.535040559063361],
              [-77.64302855959582, 0.536990357381853],
              [-77.6403735079435, 0.540186041789468],
              [-77.6395646542863, 0.542128128757042],
              [-77.64138050979193, 0.543636871330023],
              [-77.6437755548075, 0.543655814431247],
              [-77.6459426120565, 0.544126877390113],
              [-77.64592752463078, 0.546077681536985],
              [-77.6433866344998, 0.550077358097951],
              [-77.64176959773769, 0.551674278292467],
              [-77.63993865480634, 0.552233183596684],
              [-77.63582649310464, 0.552655296241028],
              [-77.63388055046161, 0.55309735781492],
              [-77.63283549477273, 0.554704168655121],
              [-77.6308895521297, 0.555145056762569],
              [-77.62973050255754, 0.557085802625636],
              [-77.62812050659409, 0.558683728648475],
              [-77.62606057006778, 0.559350760503776],
              [-77.62410758662608, 0.560487514213492],
              [-77.62306957173587, 0.561967590677625],
              [-77.62213850993027, 0.563683366259227],
              [-77.62246657762086, 0.565525373302762],
              [-77.62451964098656, 0.566800260776915],
              [-77.62768565345695, 0.570044057308849],
              [-77.6295165963883, 0.570397773623199],
              [-77.63089759875676, 0.568574039128578],
              [-77.63250759472021, 0.567328991229715],
              [-77.6346815251298, 0.56699622967335],
              [-77.63674162929419, 0.566211348259287],
              [-77.63835849841824, 0.564731271795154],
              [-77.64042664920967, 0.563260583062629],
              [-77.64294457292593, 0.562819527317174],
              [-77.64477551585728, 0.562143443006391],
              [-77.64720157391463, 0.559064602329101],
              [-77.64892556376135, 0.557701704871533],
              [-77.65466364704255, 0.553958179271831],
              [-77.65683757745215, 0.553516285336002],
              [-77.66059853741048, 0.554584139801534],
              [-77.66437559062291, 0.554270153708273],
              [-77.66653460124486, 0.555084036507367],
              [-77.66537454584429, 0.558063803089055],
              [-77.66398650267715, 0.559544717743449],
              [-77.6596295894025, 0.561573641227994],
              [-77.65779160567247, 0.562936538685562],
              [-77.656410603304, 0.564760273180184],
              [-77.65456356711854, 0.566465152287662],
              [-77.6527326241872, 0.567377103354033],
              [-77.65409049250287, 0.568994140116104],
              [-77.65637959026222, 0.568553084370592],
              [-77.66015664347466, 0.567318094755592],
              [-77.66221658000097, 0.567220026488371],
              [-77.66288763516982, 0.569179883090669],
              [-77.66275754803242, 0.571012837678779],
              [-77.66226150700203, 0.575374612457267],
              [-77.6617966466515, 0.577316531786778],
              [-77.65948458247742, 0.58028607244654],
              [-77.6577605926307, 0.581648969904109],
              [-77.65386149890792, 0.583795910585479],
              [-77.6519016423056, 0.585501795521338],
              [-77.6512986481906, 0.587171638273503],
              [-77.65371649198283, 0.587697686517288],
              [-77.65749354519528, 0.586353732160887],
              [-77.65910354115871, 0.585451839378379],
              [-77.6642686375383, 0.582049960152403],
              [-77.66564963990677, 0.580678010239353],
              [-77.66669452795759, 0.578619414817581],
              [-77.66881565237715, 0.570483939587916],
              [-77.669631546833, 0.568542020258406],
              [-77.67102059582854, 0.566376136475867],
              [-77.67251559208029, 0.564552401981246],
              [-77.67781060795922, 0.560229686871594],
              [-77.67986249785848, 0.560356756523845],
              [-77.68157156791754, 0.561405667888209],
              [-77.68363150444385, 0.560503775105701],
              [-77.68479155984441, 0.558679705334953],
              [-77.68549362805504, 0.556503930906615],
              [-77.68721761790178, 0.555367177196899],
              [-77.68904152003445, 0.555728940138295],
              [-77.69132961196541, 0.555053191103696],
              [-77.69604460014405, 0.551768155608102],
              [-77.69719660891757, 0.550278356136289],
              [-77.69755552201187, 0.548445401548179],
              [-77.69711262224766, 0.545916748995865],
              [-77.69735754145869, 0.544082956217437],
              [-77.69875363125288, 0.543586747549],
              [-77.69974554567558, 0.54617055302424],
              [-77.69982953234549, 0.550072496594112],
              [-77.70027159391937, 0.551915174189958],
              [-77.70185862346808, 0.553189223473794],
              [-77.70806860789848, 0.548306597231488],
              [-77.70910662278871, 0.546817803588056],
              [-77.71073958516686, 0.542934970757472],
              [-77.71302750945976, 0.54260187392498],
              [-77.71440851182824, 0.541238976467355],
              [-77.71590451390838, 0.539298398242352],
              [-77.71739951016015, 0.537934327318339],
              [-77.71923849971856, 0.537258243007557],
              [-77.7199095548874, 0.53910125587953],
              [-77.71725450323507, 0.542297107925208],
              [-77.71620961518425, 0.544120842419829],
              [-77.71688050271504, 0.545963855291745],
              [-77.71699850763038, 0.5460951422825],
              [-77.75296823498795, 0.523600689205973],
              [-77.77259900275834, 0.471870034645904],
              [-77.77838441149123, 0.458132323202853],
              [-77.77649662146783, 0.456451331902997],
              [-77.77871665234494, 0.455422369468238],
              [-77.779553338455, 0.45535665391694],
              [-77.78640712623181, 0.439082028714653],
              [-77.80340882440603, 0.408609881723998],
              [-77.80291502312265, 0.406346029945437],
              [-77.80229159323815, 0.405890350797165],
              [-77.80058252317909, 0.405194149918771],
              [-77.79863758636445, 0.405401350565455],
              [-77.79657764983813, 0.406764248023023],
              [-77.79541759443757, 0.408939854813298],
              [-77.79448653263198, 0.410764930412427],
              [-77.79367063817612, 0.412823861110326],
              [-77.79262558248723, 0.414656648060372],
              [-77.79180952039331, 0.416715411120265],
              [-77.79167960089397, 0.419353196052043],
              [-77.79040555161012, 0.42118598300209],
              [-77.78870352234972, 0.419334252950819],
              [-77.7882685015745, 0.41737339052014],
              [-77.79012257855865, 0.413950724174299],
              [-77.79117551323652, 0.410392941549105],
              [-77.79187054064849, 0.408677165967504],
              [-77.7933735835273, 0.406970275203264],
              [-77.79509757337404, 0.405607210107632],
              [-77.79785957811097, 0.402871524546697],
              [-77.79821765301496, 0.400812761486804],
              [-77.79800458503605, 0.398969580976825],
              [-77.7956315006068, 0.396077824378608],
              [-77.79460857314233, 0.394353163979588],
              [-77.79325858381563, 0.392500092823866],
              [-77.7912065262783, 0.391226211178036],
              [-77.78948957723024, 0.392011092592156],
              [-77.78810857486178, 0.393266198774825],
              [-77.78707156579995, 0.394746945791155],
              [-77.78441651414762, 0.397717827555425],
              [-77.78247057150459, 0.398619720337933],
              [-77.78041063497828, 0.399061614273762],
              [-77.77913658569443, 0.400425685197831],
              [-77.77821357051589, 0.402375315878203],
              [-77.77693952123204, 0.404543378955623],
              [-77.7746425444837, 0.405906108775127],
              [-77.77451363081273, 0.407856912922],
              [-77.77358256900715, 0.409572856141665],
              [-77.77185857916041, 0.411043712512253],
              [-77.77116355174846, 0.412759655731975],
              [-77.7701335834853, 0.414385744949527],
              [-77.76910361522214, 0.412749765086176],
              [-77.76923353472148, 0.41079879330124],
              [-77.76835662001045, 0.406427295515073],
              [-77.77029451602642, 0.40644623861624],
              [-77.77190350616148, 0.404848144955338],
              [-77.7740935298252, 0.403259271387924],
              [-77.77467355752547, 0.401308634879115],
              [-77.77606160069261, 0.400062581151928],
              [-77.77755760277277, 0.398121835288862],
              [-77.77757252256043, 0.396170863503926],
              [-77.77849553773898, 0.394454920284261],
              [-77.78067751477563, 0.394121152899515],
              [-77.78160052995418, 0.392414262135276],
              [-77.78311161946006, 0.388865364327444],
              [-77.78483560930678, 0.387962465716555],
              [-77.78712453942806, 0.387178590130873],
              [-77.78794060152198, 0.385001642236091],
              [-77.7896655971971, 0.383746871329606],
              [-77.79138154041677, 0.382961822277423],
              [-77.79322052997517, 0.381824062739327],
              [-77.79426558566406, 0.380108287157725],
              [-77.79382352409019, 0.377922454445581],
              [-77.79235065606282, 0.376765248892127],
              [-77.79017655801515, 0.376521335509494],
              [-77.78811662148884, 0.377306216923557],
              [-77.78584261115522, 0.375797474350577],
              [-77.7850565562747, 0.373493792079728],
              [-77.78392751391591, 0.371993766686046],
              [-77.78233361120658, 0.371062872518507],
              [-77.78073149423214, 0.372082614859778],
              [-77.7798006000646, 0.373798558079443],
              [-77.77909853185398, 0.37563134502949],
              [-77.77794652308047, 0.377573264359],
              [-77.77632864812804, 0.379054346651458],
              [-77.77427659059072, 0.378692248433936],
              [-77.77280456075367, 0.376839344916277],
              [-77.7735066289643, 0.375006557966231],
              [-77.77557360628929, 0.374104665183722],
              [-77.77695460865776, 0.372623582891208],
              [-77.77776362995301, 0.370907807309607],
              [-77.77973153318237, 0.367828128442],
              [-77.77917463953487, 0.366103132766909],
              [-77.77758056918749, 0.365172406237434],
              [-77.77873961875967, 0.363456463017769],
              [-77.78068556140269, 0.36255356440688],
              [-77.7825245509611, 0.361190499311192],
              [-77.7835385936082, 0.362807368435256],
              [-77.7844396482004, 0.365219177257188],
              [-77.78614050399435, 0.366729093296613],
              [-77.78738354023642, 0.368120153948951],
              [-77.78828459482862, 0.370305986661037],
              [-77.78964263078238, 0.371580874135248],
              [-77.79215954867024, 0.37182478751788],
              [-77.79409761232426, 0.371500910778877],
              [-77.79605059576596, 0.370362815964654],
              [-77.7999416428617, 0.370048829871394],
              [-77.80132264523017, 0.368567915217],
              [-77.80270364759863, 0.36686102445276],
              [-77.8035435142976, 0.36550617362218],
              [-77.80181851862248, 0.363410530188332],
              [-77.79887362075809, 0.36040176222167],
              [-77.7965925696258, 0.359578659329088],
              [-77.79546352726702, 0.357961790205024],
              [-77.79570760828771, 0.355892801223263],
              [-77.79469255981222, 0.353707136149183],
              [-77.79310653609188, 0.351855238459905],
              [-77.79380759847413, 0.349687343020605],
              [-77.79564658803254, 0.348432572114064],
              [-77.79758465168656, 0.349598327208753],
              [-77.7981266255464, 0.353500103140561],
              [-77.79914050055544, 0.355234989461451],
              [-77.8003845426259, 0.356734847217069],
              [-77.80196352554756, 0.358081651420548],
              [-77.80403854949961, 0.356537872492254],
              [-77.80268856017291, 0.354685136612602],
              [-77.80463450281593, 0.354126063670321],
              [-77.80874649687958, 0.353585263276898],
              [-77.80990554645174, 0.351879210702975],
              [-77.8117364893831, 0.35154544331823],
              [-77.8126675511887, 0.349368495423505],
              [-77.8127976383261, 0.347535540835395],
              [-77.81349149227161, 0.345701580418904],
              [-77.81499453515043, 0.343994857292728],
              [-77.81671852499716, 0.342740086386186],
              [-77.81741355240912, 0.340916016615438],
              [-77.81640655056069, 0.33895515418476],
              [-77.81778755292915, 0.337583204271709],
              [-77.81665750474198, 0.335739353209476],
              [-77.81494860232098, 0.334691615311556],
              [-77.81336257860065, 0.333299716468957],
              [-77.81405660018423, 0.331583940887356],
              [-77.81784052655728, 0.32989582558622],
              [-77.8186565886512, 0.327602201599177],
              [-77.81878650815052, 0.325768241182686],
              [-77.818458608098, 0.323582408470543],
              [-77.8174435596225, 0.321965539346479],
              [-77.81620052338043, 0.320583531149623],
              [-77.81038650005641, 0.31869357961989],
              [-77.8082125696468, 0.319370669759053],
              [-77.80831164374243, 0.321321641543932],
              [-77.80406956254139, 0.322557469349306],
              [-77.80224649859903, 0.322195371131784],
              [-77.80063650263558, 0.323559442055796],
              [-77.79970560846806, 0.325392229005843],
              [-77.79788958532437, 0.324569461389387],
              [-77.79571565491477, 0.324903396412196],
              [-77.79458661255599, 0.32328652728819],
              [-77.79391455155874, 0.32144368205428],
              [-77.79220564913774, 0.320503567793253],
              [-77.79038258519539, 0.31922968614748],
              [-77.78856656205171, 0.318415635710323],
              [-77.78627763193043, 0.318396692609156],
              [-77.78410353388276, 0.319299591220044],
              [-77.78340163331019, 0.321241510549498],
              [-77.78327154617278, 0.32330949370288],
              [-77.78186757738959, 0.327428025651045],
              [-77.7806015747328, 0.328330924261934],
              [-77.77879359821617, 0.326605258034533],
              [-77.77880063901485, 0.324654286249597],
              [-77.78007552648901, 0.32282049347117],
              [-77.78077658887126, 0.320535754301488],
              [-77.77964754651248, 0.319035561269743],
              [-77.7777096504965, 0.317752794806609],
              [-77.778266544144, 0.31665074217608],
              [-77.77943464617162, 0.316733052465338],
              [-77.78160052995418, 0.317672831450238],
              [-77.78366063411855, 0.316995741311075],
              [-77.78549962367696, 0.315975998969805],
              [-77.78778855379824, 0.315307961286123],
              [-77.79154163476757, 0.317404945824478],
              [-77.79301450279493, 0.318904635942033],
              [-77.79541759443757, 0.318923746681321],
              [-77.79723361758126, 0.319746514297776],
              [-77.79952254770254, 0.319412746913031],
              [-77.80147553114423, 0.318627865498911],
              [-77.80375658227652, 0.318646808600136],
              [-77.80570252491954, 0.318429884945715],
              [-77.80753363548895, 0.317752794806609],
              [-77.80948661893065, 0.316624758276191],
              [-77.8110966148941, 0.315144011259861],
              [-77.81544464335137, 0.314603210866437],
              [-77.81739762679307, 0.313701150445866],
              [-77.81887049482043, 0.314749055981849],
              [-77.82080855847445, 0.315680117787451],
              [-77.82250958190646, 0.316836988064779],
              [-77.82363862426524, 0.318570700919224],
              [-77.8251114922926, 0.320305587240057],
              [-77.82373048992415, 0.32247331504135],
              [-77.82234160856667, 0.324071408702252],
              [-77.82347065092546, 0.326149114863313],
              [-77.8259885746417, 0.325825070486246],
              [-77.8279415580834, 0.324462340666742],
              [-77.82949858041873, 0.324869198247256],
              [-77.82894855993183, 0.326991160857119],
              [-77.82801850395462, 0.328824953635547],
              [-77.82823157193354, 0.331127797716078],
              [-77.82971164839763, 0.332167824263081],
              [-77.82923857378198, 0.334000443575064],
              [-77.82945264758928, 0.335951582998064],
              [-77.83139758440392, 0.335853514730843],
              [-77.83222613147618, 0.335547114655312],
              [-77.83430634047257, 0.329611891322722],
              [-77.84095128509055, 0.294806473766541],
              [-77.83055883684705, 0.289901757512492],
              [-77.81741437065735, 0.282946358685013],
              [-77.85945788977092, 0.265253134777818],
              [-77.86886506340763, 0.215979925919373],
              [-77.86693551279639, 0.214081560560714],
              [-77.8650355029828, 0.21287959564421],
              [-77.86717959381708, 0.212130756413842],
              [-77.8691255364601, 0.212374837434538],
              [-77.87118564062449, 0.211589788382412],
              [-77.87270360329097, 0.207353071599414],
              [-77.87408460565945, 0.205637128379692],
              [-77.87578562909145, 0.207490199535471],
              [-77.87796056532945, 0.206704144654964],
              [-77.88002754265443, 0.205566217478804],
              [-77.88426962385547, 0.203652461344006],
              [-77.88784064988766, 0.200229459721982],
              [-77.88935056592709, 0.200084620435007],
              [-77.88999161388254, 0.202433397345033],
              [-77.88974753286185, 0.204845206166965],
              [-77.89080851416679, 0.206074160811681],
              [-77.89556859698455, 0.205687252160772],
              [-77.89786557373289, 0.204097372764977],
              [-77.89879663553849, 0.20215562107353],
              [-77.90007051718428, 0.200096690375574],
              [-77.90167950731933, 0.198506643341716],
              [-77.90315254298476, 0.199780524987489],
              [-77.90532664103243, 0.199907594639797],
              [-77.90760752452665, 0.199808520544138],
              [-77.90714249653804, 0.201759659967138],
              [-77.90861553220347, 0.202807397865058],
              [-77.90987365587128, 0.202817456148864],
              [-77.91204859210927, 0.202483353487992],
              [-77.91421564935827, 0.202962463073959],
              [-77.91650357365116, 0.20252056913813],
              [-77.91799957573132, 0.201156498214061],
              [-77.91915862530348, 0.199214578884607],
              [-77.91928854480282, 0.196567741497347],
              [-77.91999061301345, 0.194743001174402],
              [-77.92046351999106, 0.192674179830703],
              [-77.9195636388653, 0.190714155590342],
              [-77.91762557521128, 0.190244433735927],
              [-77.91638153314081, 0.18816639229874],
              [-77.91490950330378, 0.186774661094205],
              [-77.91309364779815, 0.185383600441924],
              [-77.91172756521735, 0.184000921692814],
              [-77.91036952926359, 0.182375000113325],
              [-77.90924065454288, 0.180306011131563],
              [-77.90753963111086, 0.178679083723694],
              [-77.9058305610518, 0.17809218286277],
              [-77.90377850351449, 0.177739304738793],
              [-77.9019546013818, 0.17657321436792],
              [-77.90322865066565, 0.174514618946148],
              [-77.90540358690365, 0.173728731703648],
              [-77.9053115536067, 0.171551616170859],
              [-77.90486949203282, 0.169474077647862],
              [-77.90396860507867, 0.167404921028037],
              [-77.90364053738809, 0.165445232063803],
              [-77.90434260559871, 0.163159487065684],
              [-77.90648652879493, 0.166963027092208],
              [-77.90831763936434, 0.16686395299655],
              [-77.90950049354156, 0.167777077529365],
              [-77.90897360710746, 0.170657937653459],
              [-77.90815754501354, 0.172834550272057],
              [-77.90848561270413, 0.174786527885374],
              [-77.91085852949531, 0.178599288005387],
              [-77.91290254040557, 0.1794121649761],
              [-77.91473365097498, 0.179313090880441],
              [-77.91712953418089, 0.179792032828345],
              [-77.9180295829447, 0.182095882737315],
              [-77.91985365271545, 0.182449096137475],
              [-77.92115050077595, 0.181942829185175],
              [-77.92172952264785, 0.176613279865137],
              [-77.92356163904564, 0.175357335492151],
              [-77.92549165607262, 0.176984262900021],
              [-77.92639958382543, 0.178601132024085],
              [-77.92868063495773, 0.179189206351396],
              [-77.93085456536733, 0.178746306587243],
              [-77.9349895258457, 0.176019505843669],
              [-77.93782763826354, 0.178567269135215],
              [-77.93954458731159, 0.17800735800256],
              [-77.94081159579677, 0.17640842615134],
              [-77.94128450277438, 0.174466674459893],
              [-77.9406126094152, 0.172505812029215],
              [-77.94028454172461, 0.170094003207282],
              [-77.93949865448215, 0.168369007532135],
              [-77.93721760334986, 0.168007076952733],
              [-77.93527953969583, 0.16730199125692],
              [-77.93586761402317, 0.165007361441496],
              [-77.9341585439641, 0.164076467273958],
              [-77.93141950564188, 0.163597190049927],
              [-77.92994663761452, 0.162206464673773],
              [-77.9289325949674, 0.160245602243094],
              [-77.92745251850332, 0.159197529069047],
              [-77.92596456305023, 0.160227497332187],
              [-77.92391149968452, 0.158943557402608],
              [-77.92347765237575, 0.156414737212231],
              [-77.92360656604671, 0.154463094875041],
              [-77.92224853009296, 0.152846225750977],
              [-77.9200665530563, 0.153632112993421],
              [-77.9187165637296, 0.151445274452954],
              [-77.91862453043265, 0.147534613703726],
              [-77.91807551577415, 0.144778811575179],
              [-77.91706063493672, 0.142818954972881],
              [-77.91673256724613, 0.140857924904083],
              [-77.91617550596057, 0.139133264505119],
              [-77.91435260965628, 0.13866236918426],
              [-77.91285660757613, 0.140369930500754],
              [-77.91249853267215, 0.142204058555308],
              [-77.9122316528748, 0.14690949136434],
              [-77.91017959533747, 0.146548734251326],
              [-77.90882155938371, 0.145048876495707],
              [-77.90757751731326, 0.143088014065029],
              [-77.90737953676008, 0.142039940890982],
              [-77.9079436388443, 0.139872045451682],
              [-77.90956151379673, 0.137704317650446],
              [-77.91014858229569, 0.135527369755664],
              [-77.91209452493871, 0.134967458623066],
              [-77.91381063579645, 0.134182577208946],
              [-77.91132355748391, 0.130486828457379],
              [-77.90950753434024, 0.129664899031241],
              [-77.90744759781391, 0.129655846575815],
              [-77.90538749364954, 0.130097740511644],
              [-77.9034425568349, 0.129853827129011],
              [-77.90425861892882, 0.127794058240738],
              [-77.90620456157184, 0.127578140414755],
              [-77.90792855141858, 0.126214069490686],
              [-77.90715758396378, 0.121959415434901],
              [-77.90856155274697, 0.118065853768201],
              [-77.91005654899874, 0.116240945807135],
              [-77.91153763129121, 0.117397816084519],
              [-77.91163653774878, 0.119466805066281],
              [-77.91380359499777, 0.120514542964145],
              [-77.91574853181241, 0.119611811991319],
              [-77.91758752137082, 0.118247741067307],
              [-77.91841850325241, 0.114002139466891],
              [-77.91843459650651, 0.11170717437534],
              [-77.91754963516843, 0.107687548884769],
              [-77.91745760187148, 0.105501716172625],
              [-77.91770151525411, 0.103206751081075],
              [-77.91882351681423, 0.101617039323344],
              [-77.92066150054424, 0.103685860667042],
              [-77.92053258687329, 0.105871693379186],
              [-77.9207456548522, 0.108166490832673],
              [-77.92176053568963, 0.110009336066526],
              [-77.92185960978526, 0.112078157410224],
              [-77.92287449062269, 0.114382174957257],
              [-77.92228658393341, 0.116558955213918],
              [-77.92317959189856, 0.118862637484824],
              [-77.92270651728289, 0.120931458828522],
              [-77.92121152103113, 0.122638517230826],
              [-77.91696960746815, 0.124218170704694],
              [-77.91490950330378, 0.125347213063492],
              [-77.91477958380443, 0.12741603440719],
              [-77.91578658565287, 0.129720051954223],
              [-77.9178386431902, 0.130650946121762],
              [-77.92000553280113, 0.13078572712493],
              [-77.9220735159545, 0.13022698945872],
              [-77.92414049327948, 0.1286280576075],
              [-77.9258654889546, 0.127038178211706],
              [-77.92792559311897, 0.126252290969205],
              [-77.92963449553997, 0.1272909764117],
              [-77.929160582734, 0.129124936828191],
              [-77.92835256726713, 0.130958729606618],
              [-77.92833764747947, 0.133144562318762],
              [-77.9272915859622, 0.134969637917891],
              [-77.9253386025205, 0.135990386087542],
              [-77.92543750897806, 0.138176218799629],
              [-77.92668959767558, 0.139676076555247],
              [-77.92851249397987, 0.14038116225106],
              [-77.93055751071851, 0.141889904824041],
              [-77.93145755948234, 0.143958726167739],
              [-77.93359360368954, 0.147879612838778],
              [-77.93709556283952, 0.15330002198931],
              [-77.93868259238823, 0.155378063426497],
              [-77.94072760912688, 0.156779014724577],
              [-77.94277949902614, 0.157826920260504],
              [-77.94528954375339, 0.157727846164903],
              [-77.94553362477409, 0.156012070583301],
              [-77.94509156320021, 0.154168219521011],
              [-77.93991054120457, 0.143679944067856],
              [-77.94051353531958, 0.139434007191312],
              [-77.94068955528643, 0.135748316723607],
              [-77.94411456856517, 0.13222573809179],
              [-77.94412261519223, 0.1303917776753],
              [-77.94243651154788, 0.126471226280387],
              [-77.94222260537865, 0.124637265863896],
              [-77.94235252487799, 0.122460485607178],
              [-77.94316858697191, 0.120743704197196],
              [-77.94487748939291, 0.121556581167908],
              [-77.9458925378684, 0.123517443598587],
              [-77.94689149308978, 0.127086122697904],
              [-77.94773052159842, 0.128215165056702],
              [-77.94974553112367, 0.127447215087045],
              [-77.9514155415139, 0.127293993896842],
              [-77.95159156148075, 0.125054181728217],
              [-77.95112653349214, 0.121602513997345],
              [-77.95310952414724, 0.120356460270159],
              [-77.95412457262273, 0.122542460620309],
              [-77.95516753601338, 0.124096145531067],
              [-77.95668014017693, 0.123474556221475],
              [-77.9623930562302, 0.121967336485589],
              [-77.96239305623298, 0.118357324318646],
              [-77.96078459099036, 0.097814473404136],
              [-77.9564893958944, 0.092249778649085],
              [-77.95629162987173, 0.092478753196815],
              [-77.95547456194942, 0.094303493519817],
              [-77.95365149800706, 0.094176423867566],
              [-77.9538875324007, 0.092225787358757],
              [-77.9552915011839, 0.088674877894221],
              [-77.95564253528921, 0.086841252753857],
              [-77.95645859738313, 0.084899333424346],
              [-77.95681751047744, 0.083065373007855],
              [-77.9566036043082, 0.081114568860983],
              [-77.95477249373879, 0.080978446753306],
              [-77.9510806006627, 0.085892086037404],
              [-77.9489975300836, 0.089207966936669],
              [-77.94717362795092, 0.088728857350702],
              [-77.9476475407569, 0.08644311235264],
              [-77.94939449701836, 0.082315527949106],
              [-77.94929458473241, 0.08036355033579],
              [-77.94724252719509, 0.080002457946648],
              [-77.9435575072796, 0.082847275887048],
              [-77.94162749025263, 0.08191738754789],
              [-77.94141358408339, 0.079622590094345],
              [-77.94074252891454, 0.077779744860493],
              [-77.9393844929608, 0.076387846017894],
              [-77.93917058679156, 0.074320030502577],
              [-77.93757651644417, 0.073046148856804],
              [-77.93563057380115, 0.073371199062251],
              [-77.93424957143267, 0.075087142281916],
              [-77.93241862850132, 0.075186048739454],
              [-77.9305806447713, 0.076089785540717],
              [-77.92899361522258, 0.07481607153295],
              [-77.92923752860521, 0.072404095072955],
              [-77.93016859041082, 0.070461169915063],
              [-77.93052649767674, 0.068284557296465],
              [-77.92996960402924, 0.066558723431001],
              [-77.92803154037522, 0.06516766277872],
              [-77.9270174977281, 0.06343294409595],
              [-77.92760456622706, 0.061481972311014],
              [-77.92864962191594, 0.059422035784678],
              [-77.92968763680616, 0.057941121130284],
              [-77.93164062024786, 0.057038222519338],
              [-77.93187749283182, 0.055204597378975],
              [-77.9314345930676, 0.053135776035333],
              [-77.93030555070882, 0.05140072207638],
              [-77.92860452727682, 0.049440865474082],
              [-77.92794051290664, 0.047019166006351],
              [-77.92772660673741, 0.044951350491033],
              [-77.92408751965137, 0.042854533590742],
              [-77.92295059830359, 0.04135551402544],
              [-77.92101253464956, 0.041111600642807],
              [-77.92115754157463, 0.036630802839113],
              [-77.92002849921585, 0.035130945083495],
              [-77.9181975562845, 0.035121892628069],
              [-77.9164886538635, 0.034190998460531],
              [-77.91501662402645, 0.032918122643139],
              [-77.91358952119047, 0.032954164826833],
              [-77.9123686131728, 0.035645929215093],
              [-77.91258251934204, 0.03783176192718],
              [-77.91302458091592, 0.039674607161089],
              [-77.91312449320186, 0.041851555055814],
              [-77.9125365865126, 0.044028167674469],
              [-77.91092659054915, 0.045284112047455],
              [-77.90933252020177, 0.044236374149534],
              [-77.90785965217441, 0.042736516393916],
              [-77.90592158852037, 0.041453582292661],
              [-77.90193951395608, 0.039357603582687],
              [-77.90012349081239, 0.037966878206532],
              [-77.89887961638, 0.036242050169449],
              [-77.89727749940555, 0.035599828747593],
              [-77.8949815284856, 0.037243687599869],
              [-77.89451549466861, 0.039185606929379],
              [-77.89529450875047, 0.041381665563392],
              [-77.8967665385875, 0.043224510797245],
              [-77.89607251700393, 0.045175314944117],
              [-77.89697256576774, 0.046900981171461],
              [-77.89878858891143, 0.047949054345509],
              [-77.89946752306928, 0.049791899579361],
              [-77.89899461609167, 0.051968679836079],
              [-77.89918555584619, 0.056331460442948],
              [-77.89826153483926, 0.058047236024549],
              [-77.8967665385875, 0.059068319470327],
              [-77.89595064413164, 0.061019961807517],
              [-77.8948135551458, 0.062356037174936],
              [-77.89401258047762, 0.06043205511827],
              [-77.89425649386024, 0.058481083333334],
              [-77.89554563056983, 0.054352493101362],
              [-77.89567555006917, 0.052519706151315],
              [-77.89500449490032, 0.050558843720637],
              [-77.89386757355254, 0.04894180695851],
              [-77.89343255277734, 0.046755974246423],
              [-77.89218163754627, 0.04513910512236],
              [-77.89012958000895, 0.045355190586406],
              [-77.88817559073887, 0.046259095025732],
              [-77.88656660060381, 0.047740009680126],
              [-77.88518559823534, 0.049338773893282],
              [-77.88391154895149, 0.051398710419619],
              [-77.88253054658301, 0.052988757453477],
              [-77.88130963856534, 0.05323267083611],
              [-77.87979854905946, 0.051018842567316],
              [-77.88084461057673, 0.049077090875926],
              [-77.88267555350808, 0.048055839792084],
              [-77.88383460308026, 0.045997076732192],
              [-77.88453650365282, 0.04428029532221],
              [-77.8859175060213, 0.043025356777605],
              [-77.88774861659071, 0.042356313265543],
              [-77.88924461867086, 0.040757381414267],
              [-77.88960252593678, 0.038923756273903],
              [-77.89087657522063, 0.036864993214067],
              [-77.89088462184769, 0.035030865159513],
              [-77.89112853523032, 0.033079222822323],
              [-77.89171560372928, 0.03124610059615],
              [-77.89115854244372, 0.029285573441541],
              [-77.88921360562908, 0.029619340826343],
              [-77.88738249505967, 0.030179251958941],
              [-77.885200518023, 0.031200335404719],
              [-77.8836895961552, 0.032573123508087],
              [-77.88050061727009, 0.032880236440747],
              [-77.87857060024312, 0.031146188310174],
              [-77.88029459008985, 0.030125440140523],
              [-77.88246952632784, 0.030026366044922],
              [-77.88430063689725, 0.029122293967532],
              [-77.8859175060213, 0.027072583363122],
              [-77.88627658675367, 0.024660774541189],
              [-77.8880005766004, 0.023178854058358],
              [-77.88847365121606, 0.020884894795188],
              [-77.88928954567191, 0.019168113385206],
              [-77.89059460799756, 0.018482138428681],
              [-77.89294455837401, 0.019774124985361],
              [-77.89441658821104, 0.020930157072371],
              [-77.89635465186508, 0.022094906338737],
              [-77.8974835265858, 0.023594764094355],
              [-77.8976976003931, 0.025780596806442],
              [-77.89985661101504, 0.027750511692602],
              [-77.9019085009143, 0.027876407878409],
              [-77.90418955204659, 0.029159509617671],
              [-77.90520460052208, 0.030659367373289],
              [-77.90727258367545, 0.029530492652555],
              [-77.90842459244895, 0.027470556126275],
              [-77.90889749942656, 0.025636595709784],
              [-77.91027850179503, 0.02380297056942],
              [-77.91132355748391, 0.022095912167117],
              [-77.91191062598287, 0.020144102191864],
              [-77.91285660757613, 0.015790541678484],
              [-77.91412361606132, 0.014308621195653],
              [-77.91559564589835, 0.0140196131739],
              [-77.917648541626, 0.016052392333904],
              [-77.91786160960491, 0.018238225046048],
              [-77.91785456880623, 0.020072185462539],
              [-77.91875461757006, 0.021689054586602],
              [-77.92022664740709, 0.023423773269371],
              [-77.92124152824452, 0.025040642393435],
              [-77.92352257937681, 0.026088715567482],
              [-77.92546064303085, 0.026449472680497],
              [-77.92707063899428, 0.025085737032555],
              [-77.92605559051879, 0.023578000287955],
              [-77.9244615201714, 0.022303951004119],
              [-77.92367563292895, 0.020461105770266],
              [-77.92369055271661, 0.018392284426568],
              [-77.92301949754776, 0.01666628292304],
              [-77.92326357856845, 0.014715478776168],
              [-77.92498756841518, 0.013125599380373],
              [-77.92670451746324, 0.01244867687933],
              [-77.92842850730997, 0.011083767764944],
              [-77.9291305755206, 0.009132795980008],
              [-77.93017563120948, 0.007191044288561],
              [-77.92903854222365, 0.005691018894936],
              [-77.9268795316017, 0.00452626962857],
              [-77.92482764170245, 0.003703166735988],
              [-77.92288957804841, 0.003008474600165],
              [-77.92118050798935, 0.001960401426118],
              [-77.91993763938534, 0.000225682743292],
              [-77.91995255917301, -0.002186126078641],
              [-77.92070056021308, -0.003125066873224],
              [-77.92167654901974, -0.00355019700271],
              [-77.923225524728, -0.00271921512109],
              [-77.92556759611547, -0.003630998549397],
              [-77.92534664914756, -0.005014012574634],
              [-77.92467458815032, -0.006856857808486],
              [-77.9244615201714, -0.00904269052063],
              [-77.92367563292895, -0.011346708067606],
              [-77.92220259726352, -0.013188547473078],
              [-77.92141754821138, -0.014751269502597],
              [-77.92371351913133, -0.016278116986427],
              [-77.92576557666865, -0.015808059855942],
              [-77.9287185211601, -0.017534228997476],
              [-77.93192258747094, -0.013812328708013],
              [-77.93364758314605, -0.014480198753631],
              [-77.93399861725136, -0.016314326808185],
              [-77.93469951199555, -0.018491945255221],
              [-77.93663757564958, -0.01790521203236],
              [-77.93834664570865, -0.016857306496433],
              [-77.93959052014104, -0.015357281102752],
              [-77.94107059660514, -0.014083231818859],
              [-77.94255855205823, -0.014020367545015],
              [-77.94394659522537, -0.016586235747468],
              [-77.94373352724645, -0.018546092349766],
              [-77.94396956164009, -0.020488011679276],
              [-77.94580050457144, -0.020135804107497],
              [-77.94727354023686, -0.018627061534517],
              [-77.94894455645549, -0.017760205107322],
              [-77.94993563268787, -0.018519102621497],
              [-77.9496845108685, -0.019991803010839],
              [-77.94866158340402, -0.021490654938077],
              [-77.9484485154251, -0.02333350017193],
              [-77.94892863083943, -0.025737430004881],
              [-77.95014149223005, -0.027065291107192],
              [-77.95281951029709, -0.026631443798408],
              [-77.95463553344078, -0.025122365949301],
              [-77.95645859738313, -0.024300604161226],
              [-77.95817554643119, -0.025213561055921],
              [-77.96000648936254, -0.024969647673288],
              [-77.9605635506481, -0.022783647323081],
              [-77.96216549998448, -0.023803557302415],
              [-77.96298256790679, -0.025628632901544],
              [-77.96322664892749, -0.027580275238734],
              [-77.9620976065687, -0.029432172928068],
              [-77.96061652427622, -0.030706054573841],
              [-77.95685556431789, -0.032223011411929],
              [-77.95492554729093, -0.033388766506675],
              [-77.95309460435958, -0.033632847527372],
              [-77.95069855351561, -0.033415756234945],
              [-77.94910465080628, -0.034806649249163],
              [-77.94637265328274, -0.036432570828651],
              [-77.94696055997201, -0.038609518723376],
              [-77.94846360285082, -0.040552443881268],
              [-77.95051549275009, -0.039730346817066],
              [-77.95187352870383, -0.037887501583157],
              [-77.95449857314277, -0.037526744470199],
              [-77.9564366367968, -0.037508639559292],
              [-77.95849657332312, -0.037833522126675],
              [-77.96004995495157, -0.038256079366604],
              [-77.95906391222528, -0.039143517820273],
              [-77.96396582681925, -0.047269941359235],
              [-77.960006281697, -0.051038275331151],
              [-77.95961756905484, -0.050462703290975],
              [-77.95777857949643, -0.049098632366906],
              [-77.95596356218114, -0.050372514012679],
              [-77.95540650089558, -0.05233253825304],
              [-77.955207514514, -0.056461296123075],
              [-77.95420855929262, -0.060724834996279],
              [-77.95436060701637, -0.066804397013129],
              [-77.95346055825254, -0.068647242246982],
              [-77.94945551727353, -0.067301443871884],
              [-77.94763161514085, -0.067770495174045],
              [-77.94557955760354, -0.068710274158946],
              [-77.94419855523506, -0.067111509945789],
              [-77.94543455067847, -0.064690481030311],
              [-77.94803662870267, -0.061338725585415],
              [-77.94791458819232, -0.059278956697142],
              [-77.94698352638673, -0.05720996771538],
              [-77.94582363862423, -0.055042239914144],
              [-77.94421364266078, -0.053560487069433],
              [-77.9427335661967, -0.05471635151838],
              [-77.94137553024294, -0.056568081569651],
              [-77.93914057957817, -0.063470746479084],
              [-77.93688953565926, -0.068086325286004],
              [-77.93621864812849, -0.069811320961094],
              [-77.93451661886809, -0.07154603964392],
              [-77.93188453363048, -0.071219983610092],
              [-77.93038953737873, -0.069965212703551],
              [-77.92945864321119, -0.06825731611093],
              [-77.92740658567386, -0.067923381088121],
              [-77.92579658971043, -0.066902297642343],
              [-77.92612448976296, -0.064833476298645],
              [-77.92884860829751, -0.062285880645106],
              [-77.93032851712356, -0.061129848558096],
              [-77.93145755948234, -0.059395800427524],
              [-77.93177758054587, -0.056758183133809],
              [-77.93176249312013, -0.054689361790111],
              [-77.93140458585421, -0.05239439669856],
              [-77.93035165117634, -0.048500835031859],
              [-77.93033555792222, -0.046657822159943],
              [-77.92917650835004, -0.044833081836941],
              [-77.92848164857615, -0.042882110052005],
              [-77.92743659288726, -0.04105720209094],
              [-77.92433160067206, -0.038663665817978],
              [-77.92329358578185, -0.036720740660087],
              [-77.92111160874518, -0.035248878461118],
              [-77.91893751069752, -0.034913769971809],
              [-77.9168775741712, -0.034814695876207],
              [-77.91320764168144, -0.033126077660881],
              [-77.91104159026084, -0.033251973846745],
              [-77.90898852689513, -0.034190914641329],
              [-77.91014053566863, -0.035898643595885],
              [-77.91095760359093, -0.03796763257759],
              [-77.9102855425937, -0.039801257718011],
              [-77.90846264628941, -0.040848492701684],
              [-77.90698961062398, -0.042240223906219],
              [-77.90666154293339, -0.044543067986808],
              [-77.90702062366576, -0.046377028403299],
              [-77.90760752452665, -0.048210653543663],
              [-77.90853154553358, -0.050153578701554],
              [-77.9099506017425, -0.056576128196696],
              [-77.91019451512513, -0.058517879888086],
              [-77.90952262176596, -0.060252933847039],
              [-77.90757751731326, -0.060604806142692],
              [-77.90551758078695, -0.059818918900191],
              [-77.90379359094021, -0.058455183252306],
              [-77.90149661419187, -0.057207956058619],
              [-77.89955150973917, -0.056422068816175],
              [-77.89726257961789, -0.055744308124758],
              [-77.8933566127345, -0.053711528964755],
              [-77.89199857678075, -0.055328398088818],
              [-77.89064758162567, -0.057405936611815],
              [-77.88882451768332, -0.05879716490216],
              [-77.88699357475197, -0.058580073609733],
              [-77.88505551109793, -0.059049963102211],
              [-77.88321652153952, -0.057576927436742],
              [-77.88217951247769, -0.055743302296378],
              [-77.88147761190513, -0.05390950951795],
              [-77.8803095098775, -0.050016786041624],
              [-77.88006559649487, -0.047956849515288],
              [-77.8803866233868, -0.045771016803144],
              [-77.88186653221284, -0.044722943629097],
              [-77.88369764278225, -0.044479030246464],
              [-77.88540654520325, -0.043784002834514],
              [-77.88789362351578, -0.040432415027681],
              [-77.88936649154314, -0.038924343006954],
              [-77.88994551341504, -0.037496569618725],
              [-77.88764149586802, -0.036755609377337],
              [-77.88433852309964, -0.039077564197157],
              [-77.88274361456193, -0.040007452536315],
              [-77.8806915570246, -0.040486394484162],
              [-77.8789905335926, -0.041416450461384],
              [-77.87682364398167, -0.041895224771224],
              [-77.8748704929019, -0.041335313638569],
              [-77.87371865176647, -0.039736549425413],
              [-77.87370255851235, -0.037785410002414],
              [-77.87506059446609, -0.036050858957651],
              [-77.87654150912051, -0.034894826870641],
              [-77.87709052377902, -0.033593955496599],
              [-77.87525958084767, -0.031796037263803],
              [-77.87320752331034, -0.032735983886766],
              [-77.8717115212302, -0.03113721967361],
              [-77.86965158470389, -0.030351164793046],
              [-77.86806455515517, -0.031850184358348],
              [-77.86705051250806, -0.033810711512899],
              [-77.86865950264311, -0.035174782436968],
              [-77.86935453005508, -0.037008742853459],
              [-77.86879763640758, -0.038733570890486],
              [-77.86846956871699, -0.040919403602629],
              [-77.86951462440587, -0.042979172490902],
              [-77.87010152526676, -0.045038941379119],
              [-77.87203958892079, -0.044912039364931],
              [-77.87364958488423, -0.046275775012873],
              [-77.87582351529383, -0.04695387098036],
              [-77.87664058321614, -0.048778611303362],
              [-77.87711349019374, -0.050964444015506],
              [-77.87724257150276, -0.05279857207006],
              [-77.87702950352384, -0.054867393413758],
              [-77.87658660375963, -0.056818029922567],
              [-77.8758015547075, -0.059003862634654],
              [-77.87673161068471, -0.060955002057653],
              [-77.87878450641236, -0.060819718140294],
              [-77.88084461057673, -0.061722784389246],
              [-77.88314058149669, -0.062400712718727],
              [-77.8841785963869, -0.063882633201501],
              [-77.88407851646289, -0.065950616354883],
              [-77.88329363504882, -0.067676450220347],
              [-77.88307151461446, -0.069519295454199],
              [-77.88388858253676, -0.071695908072797],
              [-77.88390350232443, -0.073873023605643],
              [-77.88506355772499, -0.07570681638407],
              [-77.88565062622395, -0.077648735713581],
              [-77.88750453557002, -0.08130726881501],
              [-77.88809160406896, -0.083259246428327],
              [-77.88788557688872, -0.087504848028686],
              [-77.88744351531484, -0.089346687434158],
              [-77.88654363418908, -0.091189532668011],
              [-77.88518559823534, -0.093041597995409],
              [-77.88371256256991, -0.094658299481409],
              [-77.88143151143763, -0.095245200342276],
              [-77.87948657462299, -0.094694341665104],
              [-77.87765462586324, -0.094820237850911],
              [-77.87606860214292, -0.096437274612981],
              [-77.87378654518224, -0.096572055616207],
              [-77.87160456814559, -0.095669324643382],
              [-77.86932351701329, -0.095452400988961],
              [-77.86830159537719, -0.096951252916199],
              [-77.86945360415069, -0.098441890578329],
              [-77.87128454708204, -0.098658981870756],
              [-77.87345864512972, -0.099326851916373],
              [-77.87597656884597, -0.09931008811003],
              [-77.87781555840436, -0.100555806561147],
              [-77.87953150162404, -0.101116723522125],
              [-77.88124057168311, -0.100529822661258],
              [-77.88271360734853, -0.098795103978432],
              [-77.88477354387484, -0.098895016264407],
              [-77.88671864832754, -0.099455095035125],
              [-77.88842755074855, -0.098641882788286],
              [-77.88875561843915, -0.096691078641413],
              [-77.89023552726518, -0.095643173105429],
              [-77.89217359091921, -0.094939428514181],
              [-77.893302633278, -0.093096583280271],
              [-77.89546952288893, -0.092961299362912],
              [-77.89787261453156, -0.093178390655339],
              [-77.90200053421125, -0.094750165140283],
              [-77.91011052845523, -0.094805150425145],
              [-77.91172052441867, -0.096061094798131],
              [-77.91093463717621, -0.097786090473278],
              [-77.90911861403254, -0.099294833046258],
              [-77.90695155678353, -0.099882069183252],
              [-77.90616650773138, -0.101724914417105],
              [-77.90514358026691, -0.10345862727155],
              [-77.90447252509806, -0.10518362294664],
              [-77.90414462504553, -0.107252444290339],
              [-77.90221360219017, -0.108869313414402],
              [-77.90027654436452, -0.10969124284054],
              [-77.89810160812652, -0.109591330554622],
              [-77.89627854418417, -0.110178231415489],
              [-77.89514950182539, -0.11202996146676],
              [-77.89482160177286, -0.113863921883251],
              [-77.89048765491293, -0.115389931176765],
              [-77.88822957019536, -0.119310314933671],
              [-77.88698552812491, -0.120809334498915],
              [-77.88654363418908, -0.122769358739333],
              [-77.88587157319185, -0.124495024966677],
              [-77.88462049032272, -0.125993876893915],
              [-77.88326262200704, -0.127267758539688],
              [-77.88373552898463, -0.129562555993175],
              [-77.88317863533713, -0.131404563036767],
              [-77.87837261968993, -0.131097617742171],
              [-77.87642650940884, -0.130193545664838],
              [-77.87425961979791, -0.131015475090976],
              [-77.87450453900892, -0.132957394420487],
              [-77.8730315033435, -0.13503526821961],
              [-77.87109360732752, -0.135974209014194],
              [-77.86892655007853, -0.135983261469619],
              [-77.86616555116997, -0.133019420504013],
              [-77.86485260985535, -0.132694537936629],
              [-77.86320456005146, -0.135223358127064],
              [-77.86356364078382, -0.137056983267428],
              [-77.86425749472933, -0.138783152408962],
              [-77.86632564552077, -0.139451022454637],
              [-77.86690550558299, -0.141402664791769],
              [-77.8656616311506, -0.143018695725516],
              [-77.86202254406456, -0.145105621980065],
              [-77.86021456754793, -0.148682180068363],
              [-77.86241951099932, -0.151635962750163],
              [-77.8604735683563, -0.152809932110017],
              [-77.85841363182998, -0.152259073432788],
              [-77.85865754521261, -0.154092866211215],
              [-77.85729950925885, -0.155826579065661],
              [-77.85547661295456, -0.157217639717942],
              [-77.85446156447907, -0.159177328682176],
              [-77.85298953464203, -0.161137185284474],
              [-77.85093663891439, -0.161498445311679],
              [-77.84715254490328, -0.159682589806096],
              [-77.84739662592398, -0.161525435039948],
              [-77.8483275200915, -0.163467186731395],
              [-77.84970064347098, -0.164722963466318],
              [-77.8517605799973, -0.165048013671765],
              [-77.8539276372463, -0.164570245190305],
              [-77.85710856950435, -0.161797343979231],
              [-77.86133556327965, -0.161418481955309],
              [-77.86305251232771, -0.162096242646726],
              [-77.86647065244584, -0.160696297177083],
              [-77.86819464229258, -0.161257381776124],
              [-77.8684385556752, -0.163434329670906],
              [-77.86981955804367, -0.164916082515617],
              [-77.87198661529267, -0.164554990126476],
              [-77.87380263843636, -0.162938121002469],
              [-77.87493151315708, -0.161321419516469],
              [-77.87687661760978, -0.161421331802387],
              [-77.88145464549041, -0.162533275078715],
              [-77.88386561612204, -0.163436173689604],
              [-77.88558155934172, -0.164223234398492],
              [-77.88707756142186, -0.165361161574651],
              [-77.88890850435321, -0.166155933634514],
              [-77.89072452749689, -0.164765208258359],
              [-77.89094564210286, -0.162805351656004],
              [-77.88876349742814, -0.161450333187418],
              [-77.88692450786974, -0.159742436594797],
              [-77.88475057746012, -0.159299369192524],
              [-77.88314058149669, -0.158269400929385],
              [-77.88186653221284, -0.156327649237937],
              [-77.88048552984436, -0.154962907761671],
              [-77.8805235836848, -0.153382080821302],
              [-77.88356755564485, -0.153562962291971],
              [-77.88711561526232, -0.155144627422658],
              [-77.88906055207696, -0.154900714040025],
              [-77.89019060026412, -0.153284012554025],
              [-77.89086165543297, -0.151098179841938],
              [-77.892341564259, -0.150051112496271],
              [-77.89323457222415, -0.147982458790636],
              [-77.89529450875047, -0.148082371076612],
              [-77.89828450125398, -0.150702051097483],
              [-77.9001156118234, -0.151261962230137],
              [-77.90206155446643, -0.151362042154119],
              [-77.90309856352826, -0.153077817735721],
              [-77.9035726439723, -0.155137921900121],
              [-77.90518163410735, -0.156276687266597],
              [-77.90633364288087, -0.157875451479754],
              [-77.9063495684969, -0.159826590902753],
              [-77.90670764340089, -0.161660216043117],
              [-77.90763049094137, -0.163485291642246],
              [-77.908332559152, -0.165662910089281],
              [-77.90982856123215, -0.167143992381739],
              [-77.91065250231506, -0.169899794510343],
              [-77.91100353642038, -0.171850431019152],
              [-77.9126205731825, -0.173558495249836],
              [-77.91194163902465, -0.175283323286862],
              [-77.91081259666586, -0.177126168520715],
              [-77.90991254790205, -0.178977898571986],
              [-77.90866850583159, -0.179782058363401],
              [-77.90647864980593, -0.178652177814286],
              [-77.90487653283148, -0.177405118258662],
              [-77.90371664506898, -0.175228338002],
              [-77.90153450039426, -0.174324433562731],
              [-77.89982559797326, -0.174911334423598],
              [-77.89858256173119, -0.176528035909598],
              [-77.8987126488686, -0.178479175332598],
              [-77.89931463715521, -0.182498633185048],
              [-77.90012349081239, -0.18478454582123],
              [-77.90013857823813, -0.186970378533317],
              [-77.90084064644876, -0.188687159943356],
              [-77.90245064241219, -0.18993405186086],
              [-77.90451057893851, -0.190955135306638],
              [-77.90612057490195, -0.192211079679623],
              [-77.9071805503785, -0.196908801137738],
              [-77.90662348909294, -0.198633461536701],
              [-77.90514358026691, -0.19967231461726],
              [-77.90320551661287, -0.199807598534619],
              [-77.90103963283032, -0.200511510763931],
              [-77.90162653369121, -0.202806140579412],
              [-77.90391563145056, -0.203014179416414],
              [-77.90585352746653, -0.202653254665336],
              [-77.90767659140889, -0.201958227253385],
              [-77.91007264225284, -0.20090244272842],
              [-77.91188849775847, -0.199511549714202],
              [-77.91405454917908, -0.199150457325061],
              [-77.91392563550812, -0.19685582750958],
              [-77.91402454196569, -0.194904688086581],
              [-77.91526757820776, -0.193061842852728],
              [-77.91582463949332, -0.191102153888494],
              [-77.91455863683652, -0.189620065767599],
              [-77.9143215966145, -0.187786440627235],
              [-77.91430650918876, -0.185835468842299],
              [-77.91474957659103, -0.183648630301832],
              [-77.9164586466501, -0.182944718072463],
              [-77.91863257705971, -0.18327077410629],
              [-77.92033360049172, -0.181996724822454],
              [-77.92215750262439, -0.181635800071376],
              [-77.92456864089408, -0.183000709185762],
              [-77.9285815608621, -0.184807512235921],
              [-77.9295956035092, -0.183082684198894],
              [-77.93084752456866, -0.181691958822739],
              [-77.93244159491604, -0.180761902845518],
              [-77.93357063727483, -0.179145033721454],
              [-77.93505054610087, -0.178096960547407],
              [-77.93594355406601, -0.17591112783532],
              [-77.93708064305184, -0.17429442634932],
              [-77.9355625127473, -0.170057541928259],
              [-77.93554658713124, -0.167762912112778],
              [-77.93667562949003, -0.165684870675591],
              [-77.93654654818101, -0.163851245535227],
              [-77.93460865216504, -0.164094991279796],
              [-77.92879462884102, -0.165973040507026],
              [-77.92684952438832, -0.166217121527723],
              [-77.92546852201984, -0.164735201044948],
              [-77.92579658971043, -0.162783055793568],
              [-77.92750549213143, -0.161618306527203],
              [-77.92829154701195, -0.159667334742267],
              [-77.92999257044396, -0.158619596844403],
              [-77.93169359387598, -0.156885548713831],
              [-77.93189258025754, -0.155313774228887],
              [-77.92723056570702, -0.155538744510352],
              [-77.92619355664519, -0.154047939210159],
              [-77.92663561821907, -0.151870991315434],
              [-77.92753549934483, -0.150019261264163],
              [-77.92511765555258, -0.147173437495383],
              [-77.92430159345867, -0.145457494275718],
              [-77.9225846444106, -0.144779398308174],
              [-77.92199657008327, -0.143062784536255],
              [-77.92290449783609, -0.141219939302403],
              [-77.923225524728, -0.139152123787085],
              [-77.92253150314443, -0.137199978535705],
              [-77.92069251358602, -0.136179230366054],
              [-77.91703062772332, -0.13517641946919],
              [-77.91497052355895, -0.134841143341873],
              [-77.91268159343767, -0.133947464824473],
              [-77.91087361692104, -0.132618597893838],
              [-77.9130016145012, -0.131417638805715],
              [-77.91505451022884, -0.130713558938339],
              [-77.91710656776617, -0.129548809671974],
              [-77.91927362501517, -0.129187717282832],
              [-77.92111160874518, -0.130894608047072],
              [-77.92328654498317, -0.131337675449345],
              [-77.92523164943587, -0.131328455355856],
              [-77.92705555156856, -0.131085547801604],
              [-77.92829858781063, -0.129233817750332],
              [-77.92839850009658, -0.126939020296845],
              [-77.9278105934073, -0.124644055205295],
              [-77.92779550598158, -0.122693251058422],
              [-77.92892454834036, -0.12095920292785],
              [-77.93030555070882, -0.12233215866928],
              [-77.93157959999269, -0.124040222899964],
              [-77.93215962769297, -0.125765050936991],
              [-77.93263253467056, -0.127941663555646],
              [-77.93395251678386, -0.136542166773893],
              [-77.9350056190998, -0.140209919968811],
              [-77.93593550743896, -0.142034827929876],
              [-77.93766050311406, -0.143290772302862],
              [-77.93972060727845, -0.144302468017088],
              [-77.94144459712517, -0.145098413543337],
              [-77.94350453365149, -0.145658492314055],
              [-77.94532759759385, -0.144728436336834],
              [-77.94861565057457, -0.142063829314907],
              [-77.9503175121969, -0.140211931625572],
              [-77.95339149137033, -0.138008161640641],
              [-77.95269059662614, -0.136057357493769],
              [-77.95142358814095, -0.134114432335878],
              [-77.94992859188919, -0.132867540418374],
              [-77.94876853648863, -0.131268608567098],
              [-77.94887548957325, -0.129425763333245],
              [-77.94953950394343, -0.126778758307978],
              [-77.95137061451284, -0.12677004112868],
              [-77.9534225044121, -0.126526965936364],
              [-77.95524556835446, -0.125478892762317],
              [-77.95580262964002, -0.123753058896909],
              [-77.95532955502435, -0.121341417712983],
              [-77.95462765445178, -0.11928131354864],
              [-77.9542695795478, -0.117447520770213],
              [-77.95265958358436, -0.116191576397227],
              [-77.95082864065301, -0.115405689154784],
              [-77.94888253037192, -0.114845945660193],
              [-77.9482265626288, -0.113516911091438],
              [-77.95034751941029, -0.11264988702618],
              [-77.9545745131856, -0.112163066089295],
              [-77.95651257683964, -0.111576165228371],
              [-77.95846556028134, -0.112135908722962],
              [-77.958938634897, -0.114421821359088],
              [-77.96069363778551, -0.120267360639104],
              [-77.96184564655901, -0.122101321055595],
              [-77.9631195282048, -0.123700252906815],
              [-77.96392854950005, -0.125867813070045],
              [-77.96441654390338, -0.129888779665066],
              [-77.96547651937993, -0.134468483926355],
              [-77.96607951349493, -0.138605958975688],
              [-77.96579754627184, -0.147098503280915],
              [-77.96489749750803, -0.148832216135361],
              [-77.9627306078971, -0.149654313199562],
              [-77.96240254020651, -0.151948943015043],
              [-77.96023565059558, -0.152310035404184],
              [-77.95979358902169, -0.154378689109762],
              [-77.96094559779519, -0.156094799967548],
              [-77.96244059404695, -0.157233565334025],
              [-77.96279850131288, -0.159528530425575],
              [-77.96166962659215, -0.161027382352813],
              [-77.96099857142332, -0.163439191174746],
              [-77.96147164603897, -0.165508012518444],
              [-77.96296664229074, -0.167215741473001],
              [-77.96195259964362, -0.168949621965453],
              [-77.96128858527345, -0.171479615622331],
              [-77.96279850131288, -0.175716164767266],
              [-77.96178462630384, -0.177559010001119],
              [-77.96350861615056, -0.178237105968662],
              [-77.96559855989028, -0.17788523367301],
              [-77.96623960784574, -0.176720149130517],
              [-77.96839861846767, -0.175672411232654],
              [-77.96953553981545, -0.174055374470527],
              [-77.96986360750604, -0.172104402685591],
              [-77.97133664317147, -0.170948538236644],
              [-77.97452562205656, -0.168970911999565],
              [-77.9759976518936, -0.167578845518904],
              [-77.97656259216816, -0.165853849843757],
              [-77.97563153036256, -0.164137068433774],
              [-77.97380058743121, -0.163576989663056],
              [-77.97253458477442, -0.162212415824854],
              [-77.97224457092427, -0.160929481723599],
              [-77.9746855487693, -0.159204318410445],
              [-77.97696659990159, -0.158617585187642],
              [-77.97891254254462, -0.158834508842006],
              [-77.98096460008193, -0.158013585244248],
              [-77.98208660164205, -0.155935711445125],
              [-77.9822995019829, -0.153522896794755],
              [-77.9810334993261, -0.151923964943535],
              [-77.97998056464823, -0.149295064829118],
              [-77.98110960700701, -0.147217526306122],
              [-77.98041558542343, -0.145383398251568],
              [-77.97824064918544, -0.144940498487358],
              [-77.97663149141232, -0.143801733120881],
              [-77.97753154017614, -0.141615900408794],
              [-77.97934756331982, -0.140343024591346],
              [-77.97967563101042, -0.138381994522604],
              [-77.97954554387302, -0.136322057996324],
              [-77.9785075289828, -0.13448843285596],
              [-77.977241526326, -0.133006344735065],
              [-77.97631063215847, -0.131407412883846],
              [-77.97595959805315, -0.130016687507691],
              [-77.9780124937808, -0.128634846948898],
              [-77.98397051820152, -0.131001728769775],
              [-77.98580950775992, -0.132601331173248],
              [-77.98736552426685, -0.133604477346239],
              [-77.98865549916675, -0.131661552188348],
              [-77.98760256448888, -0.127532961956433],
              [-77.98757959807416, -0.125004141765999],
              [-77.98883051330523, -0.123387105003928],
              [-77.99035652259877, -0.122791319325643],
              [-77.99249256680598, -0.123812067495294],
              [-77.99298056120931, -0.128644737594641],
              [-77.99356058890959, -0.130478698011132],
              [-77.99423952306744, -0.140018980214279],
              [-77.99620859976325, -0.149722206615365],
              [-77.99758155550467, -0.161981242935042],
              [-77.99839761759858, -0.165386977836476],
              [-77.99989361967873, -0.170121747306609],
              [-78.0008925749001, -0.170871592365415],
              [-78.00221255701341, -0.172597426230823],
              [-78.00427249353973, -0.173636446949445],
              [-78.0056456169192, -0.174955255596274],
              [-78.00655354467202, -0.17690723320959],
              [-78.0079875206686, -0.178055218669556],
              [-78.00981863123802, -0.181731689043829],
              [-78.01113157255264, -0.183060555974464],
              [-78.01313065646184, -0.18374753675937],
              [-78.01439649148058, -0.182202752002695],
              [-78.01433563886347, -0.180133763020933],
              [-78.01359551681239, -0.178118921133773],
              [-78.01319150907895, -0.174098457452885],
              [-78.0149076199367, -0.173358335401815],
              [-78.01673856286804, -0.173990330901802],
              [-78.01879849939435, -0.17439735612038],
              [-78.02005762889054, -0.172618380988808],
              [-78.02011864914572, -0.170666738651619],
              [-78.01937064810565, -0.168995890071074],
              [-78.01256554854923, -0.164801920994307],
              [-78.01096359921284, -0.163302063238689],
              [-78.01222256107097, -0.161929275135321],
              [-78.01434351785247, -0.161469443926705],
              [-78.01514449252065, -0.159635483510215],
              [-78.01634260176166, -0.158027666841576],
              [-78.01846355854316, -0.158542650973175],
              [-78.0197754940294, -0.160331349112482],
              [-78.02075165047411, -0.162220127175772],
              [-78.02201061233224, -0.162924207043147],
              [-78.0238955347201, -0.161362155565939],
              [-78.02469650938828, -0.159130390024359],
              [-78.02510051712171, -0.157179585877486],
              [-78.02595563888447, -0.155218723446808],
              [-78.02899156421743, -0.152871120003226],
              [-78.02996051222543, -0.151261962230137],
              [-78.03013653219227, -0.149428337089773],
              [-78.02984651834214, -0.147413495202557],
              [-78.03162364945504, -0.146447397041641],
              [-78.0335085718429, -0.14667354078955],
              [-78.03545350865754, -0.146502549964623],
              [-78.03739961893862, -0.14593341873848],
              [-78.03923056186997, -0.14639442341354],
              [-78.03837560774528, -0.148174404373549],
              [-78.0368276378654, -0.149782053404067],
              [-78.0350495009241, -0.151272188152006],
              [-78.03259259746302, -0.154424957215383],
              [-78.03179162279484, -0.156321614267654],
              [-78.0300755119371, -0.16251919348133],
              [-78.02921251118535, -0.164416185809785],
              [-78.0288695237071, -0.166539992438345],
              [-78.0288695237071, -0.16860864614398],
              [-78.02932751089703, -0.170505470834371],
              [-78.02932751089703, -0.174462232051042],
              [-78.03047164068154, -0.175899393170823],
              [-78.03361552492751, -0.177977937522201],
              [-78.03538561524175, -0.178609933022244],
              [-78.03727757842827, -0.179017125878886],
              [-78.0393375149546, -0.179243101988675],
              [-78.04139761911897, -0.179072111163748],
              [-78.04293854820018, -0.177817172619143],
              [-78.04396851646334, -0.175693198352519],
              [-78.0441435306018, -0.171438544296734],
              [-78.04471551167502, -0.169432754864943],
              [-78.04580650019335, -0.167598794448452],
              [-78.04700460943437, -0.163515298855657],
              [-78.04803457769754, -0.161971184651236],
              [-78.04931650597038, -0.161871440003381],
              [-78.05020850810713, -0.164212002648242],
              [-78.0504375017021, -0.170300617120574],
              [-78.0502626552017, -0.17254143511758],
              [-78.04952253315062, -0.174375395534071],
              [-78.04900352570552, -0.176670025349495],
              [-78.04900352570552, -0.178567017677949],
              [-78.0496296538733, -0.182190849700191],
              [-78.05043062854149, -0.183970495384074],
              [-78.0526045589511, -0.185867655350535],
              [-78.05449652213763, -0.186446341946294],
              [-78.05827357535006, -0.186501327231213],
              [-78.06015749190952, -0.185534558518043],
              [-78.0630725501986, -0.188462189661891],
              [-78.06673460369936, -0.190360020180663],
              [-78.06839757329091, -0.19145419382221],
              [-78.06932058846945, -0.192610896461474],
              [-78.06673460369936, -0.19346903570937],
              [-78.0645594998233, -0.192654985272213],
              [-78.06238556941369, -0.192536968075387],
              [-78.06061564673752, -0.192997972750447],
              [-78.05649560604682, -0.193168963575431],
              [-78.05448964897701, -0.19419792601019],
              [-78.05254353869593, -0.19476688959827],
              [-78.05088760990304, -0.193041055732863],
              [-78.04888953182223, -0.192119046382686],
              [-78.04745454999724, -0.191026213845646],
              [-78.0436245231567, -0.189471203167102],
              [-78.04167958634206, -0.188377364801681],
              [-78.03973364369902, -0.189181189316969],
              [-78.03950465010406, -0.191014982095396],
              [-78.03898664848734, -0.192912812614111],
              [-78.03687256486646, -0.192225999467269],
              [-78.03595759631497, -0.190436295499637],
              [-78.03464549319067, -0.189117319214688],
              [-78.03263853029247, -0.188484150248257],
              [-78.02903749704689, -0.190318278302811],
              [-78.02720655411554, -0.194274871881419],
              [-78.02588657200224, -0.195945888100027],
              [-78.02497059762237, -0.197951509893699],
              [-78.02616853922532, -0.1995594942004],
              [-78.02845763698467, -0.200427356455975],
              [-78.03034960017119, -0.200771349762647],
              [-78.03206654921925, -0.201521362459459],
              [-78.03337865234356, -0.203301175781405],
              [-78.03498060167993, -0.204566172609816],
              [-78.03715553791793, -0.20463004271204],
              [-78.0388106285205, -0.203772071102208],
              [-78.04064157145184, -0.203881203481671],
              [-78.04270150797815, -0.204351260612157],
              [-78.04441862466427, -0.203773076930588],
              [-78.04544859292743, -0.202282271630452],
              [-78.04750852945375, -0.201822440421779],
              [-78.04842349800523, -0.203783302852514],
              [-78.04985864746827, -0.204875967751434],
              [-78.05351265434197, -0.205970141392982],
              [-78.05717454020467, -0.207578796251937],
              [-78.05929549698615, -0.207353825970472],
              [-78.0609515934171, -0.206613033367148],
              [-78.06318654408189, -0.206441874904158],
              [-78.06495663439613, -0.206903047217281],
              [-78.06667358344419, -0.208565681532718],
              [-78.07067862442321, -0.208285725966391],
              [-78.07279153457763, -0.208521927998106],
              [-78.07456950388087, -0.208006943866508],
              [-78.0761716208553, -0.206624768031588],
              [-78.07662960804525, -0.204672958056335],
              [-78.07800256378665, -0.203011161931272],
              [-78.08023064129084, -0.202903370656315],
              [-78.08189361088239, -0.201638206189841],
              [-78.08200861059406, -0.199687402042969],
              [-78.08383955352541, -0.198594569505929],
              [-78.08652461239113, -0.198603621961354],
              [-78.0881275675559, -0.197113487213471],
              [-78.08995851048725, -0.196417789249267],
              [-78.09167462134498, -0.197574491888531],
              [-78.09064465308182, -0.201423461830302],
              [-78.09104949900558, -0.203546094992419],
              [-78.0945355325395, -0.206076926839557],
              [-78.09916653404824, -0.209754738318281],
              [-78.10134163792429, -0.210279613095622],
              [-78.10271459366571, -0.20889777253683],
              [-78.10397355552384, -0.204597185651608],
              [-78.10472055073552, -0.20293404842198],
              [-78.10597951259365, -0.201552375501251],
              [-78.10832258980949, -0.201101429109997],
              [-78.11106850129232, -0.199891417566448],
              [-78.11273163852194, -0.198915596397853],
              [-78.11450156119811, -0.198120489061864],
              [-78.11650852409632, -0.197886633963094],
              [-78.11719550488121, -0.199783458653428],
              [-78.11570754942812, -0.20122028449714],
              [-78.11479157504826, -0.202882080622203],
              [-78.11455554065462, -0.204779911140974],
              [-78.11455554065462, -0.206903047217281],
              [-78.11512752172784, -0.208682692901164],
              [-78.11633250412946, -0.210526543963397],
              [-78.11753061337048, -0.214321366810623],
              [-78.11844658775034, -0.215984168764066],
              [-78.11627165151235, -0.216498147067284],
              [-78.11403653320951, -0.216615158435729],
              [-78.11197659668319, -0.217582262425026],
              [-78.1118086233434, -0.219713948042568],
              [-78.11146563586514, -0.221547908459058],
              [-78.11025964763513, -0.223038713759252],
              [-78.11157962974843, -0.227005365621665],
              [-78.1098635188907, -0.22613750336609],
              [-78.1086575306607, -0.224763709434342],
              [-78.10659759413437, -0.224474533774526],
              [-78.10545363198793, -0.222459691887366],
              [-78.10379753555698, -0.221311706427343],
              [-78.10173759903067, -0.220786831650003],
              [-78.099563500983, -0.220615002634759],
              [-78.09761856416836, -0.220207809778117],
              [-78.09555862764203, -0.219520828993211],
              [-78.09424551868935, -0.221182792756395],
              [-78.09361251736095, -0.222899574166377],
              [-78.0916136010898, -0.223585716760965],
              [-78.0909276261333, -0.225600558648182],
              [-78.09098059976141, -0.227552200985372],
              [-78.08983663761497, -0.22916035293008],
              [-78.08811952092884, -0.227660495174462],
              [-78.08686055907071, -0.226286533604707],
              [-78.08468662866112, -0.225707344094701],
              [-78.08257254504024, -0.225535515079457],
              [-78.08079558156538, -0.226104311029474],
              [-78.07696555472484, -0.227938271445964],
              [-78.07490561819851, -0.227476260942524],
              [-78.07289865530032, -0.227413396668624],
              [-78.07106754473091, -0.227810363603396],
              [-78.06924464842662, -0.227701398861996],
              [-78.06546759521417, -0.225965506712782],
              [-78.06380462562262, -0.226833368968357],
              [-78.06426261281256, -0.231132950025255],
              [-78.06500256722558, -0.233093812455934],
              [-78.06626152908369, -0.234810929142043],
              [-78.06706250375188, -0.236708592022751],
              [-78.06763465246317, -0.238777581004513],
              [-78.06929058125606, -0.24251825675708],
              [-78.07032054951921, -0.24642204434565],
              [-78.06997655621257, -0.248255669486014],
              [-78.06820663353639, -0.248598656964305],
              [-78.06660451656195, -0.247161998758713],
              [-78.06585651552187, -0.245092171586634],
              [-78.0655745482988, -0.242915056053789],
              [-78.06488756751389, -0.240846402348154],
              [-78.0632326445494, -0.239806375801209],
              [-78.06117254038502, -0.239227521567386],
              [-78.06082955290675, -0.23727654978245],
              [-78.06076853265158, -0.235324907445261],
              [-78.05950957079347, -0.233941893420024],
              [-78.05773964811729, -0.233191880723155],
              [-78.05373360130989, -0.228077411038839],
              [-78.05224665168518, -0.226704455297408],
              [-78.05042258191443, -0.225890572498315],
              [-78.04733250948689, -0.228645368798539],
              [-78.04544054630037, -0.229042168095191],
              [-78.043838596964, -0.230650152401893],
              [-78.04423556389875, -0.232773959030453],
              [-78.04486856522715, -0.234733983270814],
              [-78.04292262258413, -0.234724763177326],
              [-78.04114549147121, -0.23506775065556],
              [-78.04000052349639, -0.236503738308954],
              [-78.03862756775497, -0.237822714593847],
              [-78.03746751235441, -0.238563674835234],
              [-78.03645363734536, -0.236557550127372],
              [-78.0373766525239, -0.234949901096797],
              [-78.03582750917757, -0.233801915636832],
              [-78.03211264968675, -0.234884689890066],
              [-78.0301055191505, -0.23568884968148],
              [-78.02833559647432, -0.236835661675002],
              [-78.0270766346162, -0.238497457800122],
              [-78.02564249098154, -0.2373494723401],
              [-78.02467354297356, -0.235795635128],
              [-78.02284260004221, -0.235966625952926],
              [-78.02158363818408, -0.237403787072765],
              [-78.01986652149796, -0.238893418906457],
              [-78.01820355190641, -0.240040566176162],
              [-78.01643362923024, -0.240555550307704],
              [-78.01448852477753, -0.240726541132631],
              [-78.01379349736557, -0.242126318964267],
              [-78.01586164815701, -0.244050971573188],
              [-78.01728858335493, -0.245145145214735],
              [-78.01837957187325, -0.246645002970354],
              [-78.01923352016956, -0.250430605724034],
              [-78.02088961660051, -0.251812446282827],
              [-78.02272055953186, -0.252219639139469],
              [-78.02684060022256, -0.252220644967849],
              [-78.02906750426028, -0.252573355453819],
              [-78.03107463479654, -0.253152544963768],
              [-78.03485152037092, -0.254761199822667],
              [-78.03971050964624, -0.25792402716985],
              [-78.04365553619847, -0.259081903275614],
              [-78.04525765317292, -0.260519064395396],
              [-78.04371655645365, -0.261665876388918],
              [-78.04154162021565, -0.262351851345443],
              [-78.03564461605012, -0.261943820298484],
              [-78.02986965239492, -0.259692944017672],
              [-78.02848060339939, -0.259710881290459],
              [-78.02735860183928, -0.260947714924157],
              [-78.02729758158411, -0.262447907955902],
              [-78.02769454851887, -0.26445352974963],
              [-78.02889249012182, -0.26646837163679],
              [-78.03301253081251, -0.270725204987457],
              [-78.03450065390366, -0.271990034177804],
              [-78.04176357301195, -0.275731883396872],
              [-78.04364748957141, -0.276771742305755],
              [-78.04497551831177, -0.277738511018924],
              [-78.04302253487008, -0.278659682178784],
              [-78.04135855945013, -0.277972701393878],
              [-78.03752853260958, -0.277049518577257],
              [-78.03553062216682, -0.276760845831632],
              [-78.03358451188573, -0.277039795569578],
              [-78.03232555002761, -0.278530600869772],
              [-78.03220350951726, -0.280662286487313],
              [-78.03231850922894, -0.282550226360286],
              [-78.03192154229419, -0.284565068247502],
              [-78.03002957910766, -0.284104231210449],
              [-78.02923564523813, -0.281980256943825],
              [-78.028945631388, -0.280137411709973],
              [-78.02820550933691, -0.278014610909793],
              [-78.02648956611725, -0.274273767519105],
              [-78.0252916245143, -0.272782962218912],
              [-78.02311652063824, -0.272836103485133],
              [-78.02236952542656, -0.270767114503371],
              [-78.0219725584918, -0.26869812552161],
              [-78.02111861019549, -0.266800462640902],
              [-78.02008864193232, -0.265309489702702],
              [-78.018539498586, -0.264270636622143],
              [-78.016364562348, -0.263755820128608],
              [-78.01425165219358, -0.263862605575184],
              [-78.0121915480292, -0.264955605750288],
              [-78.01029958484267, -0.265235393678552],
              [-78.00875865576147, -0.264141555313131],
              [-78.00607259106735, -0.261042765706293],
              [-78.00592054334362, -0.259434110847337],
              [-78.00519550871826, -0.258088144834176],
              [-78.00418062788083, -0.257419268960177],
              [-78.00235756393847, -0.257590259785104],
              [-78.00053349416774, -0.25807808655037],
              [-77.99716950114417, -0.25841990056216],
              [-77.9968875339211, -0.259459088918845],
              [-77.99734451528263, -0.260398867903746],
              [-78.00005354639147, -0.261917836398595],
              [-78.00263953116156, -0.263968552831443],
              [-78.0036086468076, -0.265866550988221],
              [-78.00429562759251, -0.267872340420013],
              [-78.00549356919547, -0.269372365813638],
              [-78.00692754519206, -0.270519010169153],
              [-78.00881162938958, -0.271731201007469],
              [-78.00932359603601, -0.273148916111893],
              [-78.00732451212681, -0.274593788582649],
              [-78.00634751749176, -0.276490780911047],
              [-78.00681254548037, -0.27798158621124],
              [-78.00601157081218, -0.278830673003711],
              [-78.0044636009323, -0.278667393529702],
              [-78.00292149838465, -0.277465596251261],
              [-78.00160956289841, -0.276887747845819],
              [-77.99641462694349, -0.276253740689071],
              [-77.99560560564825, -0.276894788644483],
              [-77.99639149289071, -0.277906484358709],
              [-77.99989361967873, -0.278106476568666],
              [-78.0032576127023, -0.279643549974423],
              [-78.00415749382805, -0.280546448585312],
              [-78.00307455193678, -0.281260419098487],
              [-78.00028253998643, -0.280563380029719],
              [-77.99604062642345, -0.280635296759044],
              [-77.99559051822251, -0.281890235303649],
              [-77.99709356110134, -0.283228154689766],
              [-77.9981765029926, -0.283454130799612],
              [-78.00116750132452, -0.283102258503959],
              [-78.00251749065121, -0.283717322559539],
              [-78.00526457560049, -0.286193001483753],
              [-78.00530950260155, -0.287494040495858],
              [-78.00392950606147, -0.288135926641644],
              [-78.00022856052993, -0.287908944703418],
              [-77.99807759653505, -0.288558709838185],
              [-77.99785664956714, -0.289714574287132],
              [-77.99989361967873, -0.290781758200467],
              [-78.00106859486696, -0.290944534760285],
              [-78.0022965436833, -0.291459518891827],
              [-78.00310556497855, -0.292245406134327],
              [-78.00208263751406, -0.292923334463751],
              [-78.00032763462556, -0.293383500948551],
              [-77.99853558372499, -0.29489123769315],
              [-77.9981765029926, -0.296219098795405],
              [-77.99822260346012, -0.298731155179439],
              [-77.99862661119356, -0.299743018531728],
              [-77.99989361967873, -0.300745997066599],
              [-78.00376857352035, -0.301586701955898],
              [-78.00576748979148, -0.30164168724076],
              [-78.00782759395587, -0.301416716959352],
              [-78.00937656966413, -0.300440895790757],
              [-78.01235164238, -0.297976113340667],
              [-78.01389357728958, -0.29694631271559],
              [-78.01531950665911, -0.296359076578597],
              [-78.01638752876272, -0.296920328815702],
              [-78.01624252183765, -0.298266127190857],
              [-78.01480854584106, -0.299584935837686],
              [-78.01360356343945, -0.301137934859469],
              [-78.01251961571978, -0.302745583890044],
              [-78.0117726205081, -0.304407715291234],
              [-78.01142862720145, -0.306594386193694],
              [-78.01154362691312, -0.308654155081967],
              [-78.01280258877125, -0.310271191844095],
              [-78.01502949280898, -0.31141917730406],
              [-78.01663261561181, -0.312458868574879],
              [-78.01818058549169, -0.313488836838076],
              [-78.01983651428458, -0.314871683225249],
              [-78.02120963766406, -0.316597852366783],
              [-78.02140057741856, -0.31762765299186],
              [-78.0208666501858, -0.318549494703973],
              [-78.01702857671818, -0.317048631119974],
              [-78.01605258791153, -0.318376492222285],
              [-78.01371755732274, -0.318194604923178],
              [-78.01200060827468, -0.31721861611652],
              [-78.01093258617107, -0.318501550217775],
              [-78.00946055633403, -0.318446397294792],
              [-78.00879654196385, -0.316178757207638],
              [-78.00691262540438, -0.312555931013776],
              [-78.00591249671656, -0.311524956922199],
              [-78.00307455193678, -0.311226225892767],
              [-78.00096164178234, -0.311687230567827],
              [-77.99989361967873, -0.31150500799265],
              [-77.99899256508652, -0.310737225661057],
              [-77.99788648914247, -0.309337280191414],
              [-77.9966655811248, -0.310167256244597],
              [-77.99588053207266, -0.31201010147845],
              [-77.99288953374075, -0.309959049769532],
              [-77.99230162705148, -0.307898275052878],
              [-77.99126461798964, -0.306299343201658],
              [-77.98919663483626, -0.304826475174309],
              [-77.98690753707692, -0.30449153432312],
              [-77.98666362369428, -0.302313915876084],
              [-77.98619859570569, -0.300479955459593],
              [-77.98162861445212, -0.300975828851904],
              [-77.97945351057606, -0.300071924412634],
              [-77.97750857376143, -0.300658825273501],
              [-77.97672251888089, -0.302726640788819],
              [-77.9748996225766, -0.303548737853021],
              [-77.9728465592109, -0.303674634038828],
              [-77.97217550404204, -0.3054086821694],
              [-77.97355650641052, -0.30665557408696],
              [-77.97607459776484, -0.307560316716547],
              [-77.98019463845553, -0.307985446845976],
              [-77.98213957527017, -0.309124212212453],
              [-77.98535956719704, -0.312088891368376],
              [-77.98606850856828, -0.314374804004501],
              [-77.98550457412212, -0.316099632041585],
              [-77.98321564400084, -0.315999719755609],
              [-77.98149852731471, -0.315330676243548],
              [-77.97956063129874, -0.315338722870592],
              [-77.97750052713437, -0.315934843825005],
              [-77.97567763083008, -0.316168698923832],
              [-77.97248865194497, -0.318264677633806],
              [-77.97031354806892, -0.317820772041216],
              [-77.96813961765932, -0.316799520957375],
              [-77.96664462140755, -0.315669808046323],
              [-77.96505759185884, -0.316942683863772],
              [-77.96323352208809, -0.317637543637659],
              [-77.96105959167849, -0.317312661070275],
              [-77.9593275552047, -0.315369903550447],
              [-77.96098365163564, -0.311810109268492],
              [-77.95906855439634, -0.310437321165182],
              [-77.95711557095464, -0.309650260456237],
              [-77.95732863893356, -0.307346410547325],
              [-77.95959460264011, -0.304004713386234],
              [-77.95877049391913, -0.302658747373016],
              [-77.95616154273432, -0.303687709807832],
              [-77.95445264031332, -0.304617765785054],
              [-77.95250653003224, -0.303822658449064],
              [-77.95157664169308, -0.301418728616113],
              [-77.95190454174562, -0.29912510462907],
              [-77.95427662034648, -0.295999157655899],
              [-77.95220964302149, -0.29452628962855],
              [-77.95015758548416, -0.295113190489417],
              [-77.94786865536288, -0.294904481100161],
              [-77.94557955760354, -0.294118426219654],
              [-77.94453450191465, -0.291479467821432],
              [-77.94532055679517, -0.289411819944178],
              [-77.9446186562226, -0.287342998600479],
              [-77.94277949902614, -0.286213118051364],
              [-77.94095660272184, -0.286220997040402],
              [-77.93901853906782, -0.286926082736159],
              [-77.93708064305184, -0.288207843370913],
              [-77.93514257939782, -0.288902703144799],
              [-77.9330826428715, -0.288459803380647],
              [-77.93102253870713, -0.287329755193468],
              [-77.93000061707102, -0.288946791955595],
              [-77.93161765383313, -0.290771532278541],
              [-77.93164849923684, -0.295361127185572],
              [-77.93109160558936, -0.29788994737595],
              [-77.93125153230208, -0.298946905367359],
              [-77.93260152162878, -0.300871893252463],
              [-77.93454763190987, -0.301314960654679],
              [-77.93648552792584, -0.300376019860096],
              [-77.93681359561643, -0.298307030878391],
              [-77.93771364438025, -0.296699214209752],
              [-77.93930855291795, -0.29553412966726],
              [-77.94124561074361, -0.295183263200045],
              [-77.94319155338663, -0.296087335277377],
              [-77.94491554323335, -0.297334227194938],
              [-77.94629654560183, -0.298933159046157],
              [-77.94723565403449, -0.30145393260949],
              [-77.94725057382215, -0.303630545228089],
              [-77.94794459540572, -0.30546551147296],
              [-77.95059964705804, -0.308663207537393],
              [-77.95243863661645, -0.310372109958394],
              [-77.95315562461475, -0.314499694361928],
              [-77.95407863979328, -0.316560636716645],
              [-77.95420855929262, -0.318511608501581],
              [-77.95502462138654, -0.320345568918071],
              [-77.95697056402958, -0.321249305719277],
              [-77.95925865596053, -0.321458350384717],
              [-77.96063949069094, -0.323175299432762],
              [-77.96145655861325, -0.325000039755764],
              [-77.96524065262436, -0.327620725605016],
              [-77.96717854864032, -0.326916813375703],
              [-77.96808664403119, -0.325183100521258],
              [-77.96990149370843, -0.323566231397194],
              [-77.97229754455239, -0.323549132314724],
              [-77.97367854692087, -0.325031220435562],
              [-77.97289249204034, -0.326990909399797],
              [-77.97187761120291, -0.328598893706499],
              [-77.97109256215077, -0.330676767505622],
              [-77.96927653900708, -0.332292630801305],
              [-77.96849065176463, -0.334478463513392],
              [-77.96975749261175, -0.335852425083203],
              [-77.9720455845427, -0.336295324847413],
              [-77.97387652747405, -0.336278225764943],
              [-77.97559364416017, -0.33684802754334],
              [-77.97788961508013, -0.338086202281545],
              [-77.98005650469106, -0.337733994709822],
              [-77.98175853395145, -0.336569245443457],
              [-77.9836955917771, -0.335639357104299],
              [-77.98552653470846, -0.335161420984775],
              [-77.98815157914738, -0.33502630470548],
              [-77.98998252207873, -0.335244401826287],
              [-77.99169963876486, -0.336265317634002],
              [-77.99275257344273, -0.33866907982889],
              [-77.99253849963543, -0.340972762099796],
              [-77.99255358706115, -0.343384738559791],
              [-77.99211152548727, -0.345335542706664],
              [-77.9902876233546, -0.346265431045822],
              [-77.98857151249686, -0.345705519913167],
              [-77.98684651682174, -0.344801615473898],
              [-77.98490862080578, -0.34550552770321],
              [-77.98423756563693, -0.347230523378357],
              [-77.98506150671984, -0.351242102241827],
              [-77.98433663973256, -0.352740954169064],
              [-77.98153658115515, -0.352876070448417],
              [-77.97752349354909, -0.352097894556891],
              [-77.9737476138031, -0.350868269359864],
              [-77.97145063705476, -0.349503192607472],
              [-77.96949765361306, -0.347912307383297],
              [-77.96744559607573, -0.348038371207167],
              [-77.96549261263404, -0.347134466767898],
              [-77.96354650235295, -0.346004586218783],
              [-77.96206659352693, -0.347386426777575],
              [-77.96150953224137, -0.349581144307081],
              [-77.9591066082368, -0.349246371093898],
              [-77.95704650407241, -0.348694171312218],
              [-77.95463553344078, -0.347446273566277],
              [-77.95359751855057, -0.345269660947679],
              [-77.95391854544249, -0.342974695856071],
              [-77.95368150522046, -0.340789030782048],
              [-77.9514995281838, -0.340344957551395],
              [-77.9515156214379, -0.342296767526648],
              [-77.95015758548416, -0.344147659387545],
              [-77.94820359621409, -0.342783588463533],
              [-77.94739558074721, -0.340840830943705],
              [-77.94623552534665, -0.339006870527214],
              [-77.9454196308908, -0.336712240711734],
              [-77.94348156723677, -0.337072159634431],
              [-77.94292450595121, -0.339492853273782],
              [-77.94087261605195, -0.339501905729207],
              [-77.93903362649354, -0.338489036548538],
              [-77.93708751621246, -0.338498089003963],
              [-77.93537157299278, -0.337476167367868],
              [-77.93512749197208, -0.335299219473143],
              [-77.93328850241369, -0.333817466628375],
              [-77.93179350616192, -0.332335378507537],
              [-77.93212157385251, -0.330266724801902],
              [-77.93279262902136, -0.328080892089758],
              [-77.9322055605224, -0.326021123201542],
              [-77.9301524971567, -0.326608024062409],
              [-77.92913862214766, -0.328675671939664],
              [-77.92846656115042, -0.331096700855085],
              [-77.9272615787488, -0.332297659943208],
              [-77.92469755456504, -0.330779697276739],
              [-77.92274457112335, -0.329296603327464],
              [-77.9220735159545, -0.331374477126587],
              [-77.92185256898658, -0.333325616549587],
              [-77.92312661827044, -0.335611361547649],
              [-77.92485060811717, -0.337094120220797],
              [-77.92510960892552, -0.341339721821157],
              [-77.92694055185687, -0.341556813113584],
              [-77.92864962191594, -0.34062675713642],
              [-77.93035852433694, -0.340039856275496],
              [-77.93310560928623, -0.340367085775767],
              [-77.93482959913295, -0.341848670982472],
              [-77.93518851222726, -0.343790590311926],
              [-77.9353105527376, -0.345859579293688],
              [-77.93578362735327, -0.347928232999323],
              [-77.93704963001007, -0.349410153482097],
              [-77.93887353214275, -0.347902416737497],
              [-77.93954458731159, -0.345716584025411],
              [-77.94113161686032, -0.344668510851363],
              [-77.94319155338663, -0.345003619340616],
              [-77.94503054294503, -0.346477493196346],
              [-77.9470906471094, -0.347037571967064],
              [-77.94927262414608, -0.348520163002149],
              [-77.95021860573934, -0.353100034901445],
              [-77.95206463609641, -0.355043798249653],
              [-77.9546885070689, -0.355025693338746],
              [-77.95663461734999, -0.355577893120483],
              [-77.95745051180585, -0.357755679205582],
              [-77.95713753154098, -0.362127176991748],
              [-77.95783959975161, -0.36407010214964],
              [-77.9600145359896, -0.364856995220464],
              [-77.96184564655901, -0.364613081837831],
              [-77.96742262966102, -0.361932213923694],
              [-77.96913153208202, -0.360433361996456],
              [-77.97299961276302, -0.358681711869224],
              [-77.97540253676759, -0.358321625308463],
              [-77.97666954525278, -0.360029521901083],
              [-77.97554050289399, -0.362106222233763],
              [-77.97475461565153, -0.364292054945906],
              [-77.9740825546543, -0.366595904854876],
              [-77.97421264179171, -0.368429697633303],
              [-77.97308359943293, -0.369928549560541],
              [-77.97229754455239, -0.372232399469453],
              [-77.9724276316898, -0.374183538892453],
              [-77.97083255551402, -0.37569211382737],
              [-77.96900949157167, -0.376052200388131],
              [-77.96694955504536, -0.375952120464149],
              [-77.96500361240233, -0.375274192134668],
              [-77.96055550402104, -0.376456375759631],
              [-77.95587152888419, -0.376491076838818],
              [-77.95369759847458, -0.376960128140979],
              [-77.95428449933546, -0.379019897029195],
              [-77.95658164372186, -0.380266788946756],
              [-77.95875557413147, -0.380366868870738],
              [-77.96149461245369, -0.379772089020889],
              [-77.96342462948067, -0.378960050240494],
              [-77.96584364673936, -0.380667779195051],
              [-77.96835352382855, -0.380297801988547],
              [-77.97052762187622, -0.381202712256197],
              [-77.97257951177548, -0.380615811395273],
              [-77.97405958823957, -0.378882098540885],
              [-77.9748456431201, -0.377157102865738],
              [-77.97620351143578, -0.375883221219965],
              [-77.97814157508982, -0.375179308990653],
              [-77.97997251802117, -0.374936401436401],
              [-77.9816896347073, -0.375614162127761],
              [-77.98387161174395, -0.376744042676876],
              [-77.98593154827027, -0.37696213979774],
              [-77.98798360580759, -0.376719064605425],
              [-77.99132563824482, -0.375672332535885],
              [-77.99047856310912, -0.373711470105206],
              [-77.98888365457141, -0.372635569012573],
              [-77.98828149864673, -0.371569558565739],
              [-77.98805250505175, -0.370168607267715],
              [-77.98694659674575, -0.368424836129464],
              [-77.99123360494787, -0.366935874847968],
              [-77.99266054014578, -0.367631908088299],
              [-77.99487252439583, -0.368337664336366],
              [-77.99610851983924, -0.367099992512294],
              [-77.99601749237067, -0.36540215420348],
              [-77.99554458539308, -0.363224368118438],
              [-77.99437748919384, -0.359447650182119],
              [-77.99521651770247, -0.358707695769112],
              [-77.99647564719866, -0.358969378786469],
              [-77.996963641602, -0.360379550178038],
              [-77.9975126562605, -0.362909376196797],
              [-77.9979555560247, -0.364056188190318],
              [-77.99861957039488, -0.364905945535043],
              [-78.00213661697057, -0.366587020037514],
              [-78.00367754605178, -0.368141695439931],
              [-78.00653862488434, -0.369470897646693],
              [-78.00871255529395, -0.368323918015108],
              [-78.00957455021732, -0.366598922340017],
              [-78.0111764995537, -0.364422142083299],
              [-78.01317558346291, -0.364305298352974],
              [-78.01409960446983, -0.362462285481001],
              [-78.01083351207544, -0.360230519939421],
              [-78.00723264646793, -0.358440648333669],
              [-78.00363161322234, -0.357119828030079],
              [-78.00082350801787, -0.355565655541852],
              [-78.00242663082071, -0.354354805807986],
              [-78.00448656734703, -0.354825701128846],
              [-78.00872060192101, -0.355115714978979],
              [-78.01323660371808, -0.356156747354305],
              [-78.01696051566434, -0.356789581044666],
              [-78.0173035031426, -0.354612968426011],
              [-78.01512957273299, -0.350240129535337],
              [-78.01484659968152, -0.348288487198147],
              [-78.01667754261287, -0.348234340103602],
              [-78.01964557453006, -0.35099114806053],
              [-78.02227749212959, -0.353981978754405],
              [-78.024108602699, -0.354849673371916],
              [-78.02651253253197, -0.354850679200297],
              [-78.02862561032447, -0.354507859360126],
              [-78.03051757351099, -0.353767904947119],
              [-78.0352095952749, -0.350960135018795],
              [-78.03813152672458, -0.34964233220029],
              [-78.0408705650468, -0.348667181583949],
              [-78.04281650768984, -0.348731386962356],
              [-78.04264853435004, -0.350682023471165],
              [-78.0408705650468, -0.352343987234349],
              [-78.03686552406779, -0.355323921454044],
              [-78.0338896131616, -0.356416586353021],
              [-78.03143354789086, -0.357616539612707],
              [-78.02942658499265, -0.359793487507488],
              [-78.0275956420613, -0.361347324719588],
              [-78.02547451764175, -0.363921071911022],
              [-78.0244445493786, -0.366621888754707],
              [-78.0243305554953, -0.369783710273509],
              [-78.0249635568237, -0.372024695908578],
              [-78.02673364713795, -0.374094355442594],
              [-78.0288695237071, -0.374122350999244],
              [-78.0314255012638, -0.375532354752693],
              [-78.03216562331488, -0.377484164727946],
              [-78.03430954651108, -0.379092987224965],
              [-78.03536952198763, -0.379156186774935],
              [-78.03679662482362, -0.377666219665116],
              [-78.03697163896209, -0.373013425208114],
              [-78.03811660693692, -0.370782497856851],
              [-78.03909259574357, -0.370493657473162],
              [-78.04332763614595, -0.372220664805013],
              [-78.04550156655554, -0.371588501666963],
              [-78.04715749534843, -0.368951722563565],
              [-78.04973560112954, -0.367235779343901],
              [-78.05236852455747, -0.366549804387375],
              [-78.05368062768177, -0.365114152010108],
              [-78.05648755941976, -0.363281197421998],
              [-78.05820450846782, -0.359026543366213],
              [-78.05998264540912, -0.356794777824632],
              [-78.06072259982214, -0.354436780821118],
              [-78.06330154379356, -0.35593781204318],
              [-78.0650716341078, -0.355477813196501],
              [-78.0658186293195, -0.35358098850611],
              [-78.06668062424285, -0.345377452222579],
              [-78.06496451338512, -0.342612597638549],
              [-78.06394158592063, -0.340596749923009],
              [-78.06673460369936, -0.337336021946669],
              [-78.06894658794941, -0.336280069783641],
              [-78.06874056076917, -0.333316396456098],
              [-78.06674164449802, -0.332394554743985],
              [-78.0636515720705, -0.330604683138233],
              [-78.06142450039471, -0.330314836926163],
              [-78.05982255105832, -0.328597887878118],
              [-78.05936456386839, -0.326583045990901],
              [-78.05730462734208, -0.325029041140738],
              [-78.05444354850951, -0.323817017940428],
              [-78.05175765145346, -0.322154215986984],
              [-78.05272659946145, -0.320600546412891],
              [-78.05381758797978, -0.318305413683277],
              [-78.05301661331158, -0.316353771346087],
              [-78.05239065278187, -0.312441937130473],
              [-78.05445058930817, -0.309804319836758],
              [-78.05585455809137, -0.308332457637789],
              [-78.05725064788557, -0.311422194789202],
              [-78.05725064788557, -0.313310972852491],
              [-78.05821959589356, -0.316644791024601],
              [-78.05993654494162, -0.319120637586877],
              [-78.06141662140571, -0.320178601406667],
              [-78.06303349052976, -0.320856362098084],
              [-78.06459051286508, -0.321272272134024],
              [-78.06587260877599, -0.321272272134024],
              [-78.06897760099119, -0.319936532042732],
              [-78.07057955032758, -0.317117698002221],
              [-78.07138052499577, -0.315338722870592],
              [-78.07418863020023, -0.313505097730228],
              [-78.07607254675969, -0.310812998065842],
              [-78.07682054779976, -0.308627332991762],
              [-78.0791096455591, -0.306107397618746],
              [-78.0792844920595, -0.303866747259804],
              [-78.08013961382227, -0.302204615858557],
              [-78.08151256956369, -0.300993933762811],
              [-78.08311451890006, -0.300028003239959],
              [-78.08574660413767, -0.296297218133077],
              [-78.08574660413767, -0.294336523340462],
              [-78.0867235987727, -0.292791570945724],
              [-78.0883785217372, -0.292051448894654],
              [-78.09067549848554, -0.290272641401089],
              [-78.09152961441991, -0.288428957976919],
              [-78.0923305890881, -0.285331174198461],
              [-78.09422255227463, -0.28729203662914],
              [-78.0993115409733, -0.286199874644353],
              [-78.10211964617775, -0.285803075347644],
              [-78.10583450566857, -0.285812965993387],
              [-78.11026752662413, -0.284413020523743],
              [-78.11167149540732, -0.286789792990362],
              [-78.11069450077228, -0.289319619009177],
              [-78.10812360342791, -0.290403566728799],
              [-78.10508751045688, -0.289543583462205],
              [-78.10354658137568, -0.29079952783519],
              [-78.10245559285734, -0.294594183044296],
              [-78.1001125156415, -0.295162140803995],
              [-78.09890753323988, -0.297114286055375],
              [-78.09655758286343, -0.29975089752071],
              [-78.09381049791413, -0.300329081202278],
              [-78.09232354828943, -0.302794701842686],
              [-78.09163656750454, -0.305776647719142],
              [-78.0912325597711, -0.314620729043895],
              [-78.09054557898621, -0.318234670420395],
              [-78.08940161683975, -0.321568320954441],
              [-78.08876760968298, -0.32473098066356],
              [-78.08940161683975, -0.327540929886709],
              [-78.09145350673901, -0.328119784120531],
              [-78.09277365649038, -0.3267468283791],
              [-78.09448959971004, -0.325428187370335],
              [-78.09638256872495, -0.324335019557168],
              [-78.1003265894488, -0.321183423960292],
              [-78.10307350676001, -0.319232452175356],
              [-78.10462164427796, -0.321130450332134],
              [-78.10324064190948, -0.325376051932551],
              [-78.10198251824168, -0.327562890473075],
              [-78.09877761374054, -0.331057473548185],
              [-78.09603153461964, -0.335249263330127],
              [-78.09442857945487, -0.3373090322184],
              [-78.09408559197662, -0.339269056458761],
              [-78.09619850213105, -0.341049875609087],
              [-78.0983196265506, -0.341339721821157],
              [-78.10043354253342, -0.341972723149581],
              [-78.10174564565773, -0.344096697416205],
              [-78.10060151587321, -0.347429509759934],
              [-78.09899856070844, -0.349607295844976],
              [-78.09905254016495, -0.352191101320273],
              [-78.1000825084281, -0.353979967097644],
              [-78.10174564565773, -0.355299949210917],
              [-78.10385855581215, -0.355417631131616],
              [-78.10580449845519, -0.354731823813154],
              [-78.10929857861615, -0.352438032188047],
              [-78.11061051410239, -0.350830047881345],
              [-78.11186964359858, -0.348942275646436],
              [-78.11392958012489, -0.348021439762704],
              [-78.11582154331141, -0.348600293996583],
              [-78.11776765359251, -0.348600293996583],
              [-78.11907958907875, -0.345791518239878],
              [-78.12171955330534, -0.342746875727585],
              [-78.12297063617447, -0.34441873013651],
              [-78.12319962976945, -0.347003373802067],
              [-78.12262764869621, -0.349695138190327],
              [-78.12153649253982, -0.352856959709129],
              [-78.12016253097002, -0.355666908932278],
              [-78.11947655601351, -0.358196734951036],
              [-78.11993454320344, -0.360148377288226],
              [-78.12251264898454, -0.356534603549846],
              [-78.12400060443763, -0.355270780187823],
              [-78.12531253992387, -0.353779974887686],
              [-78.12794462516148, -0.352697032996389],
              [-78.12857863231825, -0.350049357418868],
              [-78.13042449503727, -0.349752135132007],
              [-78.13240849152075, -0.351776197112713],
              [-78.13304149284915, -0.354884877365294],
              [-78.13275164663708, -0.357522494659008],
              [-78.13183550461913, -0.360052320677767],
              [-78.1313785232576, -0.36217528911601],
              [-78.13429257571828, -0.367118935613519],
              [-78.13543653786472, -0.365392934109991],
              [-78.1368715196897, -0.363849155181697],
              [-78.13790165559092, -0.362187359056577],
              [-78.13813064918588, -0.360289360899799],
              [-78.14001456574536, -0.358392703847471],
              [-78.14122055397536, -0.355808730734168],
              [-78.14344762565115, -0.35547580153974],
              [-78.14385263921297, -0.353343110093761],
              [-78.14334050492846, -0.350533160870611],
              [-78.14231154249369, -0.347370333523429],
              [-78.14196754918704, -0.345246694532932],
              [-78.1416245617088, -0.339735090275781],
              [-78.14048764036102, -0.335307266100187],
              [-78.14008363262758, -0.330943647303002],
              [-78.1386565297916, -0.325423158228432],
              [-78.1379695490067, -0.322097386683367],
              [-78.13809158951705, -0.319395564011302],
              [-78.13991549164973, -0.320200561993033],
              [-78.14151760862417, -0.321809216851932],
              [-78.1432955779274, -0.322614214833663],
              [-78.14592749552695, -0.322389412190262],
              [-78.14775860609636, -0.322452108826099],
              [-78.1489025682428, -0.324233095614488],
              [-78.14815557303112, -0.326418928326575],
              [-78.14878052773246, -0.328714899246563],
              [-78.15216061401014, -0.329980566627228],
              [-78.1517026268202, -0.332338395992679],
              [-78.14929953517756, -0.333196367602511],
              [-78.14974964337851, -0.336638312325704],
              [-78.15112259911992, -0.338364984381428],
              [-78.15146659242656, -0.340894810400187],
              [-78.15300752150777, -0.34433776095176],
              [-78.15455649721603, -0.345892436354177],
              [-78.15421250390939, -0.348133254351183],
              [-78.1534655086977, -0.350888218289469],
              [-78.15477761182201, -0.353193074026763],
              [-78.15534959289523, -0.356291695995594],
              [-78.15500660541697, -0.358650531189426],
              [-78.15477761182201, -0.362725141964859],
              [-78.15334363582542, -0.36720577213049],
              [-78.1537395969318, -0.369555722506959],
              [-78.15528857264006, -0.370531711313618],
              [-78.15826465118431, -0.369963753553918],
              [-78.15975160080902, -0.36887980583424],
              [-78.1620405309303, -0.368365827531022],
              [-78.16678653215071, -0.367905996322406],
              [-78.17193553527618, -0.36716704773778],
              [-78.17394249817438, -0.367113906471616],
              [-78.17668958312366, -0.367294955580348],
              [-78.17852052605501, -0.366662792442241],
              [-78.18051960996421, -0.367006785748913],
              [-78.18177756599395, -0.369139644832899],
              [-78.18349451504201, -0.371381636296348],
              [-78.18813356317781, -0.370009854021419],
              [-78.18984263323688, -0.373515501208772],
              [-78.19242057137991, -0.375242173264496],
              [-78.193908526833, -0.376969348234468],
              [-78.19573259660375, -0.378287989243233],
              [-78.19767753341839, -0.379328015790236],
              [-78.2001956247727, -0.381687689174385],
              [-78.20059963250613, -0.383639834425765],
              [-78.19567861714725, -0.381333972860034],
              [-78.18795050241229, -0.379253081575769],
              [-78.1856075928345, -0.378963067725635],
              [-78.18217453292871, -0.378158069743904],
              [-78.17948964170105, -0.377235054565347],
              [-78.17656754261331, -0.376764159244544],
              [-78.17416361278035, -0.377450134201069],
              [-78.171874515021, -0.380034107314373],
              [-78.17010459234483, -0.381008755016524],
              [-78.16735851322395, -0.381983737994801],
              [-78.16483254288063, -0.384223550163426],
              [-78.16695349966213, -0.38674532955514],
              [-78.16860959609308, -0.388191207854277],
              [-78.17278261041189, -0.39457201547151],
              [-78.17518654024484, -0.396406814078318],
              [-78.1775286116323, -0.398540678990742],
              [-78.17821458658882, -0.400781329349684],
              [-78.17821458658882, -0.404395270726184],
              [-78.17958854815862, -0.406411118441724],
              [-78.17884054711854, -0.408587898698443],
              [-78.17666661670894, -0.407846770818992],
              [-78.17517849361778, -0.40658110343827],
              [-78.17243962293362, -0.405478212617425],
              [-78.17077665334207, -0.406281031304331],
              [-78.16905953665595, -0.408692672488201],
              [-78.16705357958614, -0.407598834122837],
              [-78.16580149088861, -0.403975002100537],
              [-78.164596508487, -0.401391196625298],
              [-78.16242961887606, -0.399203519894456],
              [-78.16002652723343, -0.397883705419247],
              [-78.1579665907071, -0.397819500040839],
              [-78.15561664033065, -0.398505474997364],
              [-78.15355653616628, -0.399941630288765],
              [-78.15155761989513, -0.399597636982151],
              [-78.15110063853358, -0.397293787073181],
              [-78.14978065642029, -0.394762955226042],
              [-78.15007050263236, -0.392468157772555],
              [-78.14862060101973, -0.390868220092898],
              [-78.14698059784288, -0.39258416331262],
              [-78.14583562986806, -0.395276765891197],
              [-78.14343253822543, -0.394697911657317],
              [-78.1426925838124, -0.392520125572275],
              [-78.14091461450917, -0.392112932715634],
              [-78.13645158634021, -0.393140889322012],
              [-78.12901264726509, -0.393419000869642],
              [-78.12866949214876, -0.395830642053511],
              [-78.12976064830517, -0.397611796479964],
              [-78.13118758350308, -0.401514242964026],
              [-78.13210255205456, -0.40335809402626],
              [-78.13284250646757, -0.408011726673635],
              [-78.13438460901523, -0.409846692918507],
              [-78.13684855327497, -0.410949583739352],
              [-78.13804649487793, -0.412612385692853],
              [-78.13850364387754, -0.414736359959477],
              [-78.14147955478373, -0.419219169419932],
              [-78.14021254629854, -0.42071802134717],
              [-78.13884763718418, -0.419451851052315],
              [-78.1389006108123, -0.421575992957003],
              [-78.13935859800223, -0.423644646662638],
              [-78.13620750531952, -0.423408779907049],
              [-78.13346863463536, -0.419034935187995],
              [-78.13100452273756, -0.417371127406113],
              [-78.12763265072499, -0.416846252628773],
              [-78.12597655429406, -0.413114629331574],
              [-78.12396959139585, -0.41070080885288],
              [-78.12162752000839, -0.409254930553743],
              [-78.12123155890201, -0.407076809192574],
              [-78.12116953281846, -0.402821149308352],
              [-78.11825564799584, -0.400986350701544],
              [-78.11791249287951, -0.399025488270865],
              [-78.11711151821132, -0.397136542569456],
              [-78.1141966275603, -0.394488866991935],
              [-78.11545558941843, -0.391444056841578],
              [-78.11471563500542, -0.388055421022671],
              [-78.11225856390628, -0.385010610872314],
              [-78.11008463349667, -0.384006794147126],
              [-78.10899364497834, -0.38402456378185],
              [-78.10482063065953, -0.385170537585054],
              [-78.10235551293333, -0.38436553960338],
              [-78.10041057611869, -0.38407552575319],
              [-78.09921263451575, -0.38567462524253],
              [-78.09909864063245, -0.389071140050419],
              [-78.09852565373085, -0.3912480879452],
              [-78.09663352290626, -0.390670071901638],
              [-78.09051557177281, -0.385842598582315],
              [-78.08770763420642, -0.384839620047387],
              [-78.08760051348374, -0.386926546301936],
              [-78.09005758458288, -0.390721201511099],
              [-78.09194150114234, -0.393252033358237],
              [-78.0935975975733, -0.394744012124875],
              [-78.0948565594314, -0.396297681698911],
              [-78.09771763826399, -0.401069499181119],
              [-78.09920458788869, -0.402967162061827],
              [-78.10303461472924, -0.406762823099314],
              [-78.10469054352212, -0.408778838452974],
              [-78.10897855755262, -0.416993271210572],
              [-78.1105265274325, -0.418836954634799],
              [-78.11332658600989, -0.42119578982863],
              [-78.11424256038975, -0.423265784638772],
              [-78.11509650868607, -0.426075566223858],
              [-78.11629461792708, -0.42843440141769],
              [-78.11469250095263, -0.430963053970004],
              [-78.11286155802128, -0.430556196389489],
              [-78.11068762761168, -0.429751366045821],
              [-78.1091465308924, -0.427446510308471],
              [-78.10703261490958, -0.42525950412994],
              [-78.10388152222687, -0.422962862657698],
              [-78.10119663099923, -0.421751677647705],
              [-78.09805257911518, -0.421397961333412],
              [-78.09638960952363, -0.423637605863973],
              [-78.09424551868935, -0.42482046004119],
              [-78.0931855432128, -0.422769911246405],
              [-78.09622163618383, -0.419446151358159],
              [-78.09627561564034, -0.417151018628488],
              [-78.0945055253261, -0.414963341897646],
              [-78.0916446141316, -0.413065343740868],
              [-78.08964553022238, -0.412775665166862],
              [-78.08775356703586, -0.413687448595113],
              [-78.08598364435969, -0.415177415704989],
              [-78.08466349460832, -0.417535245070439],
              [-78.0827175519653, -0.419919896526039],
              [-78.08174860395732, -0.416956055560433],
              [-78.08300756581544, -0.411734465153415],
              [-78.08323655941041, -0.409493814794473],
              [-78.0825495786255, -0.407423819984331],
              [-78.08003249309958, -0.406673974925582],
              [-78.07791857711676, -0.40689894520699],
              [-78.07603449291923, -0.406555119538439],
              [-78.07489053077279, -0.40465695374354],
              [-78.07299756175787, -0.402352265644311],
              [-78.07145663267667, -0.404249257972708],
              [-78.07173960572814, -0.406145915025036],
              [-78.07231158680136, -0.408386900660105],
              [-78.07270855373612, -0.412236708792136],
              [-78.0719685993231, -0.415271460658687],
              [-78.07035860335967, -0.417394093820803],
              [-78.0673295511873, -0.419634073627492],
              [-78.06458263387609, -0.42014805193071],
              [-78.06217954223345, -0.420373022212118],
              [-78.06085956012015, -0.42215166206762],
              [-78.06200452809499, -0.424618456174471],
              [-78.06206554835016, -0.426516621969313],
              [-78.06285864402936, -0.428187470549858],
              [-78.06543758800078, -0.428767330612118],
              [-78.06806950560032, -0.427449527793613],
              [-78.07041157698778, -0.429112329747113],
              [-78.07070159083793, -0.431352980106055],
              [-78.07132755136765, -0.433828826668332],
              [-78.07160951859073, -0.43612379175994],
              [-78.07338765553203, -0.438482459315651],
              [-78.07566853902625, -0.43888965217235],
              [-78.07669850728941, -0.440670471322619],
              [-78.07675952754458, -0.443480085269641],
              [-78.07858259148693, -0.444637123185089],
              [-78.08058955438514, -0.443662307844875],
              [-78.08402261429093, -0.441657356603457],
              [-78.08614357107243, -0.441604382975299],
              [-78.08848564245989, -0.442409380957031],
              [-78.0912325597711, -0.442699227169101],
              [-78.09277365649038, -0.444426066862945],
              [-78.09500860715515, -0.445863060344664],
              [-78.09946459452543, -0.445069964665436],
              [-78.10204353849686, -0.445928942103706],
              [-78.10398864294956, -0.447655949435557],
              [-78.10598755922071, -0.447891983829209],
              [-78.10890161168139, -0.4493286420348],
              [-78.10718550082365, -0.451279781457799],
              [-78.10415661628936, -0.453284565061153],
              [-78.10260764058108, -0.455696206245079],
              [-78.09974656174852, -0.458107176876695],
              [-78.09934255401508, -0.460401974330182],
              [-78.09922755430341, -0.462235934746673],
              [-78.0983736060071, -0.464015580430555],
              [-78.09671063641555, -0.464990563408833],
              [-78.0944215386562, -0.464474573448854],
              [-78.09265161598003, -0.464754529015181],
              [-78.09259059572486, -0.466769538540461],
              [-78.0932155504262, -0.46854935186235],
              [-78.09424551868935, -0.470447182381122],
              [-78.09470350587928, -0.472227163341131],
              [-78.09475765297385, -0.476419623675326],
              [-78.09413152480606, -0.478487606828708],
              [-78.09218558216304, -0.477908584956765],
              [-78.09075160616645, -0.476643588128354],
              [-78.08863852837395, -0.475892904879288],
              [-78.08754753985562, -0.479506343341598],
              [-78.08566261746776, -0.478990521019682],
              [-78.08394650661003, -0.478293649589034],
              [-78.08245855115695, -0.479332502669536],
              [-78.08171055011687, -0.481166295447963],
              [-78.08051260851391, -0.482602283101357],
              [-78.07891049153947, -0.483514066529608],
              [-78.07701852835295, -0.483856048179518],
              [-78.07266949406728, -0.483963168902164],
              [-78.07078557750782, -0.485001183792406],
              [-78.07135755858104, -0.487123984592586],
              [-78.07215149245056, -0.489130947490764],
              [-78.07358563608521, -0.490974630914991],
              [-78.07432559049823, -0.492636427040054],
              [-78.0730666286401, -0.494479272273963],
              [-78.0732346019799, -0.496377270430742],
              [-78.07512656516643, -0.497642267259153],
              [-78.07627052731287, -0.499134078387726],
              [-78.07655350036434, -0.501374728746669],
              [-78.07712564907563, -0.503326706359985],
              [-78.07866657815684, -0.504709720385222],
              [-78.07917753897489, -0.506606377437549],
              [-78.07803357682845, -0.508097350375749],
              [-78.07448551721097, -0.510273124804087],
              [-78.07454653746615, -0.512342113785849],
              [-78.07585864059045, -0.514067780013193],
              [-78.07946051202636, -0.515795625535418],
              [-78.08083363540584, -0.517178807198718],
              [-78.08260355808201, -0.518100481272711],
              [-78.08454849489665, -0.518562491776208],
              [-78.08632663183795, -0.518273483754399],
              [-78.08781458729104, -0.51724452131964],
              [-78.08964553022238, -0.517019551038231],
              [-78.09187360772655, -0.517363544344846],
              [-78.09393354425288, -0.516795586585147],
              [-78.09593950132269, -0.516796592413527],
              [-78.09679462308544, -0.5185767410116],
              [-78.0956496551106, -0.520013566855255],
              [-78.09427653173113, -0.521385516768305],
              [-78.09421551147595, -0.523283179649013],
              [-78.09507750639932, -0.525009013514421],
              [-78.09684759671356, -0.526328995627694],
              [-78.1021655790072, -0.528689842478286],
              [-78.10394254248205, -0.529674883740427],
              [-78.1056594915301, -0.530877519209128],
              [-78.10713956799421, -0.532495394161572],
              [-78.10926052477569, -0.533246412686765],
              [-78.11114460897322, -0.53341941516851],
              [-78.11503565606895, -0.534225251340501],
              [-78.11910255549347, -0.534344442003771],
              [-78.12087264580771, -0.535212304259403],
              [-78.12161260022073, -0.537047270504274],
              [-78.12350456340725, -0.536361295547749],
              [-78.12545050605027, -0.537112146434879],
              [-78.12561764119974, -0.540906969282105],
              [-78.12744858413109, -0.542172804300833],
              [-78.1296764939972, -0.54171297309216],
              [-78.13173659816158, -0.542409676884802],
              [-78.1336285613481, -0.541551872913033],
              [-78.13568849787441, -0.542474888091533],
              [-78.13574264496899, -0.544543541797168],
              [-78.13842753619663, -0.547879539264102],
              [-78.13677261323214, -0.549080163076098],
              [-78.13487964421724, -0.548618320210664],
              [-78.13327752724278, -0.547578461301782],
              [-78.13139361068332, -0.547171603721267],
              [-78.13059263601512, -0.548842284663749],
              [-78.12870050519054, -0.549528259620274],
              [-78.1275025635876, -0.548027396036275],
              [-78.12664760946289, -0.546419411729573],
              [-78.12509963958301, -0.545270420441227],
              [-78.12149860633743, -0.543308887458295],
              [-78.11777452675311, -0.541815902863277],
              [-78.11389152628445, -0.540946867141258],
              [-78.11188456338624, -0.540945861312878],
              [-78.11033659350636, -0.542724836444449],
              [-78.10839852985234, -0.542371790682409],
              [-78.10736856158917, -0.540473960163638],
              [-78.10565161254111, -0.539840120644897],
              [-78.10359150837674, -0.539785135360034],
              [-78.10181454490188, -0.54047111031656],
              [-78.09844250525127, -0.542474888091533],
              [-78.0963745220979, -0.54586251808206],
              [-78.09557354742971, -0.547921448780016],
              [-78.09346063727527, -0.548038292510398],
              [-78.09231550166237, -0.549474112525672],
              [-78.09105653980426, -0.547856405211292],
              [-78.09162852087748, -0.545905433426412],
              [-78.0914005331109, -0.544070634819548],
              [-78.09037760564641, -0.539472155095154],
              [-78.0888906560217, -0.538269184350327],
              [-78.08676953160214, -0.538151167153501],
              [-78.08477061533101, -0.538376137434909],
              [-78.08299264602778, -0.539351120413187],
              [-78.08116153545836, -0.539575923056589],
              [-78.07939161278219, -0.538653075516095],
              [-78.07790348969104, -0.537613216607213],
              [-78.07607254675969, -0.538073215453892],
              [-78.07401261023337, -0.538181174366912],
              [-78.07012156313765, -0.537086162535047],
              [-78.06800865298321, -0.537428144184901],
              [-78.06691749682682, -0.538917943656713],
              [-78.06691749682682, -0.540869753631966],
              [-78.06616949578674, -0.542766913598427],
              [-78.0642245589721, -0.542530711566769],
              [-78.06239361604075, -0.54155371693173],
              [-78.06027953241987, -0.541552878741413],
              [-78.0586775830835, -0.54321484250454],
              [-78.05776261453201, -0.546891312878813],
              [-78.05701461349193, -0.549068428411601],
              [-78.05701461349193, -0.551191061573718],
              [-78.05832654897817, -0.555049754523168],
              [-78.05764057402166, -0.557172555323348],
              [-78.05563361112347, -0.556990835662361],
              [-78.05426752854265, -0.555670853549088],
              [-78.05266557920628, -0.554576847545604],
              [-78.05088760990304, -0.553708985289973],
              [-78.0469435891792, -0.552614141096171],
              [-78.04476949113153, -0.552495118070965],
              [-78.04305254208347, -0.553416121592761],
              [-78.0415035663752, -0.554788909696128],
              [-78.0398486434107, -0.553812082699153],
              [-78.04082463221737, -0.551914922732635],
              [-78.04373952286836, -0.549396160826063],
              [-78.04557063343778, -0.548764165326077],
              [-78.04649364861632, -0.544744707473569],
              [-78.04860655877076, -0.544519569554041],
              [-78.04998052034055, -0.543255746192074],
              [-78.04832459154767, -0.542341783468999],
              [-78.0474625966243, -0.540724914344935],
              [-78.04792762461291, -0.538944933384926],
              [-78.04626465502136, -0.537571139453235],
              [-78.04735564353969, -0.535792164321606],
              [-78.04924056592753, -0.535449176843372],
              [-78.05113252911406, -0.534420382046619],
              [-78.05244463223836, -0.533156391046589],
              [-78.05313865382193, -0.531259733994318],
              [-78.05268049899394, -0.529253944562527],
              [-78.05078853580741, -0.528610717312233],
              [-78.04867562565299, -0.528383735374064],
              [-78.04764548975176, -0.526830065800027],
              [-78.04604354041538, -0.52590705062147],
              [-78.04415861802752, -0.52590705062147],
              [-78.04249564843597, -0.525102220277802],
              [-78.0416415325016, -0.523032225467659],
              [-78.04124456556684, -0.521017551218563],
              [-78.04112956585517, -0.518840603323781],
              [-78.04015357704851, -0.517231780826819],
              [-78.0379796466389, -0.517799570948455],
              [-78.03603353635782, -0.518882512839696],
              [-78.03414961979836, -0.518656536729907],
              [-78.03277549059048, -0.51744468116766],
              [-78.03208951563397, -0.515718847302253],
              [-78.03169254869921, -0.513370070392227],
              [-78.0316395750711, -0.511066220483258],
              [-78.0318065425825, -0.509232427704831],
              [-78.0324935233674, -0.507226470635032],
              [-78.03095259428619, -0.506132632269612],
              [-78.02997559965115, -0.507677249388223],
              [-78.0294646388331, -0.509917396832975],
              [-78.02797651574195, -0.511408202133168],
              [-78.0268325535955, -0.512960865878824],
              [-78.02579453870528, -0.516745798080251],
              [-78.02494059040897, -0.518986616077257],
              [-78.02276649236131, -0.518695596398743],
              [-78.0215605041313, -0.516969594895215],
              [-78.02104954331325, -0.515135802116788],
              [-78.0200195750501, -0.513410135889444],
              [-78.01967658757184, -0.511277109167395],
              [-78.02071359663367, -0.509561165947673],
              [-78.02191153823662, -0.508071366475917],
              [-78.02277353315998, -0.506056524588701],
              [-78.02254453956502, -0.50220772228505],
              [-78.02265953927669, -0.499968077754488],
              [-78.02151457130185, -0.498422119531369],
              [-78.01974464862568, -0.498991083119449],
              [-78.01796751751277, -0.498357243600708],
              [-78.01877553297963, -0.496632080287554],
              [-78.01843254550138, -0.494626290855763],
              [-78.01648760868673, -0.494969278334054],
              [-78.01545764042358, -0.496748085827562],
              [-78.01362652985416, -0.496856212378646],
              [-78.01293954906927, -0.495130378513238],
              [-78.01145964024323, -0.49463249346411],
              [-78.01058959869282, -0.496972050280647],
              [-78.01053662506469, -0.498977001522064],
              [-78.01019263175804, -0.50075681484401],
              [-78.01144455281751, -0.502365804979036],
              [-78.01196255443422, -0.504380646866252],
              [-78.01127657947771, -0.506331451013125],
              [-78.00989557710923, -0.50758638955773],
              [-78.00949861017448, -0.509773395736318],
              [-78.0094375899193, -0.512131057463648],
              [-78.00892662910125, -0.513964850242132],
              [-78.00765962061607, -0.515282820698644],
              [-78.00600453001351, -0.514531802173394],
              [-78.00217450317295, -0.513437125617713],
              [-78.00016754027476, -0.513319108420887],
              [-77.99836761038519, -0.513525806153382],
              [-77.99660456086963, -0.511293202421484],
              [-77.99697856138965, -0.50644259504935],
              [-77.99652057419972, -0.504490785074097],
              [-77.99692558776154, -0.501012965805273],
              [-77.99661260749669, -0.500019039725828],
              [-77.99522355850115, -0.498148031297262],
              [-77.99420951585404, -0.499548144404969],
              [-77.99365262220655, -0.501227877802933],
              [-77.99159955884085, -0.503467857609621],
              [-77.9910585908094, -0.507831308768743],
              [-77.98912052715536, -0.508995387482798],
              [-77.98683159703408, -0.508551481890265],
              [-77.98499260747568, -0.506843585297645],
              [-77.98211660885545, -0.503761727135213],
              [-77.98107155316656, -0.501818801977322],
              [-77.98059864618895, -0.499640848254217],
              [-77.98046151825288, -0.495621222763646],
              [-77.97930163049038, -0.493786424156838],
              [-77.97962953054291, -0.491609476262056],
              [-77.98030058571176, -0.489531602462932],
              [-77.97858464249208, -0.488510686655218],
              [-77.97768358789989, -0.490470543257516],
              [-77.97654750474243, -0.49196973046088],
              [-77.97656259216816, -0.493920534607753],
              [-77.97760764785704, -0.496333181620003],
              [-77.97763061427177, -0.500461939490037],
              [-77.9778745276544, -0.502756904581588],
              [-77.9804305052111, -0.50859339140618],
              [-77.97964461796863, -0.510554253836858],
              [-77.98216254168489, -0.51065433376084],
              [-77.98410764613759, -0.511324047825212],
              [-77.98560364821773, -0.513040996873258],
              [-77.99167650471206, -0.515293884810831],
              [-77.99351465608015, -0.516314632980482],
              [-77.99546059872317, -0.51710186132749],
              [-77.99695559497493, -0.518358643890792],
              [-77.99777249525918, -0.520183551851801],
              [-77.99823752324778, -0.522018350458609],
              [-77.99699364881539, -0.5238690746815],
              [-77.9966655811248, -0.526055075031707],
              [-77.99290462116647, -0.52779683451314],
              [-77.98889958018745, -0.526223048371492],
              [-77.98764749148994, -0.527947876408518],
              [-77.9866336164809, -0.52979960645979],
              [-77.9857325618887, -0.532102450540322],
              [-77.98554950112317, -0.538074221282272],
              [-77.98326861762895, -0.539237964720257],
              [-77.98133055397491, -0.53948087227451],
              [-77.97926357664993, -0.538802943945029],
              [-77.97766162731355, -0.537781189946998],
              [-77.97675353192267, -0.539398059071061],
              [-77.9774556001333, -0.541232019487552],
              [-77.97780663423862, -0.543065812265979],
              [-77.97679158576312, -0.544908489861768],
              [-77.97543354980938, -0.546299382875986],
              [-77.9734956537934, -0.547454576772736],
              [-77.97202261812798, -0.548727452590128],
              [-77.97008556030234, -0.549892201856494],
              [-77.96791062406434, -0.550134103582309],
              [-77.96652962169587, -0.54876919446798],
              [-77.96549261263404, -0.546709425579706],
              [-77.96421856335019, -0.54488351179026],
              [-77.96250949329112, -0.545696556399037],
              [-77.96298960870546, -0.548217497600433],
              [-77.96276849409949, -0.550285145477687],
              [-77.96415754309501, -0.551885250795351],
              [-77.96485860547726, -0.554062869242387],
              [-77.9620136198988, -0.556726638073997],
              [-77.96150953224137, -0.558315511641467],
              [-77.96363048902285, -0.559012718348242],
              [-77.96681259474734, -0.556457746619913],
              [-77.96875753156198, -0.556331850434105],
              [-77.97058059550433, -0.555284783088439],
              [-77.97203049711698, -0.554906926892954],
              [-77.97373956717604, -0.556325815463822],
              [-77.97379254080415, -0.557906474766071],
              [-77.97130563012969, -0.561248339565282],
              [-77.97017658777091, -0.563217248623005],
              [-77.97019150755857, -0.565511207886175],
              [-77.97100052885382, -0.567336954037557],
              [-77.9723815312223, -0.568710915607369],
              [-77.97331259302788, -0.570653673127197],
              [-77.97686752580596, -0.572814527767832],
              [-77.97881363608705, -0.573601420838656],
              [-77.98087357261336, -0.573819350321457],
              [-77.98211660885545, -0.573178637642116],
              [-77.98577162155753, -0.568211857091853],
              [-77.985946635696, -0.563216410432688],
              [-77.9857025546753, -0.560695469231291],
              [-77.9861146090358, -0.559421419947455],
              [-77.98724365139458, -0.55887173473667],
              [-77.99015854204558, -0.560544427335913],
              [-77.99176753218063, -0.562026180180681],
              [-77.99257655347589, -0.563743129228726],
              [-77.99327862168651, -0.565921082951832],
              [-77.99480463098006, -0.565957963325843],
              [-77.9962466536037, -0.56692506731514],
              [-77.99758155550467, -0.568299028884951],
              [-77.99989361967873, -0.569004952771024],
              [-78.00276961829897, -0.566558107593778],
              [-78.00517254230354, -0.563696190570909],
              [-78.0060885166834, -0.561970356705444],
              [-78.00660651830013, -0.560191549211936],
              [-78.00695051160677, -0.556396894002773],
              [-78.00695051160677, -0.551979128111043],
              [-78.00787352678532, -0.550146341160996],
              [-78.01021559817278, -0.547048557382539],
              [-78.01107759309613, -0.545151565054084],
              [-78.01228358132614, -0.543426737017057],
              [-78.01377053095085, -0.542171798472452],
              [-78.01524356661628, -0.54172973689856],
              [-78.01565562097677, -0.543952617622779],
              [-78.01565562097677, -0.548090427948182],
              [-78.01525161324334, -0.550041064456991],
              [-78.01553358046642, -0.55228205009206],
              [-78.0142746186083, -0.553825829020354],
              [-78.01352661756822, -0.555605809980364],
              [-78.0134736439401, -0.55744882285228],
              [-78.0136416172799, -0.559571623652459],
              [-78.01426657198124, -0.561351269336342],
              [-78.01541153995608, -0.56278826281806],
              [-78.0173035031426, -0.564054097836845],
              [-78.02101853027148, -0.565899122365522],
              [-78.02284964084089, -0.567048113653868],
              [-78.02181950493966, -0.568772774052889],
              [-78.01969955398656, -0.569231767071187],
              [-78.01775361134354, -0.569393873078752],
              [-78.01580850689083, -0.569853871925432],
              [-78.01426657198124, -0.571054663375492],
              [-78.01340457705787, -0.572779491412518],
              [-78.01071163920317, -0.576102413110505],
              [-78.00945250970697, -0.578053049619314],
              [-78.00796556008227, -0.579434890178106],
              [-78.0061946315777, -0.580291855959558],
              [-78.00492862892091, -0.582124810547668],
              [-78.0028155511284, -0.58585542801643],
              [-78.00195355620505, -0.58768838260454],
              [-78.00069459434692, -0.589007191251426],
              [-77.99371364246173, -0.59312304099052],
              [-77.99195059294615, -0.594531033087264],
              [-77.98694659674575, -0.600563488808234],
              [-77.98621351549335, -0.601845249442988],
              [-77.98622860291908, -0.602884437799673],
              [-77.98746459836248, -0.603599078865045],
              [-77.98914349357008, -0.6040511987228],
              [-77.99434664379012, -0.604425031604762],
              [-77.99719950835757, -0.604824177834359],
              [-77.99851261731025, -0.604526117357125],
              [-78.00137352850477, -0.602793242693053],
              [-78.00360160600894, -0.602225452571417],
              [-78.00554654282358, -0.602226290761735],
              [-78.0074385060101, -0.602002493946713],
              [-78.00881162938958, -0.600512526836894],
              [-78.00904062298454, -0.598443705493196],
              [-78.01024661121455, -0.596663724533187],
              [-78.01224552748569, -0.596321742883333],
              [-78.01647956205969, -0.59644177173692],
              [-78.02059960275038, -0.596217807283836],
              [-78.02231554597005, -0.595018021662213],
              [-78.02339949368971, -0.593355890260966],
              [-78.02483363732436, -0.592037919804397],
              [-78.02729758158411, -0.591921243712079],
              [-78.02906750426028, -0.591641958698062],
              [-78.03021263987318, -0.59015215922625],
              [-78.03227257639949, -0.589981168401323],
              [-78.03421751321413, -0.590154170883011],
              [-78.03616362349523, -0.589469201754866],
              [-78.03816253976636, -0.58929821092994],
              [-78.04342654260351, -0.591839939251201],
              [-78.04520451190673, -0.591208111389278],
              [-78.04697460222097, -0.590179148954519],
              [-78.0488665654075, -0.590406298530752],
              [-78.04977449316031, -0.592420972779905],
              [-78.0516666239849, -0.593460999326851],
              [-78.05567149732585, -0.593753024833745],
              [-78.05750260789526, -0.594440005618651],
              [-78.05915853668814, -0.595597714086352],
              [-78.06098964725756, -0.596402712068084],
              [-78.0628205901889, -0.596746705374699],
              [-78.06481950646005, -0.596521735093233],
              [-78.06687961062443, -0.596983577958667],
              [-78.06825256636584, -0.598538756275275],
              [-78.06990849515873, -0.599289607162405],
              [-78.07202961957827, -0.599064469242933],
              [-78.07414252973271, -0.598487626665872],
              [-78.07843758456187, -0.598498523139995],
              [-78.08032250694973, -0.59735254933679],
              [-78.08215361751914, -0.597931738846739],
              [-78.08290061273082, -0.599820349272022],
              [-78.08444254764042, -0.601321548132091],
              [-78.08650265180479, -0.601837202815943],
              [-78.08838656836426, -0.601847261099749],
              [-78.09256762931012, -0.600928436872834],
              [-78.09394058505154, -0.602365430354553],
              [-78.09268162319341, -0.603864114643727],
              [-78.09101865360186, -0.605291217479703],
              [-78.09044650489058, -0.607242021626575],
              [-78.09027065256178, -0.611208505850925],
              [-78.08832554810908, -0.6147672943045],
              [-78.08654757880585, -0.613555438742253],
              [-78.08529649593672, -0.612000595701772],
              [-78.08152061619073, -0.610444746832911],
              [-78.07328053480934, -0.608885712840788],
              [-78.0672755717308, -0.608756966807903],
              [-78.06549860825595, -0.6093336417469],
              [-78.06315653686849, -0.608871798881523],
              [-78.0616685814154, -0.607253923929079],
              [-78.06018062596232, -0.606051120822315],
              [-78.05829654176478, -0.604957282456894],
              [-78.05635059912176, -0.604206096293581],
              [-78.05228453788756, -0.603508386672615],
              [-78.04994162830978, -0.603678371669162],
              [-78.04816449719686, -0.604075338603934],
              [-78.04611160146922, -0.604128312232035],
              [-78.04428049089981, -0.603612322272113],
              [-78.04233555408517, -0.604243311943719],
              [-78.04067258449362, -0.605733111415532],
              [-78.03849764825563, -0.606644894843782],
              [-78.03483559475487, -0.604628041299861],
              [-78.03266149670719, -0.605142187241086],
              [-78.03089157403102, -0.606803145175888],
              [-78.02516958400395, -0.608407776721322],
              [-78.02339161470071, -0.609264910140837],
              [-78.02337652727499, -0.61086451254431],
              [-78.02277353315998, -0.612544413580281],
              [-78.0215526251423, -0.6131574659791],
              [-78.02001152842304, -0.612587664200703],
              [-78.01847059934182, -0.611611675394045],
              [-78.0165865151443, -0.611031815331842],
              [-78.0145795522461, -0.611428614628551],
              [-78.013091596793, -0.612466629518735],
              [-78.01137565357334, -0.613378412947043],
              [-78.00959751663204, -0.614750362860036],
              [-78.00810956117896, -0.616357173700294],
              [-78.00719459262747, -0.618307977847167],
              [-78.00524864998444, -0.619571968847197],
              [-78.00387552660496, -0.621350776340762],
              [-78.00164057594019, -0.624737568140972],
              [-77.99992362689213, -0.626163497510504],
              [-77.9974826490471, -0.62777952844425],
              [-77.9964215001041, -0.628890298254078],
              [-77.9959485931265, -0.629838123866023],
              [-77.99552949796734, -0.631925217758692],
              [-77.99593350570078, -0.639351416340958],
              [-77.99574256594626, -0.640652455353063],
              [-77.99291954095413, -0.649377848928737],
              [-77.99365262220655, -0.650850549318079],
              [-77.99504854436269, -0.651655547299754],
              [-77.99879458453334, -0.652253679910928],
              [-77.99989361967873, -0.652651485036017],
              [-78.0047685345701, -0.652591638247259],
              [-78.00631751027838, -0.651499643900593],
              [-78.00820964110297, -0.651157662250682],
              [-78.00917054248389, -0.653750352543341],
              [-78.00968954992899, -0.656163167193654],
              [-78.01071163920317, -0.657997295248208],
              [-78.01316854266423, -0.661223825059608],
              [-78.01139861998806, -0.66201792656716],
              [-78.00962065068482, -0.663453746582434],
              [-78.01013949049187, -0.665296591816343],
              [-78.01300056932445, -0.665696576236257],
              [-78.01419851092739, -0.66782926768218],
              [-78.01585360152995, -0.666104607283216],
              [-78.01574664844533, -0.663402784611094],
              [-78.01586164815701, -0.66064798831087],
              [-78.01695263667534, -0.658471040416146],
              [-78.01935556067991, -0.658644042897834],
              [-78.02143863125902, -0.657381057726184],
              [-78.01895155294649, -0.655491441472577],
              [-78.01569350717915, -0.654161401075442],
              [-78.01374857036451, -0.652831695954433],
              [-78.01332863701504, -0.650328859663944],
              [-78.01398460475815, -0.649561747884604],
              [-78.01615149436908, -0.650484595425098],
              [-78.01855458601173, -0.650774609275231],
              [-78.02085156276006, -0.648652814303432],
              [-78.0214235438333, -0.646476034046714],
              [-78.02399460881571, -0.646080072940322],
              [-78.02708451360519, -0.647862065557092],
              [-78.0297165988428, -0.647059079232122],
              [-78.0317765353691, -0.64861492810104],
              [-78.03411860675658, -0.64787480604997],
              [-78.03400461287328, -0.64546299722798],
              [-78.03041062042637, -0.640627477281555],
              [-78.03229554281421, -0.639083530715197],
              [-78.03475965471203, -0.639256700835006],
              [-78.03813152672458, -0.637831609655791],
              [-78.04076361196219, -0.638067644049443],
              [-78.04333450930655, -0.63909945633128],
              [-78.04522664013113, -0.6369766555311],
              [-78.04689061555108, -0.635658852712595],
              [-78.05060564267995, -0.633546948386538],
              [-78.05152161705982, -0.631533279965822],
              [-78.05232963252669, -0.627856474315479],
              [-78.0530695869397, -0.626076660993533],
              [-78.05690749276926, -0.623440887718516],
              [-78.0584335020628, -0.623251624344618],
              [-78.0585635892002, -0.625573411526375],
              [-78.05816662226545, -0.627525389139691],
              [-78.05833459560523, -0.630227211811814],
              [-78.05895955030657, -0.633552145166561],
              [-78.06033351187638, -0.635170020118949],
              [-78.06182062913915, -0.636489666956152],
              [-78.06204962273411, -0.638956461062946],
              [-78.06341553767686, -0.640221457891357],
              [-78.06547564184123, -0.63953648876327],
              [-78.06696359729432, -0.638507693966517],
              [-78.06925956821428, -0.636331919538236],
              [-78.07045750981723, -0.634317077651019],
              [-78.07154849833556, -0.631336305241007],
              [-78.07411956331799, -0.63208799431851],
              [-78.07326561502168, -0.635249815837255],
              [-78.07371555558456, -0.637372616637435],
              [-78.07440153054107, -0.639496423266053],
              [-78.0755464985159, -0.641105413401078],
              [-78.0769725955235, -0.64300324391985],
              [-78.07989452697318, -0.644098088113651],
              [-78.08349656604716, -0.644796300648807],
              [-78.08425864868457, -0.646061129839154],
              [-78.08155062340413, -0.647260915460777],
              [-78.07725556857497, -0.648062895957366],
              [-78.07514165259215, -0.649434678232353],
              [-78.07462365097543, -0.652072630802195],
              [-78.07359351507421, -0.654195599240438],
              [-78.07130458495293, -0.653615571540172],
              [-78.06649756347733, -0.649989727861168],
              [-78.06358351101665, -0.649418920254334],
              [-78.0608976139606, -0.649923678464063],
              [-78.05854749594609, -0.652045473435862],
              [-78.05683155272641, -0.653941292297873],
              [-78.05454262260513, -0.654166430217344],
              [-78.0539706415319, -0.656524259582795],
              [-78.05590853754788, -0.658937074233108],
              [-78.05808263559554, -0.659742910405157],
              [-78.0584335020628, -0.66163101791625],
              [-78.05619050477095, -0.663129702205367],
              [-78.05401657436136, -0.66323682292807],
              [-78.05304762635336, -0.664907503870552],
              [-78.05007154780913, -0.665302626786627],
              [-78.0480116112828, -0.66455177589944],
              [-78.04629550042507, -0.666674576699677],
              [-78.04674560862601, -0.669665407393495],
              [-78.04531850579004, -0.671154201036927],
              [-78.04777557688917, -0.672710049905788],
              [-78.0503535150322, -0.672197245069015],
              [-78.05378758076638, -0.67214410380285],
              [-78.0567546068552, -0.673067957171725],
              [-78.0586475758701, -0.672951113441343],
              [-78.06002053161151, -0.674334965656897],
              [-78.06242362325415, -0.676061637712621],
              [-78.06402557259052, -0.675140969467009],
              [-78.06585651552187, -0.674690861266072],
              [-78.06716962447457, -0.675955690456419],
              [-78.06877157381095, -0.67698666454794],
              [-78.0707704900821, -0.676473692073102],
              [-78.072547621195, -0.678145546482028],
              [-78.07145663267667, -0.679635513591904],
              [-78.06962552210726, -0.681125313063717],
              [-78.06916854074571, -0.683826129907402],
              [-78.06704758396423, -0.683075279020272],
              [-78.06504850005501, -0.682731285713601],
              [-78.05978349138948, -0.683179382257777],
              [-78.057662534608, -0.683747172379412],
              [-78.0528565189608, -0.688161920786058],
              [-78.04999560776628, -0.688846889914146],
              [-78.04747751641197, -0.689188871564056],
              [-78.04798864486808, -0.691203545813153],
              [-78.05016358110608, -0.690283883395921],
              [-78.05256650511065, -0.689769905092703],
              [-78.05439761568006, -0.688569784194954],
              [-78.05892149646613, -0.689204797180082],
              [-78.06109660034218, -0.688175834745323],
              [-78.06224056248863, -0.689784657242342],
              [-78.06257650916821, -0.692260671442625],
              [-78.06372063895273, -0.693978458681045],
              [-78.06584159573421, -0.694097314068131],
              [-78.07253253376928, -0.693080589212059],
              [-78.07453949666747, -0.694635432252539],
              [-78.07653858057668, -0.696534268599635],
              [-78.07521859846338, -0.698593031659527],
              [-78.07327265582036, -0.699278000787672],
              [-78.07155654496262, -0.700198836671348],
              [-78.07080854392254, -0.703414637646631],
              [-78.06497155418378, -0.706103552187869],
              [-78.06222564270097, -0.706788353677894],
              [-78.06039453213155, -0.708738319634449],
              [-78.06130950068302, -0.710519138784775],
              [-78.06244658966887, -0.711035296382818],
              [-78.06474256058883, -0.710349153788229],
              [-78.06548352083023, -0.708633378206628],
              [-78.06806162661132, -0.706801597084961],
              [-78.07103753751751, -0.707435604241709],
              [-78.07200665316356, -0.705773472840463],
              [-78.07405049643576, -0.705684624666731],
              [-78.07406658968988, -0.708584427892049],
              [-78.07344062916015, -0.711683049860824],
              [-78.07377657583973, -0.713183075254506],
              [-78.07606450013263, -0.714566927470059],
              [-78.07794959015854, -0.715200934626807],
              [-78.07978053308989, -0.717270761798886],
              [-78.08211556367868, -0.718410700631807],
              [-78.08275560580576, -0.715835947611993],
              [-78.08144350268145, -0.713883969998676],
              [-78.07995554722835, -0.712219994578732],
              [-78.08018454082332, -0.70951917773499],
              [-78.0787586114538, -0.707277353909603],
              [-78.08053557492863, -0.706357523854308],
              [-78.08242049731649, -0.706647370066378],
              [-78.0856855838825, -0.708086375204857],
              [-78.0883716485766, -0.708548218070291],
              [-78.08968358406284, -0.707872469035635],
              [-78.09049260535808, -0.705803647691937],
              [-78.08974460431801, -0.70339066540356],
              [-78.09146859416474, -0.701322849888243],
              [-78.09444450507092, -0.701848898132027],
              [-78.09655758286343, -0.700703091966886],
              [-78.09810655857169, -0.699041128203703],
              [-78.0981595321998, -0.696511302184945],
              [-78.09736660415867, -0.693691629954117],
              [-78.097251604447, -0.691622640972355],
              [-78.09765661800881, -0.689391713621092],
              [-78.09885355378337, -0.686862893430714],
              [-78.098457592677, -0.684396266961926],
              [-78.09519954690967, -0.680599600096059],
              [-78.09686251650122, -0.679163612442665],
              [-78.0989766001221, -0.678703613595985],
              [-78.101264524415, -0.677386816605917],
              [-78.10178353186011, -0.679283641296252],
              [-78.10395863573616, -0.679809354263909],
              [-78.10595654617892, -0.67981036009229],
              [-78.10698651444208, -0.68176250534367],
              [-78.10778061594966, -0.686127129969293],
              [-78.10812360342791, -0.689234972031556],
              [-78.10829157676771, -0.695261225144179],
              [-78.10908551063723, -0.702498830904858],
              [-78.10890949067038, -0.71157928189939],
              [-78.11005362045489, -0.713297069137752],
              [-78.1128005377661, -0.711753122571395],
              [-78.11337251883933, -0.709576342314733],
              [-78.11325852495604, -0.706585679258922],
              [-78.11395254653962, -0.704173702798926],
              [-78.1120605833531, -0.702392716010536],
              [-78.1120605833531, -0.700205877470069],
              [-78.11235059720323, -0.698318272873166],
              [-78.11418154013458, -0.695961449336096],
              [-78.11327361238178, -0.693204641379168],
              [-78.11309859824331, -0.690042819860366],
              [-78.11333463263695, -0.687630005210053],
              [-78.11413560730513, -0.685110069837037],
              [-78.1157985768967, -0.682698261015048],
              [-78.11745450568958, -0.679826453346436],
              [-78.12071959225558, -0.678915675746509],
              [-78.11957563010913, -0.677478849902855],
              [-78.11872050834637, -0.674948018055716],
              [-78.11997963784256, -0.673630047599147],
              [-78.1218645602304, -0.673631053427584],
              [-78.12426765187305, -0.673351936051574],
              [-78.12707558943944, -0.672088950879925],
              [-78.1272506035779, -0.669966150079688],
              [-78.12718958332273, -0.667951308192528],
              [-78.12982954754932, -0.666064709424063],
              [-78.13108850940745, -0.664113737639127],
              [-78.13068349584563, -0.662332918488801],
              [-78.1305775485894, -0.660489905616885],
              [-78.12954758032625, -0.658249087619822],
              [-78.12702948897193, -0.655663438125885],
              [-78.12869262620156, -0.653884462994256],
              [-78.1318965248743, -0.65314551440963],
              [-78.13361364156043, -0.651483550646446],
              [-78.13470463007877, -0.649360749846267],
              [-78.13539161086366, -0.647347081425551],
              [-78.135276611152, -0.645222939520806],
              [-78.13356050029425, -0.641490310395284],
              [-78.13264452591439, -0.639077495744914],
              [-78.13224755897963, -0.636836677747908],
              [-78.13156158402312, -0.634938847229193],
              [-78.12893653958417, -0.630736160973072],
              [-78.1280215710327, -0.627519354169408],
              [-78.12790657132102, -0.624826751590831],
              [-78.12734263687486, -0.622522734043798],
              [-78.12648064195149, -0.620163898850024],
              [-78.12631250097364, -0.616947259684366],
              [-78.12837260513801, -0.617526113918245],
              [-78.13020354806936, -0.619424950265341],
              [-78.131400651482, -0.621322948422176],
              [-78.13368958160328, -0.625923607441393],
              [-78.13597063273556, -0.629032287693974],
              [-78.13859550953643, -0.641900353099004],
              [-78.13951165155436, -0.643509175595966],
              [-78.1408235870406, -0.644946001439678],
              [-78.14311251716188, -0.644839215993102],
              [-78.14632463009977, -0.642193216796215],
              [-78.1485515341375, -0.641743443871405],
              [-78.1515886329369, -0.63996463637784],
              [-78.15582249987283, -0.636009719179867],
              [-78.15794362429239, -0.631710138122969],
              [-78.15949260000065, -0.629759333976097],
              [-78.16126252267682, -0.629010159469601],
              [-78.1632686473847, -0.627638544832678],
              [-78.1634366207245, -0.62545153865409],
              [-78.16516161639962, -0.623789574890964],
              [-78.16722054709754, -0.623962577372652],
              [-78.16921963100674, -0.622644774554146],
              [-78.17099760030997, -0.621851008322722],
              [-78.17293549632595, -0.62420967587849],
              [-78.17105057393809, -0.625808607729709],
              [-78.17116557364977, -0.629024408704993],
              [-78.17184450780762, -0.632703058374091],
              [-78.17012755875956, -0.634364016308837],
              [-78.16870162939003, -0.633270010305409],
              [-78.16721350629886, -0.630287226238579],
              [-78.16469558258262, -0.631434205870164],
              [-78.16297963936294, -0.634531989648679],
              [-78.16223163832288, -0.638199575205533],
              [-78.1620026447279, -0.640440393202539],
              [-78.16073664207111, -0.642102189327659],
              [-78.15787455741015, -0.642561182345958],
              [-78.15684559497538, -0.645370963931043],
              [-78.15347254949637, -0.645080279528656],
              [-78.15146659242656, -0.645305249810065],
              [-78.15038264470691, -0.646857913555777],
              [-78.1507185913865, -0.650246884650812],
              [-78.14900264816681, -0.651447676100815],
              [-78.14545458854934, -0.652186457047378],
              [-78.14408163280793, -0.654832456244321],
              [-78.1425324894616, -0.656783260391137],
              [-78.14190652893187, -0.6590240783882],
              [-78.14258563072778, -0.66327068581694],
              [-78.14321159125751, -0.669306159023051],
              [-78.14389756621402, -0.671430133289675],
              [-78.14527152778382, -0.672984976330213],
              [-78.14355457873577, -0.674872916203185],
              [-78.13943453804507, -0.674581058334354],
              [-78.1377185948254, -0.675781849784357],
              [-78.13604757860679, -0.68233146893175],
              [-78.13484963700384, -0.685718931284271],
              [-78.13490261063195, -0.688130740106203],
              [-78.13644454554155, -0.690426878664198],
              [-78.13603953197973, -0.693589538373317],
              [-78.13850364387754, -0.693075392432036],
              [-78.144226639733, -0.689293645353814],
              [-78.14623259680282, -0.688436009020108],
              [-78.14863552080739, -0.688094027370255],
              [-78.15029161723834, -0.688782013983541],
              [-78.15109259190653, -0.690571717951229],
              [-78.15097055139618, -0.697402130855266],
              [-78.15268649461585, -0.698956973895747],
              [-78.15177152606437, -0.701477915097144],
              [-78.1500545770163, -0.702912896922101],
              [-78.1495365753996, -0.704810727440872],
              [-78.15131353887443, -0.705787722075911],
              [-78.15543357956513, -0.703386642090038],
              [-78.15795150328138, -0.70298062269984],
              [-78.15932462666086, -0.704246792994695],
              [-78.1593165800338, -0.711032111259613],
              [-78.16126252267682, -0.712353099201266],
              [-78.1628114983851, -0.710176151306541],
              [-78.16235351119516, -0.707239467707211],
              [-78.16287251864027, -0.705179698818995],
              [-78.16469558258262, -0.705578509772465],
              [-78.16652652551397, -0.707188338097808],
              [-78.16950260405821, -0.707370560673041],
              [-78.1716235608397, -0.705998610759991],
              [-78.1737366386322, -0.706117466147134],
              [-78.17580462178557, -0.704509649478496],
              [-78.17345450377105, -0.703469790569613],
              [-78.1716235608397, -0.701055802452856],
              [-78.17197459494501, -0.698182988955807],
              [-78.16950964485689, -0.696166135411829],
              [-78.17208858882832, -0.696005370508772],
              [-78.17282049661426, -0.694406271019488],
              [-78.16716757346943, -0.692433506286307],
              [-78.16762556065936, -0.690536681595916],
              [-78.17105862056515, -0.690547745708102],
              [-78.17363756453658, -0.690142899784348],
              [-78.17523951387295, -0.688941940696282],
              [-78.17736063829251, -0.686936821816801],
              [-78.17907758734057, -0.687967963546384],
              [-78.18073251030506, -0.690327636930533],
              [-78.18341857499917, -0.695849299471547],
              [-78.18524951793052, -0.697287131143582],
              [-78.18719462238322, -0.697406154168789],
              [-78.18936955862121, -0.699070129588733],
              [-78.19068149410745, -0.700336132245525],
              [-78.19281753831467, -0.701557040263197],
              [-78.1936035931952, -0.698503010019351],
              [-78.19314560600527, -0.696153394919008],
              [-78.1944575414915, -0.694663427809132],
              [-78.1964036517726, -0.696038227569261],
              [-78.19866961547916, -0.696907263291337],
              [-78.19921158933899, -0.694720424750813],
              [-78.19744149902475, -0.690300647202264],
              [-78.1954425827536, -0.684264168167772],
              [-78.19504561581884, -0.680703368057436],
              [-78.19532758304193, -0.678345538691985],
              [-78.19738751956824, -0.67897954584879],
              [-78.1995086439878, -0.680823396911023],
              [-78.20178952748202, -0.685135047908489],
              [-78.20322450930699, -0.687440071283902],
              [-78.20430761883634, -0.689735706927706],
              [-78.20728252391413, -0.692213732784808],
              [-78.20991561498012, -0.691817771678416],
              [-78.21266152646295, -0.690382789853459],
              [-78.21282949980275, -0.688484959334744],
              [-78.21203657176162, -0.684581004108111],
              [-78.21375251498128, -0.682856343709091],
              [-78.21363852109799, -0.681021377464219],
              [-78.21587363940084, -0.679929383117496],
              [-78.21615560662391, -0.677462756648765],
              [-78.21788060229903, -0.6756839491552],
              [-78.22010750633676, -0.674998980027055],
              [-78.22188564327806, -0.67311087251602],
              [-78.22245762435128, -0.675803978008787],
              [-78.22148163554462, -0.677818819895947],
              [-78.21987163958119, -0.683040242664958],
              [-78.22170258251253, -0.684080269211904],
              [-78.2232515582208, -0.683105286233626],
              [-78.22525751529061, -0.682357285193575],
              [-78.22983554317123, -0.682251337937316],
              [-78.23080449117923, -0.684095188999606],
              [-78.22937051518264, -0.685242168631191],
              [-78.22771458638975, -0.687933765381388],
              [-78.22696658534967, -0.689776778253304],
              [-78.22450264108993, -0.689196750553037],
              [-78.22484562856819, -0.691663544659889],
              [-78.22662359787142, -0.693905536123339],
              [-78.2270735384343, -0.695686355273665],
              [-78.23738059714067, -0.693062651939215],
              [-78.23812055155369, -0.691111680154279],
              [-78.24138664394808, -0.69117655608494],
              [-78.24321758687942, -0.690318584475108],
              [-78.24316360742291, -0.688475739241198],
              [-78.24401856154762, -0.685891933765959],
              [-78.24642165319025, -0.685441155012768],
              [-78.24819962249349, -0.68624715882288],
              [-78.24996954516966, -0.68745901438507],
              [-78.25209050195114, -0.687052994994872],
              [-78.25403560640385, -0.687062885640671],
              [-78.25403560640385, -0.689584832670448],
              [-78.25248763652397, -0.691535636817321],
              [-78.25293757708684, -0.69362256307187],
              [-78.25631749572646, -0.694130506404747],
              [-78.25820962655105, -0.694132518061508],
              [-78.2607805238954, -0.695109512696547],
              [-78.2643275776845, -0.699196193412604],
              [-78.26552551928745, -0.70345285912515],
              [-78.26809658426987, -0.704312674753737],
              [-78.2698745535731, -0.703346911868948],
              [-78.27095749546439, -0.701621916193801],
              [-78.27159150262116, -0.699552089021779],
              [-78.27302564625582, -0.697140112561726],
              [-78.2759475777055, -0.694277524986603],
              [-78.27766452675355, -0.69318553063988],
              [-78.27949563732297, -0.691234726493008],
              [-78.27852652167692, -0.68847775089796],
              [-78.27686355208536, -0.686813943116078],
              [-78.27598563154595, -0.684772111500649],
              [-78.27691652571349, -0.684004329169056],
              [-78.27870153581539, -0.684972103710606],
              [-78.28041060587445, -0.686526946751087],
              [-78.28413351199232, -0.690441630813837],
              [-78.28544662094501, -0.692801471836049],
              [-78.28784954494958, -0.693317461795971],
              [-78.28996262274208, -0.692975480146117],
              [-78.29179356567343, -0.693781316318166],
              [-78.29019161633705, -0.696310136508544],
              [-78.28813151217267, -0.697339266581366],
              [-78.28623954898615, -0.699579078749991],
              [-78.2844086060548, -0.700318865524935],
              [-78.28223450800712, -0.700199842499728],
              [-78.27937359681262, -0.700777020352973],
              [-78.2802885653641, -0.703018676540296],
              [-78.28205865567834, -0.703362669846911],
              [-78.28606453484768, -0.702732686003685],
              [-78.28783462516192, -0.703601721725704],
              [-78.28583554125271, -0.706356518025927],
              [-78.28428656554445, -0.707792338041202],
              [-78.28406561857653, -0.708786264120647],
              [-78.28560654765775, -0.710946280571022],
              [-78.28778064570542, -0.711183320792998],
              [-78.29143549076944, -0.714690979637169],
              [-78.29200763948073, -0.716579925338522],
              [-78.28749063185528, -0.716811601142524],
              [-78.28668261638842, -0.719857417121261],
              [-78.28273758983619, -0.719448715522049],
              [-78.27827456166723, -0.718514468593241],
              [-78.27667261233086, -0.720700301305328],
              [-78.27632157822553, -0.724604256531961],
              [-78.27311650608632, -0.725460216485033],
              [-78.27236951087464, -0.727936063047309],
              [-78.27107953597474, -0.729362662969095],
              [-78.2695696199353, -0.727699022825277],
              [-78.2695696199353, -0.72551218428481],
              [-78.27025559489182, -0.722241230386544],
              [-78.26993557382828, -0.719322484060058],
              [-78.26917265300054, -0.717984564673998],
              [-78.2652286322767, -0.720684375689302],
              [-78.26385450306883, -0.719418540670574],
              [-78.26539660561649, -0.717693544995427],
              [-78.2662205466994, -0.715300008722465],
              [-78.26602960694488, -0.714196950263499],
              [-78.26208458039265, -0.714131068504514],
              [-78.26019261720613, -0.711600236657375],
              [-78.25853752660358, -0.710234321714609],
              [-78.25813251304176, -0.712340023432319],
              [-78.2571025447786, -0.714751832254251],
              [-78.25504260825228, -0.713602840965905],
              [-78.25379152538315, -0.708948370128212],
              [-78.25235754938656, -0.70687854295619],
              [-78.2541355186898, -0.704349722765755],
              [-78.25225059630195, -0.701646894265309],
              [-78.24950451718105, -0.701356042224859],
              [-78.24870253668448, -0.699692904995231],
              [-78.24663555935949, -0.699538175062457],
              [-78.24572763160668, -0.700088027911306],
              [-78.24463664308834, -0.702147796799522],
              [-78.24462859646128, -0.704253833793359],
              [-78.24417865589841, -0.70525647705216],
              [-78.24211855173404, -0.705941446180304],
              [-78.23874651208341, -0.70449372386247],
              [-78.23610654785682, -0.704953722709149],
              [-78.23371150284123, -0.703975554607666],
              [-78.2312465527531, -0.705573480630562],
              [-78.22867565540874, -0.70459682127165],
              [-78.2256395624377, -0.70539863413012],
              [-78.22403761310132, -0.707168556806323],
              [-78.22197750893694, -0.706769410576726],
              [-78.21974155244378, -0.707681529281103],
              [-78.2174526223225, -0.709975153268203],
              [-78.21562151175308, -0.709856130242997],
              [-78.21356157522676, -0.710542105199522],
              [-78.21150264452884, -0.711462102892881],
              [-78.20909854705782, -0.712030228290644],
              [-78.2064056092031, -0.713574007218938],
              [-78.20314756343578, -0.711159180911864],
              [-78.20091261277099, -0.712358966533486],
              [-78.19982162425266, -0.715285759487017],
              [-78.19764752620499, -0.716838590870736],
              [-78.19793653422674, -0.719251405521106],
              [-78.19936363706273, -0.721150577144329],
              [-78.2011335597389, -0.722759399641347],
              [-78.2032466375314, -0.726384069853907],
              [-78.20856461982504, -0.730751544326552],
              [-78.21102856408478, -0.73184672379648],
              [-78.21869649675489, -0.731752678842781],
              [-78.22172554892725, -0.73226866880276],
              [-78.22282458407264, -0.734727248644447],
              [-78.2222365097453, -0.735775321818494],
              [-78.22029157293066, -0.736461129136956],
              [-78.21800264280938, -0.736803110786809],
              [-78.21617153223997, -0.737371236184572],
              [-78.21296662773882, -0.73961021016288],
              [-78.21050251584101, -0.740520820124686],
              [-78.20787864486853, -0.739768963409176],
              [-78.20621450181052, -0.741493791446203],
              [-78.20575752044897, -0.743960753191118],
              [-78.20380353117889, -0.745395567378011],
              [-78.20317857647755, -0.743615754056066],
              [-78.20060751149512, -0.740739923073932],
              [-78.19992053071023, -0.738607231627952],
              [-78.19854757496881, -0.736076399780814],
              [-78.19683062592075, -0.734223328625092],
              [-78.1952285089463, -0.73624638477736],
              [-78.1973415867388, -0.741190031274868],
              [-78.19751760670565, -0.743430681633811],
              [-78.1973415867388, -0.745843496284124],
              [-78.19676960566558, -0.748770456875718],
              [-78.19561759689208, -0.750776246307453],
              [-78.19430549376777, -0.752383224785774],
              [-78.19281753831467, -0.753819044801048],
              [-78.19218453698628, -0.755715869491439],
              [-78.19092557512815, -0.758010499306863],
              [-78.18903361194162, -0.757313795514278],
              [-78.18749251522236, -0.759435422848014],
              [-78.18703452803243, -0.754727810744214],
              [-78.18611955948094, -0.752026155710155],
              [-78.18400564349812, -0.747081335746259],
              [-78.18332654170221, -0.74391867603714],
              [-78.1819535859608, -0.74064705158662],
              [-78.17966465583952, -0.738341190020947],
              [-78.17812355912025, -0.739488169652532],
              [-78.1778335452701, -0.743797641355172],
              [-78.1774825111648, -0.745695471873944],
              [-78.17507958716023, -0.747527588271737],
              [-78.17388164555727, -0.749822218087161],
              [-78.17181349476583, -0.750570219127212],
              [-78.1698145784947, -0.750163193908634],
              [-78.17268353631626, -0.744824759771234],
              [-78.17005161871671, -0.738046314666974],
              [-78.1687395155924, -0.736663300641737],
              [-78.16673255269421, -0.737294290313343],
              [-78.16558859054777, -0.739064045351483],
              [-78.16449760202943, -0.743147876220405],
              [-78.16287251864027, -0.743995621908368],
              [-78.15872163254585, -0.739512141895659],
              [-78.15649456087007, -0.739854123545513],
              [-78.15476956519495, -0.741577945754159],
              [-78.15202365371212, -0.741802748397561],
              [-78.15157354551118, -0.739679109407064],
              [-78.15042857753635, -0.738178078185001],
              [-78.14894062208326, -0.740245893700319],
              [-78.14624751659048, -0.74489768232894],
              [-78.14768953921413, -0.745738554856302],
              [-78.14881858157291, -0.746046673617286],
              [-78.1517944924791, -0.747603528314528],
              [-78.1537395969318, -0.749447379376818],
              [-78.15613564777576, -0.75088604923917],
              [-78.15756962377235, -0.753986012312453],
              [-78.15773759711215, -0.756226830309458],
              [-78.15842457789704, -0.758296489843474],
              [-78.16024764183939, -0.762318462266933],
              [-78.16076664928451, -0.764677297460764],
              [-78.16116361621927, -0.767324134847968],
              [-78.16149855707046, -0.772836577295493],
              [-78.1631625324904, -0.773984395117395],
              [-78.16269649867341, -0.775936372730712],
              [-78.16275751892859, -0.778177190727718],
              [-78.16429861564787, -0.780996862958546],
              [-78.16589352418558, -0.785362493412492],
              [-78.16744249989384, -0.787550337781397],
              [-78.16768658091453, -0.78980926068931],
              [-78.16720562730987, -0.790766306394744],
              [-78.16532154311234, -0.790241096341276],
              [-78.16366561431946, -0.788062304427854],
              [-78.16286463965127, -0.786046456712256],
              [-78.1606905416036, -0.783858612343352],
              [-78.15817261788736, -0.782366968852898],
              [-78.15594454038317, -0.780638955692609],
              [-78.1543425910468, -0.778795104630376],
              [-78.15383163022875, -0.776382289980063],
              [-78.15366348925089, -0.774484459461291],
              [-78.14839965405181, -0.772529464362833],
              [-78.1460495360373, -0.771199759241824],
              [-78.14498151393369, -0.771162543591686],
              [-78.14279149026996, -0.772453524319985],
              [-78.1419294953466, -0.774060335160243],
              [-78.14204348922989, -0.776301320795312],
              [-78.1423876501746, -0.778542976982635],
              [-78.14198263661278, -0.78084598870123],
              [-78.14003753216008, -0.78049294293919],
              [-78.13941157163035, -0.778251119113804],
              [-78.1369476273706, -0.777671091413481],
              [-78.13526152372626, -0.77843820319282],
              [-78.13340761438019, -0.774796433897791],
              [-78.13294962719026, -0.77296146765292],
              [-78.13088952302587, -0.772553436605961],
              [-78.1292875736895, -0.771341581043714],
              [-78.12722763716317, -0.770852580812004],
              [-78.12665565608995, -0.774493344278653],
              [-78.12728161661968, -0.777086202209375],
              [-78.12847855239424, -0.778804157085801],
              [-78.1273345902478, -0.781558785747961],
              [-78.12367253674704, -0.782071758222799],
              [-78.12390153034201, -0.784547772423082],
              [-78.1245806321379, -0.786671411413636],
              [-78.12418349756508, -0.78850553946819],
              [-78.12097959889232, -0.790165156298428],
              [-78.12023159785225, -0.791827287699675],
              [-78.11799664718747, -0.792574953463657],
              [-78.11737051901969, -0.790280323648176],
              [-78.11556254250306, -0.788246538659791],
              [-78.11399864700712, -0.790323406630534],
              [-78.11325064596706, -0.795435864658145],
              [-78.11209863719354, -0.796989701870245],
              [-78.1098095394342, -0.797267813417875],
              [-78.10803961675802, -0.797727812264554],
              [-78.10861159783124, -0.799679454601744],
              [-78.11032050025224, -0.801234297642225],
              [-78.11209863719354, -0.8020404690904],
              [-78.11656149772443, -0.802856195908191],
              [-78.11844658775034, -0.803779211086749],
              [-78.12016253097002, -0.80504521374354],
              [-78.12205449415654, -0.80441405643387],
              [-78.12457258551085, -0.802808251421993],
              [-78.12777749001201, -0.803903095615794],
              [-78.12943258061456, -0.805061139359623],
              [-78.13131750300242, -0.804204341216177],
              [-78.12909663393498, -0.797480713758716],
              [-78.12870050519054, -0.795005034834503],
              [-78.12909663393498, -0.792936045852798],
              [-78.12999752088912, -0.792402956810292],
              [-78.13184355124619, -0.792594064202888],
              [-78.13493362367373, -0.793463938115281],
              [-78.13728357405019, -0.793754957793794],
              [-78.14196754918704, -0.796748638334691],
              [-78.1444314934468, -0.797328666035014],
              [-78.14637760372788, -0.796588879260014],
              [-78.14763656558601, -0.794232055722944],
              [-78.15009363668514, -0.794874780059047],
              [-78.1520385734998, -0.795851774694086],
              [-78.15358754920805, -0.797171756807415],
              [-78.1560436144788, -0.798845622873102],
              [-78.15804253074995, -0.799307633376543],
              [-78.15982050005319, -0.800971608796488],
              [-78.16067461598755, -0.803565137279463],
              [-78.1603316285093, -0.80660994742982],
              [-78.15860763866256, -0.809815690121241],
              [-78.1599195741488, -0.813440527971864],
              [-78.16323864017131, -0.814138572869012],
              [-78.16529857669762, -0.812359597737384],
              [-78.16735851322395, -0.813797597047483],
              [-78.16930361767665, -0.814088616725996],
              [-78.17136355420296, -0.812083665484579],
              [-78.17171458830828, -0.80828984846579],
              [-78.17405649205767, -0.808064878184325],
              [-78.17640661007219, -0.807380076694301],
              [-78.17668958312366, -0.812548693473218],
              [-78.17714656448521, -0.815196369050739],
              [-78.17811551249319, -0.817203164310854],
              [-78.1816555254836, -0.822725832680248],
              [-78.18308262831958, -0.828013304846309],
              [-78.18554657257933, -0.828421503531331],
              [-78.18714852191572, -0.827040501162855],
              [-78.18938464604695, -0.820328105455644],
              [-78.19299355828153, -0.818207148674162],
              [-78.19482450121288, -0.818100027951459],
              [-78.19716657260034, -0.818671170834364],
              [-78.20037063891117, -0.81908004007164],
              [-78.20203360850272, -0.818277053746669],
              [-78.20420854474071, -0.817827280821859],
              [-78.20535250688715, -0.819950919812356],
              [-78.20403252477387, -0.822768915662607],
              [-78.2036895372956, -0.824892554653104],
              [-78.20282754237223, -0.827240325734749],
              [-78.20150756025895, -0.829481479007882],
              [-78.19956161761591, -0.831657085798099],
              [-78.19807450035314, -0.833671089494999],
              [-78.19806662136415, -0.836083736507248],
              [-78.19898259574401, -0.838324722142318],
              [-78.19903556937214, -0.842174530274406],
              [-78.19931753659522, -0.845102161418254],
              [-78.20086651230348, -0.843955181786612],
              [-78.20264464924477, -0.841779239720267],
              [-78.20230065593813, -0.837125607072949],
              [-78.20247650826693, -0.834992747988906],
              [-78.20327765057318, -0.83287011482679],
              [-78.20533758709949, -0.831669993928983],
              [-78.20716853003084, -0.830867342880197],
              [-78.20740557025286, -0.828690227347352],
              [-78.20877852599428, -0.826043725236275],
              [-78.2134705477582, -0.825305614841966],
              [-78.21616365325097, -0.824620645713821],
              [-78.21819257673552, -0.823031939784471],
              [-78.21925355804045, -0.825436707807739],
              [-78.2190775380736, -0.827442497239531],
              [-78.21850555700037, -0.829393301386403],
              [-78.21758254182184, -0.831236146620256],
              [-78.21701056074862, -0.834045928205342],
              [-78.21506461810559, -0.835589874771699],
              [-78.21643857967538, -0.837831530959022],
              [-78.21809350263989, -0.838528570027734],
              [-78.21969561961433, -0.84020143026504],
              [-78.22043657985573, -0.84198241705343],
              [-78.22238151667038, -0.843131240703713],
              [-78.22375464004985, -0.841930449253709],
              [-78.22232854304225, -0.840085424724975],
              [-78.2212445953226, -0.83801576519096],
              [-78.22106958118414, -0.835602782902583],
              [-78.2234195315606, -0.834520008649406],
              [-78.22622663093665, -0.833601016784371],
              [-78.22513564241832, -0.831015367290377],
              [-78.22508250115214, -0.827853378133568],
              [-78.22634850380894, -0.825730744971452],
              [-78.2260584899588, -0.823380794594982],
              [-78.22503656832271, -0.82148296407621],
              [-78.22331961927465, -0.820098944222593],
              [-78.22191665631983, -0.818336229983174],
              [-78.22457858113276, -0.817750334950631],
              [-78.22990461005347, -0.817239206494548],
              [-78.2320175202079, -0.815695427566254],
              [-78.23368048979945, -0.81741338244268],
              [-78.2348175787853, -0.821787730075982],
              [-78.23607654064342, -0.823685728232761],
              [-78.23727464988444, -0.826560385748508],
              [-78.23715965017276, -0.829949356843542],
              [-78.23744161739585, -0.832588147603701],
              [-78.23878456592386, -0.833528094226665],
              [-78.24030252859035, -0.83150503807434],
              [-78.24150851682036, -0.829093396890471],
              [-78.24322563350648, -0.827829405890441],
              [-78.24534558445959, -0.825707610918641],
              [-78.24465960950307, -0.823176779071503],
              [-78.24156953707553, -0.818865128074037],
              [-78.24146258399091, -0.816741321445477],
              [-78.2422635586591, -0.814907361028986],
              [-78.24340852663394, -0.812721528316843],
              [-78.24564364493678, -0.812325734848514],
              [-78.24844353587612, -0.812101770395486],
              [-78.25010650546767, -0.810728814654055],
              [-78.24959554464962, -0.808252968091779],
              [-78.24816156865303, -0.807103976803432],
              [-78.24616265238188, -0.806469969646628],
              [-78.24078364983308, -0.806692928271332],
              [-78.2372355902156, -0.805877201453541],
              [-78.23506149216793, -0.80409520883677],
              [-78.23597763418586, -0.802316401343205],
              [-78.23849455207372, -0.801856234858462],
              [-78.24279061273127, -0.800314467586929],
              [-78.2455905036706, -0.799918506480537],
              [-78.24839760304667, -0.800725348480967],
              [-78.251197493986, -0.801992524604202],
              [-78.25229652913139, -0.802092436890177],
              [-78.25440256612521, -0.801144443640112],
              [-78.25331861840556, -0.799183748847497],
              [-78.24862659664164, -0.795673743070495],
              [-78.24502556339606, -0.793881859807982],
              [-78.24245449841362, -0.791929211642412],
              [-78.24343149304866, -0.78986022266065],
              [-78.24325564071988, -0.786534451115585],
              [-78.2444606231215, -0.784638632253632],
              [-78.2465205596478, -0.786311492490938],
              [-78.24983962567032, -0.786485500801007],
              [-78.25123554782645, -0.784443669185578],
              [-78.25338751764973, -0.785403732376153],
              [-78.2548146204857, -0.78660536201653],
              [-78.25589756237697, -0.78698640333522],
              [-78.25842252689189, -0.786671411413636],
              [-78.26040652337538, -0.786049474197398],
              [-78.26036863717299, -0.787937414070427],
              [-78.25876652019853, -0.790693383837038],
              [-78.26071162465125, -0.791272238070917],
              [-78.25927764865466, -0.793620847342822],
              [-78.25898763480451, -0.796267852368089],
              [-78.2591016286878, -0.800000481493669],
              [-78.26018557640747, -0.802585292797346],
              [-78.26155853214888, -0.801384501347286],
              [-78.26354956943102, -0.801711395571431],
              [-78.26416061017309, -0.803184431236843],
              [-78.26172650548867, -0.807068102257801],
              [-78.26023855003558, -0.808847747941684],
              [-78.25891856792228, -0.81079871972662],
              [-78.25782757940395, -0.812749523873435],
              [-78.25593561621743, -0.815558299630197],
              [-78.25524863543252, -0.817510277243457],
              [-78.25593561621743, -0.819697115783981],
              [-78.25639360340736, -0.8228597754931],
              [-78.25455461384895, -0.82767534650992],
              [-78.25334963144734, -0.830204334338362],
              [-78.25386863889244, -0.83313196548221],
              [-78.25621054264184, -0.83348618471075],
              [-78.25752264576614, -0.835492979970866],
              [-78.26010158973757, -0.833660863573073],
              [-78.26170353907395, -0.832803227239367],
              [-78.26611359361479, -0.832291260592967],
              [-78.2683404976525, -0.830974128326716],
              [-78.27002760712523, -0.830920148870234],
              [-78.27057662178373, -0.834083982045797],
              [-78.27223154474824, -0.83632563823312],
              [-78.27423062865743, -0.836218852786544],
              [-78.27777852063684, -0.837540678918572],
              [-78.27955648994008, -0.838517673553611],
              [-78.28401951810903, -0.836406942693998],
              [-78.28648363000684, -0.835775953022335],
              [-78.28791056520475, -0.836960651218305],
              [-78.28681957668643, -0.842788253225478],
              [-78.28881849295757, -0.844741236667176],
              [-78.29099259100524, -0.844860259692382],
              [-78.29385350219974, -0.843199134119516],
              [-78.2951736519511, -0.841077339147716],
              [-78.29672262765938, -0.839876547697656],
              [-78.30101751485047, -0.837294586241114],
              [-78.30490856194619, -0.834143828834499],
              [-78.30776964077877, -0.834488827969551],
              [-78.31069157222845, -0.833803858841407],
              [-78.31458261932417, -0.833409071201459],
              [-78.3165285619672, -0.83439595648224],
              [-78.31864163975969, -0.835770756242368],
              [-78.32122058373112, -0.836007796464401],
              [-78.32286058690795, -0.837625839054908],
              [-78.32132753681573, -0.839685607943125],
              [-78.31880961309949, -0.840370409433206],
              [-78.31742056410395, -0.841797344631118],
              [-78.3221816527501, -0.843138281502377],
              [-78.32258549284548, -0.845027059565723],
              [-78.32469957646636, -0.846527923149722],
              [-78.32286863353501, -0.84733208294108],
              [-78.32046554189237, -0.84778185586589],
              [-78.31616964887289, -0.847778838380748],
              [-78.31394157136872, -0.848228946581685],
              [-78.31113463963071, -0.850413773465448],
              [-78.31233258123366, -0.852312442174536],
              [-78.31456753189845, -0.853118613622712],
              [-78.31617752786188, -0.854483355098978],
              [-78.31439251775998, -0.855702251459888],
              [-78.31249954874507, -0.855809372182591],
              [-78.30866952190452, -0.856493167844292],
              [-78.30574759045484, -0.859022155672733],
              [-78.30397062697999, -0.858153287588721],
              [-78.30220053666575, -0.858893074363721],
              [-78.30248250388884, -0.860962063345426],
              [-78.30184950256044, -0.86303071705106],
              [-78.3000185596291, -0.863255854970589],
              [-78.2990496116211, -0.861528847638681],
              [-78.29733249493498, -0.860552020641705],
              [-78.29527255840867, -0.861012019488442],
              [-78.29321262188235, -0.862039808456757],
              [-78.29206849209784, -0.863936800785154],
              [-78.290786563825, -0.863999665059055],
              [-78.2902905227946, -0.861748788778243],
              [-78.2894365744983, -0.859679129244228],
              [-78.28766665182212, -0.858412120759056],
              [-78.28621658257143, -0.858339030563286],
              [-78.28474455273438, -0.860705912384162],
              [-78.28428656554445, -0.862656884169098],
              [-78.28234062290143, -0.86248388168741],
              [-78.2818226212847, -0.860695015910039],
              [-78.27999151071529, -0.860638857158676],
              [-78.27822158803912, -0.859942153366092],
              [-78.27873958965584, -0.858054213493119],
              [-78.27988455763067, -0.856328211989592],
              [-78.28188364153988, -0.85483958598428],
              [-78.28269148936867, -0.852941420189381],
              [-78.28223450800712, -0.850763634104339],
              [-78.28048654591728, -0.850012615579089],
              [-78.27902960350598, -0.852080766370534],
              [-78.2784495758057, -0.854258552455633],
              [-78.27678660621415, -0.858278177946147],
              [-78.26894349176752, -0.864643730499665],
              [-78.2663875142108, -0.86746960533884],
              [-78.26511363256502, -0.8656705136396],
              [-78.26585358697804, -0.863890867955718],
              [-78.26699855495288, -0.862049028550246],
              [-78.2695235194678, -0.858842112392324],
              [-78.2727886060338, -0.85401950057684],
              [-78.27513855641025, -0.852529533466964],
              [-78.27723654677702, -0.850245632487599],
              [-78.2741625676036, -0.849944889801407],
              [-78.27159150262116, -0.850051675247926],
              [-78.26907357890492, -0.848152000710513],
              [-78.26714356187794, -0.85024663831598],
              [-78.26529652569248, -0.848890781657076],
              [-78.26467157099114, -0.846711989743596],
              [-78.26284062805979, -0.845554113637888],
              [-78.26215364727489, -0.843665167936479],
              [-78.26051364409805, -0.843230147161307],
              [-78.2587205873691, -0.845434084784301],
              [-78.2580875860407, -0.847276930018154],
              [-78.25677464472606, -0.847772971048528],
              [-78.25551551522989, -0.844971068452423],
              [-78.25499751361316, -0.842151396221595],
              [-78.25489056052854, -0.839801613483189],
              [-78.2535786250423, -0.837821807951343],
              [-78.25082349346596, -0.838352717698967],
              [-78.24819157586643, -0.840474345032703],
              [-78.24675759986984, -0.842832342036218],
              [-78.24343853384732, -0.845694091421024],
              [-78.24183658451095, -0.847590916111415],
              [-78.24079856962074, -0.853273511193493],
              [-78.2409135693324, -0.855342500175254],
              [-78.23976860135758, -0.856950316843893],
              [-78.23764764457609, -0.85694042619815],
              [-78.23570254012338, -0.856369450953252],
              [-78.23364260359708, -0.856132243093214],
              [-78.23233050047277, -0.857622377841096],
              [-78.23197963400551, -0.85963705209025],
              [-78.22998055009631, -0.860838011178316],
              [-78.22769161997503, -0.860312130572595],
              [-78.22579948915045, -0.859678123415847],
              [-78.22391557259097, -0.859505120934159],
              [-78.22288560432781, -0.861166917059222],
              [-78.2248224945154, -0.864330750234785],
              [-78.2248224945154, -0.866454556863346],
              [-78.22659258482963, -0.867151595932057],
              [-78.23020149706421, -0.864343658365669],
              [-78.2342685641268, -0.864057835467122],
              [-78.23564151986821, -0.865386702397814],
              [-78.2357555137515, -0.867564488482913],
              [-78.23672462939754, -0.869580336198453],
              [-78.23718261658749, -0.871876307118441],
              [-78.23843353181854, -0.873205174049076],
              [-78.2418746383514, -0.870162375555481],
              [-78.24393457487771, -0.870399415777513],
              [-78.24530753061913, -0.871602051246271],
              [-78.24575763882007, -0.873617060771494],
              [-78.24713160038988, -0.875054892443586],
              [-78.251197493986, -0.875690911257095],
              [-78.25296758430024, -0.876441929782345],
              [-78.25205261574875, -0.878049578812863],
              [-78.24644461960497, -0.880449485332292],
              [-78.24495649651382, -0.88148733258447],
              [-78.24283553973233, -0.881828308405943],
              [-78.24054761543943, -0.881818417760201],
              [-78.23854065254123, -0.882277410778499],
              [-78.23648054837685, -0.88318835601649],
              [-78.23516861289062, -0.88474219322859],
              [-78.2355045595702, -0.886865161666833],
              [-78.23527556597523, -0.888934821200849],
              [-78.23321562944892, -0.888697780978816],
              [-78.23143749250762, -0.889907792522365],
              [-78.23109450502936, -0.891741585300792],
              [-78.22914856238634, -0.891848706023438],
              [-78.22663851765908, -0.888801884216377],
              [-78.22268661794625, -0.88811188594633],
              [-78.22085550737683, -0.887359861592699],
              [-78.21897159081736, -0.886320002683817],
              [-78.21685063403588, -0.886489987680363],
              [-78.21501952346647, -0.8869400958813],
              [-78.2134165683017, -0.885855142333241],
              [-78.21210463281545, -0.884408258205781],
              [-78.2113034905092, -0.882519480142435],
              [-78.20958754728953, -0.881478447767108],
              [-78.2058104940771, -0.881927382501601],
              [-78.20294958288258, -0.88479902253215],
              [-78.20094261998439, -0.884336173838392],
              [-78.19940152326511, -0.882952321622838],
              [-78.19728861311069, -0.882833466235695],
              [-78.19596863099738, -0.884387135809732],
              [-78.19516765632919, -0.88616611094136],
              [-78.19384750657782, -0.887538899044728],
              [-78.1920695372746, -0.887997892063026],
              [-78.19024664097032, -0.886668019303954],
              [-78.18858350374069, -0.884941011972103],
              [-78.18681358106451, -0.885282993621956],
              [-78.1870955482876, -0.887469999800544],
              [-78.18789652295578, -0.889367830319259],
              [-78.18886563860184, -0.891374625579431],
              [-78.1921235167311, -0.89660945939346],
              [-78.19075056098968, -0.898162290777236],
              [-78.18885759197478, -0.897519063526943],
              [-78.18605049259871, -0.900164056895449],
              [-78.18416557021087, -0.900153998611643],
              [-78.18222063339623, -0.901073828666938],
              [-78.18302160806441, -0.902854815455328],
              [-78.18433354355065, -0.904518623237209],
              [-78.18490552462387, -0.906479485667887],
              [-78.1867906146498, -0.907285657116063],
              [-78.18891157143128, -0.90774749998144],
              [-78.19084963508531, -0.908670515159997],
              [-78.19136864253042, -0.911373343660443],
              [-78.19050564177867, -0.913261115895409],
              [-78.18884250454904, -0.914578918713858],
              [-78.18672959439462, -0.915380899210447],
              [-78.18603557281105, -0.917449720554146],
              [-78.18460863761312, -0.91899349948244],
              [-78.18248751319358, -0.918702479803869],
              [-78.17710851064476, -0.916285809478097],
              [-78.17499560049032, -0.917196922354094],
              [-78.17419462582214, -0.91897673567604],
              [-78.17224164238044, -0.92190252280119],
              [-78.17081453954445, -0.919777710344249],
              [-78.17087555979963, -0.914609093565332],
              [-78.16870162939003, -0.91437305917168],
              [-78.16542061720797, -0.918092780166319],
              [-78.1640096076261, -0.914820988077793],
              [-78.16435259510436, -0.912987195299365],
              [-78.16504661668793, -0.91074637730236],
              [-78.16349764097967, -0.908793393860662],
              [-78.16109454933704, -0.90901735831369],
              [-78.15766148943123, -0.907976325938364],
              [-78.15469362515212, -0.906365491784584],
              [-78.15280149432753, -0.906128619200615],
              [-78.15148955884129, -0.904618535523127],
              [-78.15280954095458, -0.901186816721861],
              [-78.15589860755374, -0.896545254015109],
              [-78.15636464137073, -0.894593444039856],
              [-78.15441853108963, -0.893896404971144],
              [-78.15252656790311, -0.894129421879597],
              [-78.15018449651565, -0.893486529905488],
              [-78.15144362601184, -0.891824733780368],
              [-78.14863552080739, -0.891822722123607],
              [-78.14646159039778, -0.892968528288748],
              [-78.1448596410614, -0.894801482876858],
              [-78.14405849875514, -0.896635443293349],
              [-78.14257054330206, -0.897899099017252],
              [-78.14056358040385, -0.897897087360491],
              [-78.13850364387754, -0.898121051813575],
              [-78.13684855327497, -0.899150181886398],
              [-78.13690152690309, -0.900992859482187],
              [-78.1389006108123, -0.901907995671763],
              [-78.14187652171849, -0.901684031218679],
              [-78.14393662588286, -0.902950704427724],
              [-78.1452485613691, -0.905824523753154],
              [-78.14788064660671, -0.905320603733799],
              [-78.15074155780121, -0.906298604197218],
              [-78.15177152606437, -0.908079423347488],
              [-78.15406062382371, -0.90934643183266],
              [-78.1564635478283, -0.910044309091745],
              [-78.15789065066427, -0.91223198582253],
              [-78.15897359255554, -0.916307770064464],
              [-78.15897359255554, -0.918322779589687],
              [-78.15714264962419, -0.919640414770072],
              [-78.15479253160967, -0.918364521467538],
              [-78.15353356975154, -0.920432672258983],
              [-78.15284759479503, -0.922555473059163],
              [-78.1515886329369, -0.923991125436373],
              [-78.15278657453986, -0.925772112224763],
              [-78.153579502581, -0.928880960115464],
              [-78.15140557217138, -0.931345742565497],
              [-78.15128353166104, -0.93720033430094],
              [-78.1492846153899, -0.938400119922562],
              [-78.14659150989712, -0.938741095744092],
              [-78.14430257977584, -0.941089034463801],
              [-78.14235663713282, -0.941548027482099],
              [-78.13783258870869, -0.941887997475192],
              [-78.13560451120452, -0.942337770400002],
              [-78.13411756157981, -0.943889763593404],
              [-78.13296555280631, -0.945723724009895],
              [-78.13113360404657, -0.94658052215334],
              [-78.13079061656832, -0.948594525850183],
              [-78.1315915912365, -0.951297354350629],
              [-78.1338805213578, -0.95037735665727],
              [-78.13536864444895, -0.948887389547394],
              [-78.13645963296727, -0.946476418915779],
              [-78.13811455593178, -0.945393644662545],
              [-78.14086164088106, -0.945449635775844],
              [-78.14446250648858, -0.948561333513567],
              [-78.14938352184747, -0.948917229122742],
              [-78.15224460068004, -0.951043215046127],
              [-78.15298455509306, -0.954323891952072],
              [-78.15561664033065, -0.955472883240418],
              [-78.15773055631347, -0.957489904422459],
              [-78.15858450460978, -0.96127651300452],
              [-78.15858450460978, -0.96358019527537],
              [-78.15818049687634, -0.965702996075549],
              [-78.1589816391826, -0.967998966995538],
              [-78.16075860265745, -0.966166180045491],
              [-78.16121658984738, -0.964097358701792],
              [-78.16270454530047, -0.962382421310508],
              [-78.164596508487, -0.961868275369227],
              [-78.16636659880123, -0.962439250614068],
              [-78.16899851640078, -0.961872298682749],
              [-78.16912055691112, -0.959405504575955],
              [-78.16699960012963, -0.958193816651772],
              [-78.16471050237028, -0.957956608791676],
              [-78.16207858477074, -0.957384627718454],
              [-78.16071350801832, -0.955540944294285],
              [-78.16236859862089, -0.953590140147412],
              [-78.16271963272621, -0.951756179730921],
              [-78.16128548909155, -0.95042731280023],
              [-78.16049155522202, -0.948357317990087],
              [-78.16054553467853, -0.946351696196416],
              [-78.16266649146002, -0.944573559255105],
              [-78.16455057565754, -0.945153586955371],
              [-78.16644253884407, -0.946473569068701],
              [-78.17095954646953, -0.947227437440972],
              [-78.1731336445172, -0.947870497053202],
              [-78.17525460129869, -0.947294660304522],
              [-78.17742953753668, -0.947187371943755],
              [-78.17914564839441, -0.948336363232158],
              [-78.18120558492073, -0.948627382910672],
              [-78.18315152756375, -0.947942581420591],
              [-78.18326552144704, -0.945701763423585],
              [-78.18286855451228, -0.943921614825513],
              [-78.18206757984409, -0.942022778478417],
              [-78.18103761158093, -0.940125115597709],
              [-78.17646763032737, -0.936037261415208],
              [-78.1755295277231, -0.934699509667212],
              [-78.17743657833535, -0.933462676033457],
              [-78.17938252097838, -0.933464687690218],
              [-78.18115965209128, -0.932661533727185],
              [-78.18127465180297, -0.930827573310694],
              [-78.18333458832927, -0.930313762645596],
              [-78.18842357702795, -0.933191437646428],
              [-78.19031554021447, -0.934458613769721],
              [-78.19203165107221, -0.935155485200369],
              [-78.19494654172323, -0.937055327375845],
              [-78.19563251667974, -0.938772276423947],
              [-78.19677765229264, -0.940734144683006],
              [-78.197173613399, -0.942974962680012],
              [-78.197173613399, -0.94480976128682],
              [-78.19819654086349, -0.946590580437146],
              [-78.19997350433835, -0.946022455039383],
              [-78.20157662714116, -0.944993827880751],
              [-78.20260659540433, -0.943385843574049],
              [-78.20461255247415, -0.942754853902443],
              [-78.2049555399524, -0.940686032558745],
              [-78.20507053966408, -0.938563064120444],
              [-78.20799263875182, -0.935927458483548],
              [-78.20702352310578, -0.934317462520085],
              [-78.20519258017443, -0.933394614979591],
              [-78.20296450267026, -0.93293260447615],
              [-78.20153052667366, -0.931322776150807],
              [-78.20050055841051, -0.92976793311027],
              [-78.19976060399749, -0.927870102591555],
              [-78.19953161040252, -0.925917957340175],
              [-78.20062259892086, -0.923967153193303],
              [-78.20268253544717, -0.924601327988114],
              [-78.20417049090025, -0.925930194918806],
              [-78.20594058121449, -0.926962007200643],
              [-78.20806153799599, -0.927487887806365],
              [-78.21012164216036, -0.927715037382654],
              [-78.21183758538004, -0.926686074947895],
              [-78.21149459790178, -0.924906093987886],
              [-78.20967052813104, -0.921109594760082],
              [-78.20864055986789, -0.919554584081482],
              [-78.20830561901668, -0.911576018079415],
              [-78.20922058756817, -0.909733340483626],
              [-78.21076151664937, -0.91100822795778],
              [-78.21156349714595, -0.913015190856015],
              [-78.21327960800369, -0.914055049764897],
              [-78.21453856986182, -0.91606285085345],
              [-78.21533954453, -0.917788684718857],
              [-78.21722463455592, -0.918657552802813],
              [-78.21876556363712, -0.919634715075915],
              [-78.22019953963371, -0.921416540054622],
              [-78.22202260357606, -0.922917403638621],
              [-78.22340360594454, -0.921653412638591],
              [-78.22374759925118, -0.919412594641585],
              [-78.22506758136448, -0.917922795169773],
              [-78.22483858776951, -0.915799826731529],
              [-78.22426560086791, -0.913846843289832],
              [-78.2234116525716, -0.912003159865662],
              [-78.22101560172763, -0.908938400785701],
              [-78.22427364749497, -0.908561382780533],
              [-78.22610459042632, -0.90896052901013],
              [-78.22793553335765, -0.908853240649364],
              [-78.22999563752204, -0.909830402922466],
              [-78.2310795852417, -0.914024036723106],
              [-78.2332456366623, -0.919601522739299],
              [-78.23301664306734, -0.921724323539536],
              [-78.23307062252384, -0.923793144883177],
              [-78.23507657959365, -0.923515368611731],
              [-78.23638952090829, -0.922196392326782],
              [-78.2382206314777, -0.921276562271487],
              [-78.24005157440905, -0.920934580621577],
              [-78.24085958987591, -0.919272616858393],
              [-78.24475063697163, -0.919853650387097],
              [-78.24640656576452, -0.921120323596142],
              [-78.2474895076558, -0.922675501912749],
              [-78.24909263045862, -0.924113165946721],
              [-78.25092357338997, -0.922913380325099],
              [-78.25298350991629, -0.922860239058934],
              [-78.25476064102921, -0.923955250890742],
              [-78.25675955730034, -0.923721228153909],
              [-78.25618757622712, -0.921887267737418],
              [-78.25361651124469, -0.918893587196465],
              [-78.25258654298153, -0.917167920969121],
              [-78.25173158885684, -0.915268749345842],
              [-78.24990064592549, -0.914237942892385],
              [-78.24818453506775, -0.912853923038767],
              [-78.24893153027944, -0.911128256811367],
              [-78.25099163444382, -0.911311149938854],
              [-78.25293757708684, -0.91217029501513],
              [-78.25470749976301, -0.913436968224175],
              [-78.25722458528895, -0.91436082159305],
              [-78.25917052793197, -0.914768852640009],
              [-78.2608876446181, -0.915348880340275],
              [-78.26283258143273, -0.915404033263201],
              [-78.26306962165475, -0.913398076193403],
              [-78.26238264086986, -0.911328249021324],
              [-78.26296250093208, -0.903060842635568],
              [-78.26496158484127, -0.90237570586936],
              [-78.26593757364793, -0.904092654917406],
              [-78.26690652165591, -0.908178497443146],
              [-78.26998854745639, -0.913069170312497],
              [-78.27165252287634, -0.913874838846482],
              [-78.27348363344575, -0.913759168582544],
              [-78.27685550545831, -0.91187307272827],
              [-78.27891560962269, -0.911992095753419],
              [-78.27989159842934, -0.913601085888502],
              [-78.27954861095108, -0.915435046304992],
              [-78.27873958965584, -0.917385850451865],
              [-78.27736663391443, -0.919228528047654],
              [-78.27536050920655, -0.920139640923708],
              [-78.2746815750487, -0.921548303572649],
              [-78.27661863287435, -0.922896448880635],
              [-78.28085350563866, -0.921644360183166],
              [-78.2852625543511, -0.921710577218278],
              [-78.28697950339917, -0.920961570349846],
              [-78.28829965315053, -0.919417623783488],
              [-78.28887163422375, -0.917692628108398],
              [-78.29064960352699, -0.916663833311645],
              [-78.29099259100524, -0.914593838501503],
              [-78.29059562407048, -0.91264202852625],
              [-78.28939064166886, -0.910861377014044],
              [-78.28659058309147, -0.907867528835027],
              [-78.28430953195917, -0.899878066358838],
              [-78.28522450051065, -0.897927094573902],
              [-78.28728460467504, -0.898904256847004],
              [-78.28797158545993, -0.900919936924481],
              [-78.29008449561437, -0.90420178729687],
              [-78.29145862482223, -0.905747745519989],
              [-78.29489151708997, -0.908000298181435],
              [-78.29849255033555, -0.909494456242896],
              [-78.29991965317153, -0.910878140820387],
              [-78.3007816480949, -0.912722159520683],
              [-78.30094962143468, -0.914556958127548],
              [-78.30175059610288, -0.916680932394172],
              [-78.30335254543925, -0.915479973306049],
              [-78.3057556370819, -0.912382189527591],
              [-78.30758658001325, -0.91140804473963],
              [-78.30867756853158, -0.909863259982956],
              [-78.30942556957164, -0.90808344666101],
              [-78.31068453142977, -0.906593647189254],
              [-78.31280565584933, -0.906423662192708],
              [-78.31463659878068, -0.907346342095082],
              [-78.31652051534014, -0.907113492824692],
              [-78.31864163975969, -0.90614756230184],
              [-78.32052656214755, -0.905634589827002],
              [-78.32253251921736, -0.905400734728175],
              [-78.32470661726502, -0.906098444349198],
              [-78.32659153965288, -0.906506475396156],
              [-78.32797254202136, -0.905070823018889],
              [-78.32882649031765, -0.90346267107418],
              [-78.33042961312049, -0.902198847712214],
              [-78.33226055605184, -0.901919730336203],
              [-78.3345486479828, -0.901921909631028],
              [-78.33638763754121, -0.900721118180968],
              [-78.33833358018423, -0.901182961046402],
              [-78.33809653996221, -0.905430741941586],
              [-78.33941652207551, -0.906931605525585],
              [-78.34130060627304, -0.906878631897484],
              [-78.34313154920439, -0.906473618335667],
              [-78.34490951850762, -0.90549863535739],
              [-78.34605465412051, -0.904008835885577],
              [-78.34788559705186, -0.903557721856259],
              [-78.35057065591758, -0.905683540141581],
              [-78.35234862522081, -0.906145550645078],
              [-78.35423254178028, -0.906264406032221],
              [-78.35841360272615, -0.905933656132618],
              [-78.3599625784344, -0.904723812227132],
              [-78.36081652673072, -0.903061848463949],
              [-78.36231252881086, -0.901796851635538],
              [-78.36442560660336, -0.901519075364035],
              [-78.36522658127156, -0.899847053317046],
              [-78.36717252391458, -0.900545098214138],
              [-78.36854564729406, -0.902153753073094],
              [-78.36957561555721, -0.904224753711617],
              [-78.37174954596682, -0.904225759539997],
              [-78.37364150915334, -0.904751807783782],
              [-78.37580856640234, -0.908431463281204],
              [-78.37763950933369, -0.909237299453252],
              [-78.3794706199031, -0.909645162862148],
              [-78.38130156283445, -0.91062232513525],
              [-78.38364464005029, -0.914012972610919],
              [-78.38558957686494, -0.91430382465137],
              [-78.38764951339125, -0.913509890781881],
              [-78.38925950935469, -0.912589054898149],
              [-78.39114359355221, -0.912762057379837],
              [-78.39119757300872, -0.914895084101943],
              [-78.38896161651556, -0.918273661637045],
              [-78.38524658938668, -0.919418629611869],
              [-78.38387262781688, -0.920736432430317],
              [-78.38312563260519, -0.922751441955597],
              [-78.38335462620016, -0.92510105705594],
              [-78.38375058730654, -0.927171051866083],
              [-78.38237763156512, -0.928489022322594],
              [-78.3812335017806, -0.926762014990686],
              [-78.3769986290163, -0.922502499431062],
              [-78.37534353841373, -0.921471525339484],
              [-78.37345157522721, -0.920548510160984],
              [-78.37059753719332, -0.917780638091813],
              [-78.36899558785693, -0.916514970711148],
              [-78.36739363852055, -0.915654819806434],
              [-78.36561550157926, -0.914848983634442],
              [-78.36355556505293, -0.914666928697272],
              [-78.36195361571656, -0.915812902500477],
              [-78.3601766522417, -0.916326880803695],
              [-78.35834453584391, -0.917129699490602],
              [-78.35783357502586, -0.919253506119162],
              [-78.35908549608531, -0.921097357181395],
              [-78.36051963971997, -0.922418345123106],
              [-78.36103060053802, -0.926214006160592],
              [-78.36103060053802, -0.928563956537062],
              [-78.36074058668788, -0.932937633618053],
              [-78.3613665472176, -0.936959438403449],
              [-78.36135850059055, -0.939255241685316],
              [-78.36016860561466, -0.940338183576614],
              [-78.35895557658597, -0.938096192113164],
              [-78.35839063631143, -0.935972385484604],
              [-78.35690351904866, -0.93189576305241],
              [-78.3555376041059, -0.926318109398153],
              [-78.35416464836449, -0.924763098719552],
              [-78.35244753167838, -0.923551243157362],
              [-78.35164655701018, -0.92154444789719],
              [-78.35090660259716, -0.917747781031323],
              [-78.34976163462233, -0.915677953859245],
              [-78.34781653016962, -0.916480772546151],
              [-78.3474125224362, -0.918314565324579],
              [-78.34747354269138, -0.920329574849802],
              [-78.34620653420619, -0.921766400693514],
              [-78.34449763178519, -0.920671556499713],
              [-78.3430636557886, -0.919404548014541],
              [-78.34094957216772, -0.918942537511043],
              [-78.33934762283134, -0.920079458858822],
              [-78.3389436150979, -0.922094468384103],
              [-78.33814264042971, -0.923757270337603],
              [-78.33573150216002, -0.924848258855889],
              [-78.33293161122069, -0.927421838409316],
              [-78.3323515835204, -0.929436847934539],
              [-78.33269457099867, -0.933684628829781],
              [-78.3332065376451, -0.935582291710432],
              [-78.33423650590825, -0.937426310410785],
              [-78.33440364105773, -0.939550117039346],
              [-78.3324055629769, -0.938970256977086],
              [-78.33074158755697, -0.938219238451893],
              [-78.32920049083769, -0.937242243816854],
              [-78.32719453376788, -0.936544198919762],
              [-78.32593557190975, -0.938044224313444],
              [-78.3257065783148, -0.939932164186416],
              [-78.32472958367975, -0.941602845128898],
              [-78.32347062182163, -0.942921989051911],
              [-78.3209535362957, -0.946416572127021],
              [-78.31837459232428, -0.949171368427244],
              [-78.31642965550964, -0.949802358098907],
              [-78.3144754986015, -0.950144172110697],
              [-78.31242360870223, -0.950025149085491],
              [-78.31024951065456, -0.949381251282944],
              [-78.30927251601952, -0.947600599770738],
              [-78.3078455808216, -0.945873592438829],
              [-78.30595361763508, -0.94581776896365],
              [-78.30435150066063, -0.946792416665801],
              [-78.3032605121423, -0.948797367907218],
              [-78.30188755640089, -0.950170156010586],
              [-78.301200575616, -0.948335357403778],
              [-78.30029264786317, -0.946554538253451],
              [-78.29903452419538, -0.945053507031389],
              [-78.29737054877543, -0.94436585569423],
              [-78.2954256119608, -0.944471802950488],
              [-78.29370849527467, -0.945103630812412],
              [-78.29187755234332, -0.944866590590379],
              [-78.28993965632736, -0.941078808541874],
              [-78.28833753935291, -0.939459927761106],
              [-78.28610258868814, -0.938943267248874],
              [-78.28398850506726, -0.939683054023817],
              [-78.28249350881549, -0.941354070242426],
              [-78.28255452907067, -0.943187863020853],
              [-78.28392765245015, -0.945312675477794],
              [-78.28540756127617, -0.947102547083546],
              [-78.28684254310114, -0.948251538371949],
              [-78.28844449243753, -0.949229203559241],
              [-78.29038959689024, -0.949808225431127],
              [-78.29181653208815, -0.950903237262992],
              [-78.29307549394628, -0.952458080303472],
              [-78.29387663625253, -0.954582892760413],
              [-78.29576155864038, -0.958261710067575],
              [-78.29689764179784, -0.960043702684288],
              [-78.29959057965254, -0.963614393440366],
              [-78.30067452737221, -0.965567209244],
              [-78.3015894959237, -0.967755053612905],
              [-78.30267361128142, -0.969473008489331],
              [-78.30335958623793, -0.971199680545112],
              [-78.30204060995301, -0.972634494732006],
              [-78.29998050578864, -0.972000655213265],
              [-78.29832457699575, -0.970788632012955],
              [-78.29586063273601, -0.970443632877959],
              [-78.2938615488268, -0.970893741078896],
              [-78.29196958564027, -0.970033757812303],
              [-78.28882553375624, -0.964681074439454],
              [-78.28791861183181, -0.962728426273884],
              [-78.28688864356866, -0.96117341559534],
              [-78.28511050662736, -0.959681604466766],
              [-78.28316456398434, -0.958929580113193],
              [-78.28128064742488, -0.958810557087986],
              [-78.27950251048358, -0.959152706375903],
              [-78.27057662178373, -0.963962242422497],
              [-78.26828752402439, -0.963671222743983],
              [-78.26650955472115, -0.962458528991419],
              [-78.26576960030815, -0.960334554724795],
              [-78.26605961415828, -0.958382744749542],
              [-78.26766156349467, -0.957344897497364],
              [-78.2693255389146, -0.955918968127833],
              [-78.27024050746608, -0.954193134262425],
              [-78.27058450077273, -0.952188183020951],
              [-78.27035550717777, -0.949946359195565],
              [-78.26855457145982, -0.948598549163705],
              [-78.26605961415828, -0.952690259021665],
              [-78.26422850358887, -0.952978093576974],
              [-78.26211559343444, -0.952280216317945],
              [-78.25994149538677, -0.951935049544829],
              [-78.25810250582836, -0.955494005636467],
              [-78.25450164022084, -0.956177801298111],
              [-78.25244153605647, -0.957088746536101],
              [-78.25231949554612, -0.959040556511354],
              [-78.25415060611553, -0.959502567014795],
              [-78.25798750611669, -0.959397625586973],
              [-78.25970462280281, -0.960437652133919],
              [-78.26078756469408, -0.962219477112626],
              [-78.26129953134051, -0.963999290434572],
              [-78.26244349348697, -0.965671983033815],
              [-78.26376364323833, -0.967110149981977],
              [-78.26713551525089, -0.969300006007643],
              [-78.2690206052768, -0.970222853548137],
              [-78.27102656234662, -0.970910672523303],
              [-78.27519957666543, -0.970977727748789],
              [-78.27720653956362, -0.970807742752243],
              [-78.27949563732297, -0.970981583424248],
              [-78.28086859306438, -0.972193774262621],
              [-78.28212755492251, -0.973576453011731],
              [-78.28407249173715, -0.974265613091461],
              [-78.28607157564635, -0.974555626941594],
              [-78.2880704919175, -0.975135487003797],
              [-78.28955861500864, -0.9762844782922],
              [-78.29333449475463, -0.981176995180249],
              [-78.29985863291635, -0.98158904954073],
              [-78.30185654335911, -0.98233990042786],
              [-78.30363451266234, -0.983778905566396],
              [-78.30523662963678, -0.984646767821971],
              [-78.30900563622217, -0.98620446070953],
              [-78.31066860581372, -0.987299640179458],
              [-78.31272854234004, -0.987707503588354],
              [-78.31455965290945, -0.9873655219385],
              [-78.3163905958408, -0.986336727141804],
              [-78.31799355100557, -0.985018589047172],
              [-78.31983153473558, -0.983809918608131],
              [-78.32148763116653, -0.982952785188616],
              [-78.32354756769286, -0.981462818078796],
              [-78.32527155753958, -0.980659999391889],
              [-78.326644513281, -0.978998035628706],
              [-78.32749963504375, -0.977282260047105],
              [-78.32888063741223, -0.976018269047074],
              [-78.33087955368336, -0.97533329991893],
              [-78.33248854381841, -0.9739516269982],
              [-78.33334349794312, -0.972289663235017],
              [-78.33551759599078, -0.972408686260223],
              [-78.33615160314757, -0.974135358316005],
              [-78.33637255011547, -0.978328153926327],
              [-78.33797449945186, -0.980002187630078],
              [-78.34003460361623, -0.979886014451949],
              [-78.34186554654758, -0.981035005740296],
              [-78.34300950869404, -0.982472837412388],
              [-78.34484061926345, -0.982365884327749],
              [-78.34775550991445, -0.985187568215338],
              [-78.34929660663373, -0.986336727141804],
              [-78.35038759515206, -0.988009419741047],
              [-78.3516995306383, -0.989384219501119],
              [-78.35078456208682, -0.991452035016437],
              [-78.34877759918862, -0.991677340574029],
              [-78.34706165596894, -0.991097312873762],
              [-78.34351359635147, -0.989314482066675],
              [-78.34122449859213, -0.988679301443426],
              [-78.33721962525118, -0.988152415009324],
              [-78.33373258588888, -0.98998419613099],
              [-78.33161162910739, -0.990443189149289],
              [-78.32960550439951, -0.991245337283942],
              [-78.32491264444528, -0.99479289398721],
              [-78.3235395210658, -0.996165682090577],
              [-78.32238751229228, -0.997718848750424],
              [-78.32073258932779, -0.999154501127691],
              [-78.31883962031289, -0.999776438343872],
              [-78.3173825102635, -1.001275457909173],
              [-78.31711563046615, -1.00224239426035],
              [-78.31441464598439, -1.001453489532764],
              [-78.31310254286008, -1.001759261360917],
              [-78.31331661666738, -1.002907246820882],
              [-78.31272854234004, -1.004172411287357],
              [-78.31292752872162, -1.005582079764736],
              [-78.31392665158106, -1.006676085768163],
              [-78.31517756681212, -1.00747303712285],
              [-78.31819957818581, -1.01011383953977],
              [-78.31958762135295, -1.010729741785667],
              [-78.32088463705153, -1.010829654071642],
              [-78.32207453202743, -1.011607829963168],
              [-78.32571462494185, -1.011746801917923],
              [-78.32697258097159, -1.012217529600719],
              [-78.32824663025544, -1.013004590309606],
              [-78.33049750653628, -1.013069633878331],
              [-78.33167264936256, -1.013441455103532],
              [-78.33301559789058, -1.013541535027514],
              [-78.33560962928773, -1.014077473917041],
              [-78.33429752616343, -1.016209327172703],
              [-78.33341960562402, -1.01696738649656],
              [-78.33229860989229, -1.017481364799778],
              [-78.33129160804386, -1.01832123149876],
              [-78.33201563684082, -1.019469216958726],
              [-78.33074158755697, -1.019341979668411],
              [-78.32884258357177, -1.018129285915848],
              [-78.32619457271812, -1.017521262658931],
              [-78.32503552314594, -1.018207069977393],
              [-78.3242726023182, -1.019282132879653],
              [-78.32390564259684, -1.020601109164602],
              [-78.32382953491593, -1.022091914464738],
              [-78.32355460849152, -1.023157757273509],
              [-78.32254760664308, -1.023663688949682],
              [-78.32182357784612, -1.022289056827617],
              [-78.32112150963549, -1.021448016662191],
              [-78.32010662879806, -1.020480074482634],
              [-78.3189465733975, -1.019693181411753],
              [-78.31777159820928, -1.019177023813768],
              [-78.31642965550964, -1.019212227807145],
              [-78.31388055111354, -1.020058967666671],
              [-78.31269065613765, -1.019444238887218],
              [-78.31012763778227, -1.016875353199623],
              [-78.30896758238171, -1.017154135299506],
              [-78.30820449391591, -1.018283177658304],
              [-78.3056255499445, -1.021318097162862],
              [-78.30484050089235, -1.022527941068347],
              [-78.30359662645995, -1.022572029879086],
              [-78.30102556147753, -1.023301758370224],
              [-78.29840856366565, -1.023696713648235],
              [-78.29663864098947, -1.02525859748738],
              [-78.29302956111682, -1.025391702109914],
              [-78.2926555605968, -1.022923734536676],
              [-78.29333449475463, -1.021812797088728],
              [-78.29413563706089, -1.021010146039941],
              [-78.29606649227819, -1.019520178930065],
              [-78.29737859540249, -1.018889021620396],
              [-78.3000185596291, -1.017264273507351],
              [-78.30118565582832, -1.016786169749764],
              [-78.30187263661323, -1.015486304204103],
              [-78.30184950256044, -1.014175542184319],
              [-78.30127752148721, -1.013316564746106],
              [-78.30049163424475, -1.012638636416625],
              [-78.29782853596537, -1.012789678312004],
              [-78.296363546927, -1.012625560647678],
              [-78.29411350883649, -1.013120595849671],
              [-78.29459362425082, -1.011982501035448],
              [-78.29453260399565, -1.010852620486332],
              [-78.29952955939737, -1.008716073364951],
              [-78.29916360550439, -1.007450070708103],
              [-78.29802651651856, -1.005271111156617],
              [-78.29739351519015, -1.004485391552237],
              [-78.29599759303402, -1.00434809597806],
              [-78.29459362425082, -1.004555296624744],
              [-78.29346458189204, -1.004210129851685],
              [-78.29304464854256, -1.00170729356114],
              [-78.29064960352699, -1.002400309316329],
              [-78.28994753531636, -1.001586426517235],
              [-78.2897715153495, -1.000321597326888],
              [-78.28885654679802, -0.999588516074482],
              [-78.28505652717087, -0.99893556581651],
              [-78.2822115415924, -0.998625603036828],
              [-78.28024263253465, -0.998931710141051],
              [-78.27917461043104, -0.99934560852023],
              [-78.27902960350598, -1.000592668075853],
              [-78.27976251712032, -1.001497578343503],
              [-78.28093749230855, -1.001425493976114],
              [-78.28214264234823, -1.002140302679607],
              [-78.2833635503659, -1.002566270999353],
              [-78.28446157968291, -1.003398426347417],
              [-78.28605665585869, -1.005243115599967],
              [-78.28669753617608, -1.006509118256758],
              [-78.28684958389982, -1.007818874448219],
              [-78.28601860201823, -1.008451037586269],
              [-78.28517152688254, -1.007231135396978],
              [-78.28436250558728, -1.006569970873898],
              [-78.28315752318566, -1.007002979992308],
              [-78.28191364875327, -1.007191908090078],
              [-78.27940360402602, -1.007253095983344],
              [-78.27846550142175, -1.008138057321389],
              [-78.27855652889032, -1.009321917326986],
              [-78.27832753529535, -1.01047778177599],
              [-78.27832049449668, -1.011688631509855],
              [-78.27879356911234, -1.012800574786127],
              [-78.27936555018556, -1.013659384586276],
              [-78.28109758665936, -1.01565830085741],
              [-78.28202060183789, -1.017719243212127],
              [-78.28221858239108, -1.019129079327513],
              [-78.28214264234823, -1.020348981516804],
              [-78.2811356404998, -1.021260932583175],
              [-78.28017457148081, -1.020852901536216],
              [-78.27916756963238, -1.021160014468819],
              [-78.27834362854946, -1.02038217385342],
              [-78.27681761925592, -1.020587865757534],
              [-78.27634454464025, -1.021807935584889],
              [-78.27539856304699, -1.020930853235825],
              [-78.27462759559221, -1.021959815670584],
              [-78.2737736472959, -1.022672947993442],
              [-78.27465056200693, -1.023748849086019],
              [-78.2728425854903, -1.024813853704472],
              [-78.27176651675963, -1.024948802345762],
              [-78.27176651675963, -1.02185604770915],
              [-78.2702026212637, -1.021359839040713],
              [-78.2708356225921, -1.020320985960211],
              [-78.2710575753884, -1.019200996056838],
              [-78.27033254076304, -1.018405050530589],
              [-78.26908162553197, -1.018404044702208],
              [-78.26815760452504, -1.017960306747682],
              [-78.26574663389341, -1.01783223126705],
              [-78.26440452355573, -1.017361335946191],
              [-78.26346558276113, -1.016574275237303],
              [-78.26216856706256, -1.016356178116439],
              [-78.26081857773586, -1.016508393478261],
              [-78.26121554467062, -1.015126385281405],
              [-78.26230653318895, -1.014666554072789],
              [-78.26283258143273, -1.013473473973647],
              [-78.26548763308506, -1.013421506173927],
              [-78.26680761519836, -1.013721578307923],
              [-78.26756248939904, -1.012971565611053],
              [-78.26640360746492, -1.012067661171784],
              [-78.26515151876741, -1.011406664286767],
              [-78.26435859072627, -1.010221630814726],
              [-78.26463351715068, -1.00912896591575],
              [-78.2639926368333, -1.008189019292786],
              [-78.26305352840065, -1.00751913759035],
              [-78.26364160272799, -1.006481122700166],
              [-78.26135250496864, -1.005557101693228],
              [-78.26152064594649, -1.004373074049568],
              [-78.26290852147557, -1.004067134583352],
              [-78.26403856966274, -1.004014160955251],
              [-78.2656636530519, -1.002190258822566],
              [-78.26625860053986, -1.000988461544182],
              [-78.26219153347728, -1.001554575285184],
              [-78.26155065315989, -1.000614628662163],
              [-78.26287063527319, -0.99967568786758],
              [-78.26499159205467, -0.999451723414552],
              [-78.26759350244082, -0.998812519477781],
              [-78.26728052217595, -0.997383907899234],
              [-78.2647096248316, -0.994454097460562],
              [-78.26517465282019, -0.993144173631094],
              [-78.26551864612684, -0.991066132193907],
              [-78.26396950278051, -0.989267375770794],
              [-78.2619625398823, -0.990657095318568],
              [-78.26245154011403, -0.992040276981868],
              [-78.2584686273594, -0.99305716947606],
              [-78.25755349116986, -0.994783003341468],
              [-78.25623350905656, -0.996217817528361],
              [-78.25405857281856, -0.996035930229311],
              [-78.25251764373736, -0.995058767956209],
              [-78.25148750783615, -0.993502919087348],
              [-78.2514415750067, -0.992076151527499],
              [-78.25527160184726, -0.989323366884037],
              [-78.25424163358409, -0.987596359552185],
              [-78.25200651528125, -0.987422518880123],
              [-78.2501755723499, -0.986725647449475],
              [-78.24846650229084, -0.985919643639363],
              [-78.24697854683774, -0.984653808620635],
              [-78.2450336100231, -0.98384763717246],
              [-78.24371362790981, -0.983584948326722],
              [-78.24211151093536, -0.985444725005038],
              [-78.24050855577059, -0.986590531170179],
              [-78.23850259870078, -0.986073703019883],
              [-78.23661750867487, -0.98681365743289],
              [-78.23552652015654, -0.988421306463465],
              [-78.2335815833419, -0.988247298153397],
              [-78.23181149302766, -0.987893414200983],
              [-78.22957654236288, -0.987783611269265],
              [-78.22774459360315, -0.98857754513881],
              [-78.22866056798301, -0.99042122856298],
              [-78.23072050450932, -0.990648378139269],
              [-78.23260458870685, -0.991744060523331],
              [-78.2338025303098, -0.993127074548568],
              [-78.23551964699593, -0.994049922089062],
              [-78.23735058992727, -0.994168945114268],
              [-78.24324759409282, -0.996423844708602],
              [-78.24519353673584, -0.996886693402416],
              [-78.24667361319995, -0.998442709909341],
              [-78.24731449351734, -0.999933682847598],
              [-78.24826064274866, -1.001027353574955],
              [-78.2492145033309, -1.001697570553461],
              [-78.24958749802256, -1.002863325648207],
              [-78.24958062486195, -1.004047353291867],
              [-78.24887051002426, -1.004814129795079],
              [-78.24763451458087, -1.004488241399315],
              [-78.24601764545682, -1.004532330210054],
              [-78.24572763160668, -1.005678974565512],
              [-78.2469404929973, -1.006248943981973],
              [-78.24931357742653, -1.006215248731166],
              [-78.2503965193178, -1.006469052759542],
              [-78.2510226474856, -1.007328030197755],
              [-78.25157149450604, -1.008548938215426],
              [-78.25157149450604, -1.009930778774219],
              [-78.2507785664649, -1.010942642126508],
              [-78.24941265152216, -1.01213471639727],
              [-78.24886363686366, -1.013190668560298],
              [-78.24781053454771, -1.01403036762116],
              [-78.24681057349795, -1.014418617376634],
              [-78.24697854683774, -1.015584372471324],
              [-78.24765764863365, -1.016958166403072],
              [-78.24772654787782, -1.018060051395537],
              [-78.24876355693965, -1.019199990228458],
              [-78.25061059312512, -1.02035820161035],
              [-78.25154852809132, -1.01963618447013],
              [-78.25288359763034, -1.019845061497449],
              [-78.25376151816975, -1.020849045860757],
              [-78.25440960692389, -1.021960821498965],
              [-78.25558458211212, -1.022630703201401],
              [-78.25669853704517, -1.023507953188528],
              [-78.2571025447786, -1.02487269466485],
              [-78.25647759007725, -1.025838792825709],
              [-78.25723263191601, -1.026499454434656],
              [-78.25777460577584, -1.027349379417387],
              [-78.25734763162768, -1.028632481156706],
              [-78.25978056284565, -1.031507138672453],
              [-78.2607805238954, -1.031933106992199],
              [-78.26176455932912, -1.031130288305292],
              [-78.26303860861297, -1.031302284958656],
              [-78.2642975704711, -1.031782232734884],
              [-78.26546449903228, -1.031621132555699],
              [-78.26589951980749, -1.032706086103758],
              [-78.26681549418736, -1.033230960881099],
              [-78.26962259356341, -1.035934962847989],
              [-78.27233162467225, -1.036325894812478],
              [-78.27307861988393, -1.037058640788757],
              [-78.2740785809337, -1.037266847263822],
              [-78.27465056200693, -1.038170751703092],
              [-78.2741625676036, -1.039164677782594],
              [-78.27311650608632, -1.03994972683472],
              [-78.27183558364186, -1.040355578586855],
              [-78.2691035861183, -1.039783597513633],
              [-78.2683636317053, -1.040749528036486],
              [-78.26744061652676, -1.041589562373474],
              [-78.26643361467832, -1.042149305868065],
              [-78.26543449181888, -1.041831464099346],
              [-78.26441156435439, -1.041785363631845],
              [-78.26359550226047, -1.04254442878414],
              [-78.26280257421934, -1.044527084163121],
              [-78.26200059372276, -1.045506258092985],
              [-78.26206949296693, -1.047524117465287],
              [-78.26062763798134, -1.048016805734392],
              [-78.25967360976102, -1.048955914167038],
              [-78.25957453566541, -1.050112952082486],
              [-78.25969657617576, -1.051233612538056],
              [-78.2580035993708, -1.052867580744646],
              [-78.25551551522989, -1.052340526672481],
              [-78.25360159145703, -1.050992716640621],
              [-78.25223550887621, -1.050756849885033],
              [-78.25170158164345, -1.051831577511166],
              [-78.25299055071496, -1.054101564531265],
              [-78.25517252775163, -1.056371216275238],
              [-78.2546766543593, -1.057283167341552],
              [-78.25365456508514, -1.057761103461075],
              [-78.25377660559549, -1.059007995378636],
              [-78.25254848914108, -1.059070189100225],
              [-78.25135054753812, -1.058870029252205],
              [-78.25081662030536, -1.057695221702033],
              [-78.24934358463993, -1.057847269425793],
              [-78.24832954199282, -1.057567146221402],
              [-78.24625351221239, -1.05599252188938],
              [-78.24514056310773, -1.055675350672971],
              [-78.24394262150477, -1.055936363138073],
              [-78.24283553973233, -1.055944409765118],
              [-78.23899863973116, -1.05497428829068],
              [-78.23746458381056, -1.053156421128335],
              [-78.23554965420932, -1.05176368409542],
              [-78.2355345667836, -1.053299416396612],
              [-78.23713651611997, -1.055297494477429],
              [-78.2374575430119, -1.056310363658099],
              [-78.2381974974249, -1.057223320552851],
              [-78.23854853153023, -1.058416233013872],
              [-78.23847963228606, -1.061037086501187],
              [-78.24235559195606, -1.061310839459168],
              [-78.24333158076271, -1.061799839690877],
              [-78.24334750637877, -1.062938940333481],
              [-78.2427295924761, -1.063922808129121],
              [-78.24417161509975, -1.065722738018735],
              [-78.24453756899273, -1.06685245092973],
              [-78.24445358232282, -1.069002409096242],
              [-78.24480461642814, -1.070196159747582],
              [-78.24588755831941, -1.072546110124051],
              [-78.24723855347449, -1.072728165061221],
              [-78.24937459768171, -1.07248626333535],
              [-78.25034354568969, -1.072830089003901],
              [-78.25140352116624, -1.073753104182458],
              [-78.25261655019493, -1.074133977863085],
              [-78.25271562429054, -1.076880895174327],
              [-78.25203652249465, -1.077955790438523],
              [-78.25370753871326, -1.080098540168308],
              [-78.2538835586801, -1.081436627192488],
              [-78.25518761517735, -1.081121635270847],
              [-78.25547058822882, -1.080100551825069],
              [-78.25532558130375, -1.079033703187918],
              [-78.25582162233414, -1.07810381484876],
              [-78.2553936423576, -1.075283807341805],
              [-78.25648463087593, -1.074660864297186],
              [-78.2577595183501, -1.075158916984321],
              [-78.25978860947271, -1.077284902907763],
              [-78.26230653318895, -1.077874653615709],
              [-78.26332057583606, -1.077323627300473],
              [-78.26502964589513, -1.079250794480345],
              [-78.26600664053016, -1.078401540049811],
              [-78.26724263597357, -1.078158632495558],
              [-78.26800555680131, -1.078837734291483],
              [-78.26886755172467, -1.07797976268165],
              [-78.26988260020016, -1.077546921201247],
              [-78.27118665669741, -1.077430915661182],
              [-78.27197254393988, -1.076635975963313],
              [-78.2687986524805, -1.075304762099734],
              [-78.26747162956853, -1.07536695582138],
              [-78.26636454779609, -1.074670084390675],
              [-78.26721950192078, -1.073713206323305],
              [-78.26839464474708, -1.073270977111349],
              [-78.2691035861183, -1.072197255313597],
              [-78.26853160504508, -1.071338277875384],
              [-78.26773850936588, -1.070713323174004],
              [-78.26669362131507, -1.070712317345624],
              [-78.26474751103397, -1.069247495945319],
              [-78.26373263019654, -1.069074493463631],
              [-78.26280257421934, -1.068621535415616],
              [-78.26189464646653, -1.06778049525019],
              [-78.26118453162884, -1.066749688796733],
              [-78.26018557640747, -1.066559587232518],
              [-78.25972758921753, -1.065655515155186],
              [-78.26020049619513, -1.064670641531166],
              [-78.26093257161915, -1.063948624390946],
              [-78.2662505539128, -1.064965684523202],
              [-78.26757858265316, -1.064351793934065],
              [-78.26775359679162, -1.061966807202282],
              [-78.26762350965421, -1.06094505320425],
              [-78.26686863545353, -1.06028489450955],
              [-78.2658695125941, -1.060012985570268],
              [-78.26466352436408, -1.060011979741887],
              [-78.26325955558089, -1.058113311032855],
              [-78.26449555102428, -1.05768918673175],
              [-78.2653126189466, -1.056777235665436],
              [-78.26524355206436, -1.055440489745763],
              [-78.26612851340245, -1.054781336879444],
              [-78.26602960694488, -1.053470574859602],
              [-78.2664185272526, -1.052404564412768],
              [-78.2675705360261, -1.051981781216227],
              [-78.26995854024301, -1.052480504455616],
              [-78.27071358208175, -1.053511478547136],
              [-78.27056153435801, -1.055960335381201],
              [-78.2710575753884, -1.05692743937044],
              [-78.27261359189534, -1.058419250499014],
              [-78.27310158629865, -1.059333213222146],
              [-78.27345262040397, -1.060669959141762],
              [-78.2745136017089, -1.062875740783511],
              [-78.2751766102507, -1.063843850601131],
              [-78.27590952386505, -1.064557653476243],
              [-78.2763364980132, -1.065778561493914],
              [-78.27869449501671, -1.068618517930474],
              [-78.27978565117311, -1.069477327730624],
              [-78.2809215666925, -1.069071308340426],
              [-78.28179965486999, -1.0698861969679],
              [-78.28228764927331, -1.068874333615611],
              [-78.28462955302271, -1.069201395477819],
              [-78.28459954580931, -1.068072520757084],
              [-78.2851255940531, -1.067051437311306],
              [-78.28628564945366, -1.066826634667962],
              [-78.28728460467504, -1.06589657869074],
              [-78.28716256416469, -1.064558659304623],
              [-78.28643065637873, -1.063284777658851],
              [-78.28673559001653, -1.062136959836891],
              [-78.28774259186497, -1.061442100063005],
              [-78.28733858413153, -1.060212977780225],
              [-78.2861475156892, -1.058115322689616],
              [-78.2868805969416, -1.056959458240613],
              [-78.28784954494958, -1.057421133467983],
              [-78.28869662008529, -1.056789473244066],
              [-78.29125963844066, -1.056737337806283],
              [-78.29216756619347, -1.055735532737799],
              [-78.29296150006299, -1.054231484030595],
              [-78.29451751656993, -1.054462489282344],
              [-78.2952115381535, -1.053659502957373],
              [-78.2963335397136, -1.054337598924917],
              [-78.29631057329888, -1.055630256033851],
              [-78.29665356077714, -1.056633234568721],
              [-78.29735562898777, -1.057818268040762],
              [-78.29817957007069, -1.058641203295338],
              [-78.29930157163079, -1.059346959543348],
              [-78.30062054791571, -1.058751006226998],
              [-78.3015516097213, -1.057713158974877],
              [-78.30220053666575, -1.056457382239955],
              [-78.30251351693062, -1.055337392336583],
              [-78.30223054387915, -1.053981535677678],
              [-78.30261259102625, -1.05303354242767],
              [-78.30346653932254, -1.052202728184113],
              [-78.30431361445825, -1.052935474160392],
              [-78.30567952940099, -1.052918710354049],
              [-78.30605352992103, -1.056425363369783],
              [-78.3048785547328, -1.056875303932657],
              [-78.30522958883812, -1.058087327132966],
              [-78.30412250706567, -1.06038095112001],
              [-78.30289455824932, -1.062422782735439],
              [-78.30244461768645, -1.063668668824619],
              [-78.30212359079452, -1.06501463483778],
              [-78.30185654335911, -1.067698352598995],
              [-78.30212359079452, -1.069090251441594],
              [-78.3024595374741, -1.070175204989653],
              [-78.30609862456015, -1.068750281448501],
              [-78.30847154135134, -1.068373431081397],
              [-78.30966160396528, -1.068636287565198],
              [-78.31032561833547, -1.069450338002355],
              [-78.30945557678504, -1.070262209144687],
              [-78.30897562900877, -1.071202155767651],
              [-78.30714451843936, -1.073315065922088],
              [-78.30546562323175, -1.075780854200559],
              [-78.30510754832777, -1.078147736021435],
              [-78.30400851318238, -1.078833543339897],
              [-78.30258962461151, -1.07895876897345],
              [-78.30128456228589, -1.078867741504894],
              [-78.29962159269434, -1.080347650330907],
              [-78.29859950342016, -1.08010256348183],
              [-78.29753852211523, -1.080138605665525],
              [-78.29658549972329, -1.081004623902402],
              [-78.2953495042799, -1.080868501794725],
              [-78.2944945501552, -1.079828642885786],
              [-78.29327364213752, -1.079926711153064],
              [-78.29193153179983, -1.079735603760469],
              [-78.29132049105776, -1.078939658234162],
              [-78.29005465603903, -1.078820802847019],
              [-78.29000050894447, -1.081233449859326],
              [-78.29093157075006, -1.082237266584514],
              [-78.29138955794, -1.083476447151156],
              [-78.29027560300695, -1.084026299999948],
              [-78.28884850017096, -1.084079105990043],
              [-78.2874835910566, -1.085153330701985],
              [-78.2882845657248, -1.086925097396886],
              [-78.28725459746164, -1.087385096243565],
              [-78.28511050662736, -1.088792920702247],
              [-78.28426359912973, -1.089903690512074],
              [-78.28235654851747, -1.091817781922998],
              [-78.2834775442492, -1.09334563523521],
              [-78.2845765793946, -1.093780656010438],
              [-78.28631565666704, -1.092444580643019],
              [-78.28755953109945, -1.092969623058423],
              [-78.28882553375624, -1.095194515439402],
              [-78.28969557530667, -1.094589509667628],
              [-78.29032153583638, -1.093785685152341],
              [-78.28990964911397, -1.092727721332551],
              [-78.29051264322896, -1.091924567369517],
              [-78.29034450225112, -1.090821676548671],
              [-78.2915496522908, -1.091084700670592],
              [-78.29248859308538, -1.091727592644759],
              [-78.29375459574219, -1.091646791098071],
              [-78.29495253734514, -1.092280630616756],
              [-78.29527255840867, -1.091241777536254],
              [-78.29488363810097, -1.088982016438024],
              [-78.29695849441495, -1.089309748852486],
              [-78.29780556955065, -1.0887768274481],
              [-78.2983165303687, -1.087846939108942],
              [-78.30061350711703, -1.086818982502564],
              [-78.30525959605151, -1.085142098951735],
              [-78.3062285440595, -1.083968129591881],
              [-78.30741860667345, -1.083897386328999],
              [-78.30857849443595, -1.083166316733355],
              [-78.31105065296082, -1.082915362552058],
              [-78.31632957558563, -1.081338558925211],
              [-78.31719257633738, -1.080652416330622],
              [-78.31833653848382, -1.080192417483943],
              [-78.3206486026579, -1.078658529201391],
              [-78.32077064316825, -1.077321615643712],
              [-78.3235856215333, -1.075732742076298],
              [-78.32424963590348, -1.074269932332754],
              [-78.32439464282855, -1.073149942429382],
              [-78.32539359804993, -1.072617021024996],
              [-78.32638551247264, -1.072826233328442],
              [-78.32627855938802, -1.07412693706442],
              [-78.32582057219808, -1.07544591334937],
              [-78.32628660601507, -1.076413855528926],
              [-78.32610354524955, -1.081040330809969],
              [-78.32628660601507, -1.085052244949622],
              [-78.32586650502752, -1.086272147138914],
              [-78.32579056498469, -1.087573018512956],
              [-78.32635449943085, -1.090239804829707],
              [-78.3253245311677, -1.09120573535256],
              [-78.3243026095316, -1.091900595126447],
              [-78.32196858477118, -1.092693523167554],
              [-78.32125059094452, -1.093876544982834],
              [-78.32192952510235, -1.094871309252653],
              [-78.32292160716312, -1.095170543196275],
              [-78.32398962926673, -1.095207423570287],
              [-78.32512655061451, -1.095461562874789],
              [-78.32604956579304, -1.095860541466323],
              [-78.32697258097159, -1.096801326279603],
              [-78.32828552228621, -1.097452432518878],
              [-78.32966652465468, -1.097598109996227],
              [-78.33068861392886, -1.097455114727893],
              [-78.33170349476627, -1.096615248028968],
              [-78.33193165017093, -1.095495258125595],
              [-78.33316764561432, -1.09583941907033],
              [-78.33390055922867, -1.096906267707482],
              [-78.33489264128944, -1.097531222408804],
              [-78.33482357440721, -1.098814324148123],
              [-78.33353460533569, -1.099490073182722],
              [-78.33277855766856, -1.098667305566266],
              [-78.33187850890475, -1.099119257785901],
              [-78.33081853342819, -1.099253032960746],
              [-78.32868953001964, -1.100155093381318],
              [-78.32728556123645, -1.100333963195226],
              [-78.32626363960036, -1.100252155820158],
              [-78.3223796333033, -1.101207022230824],
              [-78.32142661091136, -1.101811022174161],
              [-78.32122058373112, -1.103148941560278],
              [-78.32225759279295, -1.105227653549719],
              [-78.3221816527501, -1.106546629834668],
              [-78.31980856832087, -1.107195556779118],
              [-78.31898462723795, -1.106553670633332],
              [-78.3179625379638, -1.106127534675522],
              [-78.31562063421438, -1.106803451348185],
              [-78.31612354840539, -1.105384562777317],
              [-78.3158415811823, -1.104110848769608],
              [-78.31455261211077, -1.104018647834607],
              [-78.31385758469882, -1.102834955467074],
              [-78.31285058285039, -1.10243597687554],
              [-78.31212655405342, -1.101603821527476],
              [-78.31105752612143, -1.101891823720848],
              [-78.30994457701675, -1.102695983512263],
              [-78.3086926559573, -1.104582749918848],
              [-78.30766252005608, -1.108006422093069],
              [-78.30838755468143, -1.108739335707412],
              [-78.30890656212654, -1.109661512695595],
              [-78.31137050638628, -1.112980075803932],
              [-78.3117986540009, -1.114363089829169],
              [-78.31285058285039, -1.115122993171781],
              [-78.31412463213424, -1.114654947698],
              [-78.31558258037394, -1.114718985438344],
              [-78.31646754171203, -1.114078105120939],
              [-78.3175426046143, -1.11372690337754],
              [-78.31764955769893, -1.114766091734225],
              [-78.31733657743406, -1.115723975629976],
              [-78.31752751718858, -1.117919698987862],
              [-78.31626855533045, -1.118578851854238],
              [-78.3167726429879, -1.119744606948927],
              [-78.31877155925903, -1.121074479708],
              [-78.31955761413955, -1.123353183907398],
              [-78.31914555977907, -1.124653384729186],
              [-78.32019061546796, -1.125179265334907],
              [-78.32153356399598, -1.125053201511037],
              [-78.32329560768316, -1.126139160887419],
              [-78.32242556613274, -1.127086986499421],
              [-78.32115956347594, -1.127764076638528],
              [-78.32004560854288, -1.128079068560169],
              [-78.3196635613958, -1.129009962727707],
              [-78.31863359313263, -1.129496783664592],
              [-78.31511654655695, -1.129050866415241],
              [-78.3148265327068, -1.130026687583836],
              [-78.31495661984421, -1.131463681065554],
              [-78.3159785414803, -1.132241689319017],
              [-78.31863359313263, -1.136391234308917],
              [-78.32008349474528, -1.138010282727805],
              [-78.32073963012647, -1.14067690140655],
              [-78.31948854725734, -1.140286975270442],
              [-78.31822958539921, -1.140114140426817],
              [-78.31636863525446, -1.141675856627899],
              [-78.31533062036425, -1.141502854146211],
              [-78.31470449219646, -1.139506284807965],
              [-78.31385054390014, -1.138312366518562],
              [-78.31111955220499, -1.1358784294722],
              [-78.30899859542349, -1.135424465595804],
              [-78.3074645395029, -1.13529756358156],
              [-78.30643440360167, -1.135422453939043],
              [-78.30566360378494, -1.134518381861653],
              [-78.30456557446794, -1.134426348564716],
              [-78.30332957902453, -1.133847661968957],
              [-78.3032305049289, -1.132780478055679],
              [-78.3013075287006, -1.132086624110173],
              [-78.30111658894609, -1.130944841258497],
              [-78.29996458017258, -1.130337656191955],
              [-78.29885062523954, -1.130969819330005],
              [-78.29682153411693, -1.133009639288673],
              [-78.29443352990003, -1.133350447472139],
              [-78.29321262188235, -1.132744771148111],
              [-78.2932285474984, -1.131271735482642],
              [-78.29354856856195, -1.130024675927075],
              [-78.29414351604989, -1.128769066830216],
              [-78.29443352990003, -1.127512954819167],
              [-78.29156457207846, -1.128178813208024],
              [-78.29110759071692, -1.126932088928584],
              [-78.29051951638958, -1.124464288993352],
              [-78.28532458043466, -1.12521815736568],
              [-78.28397358527958, -1.124955133243759],
              [-78.28276860287797, -1.124349121643604],
              [-78.28183754107236, -1.122407537590277],
              [-78.2796015845792, -1.122565452646256],
              [-78.27925859710095, -1.123577315998489],
              [-78.27856457551738, -1.124778275086612],
              [-78.27845762243275, -1.126016282186754],
              [-78.2786175491455, -1.1271820372815],
              [-78.27774063443447, -1.129485887190469],
              [-78.27962455099393, -1.130734790764734],
              [-78.28061663305469, -1.131177690528943],
              [-78.27813760136922, -1.133859396633397],
              [-78.27737451290342, -1.134934459535714],
              [-78.27639751826837, -1.135764267950833],
              [-78.27512363662258, -1.136025448053999],
              [-78.27346049939297, -1.134325598088481],
              [-78.27272054497995, -1.133059595431632],
              [-78.2705915415714, -1.131764759027931],
              [-78.26824159119495, -1.132015545571164],
              [-78.26594562027499, -1.131489664965443],
              [-78.26675464157024, -1.130179908774039],
              [-78.26720458213312, -1.128499840099948],
              [-78.26376364323833, -1.129029911657256],
              [-78.26252764779493, -1.128395904500508],
              [-78.26200059372276, -1.127049100297029],
              [-78.25892661454934, -1.125916034624709],
              [-78.25784249919161, -1.125861049339846],
              [-78.25695753785352, -1.126692198859473],
              [-78.25662259700233, -1.12800212268894],
              [-78.25687455701201, -1.129511032899984],
              [-78.25672955008694, -1.13098373328927],
              [-78.25691261085247, -1.132041529470996],
              [-78.25816352608354, -1.134211436567114],
              [-78.25821649971165, -1.137120459885864],
              [-78.25891856792228, -1.138422169450223],
              [-78.25829361322094, -1.139651124094939],
              [-78.25835463347612, -1.141015027380945],
              [-78.25904060843263, -1.142119091668235],
              [-78.25923154818715, -1.143293899218406],
              [-78.2583085330086, -1.144195792000914],
              [-78.25812563988114, -1.145361547095661],
              [-78.25756053196854, -1.146273665800038],
              [-78.25497454719844, -1.145240680051756],
              [-78.25393653230822, -1.145321816874571],
              [-78.2529295304598, -1.146196552290746],
              [-78.251655648814, -1.14627668328518],
              [-78.24982453824458, -1.144133765917388],
              [-78.25048050598771, -1.143203709940167],
              [-78.2509455339763, -1.142083887674858],
              [-78.25155657471838, -1.141270843066081],
              [-78.24990064592549, -1.139280979250373],
              [-78.24890856386472, -1.13882030985144],
              [-78.24788664222862, -1.138629202458844],
              [-78.2471545668046, -1.139351051961],
              [-78.24663555935949, -1.140741944975218],
              [-78.24665064678521, -1.141807955422053],
              [-78.24727660731494, -1.143082842896263],
              [-78.24674955324278, -1.144040726792014],
              [-78.24569661856489, -1.143994626324513],
              [-78.24489564389671, -1.145149820221263],
              [-78.24368261486802, -1.145410665048303],
              [-78.24060058906755, -1.141803093918213],
              [-78.23926551952852, -1.141059954382001],
              [-78.23845649823328, -1.139722202633948],
              [-78.23805953129852, -1.138610091719613],
              [-78.23782349690488, -1.136974447132445],
              [-78.23743457659717, -1.13587256213998],
              [-78.23671758859888, -1.13503034850811],
              [-78.23650351479158, -1.134036590066671],
              [-78.23554965420932, -1.132770755047943],
              [-78.23494749828464, -1.13158655976622],
              [-78.23485563262575, -1.129535005143111],
              [-78.23562660008055, -1.128523812343076],
              [-78.23599255397353, -1.127565928447268],
              [-78.2350316525926, -1.126978189396084],
              [-78.23300960226867, -1.125214134052158],
              [-78.23185759349516, -1.124861088290061],
              [-78.23079661219023, -1.124236133588738],
              [-78.22958358316154, -1.123087309938398],
              [-78.22758449925234, -1.119850554205186],
              [-78.22669249711558, -1.119280584788726],
              [-78.22599059654301, -1.118547838812447],
              [-78.22500656110931, -1.118203677867712],
              [-78.22405253288899, -1.117651645724095],
              [-78.22331257847597, -1.116864752653214],
              [-78.22306061846628, -1.11577091428785],
              [-78.22301451799878, -1.114605829745358],
              [-78.22219057691586, -1.113448121277656],
              [-78.22125264194966, -1.112887036678615],
              [-78.22093161505774, -1.111739051218592],
              [-78.22037455377217, -1.110826261961961],
              [-78.21910050448832, -1.111041173959563],
              [-78.21862055671205, -1.111963350947804],
              [-78.22019149300665, -1.11359111654599],
              [-78.22057354015375, -1.114739102005956],
              [-78.22039064702629, -1.116103005291961],
              [-78.21944449779497, -1.117213942739852],
              [-78.21907049727493, -1.118315827732317],
              [-78.21643053304834, -1.117499933276463],
              [-78.21535463195573, -1.117769662920921],
              [-78.21422558959695, -1.117714845274065],
              [-78.21224963974053, -1.11684597719011],
              [-78.21112059738175, -1.117105648550705],
              [-78.20980849425744, -1.117637899402837],
              [-78.20863351906921, -1.117582746479911],
              [-78.20745049725393, -1.117030714336295],
              [-78.20618449459712, -1.115284931541282],
              [-78.20635950873559, -1.114128899454215],
              [-78.20733650337064, -1.110949140662626],
              [-78.20840452547425, -1.110200301432258],
              [-78.21063260297842, -1.109380383662824],
              [-78.21113551716941, -1.108459380141085],
              [-78.21098363708373, -1.107392531503933],
              [-78.20972450758754, -1.107102685291864],
              [-78.20961755450293, -1.108593490592],
              [-78.2084125721013, -1.109007388971179],
              [-78.20713064382845, -1.109693363927704],
              [-78.20610050792723, -1.109963261210225],
              [-78.20452152500557, -1.112871278700595],
              [-78.20400251756047, -1.114821915209404],
              [-78.20209462875789, -1.116582953068246],
              [-78.20098855281383, -1.116201911749499],
              [-78.19960755044536, -1.116643805685328],
              [-78.1956405633068, -1.114777826398665],
              [-78.18967449225902, -1.114826944351307],
              [-78.19018562071514, -1.116082888724293],
              [-78.19113964893545, -1.116725948336523],
              [-78.19182562389197, -1.117457688484421],
              [-78.19326060571694, -1.118109632914013],
              [-78.19447363474562, -1.117930763100105],
              [-78.19637263873082, -1.11902577493197],
              [-78.19758550012143, -1.11946951288644],
              [-78.19985163146606, -1.119562552011814],
              [-78.20093557918571, -1.119798754043529],
              [-78.20294153625552, -1.120640464761152],
              [-78.20372759113606, -1.121526431927634],
              [-78.20497163320651, -1.123696339023695],
              [-78.20420049811365, -1.125783265278301],
              [-78.20336951623207, -1.127109952914111],
              [-78.20221750745856, -1.127407175201029],
              [-78.20141552696198, -1.128075045246646],
              [-78.20024859840082, -1.128633782912857],
              [-78.19806662136415, -1.12922001322147],
              [-78.19705157288865, -1.129941862723626],
              [-78.19363360040859, -1.13150173490601],
              [-78.19336655297317, -1.13276572590604],
              [-78.19340561264201, -1.134031560924768],
              [-78.19395462730051, -1.137366217287195],
              [-78.1944575414915, -1.138252184453677],
              [-78.19294762545208, -1.140103076314631],
              [-78.19191748955086, -1.140825093454794],
              [-78.19071954794791, -1.141022068179609],
              [-78.18851460449652, -1.140883934415172],
              [-78.1884616308684, -1.141986825236017],
              [-78.18944549866406, -1.143939808677715],
              [-78.1903606348536, -1.145132721138737],
              [-78.18970449947241, -1.147219479755222],
              [-78.18914056502625, -1.148158420549862],
              [-78.18809550933736, -1.148202341722538],
              [-78.18796558983802, -1.149440516460743],
              [-78.1891025111858, -1.149631456215275],
              [-78.19033062764021, -1.149289474565364],
              [-78.1915745020726, -1.14855840496972],
              [-78.19345858627013, -1.146816477850223],
              [-78.19474755534165, -1.146248520090523],
              [-78.19527460941381, -1.145309579295883],
              [-78.19653357127194, -1.144533750337246],
              [-78.19745658645047, -1.145293821317921],
              [-78.19819654086349, -1.147815433071571],
              [-78.19788356059863, -1.149179336357577],
              [-78.19513664328741, -1.151471283963986],
              [-78.1940686211838, -1.151859198443276],
              [-78.18906361915502, -1.152947169476477],
              [-78.18653060801304, -1.153216228568681],
              [-78.18586759947125, -1.154253908182739],
              [-78.18586759947125, -1.155446820643817],
              [-78.18608854643917, -1.156621795831995],
              [-78.18711851470232, -1.157706917018118],
              [-78.18663756109767, -1.1587635397334],
              [-78.18768362261494, -1.15922555023684],
              [-78.18888860501656, -1.157058828263985],
              [-78.19027765401209, -1.157014907091309],
              [-78.19140652873281, -1.157340795487073],
              [-78.19255853750632, -1.157251947313341],
              [-78.19339756601495, -1.156458013443796],
              [-78.1946255148313, -1.156946678399379],
              [-78.19554853000984, -1.157598622829028],
              [-78.1979445808538, -1.160238587055574],
              [-78.198242641331, -1.16258836979398],
              [-78.19886055523367, -1.16370919788767],
              [-78.19994349712495, -1.164226361314036],
              [-78.20056157866568, -1.163304184325852],
              [-78.20099659944088, -1.162257284618249],
              [-78.20195750082182, -1.161056493168189],
              [-78.20286559621269, -1.157867849559238],
              [-78.20315561006284, -1.155482024637138],
              [-78.20375055755078, -1.154363040562203],
              [-78.20493357936606, -1.154273018922026],
              [-78.20515452633397, -1.155348920014603],
              [-78.20516961375971, -1.157833651394242],
              [-78.20497163320651, -1.158845514746531],
              [-78.20450559938952, -1.159730643722696],
              [-78.20416261191127, -1.161130421554276],
              [-78.20358258421098, -1.162042372620647],
              [-78.20371250371032, -1.166588213993009],
              [-78.2056425207373, -1.169888001638242],
              [-78.20661951537234, -1.170566768157983],
              [-78.20877852599428, -1.169692032741807],
              [-78.20960263471525, -1.168987785236311],
              [-78.21029665629884, -1.169901747959443],
              [-78.21155561815695, -1.16802302817996],
              [-78.21269958030341, -1.16745507042026],
              [-78.21520962503065, -1.168008946582574],
              [-78.21553752508319, -1.166871019406415],
              [-78.21547650482802, -1.164594159225715],
              [-78.21741456848204, -1.164767161707459],
              [-78.2183305428619, -1.165256329577232],
              [-78.21752956819373, -1.166132070821789],
              [-78.21843749594653, -1.166955173714371],
              [-78.21965756577389, -1.167760171696102],
              [-78.21936051112507, -1.16871805559191],
              [-78.21953552526354, -1.170036864238739],
              [-78.21836859670236, -1.170424778718086],
              [-78.21840665054282, -1.17182573001611],
              [-78.2195125588488, -1.173570674620805],
              [-78.2183305428619, -1.176216506179628],
              [-78.217506601779, -1.177390307901419],
              [-78.21731549438641, -1.178646252274405],
              [-78.21614856582525, -1.178636193990599],
              [-78.21508054372164, -1.17961117696882],
              [-78.21260855283484, -1.181018163237184],
              [-78.21192961867699, -1.181784939740396],
              [-78.21095262404195, -1.182163969402325],
              [-78.21000664244869, -1.183192764199077],
              [-78.20882462646179, -1.188775446995237],
              [-78.20907558064309, -1.189779263720425],
              [-78.21018249477747, -1.190304306135829],
              [-78.21070854302125, -1.191633340704584],
              [-78.21041065018213, -1.192663308967724],
              [-78.20938152010929, -1.192391232390435],
              [-78.20729861716825, -1.190717198686627],
              [-78.20686359639303, -1.191755381214932],
              [-78.20746659050803, -1.192614191015082],
              [-78.20721463049836, -1.193943057945773],
              [-78.20697054947766, -1.198451851306118],
              [-78.20634458894793, -1.19969773739524],
              [-78.20401760498619, -1.200661488623268],
              [-78.20174460048096, -1.202051543447169],
              [-78.20120949978174, -1.203333304081923],
              [-78.201049573069, -1.204417419439665],
              [-78.20089752534527, -1.207869087170536],
              [-78.20097363302617, -1.209197786463164],
              [-78.20205657491744, -1.209839840246957],
              [-78.20306357676587, -1.208973989648143],
              [-78.20351452315714, -1.207989116024123],
              [-78.2033084959769, -1.20573019311621],
              [-78.20409354502904, -1.204728388047783],
              [-78.2040705786143, -1.203698419784587],
              [-78.2054745473975, -1.203690205519479],
              [-78.20933558727984, -1.204860151565811],
              [-78.21051760326674, -1.204879094666978],
              [-78.21440865036247, -1.205443196751219],
              [-78.21376056160834, -1.204259169107559],
              [-78.2128375464298, -1.203372363750759],
              [-78.21022054861793, -1.203803193574402],
              [-78.20892353291936, -1.203179412339523],
              [-78.2077255913164, -1.202834245566407],
              [-78.20719954307262, -1.201930341127138],
              [-78.20862564008021, -1.201416530461984],
              [-78.21183054458136, -1.19933278933064],
              [-78.21289052005793, -1.199189794062363],
              [-78.21399659600198, -1.198828534035158],
              [-78.21503461089219, -1.197980788347195],
              [-78.21443161677719, -1.196795754875154],
              [-78.21303552698299, -1.195259016745524],
              [-78.21451560344708, -1.194573879979316],
              [-78.21586659860216, -1.194548063717548],
              [-78.2184445367452, -1.195318863534283],
              [-78.21869649675489, -1.193013169606616],
              [-78.22266365153152, -1.195431013398832],
              [-78.22390752596392, -1.194945030652264],
              [-78.22459450674882, -1.194176913044544],
              [-78.2258836434584, -1.194033917776267],
              [-78.22597450328891, -1.196545974160301],
              [-78.2281955399944, -1.1982106201325],
              [-78.22942365644882, -1.19871772527506],
              [-78.23020954369127, -1.197896634039239],
              [-78.23122358633839, -1.197671831395837],
              [-78.23230753405804, -1.198341880736336],
              [-78.23307749568446, -1.197664790597173],
              [-78.23336750953459, -1.196318824584012],
              [-78.23464155881845, -1.196482774610274],
              [-78.23484054520002, -1.195099928223101],
              [-78.23482562541236, -1.193808109304484],
              [-78.23450459852043, -1.192768082757539],
              [-78.23398559107532, -1.189253550752767],
              [-78.2333375023212, -1.18804135991445],
              [-78.23352860971377, -1.187011559289317],
              [-78.23461959823211, -1.187473402154751],
              [-78.23542761369897, -1.188423407061521],
              [-78.23587051346318, -1.189824525997608],
              [-78.2372895696721, -1.191804331529511],
              [-78.2392725603272, -1.193044182648407],
              [-78.23989064186793, -1.192196101684317],
              [-78.24120358318255, -1.19264017491497],
              [-78.24184463113801, -1.194041126212994],
              [-78.24254653171057, -1.194845956556662],
              [-78.24392652825065, -1.197079733755004],
              [-78.24384354740914, -1.198335845766053],
              [-78.24423950851552, -1.199510485678161],
              [-78.24566661135151, -1.201291640104557],
              [-78.24631453246757, -1.200425454229617],
              [-78.24650563986015, -1.198048849401061],
              [-78.246223504999, -1.195608877384416],
              [-78.24636063293508, -1.19424396827003],
              [-78.24713160038988, -1.191462349879657],
              [-78.24757349432569, -1.188471351547719],
              [-78.24826852173764, -1.187225465458596],
              [-78.24845158250317, -1.185915541629072],
              [-78.24841352866272, -1.183429972059116],
              [-78.24816156865303, -1.181975041304611],
              [-78.24799359531323, -1.17829722982583],
              [-78.2475356081233, -1.177321241019172],
              [-78.24758958757981, -1.176101506467944],
              [-78.2470166006782, -1.17342566769571],
              [-78.24657453910432, -1.172106691410818],
              [-78.24524651036396, -1.169502937005973],
              [-78.24507853702417, -1.1681200906188],
              [-78.24555161163983, -1.167152986629503],
              [-78.24633062572168, -1.166142296743658],
              [-78.24653665290192, -1.165148203026149],
              [-78.2460025580311, -1.16404514456724],
              [-78.24591052473414, -1.162464485264934],
              [-78.24502556339606, -1.160990443771141],
              [-78.24153852403376, -1.159676496628151],
              [-78.24034862905786, -1.159440629872563],
              [-78.23855557232889, -1.158462629409144],
              [-78.23410763158567, -1.156904768883464],
              [-78.23025564415879, -1.15715387904612],
              [-78.22929356931141, -1.157459650874216],
              [-78.22824851362252, -1.157368791043723],
              [-78.22592957628784, -1.156010922728001],
              [-78.22507462216315, -1.155250851747383],
              [-78.22473951367388, -1.154284083034213],
              [-78.2260584899588, -1.153445222163612],
              [-78.22939264340704, -1.153520156378079],
              [-78.23153656660324, -1.153314967388155],
              [-78.23398559107532, -1.152576018803529],
              [-78.23463451801977, -1.151682340286129],
              [-78.23580949320801, -1.151322253725368],
              [-78.23689260273734, -1.151503135196094],
              [-78.2379225710005, -1.152065225623573],
              [-78.23909754618873, -1.15229203992368],
              [-78.24018853470706, -1.152915988796622],
              [-78.2413324968535, -1.153044231915374],
              [-78.24359862819813, -1.153805141086309],
              [-78.24469749570545, -1.154340074147456],
              [-78.24612459854144, -1.154458091344281],
              [-78.24723050684743, -1.154125162149853],
              [-78.24858854280119, -1.154479046102267],
              [-78.24990064592549, -1.155031078245884],
              [-78.25090060697525, -1.155980915514647],
              [-78.25131249369768, -1.15730089762792],
              [-78.2521285557916, -1.158232797623839],
              [-78.25147258804847, -1.159117758961941],
              [-78.25099163444382, -1.160300445501093],
              [-78.25117452757128, -1.161710449254599],
              [-78.25333353819322, -1.165155243824813],
              [-78.25534050109142, -1.167063970817708],
              [-78.25609554293017, -1.168112882182072],
              [-78.25759858580898, -1.169488017218328],
              [-78.25924663561287, -1.171684746404594],
              [-78.2604755902576, -1.172237616738528],
              [-78.26139055880908, -1.172906492612583],
              [-78.26206161397793, -1.173702438138832],
              [-78.26248154732743, -1.175040525163013],
              [-78.263175568911, -1.176270317998046],
              [-78.26305352840065, -1.177300286261243],
              [-78.26396950278051, -1.178105284242918],
              [-78.26522058564964, -1.178730071306234],
              [-78.26607553977433, -1.177538164673592],
              [-78.26724950913417, -1.17738645222596],
              [-78.26797454375952, -1.178136129646646],
              [-78.26906553227786, -1.177333310959796],
              [-78.27049263511384, -1.176783458110947],
              [-78.2716905767168, -1.176739369300208],
              [-78.27306353245821, -1.175819371606792],
              [-78.2719876313656, -1.175068520719663],
              [-78.27117961589875, -1.171136737574443],
              [-78.26824159119495, -1.170202993559826],
              [-78.26752460319665, -1.169010081098804],
              [-78.26838659812002, -1.168342043415123],
              [-78.2710875826018, -1.167946920499048],
              [-78.27206457723683, -1.16721618617953],
              [-78.27243757192848, -1.165933084440212],
              [-78.27043949384766, -1.163888402977705],
              [-78.26867661197016, -1.161555551683762],
              [-78.26736450884586, -1.161807344055376],
              [-78.26652564797527, -1.162782327033653],
              [-78.26588460001982, -1.161869370138902],
              [-78.26585358697804, -1.160522565935423],
              [-78.2649305717995, -1.160133477989689],
              [-78.26401560324801, -1.159454711469891],
              [-78.26475555766103, -1.158298847020944],
              [-78.26600664053016, -1.157938760460183],
              [-78.26806657705649, -1.156774849384135],
              [-78.26763960290833, -1.155464087364351],
              [-78.26707449499571, -1.154559847648954],
              [-78.26731153521773, -1.153250091457551],
              [-78.26703660879332, -1.152192127637761],
              [-78.26717356909134, -1.15092713080935],
              [-78.26683058161308, -1.149987351824393],
              [-78.26689160186825, -1.148876414376502],
              [-78.26794453654614, -1.149356362152787],
              [-78.26883653868289, -1.148661334740837],
              [-78.27036254797643, -1.150723450561998],
              [-78.27046950106106, -1.151825335554463],
              [-78.27148454953655, -1.152956054293838],
              [-78.27349050660636, -1.155876812277086],
              [-78.27356762011564, -1.156906780540282],
              [-78.27442961503901, -1.15751279214038],
              [-78.27632157822553, -1.159547750595209],
              [-78.27722162698936, -1.160027698371493],
              [-78.27848863547453, -1.159531489703056],
              [-78.27918248942004, -1.158466820360729],
              [-78.2796555640357, -1.157183886259475],
              [-78.28023559173599, -1.156262715099672],
              [-78.2814405741376, -1.15599281781715],
              [-78.28080757280921, -1.154673003341941],
              [-78.27978565117311, -1.153677903795995],
              [-78.27939555739896, -1.152683139526232],
              [-78.27793861498765, -1.151218150487864],
              [-78.27790860777425, -1.149700523097465],
              [-78.27899959629258, -1.149240524250786],
              [-78.28162363490313, -1.149541266936978],
              [-78.28285963034654, -1.150283233006803],
              [-78.28413351199232, -1.150691264053762],
              [-78.28503456658453, -1.151541356674613],
              [-78.28627056202792, -1.152262032710269],
              [-78.28777360490675, -1.151318398049909],
              [-78.28913164086049, -1.151174396953252],
              [-78.29058858327181, -1.15342611142438],
              [-78.29198450542795, -1.15389700674524],
              [-78.2936706090723, -1.152607031845321],
              [-78.29326660133886, -1.151386123827649],
              [-78.29419749550638, -1.150628399779862],
              [-78.29536459170562, -1.150286250491945],
              [-78.29788955622054, -1.150288262148706],
              [-78.29891952448371, -1.150813136926047],
              [-78.2999875465873, -1.151709330014398],
              [-78.301200575616, -1.151123099705728],
              [-78.30226155692093, -1.150889412245022],
              [-78.30329152518408, -1.150429245760222],
              [-78.3047405886064, -1.148397304790535],
              [-78.30602251687925, -1.148416415529823],
              [-78.30709858560992, -1.147215624079763],
              [-78.3081136340854, -1.146990653798355],
              [-78.31076851809966, -1.147372533307362],
              [-78.3100966247405, -1.14830242164652],
              [-78.31018849039938, -1.149450239468422],
              [-78.30681661838683, -1.150260266592056],
              [-78.3072965661631, -1.152376194231636],
              [-78.30613751659092, -1.152591273867301],
              [-78.30513755554117, -1.153377161109802],
              [-78.30471762219167, -1.154642157938213],
              [-78.3038866403101, -1.155580931094732],
              [-78.30107853510565, -1.156174034563946],
              [-78.29981957324752, -1.15883864158593],
              [-78.298530604176, -1.160762623642597],
              [-78.29799650930516, -1.162126526928603],
              [-78.29671458103232, -1.164501120100397],
              [-78.2983165303687, -1.16589519823782],
              [-78.29971362599129, -1.165553048949903],
              [-78.30036154710734, -1.16467730770529],
              [-78.30129964971161, -1.163991332748822],
              [-78.30261259102625, -1.163640298643486],
              [-78.30406165444856, -1.163532339730466],
              [-78.30529764989195, -1.163235285081669],
              [-78.3063586311969, -1.163336203196025],
              [-78.30879960904193, -1.164251339385544],
              [-78.31112659300366, -1.165609207701209],
              [-78.31214851463976, -1.166468185139422],
              [-78.31352951700823, -1.167193219764783],
              [-78.31458261932417, -1.168215141400879],
              [-78.31513951297167, -1.169416771041256],
              [-78.31591064806453, -1.170095705199117],
              [-78.31585650096996, -1.171206810285071],
              [-78.31462855215362, -1.171440833021961],
              [-78.3137585106032, -1.170834653783743],
              [-78.31278956259521, -1.171357684542386],
              [-78.3128735492651, -1.172442805728451],
              [-78.31413251112323, -1.173446622453696],
              [-78.31534554015191, -1.174143661522407],
              [-78.31948854725734, -1.174897529894736],
              [-78.32080852937062, -1.175414358044975],
              [-78.32211258586787, -1.176228240844068],
              [-78.3229526202049, -1.176970206913893],
              [-78.32440151598915, -1.179023102641509],
              [-78.32563063827195, -1.179376986593923],
              [-78.3259275252827, -1.180796042802854],
              [-78.32379952770253, -1.181507834021147],
              [-78.32153356399598, -1.180601917925117],
              [-78.31961863439473, -1.178910282224649],
              [-78.3182835648557, -1.178782039105954],
              [-78.31743648972001, -1.17804024067425],
              [-78.31603252093682, -1.177750226824116],
              [-78.31494153241849, -1.177179251579275],
              [-78.31381953085837, -1.176310383495263],
              [-78.3131255092748, -1.175352499599512],
              [-78.3119656215123, -1.174628470802531],
              [-78.31047850424953, -1.174346503579443],
              [-78.31050163830231, -1.175458279217651],
              [-78.30970753679473, -1.176270317998046],
              [-78.30651855790964, -1.174713630938868],
              [-78.30558749610404, -1.175182346964903],
              [-78.3050465280726, -1.176085245575791],
              [-78.30386350625731, -1.176888399538825],
              [-78.30330661260982, -1.178153396367236],
              [-78.30215460383631, -1.178549189835508],
              [-78.3032836461951, -1.179409005464095],
              [-78.30442056754286, -1.179951985152286],
              [-78.30506161549832, -1.180901990059056],
              [-78.30809050003262, -1.183985692240185],
              [-78.30885358849842, -1.184980791786074],
              [-78.31146958048191, -1.187080626171564],
              [-78.31296558256206, -1.190407235906946],
              [-78.31104260633376, -1.191291191416667],
              [-78.30960058371011, -1.190991286920735],
              [-78.30848662877706, -1.190330290035718],
              [-78.30797550032095, -1.18903746528872],
              [-78.30704460615341, -1.188440506143991],
              [-78.30603056350631, -1.188367583586341],
              [-78.30525959605151, -1.189053558542867],
              [-78.30409954065095, -1.188699506952389],
              [-78.30310058542958, -1.187740617228201],
              [-78.29944657855586, -1.18623673615906],
              [-78.29835559003753, -1.185973712037196],
              [-78.2972106220627, -1.185231578329365],
              [-78.29532653786517, -1.184837796517797],
              [-78.29625659384239, -1.186812740545804],
              [-78.29663864098947, -1.189659234866838],
              [-78.29650855385206, -1.191005200880056],
              [-78.29698950745673, -1.192253266264004],
              [-78.29719553463697, -1.193716914197864],
              [-78.29827864416632, -1.195624803000442],
              [-78.29821762391114, -1.196699698264638],
              [-78.2976535218269, -1.197557669874527],
              [-78.29741664924295, -1.198551763592036],
              [-78.2975155557005, -1.199690696596576],
              [-78.29825551011352, -1.202627547833913],
              [-78.29878960498436, -1.203975190227709],
              [-78.29894249089843, -1.205095180131082],
              [-78.29830948957003, -1.207190991202992],
              [-78.29817152344363, -1.208220959466189],
              [-78.29724850826508, -1.208618094038968],
              [-78.29467057012205, -1.207585108290687],
              [-78.29425063677257, -1.206573077300334],
              [-78.29406757600705, -1.205569092937026],
              [-78.29335762880743, -1.204864342517396],
              [-78.29325050808474, -1.203797326242181],
              [-78.29229765333088, -1.202911526713763],
              [-78.29141956515339, -1.201726493241722],
              [-78.29188559897038, -1.200416569412255],
              [-78.2919385725985, -1.199305631964307],
              [-78.29166465200247, -1.198202573505398],
              [-78.29051264322896, -1.197107729311597],
              [-78.28811659238501, -1.195677776628543],
              [-78.28693357056972, -1.193868961921623],
              [-78.28597250155073, -1.1946820065304],
              [-78.28559850103069, -1.197066825624063],
              [-78.28421749866222, -1.196938750143431],
              [-78.28302760368632, -1.196548991645443],
              [-78.281936615168, -1.195960749680012],
              [-78.28116665354158, -1.194703967116709],
              [-78.27986159121595, -1.194549069545928],
              [-78.27844253500703, -1.194972020380533],
              [-78.27637455185365, -1.195250802480416],
              [-78.27294149194786, -1.194425017378819],
              [-78.27176651675963, -1.194630876920996],
              [-78.27312455271338, -1.196909916396521],
              [-78.27293361295887, -1.198283877966276],
              [-78.27651251798005, -1.19965163692774],
              [-78.27719161977596, -1.200749666244747],
              [-78.27640556489543, -1.202705499533522],
              [-78.27439860199723, -1.204059344535665],
              [-78.2733615929354, -1.204500232643113],
              [-78.27317853216988, -1.20553003326819],
              [-78.27428460811394, -1.205875200041305],
              [-78.27467352842164, -1.207076997319689],
              [-78.27432249431634, -1.208297067147043],
              [-78.2763364980132, -1.21021484659542],
              [-78.27631353159848, -1.211614792065063],
              [-78.27536050920655, -1.212644760328203],
              [-78.2729795457883, -1.213165779430085],
              [-78.27090452183627, -1.213181537408104],
              [-78.26894349176752, -1.214417365213421],
              [-78.26797454375952, -1.214742583056932],
              [-78.2667995685713, -1.214867473414358],
              [-78.2667695613579, -1.215879504404711],
              [-78.2689366186069, -1.217472401285647],
              [-78.26727264318696, -1.219928131280255],
              [-78.26347362938819, -1.223140914770397],
              [-78.26181753295724, -1.226591576672888],
              [-78.26099359187432, -1.227440495827295],
              [-78.26035354974725, -1.228415478805573],
              [-78.25885050686844, -1.226669696010561],
              [-78.25751459913909, -1.226894498653962],
              [-78.25653861033243, -1.228953596989925],
              [-78.25546254160177, -1.229341511469272],
              [-78.25469257997536, -1.229999490869147],
              [-78.25315852405475, -1.230938431663731],
              [-78.25186955498323, -1.231108249022213],
              [-78.25283062400223, -1.232040149018133],
              [-78.25510362850746, -1.231997066035774],
              [-78.2561646098124, -1.231248394443469],
              [-78.25750755834042, -1.230861318154439],
              [-78.25888051408184, -1.231160216821934],
              [-78.25985750871688, -1.231812328889589],
              [-78.26084858494926, -1.232066132918021],
              [-78.26223763394479, -1.232176271125809],
              [-78.2635644892187, -1.23265605126403],
              [-78.26706661600672, -1.232014165118244],
              [-78.26786759067491, -1.233997993963669],
              [-78.26902764607547, -1.234488000023759],
              [-78.26933257971328, -1.235517800648836],
              [-78.26679252777262, -1.237042804114026],
              [-78.26609062720006, -1.237981912546672],
              [-78.2640534894504, -1.239922490771676],
              [-78.26176455932912, -1.240922451821405],
              [-78.26036059054593, -1.241553609131131],
              [-78.25930061506938, -1.241643630771307],
              [-78.25717949064982, -1.241252363530691],
              [-78.25567661540907, -1.241476663259903],
              [-78.25490564795427, -1.242307477503402],
              [-78.25424163358409, -1.243517321408888],
              [-78.25310555042664, -1.244546283843647],
              [-78.25419653894497, -1.245044336530839],
              [-78.25541660877232, -1.244738397064623],
              [-78.25743849145819, -1.245870121632436],
              [-78.25814055966882, -1.246729099070649],
              [-78.2577816465745, -1.24768715060452],
              [-78.25753052475514, -1.249068991163256],
              [-78.25708762499093, -1.250034754048045],
              [-78.25360159145703, -1.251115851920645],
              [-78.25244857685513, -1.251006719541181],
              [-78.25290656404506, -1.252100557906601],
              [-78.25543957518704, -1.252256796581946],
              [-78.2567676039274, -1.252564747704866],
              [-78.25807149278658, -1.253334541693221],
              [-78.26031449007841, -1.253716421202284],
              [-78.261611505777, -1.253410481736069],
              [-78.26477852407575, -1.253802587167002],
              [-78.2656475597978, -1.254453525768213],
              [-78.26528160590482, -1.255538479316215],
              [-78.26699050832582, -1.256506421495828],
              [-78.27107953597474, -1.256809343476903],
              [-78.27242265214082, -1.256521341283531],
              [-78.27323955242505, -1.255934272784543],
              [-78.27516956945203, -1.255512495416383],
              [-78.27800751423182, -1.255768311101519],
              [-78.2785496557297, -1.256726362635391],
              [-78.27832049449668, -1.258262094936583],
              [-78.27769453396695, -1.259138171457323],
              [-78.27552764435602, -1.260464188540936],
              [-78.27343753297825, -1.261384018596232],
              [-78.26983649973266, -1.2618768745034],
              [-78.26863051150265, -1.262851857481678],
              [-78.26807361785515, -1.264115848481708],
              [-78.26835659090662, -1.265200634391704],
              [-78.26947758663835, -1.264497895628836],
              [-78.27302564625582, -1.265847549679393],
              [-78.27439860199723, -1.265975792798088],
              [-78.27539856304699, -1.265669685693808],
              [-78.27766452675355, -1.264505774617817],
              [-78.27896154245214, -1.264263872891945],
              [-78.28049459254434, -1.264391780734513],
              [-78.28112055307408, -1.265566755922748],
              [-78.28098258694767, -1.267057561222941],
              [-78.27898350303848, -1.269965411075248],
              [-78.27671854516029, -1.270875182846737],
              [-78.27758054008366, -1.272141185503585],
              [-78.27886163016619, -1.272739318114702],
              [-78.2809215666925, -1.272940148514976],
              [-78.28250155544255, -1.272680141878254],
              [-78.28224959543286, -1.274179999633873],
              [-78.28163956051918, -1.275001090869694],
              [-78.28024263253465, -1.275722772733786],
              [-78.27737451290342, -1.276144047187756],
              [-78.2740785809337, -1.277315669614723],
              [-78.272628511683, -1.276852988558971],
              [-78.27158362363217, -1.277565953243766],
              [-78.27277351860806, -1.278280761947201],
              [-78.27425359507217, -1.278571613987708],
              [-78.27568857689714, -1.279061787685862],
              [-78.27760350649838, -1.280057725422068],
              [-78.27964064424803, -1.280746717863735],
              [-78.28121158054265, -1.282410525645616],
              [-78.28109758665936, -1.283422221359842],
              [-78.2802585581507, -1.284560148536002],
              [-78.27928156351567, -1.285173200934821],
              [-78.27815252115688, -1.285452318310831],
              [-78.27356762011564, -1.287210171046411],
              [-78.27243757192848, -1.287668996426646],
              [-78.27162955646162, -1.288264949742995],
              [-78.27114055622991, -1.28982783941052],
              [-78.27095062230377, -1.292375770340186],
              [-78.27097358871849, -1.295095362647032],
              [-78.2708356225921, -1.296360359475443],
              [-78.27099655513322, -1.297833395140856],
              [-78.27236163188564, -1.297654357688884],
              [-78.27329252605317, -1.296977267549721],
              [-78.27577960436571, -1.296826225654343],
              [-78.27803752144521, -1.295817547425258],
              [-78.27924350967521, -1.295845542981908],
              [-78.28038763945973, -1.296642326698532],
              [-78.28166956773258, -1.296074368938832],
              [-78.28280665671842, -1.296915409104201],
              [-78.283645517589, -1.298064232754541],
              [-78.28498863375508, -1.297650334375305],
              [-78.28614064252858, -1.297045328603588],
              [-78.2849654997023, -1.295915448054473],
              [-78.28305057010105, -1.29495538486384],
              [-78.28223450800712, -1.293544710558137],
              [-78.28085350563866, -1.292720601837118],
              [-78.27864856218727, -1.290739958114955],
              [-78.27916756963238, -1.289817948764778],
              [-78.28198254799744, -1.290101927644628],
              [-78.28285258954786, -1.291340772935087],
              [-78.28378264552506, -1.291874700167853],
              [-78.28480557298955, -1.291956842819047],
              [-78.28620149514569, -1.291605641075705],
              [-78.28892561368025, -1.291762885579431],
              [-78.29064960352699, -1.289622985696724],
              [-78.29195349238617, -1.289533969884928],
              [-78.29184754512993, -1.288186998043386],
              [-78.29145862482223, -1.287247051420422],
              [-78.29139760456705, -1.285955064863742],
              [-78.29237359337371, -1.284808420508284],
              [-78.29364764265756, -1.284041308728945],
              [-78.29507457785549, -1.283997387556269],
              [-78.29606649227819, -1.284288407234783],
              [-78.29621149920325, -1.283240501698799],
              [-78.29440352268664, -1.281177547687378],
              [-78.29240460641549, -1.279910539202149],
              [-78.29098555020657, -1.279294804594315],
              [-78.2903676363039, -1.278453596790882],
              [-78.290786563825, -1.277432680983168],
              [-78.29139760456705, -1.276637908923306],
              [-78.29124455101493, -1.275599055842747],
              [-78.28945954091303, -1.274656929924959],
              [-78.28879552654284, -1.273834162308503],
              [-78.28884162701036, -1.272714004767124],
              [-78.28932962141369, -1.271458395670265],
              [-78.29019949532604, -1.27204613472145],
              [-78.29370162211407, -1.273532246155867],
              [-78.29579961248083, -1.274908051744376],
              [-78.29679152690353, -1.27517107586624],
              [-78.29804260977266, -1.274828926578323],
              [-78.29717256822225, -1.273083981973627],
              [-78.29556257225882, -1.271112223068769],
              [-78.29387663625253, -1.26991746658905],
              [-78.29196153901323, -1.26887660185173],
              [-78.29098555020657, -1.267962471490591],
              [-78.29089351690962, -1.266226579341321],
              [-78.29240460641549, -1.267042641435239],
              [-78.29358662240239, -1.266682722512542],
              [-78.29366256244523, -1.264503930599119],
              [-78.29525763862101, -1.26525679314301],
              [-78.29710350134002, -1.267119419668404],
              [-78.29943853192881, -1.270158362486541],
              [-78.30066664838321, -1.271145247767322],
              [-78.30207061716641, -1.271571216087068],
              [-78.30396258035293, -1.272937298667898],
              [-78.30401655980944, -1.271482367913336],
              [-78.30361154624762, -1.269060500807598],
              [-78.30386350625731, -1.267651502882472],
              [-78.3026275108139, -1.263592985361129],
              [-78.30068961479795, -1.262017858114973],
              [-78.29999559321436, -1.261085958119054],
              [-78.29817957007069, -1.259449140065442],
              [-78.2970805349253, -1.258833070181424],
              [-78.2960286060758, -1.257757169088848],
              [-78.29430361040069, -1.255414594787169],
              [-78.29470057733545, -1.254448664264373],
              [-78.29499863781264, -1.253074535056498],
              [-78.29431953601673, -1.252197620345441],
              [-78.29288455419176, -1.2516266451006],
              [-78.29201551846973, -1.250939664315695],
              [-78.29096962459052, -1.250477821450318],
              [-78.29111463151558, -1.249203772166481],
              [-78.2917176256306, -1.248037849433672],
              [-78.29301464132917, -1.247984875805571],
              [-78.29447963036753, -1.247635182804743],
              [-78.29562359251398, -1.246895060753673],
              [-78.2965776207343, -1.247646079278923],
              [-78.29819448985835, -1.24828008643567],
              [-78.29803456314562, -1.246932946956065],
              [-78.29925563880136, -1.246302124922465],
              [-78.3003695937344, -1.246502284770486],
              [-78.3024595374741, -1.246061061386968],
              [-78.30455065468027, -1.247618083722273],
              [-78.30608353713443, -1.25044798187497],
              [-78.30642652461268, -1.25178556598496],
              [-78.30638159761162, -1.253493630215644],
              [-78.30757853338619, -1.253630590513694],
              [-78.30868561515864, -1.252971772923445],
              [-78.3094636234121, -1.251725719196259],
              [-78.31024163166558, -1.248264999009962],
              [-78.31087463299397, -1.247370985216435],
              [-78.31118761325884, -1.246025186841337],
              [-78.31088251198297, -1.243603319735541],
              [-78.31000559727192, -1.240782641676333],
              [-78.30942556957164, -1.239571791942467],
              [-78.30858654106301, -1.238567639941095],
              [-78.30816660771353, -1.237482854031157],
              [-78.30893656933993, -1.236516755870241],
              [-78.30929565007231, -1.235486787607101],
              [-78.30906665647734, -1.234294042784086],
              [-78.31060758555854, -1.234511972266887],
              [-78.31195858071362, -1.235200126518237],
              [-78.31385758469882, -1.236539722284988],
              [-78.31505552630178, -1.237136681429661],
              [-78.31638355504212, -1.237445638381018],
              [-78.31816856514403, -1.235612851430972],
              [-78.32091565009331, -1.235371787895417],
              [-78.32109854322077, -1.23438708190946],
              [-78.32077751632885, -1.233058214978769],
              [-78.32035858880775, -1.232145258084074],
              [-78.32047258269104, -1.23109735254809],
              [-78.32161755066588, -1.230646406156836],
              [-78.32215851869732, -1.231560201241848],
              [-78.32239555891934, -1.233087048725736],
              [-78.32217360612304, -1.234099079716088],
              [-78.32320357438621, -1.236215007355668],
              [-78.32322654080093, -1.237307839892651],
              [-78.32231861304813, -1.237930782937269],
              [-78.32174663197489, -1.238869556093789],
              [-78.32138051044386, -1.240405623671165],
              [-78.32133457761441, -1.242511325388818],
              [-78.32175451096388, -1.244997397872964],
              [-78.32260862689826, -1.247699891097341],
              [-78.32254056584442, -1.248712089725757],
              [-78.32163951125222, -1.251367644292259],
              [-78.32206749122875, -1.252334580643492],
              [-78.32196053814414, -1.253410481736069],
              [-78.32107557680605, -1.253951449767499],
              [-78.31974050726701, -1.254284378961984],
              [-78.31922954644897, -1.255187445210936],
              [-78.3188625867276, -1.256353367943746],
              [-78.31752751718858, -1.258303166262181],
              [-78.31915260057774, -1.260681950385617],
              [-78.32021358188268, -1.261821889218538],
              [-78.32069352965895, -1.262762003479565],
              [-78.32112855043417, -1.264108640044981],
              [-78.32051851552049, -1.26492185229182],
              [-78.32077751632885, -1.266557496878988],
              [-78.32153356399598, -1.267768514250918],
              [-78.32269261356815, -1.267191504035793],
              [-78.32348654743768, -1.264815737397498],
              [-78.3243565889881, -1.264030688345372],
              [-78.32463050958413, -1.262693942425699],
              [-78.32472254288108, -1.260145173305773],
              [-78.32579760578336, -1.257851214042546],
              [-78.32653856602477, -1.2567944236892],
              [-78.32797958282002, -1.256344483126327],
              [-78.3290864969544, -1.257213183572276],
              [-78.32866656360493, -1.258396205387555],
              [-78.32933862460216, -1.259743177229097],
              [-78.33055852679145, -1.26418173024075],
              [-78.33162654889506, -1.264580708832284],
              [-78.33259549690304, -1.264102772712761],
              [-78.33462559385403, -1.264176701098847],
              [-78.33539555548043, -1.263490726142322],
              [-78.33660858450911, -1.263220996497864],
              [-78.33772253944217, -1.263872773289393],
              [-78.33734853892213, -1.265119832845016],
              [-78.33729556529401, -1.266131528559242],
              [-78.33750159247425, -1.26821057582481],
              [-78.33628856344558, -1.268597316837656],
              [-78.33397649927151, -1.268866543567924],
              [-78.33127551478974, -1.268935442812108],
              [-78.33022358594025, -1.269232497460905],
              [-78.32964355823997, -1.270109412171962],
              [-78.3286435971902, -1.271111384878452],
              [-78.32876563770054, -1.272484340619883],
              [-78.32806356948991, -1.273342144591652],
              [-78.32736150127928, -1.274660953238481],
              [-78.3266145060676, -1.275463939563451],
              [-78.32761362892704, -1.275916897611467],
              [-78.32866656360493, -1.275113911286553],
              [-78.32939159823027, -1.274148148401764],
              [-78.3304825867486, -1.273914125664874],
              [-78.33180960966058, -1.274493985727077],
              [-78.33233649609468, -1.275668960915311],
              [-78.33216852275488, -1.276680824267601],
              [-78.33408361999419, -1.276683003562425],
              [-78.3351516420978, -1.277334947992017],
              [-78.33766956581405, -1.28006661023943],
              [-78.33732557250741, -1.281521373355929],
              [-78.33513655467208, -1.282865495350393],
              [-78.33452551393002, -1.283660267410255],
              [-78.33354952512336, -1.284535170464494],
              [-78.3333735051565, -1.285692208379942],
              [-78.33442660747245, -1.286425289632348],
              [-78.3356245490754, -1.286562249930341],
              [-78.33670061780607, -1.285813075423846],
              [-78.33792152582375, -1.285254170119572],
              [-78.34022554337075, -1.281741649771561],
              [-78.34071353777408, -1.280431558304031],
              [-78.34135458572953, -1.279392705223529],
              [-78.34252956091777, -1.279447690508391],
              [-78.34344452946924, -1.279015854856368],
              [-78.34468052491265, -1.278980650863048],
              [-78.34577956005803, -1.279605773202434],
              [-78.34671749502424, -1.280437593274314],
              [-78.34683249473592, -1.281793617571338],
              [-78.3475794899476, -1.284170390037957],
              [-78.34674062907703, -1.286781185241466],
              [-78.34869361251872, -1.287451905134219],
              [-78.34968552694143, -1.287155185761492],
              [-78.3507615956721, -1.287183181318142],
              [-78.35266864628434, -1.288279031340323],
              [-78.35346962095254, -1.289346047615538],
              [-78.35272949890145, -1.29127874685156],
              [-78.35157749012795, -1.291919627168966],
              [-78.35013563514237, -1.293960452956014],
              [-78.35003656104675, -1.295044568313699],
              [-78.35099058926706, -1.297720407085933],
              [-78.34988350749461, -1.298442088950026],
              [-78.34847249791275, -1.298892197150963],
              [-78.34815264448727, -1.300302200904468],
              [-78.34717564985223, -1.301149946592375],
              [-78.34655756831151, -1.302233894312053],
              [-78.3462595078343, -1.304227781441284],
              [-78.34805256456326, -1.303482965524438],
              [-78.34913651228293, -1.303998787846297],
              [-78.35017352134476, -1.303312980527892],
              [-78.35073058263032, -1.302085031711499],
              [-78.35401964143942, -1.301329151682467],
              [-78.35504156307553, -1.301321105055422],
              [-78.35633053214704, -1.300681230566397],
              [-78.35852055581077, -1.300268002739472],
              [-78.3597416314665, -1.300242018839583],
              [-78.3594435709893, -1.299049274016625],
              [-78.35835258247097, -1.2983701722207],
              [-78.35791756169576, -1.297321428494399],
              [-78.35800959499271, -1.295712438359374],
              [-78.35916864456489, -1.295858283474729],
              [-78.36264059650146, -1.295762394502333],
              [-78.36406753169938, -1.295393590762274],
              [-78.3648374933258, -1.294644416255778],
              [-78.36434161993347, -1.293387466054412],
              [-78.3632655512028, -1.292862591277071],
              [-78.36179352136575, -1.2931955204715],
              [-78.36083261998483, -1.292651534954928],
              [-78.3598556253498, -1.291828599700352],
              [-78.35860454248066, -1.291628607490395],
              [-78.35852055581077, -1.290055994815191],
              [-78.35919161097961, -1.289288883035852],
              [-78.36149562852663, -1.288749088470865],
              [-78.36210650163062, -1.287891116861033],
              [-78.36105356695275, -1.287292984249859],
              [-78.36003164531664, -1.287309915694266],
              [-78.35878760324618, -1.286739946277805],
              [-78.35816164271645, -1.285582070172097],
              [-78.3547745156401, -1.283889428643249],
              [-78.35530056388389, -1.282723505910496],
              [-78.35920753659566, -1.282023449356586],
              [-78.36028259949795, -1.281183415019598],
              [-78.36095449285712, -1.278717626741127],
              [-78.36093856724106, -1.27768682028767],
              [-78.3599625784344, -1.277206872511385],
              [-78.35708657981418, -1.277393788952395],
              [-78.35474350259832, -1.27833088572828],
              [-78.35379752100506, -1.278917786589147],
              [-78.35295061350743, -1.279829570017398],
              [-78.35192165107266, -1.280343715958679],
              [-78.3511805231932, -1.279140577575788],
              [-78.35084558234199, -1.278064844121218],
              [-78.34939551309128, -1.275650856004461],
              [-78.34847249791275, -1.275242992595565],
              [-78.3474425296496, -1.272865214300509],
              [-78.3482056181154, -1.272017133336419],
              [-78.35192852423327, -1.27249004031404],
              [-78.35324850634656, -1.272419129413095],
              [-78.35522462384105, -1.271861229937258],
              [-78.35642256544399, -1.271691077302592],
              [-78.35752864138806, -1.27177338759185],
              [-78.35836062909803, -1.270653397688534],
              [-78.3588105696609, -1.269705404438525],
              [-78.35866556273584, -1.268647440618736],
              [-78.35788755448236, -1.267716378813134],
              [-78.35662054599719, -1.268392630761923],
              [-78.35530861051095, -1.267514710222542],
              [-78.3549724961933, -1.26652078414304],
              [-78.35372158096223, -1.266744580958061],
              [-78.35305756659204, -1.265560553314401],
              [-78.35132553011826, -1.263732963144321],
              [-78.35002164125908, -1.263035756437546],
              [-78.35022750080125, -1.261690125700511],
              [-78.3499145205364, -1.260695193792628],
              [-78.3498305338665, -1.259547040694599],
              [-78.3511126297774, -1.257181332340167],
              [-78.35063955516175, -1.255816590863844],
              [-78.3505096356624, -1.254288402275506],
              [-78.35122662366071, -1.251975667549175],
              [-78.35024258822699, -1.25097185082393],
              [-78.34738955602147, -1.24890001199509],
              [-78.34741956323487, -1.247598134792668],
              [-78.34524563282527, -1.246015128557474],
              [-78.34627560108842, -1.245157156947641],
              [-78.34658053472623, -1.241696604399408],
              [-78.34565751954769, -1.24101750260354],
              [-78.34437559127484, -1.240700499025138],
              [-78.34376555636116, -1.239814699496719],
              [-78.34355165019193, -1.236469817212424],
              [-78.34368861048993, -1.235403974403653],
              [-78.34426863819023, -1.234256994772011],
              [-78.34450551077418, -1.232775074289236],
              [-78.34413151025414, -1.231202126337848],
              [-78.34303264274682, -1.230704241288777],
              [-78.34325459554312, -1.229602523934375],
              [-78.34252151429071, -1.228679341117754],
              [-78.34329264938357, -1.228011471072136],
              [-78.34445957794473, -1.227452565767862],
              [-78.3451846125701, -1.226621751524362],
              [-78.34537454649622, -1.225320544874194],
              [-78.34498562618852, -1.222817038031394],
              [-78.34514655872964, -1.221443914651957],
              [-78.34604660749345, -1.220369019387704],
              [-78.34600855365301, -1.217721343810183],
              [-78.34693156883154, -1.215788476936154],
              [-78.34693156883154, -1.21476739349032],
              [-78.34593965440884, -1.213681434113937],
              [-78.34484849825245, -1.212804687040943],
              [-78.34427651717921, -1.211972866969063],
              [-78.34307052894921, -1.211744711564393],
              [-78.34192656680275, -1.211788632737068],
              [-78.33828764735479, -1.212318536656312],
              [-78.33900463535308, -1.211090923116103],
              [-78.3432616363418, -1.208708953869518],
              [-78.34391760408491, -1.207841929804204],
              [-78.34387955024445, -1.206830234089978],
              [-78.34343765630864, -1.20448933616899],
              [-78.3424375276208, -1.20233753398378],
              [-78.34061463131651, -1.200229485333182],
              [-78.3394926297564, -1.200038545578707],
              [-78.33795153303714, -1.198510692266439],
              [-78.34014859749952, -1.197373770918659],
              [-78.34103355883761, -1.196660973871928],
              [-78.34165951936734, -1.194303144506534],
              [-78.34146153881416, -1.193200086047568],
              [-78.34150663345328, -1.19176309256585],
              [-78.34104965209173, -1.190416456000435],
              [-78.34249854787598, -1.190192323909343],
              [-78.34361250280904, -1.18960542304842],
              [-78.34399454995614, -1.193319947263092],
              [-78.34381065100028, -1.197142933304974],
              [-78.34588651314266, -1.200506591052431],
              [-78.34687054857636, -1.201202624292762],
              [-78.3473285357663, -1.202278357747332],
              [-78.34803060397692, -1.203092240546425],
              [-78.34876251176289, -1.205497176207757],
              [-78.34930465326079, -1.206447181114527],
              [-78.34939551309128, -1.207449992011391],
              [-78.34980756745178, -1.208407875907142],
              [-78.3510665293099, -1.208625805389943],
              [-78.35200463191417, -1.207949050526906],
              [-78.35395862118425, -1.207191997031373],
              [-78.35485850231001, -1.206434105345579],
              [-78.35579660491427, -1.205929011859723],
              [-78.35658249215673, -1.205188051618336],
              [-78.35775763498303, -1.204611376679338],
              [-78.35918457018094, -1.202877328548766],
              [-78.35991664560497, -1.201821544023801],
              [-78.36042056562434, -1.200710438937847],
              [-78.36134358080288, -1.199536469577993],
              [-78.36172462212159, -1.198587638137667],
              [-78.36186258824799, -1.197557669874527],
              [-78.36290764393688, -1.197965700921486],
              [-78.3651585202177, -1.197190877791229],
              [-78.36605052235447, -1.196476907278054],
              [-78.36690564411722, -1.195438892387813],
              [-78.36797349858277, -1.193099000295206],
              [-78.3684614929861, -1.191708274919051],
              [-78.36859862092217, -1.190443445728704],
              [-78.36986562940736, -1.19025434999287],
              [-78.37000258970536, -1.191384230541985],
              [-78.3696056227706, -1.192865983386696],
              [-78.36956756893015, -1.194103990486894],
              [-78.3699115622368, -1.195351050042461],
              [-78.36890456038836, -1.19612788482948],
              [-78.36693565133062, -1.198917549846954],
              [-78.3659895020993, -1.200001665204695],
              [-78.36559253516454, -1.201212347300441],
              [-78.36616552206614, -1.202044335010442],
              [-78.36528760152673, -1.202549260858177],
              [-78.36412050532749, -1.202864420417882],
              [-78.36331165167032, -1.20361325964825],
              [-78.36260958345967, -1.20600830466384],
              [-78.36253364341684, -1.207100969562816],
              [-78.36141952084573, -1.207696922879165],
              [-78.3607635531026, -1.208553888660617],
              [-78.35947458403108, -1.209519986821533],
              [-78.35732261420782, -1.213015743363087],
              [-78.35709362061284, -1.215382457545957],
              [-78.35829960884286, -1.216206230990792],
              [-78.35968765201, -1.216234394185506],
              [-78.35886354328902, -1.218213361527035],
              [-78.3582075755459, -1.219080050316165],
              [-78.35723862753792, -1.219965011654267],
              [-78.35638451160355, -1.223397736283971],
              [-78.35654460595434, -1.224924583767802],
              [-78.35689564005966, -1.225937788224599],
              [-78.35624654547715, -1.227301523872541],
              [-78.35682657317743, -1.228666600624933],
              [-78.35688055263392, -1.229742501717567],
              [-78.35749863417466, -1.230601311517717],
              [-78.35811654807733, -1.231984325542953],
              [-78.35981757150934, -1.234281972843519],
              [-78.36011462615815, -1.235484105398086],
              [-78.36128960134639, -1.235900853624344],
              [-78.36229660319482, -1.235504054327635],
              [-78.36476859408162, -1.235605810632308],
              [-78.36692056390488, -1.234468889284528],
              [-78.3676905255313, -1.23353900094537],
              [-78.36861454653823, -1.233991958993386],
              [-78.36955264914249, -1.234698050517522],
              [-78.36988054919502, -1.235972770353669],
              [-78.36964451480138, -1.237336841277681],
              [-78.37377159629075, -1.237205748311908],
              [-78.37485453818202, -1.237314880691372],
              [-78.37437459040575, -1.238678616339257],
              [-78.3746335912141, -1.239826601799223],
              [-78.37435950298003, -1.240938377437487],
              [-78.373619548567, -1.241895423142921],
              [-78.37350454885534, -1.24339528089854],
              [-78.37293960858078, -1.244515103163849],
              [-78.3737335424503, -1.245393191341293],
              [-78.37506861198932, -1.245918066118634],
              [-78.37641860131602, -1.245748081122088],
              [-78.37745661620623, -1.246020325337497],
              [-78.3783725905861, -1.246464063291967],
              [-78.37889059220282, -1.247549184478089],
              [-78.37989759405126, -1.247279119557504],
              [-78.38042464812342, -1.246285193478059],
              [-78.38160649647226, -1.245482039515025],
              [-78.38240059797984, -1.244615350725894],
              [-78.38327751269087, -1.243495360822578],
              [-78.38367464726369, -1.242556252389875],
              [-78.38378864114698, -1.241327465383222],
              [-78.38626063203378, -1.241194528398751],
              [-78.38732949232772, -1.241845466999962],
              [-78.38790164103901, -1.243039217651301],
              [-78.38882465621755, -1.24421536630598],
              [-78.38970961755564, -1.244912237736628],
              [-78.39086162632914, -1.245175094220485],
              [-78.39196753463513, -1.246044297580568],
              [-78.39331065080123, -1.247870881922267],
              [-78.3942795988092, -1.248322834141902],
              [-78.39495065397806, -1.249534857342212],
              [-78.3959885012302, -1.249166053602153],
              [-78.39710949696193, -1.249347940901202],
              [-78.39827759898955, -1.249962837318719],
              [-78.3987885598076, -1.248797920414347],
              [-78.398918646945, -1.247804161972908],
              [-78.39900950677551, -1.246593144601036],
              [-78.39879660643466, -1.244993206921379],
              [-78.39794165230997, -1.243700214536375],
              [-78.3977666381715, -1.242714502721981],
              [-78.39781156517256, -1.239895501043407],
              [-78.39752959794947, -1.23829573100187],
              [-78.39691151640875, -1.23682168950802],
              [-78.39608757532584, -1.236080058714379],
              [-78.39579756147569, -1.234887146253357],
              [-78.39734653718395, -1.23291806955757],
              [-78.39761358461938, -1.231888268932437],
              [-78.39770561791633, -1.230342478347382],
              [-78.39850659258451, -1.228505500445749],
              [-78.39939859472128, -1.230001502525909],
              [-78.39955852143402, -1.231058292879254],
              [-78.40040559656971, -1.233373039262347],
              [-78.40131352432252, -1.237042804114026],
              [-78.40380864926212, -1.240099684204949],
              [-78.40464751013269, -1.239124701226672],
              [-78.40510549732262, -1.236422710916543],
              [-78.40435062312194, -1.232410293862699],
              [-78.40396857597484, -1.231253255947308],
              [-78.40391560234673, -1.229761444818735],
              [-78.40411358289992, -1.227140423693356],
              [-78.40447249599423, -1.225930747425934],
              [-78.40406765007047, -1.22468268204193],
              [-78.40602851250117, -1.222994734378915],
              [-78.40630360656364, -1.221983038664689],
              [-78.4070516076037, -1.221278958797313],
              [-78.40767656230506, -1.222327870161621],
              [-78.40753155537999, -1.223664951357421],
              [-78.40835549646289, -1.224632725898971],
              [-78.40859446428644, -1.225281152953944],
              [-78.40902288722975, -1.229799067628845],
              [-78.41098225229887, -1.229876746275899],
              [-78.41159862244255, -1.231296338929667],
              [-78.41340659895918, -1.234344166565165],
              [-78.41387950593679, -1.23565492858495],
              [-78.41488650778523, -1.235112954725082],
              [-78.41540551523033, -1.234201171296831],
              [-78.41606852377213, -1.230939437492111],
              [-78.41575654933564, -1.229935453128803],
              [-78.41565755035587, -1.229756087476393],
              [-78.4176522087449, -1.22552961098076],
              [-78.41800658742615, -1.225419618969795],
              [-78.41787045041858, -1.225067179263492],
              [-78.4213704091875, -1.217651125690606],
              [-78.4216068263503, -1.217575895836205],
              [-78.42570588092605, -1.221999931427696],
              [-78.42513254623643, -1.222777810724494],
              [-78.4247815121311, -1.224323768947613],
              [-78.42575062777716, -1.225028854643426],
              [-78.42608657445675, -1.226204500383915],
              [-78.4272766370707, -1.228220515737519],
              [-78.42847457867364, -1.228809595893267],
              [-78.43116751652836, -1.228070479670521],
              [-78.4312725811204, -1.228007970435681],
              [-78.43134528493097, -1.228086438345706],
              [-78.43200247708808, -1.232894999375411],
              [-78.43191551756844, -1.232987974630078],
              [-78.43141964417612, -1.233982068347643],
              [-78.43138863113434, -1.234985046882514],
              [-78.4323444685434, -1.235397291538727],
              [-78.43239656712775, -1.235778487844416],
              [-78.43167864498447, -1.236494795283875],
              [-78.43164059114403, -1.237515711091646],
              [-78.43106056344374, -1.238644753450387],
              [-78.43130464446443, -1.239620742257046],
              [-78.43113750931496, -1.240650710520242],
              [-78.4324185993975, -1.240986489561749],
              [-78.43305160072589, -1.241872456728231],
              [-78.43324328632575, -1.241973785360493],
              [-78.43358473411226, -1.24447209959346],
              [-78.4334866215011, -1.244648375424447],
              [-78.43370401054847, -1.245344824589839],
              [-78.43552329234707, -1.258656193972664],
              [-78.43482957002912, -1.259109170072293],
              [-78.43542451751706, -1.261368931170523],
              [-78.43532561105951, -1.26261599072609],
              [-78.43402054873387, -1.262055073765112],
              [-78.43247961965267, -1.263760958700971],
              [-78.43341856044725, -1.264502924770738],
              [-78.43391460147764, -1.26556977340789],
              [-78.43355552074527, -1.266798728052606],
              [-78.43265563961951, -1.267466598098281],
              [-78.43436454204051, -1.268489525562757],
              [-78.43674449963036, -1.269350514657731],
              [-78.43699033059467, -1.269390258386127],
              [-78.4370410143853, -1.269761102891519],
              [-78.44079380616948, -1.276853111094305],
              [-78.44076704401745, -1.277528148102698],
              [-78.4402234923656, -1.277767621834357],
              [-78.44056664748193, -1.280090582482558],
              [-78.44065887969457, -1.280256438445412],
              [-78.44051039383442, -1.284001781895939],
              [-78.43937658486797, -1.285196167349511],
              [-78.43817160246635, -1.28431824681013],
              [-78.43716460061792, -1.28475025010016],
              [-78.43728664112827, -1.285799161464524],
              [-78.43713358757614, -1.286793087544027],
              [-78.43740063501156, -1.288238965843107],
              [-78.43666051296047, -1.289277986561729],
              [-78.43695857343766, -1.290371824927149],
              [-78.43798854170083, -1.291176822908824],
              [-78.44021561337661, -1.293908485156237],
              [-78.44125362826684, -1.293864563983561],
              [-78.44154176470789, -1.292933020567278],
              [-78.44324565381777, -1.292031410302248],
              [-78.44393952532288, -1.293225527684911],
              [-78.44491551412952, -1.293470614533987],
              [-78.44591564281735, -1.293191664796041],
              [-78.44702155112336, -1.292469647655821],
              [-78.44790651246144, -1.293202561270164],
              [-78.44908165528773, -1.293483522664872],
              [-78.44960049509477, -1.292219866940968],
              [-78.44937150149981, -1.290826794631926],
              [-78.44937150149981, -1.289362979060002],
              [-78.44921691398652, -1.288871727297009],
              [-78.45050725396685, -1.288188945908891],
              [-78.45862299108245, -1.307002700131429],
              [-78.4584375682216, -1.307078590378398],
              [-78.453907865413, -1.337728219496995],
              [-78.46534515507052, -1.346556268160374],
              [-78.46665836774301, -1.355441163865776],
              [-78.46240452781393, -1.375598974756235],
              [-78.45696483045943, -1.379797729114173],
              [-78.45456755616569, -1.384620479740394],
              [-78.45214207859122, -1.39663505150231],
              [-78.44974480432418, -1.397847789950098],
              [-78.44974480429693, -1.401457802128166],
              [-78.44399927805875, -1.424592374754854],
              [-78.44252477810345, -1.42672788731835],
              [-78.46437787189053, -1.432976002438499],
              [-78.48346043918424, -1.460124025691789],
              [-78.47903341368254, -1.495714947117751],
              [-78.4621802230634, -1.500533511210259],
              [-78.45008280152739, -1.505183517032244],
              [-78.45229675466814, -1.522983935375722],
              [-78.45760774346012, -1.529402918609438],
              [-78.45740167105673, -1.54602609248296],
              [-78.4563610621344, -1.545626100871666],
              [-78.45711557086729, -1.546654976416503],
              [-78.4564585682793, -1.546173763758645],
              [-78.45493356481414, -1.546605599410668],
              [-78.4535755288604, -1.54590772215164],
              [-78.45197257369563, -1.545941752678516],
              [-78.45128659873912, -1.546817661561192],
              [-78.45030960410408, -1.545514610892326],
              [-78.44776150553636, -1.544915975366962],
              [-78.44734961881394, -1.546234616375784],
              [-78.4464566108488, -1.548194472978082],
              [-78.44381765245059, -1.552113347992361],
              [-78.44222257627482, -1.552844082311879],
              [-78.44094064800197, -1.55405325566511],
              [-78.44039163334347, -1.552597151444104],
              [-78.44084157390634, -1.55139619235598],
              [-78.44078860027822, -1.549742442857962],
              [-78.44215350939258, -1.54658062133916],
              [-78.44253555653968, -1.544909940396678],
              [-78.44248157708317, -1.543851808938825],
              [-78.44093360720329, -1.542196047783989],
              [-78.43951455099437, -1.541254927694581],
              [-78.43857561019978, -1.539880295572516],
              [-78.43784353477575, -1.541459949046441],
              [-78.43663754654575, -1.542542890937739],
              [-78.43553163823975, -1.542631906749534],
              [-78.43457761001943, -1.539974340526271],
              [-78.43296057325732, -1.539981213686872],
              [-78.43144965138951, -1.540594098447627],
              [-78.4303895082749, -1.540746146171386],
              [-78.4310225096033, -1.544118018183951],
              [-78.43096148934812, -1.545871847606008],
              [-78.43042756211534, -1.548807357738838],
              [-78.42958065461771, -1.550225575757452],
              [-78.4278796311857, -1.54989834625718],
              [-78.42589563470223, -1.547627688684827],
              [-78.42522457953338, -1.546560672409612],
              [-78.42491159926851, -1.544824947898405],
              [-78.42451463233375, -1.543722057077559],
              [-78.42367560382512, -1.543150914194655],
              [-78.42156956683128, -1.543771007392138],
              [-78.42037950421734, -1.547512688973143],
              [-78.42037950421734, -1.548641396055814],
              [-78.42166160012825, -1.550505531323779],
              [-78.42237858812653, -1.552078143998983],
              [-78.42169965396869, -1.553433162467627],
              [-78.41994465108019, -1.553801295655433],
              [-78.41886858234952, -1.553827111917201],
              [-78.41727451200212, -1.553223111973807],
              [-78.41842652077564, -1.555544060965246],
              [-78.41948649625219, -1.556981892637339],
              [-78.41976963694172, -1.558699847513765],
              [-78.42058553139758, -1.560272795465153],
              [-78.42194356735132, -1.559822854902279],
              [-78.42311854253956, -1.558549643808703],
              [-78.42482761259862, -1.559636776651587],
              [-78.42642252113633, -1.562114634870625],
              [-78.42739850994299, -1.561447435377204],
              [-78.4290316399592, -1.561178711561183],
              [-78.43006160822236, -1.561821435897286],
              [-78.43111454290025, -1.563169581205273],
              [-78.43103759702902, -1.564868425342468],
              [-78.43119065058114, -1.566522342478549],
              [-78.43211349812162, -1.567996216334279],
              [-78.4333265271503, -1.569289879271594],
              [-78.43653864008819, -1.570134775112479],
              [-78.43780564857337, -1.570868024002948],
              [-78.43781252173397, -1.572440636678209],
              [-78.43845356968943, -1.573968657628484],
              [-78.43817160246635, -1.575721648860224],
              [-78.43836254222086, -1.577493415555125],
              [-78.4397276189733, -1.578588259748926],
              [-78.44142160160662, -1.579042391263386],
              [-78.44446557356665, -1.57870325946061],
              [-78.44614463641231, -1.578759418211916],
              [-78.4483795870771, -1.579241210006899],
              [-78.44866960092725, -1.58055230730281],
              [-78.44758565320758, -1.580821031118887],
              [-78.44844060733227, -1.582195998517022],
              [-78.44295465169884, -1.583372985362018],
              [-78.44062062693843, -1.583577839075815],
              [-78.43975058538801, -1.584426925868286],
              [-78.43970465255856, -1.58553870150655],
              [-78.43990363894014, -1.586659529600183],
              [-78.44084157390634, -1.588043717091864],
              [-78.4423905496146, -1.588813678718282],
              [-78.4463726241789, -1.591312324057185],
              [-78.44703663854908, -1.592868340564166],
              [-78.44726563214405, -1.594015990748005],
              [-78.44601454927492, -1.593987157001038],
              [-78.44457252665127, -1.592982334447413],
              [-78.44334457783492, -1.592556198489604],
              [-78.44164254857453, -1.592228130799015],
              [-78.43869060991146, -1.592252270680206],
              [-78.4355235916127, -1.593079229248247],
              [-78.43426563558296, -1.592092176329402],
              [-78.4332425404804, -1.590753251114904],
              [-78.43254851889684, -1.58744558448069],
              [-78.43182365190955, -1.585302667112899],
              [-78.43183857169721, -1.583774981438694],
              [-78.43033552881839, -1.580520120794574],
              [-78.42887858640708, -1.578548361889773],
              [-78.42802463811077, -1.577028555204549],
              [-78.42649862881723, -1.576267310757487],
              [-78.42545357312834, -1.57510054983436],
              [-78.4200055036973, -1.571722642851512],
              [-78.4171145852894, -1.571185698133604],
              [-78.41556560958112, -1.571725660336654],
              [-78.41421562025444, -1.572528814299687],
              [-78.41349058562908, -1.573531792834558],
              [-78.41441360080762, -1.574490682558746],
              [-78.41587054321893, -1.575531379658003],
              [-78.4172515455874, -1.575948463160387],
              [-78.41700763220477, -1.577231229623521],
              [-78.41653455758912, -1.578523383818265],
              [-78.41752663964988, -1.579907068395755],
              [-78.41874654183917, -1.580569071109153],
              [-78.41876950825389, -1.582421136436551],
              [-78.41836550052047, -1.583496031700747],
              [-78.41662558505767, -1.583258991478772],
              [-78.41534449497516, -1.582019140359876],
              [-78.41372662002273, -1.582352069554304],
              [-78.41295649075825, -1.5857666892731],
              [-78.41325354540706, -1.587466539238676],
              [-78.41632065141988, -1.591699400346215],
              [-78.41851050744553, -1.593944241656743],
              [-78.4199676174949, -1.595770993636506],
              [-78.42111157964135, -1.596793921100982],
              [-78.422553602265, -1.597680726457781],
              [-78.42478955875816, -1.600132600776931],
              [-78.42637658830688, -1.600731571578422],
              [-78.42713163014562, -1.60221466552764],
              [-78.43128955703871, -1.607786954763867],
              [-78.43231952530186, -1.610852049119956],
              [-78.43334949356503, -1.61208284778337],
              [-78.43361654100045, -1.613682617824907],
              [-78.43434861642447, -1.6151566593187],
              [-78.43449362334954, -1.616701611713438],
              [-78.43268564683291, -1.616184615925135],
              [-78.43165551093169, -1.615433597399885],
              [-78.42947353389502, -1.613369805198147],
              [-78.42635362189216, -1.609768939590595],
              [-78.42507152598125, -1.608555910561904],
              [-78.41892960260469, -1.603695412544027],
              [-78.41761749948039, -1.604769469617906],
              [-78.41699254477905, -1.606196237177755],
              [-78.41583248937849, -1.60569818449062],
              [-78.41492456162567, -1.606718094469954],
              [-78.4144746210628, -1.615564522727595],
              [-78.41479464212632, -1.617969290750921],
              [-78.41468064824303, -1.619695292254391],
              [-78.41383357310734, -1.621095237724035],
              [-78.41275063121607, -1.620732133678132],
              [-78.4111255478269, -1.620648314646303],
              [-78.41058357396707, -1.617511471199009],
              [-78.40950063207579, -1.614799422605074],
              [-78.40875950419633, -1.613153719734044],
              [-78.40742460229536, -1.61240169538047],
              [-78.40799758919697, -1.610865795441157],
              [-78.40821048953782, -1.606104371518882],
              [-78.40547949784266, -1.605621238619392],
              [-78.4026105400211, -1.606223394544088],
              [-78.39941351450894, -1.60404124986934],
              [-78.39830760620295, -1.603895404753985],
              [-78.3976665582475, -1.605367266952953],
              [-78.39747662432136, -1.60655112695855],
              [-78.39681260995118, -1.608185933355401],
              [-78.39669056944084, -1.611176931687339],
              [-78.39635462276125, -1.612920870463654],
              [-78.39575950763523, -1.614555676860505],
              [-78.39536254070048, -1.616263405815062],
              [-78.3953015204453, -1.618025449502227],
              [-78.39452351219184, -1.619597223987171],
              [-78.39433257243732, -1.621340995125365],
              [-78.39288350901501, -1.622261160456844],
              [-78.39222754127188, -1.62390501930912],
              [-78.3910145122432, -1.625185941753614],
              [-78.39064755252184, -1.626893670708171],
              [-78.38973962476902, -1.62832043826802],
              [-78.38799250086952, -1.628580612542805],
              [-78.38655852487292, -1.629445624951359],
              [-78.38565864374716, -1.628839613351204],
              [-78.38420052786941, -1.629795485590193],
              [-78.38294257183966, -1.631076408034687],
              [-78.38200363104508, -1.632512395688025],
              [-78.38156861026987, -1.634256166826276],
              [-78.38145461638658, -1.636686918749433],
              [-78.38154564385515, -1.641006616373943],
              [-78.38072153513417, -1.641809602698856],
              [-78.37811258394935, -1.641526462009381],
              [-78.37719761539788, -1.640856747945008],
              [-78.3761515538806, -1.641930637380824],
              [-78.3750535245636, -1.641205770393583],
              [-78.37231465387944, -1.642829512678247],
              [-78.3707655105331, -1.641543561091851],
              [-78.36978164273746, -1.640457601715411],
              [-78.37135358486044, -1.638263722376223],
              [-78.37178759980728, -1.636701000346761],
              [-78.37255856726208, -1.63520198078146],
              [-78.3739015157901, -1.634282150726108],
              [-78.37474859092579, -1.633397357026126],
              [-78.37403059709911, -1.62920338794936],
              [-78.37606052641203, -1.626368628292767],
              [-78.37554956559399, -1.624786795524017],
              [-78.37409949634328, -1.624187992360589],
              [-78.37106357101031, -1.624030915494984],
              [-78.36939959559037, -1.623396070147862],
              [-78.36788951191288, -1.62254396587025],
              [-78.36734049725438, -1.621586081974442],
              [-78.3682635124329, -1.620448322436346],
              [-78.36995648923786, -1.620486208638738],
              [-78.37499250430842, -1.619869132926397],
              [-78.3775176364614, -1.618273386198382],
              [-78.37834962417138, -1.617443410145199],
              [-78.37852463830984, -1.613918652218558],
              [-78.37990564067832, -1.610666976697644],
              [-78.3799435268807, -1.608977017377867],
              [-78.38095857535619, -1.606168241621162],
              [-78.38083653484584, -1.605074403255742],
              [-78.37682361487784, -1.605729365170475],
              [-78.37528251815856, -1.604950183450569],
              [-78.37451959733082, -1.603557278779533],
              [-78.37357361573756, -1.605056465982898],
              [-78.37213159311392, -1.606048212767575],
              [-78.37038463685246, -1.606100348205359],
              [-78.3693085681218, -1.605565415144213],
              [-78.36814163956062, -1.604506277857979],
              [-78.3692545886653, -1.603649312076527],
              [-78.36952951508971, -1.601616532916523],
              [-78.37006360996054, -1.599954736791403],
              [-78.37068152386321, -1.598897611161931],
              [-78.37232253286842, -1.59702978785657],
              [-78.37329064268609, -1.596335095720747],
              [-78.37973062653985, -1.595114020065012],
              [-78.38098154177092, -1.594140042915114],
              [-78.37944765348838, -1.593361196471335],
              [-78.37818952982057, -1.593675014926532],
              [-78.37506056536226, -1.594908998713152],
              [-78.37343564961117, -1.594320086195467],
              [-78.37222262058248, -1.592999098253813],
              [-78.37198658618884, -1.591806185792791],
              [-78.37305460829245, -1.588400450891356],
              [-78.37305460829245, -1.586683837119438],
              [-78.37398550245999, -1.585274671556249],
              [-78.37423662427935, -1.583991737455051],
              [-78.37774663005638, -1.582875938503264],
              [-78.37865455780918, -1.581638937231503],
              [-78.37822758366103, -1.580653225417109],
              [-78.37697549496352, -1.581004091884381],
              [-78.3750456455746, -1.580242009246945],
              [-78.37362658936568, -1.579264176421589],
              [-78.37192556593367, -1.578783390455044],
              [-78.36989563662075, -1.576648352076177],
              [-78.36806452605134, -1.578390446833794],
              [-78.3676605183179, -1.579627112829428],
              [-78.36917864862245, -1.580407132739651],
              [-78.37085754383006, -1.580833268697518],
              [-78.37195557314706, -1.580193058932366],
              [-78.37330656830214, -1.581442130144751],
              [-78.3725815336768, -1.582444102851298],
              [-78.37276459444232, -1.584387698561386],
              [-78.37250559363395, -1.585480866374553],
              [-78.37161258566881, -1.586030886861465],
              [-78.37052964377753, -1.585414649339441],
              [-78.36927755508002, -1.586099618467529],
              [-78.36956052813149, -1.587284484301506],
              [-78.37045253026824, -1.588740588522512],
              [-78.37104764539426, -1.590178420194604],
              [-78.3696135017596, -1.590112538435562],
              [-78.36921653482484, -1.591784225206425],
              [-78.36791264596566, -1.592641190987877],
              [-78.36727863880887, -1.594059241368427],
              [-78.36734753805304, -1.595441920117537],
              [-78.3644175599763, -1.597805952091335],
              [-78.36270848991724, -1.597604786414934],
              [-78.36109949978218, -1.596861814516785],
              [-78.3599625784344, -1.595486008928276],
              [-78.35928364427657, -1.593985145344277],
              [-78.35931365148996, -1.59236810858215],
              [-78.36023750485882, -1.590986268023357],
              [-78.3598256181364, -1.589341570980764],
              [-78.35916160376622, -1.587912456488027],
              [-78.35811654807733, -1.589501330055441],
              [-78.35824562938635, -1.590631545880683],
              [-78.3569715801025, -1.590702456781571],
              [-78.35531648949994, -1.590266262539956],
              [-78.35563651056347, -1.591432185272708],
              [-78.35584253774371, -1.593177129877404],
              [-78.35525563688283, -1.594838926002524],
              [-78.35445365638626, -1.59639209266237],
              [-78.3532635937723, -1.597510909099299],
              [-78.35197462470077, -1.598204930682869],
              [-78.35063955516175, -1.597498839158675],
              [-78.34890751868797, -1.597496827501914],
              [-78.34754160374521, -1.596509942221132],
              [-78.34674850806601, -1.595559937314363],
              [-78.34509257927313, -1.594997008696566],
              [-78.3425754937472, -1.592924164039346],
              [-78.3416135865379, -1.592318152439191],
              [-78.3378755929943, -1.592548151862559],
              [-78.33652459783922, -1.591660340677379],
              [-78.3358386228827, -1.592934222323152],
              [-78.3339996333243, -1.593681217534879],
              [-78.33475450752498, -1.594531980707984],
              [-78.33651755704055, -1.595066913769131],
              [-78.34006461082963, -1.594954931542588],
              [-78.34179664730341, -1.595101111934127],
              [-78.34453551798758, -1.596315984981459],
              [-78.34365860327655, -1.598058750291329],
              [-78.34295653506592, -1.598961648902218],
              [-78.34143857239943, -1.599836719594578],
              [-78.33805865375982, -1.60036461185706],
              [-78.3370285178586, -1.601475549304951],
              [-78.33541064290617, -1.60190671440472],
              [-78.33489951445004, -1.602836602743878],
              [-78.33576955600047, -1.603479327079924],
              [-78.33920261590626, -1.603041456457674],
              [-78.3423005673228, -1.604572327255028],
              [-78.34393352970096, -1.604692188470551],
              [-78.34712250858605, -1.605790217787558],
              [-78.34876251176289, -1.605810166717163],
              [-78.35027360126877, -1.606698145540406],
              [-78.35190555781854, -1.606998050036282],
              [-78.35481256948056, -1.606351134748593],
              [-78.35660562620951, -1.606435121418485],
              [-78.35821562217296, -1.607051023664383],
              [-78.35826859580108, -1.608552054886445],
              [-78.35622357906243, -1.610681728847226],
              [-78.35514851616014, -1.612107825854878],
              [-78.35372962758929, -1.613018771092811],
              [-78.3526005852305, -1.612330616841462],
              [-78.34963959411198, -1.61438770352072],
              [-78.34806061119032, -1.615044677092214],
              [-78.34630560830182, -1.615295631273511],
              [-78.34397862434008, -1.615888399466598],
              [-78.34270457505623, -1.616890372173145],
              [-78.34116364597502, -1.616075483545671],
              [-78.33946949570363, -1.615756468310508],
              [-78.33648654399876, -1.615879514649237],
              [-78.33482357440721, -1.616193333104434],
              [-78.33322849823143, -1.615522445573617],
              [-78.33178764907424, -1.614508738202687],
              [-78.3302616397807, -1.61401873214254],
              [-78.32920049083769, -1.614107747954392],
              [-78.3300475659734, -1.616828346089619],
              [-78.33201563684082, -1.6170293441279],
              [-78.33359562559086, -1.617682294385929],
              [-78.33478552056675, -1.617856470334061],
              [-78.33594557596732, -1.617848256068953],
              [-78.34110262571984, -1.618424260455697],
              [-78.35152451649984, -1.623570916648248],
              [-78.35389760092907, -1.623656076784584],
              [-78.35508749590497, -1.624885869619675],
              [-78.35483553589528, -1.625897732971907],
              [-78.35398862839764, -1.626800631582853],
              [-78.35303460017732, -1.627151498050068],
              [-78.35143265084095, -1.626417746245409],
              [-78.35032657489688, -1.626470719873566],
              [-78.34751159653182, -1.628472653629842],
              [-78.34391760408491, -1.627076731473664],
              [-78.34267456784283, -1.627137751728867],
              [-78.34094253136905, -1.627515775562415],
              [-78.33741760580436, -1.627654747517226],
              [-78.3360905828924, -1.627382503301817],
              [-78.33679952426363, -1.630799469953502],
              [-78.3374935458472, -1.631730196482977],
              [-78.34597049981255, -1.635754012925133],
              [-78.34763363704216, -1.635612191123244],
              [-78.34929660663373, -1.635189072650576],
              [-78.35066252157647, -1.634053157131177],
              [-78.35352360040903, -1.633442284027183],
              [-78.3547745156401, -1.633507159957787],
              [-78.3555754903083, -1.635504902762534],
              [-78.35546149642501, -1.637212966993218],
              [-78.35473662943772, -1.638684661554123],
              [-78.35366056070706, -1.639957872647642],
              [-78.34865555867827, -1.64155965434594],
              [-78.34767152324456, -1.64225367592951],
              [-78.34541360616505, -1.643172332518418],
              [-78.34194953321749, -1.642969490461383],
              [-78.33840164123808, -1.643000503503174],
              [-78.33493052749184, -1.642580570153655],
              [-78.33220657659534, -1.641573735943325],
              [-78.33068861392886, -1.641300653537598],
              [-78.32930761156038, -1.640313600618754],
              [-78.32803356227653, -1.639136781411821],
              [-78.32648458656826, -1.638249808416958],
              [-78.32484458339142, -1.637758796528487],
              [-78.32360858794803, -1.639799622315536],
              [-78.32203664582504, -1.640240678061048],
              [-78.32090760346625, -1.639841867107577],
              [-78.31960254114063, -1.638637722896306],
              [-78.31930565412988, -1.636975088580868],
              [-78.31878664668477, -1.635970936579497],
              [-78.31767252411365, -1.636520957066409],
              [-78.3155896211726, -1.636607961221443],
              [-78.31438463877099, -1.636244857175541],
              [-78.31272854234004, -1.636044026775267],
              [-78.31099650586626, -1.634070256213647],
              [-78.31004365111238, -1.634784059088759],
              [-78.3088685082861, -1.636030112815945],
              [-78.30780752698115, -1.635341958564652],
              [-78.30586259016651, -1.63599909977421],
              [-78.3043055678312, -1.636792027815318],
              [-78.30378756621447, -1.6383549174829],
              [-78.30375655317269, -1.639556882399347],
              [-78.30445057475626, -1.640288622547246],
              [-78.30557257631638, -1.639603821057165],
              [-78.30660254457953, -1.638294735418015],
              [-78.30760954642797, -1.637889889494261],
              [-78.30912750909445, -1.638993618505424],
              [-78.30955465088067, -1.640124672520983],
              [-78.31053164551571, -1.641562504193018],
              [-78.31208749438458, -1.642070615164016],
              [-78.31288964251922, -1.64064351232804],
              [-78.3149105193767, -1.642282509676477],
              [-78.31609354119199, -1.641597708186396],
              [-78.31756557102902, -1.641409450640936],
              [-78.31900759365267, -1.642459535471687],
              [-78.31819957818581, -1.644320485616447],
              [-78.3180694910484, -1.646705137072104],
              [-78.31713859688088, -1.648186051726498],
              [-78.31547562728932, -1.649981790664469],
              [-78.31204960818219, -1.650086061540094],
              [-78.31192756767184, -1.651766800766381],
              [-78.31085954556823, -1.654241641500278],
              [-78.31426259826064, -1.654453536012738],
              [-78.31549859370404, -1.655142696092469],
              [-78.31797058459084, -1.652786710745715],
              [-78.31879452567375, -1.651278806363052],
              [-78.32015960242617, -1.650133000197911],
              [-78.32072454270073, -1.64904888484017],
              [-78.32450863671184, -1.644337249422847],
              [-78.32618753191944, -1.643788570040442],
              [-78.32703360122676, -1.645154484983209],
              [-78.32775863585212, -1.643700392418964],
              [-78.32879665074233, -1.644343452031194],
              [-78.32901759771023, -1.646612097946786],
              [-78.32897149724273, -1.648373973995888],
              [-78.32997162593055, -1.649713066848449],
              [-78.33126059500208, -1.650663071755218],
              [-78.33275559125384, -1.651360949014247],
              [-78.33146662218232, -1.652389743810943],
              [-78.33027655956836, -1.652685792631416],
              [-78.32901759771023, -1.653850541897725],
              [-78.32767464918221, -1.65696643058709],
              [-78.32682053324784, -1.658537366881717],
              [-78.32595853832449, -1.659421154753318],
              [-78.32239555891934, -1.661368941415049],
              [-78.3191606472048, -1.662340068717867],
              [-78.31761150385847, -1.663079017302493],
              [-78.3169635827424, -1.663882841817781],
              [-78.31949659388438, -1.665096876674852],
              [-78.3205106365315, -1.664944828951093],
              [-78.32144957732608, -1.664177884809817],
              [-78.32610354524955, -1.663407084993082],
              [-78.32730852765117, -1.664013934783554],
              [-78.32870461744537, -1.664331944190337],
              [-78.32918557105003, -1.665534914935222],
              [-78.332221496383, -1.666559854056459],
              [-78.3298495854202, -1.66850864654657],
              [-78.32816364941392, -1.669012398927862],
              [-78.32514163804024, -1.670463306368902],
              [-78.32347850081062, -1.670741250278411],
              [-78.32121253710406, -1.671479360672777],
              [-78.31968652781052, -1.672371195171479],
              [-78.31844349156844, -1.673572154259546],
              [-78.3176805707407, -1.674619053967149],
              [-78.3162536355428, -1.675647178211591],
              [-78.31557453374688, -1.677109987955134],
              [-78.31670357610567, -1.677690015655401],
              [-78.31822153877215, -1.677999810797075],
              [-78.31947362746966, -1.677738965969979],
              [-78.32340256076778, -1.674752996780001],
              [-78.32453964975362, -1.67593015126306],
              [-78.32354756769286, -1.67872987456434],
              [-78.32337959435306, -1.680509520248222],
              [-78.3228986407484, -1.681990434902616],
              [-78.3237535948731, -1.683519294043265],
              [-78.32397454184101, -1.684504502943412],
              [-78.32339451414072, -1.685389464281457],
              [-78.32234157946284, -1.685803362660693],
              [-78.32061758961612, -1.685937305473544],
              [-78.31941260721449, -1.68560018532753],
              [-78.3189165661841, -1.687290144647307],
              [-78.31868757258914, -1.689486035643256],
              [-78.32260862689826, -1.689589971242754],
              [-78.32226563942001, -1.688288932230648],
              [-78.32379164871355, -1.687830106850356],
              [-78.32552351754927, -1.687642016942959],
              [-78.32641551968602, -1.686378361219056],
              [-78.3269955473863, -1.685204391859202],
              [-78.32903251749791, -1.682622598040666],
              [-78.3303456264506, -1.681730428265894],
              [-78.33140560192714, -1.680547574088621],
              [-78.3326035435301, -1.680250687077887],
              [-78.33431965438783, -1.681346704738132],
              [-78.33524350775669, -1.68253257640049],
              [-78.3342745597487, -1.68336255245373],
              [-78.3333966392093, -1.684933321110236],
              [-78.33439660025905, -1.686037385397526],
              [-78.3347166213226, -1.687330210144523],
              [-78.3346866141092, -1.688577102062084],
              [-78.33541064290617, -1.691306752652736],
              [-78.33290059817891, -1.695568615145305],
              [-78.33354164613436, -1.697250360199973],
              [-78.33565556211718, -1.698698417793935],
              [-78.33463263465269, -1.700839155866902],
              [-78.33714251174189, -1.701855042532713],
              [-78.33801255329232, -1.700727006002296],
              [-78.338264513302, -1.699091361415128],
              [-78.33893556847084, -1.698125430892276],
              [-78.33986663027645, -1.69729528720103],
              [-78.3391115884377, -1.696182338096378],
              [-78.3402936044246, -1.695443557149815],
              [-78.3405155572209, -1.694033553396309],
              [-78.34172053962251, -1.692833600136566],
              [-78.34328460275651, -1.692112756462848],
              [-78.34339155584112, -1.690766790449686],
              [-78.34484849825245, -1.689720058380146],
              [-78.3449705387628, -1.688031104888694],
              [-78.34432261764672, -1.686394119197018],
              [-78.3428495819813, -1.683536393125735],
              [-78.34272754147095, -1.682442554760314],
              [-78.3439715835414, -1.680962645934301],
              [-78.34532961949516, -1.680214812532256],
              [-78.34691664904388, -1.680451685116225],
              [-78.34815951764789, -1.681176552103523],
              [-78.3491896535491, -1.68039167068946],
              [-78.34806061119032, -1.679332868679353],
              [-78.34641256138643, -1.678814699424549],
              [-78.34552760004834, -1.677459010403709],
              [-78.34521461978348, -1.675686908432681],
              [-78.34468052491265, -1.674620059795529],
              [-78.34674062907703, -1.672020161066143],
              [-78.34826663837057, -1.672410422478379],
              [-78.34939551309128, -1.673072257553713],
              [-78.35068565562925, -1.672522404704864],
              [-78.35036462873734, -1.669133601247893],
              [-78.3505936223323, -1.66796751087702],
              [-78.34749550327771, -1.662505024572511],
              [-78.34746549606432, -1.660806180435316],
              [-78.34812951043449, -1.658005283667649],
              [-78.34722158268168, -1.656242401790109],
              [-78.34609958112156, -1.655246464053903],
              [-78.34930465326079, -1.654817645887022],
              [-78.35245557830542, -1.653321643806862],
              [-78.3515315572985, -1.650021856161686],
              [-78.35157061696734, -1.64883799615609],
              [-78.35274458632719, -1.64743922415289],
              [-78.35589551137183, -1.649458089353573],
              [-78.35707853318712, -1.649641150119123],
              [-78.35803256140744, -1.647816074519994],
              [-78.35930661069129, -1.646996324388681],
              [-78.36044353203907, -1.646862046299702],
              [-78.36200759517305, -1.64763200792612],
              [-78.36183962183327, -1.651562952880965],
              [-78.36120561467648, -1.653116622455002],
              [-78.36027555869927, -1.65461547438224],
              [-78.35910762430971, -1.655951549749659],
              [-78.3585056360231, -1.657531538499711],
              [-78.3586736093629, -1.658752278879319],
              [-78.36199150191895, -1.660148033397377],
              [-78.36305952402256, -1.661035174030303],
              [-78.3642505924649, -1.660377026992364],
              [-78.36469265403878, -1.661913932760058],
              [-78.36459357994316, -1.663595007262472],
              [-78.36433457913479, -1.664777861439688],
              [-78.36286154346936, -1.666556836571317],
              [-78.36350259142482, -1.668183763979187],
              [-78.36345649095732, -1.66943065589669],
              [-78.36466264682538, -1.669685465753503],
              [-78.36834749910281, -1.668949702292025],
              [-78.36936958837697, -1.668490709273726],
              [-78.37069661128893, -1.668898572682622],
              [-78.37014055583177, -1.670804449828438],
              [-78.3681565593483, -1.672889364426226],
              [-78.36884354013318, -1.67389318115147],
              [-78.37405356351383, -1.671569382312953],
              [-78.37630460743273, -1.669475415259683],
              [-78.37733457569588, -1.668789607941278],
              [-78.37762458954603, -1.666558848228078],
              [-78.37744152878051, -1.664958910548421],
              [-78.37770052958886, -1.663712018630918],
              [-78.37868456502258, -1.664480806790891],
              [-78.3793105255523, -1.663425022265926],
              [-78.38037854765591, -1.663752084128134],
              [-78.38145461638658, -1.663518061391244],
              [-78.38249162544841, -1.66429674019696],
              [-78.38487258886664, -1.669785545677485],
              [-78.38530760964186, -1.671340388717965],
              [-78.3847505483563, -1.67239617324293],
              [-78.38453664218706, -1.673969121194318],
              [-78.38513963630206, -1.675541901507586],
              [-78.38438459446331, -1.676995994071831],
              [-78.38434553479448, -1.678251938444816],
              [-78.38333853294604, -1.679488604440451],
              [-78.37919653166901, -1.681191639529231],
              [-78.37824250344869, -1.681949698853145],
              [-78.37744152878051, -1.682942451466147],
              [-78.37660954107054, -1.684432418576023],
              [-78.37572457973245, -1.685126440159593],
              [-78.3734815824406, -1.684897446564605],
              [-78.37211650568818, -1.68393838920241],
              [-78.3706585574485, -1.684894429079463],
              [-78.37187158647717, -1.688140237268158],
              [-78.37264255393197, -1.689523921845648],
              [-78.3731766488028, -1.690925040781792],
              [-78.37473249767167, -1.690881790161313],
              [-78.37625850696521, -1.690278796046357],
              [-78.37725058902599, -1.688825038758239],
              [-78.37822758366103, -1.688374092366985],
              [-78.37973749970045, -1.68876519196948],
              [-78.38105764945182, -1.690159102468897],
              [-78.37905152474394, -1.692902667018814],
              [-78.37789163698145, -1.693443802688307],
              [-78.37615960050766, -1.693594676945622],
              [-78.37531252537195, -1.694307641630417],
              [-78.3744426514596, -1.695490495807633],
              [-78.373374629356, -1.696175632573841],
              [-78.37161258566881, -1.696462461300769],
              [-78.37042252305486, -1.696894464590798],
              [-78.3702164958746, -1.700265163136919],
              [-78.3706746507026, -1.70143997068709],
              [-78.3702164958746, -1.702713852332863],
              [-78.37161258566881, -1.70307812984521],
              [-78.37285662773927, -1.702093926773443],
              [-78.37393152300348, -1.700867151423552],
              [-78.37479351792685, -1.700145134283332],
              [-78.3769686218029, -1.698602361183418],
              [-78.37824250344869, -1.698288207452094],
              [-78.37948654551916, -1.698253506372964],
              [-78.38111850206893, -1.6975695430732],
              [-78.38246949722401, -1.696676367469991],
              [-78.38405652677272, -1.696262469090811],
              [-78.38514751529105, -1.695622594601787],
              [-78.38633757790501, -1.695515473879084],
              [-78.38821462130387, -1.696756498464424],
              [-78.38916060289714, -1.697128487327689],
              [-78.38987759089542, -1.698005402038746],
              [-78.38914450964302, -1.700933033182537],
              [-78.38903051575973, -1.702603881763139],
              [-78.38851150831462, -1.703940795320818],
              [-78.38544456993986, -1.705771738252167],
              [-78.38349963312523, -1.708109451050007],
              [-78.38348353987111, -1.709320636059999],
              [-78.38318665286036, -1.711046302287343],
              [-78.38153860305647, -1.713737228485286],
              [-78.38154564385515, -1.714874988023382],
              [-78.38185862412001, -1.716213075047563],
              [-78.38146953617425, -1.717441862054216],
              [-78.37981461320975, -1.720466723275024],
              [-78.3786775242239, -1.721631472541333],
              [-78.3772205818126, -1.723545396314194],
              [-78.37573949952011, -1.724455503361867],
              [-78.37458061758599, -1.724616435902988],
              [-78.37283349368649, -1.724605371790744],
              [-78.37129960540395, -1.724151407914349],
              [-78.37014055583177, -1.725297381717553],
              [-78.36849954682654, -1.72576525955327],
              [-78.3675235580199, -1.724995465564916],
              [-78.36651655617146, -1.726078407456157],
              [-78.3650205540913, -1.730802783366414],
              [-78.36373862581846, -1.731659749147866],
              [-78.36057261334808, -1.732730788736603],
              [-78.35976459788122, -1.733678781986612],
              [-78.35938255073413, -1.734843531252977],
              [-78.35851250918371, -1.738936414577324],
              [-78.3582915622158, -1.740698290626483],
              [-78.35663563342291, -1.742214073998127],
              [-78.35605661155101, -1.743442022814463],
              [-78.35585762516945, -1.744543907806928],
              [-78.35459162251264, -1.744136044398033],
              [-78.35344665453782, -1.742597965163895],
              [-78.3518374967647, -1.743010857714751],
              [-78.35087558955539, -1.744311058536539],
              [-78.34985350028123, -1.745330968515873],
              [-78.35091364339584, -1.745946703123707],
              [-78.35201251090317, -1.747023610044664],
              [-78.35253855914694, -1.75010664167354],
              [-78.3541415143117, -1.749430389724694],
              [-78.35472858281067, -1.748021559437689],
              [-78.35579660491427, -1.749297620378286],
              [-78.3569415728891, -1.749823333345944],
              [-78.35872658299101, -1.746563611197985],
              [-78.35968061121133, -1.743899004176001],
              [-78.36051159309291, -1.743321993960876],
              [-78.36151155414268, -1.743729857369772],
              [-78.36272458317136, -1.743730863198152],
              [-78.3632655512028, -1.744735015199524],
              [-78.36331165167032, -1.745828685926881],
              [-78.36235058265132, -1.747408842314996],
              [-78.36212158905636, -1.748745588234669],
              [-78.36141164185673, -1.750308477902195],
              [-78.36145757468617, -1.751456295724097],
              [-78.3628466236817, -1.753139214245266],
              [-78.36396761941343, -1.753556130109587],
              [-78.36486850636757, -1.756567077371074],
              [-78.36759161907374, -1.757374925199883],
              [-78.36814163956062, -1.758261730556626],
              [-78.36782061266871, -1.759408710188268],
              [-78.36857565450745, -1.760945783593968],
              [-78.36833157348676, -1.762707492005063],
              [-78.36900363448399, -1.765546442613186],
              [-78.36917864862245, -1.767336146580874],
              [-78.36895753401649, -1.769016047616901],
              [-78.3679885860085, -1.770208121887606],
              [-78.36940764221742, -1.771122922800998],
              [-78.37015564325749, -1.772470732832915],
              [-78.37126959819055, -1.771152762376346],
              [-78.37138359207384, -1.768279110689036],
              [-78.37203251901829, -1.767213267880209],
              [-78.37175759259388, -1.765667477295153],
              [-78.37179564643434, -1.764077597899359],
              [-78.37274950701658, -1.763265559118963],
              [-78.37332953471686, -1.762208601127554],
              [-78.37272654060186, -1.760111784227263],
              [-78.37283349368649, -1.758755927568359],
              [-78.37103255796853, -1.757749763910226],
              [-78.36962154838666, -1.75787498954378],
              [-78.3676605183179, -1.755765935064801],
              [-78.36768365237069, -1.754122243850588],
              [-78.3670575242029, -1.751681266005562],
              [-78.36785849887109, -1.74841047974536],
              [-78.3688125270914, -1.745673955994107],
              [-78.3699955489067, -1.745648642646472],
              [-78.37079652357488, -1.748378628513308],
              [-78.37149859178551, -1.749391665331984],
              [-78.37190259951895, -1.750603353256167],
              [-78.37285662773927, -1.751300392324879],
              [-78.37425959069408, -1.74917859735308],
              [-78.37457257095895, -1.746232861298381],
              [-78.37382557574725, -1.742897869659828],
              [-78.37365760240746, -1.739535385378815],
              [-78.37418365065123, -1.7362376093904],
              [-78.37412263039606, -1.735062634202166],
              [-78.37374158907735, -1.733444759249778],
              [-78.37422958348068, -1.731709872928889],
              [-78.37506056536226, -1.730156873907106],
              [-78.3761976543481, -1.729209886485421],
              [-78.37846361805467, -1.727875152222566],
              [-78.37937959243453, -1.728338168554387],
              [-78.37985249941214, -1.729485986376289],
              [-78.38008853380578, -1.730986849960289],
              [-78.3801036212315, -1.732314878700663],
              [-78.37981461320975, -1.733850611001856],
              [-78.37909661938306, -1.735322473200881],
              [-78.37911254499912, -1.736371384565189],
              [-78.37980656658269, -1.7371312879078],
              [-78.3821105841297, -1.737134473031006],
              [-78.38322453906275, -1.736602557455001],
              [-78.38492556249476, -1.733695713431075],
              [-78.3872525464565, -1.731601914015869],
              [-78.3889235626751, -1.731829901782476],
              [-78.39012150427806, -1.732789629696924],
              [-78.3908465389034, -1.736143564436702],
              [-78.39137258714719, -1.737789434945739],
              [-78.39160158074216, -1.739461121716602],
              [-78.39120461380739, -1.741024011384184],
              [-78.39171557462545, -1.742742133898673],
              [-78.39137258714719, -1.743915935620464],
              [-78.39064755252184, -1.745044810341199],
              [-78.3896405506734, -1.746236884611903],
              [-78.38824462851727, -1.747336757947608],
              [-78.38723762666883, -1.748483569941129],
              [-78.38721449261604, -1.750209403806593],
              [-78.38827564155905, -1.751584371204729],
              [-78.38830564877244, -1.756419052960837],
              [-78.38851150831462, -1.758027875457856],
              [-78.38842064848411, -1.75938373211676],
              [-78.3898846316941, -1.76018085110951],
              [-78.39115851333989, -1.761357670316386],
              [-78.39134257993379, -1.762939503085136],
              [-78.3918915945923, -1.764639353050711],
              [-78.39170853382677, -1.766292431996533],
              [-78.39200558847558, -1.767892034400006],
              [-78.39317352286514, -1.766728290962021],
              [-78.39345549008823, -1.764099558485725],
              [-78.39422662518109, -1.762608417909405],
              [-78.39302063695108, -1.761495636442817],
              [-78.39253264254775, -1.759895698763216],
              [-78.39337955004538, -1.758612932300025],
              [-78.39495065397806, -1.757756972346954],
              [-78.39500463343455, -1.75620296749679],
              [-78.39663658998433, -1.75366509485093],
              [-78.39781156517256, -1.752402277317344],
              [-78.39803351796886, -1.751055473113865],
              [-78.40135962479003, -1.750102618360017],
              [-78.40229755975624, -1.74949845077856],
              [-78.40289250724418, -1.746254821884691],
              [-78.40426663645205, -1.744087932273771],
              [-78.40483861752527, -1.742778008444304],
              [-78.40605952554294, -1.743051090850031],
              [-78.40838650950468, -1.74465387837671],
              [-78.40924062543905, -1.745766827481361],
              [-78.40972861984237, -1.748396733424158],
              [-78.41099562832756, -1.749609427176722],
              [-78.41240663790943, -1.750072443508543],
              [-78.4136275459271, -1.751357389266559],
              [-78.41459649393508, -1.752814164039819],
              [-78.41604656318579, -1.7537381850467],
              [-78.41722857917269, -1.754959093064372],
              [-78.41815159435122, -1.755511963398362],
              [-78.41925062949662, -1.755378020585511],
              [-78.42038755084438, -1.754033060400673],
              [-78.42288250814592, -1.755121031433873],
              [-78.42578164081894, -1.753742040722159],
              [-78.4260106344139, -1.752107401963372],
              [-78.4271165427199, -1.751719487484081],
              [-78.42949650030975, -1.751443387593213],
              [-78.4315336380594, -1.748074700703853],
              [-78.43206756529217, -1.746448611486301],
              [-78.43203755807879, -1.744794861988225],
              [-78.43367756125562, -1.746432685870275],
              [-78.43420461532779, -1.747635824253166],
              [-78.43566859853776, -1.748414503058882],
              [-78.43631752548221, -1.749870607279888],
              [-78.43638659236444, -1.751190421755155],
              [-78.43600454521736, -1.752843165424792],
              [-78.43521161717622, -1.754397170274956],
              [-78.43413554844555, -1.75557013380643],
              [-78.43383765560641, -1.757196893576236],
              [-78.43383765560641, -1.758868915623225],
              [-78.43336458099076, -1.760539596565707],
              [-78.43257165294962, -1.761415505448383],
              [-78.4315876175159, -1.762173564772297],
              [-78.42996957492541, -1.762875632982912],
              [-78.42885561999236, -1.763552387845891],
              [-78.42794752460148, -1.764445563449101],
              [-78.42785649713292, -1.766235435054853],
              [-78.42821457203691, -1.767645271170295],
              [-78.42602555420157, -1.769124174167928],
              [-78.42489651184279, -1.768941951592751],
              [-78.42383553053784, -1.768009213406515],
              [-78.42185958068143, -1.767706123787434],
              [-78.42229460145664, -1.769597919335865],
              [-78.42188254709615, -1.771170029096936],
              [-78.42272962223186, -1.772110981548281],
              [-78.42366755719806, -1.771605888062425],
              [-78.42493456568323, -1.771435735427815],
              [-78.42671957578514, -1.771573869192309],
              [-78.42834449153624, -1.771892716789353],
              [-78.42979456078695, -1.772382722849443],
              [-78.43055764925275, -1.773450744953095],
              [-78.43078664284772, -1.774995697347833],
              [-78.4306025762538, -1.776396648645857],
              [-78.43020661514744, -1.777625268014447],
              [-78.42937462743747, -1.779178434674293],
              [-78.42756665092084, -1.780574189192407],
              [-78.42947353389502, -1.781646234609525],
              [-78.4302215349351, -1.782587187060869],
              [-78.43069460955077, -1.784033065360006],
              [-78.43058061566747, -1.785379701925422],
              [-78.42993956771201, -1.786490639373312],
              [-78.42731452327308, -1.787308713123991],
              [-78.42613250728618, -1.787262780294554],
              [-78.42445361207858, -1.787648683117141],
              [-78.42292056198636, -1.788369526790859],
              [-78.42139455269282, -1.788827513980834],
              [-78.41984557698456, -1.78850061975669],
              [-78.41831152106396, -1.78913965605534],
              [-78.41725959221446, -1.790277415593437],
              [-78.41626751015369, -1.791062297007556],
              [-78.41474150086015, -1.790969257882182],
              [-78.41390951315017, -1.792197374336581],
              [-78.41361262613943, -1.793453151071503],
              [-78.41275750437667, -1.79491612845311],
              [-78.4113696288476, -1.795781979051981],
              [-78.4097215790437, -1.795517110911362],
              [-78.40843965077086, -1.79644599342214],
              [-78.40721153431645, -1.797655837327625],
              [-78.40561662577873, -1.797779889494734],
              [-78.40361754186954, -1.79904170119994],
              [-78.4020236391602, -1.799753660056297],
              [-78.40075663067502, -1.799986676964807],
              [-78.40003159604967, -1.80149458134747],
              [-78.39979556165603, -1.803067361660794],
              [-78.39929164163665, -1.80477442006304],
              [-78.39770561791633, -1.804582306842121],
              [-78.39814751185214, -1.806020138514157],
              [-78.39817852489392, -1.807366272165382],
              [-78.39773562512973, -1.80897408883402],
              [-78.40160353817267, -1.810317875552357],
              [-78.40361754186954, -1.811630816866966],
              [-78.40476955064304, -1.809401901172464],
              [-78.40542551838615, -1.811244914044437],
              [-78.40635658019175, -1.812791878095936],
              [-78.40732552819973, -1.813434770070046],
              [-78.40811962970731, -1.81431252297142],
              [-78.40835549646289, -1.815948502834715],
              [-78.40787454285824, -1.817113419739144],
              [-78.4084855836003, -1.820069381715712],
              [-78.4085695702702, -1.821805273864982],
              [-78.40688363426392, -1.824766935535763],
              [-78.40650158711682, -1.826401909570677],
              [-78.40692152046631, -1.827857510877493],
              [-78.4062345396814, -1.829356698080858],
              [-78.40531957112992, -1.830765360729799],
              [-78.40422053598454, -1.832110488552644],
              [-78.40277851336089, -1.831448318201183],
              [-78.40326650776422, -1.830274516479392],
              [-78.40287758745652, -1.828357575221389],
              [-78.40312954746621, -1.826694773267889],
              [-78.40293860771169, -1.825094835588288],
              [-78.4022216197134, -1.823494897908688],
              [-78.4022755991699, -1.821714246396425],
              [-78.40180151872585, -1.820611355575579],
              [-78.3973845910244, -1.82261111003703],
              [-78.39566060117768, -1.822987960404134],
              [-78.39434061906438, -1.823817936457374],
              [-78.39301259032402, -1.823245955384095],
              [-78.39144852719002, -1.82305400980124],
              [-78.38973258397036, -1.822924928492228],
              [-78.38719957282838, -1.823003047829843],
              [-78.38624554460806, -1.823571005589542],
              [-78.38499462937699, -1.823686005301226],
              [-78.38407161419845, -1.824208868421806],
              [-78.38443756809143, -1.825673689822111],
              [-78.38459062164355, -1.827273627501711],
              [-78.3838655870182, -1.828889490797394],
              [-78.38272849803236, -1.829629612848464],
              [-78.38211863075675, -1.832258177686754],
              [-78.38167556335449, -1.833215223392187],
              [-78.38039363508165, -1.835283038907505],
              [-78.37945553247738, -1.835797184848786],
              [-78.37850150425706, -1.835280021422363],
              [-78.37671649415515, -1.83353323279897],
              [-78.37591551948697, -1.830839289115886],
              [-78.37459553737366, -1.830575594441768],
              [-78.3744205232352, -1.828794440015315],
              [-78.37476351071345, -1.827412599456522],
              [-78.37388659600242, -1.826290765534452],
              [-78.37226050678487, -1.825782822201575],
              [-78.37063559103376, -1.82121619370929],
              [-78.3717805590086, -1.820133251817992],
              [-78.3721926133691, -1.819067241371158],
              [-78.37438163120443, -1.817408295093117],
              [-78.37322258163225, -1.815879435952468],
              [-78.3727116208142, -1.814730612302185],
              [-78.3715285989989, -1.813508698456133],
              [-78.37074254411839, -1.811981012781985],
              [-78.37104060459558, -1.81069807868073],
              [-78.37180352542332, -1.809199059115429],
              [-78.37088755104345, -1.806748358262723],
              [-78.37084949720301, -1.805637253176769],
              [-78.37001063633242, -1.804867459188415],
              [-78.36709557804335, -1.805026380072775],
              [-78.3654026012384, -1.804553305457091],
              [-78.36437263297525, -1.805193347584179],
              [-78.3627705160008, -1.805299462478445],
              [-78.3622436295667, -1.806962096793882],
              [-78.36113755362264, -1.807629128649239],
              [-78.35951263787153, -1.806632352722659],
              [-78.35879564987324, -1.805086226861476],
              [-78.35655952574201, -1.803293337770583],
              [-78.35511062995775, -1.803165597566078],
              [-78.35522462384105, -1.805234418909777],
              [-78.35592652441362, -1.807603312387414],
              [-78.35467560918255, -1.812029963096563],
              [-78.353752594004, -1.81274192195292],
              [-78.35300459296393, -1.814240606242095],
              [-78.35160062418073, -1.814491728061455],
              [-78.35028064206745, -1.815230509008018],
              [-78.34857961863543, -1.814957594240411],
              [-78.3473285357663, -1.814313528799801],
              [-78.34575659364332, -1.812654917797886],
              [-78.34523758619821, -1.814220657312546],
              [-78.34555861309012, -1.81592872154323],
              [-78.34457357182802, -1.81629853111167],
              [-78.34297950148064, -1.814542522394788],
              [-78.34126255243258, -1.815669553096768],
              [-78.33988959669117, -1.816797421989122],
              [-78.33861554740731, -1.816045565273555],
              [-78.33607448963828, -1.816330382343722],
              [-78.33524350775669, -1.815488503988036],
              [-78.33489951445004, -1.81140383492874],
              [-78.33563963650113, -1.80983289863417],
              [-78.33589159651082, -1.808586174354673],
              [-78.33479356719381, -1.808348966494577],
              [-78.33355757175042, -1.807281279667109],
              [-78.33286254433845, -1.809160167084656],
              [-78.33168756915023, -1.809446995811584],
              [-78.33148154196998, -1.810883989293359],
              [-78.33189359633047, -1.811877747734741],
              [-78.32948262569884, -1.814178580158568],
              [-78.32793465581896, -1.814782747739969],
              [-78.32704952684281, -1.815539633597439],
              [-78.32633957964319, -1.816551664587791],
              [-78.33061250624795, -1.819142343223689],
              [-78.33226055605184, -1.819578369827241],
              [-78.33370257867549, -1.818758452057864],
              [-78.33449550671662, -1.817286589858895],
              [-78.33538851468177, -1.818155457942851],
              [-78.33582252962859, -1.819312328220235],
              [-78.33383149234643, -1.822138035421347],
              [-78.33479356719381, -1.824940776207768],
              [-78.33441956667377, -1.826530823241626],
              [-78.33367961226077, -1.827405726295922],
              [-78.33032249239781, -1.828241569681325],
              [-78.33187062991576, -1.830900309371088],
              [-78.33312959177387, -1.831869257379026],
              [-78.33470958052392, -1.832558249820693],
              [-78.33643357037064, -1.832317353923202],
              [-78.33819561405782, -1.832898219813842],
              [-78.34181257291947, -1.835162004225538],
              [-78.34294865607693, -1.835282033079125],
              [-78.34341452225586, -1.833213211735426],
              [-78.34272754147095, -1.831983251262329],
              [-78.34170561983485, -1.831457370656608],
              [-78.34123254521919, -1.830318605290131],
              [-78.34171249299546, -1.828240563852944],
              [-78.341529599868, -1.826748585086364],
              [-78.34322358250134, -1.826615648101836],
              [-78.34474959179488, -1.826880851518581],
              [-78.34597754061122, -1.827270777654633],
              [-78.34706165596894, -1.828428486122334],
              [-78.34759558320172, -1.826766857635278],
              [-78.34725963652214, -1.825627756992674],
              [-78.34730556935158, -1.823865880943572],
              [-78.34707657575662, -1.822609098380269],
              [-78.34722862348035, -1.821453066293202],
              [-78.3502125810136, -1.82188221973621],
              [-78.35141756341521, -1.82183025193649],
              [-78.3543855953324, -1.82085811880529],
              [-78.35542260439424, -1.820877061906458],
              [-78.35694861368778, -1.822036111478667],
              [-78.35536963076612, -1.825305724272368],
              [-78.35646061928445, -1.826084738354211],
              [-78.35809358166262, -1.82925862981358],
              [-78.35834453584391, -1.830876337127961],
              [-78.3588945563308, -1.832296231527209],
              [-78.36067151980565, -1.838317958412119],
              [-78.35885650249035, -1.838675698039992],
              [-78.35354656682377, -1.837041897471522],
              [-78.35202055753022, -1.837419083114753],
              [-78.35117365003258, -1.838248891529872],
              [-78.35073862925738, -1.839377766250607],
              [-78.3511805231932, -1.840625663996548],
              [-78.355216577214, -1.843415496652028],
              [-78.35693352626205, -1.844032404726306],
              [-78.3579326491215, -1.844720391339592],
              [-78.3597416314665, -1.846359388688029],
              [-78.36182352857915, -1.848974207205117],
              [-78.3620835352159, -1.851729841695601],
              [-78.36061854617753, -1.853119728881495],
              [-78.35892456354419, -1.854228654672625],
              [-78.35763559447268, -1.855500524661636],
              [-78.35623950467847, -1.857405395979072],
              [-78.35452255563042, -1.861262244909824],
              [-78.35420253456688, -1.863159069600158],
              [-78.35600263209452, -1.865800710207395],
              [-78.36303756343622, -1.868856752108002],
              [-78.36805765289073, -1.871891503974553],
              [-78.37147562537079, -1.876107936551875],
              [-78.37185649905145, -1.878150941633749],
              [-78.37080356437356, -1.879902759399044],
              [-78.36695861774534, -1.883077489048731],
              [-78.36410558553983, -1.883787436248326],
              [-78.36318961115997, -1.884590422573297],
              [-78.36317452373423, -1.886424382989787],
              [-78.36450959327325, -1.886670308029181],
              [-78.36627163696043, -1.888580040850456],
              [-78.36509649413415, -1.890196071784203],
              [-78.36183157520621, -1.892811896129615],
              [-78.35984757872274, -1.893513629064103],
              [-78.35798662857799, -1.894793545680216],
              [-78.35652951852862, -1.896065415669227],
              [-78.35332461402747, -1.899422200256083],
              [-78.35266864628434, -1.901491021599725],
              [-78.35258449197639, -1.90373183959673],
              [-78.35340155989869, -1.905558759214557],
              [-78.35588863821123, -1.909321563191611],
              [-78.35771153451552, -1.909767480440962],
              [-78.35975655125416, -1.909345535434682],
              [-78.36493656742141, -1.905342003198257],
              [-78.36791951912626, -1.902797927944107],
              [-78.37030065018257, -1.902259139207445],
              [-78.372413560337, -1.903329172967858],
              [-78.37283349368649, -1.905217783393084],
              [-78.37234449345476, -1.907403616105228],
              [-78.37106357101031, -1.909019479400854],
              [-78.36953756171677, -1.910129578658484],
              [-78.3665085095444, -1.913188135130042],
              [-78.36538650798428, -1.914867197975695],
              [-78.36495249303746, -1.916647178935705],
              [-78.3645855333161, -1.919401975235928],
              [-78.36445661964514, -1.922330444570093],
              [-78.36470757382645, -1.92456237774968],
              [-78.36536454739795, -1.927139310064376],
              [-78.36750059160516, -1.930333150453293],
              [-78.36900363448399, -1.932097038159213],
              [-78.37193260673234, -1.935002541078632],
              [-78.37495461810603, -1.940140144815757],
              [-78.37605264742304, -1.941732035868313],
              [-78.37748762924801, -1.942927127624216],
              [-78.37889059220282, -1.941655257635148],
              [-78.37863963802151, -1.939423492093624],
              [-78.37737262953634, -1.937993539410513],
              [-78.37632757384745, -1.936509439632914],
              [-78.37561762664782, -1.934221850616154],
              [-78.37293960858078, -1.928506230835467],
              [-78.3727416280276, -1.926328277112361],
              [-78.37391660321582, -1.92465860199826],
              [-78.37577855918894, -1.923784369496218],
              [-78.37776154984404, -1.923245580759612],
              [-78.38069957454783, -1.926033234120325],
              [-78.38208057691631, -1.927743142369707],
              [-78.38312563260519, -1.929571235453977],
              [-78.3839495736881, -1.931740974911975],
              [-78.38487962966532, -1.933395897876494],
              [-78.38660462534042, -1.935106811954256],
              [-78.39056356585193, -1.938284559089084],
              [-78.3924406092508, -1.938387321222081],
              [-78.39407357162897, -1.937223577784152],
              [-78.39507252685034, -1.935553567393924],
              [-78.3960185084436, -1.933421881776326],
              [-78.39646157584586, -1.931136807330518],
              [-78.39771249107693, -1.927677092972601],
              [-78.39789555184245, -1.924749461828753],
              [-78.3969195630358, -1.922967469211983],
              [-78.39547754041216, -1.921663580352799],
              [-78.39533957428576, -1.919368782899312],
              [-78.39566060117768, -1.917588801939303],
              [-78.39489751271188, -1.915644200400777],
              [-78.39479055962725, -1.911577300976262],
              [-78.3941495116718, -1.909859513737899],
              [-78.38919865673758, -1.904962805898265],
              [-78.38832861518716, -1.90308207446202],
              [-78.3889235626751, -1.900778224553051],
              [-78.39102959966893, -1.900935301418713],
              [-78.39263959563237, -1.90241101929314],
              [-78.39390559828917, -1.904012130439185],
              [-78.39562958813589, -1.905370836945224],
              [-78.39772757850267, -1.90540989661406],
              [-78.39948258139117, -1.904589978844683],
              [-78.39951359443295, -1.902466172216066],
              [-78.4012065712379, -1.901592107352144],
              [-78.40287054665784, -1.902842016754846],
              [-78.40396857597484, -1.904380095988927],
              [-78.4045635234628, -1.906495688352379],
              [-78.40459453650458, -1.908736673987448],
              [-78.40422053598454, -1.910859474787685],
              [-78.40435749628254, -1.912694273394493],
              [-78.40494557060988, -1.914467045917718],
              [-78.4072795953703, -1.915139274553042],
              [-78.40863059052538, -1.913921216382448],
              [-78.41049958729717, -1.912930475426208],
              [-78.41198754275027, -1.913953402890684],
              [-78.4115065891456, -1.916366217540997],
              [-78.41078155452026, -1.91809087793996],
              [-78.41080452093497, -1.920042855553277],
              [-78.41230052301512, -1.921174747759153],
              [-78.41334557870401, -1.922947687920498],
              [-78.41233857685557, -1.924446372209673],
              [-78.41053764113762, -1.925952432573638],
              [-78.40953851827818, -1.927966268632417],
              [-78.40975963288416, -1.932385710904839],
              [-78.40927163848083, -1.934228556138692],
              [-78.40898162463068, -1.938412299293589],
              [-78.40888959133373, -1.940428314647193],
              [-78.40914155134342, -1.942606100732291],
              [-78.40911053830165, -1.944495046433644],
              [-78.4087905172381, -1.946391703485972],
              [-78.4088136512909, -1.948398498746087],
              [-78.41030864754266, -1.949873546068261],
              [-78.41194965654788, -1.949062680754366],
              [-78.41388654673545, -1.945143805740031],
              [-78.41587858984599, -1.944893857387115],
              [-78.41880756209434, -1.947916539313042],
              [-78.42062358523803, -1.947268785835035],
              [-78.42042560468484, -1.945198958663013],
              [-78.4198375303575, -1.943318059588648],
              [-78.41915859619965, -1.938726453024913],
              [-78.42015855724942, -1.937471514480308],
              [-78.42211958731818, -1.939110511828744],
              [-78.4260406416273, -1.943888029005109],
              [-78.42786454375997, -1.944108137782735],
              [-78.42944352668164, -1.943170035178468],
              [-78.43047349494479, -1.938924433578052],
              [-78.43240351197177, -1.938792502421961],
              [-78.43333457377736, -1.94055521666138],
              [-78.43511153725221, -1.942094972276152],
              [-78.43818652225401, -1.942181138240869],
              [-78.44033849207729, -1.941994221799916],
              [-78.44156660853169, -1.940089350482481],
              [-78.44216155601964, -1.937505377369121],
              [-78.4421696026467, -1.93336572302502],
              [-78.44149754164945, -1.928954997931896],
              [-78.44146753443607, -1.926840411396824],
              [-78.44218452243436, -1.924880387156463],
              [-78.44483957408669, -1.925381289690733],
              [-78.44633457033845, -1.92513033550938],
              [-78.44653355672003, -1.927083151313013],
              [-78.44615955619999, -1.929034123097949],
              [-78.44606752290304, -1.931104117908092],
              [-78.44776150553636, -1.934929618520925],
              [-78.44812762706741, -1.936936413781098],
              [-78.44840255349182, -1.940777169457704],
              [-78.44882164865098, -1.94306408792221],
              [-78.45009653612516, -1.943636068995431],
              [-78.45342264294634, -1.937866302120199],
              [-78.45480364531481, -1.937479393469289],
              [-78.45589463383314, -1.940020451238297],
              [-78.4543765035286, -1.946118788718309],
              [-78.45414750993362, -1.950998565113537],
              [-78.4555285123021, -1.952473444797647],
              [-78.45728250936222, -1.951599379933725],
              [-78.45915955276108, -1.952099276639558],
              [-78.46144865052042, -1.952998487213051],
              [-78.46588854463658, -1.953420264581268],
              [-78.47009257199718, -1.95355320156574],
              [-78.4719845351837, -1.954460123490151],
              [-78.47380055832738, -1.954553162615468],
              [-78.47528851378047, -1.954076232324383],
              [-78.47660849589377, -1.956256030066186],
              [-78.47674562382986, -1.95848896907421],
              [-78.47862954038932, -1.959223894345314],
              [-78.47745557102947, -1.960839590002934],
              [-78.4773105641044, -1.962728535704287],
              [-78.47568564835329, -1.964578589374923],
              [-78.47554064142822, -1.966702228365421],
              [-78.47584557506603, -1.968537026972228],
              [-78.47703563767999, -1.973057890273139],
              [-78.47722657743451, -1.975127717445218],
              [-78.47662358331951, -1.976907530767164],
              [-78.47526554736575, -1.977420503241945],
              [-78.47427363294305, -1.975032834301203],
              [-78.47476162734637, -1.973081862516267],
              [-78.47434253218721, -1.971309928183302],
              [-78.47062649922995, -1.969902941914995],
              [-78.46858265595775, -1.969809902789621],
              [-78.4689556506494, -1.968021204650313],
              [-78.47082565324959, -1.966921163676545],
              [-78.47233556928902, -1.965360285665781],
              [-78.47191664176792, -1.963578628325138],
              [-78.47054251256006, -1.962329724750873],
              [-78.4687655490852, -1.961314676275379],
              [-78.46682765306925, -1.960931623299871],
              [-78.4647826363306, -1.9609468783637],
              [-78.46267659933676, -1.960618643035048],
              [-78.46090650902252, -1.960000729132332],
              [-78.45902259246306, -1.959564870166844],
              [-78.45471161201785, -1.960515880901994],
              [-78.45058453052849, -1.961928566864515],
              [-78.44847849353467, -1.962178515217431],
              [-78.4463726241789, -1.961506621858234],
              [-78.44432056664158, -1.961349712630636],
              [-78.44216155601964, -1.961535790881328],
              [-78.44157465515875, -1.95948373334403],
              [-78.4404675733863, -1.957592943623922],
              [-78.43849162352988, -1.958412861393299],
              [-78.43771361527642, -1.960082871783527],
              [-78.43762158197946, -1.962152698955606],
              [-78.43821753529579, -1.963870653832032],
              [-78.43959853766427, -1.96558039444335],
              [-78.44158957494642, -1.965963447418858],
              [-78.44235953657282, -1.96807903978231],
              [-78.44351959197338, -1.970538960728504],
              [-78.44528951464956, -1.971093004528939],
              [-78.4470135044963, -1.972514910584948],
              [-78.44828051298147, -1.973818631806068],
              [-78.45027155026362, -1.974381728061928],
              [-78.45114863261271, -1.97643445615148],
              [-78.4518666264394, -1.978839224174749],
              [-78.45125558569734, -1.98056539331634],
              [-78.45082861154918, -1.982462050368611],
              [-78.44942464276599, -1.984140945576257],
              [-78.4493946355526, -1.986318899299363],
              [-78.44844865395933, -1.987880615500501],
              [-78.44729664518583, -1.991674432519289],
              [-78.4459075961903, -1.992197295639869],
              [-78.44488550691614, -1.989800406605582],
              [-78.44523654102144, -1.985724790001768],
              [-78.44586954234984, -1.981758138139298],
              [-78.44522061540539, -1.979642378137783],
              [-78.4431075376129, -1.978916505322104],
              [-78.44141355497956, -1.979844382004501],
              [-78.44000958619637, -1.981062272537031],
              [-78.43888859046464, -1.982678135832714],
              [-78.43754564193662, -1.984185872577314],
              [-78.43653864008819, -1.985801903511003],
              [-78.4354396049428, -1.984146142356224],
              [-78.43400562894621, -1.978360282226902],
              [-78.43280064654459, -1.976876685363436],
              [-78.43108352985847, -1.976267488640076],
              [-78.4295885336067, -1.975018752703818],
              [-78.4291156266291, -1.973065936900184],
              [-78.42750563066566, -1.971643863206111],
              [-78.42563663389386, -1.972119787668873],
              [-78.42434649135589, -1.973681671508075],
              [-78.42340050976263, -1.975234670529858],
              [-78.42152363400184, -1.975484786520838],
              [-78.41994465108019, -1.976702509415304],
              [-78.4180225130422, -1.977124622059591],
              [-78.41664151067373, -1.975757701288501],
              [-78.41523754189053, -1.975393759052281],
              [-78.41365051234182, -1.977505495740274],
              [-78.4130406450662, -1.979231497243745],
              [-78.40943961182062, -1.977246494931876],
              [-78.4076306294756, -1.978065406872872],
              [-78.40782156923012, -1.979412378714414],
              [-78.40682261400875, -1.982032058735342],
              [-78.40501363166373, -1.983086166879673],
              [-78.40543356501321, -1.984975783133279],
              [-78.40698254072149, -1.986107842977219],
              [-78.40757765584749, -1.987934762595046],
              [-78.40782961585718, -1.990058569223606],
              [-78.40870653056821, -1.99205748549474],
              [-78.41036262699916, -1.993189377700617],
              [-78.41165159607068, -1.992486471299685],
              [-78.41134649479481, -1.98991054481337],
              [-78.41070561447742, -1.987966613827155],
              [-78.4109035950306, -1.985788827742056],
              [-78.41191059687904, -1.984173132084436],
              [-78.41365855896888, -1.983009053370381],
              [-78.41481056774238, -1.984493991338297],
              [-78.41685457865265, -1.984190063528899],
              [-78.4186015349141, -1.983316166302984],
              [-78.42012754420765, -1.982324084242236],
              [-78.42164651270251, -1.980934364694463],
              [-78.42312658916661, -1.981271317202413],
              [-78.42264563556195, -1.98350291510593],
              [-78.42164651270251, -1.985461933517911],
              [-78.42127251218247, -1.987530754861609],
              [-78.42043264548352, -1.989201771080218],
              [-78.41902951489064, -1.990482358248528],
              [-78.41888450796557, -1.992714459066235],
              [-78.41954064334675, -1.994830051429688],
              [-78.42034949700394, -1.996549012134551],
              [-78.4240955371746, -1.995488030829563],
              [-78.42636854167982, -1.995808051893107],
              [-78.42713163014562, -1.997870000076205],
              [-78.4316255037183, -1.997768914323842],
              [-78.43300650608677, -1.999253014101441],
              [-78.43640855295078, -1.999926751479336],
              [-78.43630964649323, -2.000984715299126],
              [-78.43654651907718, -2.002620527524357],
              [-78.43840763685999, -2.003625685354052],
              [-78.43801854891421, -2.005523515872824],
              [-78.43689755318249, -2.005142474554134],
              [-78.43544765156986, -2.004390617838567],
              [-78.43396757510575, -2.00396364369044],
              [-78.43253359910916, -2.004331441602119],
              [-78.43148049679323, -2.003949394454992],
              [-78.43071757596549, -2.004798481247462],
              [-78.4315415170484, -2.006471341484769],
              [-78.43305964735295, -2.006691282624331],
              [-78.43522653696388, -2.007309364165053],
              [-78.4364246462049, -2.008015288051126],
              [-78.43587462571801, -2.00970524737096],
              [-78.43448658255086, -2.008600177255289],
              [-78.43412062865788, -2.010479064672836],
              [-78.43312049997006, -2.010061981170452],
              [-78.43193865162122, -2.010176980882079],
              [-78.43254851889684, -2.011596875281327],
              [-78.43388358843586, -2.011616824210932],
              [-78.43400562894621, -2.013777846489631],
              [-78.43460862306121, -2.015450706726938],
              [-78.43482957002912, -2.016598692186903],
              [-78.43208349090823, -2.015952782727595],
              [-78.43072562259255, -2.016004582889309],
              [-78.42982456800034, -2.014909738695508],
              [-78.42955064740431, -2.013408707473445],
              [-78.42858857255693, -2.01240388491982],
              [-78.42696365680584, -2.009528221575749],
              [-78.42570452730965, -2.008550221112273],
              [-78.42374450306927, -2.007976060744227],
              [-78.4247815121311, -2.011974060924615],
              [-78.42584953423471, -2.012002894671525],
              [-78.42644464936073, -2.012979721668501],
              [-78.4267276224122, -2.015501668698278],
              [-78.42565155368153, -2.01628571192208],
              [-78.42432352494117, -2.016247658081625],
              [-78.42319465022045, -2.016742693283618],
              [-78.4220885742764, -2.019361367476108],
              [-78.41902951489064, -2.017983550230838],
              [-78.41939563642168, -2.016673626401371],
              [-78.41828956047762, -2.016608582832703],
              [-78.41927359591133, -2.014720642959674],
              [-78.42051663215341, -2.013990746830473],
              [-78.4207385849497, -2.012816777470618],
              [-78.41957852954914, -2.012823985907403],
              [-78.4186015349141, -2.012071793915709],
              [-78.4194186028364, -2.01020112076327],
              [-78.4180525202556, -2.008167335774885],
              [-78.4163136106212, -2.008598333236591],
              [-78.41687754506736, -2.009521013138965],
              [-78.41474150086015, -2.010800929755078],
              [-78.41535153577382, -2.011633085103085],
              [-78.41600750351695, -2.013116681966551],
              [-78.41500050166852, -2.014108596389235],
              [-78.41297157818397, -2.012541851046251],
              [-78.41188058966564, -2.009332085041251],
              [-78.4095914919063, -2.009979167967003],
              [-78.40925554522671, -2.01096387395296],
              [-78.41043052041493, -2.012121750058668],
              [-78.41156760940078, -2.012864721956873],
              [-78.40946961903401, -2.013412898425031],
              [-78.41001159289384, -2.014380672966581],
              [-78.40885958412034, -2.014622574692453],
              [-78.40833253004817, -2.015643490500167],
              [-78.40864551031304, -2.016918713250448],
              [-78.4070894938061, -2.019066492122136],
              [-78.40641760044693, -2.016991635808154],
              [-78.4053805913851, -2.018485458593489],
              [-78.40385458209155, -2.017009573080941],
              [-78.40530364551387, -2.016831709095413],
              [-78.4050825309079, -2.015791682548411],
              [-78.4060975793834, -2.01538666898665],
              [-78.40721153431645, -2.014701867496569],
              [-78.40735654124151, -2.012939991447411],
              [-78.4078525822719, -2.012063914926728],
              [-78.40739459508197, -2.010997066289576],
              [-78.4051586385888, -2.010957000792359],
              [-78.40551755168312, -2.009545153020156],
              [-78.4070355143496, -2.007381280894379],
              [-78.40639463403221, -2.006576282912647],
              [-78.40789063611236, -2.005665337674714],
              [-78.40950063207579, -2.005569448702261],
              [-78.41044661366905, -2.004413584253314],
              [-78.40766164251738, -2.003812434156998],
              [-78.40716560148701, -2.001751659440345],
              [-78.40620453246801, -2.003837412228506],
              [-78.40503659807845, -2.002073524522586],
              [-78.4045635234628, -2.003563491632463],
              [-78.40432765670722, -2.0050455797533],
              [-78.40353355519963, -2.006110416733748],
              [-78.40200754590609, -2.005243392668433],
              [-78.40254264660531, -2.007690237845679],
              [-78.40152759812982, -2.008105309691359],
              [-78.40217551924589, -2.009750174372016],
              [-78.40069561041986, -2.011636940778544],
              [-78.4001085419209, -2.012909816595993],
              [-78.39978751502898, -2.014572786187557],
              [-78.3998036082831, -2.01580157319421],
              [-78.39904051981729, -2.016676643886512],
              [-78.39831565283001, -2.015862761087419],
              [-78.39718661047122, -2.015508541858935],
              [-78.39739263765146, -2.016683517047113],
              [-78.39691151640875, -2.017812391767848],
              [-78.39559958092251, -2.016355616994588],
              [-78.39564551375194, -2.018515298168779],
              [-78.39613350815527, -2.020061424029961],
              [-78.39404255858717, -2.020509352936074],
              [-78.3932115767056, -2.021882141039441],
              [-78.39183862096418, -2.023171948301297],
              [-78.39082357248869, -2.022690994696632],
              [-78.39035049787303, -2.02377494241631],
              [-78.3892516303657, -2.023900168049863],
              [-78.38950359037538, -2.02205715517789],
              [-78.3901365917038, -2.021054344281083],
              [-78.38962563088573, -2.019228430491637],
              [-78.38887058904699, -2.02062837596128],
              [-78.38773350006115, -2.021972162679617],
              [-78.38729864692401, -2.023210002141752],
              [-78.38745153283807, -2.02436687241908],
              [-78.38905364981251, -2.025373036077212],
              [-78.38805352112469, -2.026039732656386],
              [-78.38779452031632, -2.027882745528359],
              [-78.38786358719855, -2.029943520244956],
              [-78.38684853872306, -2.029661720659931],
              [-78.38545948972754, -2.028881533111644],
              [-78.3859025571298, -2.025855833700575],
              [-78.38545261656692, -2.024156151373063],
              [-78.38410162141184, -2.025707976928402],
              [-78.38267552440425, -2.028651533688333],
              [-78.38152351563075, -2.029716538306786],
              [-78.38069152792077, -2.03088950183826],
              [-78.38040956069769, -2.029551750090206],
              [-78.37682361487784, -2.031353523998462],
              [-78.37588450644517, -2.032011503398337],
              [-78.37513751123349, -2.031133582858956],
              [-78.37703651521869, -2.02913047563618],
              [-78.37805961032123, -2.02894272100491],
              [-78.37796757702428, -2.027568591797035],
              [-78.37940959964793, -2.027715778016955],
              [-78.37931857217936, -2.026278952173243],
              [-78.37997453992249, -2.024318927932882],
              [-78.3782346244597, -2.024234941262989],
              [-78.37844065163995, -2.023132218080207],
              [-78.37631265405979, -2.023129032957002],
              [-78.37732652906882, -2.021965289519017],
              [-78.37593060691269, -2.020968345954429],
              [-78.37476351071345, -2.021382076695545],
              [-78.37398550245999, -2.022339290039042],
              [-78.37281052727175, -2.022445069657238],
              [-78.37163555208353, -2.021539153561207],
              [-78.37048354331003, -2.021538147732826],
              [-78.37081948998961, -2.019902335507538],
              [-78.37133061844573, -2.018764408331435],
              [-78.37377964291781, -2.019067497950516],
              [-78.37362658936568, -2.018036356220932],
              [-78.37217752594336, -2.017085513123789],
              [-78.37138359207384, -2.01629744658652],
              [-78.37258958030385, -2.015344591832616],
              [-78.37457257095895, -2.016492744930702],
              [-78.37533549178667, -2.017572501698737],
              [-78.37686150108023, -2.017635365972637],
              [-78.37806665111991, -2.017971312652207],
              [-78.37899050448877, -2.016654683300203],
              [-78.37734262232294, -2.015936521835442],
              [-78.37635054026217, -2.015799561537449],
              [-78.37457257095895, -2.014829607701074],
              [-78.37607561383776, -2.013793772105657],
              [-78.37532761279769, -2.012544868531393],
              [-78.3758466202428, -2.01157893800854],
              [-78.37678556103738, -2.012086043151101],
              [-78.37799859006606, -2.010886089891414],
              [-78.37951655273255, -2.010463977247127],
              [-78.38210253750265, -2.010937890053071],
              [-78.38127859641973, -2.009753191857158],
              [-78.38025650714556, -2.009300233809142],
              [-78.37835649733198, -2.009351195780482],
              [-78.37798350264033, -2.007633073265993],
              [-78.37961562682817, -2.008024172868545],
              [-78.37846361805467, -2.006531188273527],
              [-78.38006556739104, -2.005507254980671],
              [-78.38173658360967, -2.005370462320684],
              [-78.38225559105477, -2.004449458798945],
              [-78.38317156543464, -2.004921359948128],
              [-78.38442952146437, -2.003170715649276],
              [-78.3837054926674, -2.001807818191708],
              [-78.38382753317775, -1.9998709280041],
              [-78.38346862008345, -1.997836807739588],
              [-78.38587959071508, -1.996476089576845],
              [-78.38706160670198, -1.995294241228009],
              [-78.38528464322714, -1.995535137125501],
              [-78.38359853958279, -1.994068304068435],
              [-78.38088950847396, -1.995167003937695],
              [-78.38028752018734, -1.996015252539792],
              [-78.37908153195734, -1.995100116350272],
              [-78.37739559595106, -1.996128240594714],
              [-78.37541159946758, -1.995284015306083],
              [-78.36951459530204, -1.994516065336484],
              [-78.36897262144221, -1.993087286119817],
              [-78.36728651779787, -1.992786208157497],
              [-78.36633248957754, -1.991203369560367],
              [-78.36277755679947, -1.989652717471472],
              [-78.36109949978218, -1.988438514976338],
              [-78.3600765723177, -1.987072935309754],
              [-78.35730752678208, -1.985983958448173],
              [-78.35405752764181, -1.985489929074561],
              [-78.35418660895083, -1.984036004148379],
              [-78.35225659192386, -1.98318306168045],
              [-78.35002851441969, -1.983577178768144],
              [-78.3482896047853, -1.982571015110011],
              [-78.34641256138643, -1.982902100285742],
              [-78.34443661153001, -1.981354130405862],
              [-78.34311662941671, -1.981469297755609],
              [-78.3420186000997, -1.982849964847958],
              [-78.33973654313904, -1.98365110715423],
              [-78.33866164787482, -1.982917020073444],
              [-78.33300051046486, -1.984489129834458],
              [-78.33131457445857, -1.985453886890866],
              [-78.3281785692016, -1.986541690286003],
              [-78.32673654657795, -1.987506782618482],
              [-78.32505061057167, -1.988352516649684],
              [-78.32300559383302, -1.987500747648198],
              [-78.32011450778705, -1.98700789174103],
              [-78.3193354937052, -1.988714614867206],
              [-78.31734462406112, -1.989912556470131],
              [-78.31678052197688, -1.991023326280015],
              [-78.31439251775998, -1.990902626874174],
              [-78.31079852531306, -1.989902665824388],
              [-78.30939455652987, -1.991119382890474],
              [-78.30975363726225, -1.992891484861502],
              [-78.31085149894118, -1.994664257384727],
              [-78.31121862630062, -1.996608188370999],
              [-78.31214851463976, -1.998551951719207],
              [-78.31226351435143, -1.999826839193361],
              [-78.31277464280755, -2.00071263872178],
              [-78.31495661984421, -2.002939710397584],
              [-78.31557453374688, -2.004386426886981],
              [-78.3172985235936, -2.007263431335616],
              [-78.31731461684772, -2.008501103159631],
              [-78.31783261846445, -2.009649088619653],
              [-78.31967160802286, -2.012471945973687],
              [-78.32028164293652, -2.014090826754511],
              [-78.32129652377395, -2.015140576309136],
              [-78.32121253710406, -2.01618848184512],
              [-78.32345553439589, -2.017900401751263],
              [-78.32496662390177, -2.018788380574506],
              [-78.32617965293045, -2.019820192856344],
              [-78.32633253884451, -2.020842114492496],
              [-78.32749963504375, -2.021820282593978],
              [-78.32900251028451, -2.022066039995309],
              [-78.33005561260045, -2.022908253627122],
              [-78.33159654168166, -2.02376907508409],
              [-78.33233649609468, -2.024999873747504],
              [-78.33151255501177, -2.025577051600692],
              [-78.33225250942478, -2.027050757818358],
              [-78.33357249153808, -2.027631791347005],
              [-78.33209962351071, -2.029318733181697],
              [-78.33074158755697, -2.029957601842341],
              [-78.32874250364776, -2.031346315561734],
              [-78.32991764647406, -2.031917458444639],
              [-78.33396157948384, -2.030044605997375],
              [-78.3356245490754, -2.030300421682512],
              [-78.33740251837864, -2.031451424627619],
              [-78.33888259484273, -2.031354529826842],
              [-78.34046962439145, -2.031591402410811],
              [-78.34156765370845, -2.032208478123152],
              [-78.34205665394016, -2.033862395259291],
              [-78.34371962353173, -2.034706117633675],
              [-78.34611550673762, -2.036318125253899],
              [-78.34454356461464, -2.03912572754416],
              [-78.34572558060154, -2.039561754147769],
              [-78.34718352884123, -2.039509786347992],
              [-78.34993765458918, -2.035692164724196],
              [-78.35121153623497, -2.035134097610239],
              [-78.35243965268938, -2.032190540850308],
              [-78.35105160952223, -2.031808493703238],
              [-78.35140264362755, -2.030814567623793],
              [-78.35269949168806, -2.029741516378238],
              [-78.3550185966608, -2.030377535191803],
              [-78.35588863821123, -2.031183371363852],
              [-78.35610958517914, -2.03266545948469],
              [-78.35611763180619, -2.034337313893616],
              [-78.35578151748854, -2.03529503015136],
              [-78.35726159395264, -2.035993075048452],
              [-78.35872658299101, -2.036438154107486],
              [-78.35930661069129, -2.037696110137233],
              [-78.35797858195093, -2.03954599616975],
              [-78.35879564987324, -2.041011823398435],
              [-78.35892456354419, -2.042529786064961],
              [-78.35872658299101, -2.043993601636885],
              [-78.35756652759045, -2.047208229145724],
              [-78.355956531627, -2.047892360083495],
              [-78.35594949082834, -2.049347123199993],
              [-78.35691055984732, -2.049972916091633],
              [-78.35691860647438, -2.051410077211472],
              [-78.35743761391949, -2.052919993250896],
              [-78.35734558062254, -2.054365871549976],
              [-78.35693352626205, -2.056036552492515],
              [-78.35484358252234, -2.05826546818696],
              [-78.35394252793013, -2.057576475745293],
              [-78.35144052982994, -2.058340402401427],
              [-78.35317256630373, -2.060367146591148],
              [-78.35444661558758, -2.060568144629485],
              [-78.35507961691597, -2.059584444471909],
              [-78.35680360676271, -2.060482146302832],
              [-78.35923754380906, -2.062636965973184],
              [-78.36065660001799, -2.063208947046405],
              [-78.36180860879149, -2.063301147981406],
              [-78.36295357676632, -2.064893877224279],
              [-78.3636475983499, -2.066186869609339],
              [-78.36479156049634, -2.06750869574131],
              [-78.36499758767658, -2.069171497694811],
              [-78.36406753169938, -2.070462646061173],
              [-78.36476155328295, -2.071421368147298],
              [-78.36435653972113, -2.073011415181156],
              [-78.36367056476462, -2.074528204381181],
              [-78.36205252217412, -2.075095323950563],
              [-78.36228956239614, -2.076143061848484],
              [-78.36318156453291, -2.077355923239054],
              [-78.36343352454259, -2.078503908699076],
              [-78.36254152240583, -2.080625703670876],
              [-78.36124450670725, -2.081310840437084],
              [-78.36043565305008, -2.082773482542564],
              [-78.36217456268447, -2.082486486177572],
              [-78.3645855333161, -2.08037676114634],
              [-78.3666686038952, -2.078925015514983],
              [-78.36644748928923, -2.077803852145166],
              [-78.36550854849463, -2.076419999929612],
              [-78.36457849251742, -2.075478209287951],
              [-78.36553956153642, -2.073663359610748],
              [-78.3665924962143, -2.072590308365193],
              [-78.36698158416006, -2.069897705786616],
              [-78.36663859668181, -2.068261725923321],
              [-78.36608857619491, -2.067049702723011],
              [-78.36567652183443, -2.065539786683587],
              [-78.36643961030023, -2.064528929159678],
              [-78.36440264018864, -2.061859292995848],
              [-78.3604586194648, -2.0591415447077],
              [-78.35948162482975, -2.057793399399714],
              [-78.35896261738465, -2.056120706800471],
              [-78.35926855685084, -2.054729646148132],
              [-78.36019861282804, -2.053321989327571],
              [-78.36142756747277, -2.052419928907],
              [-78.36247262316166, -2.051875775752308],
              [-78.36427255305124, -2.052478769867321],
              [-78.36567652183443, -2.053619882166686],
              [-78.36682853060793, -2.053675705641865],
              [-78.36709557804335, -2.051931934503671],
              [-78.36874362784724, -2.050689065899633],
              [-78.37118561152066, -2.052307946680457],
              [-78.37042252305486, -2.053238840847996],
              [-78.37136062565912, -2.054044844658108],
              [-78.37290155474032, -2.053161727338704],
              [-78.37401550967338, -2.052820751517174],
              [-78.37654852081536, -2.052743973284066],
              [-78.37784553651393, -2.053992709220267],
              [-78.37687658850595, -2.055753747079109],
              [-78.37630460743273, -2.057270536279134],
              [-78.37707557488753, -2.058771399863133],
              [-78.37723550160025, -2.05995626569711],
              [-78.3797225799128, -2.059156464495345],
              [-78.38183549006722, -2.063199056400663],
              [-78.38248458464973, -2.061674052935473],
              [-78.38523049613256, -2.06245424048376],
              [-78.38671861922373, -2.062186019581929],
              [-78.3898846316941, -2.06321213216961],
              [-78.39099154582848, -2.063756117686182],
              [-78.39271553567521, -2.064038923099588],
              [-78.39379864520455, -2.06341715352147],
              [-78.39481352604197, -2.063951918944554],
              [-78.39613350815527, -2.06343911410778],
              [-78.397254503887, -2.063251024200383],
              [-78.39685049615358, -2.061832135629516],
              [-78.39584349430514, -2.060809375803103],
              [-78.39435553885204, -2.059912176886371],
              [-78.39186058155052, -2.059058396228124],
              [-78.3897245373433, -2.057220580136175],
              [-78.38851955494168, -2.056468555782544],
              [-78.38811454137986, -2.055500613602931],
              [-78.386840492096, -2.05420678302761],
              [-78.38800054749657, -2.053773941547206],
              [-78.39070153197834, -2.0542758499098],
              [-78.39297453648356, -2.056312484745263],
              [-78.39456961265934, -2.057119494383755],
              [-78.39617960862279, -2.057321498250474],
              [-78.39910154007246, -2.057000638996612],
              [-78.40048254244094, -2.056388760064237],
              [-78.40104664452517, -2.055368514808777],
              [-78.40264155306288, -2.054475842119757],
              [-78.40185549818236, -2.053616864681544],
              [-78.40044365041017, -2.053089810609379],
              [-78.39884958006277, -2.052761910556853],
              [-78.39770561791633, -2.051855994460823],
              [-78.39855956621264, -2.051090056147927],
              [-78.39694957024919, -2.049288282239672],
              [-78.39569060839106, -2.048365267061115],
              [-78.39617960862279, -2.047416435620789],
              [-78.39647649563354, -2.045772409130393],
              [-78.39540059454093, -2.04304175271136],
              [-78.39689659662108, -2.044010533081291],
              [-78.39822361953304, -2.04630834801992],
              [-78.39866651929725, -2.047411238840823],
              [-78.39969665519847, -2.04726924940087],
              [-78.40007752887912, -2.048381192677141],
              [-78.40002455525101, -2.049628252232765],
              [-78.40113063119506, -2.049286270582911],
              [-78.40184761919336, -2.047823293201304],
              [-78.40380864926212, -2.047754393957121],
              [-78.40485353731293, -2.047177216103933],
              [-78.40650963374388, -2.046557458182576],
              [-78.4072495881569, -2.04563645466078],
              [-78.40862254389832, -2.044554518597863],
              [-78.40869161078055, -2.045882547338238],
              [-78.4103245731587, -2.044800611275321],
              [-78.41130056196536, -2.04382646648736],
              [-78.41177363658103, -2.042579574569857],
              [-78.41281165147124, -2.042265588476596],
              [-78.41271257737561, -2.043430673019088],
              [-78.41130056196536, -2.046465257247519],
              [-78.41249850356832, -2.046774214198877],
              [-78.41201754996365, -2.04780435010008],
              [-78.40856152364314, -2.048829121583253],
              [-78.40778351538967, -2.049767224187519],
              [-78.40726450794456, -2.051681986150754],
              [-78.40686050021114, -2.05532358780772],
              [-78.4080195497833, -2.055153770449238],
              [-78.40864551031304, -2.056989407246363],
              [-78.40859957748359, -2.05845339045635],
              [-78.40978964009754, -2.058039492077171],
              [-78.40973649883136, -2.056701740329117],
              [-78.41143752226337, -2.055882493111994],
              [-78.41272749716327, -2.055676633569817],
              [-78.41386458614912, -2.054521774949194],
              [-78.4140925739157, -2.052343821226088],
              [-78.41510058159253, -2.053646704256892],
              [-78.41536762902794, -2.055039776565934],
              [-78.41539763624134, -2.0562506262998],
              [-78.41683949122692, -2.056821601544641],
              [-78.41823558102112, -2.056525720362288],
              [-78.41905952210404, -2.055243792089414],
              [-78.41979964415512, -2.056205699298744],
              [-78.42077663879016, -2.054270820767897],
              [-78.42180660705331, -2.055184615852966],
              [-78.4229656566255, -2.055114543142338],
              [-78.42328651587934, -2.053488789200912],
              [-78.42450759153508, -2.053345961570642],
              [-78.42657456886008, -2.054243831039628],
              [-78.42725350301791, -2.053422739803807],
              [-78.42826050486634, -2.053948620409528],
              [-78.42997762155247, -2.054113911540298],
              [-78.43081665006112, -2.053156698196801],
              [-78.43161058393063, -2.05422488793846],
              [-78.43273157966236, -2.054172752500676],
              [-78.43368560788268, -2.052131926713628],
              [-78.43441064250803, -2.049819024349233],
              [-78.43542451751706, -2.048962058567781],
              [-78.43503559720936, -2.046332487901111],
              [-78.43602751163208, -2.045963348884925],
              [-78.43728664112827, -2.046480177035221],
              [-78.4386825632844, -2.045135384488503],
              [-78.44010161949332, -2.04256247548733],
              [-78.44019365279027, -2.039137965122791],
              [-78.43942251769742, -2.037672137894106],
              [-78.4393695440693, -2.036660106903753],
              [-78.44061258031137, -2.036092149144054],
              [-78.4413755011391, -2.033913189592568],
              [-78.44257361038012, -2.036050239628139],
              [-78.44349662555867, -2.038337828644956],
              [-78.4437945183978, -2.041420860273774],
              [-78.44505364789399, -2.044395597713503],
              [-78.4439006332921, -2.04526128067431],
              [-78.44347349150588, -2.046526277502721],
              [-78.44287854401793, -2.047537302664693],
              [-78.44261954320957, -2.048594260656103],
              [-78.44375663219542, -2.050294948811938],
              [-78.44501458822515, -2.051391134110247],
              [-78.44649449705119, -2.052386736570384],
              [-78.44465651332116, -2.053269686251724],
              [-78.44297057731488, -2.053447717875315],
              [-78.44199358267984, -2.05305795937727],
              [-78.44120752779932, -2.05425875082733],
              [-78.44107861412836, -2.058216517872381],
              [-78.43907953021916, -2.059822322884258],
              [-78.43885757742287, -2.060942312787574],
              [-78.4377214942654, -2.06175435156797],
              [-78.43768360806303, -2.062955981208347],
              [-78.43915563790006, -2.064224163159963],
              [-78.43775954810586, -2.065224794761946],
              [-78.43625650522704, -2.065275924371406],
              [-78.43521849033682, -2.066006993967051],
              [-78.43620252577054, -2.068547716459932],
              [-78.43509661746454, -2.06898860456738],
              [-78.4339526553181, -2.067857885827948],
              [-78.43235757914232, -2.067393696029683],
              [-78.43064163592264, -2.067878840585934],
              [-78.4295885336067, -2.06850983025754],
              [-78.42913859304383, -2.069448771052123],
              [-78.42980160158562, -2.070552667701349],
              [-78.4310146306143, -2.071557322616911],
              [-78.43238054555704, -2.072210272874884],
              [-78.43339559403253, -2.073414417086212],
              [-78.43424954232884, -2.074925171315954],
              [-78.43460057643415, -2.076010124863956],
              [-78.43354764175628, -2.07786101672491],
              [-78.43236562576938, -2.07897094834442],
              [-78.43033552881839, -2.081831691900845],
              [-78.42912249978971, -2.081956749896392],
              [-78.42817651819645, -2.080355806388354],
              [-78.42860449817299, -2.078784031903467],
              [-78.42947353389502, -2.078115994219729],
              [-78.42900850590642, -2.077039925489089],
              [-78.42924454030006, -2.075929155679205],
              [-78.42823753845163, -2.075041176856018],
              [-78.42724662985731, -2.075618187071143],
              [-78.42712358351856, -2.07680204707674],
              [-78.42372857745323, -2.079742753989535],
              [-78.42256952788105, -2.079126013553321],
              [-78.42174558679814, -2.080145755894591],
              [-78.42133353243764, -2.081311846265464],
              [-78.4198375303575, -2.081398850420499],
              [-78.41825050080878, -2.081170862653892],
              [-78.41700763220477, -2.079885916895932],
              [-78.41599258372928, -2.080073839165266],
              [-78.41468064824303, -2.079998904950799],
              [-78.41512254217886, -2.081445789078316],
              [-78.41590859705938, -2.082458490620922],
              [-78.41645861754627, -2.084104528768023],
              [-78.41838058794619, -2.08701841359067],
              [-78.41976963694172, -2.087743112939904],
              [-78.42086062546005, -2.086434362576881],
              [-78.42368365045216, -2.086601330088286],
              [-78.42350763048532, -2.087740263092826],
              [-78.42250850762588, -2.089166024824294],
              [-78.42131056602292, -2.091758044564642],
              [-78.42183661426671, -2.093412799891098],
              [-78.42337754334791, -2.095909768849367],
              [-78.42452955212141, -2.096038514882309],
              [-78.42440751161107, -2.097032608599818],
              [-78.42301963608199, -2.097264619679947],
              [-78.42199654097944, -2.09776937788962],
              [-78.420494503929, -2.097477520020789],
              [-78.4190675687311, -2.096444534272507],
              [-78.41794556717097, -2.096479738265828],
              [-78.4163436178346, -2.097163533927528],
              [-78.41471853444543, -2.097413649918508],
              [-78.41403960028758, -2.095830643683314],
              [-78.41394756699063, -2.09462884640493],
              [-78.41476463491294, -2.093617821242958],
              [-78.41403155366052, -2.09292094981231],
              [-78.41250655019537, -2.09280075332066],
              [-78.41156760940078, -2.092040849978048],
              [-78.41004160010723, -2.091206850611343],
              [-78.40912663155575, -2.090184090784931],
              [-78.4084015969304, -2.091854939365476],
              [-78.40795165636753, -2.095784543215814],
              [-78.40795165636753, -2.097203431786681],
              [-78.40885958412034, -2.098678479108855],
              [-78.40928655826849, -2.099781202291695],
              [-78.4099735390534, -2.102592995533541],
              [-78.40978259929888, -2.103712985436857],
              [-78.40820361637722, -2.103883138071467],
              [-78.40669252687134, -2.103058023522124],
              [-78.40523558446003, -2.103164138416446],
              [-78.40435749628254, -2.10413794792828],
              [-78.4042285826116, -2.105438819302321],
              [-78.40604359992689, -2.105361035240776],
              [-78.40729552098634, -2.104657961201724],
              [-78.40871457719527, -2.105916755421788],
              [-78.4089584905779, -2.107055688426328],
              [-78.40875263103572, -2.108076771872106],
              [-78.40970649161797, -2.1090457198801],
              [-78.41123954171019, -2.109264655191282],
              [-78.41236858406897, -2.108678592520675],
              [-78.4131246317361, -2.110162692298331],
              [-78.4134595725873, -2.111591471514942],
              [-78.4146425944026, -2.114259263660074],
              [-78.41476463491294, -2.115877138612518],
              [-78.41523754189053, -2.117413876742148],
              [-78.41680965165159, -2.117108943104313],
              [-78.4180525202556, -2.116045279590367],
              [-78.41935758258123, -2.115198204454657],
              [-78.42102859879984, -2.114595210339701],
              [-78.42225654761619, -2.114651369091007],
              [-78.42335558276157, -2.115349246350036],
              [-78.42430156435483, -2.114564364935973],
              [-78.42379764433547, -2.11363229730199],
              [-78.42275962944524, -2.112356403999456],
              [-78.42213450710584, -2.111180422982841],
              [-78.41929656232605, -2.109386528063567],
              [-78.41821261460639, -2.10663793437169],
              [-78.42142455990621, -2.104591911804675],
              [-78.4221725609463, -2.103571163635024],
              [-78.42278259585997, -2.102244308361094],
              [-78.42278259585997, -2.100662140316217],
              [-78.42504855956652, -2.101037314302687],
              [-78.42657456886008, -2.101699149378078],
              [-78.4269785765935, -2.099811209505049],
              [-78.42570452730965, -2.098570352557829],
              [-78.42702450942295, -2.097650354864413],
              [-78.42808549072788, -2.097282556952734],
              [-78.4299166012973, -2.098089566591227],
              [-78.4324185993975, -2.100154364621403],
              [-78.4334945004901, -2.099415416036777],
              [-78.4340435151486, -2.100916279620719],
              [-78.43432665583813, -2.102688213953684],
              [-78.43444852871042, -2.105218878162759],
              [-78.43379960176597, -2.111082522353627],
              [-78.43504364383642, -2.111627513698636],
              [-78.43636362594972, -2.111285532048726],
              [-78.43688162756645, -2.109569588829061],
              [-78.43703451348051, -2.106551936045037],
              [-78.43678255347082, -2.105051072461038],
              [-78.43753860113794, -2.103596979896793],
              [-78.43743852121393, -2.101816328384587],
              [-78.43852950973228, -2.101719098307626],
              [-78.43907165123017, -2.100228460645553],
              [-78.44012458590805, -2.100176492845776],
              [-78.44116964159693, -2.101931328096214],
              [-78.44230656294471, -2.106550930216656],
              [-78.44257361038012, -2.109488787282373],
              [-78.4423905496146, -2.111205401054292],
              [-78.44266564367707, -2.114089446301591],
              [-78.44259657679486, -2.118463123382639],
              [-78.44287854401793, -2.119637763294747],
              [-78.44227554990293, -2.121091688220872],
              [-78.44223749606248, -2.122348638422238],
              [-78.44186349554244, -2.123549597510362],
              [-78.44206952272269, -2.124743348161701],
              [-78.44159661574508, -2.126332389367178],
              [-78.44103955445952, -2.127470316543338],
              [-78.43994152514253, -2.128824161545481],
              [-78.43915563790006, -2.130142970192367],
              [-78.4370115470658, -2.135850543345953],
              [-78.43466159668932, -2.137807382463109],
              [-78.43446361613614, -2.139253260762246],
              [-78.43591351774879, -2.139255272419007],
              [-78.4381635558393, -2.143363243169119],
              [-78.43819456888107, -2.145035097578045],
              [-78.43790455503093, -2.146814743261871],
              [-78.43716460061792, -2.148394732011923],
              [-78.4362105723976, -2.149540538177064],
              [-78.43483761665618, -2.150559609966137],
              [-78.43381452155363, -2.150829674886666],
              [-78.43389163506292, -2.148461787237409],
              [-78.43285362017271, -2.147537766230471],
              [-78.43096148934812, -2.146386930923427],
              [-78.42947353389502, -2.147152701598259],
              [-78.42748249661288, -2.149770537600432],
              [-78.4260106344139, -2.150210755155626],
              [-78.42472049187593, -2.149250524326931],
              [-78.4234925430596, -2.150016462639826],
              [-78.42331652309274, -2.151001503901909],
              [-78.42400350387764, -2.151788564610854],
              [-78.42523162033204, -2.151483630973019],
              [-78.42575062777716, -2.153147271116836],
              [-78.42676550861458, -2.154369184962889],
              [-78.4278796311857, -2.154759111098997],
              [-78.42948158052208, -2.159334792046707],
              [-78.43015263569093, -2.162715716514697],
              [-78.4309925023899, -2.161741571726736],
              [-78.4317855980691, -2.15874202385362],
              [-78.43318152022523, -2.158346900937545],
              [-78.43385358122246, -2.156586030716824],
              [-78.43466964331638, -2.157807106372559],
              [-78.43528755721906, -2.164722679412932],
              [-78.43630260569455, -2.166522274026363],
              [-78.43887350303892, -2.167376222322673],
              [-78.4388966370917, -2.165143450952712],
              [-78.43862958965629, -2.161654735209765],
              [-78.43828559634964, -2.160470707566105],
              [-78.43862154302923, -2.159007059632245],
              [-78.4400486458652, -2.158512862620569],
              [-78.4414826218618, -2.15845201000343],
              [-78.44288659064499, -2.158834057150557],
              [-78.44276455013464, -2.163062559668447],
              [-78.4437026527389, -2.164139466589404],
              [-78.44501458822515, -2.166528308996647],
              [-78.44534265591574, -2.168074267219765],
              [-78.44606752290304, -2.169692980362527],
              [-78.44611362337054, -2.171112036571458],
              [-78.44559461592543, -2.172602003681277],
              [-78.44304651735773, -2.175887709729125],
              [-78.44249750269923, -2.177124543362822],
              [-78.44133761493673, -2.176245784633124],
              [-78.43989559231308, -2.176803684108961],
              [-78.43843060327471, -2.177822588259971],
              [-78.43648549882201, -2.177093697959151],
              [-78.43662262675808, -2.179148437705464],
              [-78.4340135079352, -2.180598339318124],
              [-78.4327775124918, -2.179801220325373],
              [-78.4317855980691, -2.180938141673153],
              [-78.43057256904042, -2.181785216808862],
              [-78.42940564047925, -2.183031102898042],
              [-78.42984753441506, -2.184197025630795],
              [-78.43170949038819, -2.183667121711551],
              [-78.43279259991753, -2.1829191206715],
              [-78.43383765560641, -2.181773314506358],
              [-78.43515059692105, -2.182625251145907],
              [-78.43691264060823, -2.185627984142229],
              [-78.43943760512315, -2.187973911205177],
              [-78.44058257309798, -2.186791895218278],
              [-78.44136058135145, -2.183900976810378],
              [-78.44232952935943, -2.183035126211564],
              [-78.44316051124102, -2.183823192748832],
              [-78.44413750587606, -2.182957174511955],
              [-78.44509153409638, -2.181801310062951],
              [-78.4468155239431, -2.18145211997637],
              [-78.44834153323664, -2.181843387216929],
              [-78.44962362914755, -2.182930184783686],
              [-78.45116455822877, -2.183609957131864],
              [-78.4516825598455, -2.18484913769845],
              [-78.45382665067976, -2.186190912760026],
              [-78.4554906260997, -2.186680918820116],
              [-78.45648958132108, -2.186547981835645],
              [-78.4578325298491, -2.184806054716091],
              [-78.4583815445076, -2.183225227775722],
              [-78.45811449707219, -2.181552367538416],
              [-78.45826755062431, -2.180287203071941],
              [-78.4593504925156, -2.180967310696246],
              [-78.4620665644231, -2.181215415030465],
              [-78.46245565236886, -2.179517409083587],
              [-78.4617306177435, -2.177988549942995],
              [-78.46073149488406, -2.176793458187092],
              [-78.46057860897, -2.17426396744446],
              [-78.46133465663713, -2.173433991391278],
              [-78.4638215673116, -2.173048926759009],
              [-78.4647526291172, -2.172544000911273],
              [-78.4654764902761, -2.169138098371775],
              [-78.46553063737066, -2.168090192835791],
              [-78.46430956171493, -2.16771736578221],
              [-78.46207461105014, -2.165752480038009],
              [-78.4595945735363, -2.166525291511505],
              [-78.45852655143268, -2.167391309748382],
              [-78.45758861646647, -2.168420104545078],
              [-78.45658865541671, -2.168192284416591],
              [-78.45481856510247, -2.165351489789714],
              [-78.45358256965908, -2.165458442874353],
              [-78.4501184967115, -2.163617441659142],
              [-78.44819652631158, -2.161634618642154],
              [-78.44926454841519, -2.160407675654199],
              [-78.45082056492213, -2.160021772831612],
              [-78.45375054299886, -2.158626018313555],
              [-78.45487254455897, -2.159486839770466],
              [-78.45785549626383, -2.156672029043477],
              [-78.45864054531597, -2.157747930136054],
              [-78.45960262016334, -2.156756183351433],
              [-78.46076150209745, -2.156496009076591],
              [-78.46396657423666, -2.154956085823812],
              [-78.46669756593184, -2.155855296397306],
              [-78.46665163310239, -2.154752237938396],
              [-78.46459957556507, -2.153917400381317],
              [-78.46337849990933, -2.153671475341923],
              [-78.46048758150143, -2.152365407187915],
              [-78.45874062523998, -2.152163403321197],
              [-78.45573453948234, -2.150450645224737],
              [-78.45328551501025, -2.148484753652156],
              [-78.4539265629657, -2.14699478654228],
              [-78.45363654911557, -2.145539855787717],
              [-78.45216351345015, -2.145067116448217],
              [-78.45375858962592, -2.144400922783177],
              [-78.45650450110875, -2.143663985855312],
              [-78.45962558657807, -2.14416706768435],
              [-78.46066259563989, -2.143220080262722],
              [-78.46044164867199, -2.142144179170145],
              [-78.45944252581255, -2.140849175128324],
              [-78.45909149170723, -2.139276394814999],
              [-78.45948762045167, -2.138084655820421],
              [-78.45697053492574, -2.135693634118354],
              [-78.45770261034977, -2.134999612534784],
              [-78.45867155835775, -2.135805616344896],
              [-78.46023562149175, -2.136115746762698],
              [-78.46128050954256, -2.137327434686824],
              [-78.46292855934645, -2.139834294290893],
              [-78.46424854145975, -2.1409391967685],
              [-78.46504264296733, -2.14223218915356],
              [-78.46599549772121, -2.143237011707186],
              [-78.46720852674989, -2.142661007320385],
              [-78.4682086554377, -2.14186724108896],
              [-78.46881064372432, -2.140838446292264],
              [-78.46902454989358, -2.138615565568045],
              [-78.46872749524475, -2.136969527420945],
              [-78.46936049657316, -2.134991733545803],
              [-78.47004663916773, -2.134034855478376],
              [-78.46867351578825, -2.132721746525704],
              [-78.46584361763553, -2.131135890443431],
              [-78.46463762940552, -2.130817881036648],
              [-78.46333357290827, -2.130743114460302],
              [-78.46190663771036, -2.130018247473004],
              [-78.46068556205462, -2.128795327798571],
              [-78.45985458017303, -2.127312401487359],
              [-78.45864859194303, -2.12630741129567],
              [-78.45703155518092, -2.126277404082316],
              [-78.45462058454929, -2.125143500219679],
              [-78.45505560532449, -2.12397858331525],
              [-78.45642051443885, -2.123302666642587],
              [-78.4580226314133, -2.123142739929847],
              [-78.4603276547887, -2.122405803001982],
              [-78.46159348980743, -2.122362720019623],
              [-78.4617306177435, -2.120825646613866],
              [-78.46337162674872, -2.121379858052308],
              [-78.46463058860685, -2.122439665890795],
              [-78.46578259738035, -2.12377942929561],
              [-78.46673561977228, -2.124521395365434],
              [-78.4683835019381, -2.125211561273545],
              [-78.46976450430658, -2.125530408870588],
              [-78.47148849415332, -2.125406524341543],
              [-78.47387649837022, -2.127000427050916],
              [-78.47548649433367, -2.127076199455644],
              [-78.47641755613925, -2.128360307023343],
              [-78.47786762538996, -2.129285166220598],
              [-78.47911049399397, -2.129340989695777],
              [-78.4807436240102, -2.129037229524442],
              [-78.4809212500449, -2.128984879376842],
              [-78.48810867706014, -2.131287072522353],
              [-78.48046604305942, -2.153615209288718],
              [-78.48869565640547, -2.158423417745039],
              [-78.50491735906982, -2.166293438382186],
              [-78.50750500490264, -2.184830445535269],
              [-78.50258179411497, -2.185215916711083],
              [-78.5021515076544, -2.184726929550038],
              [-78.50308256946, -2.183165045710894],
              [-78.5043485721168, -2.182191068560996],
              [-78.50475358567861, -2.180546371518346],
              [-78.50415059156362, -2.179189509031062],
              [-78.50273857615336, -2.178238498295912],
              [-78.50193056068649, -2.177071737372785],
              [-78.49990851036257, -2.176787590854872],
              [-78.49866463593017, -2.177861480290687],
              [-78.49723853892257, -2.180696239947281],
              [-78.49671953147747, -2.182350324721483],
              [-78.49641459783966, -2.184762133543416],
              [-78.494872495292, -2.185572831219304],
              [-78.49344656592247, -2.185968959963759],
              [-78.49247761791449, -2.184773868207856],
              [-78.49178359633092, -2.183363193902153],
              [-78.49027250682504, -2.183017021300657],
              [-78.4892886390294, -2.183783127251615],
              [-78.48796061028904, -2.185913807040777],
              [-78.48716751460984, -2.186852915473423],
              [-78.48599958022028, -2.187419867404742],
              [-78.48487858448856, -2.188610600570996],
              [-78.48416863728893, -2.19018271033201],
              [-78.48435957704345, -2.191348465426756],
              [-78.4851685983387, -2.192931304023887],
              [-78.48562658552864, -2.194595279443831],
              [-78.48641163458078, -2.195979131659385],
              [-78.48625154022997, -2.197560964428135],
              [-78.48545861218884, -2.199050931537954],
              [-78.4850085039879, -2.200667968300081],
              [-78.48361962263043, -2.201514875797727],
              [-78.48249058027164, -2.201991638450806],
              [-78.48149162505027, -2.203354703546438],
              [-78.47934753421599, -2.205447497133207],
              [-78.47969052169425, -2.206940481728225],
              [-78.4807204899574, -2.208279406942722],
              [-78.48017851609758, -2.211847918403976],
              [-78.48026250276747, -2.21480371274248],
              [-78.4801174958424, -2.215996792841622],
              [-78.48115551073262, -2.218338361314864],
              [-78.48122357178647, -2.22156522640239],
              [-78.47848553929263, -2.223332299231458],
              [-78.47549454096072, -2.22430309125815],
              [-78.4742355791026, -2.223894054382811],
              [-78.47213758873583, -2.225941920968523],
              [-78.47120652693023, -2.224205022990873],
              [-78.47098557996232, -2.222506346491798],
              [-78.46949762450923, -2.219403365933317],
              [-78.46962754400857, -2.21772262670703],
              [-78.46878063651094, -2.214855848180321],
              [-78.46726954700506, -2.215513659942133],
              [-78.4664915387516, -2.217039669235646],
              [-78.46553751053128, -2.218393514237846],
              [-78.46447753505473, -2.217623552611428],
              [-78.46403463529052, -2.215543667155544],
              [-78.46469864966069, -2.214604726360903],
              [-78.46489763604227, -2.213339729532493],
              [-78.46348562063201, -2.2115941143756],
              [-78.46270761237855, -2.212576808704796],
              [-78.46100658894653, -2.212375810666458],
              [-78.46059453458605, -2.213684896305608],
              [-78.4594266001965, -2.214921897577369],
              [-78.45892351836744, -2.217975592545088],
              [-78.45666459545954, -2.220140302861239],
              [-78.4565965344057, -2.22188440927556],
              [-78.45626058772612, -2.223501278399624],
              [-78.45690163568158, -2.225047907174996],
              [-78.45635949418367, -2.226384820732733],
              [-78.45757252321236, -2.22735393637879],
              [-78.45884657249621, -2.227653673236603],
              [-78.45938854635604, -2.228495719230409],
              [-78.45911462576001, -2.231396528284051],
              [-78.45937362656838, -2.232933266413681],
              [-78.46004451409917, -2.234488277092225],
              [-78.4608535353944, -2.235339375541457],
              [-78.46353960008851, -2.236754073160739],
              [-78.46418752120458, -2.238282932301331],
              [-78.46531656356336, -2.23944198187354],
              [-78.46620152490145, -2.240708990358712],
              [-78.46871961625577, -2.23918499272196],
              [-78.46942151682833, -2.237902896811022],
              [-78.46958949016812, -2.235373238430327],
              [-78.47055860581416, -2.233115321350795],
              [-78.47167155491884, -2.23387639815985],
              [-78.47651663023488, -2.233071400178119],
              [-78.47932456780127, -2.232217451881809],
              [-78.48030860323499, -2.232445439648416],
              [-78.48536657889188, -2.238961028268875],
              [-78.48401658956519, -2.240070959888385],
              [-78.48136153791286, -2.241430672222805],
              [-78.48043852273433, -2.240280842744141],
              [-78.47977450836414, -2.23850907604924],
              [-78.47985061604506, -2.237180209118549],
              [-78.47911854062103, -2.235850336359533],
              [-78.47654764327666, -2.238330876787586],
              [-78.47485349300527, -2.241174856537612],
              [-78.47435761961295, -2.242385706271477],
              [-78.47400658550762, -2.244083712218355],
              [-78.47392259883773, -2.245837541640412],
              [-78.47411353859225, -2.247057443829704],
              [-78.47405251833707, -2.248060254726568],
              [-78.47301450344686, -2.249252328997272],
              [-78.47171765538634, -2.249403203254587],
              [-78.47058861302756, -2.250486145145828],
              [-78.4690475163083, -2.252335863540338],
              [-78.46619465174084, -2.256596552566407],
              [-78.46516451583962, -2.257687708722813],
              [-78.46418752120458, -2.257324437038847],
              [-78.46518664406402, -2.253828848135299],
              [-78.46448558168177, -2.252516912649071],
              [-78.4634395201645, -2.25236184744017],
              [-78.46199062438025, -2.252648843805162],
              [-78.46066259563989, -2.25346004439524],
              [-78.46022757486469, -2.254570814205124],
              [-78.45919760660152, -2.254026828688495],
              [-78.45774049655215, -2.254800645990372],
              [-78.45708452880903, -2.253019994478166],
              [-78.45756565005175, -2.250137290335374],
              [-78.4559095536208, -2.249565141624089],
              [-78.45446049019849, -2.249454165225927],
              [-78.45326958939421, -2.249832021421412],
              [-78.4529725347454, -2.251204977162843],
              [-78.45153051212175, -2.252114916572395],
              [-78.44995152920009, -2.254145851713702],
              [-78.44979059665897, -2.256205788240038],
              [-78.44687654419828, -2.256571574494956],
              [-78.44584657593512, -2.257681506114466],
              [-78.4451066215221, -2.259234337498185],
              [-78.4448925477148, -2.262198178463791],
              [-78.4433516186336, -2.261716386668809],
              [-78.44210053576447, -2.260440493366275],
              [-78.44103251366086, -2.259019593138646],
              [-78.43986558509968, -2.258131614315403],
              [-78.43878951636901, -2.256050723031137],
              [-78.43779760194631, -2.255823573454848],
              [-78.4365994927053, -2.256001772716559],
              [-78.43566859853776, -2.256678527579538],
              [-78.43287658658743, -2.257875463354139],
              [-78.43140455675038, -2.25770044921569],
              [-78.42997762155247, -2.257011624412087],
              [-78.42890959944886, -2.255825752749729],
              [-78.42865763943917, -2.254803663475514],
              [-78.43235053834364, -2.254060859215429],
              [-78.43344152686198, -2.25285084767188],
              [-78.43383061480775, -2.251360880562061],
              [-78.43337262761781, -2.249741999781236],
              [-78.43328864094792, -2.246597277344961],
              [-78.43271649223662, -2.245683482259892],
              [-78.43253359910916, -2.243893443016077],
              [-78.43095361035913, -2.24423458647567],
              [-78.4298404936164, -2.245470414280987],
              [-78.42839059200375, -2.245214598595851],
              [-78.42597962137212, -2.246557379485807],
              [-78.42505660619358, -2.248878160839183],
              [-78.4237895977084, -2.248433417056276],
              [-78.42265351455094, -2.248991148894106],
              [-78.41986854339928, -2.246266359807294],
              [-78.4191745218157, -2.247494476261693],
              [-78.41876263509329, -2.249047307645412],
              [-78.41744265297999, -2.250056991702877],
              [-78.41583248937849, -2.250281123793968],
              [-78.41480252111532, -2.250008041388298],
              [-78.41340659895918, -2.248252367947487],
              [-78.41114063525262, -2.247087283405051],
              [-78.4113696288476, -2.249269260441679],
              [-78.41041560062727, -2.252403924594148],
              [-78.40953063928919, -2.253838906419162],
              [-78.40838650950468, -2.254776673747301],
              [-78.40730256178502, -2.256202770754896],
              [-78.40785962307058, -2.257550580786756],
              [-78.4091185849287, -2.256775590018435],
              [-78.40976751187314, -2.255294843002048],
              [-78.41116360166735, -2.254492694867452],
              [-78.4123075638138, -2.254087681305634],
              [-78.41339855233213, -2.25436998380485],
              [-78.41436750034012, -2.255311606808448],
              [-78.41584757680421, -2.256308550373035],
              [-78.41658049041855, -2.255342787488303],
              [-78.41815159435122, -2.254495879990657],
              [-78.41976963694172, -2.255221752806335],
              [-78.4213715862781, -2.255350666477284],
              [-78.4229656566255, -2.255688624813615],
              [-78.42386654357962, -2.256187850967194],
              [-78.42301963608199, -2.257251682119261],
              [-78.42143260653327, -2.257565500574401],
              [-78.42250850762588, -2.260115275522764],
              [-78.42191356013792, -2.261362335078388],
              [-78.42349958385826, -2.261907158785277],
              [-78.42671152915808, -2.260394392898775],
              [-78.42812354456834, -2.259945458164282],
              [-78.42935149338467, -2.261050193003825],
              [-78.42975650694649, -2.262442091846424],
              [-78.42897749286465, -2.264058960970488],
              [-78.42871061306728, -2.265096975860672],
              [-78.42935149338467, -2.265911864488146],
              [-78.43366264146795, -2.266009932755424],
              [-78.4346465092636, -2.266391979902494],
              [-78.43442556229569, -2.269219866398487],
              [-78.43571453136721, -2.26861670464541],
              [-78.43660753933236, -2.267958892883598],
              [-78.43826262993491, -2.268991710993816],
              [-78.4389806237616, -2.270312698935527],
              [-78.43903359738971, -2.273692449937073],
              [-78.43933065203852, -2.275383247447166],
              [-78.44004059923815, -2.276315147443086],
              [-78.44103955445952, -2.275648115587785],
              [-78.44224554268953, -2.275117205840161],
              [-78.44364951147273, -2.274803387384964],
              [-78.44483152745963, -2.274805231403661],
              [-78.44635753675317, -2.27454623059532],
              [-78.44796753271662, -2.273780459920488],
              [-78.45285753503371, -2.273943236480306],
              [-78.45380351662698, -2.275318203878498],
              [-78.45400250300855, -2.277839983270212],
              [-78.45487958535765, -2.278374916331359],
              [-78.4569246020963, -2.277484087661037],
              [-78.45787863031661, -2.276148347569745],
              [-78.45941955939782, -2.276521174623326],
              [-78.45936557994132, -2.277732024357192],
              [-78.45725250214882, -2.27865001039379],
              [-78.45616956025755, -2.279462049174185],
              [-78.45563462719639, -2.280752862264421],
              [-78.45565759361111, -2.282289768032115],
              [-78.45503263890977, -2.283417804562532],
              [-78.45407056406239, -2.28408567460815],
              [-78.45202655315214, -2.284090536111989],
              [-78.45059961795421, -2.283437585854017],
              [-78.4489825811921, -2.283253519260143],
              [-78.44732665239923, -2.283476645522853],
              [-78.44611362337054, -2.283239605300821],
              [-78.44452659382182, -2.282477690301505],
              [-78.44322957812325, -2.282176612339128],
              [-78.44155856190463, -2.281975614300791],
              [-78.44013950569571, -2.282135876289658],
              [-78.44041459975818, -2.283590639406157],
              [-78.44142964823368, -2.284686657066402],
              [-78.4401326325351, -2.285379505183528],
              [-78.43912462485828, -2.284908442224605],
              [-78.43836254222086, -2.286081405756079],
              [-78.43690459398117, -2.286169415739494],
              [-78.43715655399086, -2.287814448058271],
              [-78.43762158197946, -2.289053293348729],
              [-78.4377515014788, -2.29237889725573],
              [-78.43859857661451, -2.29317584861036],
              [-78.43897257713454, -2.291903140431032],
              [-78.44007161227992, -2.291388994489807],
              [-78.44184857575478, -2.291600050811951],
              [-78.44480856104491, -2.293114995993278],
              [-78.44512958793683, -2.294136917629373],
              [-78.44622057645516, -2.293750847168781],
              [-78.44720461188888, -2.292513845896963],
              [-78.44802050634473, -2.293302080072351],
              [-78.4471435916337, -2.294682914802706],
              [-78.44660161777387, -2.295964843075581],
              [-78.4469756182939, -2.296995481890974],
              [-78.44543452157464, -2.299867289559643],
              [-78.44458057327833, -2.300923241722671],
              [-78.4453046020753, -2.302045243282748],
              [-78.44683061136884, -2.302653266539664],
              [-78.44966151534994, -2.304302992724217],
              [-78.45024154305023, -2.305749876851678],
              [-78.45218664750293, -2.307850717065548],
              [-78.45429955765736, -2.309191653936807],
              [-78.45493356481414, -2.307809813378014],
              [-78.45497849181521, -2.306012062783282],
              [-78.45460549712355, -2.304889893585084],
              [-78.45295761495773, -2.301941140045244],
              [-78.45082056492213, -2.299461437807508],
              [-78.45028663768935, -2.29834865634092],
              [-78.4522855539605, -2.295071667472371],
              [-78.45159153237692, -2.293940948732939],
              [-78.4518126469829, -2.292774858362122],
              [-78.45275158777748, -2.292053847050283],
              [-78.45503263890977, -2.292075975274656],
              [-78.45597057387597, -2.29270210344248],
              [-78.4564585682793, -2.293686809428436],
              [-78.4577785503926, -2.294855749646388],
              [-78.45857265190018, -2.296257706772792],
              [-78.46244056494312, -2.298560383215317],
              [-78.46240955190135, -2.296978718084631],
              [-78.46093752206431, -2.294435816296868],
              [-78.4612655897549, -2.293053975738076],
              [-78.46217351750771, -2.292261047696968],
              [-78.4623106454438, -2.290878033671731],
              [-78.46356156067485, -2.290455082837127],
              [-78.46562954382823, -2.291065117750804],
              [-78.46614050464628, -2.288092224329773],
              [-78.46601058514693, -2.28701632323714],
              [-78.46485153557475, -2.287177423416324],
              [-78.4635696073019, -2.285846377190808],
              [-78.46214250446593, -2.285482602592651],
              [-78.46163959027494, -2.284541482503244],
              [-78.46323349298426, -2.283848466748054],
              [-78.46444652201293, -2.282955794059035],
              [-78.46583557100847, -2.281846700629842],
              [-78.46678959922879, -2.280700894464701],
              [-78.4683835019381, -2.280422782917071],
              [-78.46757565410931, -2.27725023256221],
              [-78.46858265595775, -2.276781013622042],
              [-78.469428557627, -2.27544527353075],
              [-78.46910048993641, -2.273782471577249],
              [-78.46993264528444, -2.27229233682931],
              [-78.47098557996232, -2.270938491827167],
              [-78.47108465405795, -2.26981783137154],
              [-78.47025249870991, -2.268975617739727],
              [-78.46918464424436, -2.269028759005892],
              [-78.4669265595268, -2.270605562632738],
              [-78.46576650412624, -2.269699646536708],
              [-78.46508756996839, -2.268840669098438],
              [-78.46796457441701, -2.267652953417382],
              [-78.46914659040392, -2.267536780239254],
              [-78.47048953893194, -2.267674914003749],
              [-78.46997857811388, -2.264320979263971],
              [-78.47014655145368, -2.263201324636782],
              [-78.47178655463051, -2.261595184348778],
              [-78.47313654395721, -2.260829246035939],
              [-78.47422049167686, -2.260587511948131],
              [-78.47714259076461, -2.261857370280381],
              [-78.47745557102947, -2.265066130457001],
              [-78.4784085934214, -2.266441936045453],
              [-78.4794236418969, -2.267437873781716],
              [-78.48087354350953, -2.268425932528942],
              [-78.48175850484762, -2.269718589637876],
              [-78.48292560104686, -2.27104058340791],
              [-78.48391751546957, -2.271747680760484],
              [-78.48464154426654, -2.269877510522235],
              [-78.48436762367051, -2.268313782664336],
              [-78.48465763752064, -2.266813924908774],
              [-78.48457365085075, -2.265114913133516],
              [-78.48380251575789, -2.263586221630931],
              [-78.48400854293813, -2.262068091326398],
              [-78.48474849735115, -2.260840477786189],
              [-78.48636653994164, -2.260843327633268],
              [-78.48802163054421, -2.261198217414062],
              [-78.4885255505636, -2.260151485344522],
              [-78.4905776081009, -2.2623782217442],
              [-78.49183656995903, -2.262832353258659],
              [-78.49325562616797, -2.266078999637671],
              [-78.49326350515695, -2.270588463550212],
              [-78.49411762109132, -2.271231523162442],
              [-78.49587262397984, -2.269689755890909],
              [-78.4962695909146, -2.270693572616153],
              [-78.49878650880245, -2.271466384089649],
              [-78.49991655698962, -2.272136433430092],
              [-78.50144156045478, -2.272591570772988],
              [-78.50344064436399, -2.274673300247571],
              [-78.50478359289201, -2.274594175081518],
              [-78.50545464806086, -2.273701334754435],
              [-78.50489054597662, -2.271566631651694],
              [-78.50534853316655, -2.270447479938696],
              [-78.50402855105327, -2.269586658481785],
              [-78.50283865607737, -2.269249873611841],
              [-78.50287654227975, -2.264954148230458],
              [-78.5051656400391, -2.265286071596506],
              [-78.50472257263684, -2.267137801647777],
              [-78.50610357500531, -2.268577812614637],
              [-78.50729363761927, -2.269393539432428],
              [-78.50810249127645, -2.270180767779379],
              [-78.5085215864356, -2.271094562864448],
              [-78.5075915304584, -2.273343427488498],
              [-78.5075075437885, -2.27484345288218],
              [-78.50763662509752, -2.276470045013923],
              [-78.50849157922221, -2.277799917772995],
              [-78.50957452111349, -2.278091105089572],
              [-78.51099357732241, -2.276820073290821],
              [-78.51056660317425, -2.275725229097077],
              [-78.51185657807416, -2.274634240578735],
              [-78.51319164761318, -2.273913396904959],
              [-78.51432756313258, -2.273002284028962],
              [-78.5135495548791, -2.272151353217794],
              [-78.51309961431623, -2.270433565979431],
              [-78.51300054022062, -2.268833628299774],
              [-78.51252763324301, -2.267757727207197],
              [-78.51516759746961, -2.265792003272622],
              [-78.5155335513626, -2.264146970953902],
              [-78.51751754784607, -2.261647152148555],
              [-78.51851650306745, -2.260799238822528],
              [-78.51963749879917, -2.263820244367821],
              [-78.52072865495556, -2.264544943717056],
              [-78.52050754034958, -2.266144043206339],
              [-78.5199585256911, -2.267498894036862],
              [-78.51895856464132, -2.26868979484118],
              [-78.51918051743762, -2.270543536549155],
              [-78.51917263844862, -2.273887245367007],
              [-78.51876058408814, -2.275342176121569],
              [-78.51787562275005, -2.276569286747588],
              [-78.51685353347588, -2.27957805471425],
              [-78.51722753399592, -2.280870711823184],
              [-78.51719652095414, -2.283753751242102],
              [-78.51869956383297, -2.283999676281496],
              [-78.52019456008473, -2.283514699363309],
              [-78.52184260988862, -2.284014763707205],
              [-78.52301758507684, -2.284595629597845],
              [-78.52631351704656, -2.284763435299567],
              [-78.52790054659528, -2.285191582914138],
              [-78.5293735822607, -2.285012545462166],
              [-78.53030363823791, -2.285629621174508],
              [-78.53015863131284, -2.286830412624568],
              [-78.53079951163024, -2.28835927176516],
              [-78.53063958491751, -2.2901122629969],
              [-78.5315706467231, -2.29163190204406],
              [-78.53315750863375, -2.292068934475992],
              [-78.53340158965445, -2.293587902970899],
              [-78.53433265146005, -2.294926828185339],
              [-78.53491251152226, -2.296383770596663],
              [-78.53524762001153, -2.297793439074042],
              [-78.5370715221442, -2.300427535968424],
              [-78.53737662342007, -2.301928399552423],
              [-78.53820056450299, -2.303447200409209],
              [-78.53861949202408, -2.305119893008452],
              [-78.53655955549776, -2.304629048758045],
              [-78.53529355284097, -2.303497156552169],
              [-78.5344315579176, -2.302085308779965],
              [-78.53339354302739, -2.301080318588333],
              [-78.5308075582573, -2.299792355345176],
              [-78.5294035894741, -2.29889549170457],
              [-78.5260545162382, -2.296060732047977],
              [-78.52444452027477, -2.295280879775817],
              [-78.52323953787314, -2.295350784848381],
              [-78.52125554138966, -2.296684513282912],
              [-78.5213546154853, -2.299007473931113],
              [-78.52207965011064, -2.300075496034708],
              [-78.52293359840695, -2.300881332206757],
              [-78.52371965328747, -2.302193267692985],
              [-78.52408560718045, -2.303577287546602],
              [-78.52400949949956, -2.304669952445579],
              [-78.52301758507684, -2.306258826012993],
              [-78.5215755624532, -2.305108996534329],
              [-78.52080560082679, -2.3036252320328],
              [-78.51946265229877, -2.302520329555193],
              [-78.51841759660988, -2.30121744652439],
              [-78.51713550069897, -2.300229555415228],
              [-78.51686057427456, -2.298963385120373],
              [-78.51605959960636, -2.297471741629863],
              [-78.51524353751245, -2.296366839152256],
              [-78.51410661616467, -2.295370901415993],
              [-78.51326758765603, -2.293787727542735],
              [-78.51161953785214, -2.293676918782694],
              [-78.5106425432171, -2.294234818258531],
              [-78.50789663173427, -2.291944044118566],
              [-78.50701854355678, -2.290577123347475],
              [-78.50528751291138, -2.288866209269713],
              [-78.50405151746799, -2.288548367500994],
              [-78.5029145961202, -2.28849120292125],
              [-78.50090763322201, -2.286897300211933],
              [-78.49890150851414, -2.289623095127126],
              [-78.49861954129105, -2.291358149086079],
              [-78.49853555462116, -2.295948749821491],
              [-78.49882556847129, -2.297684641970704],
              [-78.49938162392847, -2.299348617390649],
              [-78.4987715890148, -2.300802374678767],
              [-78.49730659997643, -2.30097219203725],
              [-78.49748949310388, -2.302527370353857],
              [-78.49845860874993, -2.303929159842255],
              [-78.4988785420994, -2.305304127240447],
              [-78.49784052720919, -2.308483718393973],
              [-78.50125849968926, -2.308561837731645],
              [-78.5035175902352, -2.311105745347731],
              [-78.50429559848868, -2.312317600909978],
              [-78.50439450494625, -2.313782254672219],
              [-78.5039596518091, -2.314838206835248],
              [-78.50502063311403, -2.315473219820376],
              [-78.50672165654603, -2.315548154034843],
              [-78.50811757870218, -2.315415217050372],
              [-78.50959765516627, -2.315788211742017],
              [-78.51134461142773, -2.31587320424029],
              [-78.51287062072127, -2.31643630049615],
              [-78.5144345162172, -2.31566130972783],
              [-78.51606764623342, -2.315420413830338],
              [-78.51781460249488, -2.316300346026537],
              [-78.52041651288103, -2.318221142959999],
              [-78.52199549580268, -2.318567147923375],
              [-78.52321657145842, -2.319445906653129],
              [-78.52619164417428, -2.320381997600634],
              [-78.52706151808664, -2.321323955880359],
              [-78.52766451220164, -2.322770672369813],
              [-78.52844252045512, -2.324100712766892],
              [-78.52903763558112, -2.325647509180328],
              [-78.52858752738018, -2.326830530995665],
              [-78.52472665513591, -2.326534314537128],
              [-78.52320852483136, -2.326585444146588],
              [-78.52205651605786, -2.325716408424512],
              [-78.52053855339138, -2.32602050387203],
              [-78.51922661790513, -2.326687368089267],
              [-78.51802850866412, -2.326359300398678],
              [-78.51650249937057, -2.326682338947364],
              [-78.5147625839078, -2.326028382861011],
              [-78.51338158153932, -2.325827384822674],
              [-78.5139616092396, -2.327310478771892],
              [-78.51705956065614, -2.329982294230604],
              [-78.51840250918416, -2.3306530141233],
              [-78.51956155875634, -2.330854179799701],
              [-78.51989750543592, -2.332328053655488],
              [-78.52206456268492, -2.335721886254362],
              [-78.52442155386004, -2.33633980015702],
              [-78.52615359033383, -2.336333765186737],
              [-78.52748161907418, -2.336742634424013],
              [-78.5285565143384, -2.337901683996222],
              [-78.52841955404038, -2.339329625022515],
              [-78.52744255940534, -2.340367304636572],
              [-78.52660353089671, -2.341559211269271],
              [-78.5255276298041, -2.342777269439807],
              [-78.52311649153441, -2.344507964809111],
              [-78.52323953787314, -2.347562833243273],
              [-78.52198057601501, -2.348211927825787],
              [-78.52079755419973, -2.347178942077505],
              [-78.5180055422494, -2.346117122582257],
              [-78.51638749965889, -2.346032130083927],
              [-78.5151515042155, -2.344982212891239],
              [-78.51377854847408, -2.344554065276611],
              [-78.51243559994606, -2.343865072834944],
              [-78.51213049867019, -2.342473341630409],
              [-78.5120165047869, -2.340936435862716],
              [-78.51160461806447, -2.339489384097192],
              [-78.51075754292877, -2.338231595705508],
              [-78.50787349768149, -2.336662838705706],
              [-78.50663750223808, -2.333867809270259],
              [-78.50554651371975, -2.335113695359382],
              [-78.50483656652013, -2.336603830107322],
              [-78.50337962410882, -2.337622566620212],
              [-78.50194564811223, -2.338179627905731],
              [-78.50165563426208, -2.339553589475543],
              [-78.5028684956527, -2.342393210635976],
              [-78.50576762832573, -2.343067283289997],
              [-78.50812562532923, -2.345150186231024],
              [-78.50967359520911, -2.345694842299849],
              [-78.51045260929097, -2.34686177086104],
              [-78.51174157836249, -2.349883782234713],
              [-78.5120165047869, -2.351555469005575],
              [-78.5127256137962, -2.352994474144111],
              [-78.51284765430654, -2.354621401551981],
              [-78.51252763324301, -2.35595831510966],
              [-78.51191759832933, -2.35753914205003],
              [-78.51089450322678, -2.358992061147831],
              [-78.51087153681206, -2.360527961087143],
              [-78.51111561783276, -2.362083809956005],
              [-78.51079559676923, -2.363664804534437],
              [-78.5100635213452, -2.364910522985497],
              [-78.50899449341321, -2.365875447679969],
              [-78.5070346368109, -2.368384318940798],
              [-78.50589754782506, -2.369349243635213],
              [-78.50312062330045, -2.371152023371906],
              [-78.50212049461263, -2.372442165909888],
              [-78.50083957216816, -2.373569867164122],
              [-78.49991655698962, -2.374083007277022],
              [-78.49806952080417, -2.374342008085364],
              [-78.49639850458554, -2.373688051999011],
              [-78.49555159708791, -2.37254811316609],
              [-78.494872495292, -2.369826341564419],
              [-78.49539954936417, -2.364413643764806],
              [-78.49501750221708, -2.362099735572031],
              [-78.49323249211517, -2.360289076846414],
              [-78.49037962754772, -2.358395102003101],
              [-78.48590855275171, -2.356714027500686],
              [-78.48320052747127, -2.356104160225073],
              [-78.4807504971708, -2.35599117217015],
              [-78.4794236418969, -2.356332315629743],
              [-78.47847749266558, -2.357541153706791],
              [-78.47839350599568, -2.358987032005928],
              [-78.4788746272384, -2.367465494713827],
              [-78.47972857553471, -2.369609250271992],
              [-78.48078151021258, -2.371399960068061],
              [-78.4824525264312, -2.372813148944772],
              [-78.48407760982036, -2.374623807670389],
              [-78.48444356371334, -2.376115618798963],
              [-78.48181164611381, -2.377095630919143],
              [-78.47988850224743, -2.377309704726429],
              [-78.47780660513479, -2.377865592545561],
              [-78.47686749670214, -2.379581535765226],
              [-78.4769216437967, -2.381127326350281],
              [-78.47598253536405, -2.382209430051262],
              [-78.4747845937611, -2.382271120858661],
              [-78.47253455567059, -2.381751442861287],
              [-78.4682465416401, -2.38028846547968],
              [-78.46585049079613, -2.379091362067072],
              [-78.46400462807712, -2.378599679626348],
              [-78.4623414908475, -2.379545493581531],
              [-78.46070064948034, -2.380889280299868],
              [-78.45919760660152, -2.381383477311545],
              [-78.45777150959393, -2.379256653197842],
              [-78.45820653036914, -2.376645857994333],
              [-78.45890055195271, -2.374604864569221],
              [-78.45884657249621, -2.371216228750257],
              [-78.4580226314133, -2.369587289685626],
              [-78.45600158691775, -2.367956338964234],
              [-78.45400954380722, -2.366850430658246],
              [-78.45204935192879, -2.366313318302275],
              [-78.44971465661612, -2.36637232690066],
              [-78.44782252579154, -2.367273381492851],
              [-78.44561758234016, -2.368760331117585],
              [-78.44344365193055, -2.369668091232313],
              [-78.44099462745847, -2.369619140917735],
              [-78.43628651244043, -2.368923107677404],
              [-78.43423462254118, -2.368802408271563],
              [-78.43231952530186, -2.369349243635213],
              [-78.43164059114403, -2.371626103815913],
              [-78.43218960580252, -2.373497782796733],
              [-78.43358653378705, -2.375271896424522],
              [-78.43468456310406, -2.377803734100041],
              [-78.43429564279636, -2.38028846547968],
              [-78.43299862709777, -2.382771185202557],
              [-78.4329376068426, -2.384904211924663],
              [-78.43424250153016, -2.387138994951385],
              [-78.43653864008819, -2.388570791653137],
              [-78.43863663045495, -2.389505876772262],
              [-78.44017051873749, -2.390520589971629],
              [-78.44183365596712, -2.391933611210277],
              [-78.44279455734804, -2.394131346224924],
              [-78.44204756213635, -2.396118360193498],
              [-78.43753860113794, -2.397086134735048],
              [-78.43825558913625, -2.401190920361955],
              [-78.43646957320595, -2.40197395775732],
              [-78.43488354948562, -2.401012888738364],
              [-78.43388358843586, -2.399221173113915],
              [-78.43315855381051, -2.397250252399374],
              [-78.43202951145173, -2.39517824593247],
              [-78.43039654907356, -2.393358534751428],
              [-78.42868764665256, -2.392116504337707],
              [-78.4266436357423, -2.391127775038228],
              [-78.42468260567355, -2.390654532784481],
              [-78.42280556227469, -2.390741536939515],
              [-78.42205052043595, -2.392737435725508],
              [-78.42185253988275, -2.397806978408823],
              [-78.4211575124708, -2.39979382473939],
              [-78.41897553543413, -2.40288875867077],
              [-78.4167865175988, -2.402414845864769],
              [-78.41505464876307, -2.400839048066302],
              [-78.41428351367021, -2.399093935823544],
              [-78.41452759469091, -2.397070209119022],
              [-78.41253655740877, -2.397165259901101],
              [-78.40995761343734, -2.400277795829197],
              [-78.40846261718558, -2.400113007612617],
              [-78.40828659721873, -2.397663144950172],
              [-78.40847753697324, -2.395702282519494],
              [-78.40861550309964, -2.39051572846779],
              [-78.4084316041438, -2.388002833893438],
              [-78.40785962307058, -2.385670150237559],
              [-78.40679964759403, -2.383879272803426],
              [-78.40462454371797, -2.383649105741995],
              [-78.4028246138284, -2.384196108743708],
              [-78.3954616147961, -2.389550803773318],
              [-78.39328751674843, -2.391498758073112],
              [-78.39096857941375, -2.391900418873661],
              [-78.3885646495808, -2.391616439993811],
              [-78.38433849399581, -2.392574491527625],
              [-78.38233957772466, -2.392381372478326],
              [-78.38033261482647, -2.391103635157037],
              [-78.37970749248706, -2.388833648136938],
              [-78.37936349918041, -2.386492917854014],
              [-78.37847149704366, -2.384638002679537],
              [-78.37643452693207, -2.383767290576884],
              [-78.37436654377869, -2.383465206786127],
              [-78.37248950037984, -2.382404225481196],
              [-78.37053651693815, -2.380836474309831],
              [-78.36824758681686, -2.379512468882979],
              [-78.3659895020993, -2.378875444241089],
              [-78.36359361889339, -2.378699424274203],
              [-78.36357149066899, -2.380597254792974],
              [-78.36475350665589, -2.382551244063052],
              [-78.36827859985864, -2.386868930030801],
              [-78.37253560084734, -2.388901709190804],
              [-78.37403059709911, -2.390322609418433],
              [-78.37481665197963, -2.392403500702699],
              [-78.3731766488028, -2.393755501686144],
              [-78.36849954682654, -2.393638490317699],
              [-78.3634496177967, -2.393881565510014],
              [-78.36124450670725, -2.39422036203672],
              [-78.35953560428625, -2.39533716681683],
              [-78.35846758218264, -2.396826128098326],
              [-78.35797858195093, -2.400647773035701],
              [-78.35733049319681, -2.402463628541284],
              [-78.35574363128616, -2.403860556525842],
              [-78.35350063399432, -2.404543513997169],
              [-78.35303460017732, -2.406575454966855],
              [-78.35329460681407, -2.408401368756302],
              [-78.35488850952339, -2.412868923152985],
              [-78.35635349856176, -2.419405634169493],
              [-78.35707853318712, -2.421214448876412],
              [-78.35823858858768, -2.422825115392129],
              [-78.35949654461741, -2.424138056706738],
              [-78.3611445944213, -2.425206917000651],
              [-78.36316664474523, -2.425680997444715],
              [-78.36521149384582, -2.4255861143007],
              [-78.36740151750955, -2.426105121745763],
              [-78.36840064036899, -2.427670861260424],
              [-78.36679852339454, -2.429989798595102],
              [-78.36382261248836, -2.433120439434049],
              [-78.36194657491788, -2.434245458479268],
              [-78.36099958749624, -2.435789405045625],
              [-78.36012250514715, -2.439853957537252],
              [-78.3584595355556, -2.440745792035955],
              [-78.35643765286973, -2.440271879229954],
              [-78.35473662943772, -2.439319024476106],
              [-78.35125763670248, -2.436954154311991],
              [-78.34809849739271, -2.434182258929297],
              [-78.3452915656547, -2.431447746834806],
              [-78.3408204908587, -2.427579833791867],
              [-78.33591456292554, -2.422880268315055],
              [-78.3329545776354, -2.419576457356357],
              [-78.3314205217148, -2.418443726960163],
              [-78.32921557826343, -2.417689523311765],
              [-78.325538604975, -2.415856736361661],
              [-78.32321162101327, -2.414938750325064],
              [-78.32127355735923, -2.414799946008316],
              [-78.31948050063028, -2.415464798568848],
              [-78.31791660513434, -2.41715157276542],
              [-78.31681857581734, -2.419218382452357],
              [-78.31662763606282, -2.421062401152653],
              [-78.31717665072132, -2.422933241943156],
              [-78.31735250305012, -2.425283192319625],
              [-78.3176805707407, -2.427218070850472],
              [-78.3189465733975, -2.428656908350888],
              [-78.32099159013615, -2.429646643478748],
              [-78.32753752124614, -2.430761604240217],
              [-78.32950559211356, -2.431352528414607],
              [-78.33138263551243, -2.432468495004457],
              [-78.33377063972934, -2.4335034924095],
              [-78.33594557596732, -2.43483621501565],
              [-78.33750159247425, -2.436312268166205],
              [-78.33657053066867, -2.438134996832446],
              [-78.33435049979155, -2.438249158353813],
              [-78.3298795926336, -2.438980898501711],
              [-78.32534749758241, -2.441555819159589],
              [-78.32572954472951, -2.443436718233897],
              [-78.32748454761803, -2.444442714253967],
              [-78.32949050468784, -2.44463667149364],
              [-78.33113855449173, -2.445706705253997],
              [-78.33207648945793, -2.447380571319684],
              [-78.33365664584603, -2.449253423766947],
              [-78.33607448963828, -2.450976072509206],
              [-78.3395305159588, -2.452880105636268],
              [-78.34142264678339, -2.454339059704353],
              [-78.34294161527825, -2.45610378560059],
              [-78.34526055261293, -2.459262589634193],
              [-78.34747354269138, -2.463523278660318],
              [-78.34934958026184, -2.464638071783668],
              [-78.35134162337238, -2.46459700045807],
              [-78.3532635937723, -2.465422953197788],
              [-78.35474350259832, -2.464315033234982],
              [-78.35397354097192, -2.462578135257388],
              [-78.35268356607202, -2.460741492631882],
              [-78.3483965578699, -2.455834726508385],
              [-78.34739659682015, -2.454160860442698],
              [-78.34687054857636, -2.45247894774991],
              [-78.3491596463357, -2.451779226472183],
              [-78.3511505159798, -2.451737987508523],
              [-78.35343961373914, -2.452021966388372],
              [-78.3571395534423, -2.454198746645091],
              [-78.36096153365578, -2.455452846999378],
              [-78.36370861860507, -2.457961550622088],
              [-78.36637859004506, -2.457831631122758],
              [-78.36810257989178, -2.457057813820882],
              [-78.36983461636558, -2.45524480816232],
              [-78.37173462617916, -2.454407958948536],
              [-78.37367252219512, -2.455686869736212],
              [-78.37360362295095, -2.459888550163953],
              [-78.37412263039606, -2.462338245188278],
              [-78.3752976055843, -2.464121075995365],
              [-78.37753255624907, -2.464523239710047],
              [-78.37914255221251, -2.463586310572225],
              [-78.38081356843112, -2.462930342829111],
              [-78.38525363018535, -2.461737430368089],
              [-78.38737458696684, -2.460765464874953],
              [-78.3891676436958, -2.460280487956766],
              [-78.39133453330673, -2.461378349635709],
              [-78.39080865270103, -2.463419175422757],
              [-78.3880696143788, -2.466713095735713],
              [-78.3853535424713, -2.469255829885412],
              [-78.38230152388421, -2.474292347870175],
              [-78.3814236033448, -2.476179114276704],
              [-78.3775176364614, -2.483382689510449],
              [-78.37704456184575, -2.485297451473627],
              [-78.37638859410262, -2.487058489332412],
              [-78.3744426514596, -2.491347006277124],
              [-78.3727416280276, -2.493784128446691],
              [-78.37094153049996, -2.497666793639212],
              [-78.3704905841087, -2.499987407354524],
              [-78.3712315443501, -2.502311206193042],
              [-78.3731305483353, -2.503825145545989],
              [-78.37545049149836, -2.504516149644417],
              [-78.37791460339616, -2.504919151549473],
              [-78.3821945707996, -2.50613788027232],
              [-78.38402551373095, -2.506287078149001],
              [-78.38707753231803, -2.505904025173493],
              [-78.38886254241993, -2.505185025518472],
              [-78.39080865270103, -2.504113315377424],
              [-78.39482861346771, -2.501500508517154],
              [-78.39657556972915, -2.499859499511956],
              [-78.39884153343571, -2.498544546540586],
              [-78.40106961093989, -2.498612607594396],
              [-78.40309853442443, -2.499366475966724],
              [-78.40499150343933, -2.499623465118304],
              [-78.4067005734984, -2.500692493050281],
              [-78.40638759323353, -2.502545228929932],
              [-78.4021376330435, -2.504073249880207],
              [-78.4004895832396, -2.505307233666883],
              [-78.39961954168919, -2.507302964814812],
              [-78.39618664942145, -2.510287760538347],
              [-78.39512650630684, -2.512978519098226],
              [-78.39647649563354, -2.514987493653223],
              [-78.39653751588871, -2.517283129297027],
              [-78.39860549904208, -2.521027828363174],
              [-78.3981855656926, -2.522952648610158],
              [-78.39609562195288, -2.523164710760739],
              [-78.39435553885204, -2.522618881225412],
              [-78.39263959563237, -2.521377856640129],
              [-78.39014463833084, -2.518110087865068],
              [-78.38825250750625, -2.516769150993809],
              [-78.38626851102278, -2.51588838060735],
              [-78.38404864778373, -2.515884189655765],
              [-78.38224050362903, -2.516196331730271],
              [-78.37583957944412, -2.51910300811619],
              [-78.37409949634328, -2.519704996402822],
              [-78.37168165255103, -2.520278150942488],
              [-78.36889651376131, -2.520353923347272],
              [-78.36691251727783, -2.520630023238084],
              [-78.36266356291618, -2.522211856006834],
              [-78.35634662540116, -2.523445672155447],
              [-78.35541556359556, -2.525269574288075],
              [-78.35686462701788, -2.526862471169068],
              [-78.3588945563308, -2.527571580178346],
              [-78.35961959095616, -2.52955239153863],
              [-78.35837554888569, -2.530869356166761],
              [-78.3518596249891, -2.531651220095682],
              [-78.35058557570524, -2.533555085584737],
              [-78.35110458315036, -2.535887769240617],
              [-78.35260762602917, -2.537481839588054],
              [-78.35467560918255, -2.537720723828727],
              [-78.35611763180619, -2.53931362070972],
              [-78.35523250283003, -2.540911546732559],
              [-78.352546605774, -2.541846296575557],
              [-78.3505475218648, -2.541778403159753],
              [-78.34822858453012, -2.541023528959101],
              [-78.3466876554489, -2.539836651468306],
              [-78.3455356466754, -2.538397478691763],
              [-78.34393352970096, -2.537038604547718],
              [-78.34192656680275, -2.53794753812889],
              [-78.3414995926546, -2.539744618171369],
              [-78.34159849911217, -2.541633228596652],
              [-78.34072158440114, -2.543466183184762],
              [-78.33873758791766, -2.543733398258212],
              [-78.33784457995252, -2.541761304077284],
              [-78.33652459783922, -2.538152727118813],
              [-78.3349375682905, -2.5371916580998],
              [-78.33302263868926, -2.537630702188551],
              [-78.3316646027355, -2.538901733987245],
              [-78.33084049401452, -2.540725300843803],
              [-78.33070353371652, -2.547050955538168],
              [-78.3301315526433, -2.549263945616588],
              [-78.32890360382694, -2.550932614902308],
              [-78.32695062038525, -2.551660499374748],
              [-78.32489755701954, -2.552795576703829],
              [-78.32302051362069, -2.552890459847845],
              [-78.32168561171972, -2.551234531055002],
              [-78.3205106365315, -2.549279871232613],
              [-78.31896249901355, -2.548029961829968],
              [-78.31690960328591, -2.547899036502258],
              [-78.31492661263081, -2.548228948211545],
              [-78.31307253564668, -2.548667824662175],
              [-78.30904352242456, -2.550186793157081],
              [-78.30693849125912, -2.550172711559696],
              [-78.30540460297658, -2.54903998116356],
              [-78.30266556465436, -2.545555121096072],
              [-78.30058249407526, -2.546175214293555],
              [-78.30006449245853, -2.548260799443597],
              [-78.29941556551408, -2.55007581675892],
              [-78.29756165616801, -2.550631704578052],
              [-78.29615064658614, -2.551957721661609],
              [-78.29676856048881, -2.554055376752274],
              [-78.29798158951749, -2.555494381890753],
              [-78.29972854577895, -2.556266355173932],
              [-78.30469549396727, -2.55654832239702],
              [-78.30667865226043, -2.557483072240018],
              [-78.30876155520147, -2.559275961330911],
              [-78.30971558342179, -2.561240008884795],
              [-78.30944048935932, -2.563669754979571],
              [-78.30856357464829, -2.565493489474193],
              [-78.30744157308817, -2.567044476839214],
              [-78.30586259016651, -2.568496557746698],
              [-78.30476355502113, -2.570337223685726],
              [-78.30492364937193, -2.572516183237269],
              [-78.30791464770384, -2.575350775255743],
              [-78.30760954642797, -2.577147855298222],
              [-78.30596954325114, -2.580741512469046],
              [-78.3056255499445, -2.583053409005004],
              [-78.30617557043138, -2.584925255623887],
              [-78.30760954642797, -2.586175165026589],
              [-78.30810558745836, -2.588164861204234],
              [-78.30961650932616, -2.589983734195016],
              [-78.31184358100195, -2.589988763336919],
              [-78.31243165532929, -2.588119766565114],
              [-78.31060054475988, -2.584474309232633],
              [-78.31038663859064, -2.582594248348641],
              [-78.31030265192075, -2.577533758120808],
              [-78.3107836055254, -2.573431989979042],
              [-78.3112025330465, -2.571634239384309],
              [-78.31282761643567, -2.570002282834537],
              [-78.31449863265428, -2.56922846553266],
              [-78.31613964165949, -2.567822317454613],
              [-78.31690256248723, -2.565953655958936],
              [-78.3191145467373, -2.565723656535624],
              [-78.32147958453947, -2.566415498824369],
              [-78.32335662793834, -2.566319609851917],
              [-78.32547758471982, -2.566567378910008],
              [-78.32761362892704, -2.567205577018399],
              [-78.33130652783152, -2.567032574536711],
              [-78.33342765225107, -2.566060609043575],
              [-78.3376315119736, -2.561315781289579],
              [-78.34101863904995, -2.558450008591251],
              [-78.34256761475821, -2.557513247091492],
              [-78.34496349796412, -2.557626235146415],
              [-78.34700063571377, -2.558498120715512],
              [-78.35170757726536, -2.562583795603132],
              [-78.35363759429234, -2.562433759536191],
              [-78.35559057773402, -2.561642843151787],
              [-78.3571856539098, -2.560471053086815],
              [-78.35871853636395, -2.559254000744602],
              [-78.35964959816955, -2.557368240166397],
              [-78.36148858772795, -2.556585370409096],
              [-78.36349454479776, -2.556951324302077],
              [-78.36493656742141, -2.558427042176504],
              [-78.36622654232131, -2.560147176347812],
              [-78.36817952576301, -2.56056593623083],
              [-78.37034557718363, -2.560526038371677],
              [-78.37219965416776, -2.561233806276448],
              [-78.37330656830214, -2.56279082861181],
              [-78.3738636295877, -2.564780524789398],
              [-78.37538963888124, -2.565795573264893],
              [-78.37718956877082, -2.565356696814263],
              [-78.37907449115866, -2.56423083957867],
              [-78.38081356843112, -2.563691883204001],
              [-78.38263663237348, -2.563767655608785],
              [-78.38434553479448, -2.564837689369142],
              [-78.38615451713949, -2.565672694564228],
              [-78.39339463747112, -2.571580092289764],
              [-78.39524854681719, -2.572180236557642],
              [-78.39719365126989, -2.57116334406345],
              [-78.3975525643642, -2.569248414462209],
              [-78.39694957024919, -2.567493411573707],
              [-78.3942034911283, -2.561541925037488],
              [-78.39375254473704, -2.559381070396853],
              [-78.39330260417417, -2.552620730203444],
              [-78.39481352604197, -2.551050799737197],
              [-78.40181761197996, -2.552086635332557],
              [-78.40390755571967, -2.55175571779489],
              [-78.40507549010923, -2.550032901414625],
              [-78.40515159779014, -2.54583021515856],
              [-78.40685262122214, -2.544650043190359],
              [-78.40869161078055, -2.544852047057077],
              [-78.41093460807238, -2.545372060330521],
              [-78.4178545397024, -2.545837926509478],
              [-78.42229460145664, -2.546886167321588],
              [-78.42455251853615, -2.547757885252622],
              [-78.426429561935, -2.548810987568572],
              [-78.42849754508838, -2.549058756626664],
              [-78.42804760452549, -2.547005860899048],
              [-78.42723053660319, -2.545378262938925],
              [-78.42489651184279, -2.541993482795419],
              [-78.42578164081894, -2.540331519032293],
              [-78.42768852379312, -2.540878354395943],
              [-78.42962658744716, -2.540954462076854],
              [-78.43091555651867, -2.539456448339934],
              [-78.43155660447412, -2.537469602009367],
              [-78.43346365508638, -2.536858896543436],
              [-78.43731664834165, -2.536441813041051],
              [-78.44624354286988, -2.533757927641773],
              [-78.4481355060564, -2.532974890246408],
              [-78.449561603064, -2.53181198499874],
              [-78.4506074969432, -2.529925386230275],
              [-78.4510195513037, -2.527946251250626],
              [-78.45057665153949, -2.523716742904355],
              [-78.44968464940273, -2.518418206626109],
              [-78.44973762303086, -2.516060377260658],
              [-78.45064555078366, -2.513712438540949],
              [-78.45417751714702, -2.511586787893691],
              [-78.45471161201785, -2.509716617655442],
              [-78.45631456718262, -2.508662006596921],
              [-78.45743556291434, -2.507226857133901],
              [-78.45844256476278, -2.505683916395924],
              [-78.45805364445508, -2.503632194134752],
              [-78.4566805210756, -2.502436264188532],
              [-78.45446049019849, -2.502377255590147],
              [-78.45264463469286, -2.502573224486582],
              [-78.45034749030646, -2.50217039021959],
              [-78.44767751886647, -2.501153497725397],
              [-78.44149050085079, -2.499387430724653],
              [-78.44026959283312, -2.498138694788452],
              [-78.43708061394801, -2.496116644464507],
              [-78.43327355352218, -2.492314110266364],
              [-78.43151050400662, -2.490159290596068],
              [-78.43176263165438, -2.488252407621871],
              [-78.43611149830197, -2.488731349569719],
              [-78.43600454521736, -2.486553395846613],
              [-78.435279510592, -2.48457258448633],
              [-78.43196061220756, -2.479794061481584],
              [-78.42940564047925, -2.474340459994437],
              [-78.43058061566747, -2.472679502059634],
              [-78.43254851889684, -2.473388611068913],
              [-78.43403664198799, -2.47462946801619],
              [-78.43618760598288, -2.475754319423402],
              [-78.43911758405962, -2.476961145843688],
              [-78.44044460697158, -2.477100285436563],
              [-78.44400054557805, -2.477938140478727],
              [-78.44589250876457, -2.476983106430055],
              [-78.44747149168623, -2.475640325540098],
              [-78.4493175220433, -2.471585663694214],
              [-78.45134761899429, -2.47108375533162],
              [-78.45294956833067, -2.472442461837602],
              [-78.45504755869744, -2.473377379318663],
              [-78.45808448985879, -2.475986162865411],
              [-78.46195961133847, -2.478325049129694],
              [-78.46609457181683, -2.481170872898474],
              [-78.46767456056688, -2.482068910005523],
              [-78.46997053148684, -2.48241692662566],
              [-78.4741515924327, -2.482840715650639],
              [-78.47581456202425, -2.481839748772472],
              [-78.47789763260336, -2.478057163503934],
              [-78.47986553583272, -2.478594108221841],
              [-78.48118551794602, -2.480025066733276],
              [-78.48212462637866, -2.481644785704418],
              [-78.48387962926718, -2.485868594356532],
              [-78.48443551708628, -2.487912269990659],
              [-78.48471061114877, -2.490027359439921],
              [-78.48710649435466, -2.501347454965014],
              [-78.48788450260814, -2.503210416766535],
              [-78.48893760492408, -2.504939268117141],
              [-78.49008961369758, -2.506377937979494],
              [-78.49228651052191, -2.508108800986861],
              [-78.49547548940701, -2.511512524231478],
              [-78.49722261330652, -2.511959614947273],
              [-78.50399049721281, -2.512957396702234],
              [-78.50478359289201, -2.51370858286549],
              [-78.50581356115516, -2.513954340266821],
              [-78.50753051020322, -2.512439562723557],
              [-78.50857556589212, -2.511194682462758],
              [-78.50962850056999, -2.510627562893433],
              [-78.50933060773086, -2.509550823610482],
              [-78.50713354326847, -2.509203812818726],
              [-78.50534853316655, -2.509208841960628],
              [-78.50377659104358, -2.509404810857006],
              [-78.49748261994327, -2.507775033602059],
              [-78.49442255472913, -2.503838221314993],
              [-78.4917374958634, -2.50113053131065],
              [-78.4959636514484, -2.500108609674555],
              [-78.49990851036257, -2.499844412086247],
              [-78.50209065503729, -2.500400467543386],
              [-78.50519564725249, -2.500433492241939],
              [-78.50820961199913, -2.501541412204688],
              [-78.50969656162384, -2.501309233486552],
              [-78.51039862983447, -2.49994549783861],
              [-78.5101396290261, -2.498463744993899],
              [-78.51155851759697, -2.498167528535362],
              [-78.51182556503238, -2.496947793984134],
              [-78.51088763006618, -2.495644910953331],
              [-78.50757560484234, -2.494499942978507],
              [-78.50609552837825, -2.494351918568327],
              [-78.50450162566892, -2.494701779207162],
              [-78.50308256946, -2.495602833799353],
              [-78.50155656016646, -2.496105915628448],
              [-78.49990851036257, -2.49640079098242],
              [-78.49878650880245, -2.49689565854635],
              [-78.49774949974062, -2.497047706270109],
              [-78.49661257839286, -2.496629616939344],
              [-78.49573549604375, -2.49590491759011],
              [-78.49533065012001, -2.494955918511664],
              [-78.49711649841223, -2.493431082684594],
              [-78.49783365404859, -2.492240181880334],
              [-78.49990851036257, -2.491358238027374],
              [-78.50366259716029, -2.490750214770458],
              [-78.50512658037026, -2.490618283614367],
              [-78.50619460247387, -2.490719034090603],
              [-78.50746161095906, -2.490206229253886],
              [-78.5052796339224, -2.487952503125939],
              [-78.50386862434053, -2.48736157895155],
              [-78.5027776358222, -2.487179356376316],
              [-78.50087762600862, -2.487789391289994],
              [-78.49803163460177, -2.489031421703714],
              [-78.49692555865771, -2.48876722411535],
              [-78.49623857787282, -2.487998435955376],
              [-78.49778755358108, -2.484656738794286],
              [-78.49740550643398, -2.482848929915747],
              [-78.49651350429723, -2.481049000026189],
              [-78.49629959812799, -2.479747122823767],
              [-78.4972836335617, -2.477308156635502],
              [-78.4976725538694, -2.475510406040769],
              [-78.49831360182486, -2.473577539166683],
              [-78.49832164845192, -2.471643666464217],
              [-78.49960357672477, -2.470226621912104],
              [-78.49999249703247, -2.466107084135558],
              [-78.49985453090606, -2.465013078132131],
              [-78.4991226231201, -2.463981265850236],
              [-78.49620857065942, -2.462339251016658],
              [-78.4953846295765, -2.461479435388128],
              [-78.49514054855581, -2.459915539892165],
              [-78.49532360932133, -2.4588765191736],
              [-78.49584161093806, -2.457982505380073],
              [-78.49719260609314, -2.456954716411701],
              [-78.49802358797471, -2.455157804007285],
              [-78.49991655698962, -2.455513867254524],
              [-78.50226550153769, -2.458030617504335],
              [-78.50366259716029, -2.458485587209111],
              [-78.50509657315688, -2.457927687733218],
              [-78.50573762111233, -2.456573675093011],
              [-78.50554651371975, -2.454973905051475],
              [-78.50467663980739, -2.453416882716169],
              [-78.50461561955221, -2.452179043254034],
              [-78.50323461718374, -2.451526092996062],
              [-78.50210557482497, -2.450294288504267],
              [-78.49901550239743, -2.448408192649936],
              [-78.4976725538694, -2.446788473678851],
              [-78.49727659276303, -2.445350642006758],
              [-78.49707056558279, -2.442033755279112],
              [-78.49658961197812, -2.438408246876236],
              [-78.49595661064973, -2.436735051362803],
              [-78.49378150677367, -2.43337843441401],
              [-78.49224862431953, -2.431956695996064],
              [-78.49059252788858, -2.430922704419402],
              [-78.48996757318723, -2.42924062408855],
              [-78.48603863988912, -2.427019084468895],
              [-78.48520665217914, -2.42527313403582],
              [-78.48625958685703, -2.423440347085773],
              [-78.4864346009955, -2.421362305648586],
              [-78.48497749094612, -2.417238576920454],
              [-78.48480264444572, -2.415963857084364],
              [-78.48574057941192, -2.414880747555003],
              [-78.48690063481249, -2.414729705659681],
              [-78.49050150042001, -2.416163681656258],
              [-78.49246253048877, -2.41658361500572],
              [-78.49432364827157, -2.417409567745437],
              [-78.49624662449988, -2.418696525160158],
              [-78.49991655698962, -2.419408651654635],
              [-78.50149553991129, -2.419483418231039],
              [-78.50273153535468, -2.420516571617384],
              [-78.50490563340236, -2.422860319385507],
              [-78.50650758273873, -2.425566165371094],
              [-78.50730151660825, -2.424257247370008],
              [-78.50711862348079, -2.422720341602314],
              [-78.50536362059229, -2.420575412577705],
              [-78.50564558781538, -2.418668697241628],
              [-78.50614950783475, -2.416943701566481],
              [-78.50605060137718, -2.415315768330231],
              [-78.50670656912031, -2.41375405212915],
              [-78.50640850864312, -2.412352095002689],
              [-78.50518055982677, -2.411581965738208],
              [-78.50659156940863, -2.410771100424256],
              [-78.5077284907564, -2.409707269272246],
              [-78.50827063225431, -2.408261223335103],
              [-78.5083614920848, -2.406797407763179],
              [-78.50744652353333, -2.405557556644283],
              [-78.50334961689542, -2.403082715910443],
              [-78.50180064118716, -2.403034603786125],
              [-78.49806264764355, -2.401699701885207],
              [-78.49721557250786, -2.399746047891256],
              [-78.49691751203066, -2.398606109058335],
              [-78.49628451070225, -2.397439012859081],
              [-78.49488054191906, -2.395638244779207],
              [-78.49270661150946, -2.392281627830414],
              [-78.49200454329883, -2.390752768689822],
              [-78.49194352304366, -2.388800958714569],
              [-78.49310257261583, -2.386614958364362],
              [-78.4934995395506, -2.385377957092544],
              [-78.49443060135619, -2.384503054038305],
              [-78.4956665967996, -2.38430607931349],
              [-78.49724557972125, -2.383802997484452],
              [-78.49991655698962, -2.385046033726496],
              [-78.50180852017614, -2.386667931992463],
              [-78.50377659104358, -2.389174791596531],
              [-78.5050355529017, -2.390432579988214],
              [-78.50640850864312, -2.391438576008227],
              [-78.51079559676923, -2.393643519459658],
              [-78.51189463191461, -2.394766191571989],
              [-78.51319164761318, -2.393837476699275],
              [-78.51389354818575, -2.392419593956788],
              [-78.51322953381558, -2.390999531919533],
              [-78.51231355943571, -2.389804607801693],
              [-78.51084857039734, -2.386972865630298],
              [-78.50968164183617, -2.385760171877735],
              [-78.5083546189242, -2.384872025416428],
              [-78.50753051020322, -2.383722195937764],
              [-78.50804851181995, -2.382232396465952],
              [-78.50825453900019, -2.380605301420019],
              [-78.50788154430853, -2.379141653486158],
              [-78.50728559099221, -2.377693595892254],
              [-78.50585161499562, -2.377221694743014],
              [-78.50452459208364, -2.37628761545227],
              [-78.50397457159676, -2.374867888691085],
              [-78.50437958515857, -2.373233082294234],
              [-78.50649249531301, -2.370679116394285],
              [-78.50907160692249, -2.368903158747855],
              [-78.50991063543114, -2.367594408384775],
              [-78.51100162394947, -2.366511298855471],
              [-78.51267264016808, -2.36627040295798],
              [-78.51385465615498, -2.367258461705205],
              [-78.51343556099582, -2.370330261583831],
              [-78.51430560254624, -2.371706067172283],
              [-78.5146485900245, -2.374869900347846],
              [-78.51770764941026, -2.384184206441205],
              [-78.51837149614238, -2.385766877400272],
              [-78.51933256516136, -2.387187945265964],
              [-78.52029464000874, -2.388282957097829],
              [-78.52166759575016, -2.387797980179641],
              [-78.52234652990799, -2.386443129349118],
              [-78.52375753948986, -2.385812977867772],
              [-78.52529159541047, -2.385489939319086],
              [-78.52674853782177, -2.385619020628098],
              [-78.52748161907418, -2.3842831128988],
              [-78.52765663321264, -2.382792139960543],
              [-78.52812954019025, -2.381184490929968],
              [-78.5297085231119, -2.381105365763915],
              [-78.53098257239576, -2.380466329465264],
              [-78.53102850522521, -2.378920371242145],
              [-78.52965554948379, -2.378149571425411],
              [-78.52841955404038, -2.377054559593546],
              [-78.52773257325549, -2.3756789216431],
              [-78.52854964117779, -2.374460863472507],
              [-78.53008252363195, -2.374545017780463],
              [-78.53092155214058, -2.373650836348872],
              [-78.52728263269262, -2.370319029833524],
              [-78.52652759085386, -2.368898129605896],
              [-78.52632156367362, -2.367542272946991],
              [-78.52619952316327, -2.364677673715107],
              [-78.52475750053962, -2.361890858544712],
              [-78.52484165484759, -2.360254878681417],
              [-78.52538262287904, -2.358538935461752],
              [-78.52465054745501, -2.357119041062504],
              [-78.52530651519812, -2.355646173035154],
              [-78.52555864284588, -2.35381254789479],
              [-78.52626054341845, -2.352295591056645],
              [-78.52725949863982, -2.351076527057671],
              [-78.52863362784768, -2.350175640103544],
              [-78.53092959876764, -2.347874975317836],
              [-78.53218051399871, -2.346900830529876],
              [-78.53354659657951, -2.34625307705187],
              [-78.53494251873566, -2.346544934920701],
              [-78.53633860852986, -2.347296791636268],
              [-78.53779555094117, -2.347290924303991],
              [-78.53684252854923, -2.348508647198457],
              [-78.53552254643594, -2.349157909419034],
              [-78.53435561787477, -2.350194583204768],
              [-78.53347065653668, -2.351530658572187],
              [-78.53353854995245, -2.353040574611612],
              [-78.53530159946803, -2.353287505479386],
              [-78.53614750113728, -2.354428450140688],
              [-78.53546856697943, -2.355737368141774],
              [-78.53670456242284, -2.356770186251993],
              [-78.53775062394011, -2.357955219724033],
              [-78.53984861430688, -2.363915591077671],
              [-78.54051262867705, -2.365425674755159],
              [-78.54145056364325, -2.366747333249123],
              [-78.54290750605458, -2.367382346234251],
              [-78.54742451368003, -2.366170490672005],
              [-78.54902663065447, -2.365567496557048],
              [-78.55009465275808, -2.364349773662582],
              [-78.55091054721393, -2.362949660554818],
              [-78.55227662979475, -2.362138627602803],
              [-78.5536955183656, -2.361535968763917],
              [-78.55667863770852, -2.361405881626524],
              [-78.55620556309286, -2.362950666383199],
              [-78.55502354710596, -2.364213651554849],
              [-78.55422961323644, -2.365685681391881],
              [-78.55365763216321, -2.36722040786475],
              [-78.55336761831308, -2.368874157362825],
              [-78.5533065980579, -2.370618263777203],
              [-78.55200153573226, -2.371520156559711],
              [-78.55052162690623, -2.371987028566991],
              [-78.5474855339352, -2.372615000753513],
              [-78.54593655822693, -2.373380100876091],
              [-78.54534948972798, -2.374689689429431],
              [-78.54640158621554, -2.375992907736361],
              [-78.54788954166862, -2.376808634554152],
              [-78.54932351766521, -2.377308698898048],
              [-78.54857652245353, -2.37871752918511],
              [-78.54709661362749, -2.379039561905415],
              [-78.54590554518515, -2.379959559598774],
              [-78.54566163180252, -2.381478192817553],
              [-78.54584452492998, -2.383032197667717],
              [-78.54489150253805, -2.384206167027571],
              [-78.54457852227318, -2.385659924315632],
              [-78.54313649964953, -2.38626409189709],
              [-78.54186261800375, -2.387046794016328],
              [-78.54298361373547, -2.38781792910919],
              [-78.54373949376453, -2.388984690032316],
              [-78.54253350553454, -2.389705868982219],
              [-78.54050458204999, -2.392278610345272],
              [-78.53909256663974, -2.392790576991729],
              [-78.53788758423812, -2.393647375135117],
              [-78.5365146284967, -2.394358495801157],
              [-78.53542363997838, -2.395378238142428],
              [-78.53458360564134, -2.396524379583695],
              [-78.53474453818247, -2.397971263711213],
              [-78.53415663149319, -2.399551084823202],
              [-78.53273757528427, -2.400063889659918],
              [-78.53125749882017, -2.399682010150912],
              [-78.5300065835891, -2.400330098905044],
              [-78.52998361717438, -2.402001785675907],
              [-78.5297625025684, -2.40353768561522],
              [-78.53079263846963, -2.404543513997169],
              [-78.53240950759368, -2.404771669401839],
              [-78.53843659889662, -2.406138422534923],
              [-78.54335057345683, -2.407729307759098],
              [-78.54955251126019, -2.406945432173359],
              [-78.55089562742627, -2.406061644301758],
              [-78.55106360076606, -2.407580277520481],
              [-78.54967455177054, -2.408572359581228],
              [-78.54823252914689, -2.409057336499416],
              [-78.54315158707526, -2.410205154321375],
              [-78.54016159457174, -2.410679067127319],
              [-78.53868051227926, -2.411282228880395],
              [-78.53776554372777, -2.412382940406417],
              [-78.53667455520944, -2.413284833188925],
              [-78.53964258712664, -2.413751034644008],
              [-78.5398715807216, -2.415287772773581],
              [-78.53985565510554, -2.418568449679526],
              [-78.53963454049958, -2.420167549168809],
              [-78.54116054979312, -2.420098314648556],
              [-78.54246561211875, -2.4194686660814],
              [-78.54524253664336, -2.417764625164239],
              [-78.5467985531503, -2.418463508251705],
              [-78.54794352112513, -2.419243360523865],
              [-78.54721060751079, -2.420923261559835],
              [-78.54507456330357, -2.423088139513993],
              [-78.54423553479492, -2.424460089427043],
              [-78.54510457051695, -2.425699940545883],
              [-78.54685957340547, -2.425919881685445],
              [-78.54866050912342, -2.425824160351056],
              [-78.55466463401164, -2.424651196819639],
              [-78.55785361289675, -2.423536236058169],
              [-78.56068451687786, -2.422285320827086],
              [-78.5622326543958, -2.421863208182799],
              [-78.56352950245632, -2.42098830512856],
              [-78.56390350297634, -2.422390262254964],
              [-78.56381264314585, -2.423854077826888],
              [-78.56281251445802, -2.425055204553075],
              [-78.55993651583779, -2.426369151696065],
              [-78.55541950821232, -2.42776188872898],
              [-78.55398553221575, -2.428662943321171],
              [-78.55305463804821, -2.430025673140733],
              [-78.55091054721393, -2.432146629922215],
              [-78.55068960024603, -2.433609607303822],
              [-78.55089562742627, -2.435128240522545],
              [-78.55147565512655, -2.436548302559856],
              [-78.55133852719047, -2.438219989330719],
              [-78.5524595229222, -2.439542150738873],
              [-78.55311549066532, -2.447108327104331],
              [-78.55236849545363, -2.448444402471694],
              [-78.55252858980442, -2.449999077874168],
              [-78.55374161883312, -2.450987304259456],
              [-78.5543824991505, -2.452452125659761],
              [-78.55532060175477, -2.453710081689508],
              [-78.55687762409009, -2.453974949830069],
              [-78.55834965392714, -2.458144611387638],
              [-78.55745664596199, -2.459390665114825],
              [-78.55785361289675, -2.462644184654437],
              [-78.55747257157803, -2.464108335502488],
              [-78.55788462593853, -2.465418929884265],
              [-78.55932664856218, -2.465892172137956],
              [-78.56243164077738, -2.466521150152857],
              [-78.56384265035925, -2.467201928329359],
              [-78.56453650430475, -2.468504811360162],
              [-78.56664254129858, -2.470795585500184],
              [-78.56789362416771, -2.470101731554678],
              [-78.56640650690494, -2.46730603156692],
              [-78.5683365239319, -2.464544194468033],
              [-78.56942751245025, -2.463741208143063],
              [-78.5709156355414, -2.463138214028049],
              [-78.56979363398129, -2.46226817247765],
              [-78.5681455841774, -2.462021409247939],
              [-78.5677335298169, -2.460584583404284],
              [-78.56656660125573, -2.459397370637419],
              [-78.56644456074538, -2.45616162073253],
              [-78.56493363887758, -2.453258129469873],
              [-78.56478863195251, -2.451694066335847],
              [-78.56295752138308, -2.448924350247978],
              [-78.56229350701291, -2.44745936120961],
              [-78.56217951312962, -2.445967550081036],
              [-78.56329363570075, -2.44521049658556],
              [-78.56443759784719, -2.446279356879472],
              [-78.5652615389301, -2.447609397276608],
              [-78.56633760766077, -2.448660487935797],
              [-78.56686365590454, -2.450215163338214],
              [-78.56832864494292, -2.450941036153893],
              [-78.56958760680104, -2.451929094901118],
              [-78.57102158279763, -2.452726884446122],
              [-78.57273853184569, -2.452558072915963],
              [-78.57442463549003, -2.452001011630443],
              [-78.5751726365301, -2.450827042270589],
              [-78.57425649451217, -2.449316288040848],
              [-78.57471464934017, -2.448006531849444],
              [-78.57621752458093, -2.44795523460192],
              [-78.5771256199718, -2.44678143288013],
              [-78.57664449872908, -2.445334716390732],
              [-78.57653050484579, -2.443779538074125],
              [-78.57659152510097, -2.442288732773932],
              [-78.5774305536096, -2.439190110805157],
              [-78.57879663619042, -2.438885177167322],
              [-78.5802845916435, -2.438851984830762],
              [-78.58019255834655, -2.440388052408082],
              [-78.58100157964179, -2.445089629541656],
              [-78.58205451431968, -2.446275501204013],
              [-78.5835875644119, -2.447001374019692],
              [-78.5857614948215, -2.44929130996934],
              [-78.58715054381703, -2.449032309160998],
              [-78.59008052189377, -2.450601233798807],
              [-78.59160653118731, -2.450893091667695],
              [-78.59279659380127, -2.450109216081955],
              [-78.59411657591457, -2.450473325956239],
              [-78.5955585985382, -2.450213319319516],
              [-78.59607660015493, -2.448488491282433],
              [-78.59564258520811, -2.446995339049408],
              [-78.59456651647744, -2.4458823899447],
              [-78.59593159322986, -2.445459606748159],
              [-78.59922752519958, -2.448909262822269],
              [-78.60070760166367, -2.449065166221487],
              [-78.6021045296482, -2.448553367213094],
              [-78.60324061280565, -2.447606379791466],
              [-78.60337857893205, -2.446141558391162],
              [-78.60314153871003, -2.444632648180118],
              [-78.6016316226706, -2.441881707555353],
              [-78.60160060962882, -2.440181857589778],
              [-78.60125762215057, -2.438698931278623],
              [-78.60132551556634, -2.437226230889337],
              [-78.60079158833358, -2.435716147211849],
              [-78.59971652543129, -2.434412425990672],
              [-78.6004025003878, -2.43298549079276],
              [-78.60016663363223, -2.431358731022954],
              [-78.60018960004696, -2.429704813886872],
              [-78.60045664748237, -2.428285925316004],
              [-78.59990662699549, -2.426820936277636],
              [-78.59983051931458, -2.425193170679449],
              [-78.59948753183633, -2.423728349279145],
              [-78.597930509501, -2.42058949417509],
              [-78.59865554412636, -2.419235649172947],
              [-78.59744251509768, -2.418410534623547],
              [-78.59748861556518, -2.416765837580954],
              [-78.59609252577098, -2.415985650032667],
              [-78.59327654157754, -2.411959151381495],
              [-78.59224657331438, -2.411071172558252],
              [-78.59114854399738, -2.409749178788161],
              [-78.58986661572453, -2.408906294604094],
              [-78.5884475595156, -2.408271449256972],
              [-78.58687561739262, -2.407907339382689],
              [-78.58527350041817, -2.407750262517027],
              [-78.58395351830487, -2.408127280522251],
              [-78.58261157560524, -2.409074435581942],
              [-78.58164950075786, -2.410365248672178],
              [-78.58124549302443, -2.413111830707237],
              [-78.57983364525224, -2.412521912361228],
              [-78.57786557438482, -2.409780359468016],
              [-78.57710265355708, -2.408386281330593],
              [-78.57698849203572, -2.406732364194454],
              [-78.57905563699877, -2.404313682211921],
              [-78.57807964819212, -2.403245827746389],
              [-78.57371552648073, -2.40192685146144],
              [-78.57411953421416, -2.400418947078776],
              [-78.57553054379602, -2.397728188518897],
              [-78.5745696424151, -2.39648733157162],
              [-78.57401258112954, -2.395049164623458],
              [-78.5739666483001, -2.393422572491716],
              [-78.57327262671653, -2.390239628576921],
              [-78.5725405512925, -2.38883767145046],
              [-78.5681995636339, -2.386606911737317],
              [-78.56694060177577, -2.385700157450913],
              [-78.56652854741529, -2.384190073773425],
              [-78.56752750263666, -2.38282734395392],
              [-78.56873349086666, -2.381952440899624],
              [-78.56969455988566, -2.380779309730087],
              [-78.56941259266257, -2.379224466689607],
              [-78.56883960576097, -2.37770466000444],
              [-78.56896952526031, -2.376168760065127],
              [-78.56864162520777, -2.371170128282756],
              [-78.56724553541358, -2.370652126666073],
              [-78.56576562658755, -2.371119166311416],
              [-78.56433064476256, -2.371813020256923],
              [-78.56380459651879, -2.370456157769638],
              [-78.56496465191935, -2.36940121143499],
              [-78.56455259755886, -2.367954327307473],
              [-78.5633625349449, -2.366768288007052],
              [-78.56132556483331, -2.364278695123573],
              [-78.56035661682533, -2.362930717453651],
              [-78.55964650198764, -2.361482827497753],
              [-78.5591355411696, -2.358228134491696],
              [-78.56034052357121, -2.355221210543732],
              [-78.5579835323961, -2.353147527696194],
              [-78.55711349084567, -2.351771554469678],
              [-78.55670160412325, -2.350170610961641],
              [-78.55655659719817, -2.348606883103798],
              [-78.55664058386807, -2.345533909758728],
              [-78.55698357134632, -2.34392626072821],
              [-78.55670160412325, -2.342443166778935],
              [-78.55791463315192, -2.341352345898656],
              [-78.55918851479771, -2.342086265341379],
              [-78.56031051635782, -2.343182115363561],
              [-78.56118055790824, -2.344619947035653],
              [-78.5621265395015, -2.347848823779884],
              [-78.56313354134994, -2.350390552101203],
              [-78.5646746380692, -2.353158424170374],
              [-78.5657275727471, -2.354326358559888],
              [-78.56616259352231, -2.355791347598256],
              [-78.56626149997986, -2.358845880756292],
              [-78.56646752716011, -2.360355964433779],
              [-78.56752062947605, -2.361333797259192],
              [-78.57033560784112, -2.362839689985094],
              [-78.5717695838377, -2.36338484896811],
              [-78.57328050570551, -2.363007830962943],
              [-78.57443955527769, -2.362268714740196],
              [-78.57419564189506, -2.363740576939222],
              [-78.57415054725594, -2.365277482706858],
              [-78.57447056831947, -2.366859315475608],
              [-78.57699553283439, -2.372857070117448],
              [-78.57924657675329, -2.376909720306514],
              [-78.57994059833686, -2.380137423584358],
              [-78.58092463377058, -2.383303268416626],
              [-78.58109260711036, -2.385065982656101],
              [-78.58243555563838, -2.385971898752132],
              [-78.58274065691425, -2.387599999626445],
              [-78.5827865897437, -2.389190717212557],
              [-78.58322865131758, -2.390745560253038],
              [-78.58446464676099, -2.39385742562888],
              [-78.58531155425862, -2.395187298387896],
              [-78.58670764405282, -2.396030350210083],
              [-78.5897675416289, -2.396722360136891],
              [-78.59130059172112, -2.396788241895933],
              [-78.5943526103082, -2.396269402088876],
              [-78.59429159005303, -2.397670185748893],
              [-78.59470364441351, -2.399135174787261],
              [-78.59991450598449, -2.402687592994369],
              [-78.60110456859844, -2.403828705293733],
              [-78.60245556375351, -2.404581567837624],
              [-78.6044085471952, -2.408914173593075],
              [-78.60563649601156, -2.409865351966289],
              [-78.60639958447736, -2.411241157554798],
              [-78.60699453196531, -2.413095066900894],
              [-78.60849757484412, -2.413386924769725],
              [-78.61162553347405, -2.413058018888819],
              [-78.61188453428241, -2.414522002098806],
              [-78.61120560012456, -2.417485675426292],
              [-78.61191554732419, -2.418851422730995],
              [-78.61301458246957, -2.420001587485785],
              [-78.61444855846617, -2.420194538897078],
              [-78.61577558137814, -2.420784457243144],
              [-78.61712657653322, -2.420596367335691],
              [-78.61746956401147, -2.42369716859929],
              [-78.61791263141374, -2.42537019647466],
              [-78.61807255812649, -2.426834850236901],
              [-78.61939958103845, -2.427171970382972],
              [-78.6206815093113, -2.427878900097426],
              [-78.62168851115973, -2.429019677120664],
              [-78.6231234929847, -2.429699617106905],
              [-78.62470264354442, -2.429765666503954],
              [-78.62620551878518, -2.429434748966287],
              [-78.62769364187633, -2.429915702570952],
              [-78.62926457817095, -2.430044616241901],
              [-78.63083652029393, -2.429577911872684],
              [-78.63163749496212, -2.428476697432473],
              [-78.63229363034331, -2.426841891035565],
              [-78.63235465059849, -2.425170204264703],
              [-78.63219455624768, -2.423678057860059],
              [-78.63363657887133, -2.424223049205011],
              [-78.63513962175014, -2.424488252621757],
              [-78.63671860467181, -2.424508033913241],
              [-78.63737457241493, -2.423009349624067],
              [-78.63736753161625, -2.41987233853871],
              [-78.63774857293497, -2.418427633706074],
              [-78.63758864622223, -2.4169358225775],
              [-78.63769559930685, -2.415326832442418],
              [-78.63872556757, -2.414017914441331],
              [-78.63774153213629, -2.412624003941971],
              [-78.63964858274855, -2.409862334481147],
              [-78.64071660485216, -2.408661375393081],
              [-78.64196752008323, -2.407488244223543],
              [-78.64447756481047, -2.405685632124914],
              [-78.6443325578854, -2.404012771887608],
              [-78.64376057681218, -2.402619699578565],
              [-78.64536252614856, -2.40254191551702],
              [-78.64670564231464, -2.401820904205238],
              [-78.64708752182368, -2.400148882158192],
              [-78.6468585282287, -2.398540227299293],
              [-78.64751449597183, -2.397257125559975],
              [-78.6488646529366, -2.396925202193927],
              [-78.64974961427468, -2.39559817928199],
              [-78.65006259453953, -2.394053562163379],
              [-78.65001649407203, -2.392543646123954],
              [-78.64934560654125, -2.390987629616973],
              [-78.649139579361, -2.389378639481947],
              [-78.6476055234404, -2.386682013589791],
              [-78.64759865027979, -2.385146113650535],
              [-78.64723152292036, -2.383771313890406],
              [-78.64710965004808, -2.380860278914838],
              [-78.64575949308332, -2.380198443839504],
              [-78.6462855413271, -2.378707638539311],
              [-78.64563762021103, -2.377395703053082],
              [-78.64536466808987, -2.376355286243141],
              [-78.65030014270727, -2.377766473016978],
              [-78.66200360035785, -2.384645855504281],
              [-78.6682805809926, -2.385426732237914],
              [-78.67551294599141, -2.38117553755662],
              [-78.67878099475219, -2.392605773925372],
              [-78.680950948488, -2.394343431830381],
              [-78.67926061900226, -2.39588316399022],
              [-78.67803149671947, -2.396523373755315],
              [-78.67749052868803, -2.397932204042377],
              [-78.67855855079164, -2.398983127063502],
              [-78.68319659309904, -2.398728987759],
              [-78.68463157492403, -2.397963049446105],
              [-78.68495766928123, -2.397551938050853],
              [-78.68961103370751, -2.401278264260815],
              [-78.67533535349236, -2.407142330512259],
              [-78.66503236230649, -2.401086129529801],
              [-78.66345165943322, -2.413795646280501],
              [-78.67146815501631, -2.436696116235791],
              [-78.67071560398307, -2.437249197304027],
              [-78.66966250166712, -2.438314201922481],
              [-78.67031059042125, -2.44124384472309],
              [-78.67031059042125, -2.442825845129903],
              [-78.67184464634185, -2.442656865961737],
              [-78.67314149440236, -2.44166478390099],
              [-78.67320389205342, -2.441654541454985],
              [-78.68112098036418, -2.464271037792404],
              [-78.69191532466161, -2.46881924785453],
              [-78.70216657435537, -2.485697237914729],
              [-78.69702968216956, -2.495100362593867],
              [-78.69649882756055, -2.496908586105799],
              [-78.69644634896488, -2.496904367112416],
              [-78.68994506443656, -2.504450931808701],
              [-78.68606623936965, -2.507792132002011],
              [-78.68105399709798, -2.513609927453534],
              [-78.67152306906387, -2.521821471161076],
              [-78.6513564397694, -2.534452547469844],
              [-78.64198459547345, -2.549415677681718],
              [-78.63501795772856, -2.554722289738793],
              [-78.62857484263229, -2.540282240924002],
              [-78.62296548528475, -2.536941040611174],
              [-78.60885373617802, -2.553320060545352],
              [-78.60173859080669, -2.559450294762355],
              [-78.59414531272326, -2.556661701267732],
              [-78.59247471209582, -2.565821137672342],
              [-78.5866569150251, -2.58166341517665],
              [-78.58331527289828, -2.614421896037673],
              [-78.5856085477433, -2.61654639176326],
              [-78.58464038352247, -2.640968617718754],
              [-78.58602297024451, -2.64297779388391],
              [-78.58212173511612, -2.647903375666131],
              [-78.58136753353477, -2.646626624250644],
              [-78.58009365188899, -2.645711655699131],
              [-78.57858256238312, -2.645401525281386],
              [-78.5770795195043, -2.645570504449552],
              [-78.57656855868625, -2.642243056523853],
              [-78.57489754246762, -2.641724887269049],
              [-78.5750965288492, -2.638788203669776],
              [-78.57637761893173, -2.637814058881816],
              [-78.57637057813305, -2.636277320752242],
              [-78.57545460375319, -2.635209466286653],
              [-78.57618751736753, -2.633755541360529],
              [-78.5763325242926, -2.632481659714699],
              [-78.57465362908499, -2.632432709400121],
              [-78.57323457287607, -2.63257453120201],
              [-78.57186849029526, -2.633502575522414],
              [-78.57073257477587, -2.634512594856005],
              [-78.5696565060452, -2.635713218668002],
              [-78.56802354366705, -2.638611177874566],
              [-78.56706264228612, -2.639965190514772],
              [-78.56565062687586, -2.640730122999287],
              [-78.56414758399704, -2.640826850162],
              [-78.56061561763369, -2.642171810346781],
              [-78.56239358693692, -2.643398920972857],
              [-78.56164558589685, -2.644599880060923],
              [-78.56244656056504, -2.64592070036457],
              [-78.55986761659362, -2.647795564468595],
              [-78.55960056915819, -2.649304307041575],
              [-78.56063053742136, -2.650563268899703],
              [-78.55992159605012, -2.651854249627945],
              [-78.55850958063986, -2.652249037267893],
              [-78.55789954572619, -2.653829864208262],
              [-78.55757851883428, -2.655373978412683],
              [-78.55609861000823, -2.65505496317752],
              [-78.55472565426682, -2.65510693097724],
              [-78.5547105668411, -2.656642830916553],
              [-78.55356559886626, -2.657408769229448],
              [-78.55089562742627, -2.658424823533323],
              [-78.54969751818525, -2.661414480760698],
              [-78.54953759147251, -2.662923223333678],
              [-78.55045356585238, -2.664136252362368],
              [-78.5506366266179, -2.665583136489886],
              [-78.55004855229056, -2.666494249365883],
              [-78.54919460399427, -2.668444885874692],
              [-78.54890459014412, -2.670025880453125],
              [-78.55065959303263, -2.671755737632054],
              [-78.55005659891762, -2.672866675080002],
              [-78.54861457629399, -2.673152497978549],
              [-78.54660057259711, -2.672732732267093],
              [-78.54456360248551, -2.673062476338373],
              [-78.54254959878864, -2.673736548992338],
              [-78.54060365614562, -2.673543597581045],
              [-78.53852058556652, -2.672951667578275],
              [-78.53672065567694, -2.673327679755062],
              [-78.53549153339415, -2.674996516678846],
              [-78.53124257903251, -2.676588407731401],
              [-78.52953350897344, -2.677759359606114],
              [-78.52779359351067, -2.679401206801629],
              [-78.52609257007866, -2.683048843428935],
              [-78.52585552985663, -2.685009705859613],
              [-78.52652759085386, -2.687107696226406],
              [-78.5278095191267, -2.688827495121529],
              [-78.52962453644201, -2.689825444514554],
              [-78.53210457395588, -2.690400443072917],
              [-78.53384365122832, -2.691000252064669],
              [-78.53828455117288, -2.693195975422555],
              [-78.54264062625721, -2.69706372082743],
              [-78.54643259925732, -2.698834816970077],
              [-78.54851550219836, -2.699480558791322],
              [-78.55035365356643, -2.700885365764805],
              [-78.5511856412764, -2.702685463292482],
              [-78.55139954744564, -2.704746238009079],
              [-78.55205551518877, -2.706438208985674],
              [-78.55355051144053, -2.707913926860101],
              [-78.55532864838183, -2.709264922015166],
              [-78.55937962219029, -2.711540608729422],
              [-78.5600355899334, -2.713350429264779],
              [-78.5615235453865, -2.714600338667424],
              [-78.56365958959373, -2.715065366656063],
              [-78.56980855376895, -2.718503120427613],
              [-78.57212849693201, -2.720342948176381],
              [-78.57379951315062, -2.721927966068279],
              [-78.5749585627228, -2.723421788853614],
              [-78.57585157068795, -2.725276871666154],
              [-78.57616455095281, -2.729290294548321],
              [-78.57677458586649, -2.731216120623742],
              [-78.57804159435166, -2.73264724677324],
              [-78.57982660445357, -2.734114918020623],
              [-78.58098565402575, -2.735662887900503],
              [-78.58170364785242, -2.737471870245486],
              [-78.58315254363669, -2.739065772954859],
              [-78.58400749776138, -2.741317487425988],
              [-78.58364053804002, -2.743179275761065],
              [-78.58206155511836, -2.744640241485911],
              [-78.57797252746944, -2.74368218995204],
              [-78.57636253150599, -2.744574192088805],
              [-78.57472956912784, -2.746026105358226],
              [-78.5746236218716, -2.748448140102028],
              [-78.57620964559193, -2.749409879673294],
              [-78.57719451921596, -2.750965896180219],
              [-78.57741563382194, -2.752972691440334],
              [-78.57856764259544, -2.754412534769187],
              [-78.5805355458248, -2.755004632410021],
              [-78.5825725159364, -2.755939382253018],
              [-78.58267963665908, -2.758000324607735],
              [-78.58151254045985, -2.7596701673599],
              [-78.58083360630201, -2.76205498645362],
              [-78.58099353301475, -2.764178793082181],
              [-78.5801845117195, -2.766228503686591],
              [-78.57894164311548, -2.767554520770204],
              [-78.57776650028919, -2.769161331610405],
              [-78.57849153491455, -2.77102446104999],
              [-78.58034561189868, -2.770477458048276],
              [-78.58187061536384, -2.7691973737941],
              [-78.58414462569745, -2.767872530176987],
              [-78.58629659552072, -2.767597436114499],
              [-78.59067563701977, -2.768591362193945],
              [-78.59291863431162, -2.767845372810655],
              [-78.59448252980755, -2.766050807339127],
              [-78.59682460119501, -2.765069621752502],
              [-78.5982585771916, -2.766383736533555],
              [-78.60015054037812, -2.770083340960582],
              [-78.60197461014886, -2.771199139912312],
              [-78.60372156641031, -2.771980165650973],
              [-78.60558352238344, -2.773783113025672],
              [-78.60685757166729, -2.782172559921776],
              [-78.60611761725427, -2.784395273007931],
              [-78.60475958130053, -2.785721122453424],
              [-78.60305051124146, -2.786838094871655],
              [-78.60186748942618, -2.788390088065057],
              [-78.60202758377697, -2.790396715687109],
              [-78.60318763917753, -2.791953570384351],
              [-78.60409556693035, -2.794089614591599],
              [-78.60334756589027, -2.796202357107973],
              [-78.60199757656358, -2.797754350301375],
              [-78.60041859364192, -2.799215148388157],
              [-78.59870952358285, -2.801480106266354],
              [-78.59818263714875, -2.80261786580445],
              [-78.5966645068442, -2.803256734465037],
              [-78.59609956656965, -2.804104983067191],
              [-78.5896305813309, -2.808782420319574],
              [-78.58786753181532, -2.80809258968759],
              [-78.58644059661741, -2.806769757727238],
              [-78.58541062835425, -2.80843943284134],
              [-78.58395351830487, -2.80813869015509],
              [-78.5819934940645, -2.805847748377062],
              [-78.58096352580135, -2.803838773822122],
              [-78.58005559804853, -2.802481073144463],
              [-78.57942159089176, -2.805380876369725],
              [-78.57942159089176, -2.809240407509492],
              [-78.57904054957305, -2.810469362154208],
              [-78.57428750755395, -2.812104336189179],
              [-78.57228859128281, -2.813257015514921],
              [-78.57051850096857, -2.812394182401249],
              [-78.56966354684388, -2.810784354075849],
              [-78.5672145223718, -2.809920515133797],
              [-78.56544459969562, -2.80957333670392],
              [-78.56362153575327, -2.809514328105536],
              [-78.56322456881851, -2.80744466857152],
              [-78.56442251042147, -2.805774658181292],
              [-78.56451454371842, -2.80451888144637],
              [-78.56322456881851, -2.802265825870734],
              [-78.56226349979951, -2.800085189938557],
              [-78.56141659230188, -2.797082456942178],
              [-78.56056951716619, -2.795797511184219],
              [-78.55975362271033, -2.795181608938321],
              [-78.55503058790462, -2.795072476558857],
              [-78.55358152448231, -2.795773538941091],
              [-78.552619617273, -2.796919345106232],
              [-78.55162049441356, -2.799204251914034],
              [-78.55153650774366, -2.800677119941383],
              [-78.55304759724953, -2.80185511261476],
              [-78.5535275450258, -2.803410961483621],
              [-78.55257452263388, -2.804384770995455],
              [-78.55078850670358, -2.808403390657645],
              [-78.54956055788725, -2.809250465793355],
              [-78.54713449982988, -2.808748557430704],
              [-78.54508260993062, -2.807595710466899],
              [-78.54309056682008, -2.807139567295678],
              [-78.5408095156878, -2.807252722988608],
              [-78.53892559912833, -2.806444539883728],
              [-78.53807064500364, -2.804544865346259],
              [-78.53584256749947, -2.803970704978212],
              [-78.5345916522684, -2.805747836091086],
              [-78.53310352917725, -2.806142791369098],
              [-78.53134165312814, -2.804195675259621],
              [-78.52951858918577, -2.803451026980838],
              [-78.52768664042604, -2.803157995645563],
              [-78.52512362207067, -2.802121992412083],
              [-78.5242155266798, -2.800501938164871],
              [-78.52308665195908, -2.799071147291443],
              [-78.5225066242588, -2.797570283707444],
              [-78.52191955575984, -2.796611226345249],
              [-78.52096552753953, -2.795786447072032],
              [-78.51969952488272, -2.795684355491233],
              [-78.5187986379286, -2.796495556081311],
              [-78.5185245496945, -2.797471209611842],
              [-78.52059957364655, -2.801064028592293],
              [-78.52249153683307, -2.804989776767229],
              [-78.52465859408207, -2.806314788022405],
              [-78.52655055726859, -2.807710542540519],
              [-78.52694651837496, -2.809021472198367],
              [-78.52552058900542, -2.810220419629673],
              [-78.52329251150125, -2.811544425056525],
              [-78.52266655097154, -2.813676110674066],
              [-78.52317851761796, -2.814535926302597],
              [-78.52603959645053, -2.815744093827448],
              [-78.52866363506108, -2.816436941944573],
              [-78.53278350811371, -2.816165033005291],
              [-78.53402755018418, -2.816944717639444],
              [-78.5340426376099, -2.818273752208142],
              [-78.53325658272938, -2.819654586938555],
              [-78.53009761105767, -2.821148409723889],
              [-78.52752654607524, -2.821377403318877],
              [-78.52603959645053, -2.822585570843671],
              [-78.52649657781208, -2.824638466571344],
              [-78.52568051571816, -2.826146203315943],
              [-78.52409365380751, -2.82678406614815],
              [-78.52277350405615, -2.826908285953323],
              [-78.52049262056192, -2.825466095691638],
              [-78.51854651028084, -2.824549450759491],
              [-78.51666259372138, -2.825928106195079],
              [-78.51340454795404, -2.824836279486476],
              [-78.50942264102781, -2.821989449889315],
              [-78.50802655123361, -2.822420447350964],
              [-78.50585949398462, -2.824603262577966],
              [-78.50271560973863, -2.824668138508571],
              [-78.50231160200521, -2.827135267891549],
              [-78.5037455780018, -2.829613796662841],
              [-78.50557752676153, -2.831452953859298],
              [-78.50757560484234, -2.833174764411183],
              [-78.50952154748538, -2.83547459100663],
              [-78.50952154748538, -2.837833258562398],
              [-78.51123849653342, -2.840132079329408],
              [-78.51312258073096, -2.839269246215736],
              [-78.51477850952384, -2.84088125383596],
              [-78.51621265315849, -2.842772881746328],
              [-78.51884457075803, -2.843872922720095],
              [-78.52307156453335, -2.844043913545079],
              [-78.52530651519812, -2.845079749140439],
              [-78.52741959299063, -2.845824732695348],
              [-78.52951858918577, -2.845766562287281],
              [-78.53044864516299, -2.845072708341775],
              [-78.52999149616338, -2.843119054347824],
              [-78.5298156438346, -2.841049227175745],
              [-78.53130359928768, -2.839552051629141],
              [-78.53404951077052, -2.838627192431886],
              [-78.53462249767212, -2.836622408828532],
              [-78.53588062133993, -2.835178542186213],
              [-78.54010761511523, -2.834202721017618],
              [-78.5439376419558, -2.832818701164001],
              [-78.54582256434364, -2.83275969256556],
              [-78.5474855339352, -2.831786888882107],
              [-78.54805751500842, -2.829709015082983],
              [-78.54971361143937, -2.825917209720956],
              [-78.55165050162695, -2.825342211162592],
              [-78.55571756868953, -2.826897054203073],
              [-78.55560256897785, -2.829021028469697],
              [-78.55417663960831, -2.832704539642634],
              [-78.55383264630167, -2.834773528624396],
              [-78.55474862068155, -2.836556359431484],
              [-78.55663253724101, -2.837184331617948],
              [-78.55897561445686, -2.837125323019563],
              [-78.5609205512715, -2.837355322442875],
              [-78.56258352086306, -2.838453351759881],
              [-78.5608065573882, -2.839832174833532],
              [-78.55977658912505, -2.841330020932389],
              [-78.55829651266095, -2.842474988907213],
              [-78.55617555587946, -2.843048813999133],
              [-78.55435165374679, -2.844148016782583],
              [-78.55583156257282, -2.845352663908102],
              [-78.56018059685849, -2.846039644693008],
              [-78.56214850008784, -2.846676669334897],
              [-78.56378163010407, -2.847828678108442],
              [-78.56275149420284, -2.849380503663781],
              [-78.56155355259989, -2.851276154887671],
              [-78.56092859789855, -2.853173147216125],
              [-78.55995964989057, -2.854897137062835],
              [-78.55852550625592, -2.856682817717001],
              [-78.55904350787264, -2.858925647370768],
              [-78.56087461844206, -2.859209793888681],
              [-78.56361365676428, -2.858754656545841],
              [-78.5655595994073, -2.859617657297576],
              [-78.56658956767046, -2.861337623830821],
              [-78.56830551089013, -2.862145471659574],
              [-78.57002262757625, -2.863577435999446],
              [-78.5718535705076, -2.863698470681413],
              [-78.57527958961472, -2.861970457521124],
              [-78.57733952614103, -2.861965596017285],
              [-78.57865850242595, -2.863631415455927],
              [-78.57911648961588, -2.865422125251996],
              [-78.58071860659032, -2.867025080416738],
              [-78.58260352897818, -2.86702910373026],
              [-78.58431963983591, -2.868289071416768],
              [-78.58826449875008, -2.869210074938565],
              [-78.5901485829476, -2.870651929924179],
              [-78.59123957146595, -2.872262764077902],
              [-78.59204054613413, -2.874677590384977],
              [-78.5924375130689, -2.876685559111593],
              [-78.59323865537515, -2.878639380743607],
              [-78.59438362334998, -2.88053922291914],
              [-78.5949556044232, -2.882665041204461],
              [-78.59398665641523, -2.884216699121737],
              [-78.5922695397291, -2.883355039474509],
              [-78.58901249979016, -2.880257088057988],
              [-78.58792151127183, -2.878302260597593],
              [-78.58672356966888, -2.87657357688505],
              [-78.5849536469927, -2.875656596676833],
              [-78.58289354282832, -2.875543440983847],
              [-78.58106259989697, -2.876290603833581],
              [-78.57957464444388, -2.878357245882455],
              [-78.57791854801293, -2.879456281027842],
              [-78.57769056024635, -2.881462070459577],
              [-78.5790024957326, -2.883019092794882],
              [-78.57763658078986, -2.884399927525294],
              [-78.57757556053468, -2.886812742175664],
              [-78.57803354772462, -2.888765390341234],
              [-78.5781475416079, -2.890835385151377],
              [-78.57637761893173, -2.891763261833717],
              [-78.57552350299736, -2.893605268877309],
              [-78.57351654009915, -2.894405070079017],
              [-78.57203663127312, -2.892910073827295],
              [-78.57145660357284, -2.89118323413345],
              [-78.57151762382802, -2.888941577946127],
              [-78.5730055792811, -2.887390590581106],
              [-78.57369256006601, -2.885439786434233],
              [-78.57191459076277, -2.886122743905617],
              [-78.57003050656525, -2.887157573672596],
              [-78.56636862070255, -2.888831607376346],
              [-78.56356856212516, -2.8919342526587],
              [-78.56145464614234, -2.892282101640774],
              [-78.55996652305117, -2.891248445340239],
              [-78.55785361289675, -2.89147945059193],
              [-78.55579350873236, -2.890787273027058],
              [-78.55425257965116, -2.889527472978614],
              [-78.55224662258135, -2.888547628496497],
              [-78.55041450618354, -2.889293617879844],
              [-78.54875958321905, -2.890618293858893],
              [-78.54715763388266, -2.891600149997771],
              [-78.54532652331325, -2.891713473328821],
              [-78.54367059452038, -2.890444453186888],
              [-78.5414964964727, -2.889473325884069],
              [-78.53560653310583, -2.892117145786131],
              [-78.53332564961161, -2.890856507547369],
              [-78.53252450730535, -2.888667657350084],
              [-78.53131851907534, -2.886368668945011],
              [-78.5293735822607, -2.886481824637997],
              [-78.5280074996799, -2.889416496580509],
              [-78.52903763558112, -2.89275333223776],
              [-78.52869464810286, -2.894532977921585],
              [-78.52972461636602, -2.896261829272191],
              [-78.53183752652045, -2.896772957728274],
              [-78.53395060431295, -2.897753975676892],
              [-78.53675854187934, -2.899937796732218],
              [-78.53898661938351, -2.899020816524001],
              [-78.54109952953795, -2.898672632265743],
              [-78.54481455666682, -2.900569624594198],
              [-78.5469355134483, -2.90033962517083],
              [-78.54882060347423, -2.900741788885568],
              [-78.55013253896047, -2.902289591127385],
              [-78.55110953359551, -2.903964295383389],
              [-78.55248265697499, -2.905458118168724],
              [-78.55459556712941, -2.905915099530262],
              [-78.55705263822855, -2.905974108128703],
              [-78.5588835811599, -2.906602080315167],
              [-78.55825762063017, -2.908670063468549],
              [-78.55654150977244, -2.909994907085661],
              [-78.55151354132894, -2.912532779731521],
              [-78.55162854104061, -2.914539574991636],
              [-78.55259665085828, -2.916385605348694],
              [-78.55282564445324, -2.918392400608866],
              [-78.55151354132894, -2.919664270597877],
              [-78.54962962476947, -2.920238095689797],
              [-78.54796564934954, -2.921735103598337],
              [-78.54694356007536, -2.923576775365746],
              [-78.5474015472653, -2.925646770175888],
              [-78.54928563146282, -2.926103583899362],
              [-78.55117055385068, -2.92523974495731],
              [-78.55294751732552, -2.92466491403701],
              [-78.55477158709627, -2.924777734453869],
              [-78.55677754416608, -2.924429717833675],
              [-78.55825762063017, -2.922995909475162],
              [-78.56192051232127, -2.921440060606301],
              [-78.56344551578641, -2.921162954887052],
              [-78.5635226292957, -2.923278044336314],
              [-78.56272165462751, -2.925409897591919],
              [-78.56386561677397, -2.926903720377254],
              [-78.56500957892041, -2.925296741898933],
              [-78.5666655077133, -2.924026045376365],
              [-78.56826762468775, -2.925403694983572],
              [-78.57026654095888, -2.926040719625519],
              [-78.5720976515283, -2.925004716392039],
              [-78.57392859445964, -2.925117872085025],
              [-78.57467659549972, -2.923167067938152],
              [-78.57472956912784, -2.921269069781317],
              [-78.57592751073079, -2.919771223682517],
              [-78.57787362101188, -2.919658235627594],
              [-78.57981855782651, -2.918966225700785],
              [-78.58124549302443, -2.917523532524854],
              [-78.58313762384901, -2.917184400722078],
              [-78.5842205657403, -2.91878635005844],
              [-78.58656364295614, -2.918095178321948],
              [-78.58839458588749, -2.918957340883424],
              [-78.58982856188408, -2.923099845074603],
              [-78.59091150377535, -2.924657705600282],
              [-78.59228563298322, -2.923159859501425],
              [-78.59274261434476, -2.92120017053719],
              [-78.59560352553927, -2.918612174110308],
              [-78.59920455878486, -2.916884496226203],
              [-78.60120364269406, -2.916138339204792],
              [-78.60514850160823, -2.915966510189548],
              [-78.60915354258725, -2.914356514226142],
              [-78.61029851056207, -2.915850504649484],
              [-78.61029851056207, -2.917748502806319],
              [-78.60961152977718, -2.91964532749671],
              [-78.60566750905333, -2.920793983508929],
              [-78.60452254107851, -2.92246315570884],
              [-78.60309560588058, -2.923842816972808],
              [-78.60120364269406, -2.924588806356098],
              [-78.59925853824136, -2.925054672535055],
              [-78.59754963582036, -2.92603686395006],
              [-78.59634364759034, -2.927588689505399],
              [-78.59640450020746, -2.929947357061167],
              [-78.59725962197022, -2.931784334962799],
              [-78.59892259156177, -2.933161146379689],
              [-78.60006755953661, -2.934773153999856],
              [-78.59967059260185, -2.938450797840574],
              [-78.60137949502285, -2.939204833850908],
              [-78.60315662613576, -2.939542624549233],
              [-78.60521656266208, -2.940523642497794],
              [-78.60955855614907, -2.940008826004259],
              [-78.6115034929637, -2.940871491479868],
              [-78.61047352470055, -2.942939474633249],
              [-78.61099253214566, -2.945182304287016],
              [-78.61179350681384, -2.946964129265723],
              [-78.6115034929637, -2.948806974499576],
              [-78.60961957640424, -2.947998959032702],
              [-78.60739149890007, -2.945011313462089],
              [-78.60527054211857, -2.944202292166835],
              [-78.60309560588058, -2.945354468578387],
              [-78.60086853420479, -2.945756129378935],
              [-78.59623753269605, -2.945357318425465],
              [-78.59664154042949, -2.947139311042235],
              [-78.59824348976586, -2.948407995908042],
              [-78.59903759127344, -2.950189820886749],
              [-78.60104354834327, -2.950989957364641],
              [-78.60224148994621, -2.952483780149976],
              [-78.6026996447742, -2.954500633693897],
              [-78.60292863836918, -2.956570628504039],
              [-78.60379063329255, -2.958407271129545],
              [-78.60516358903396, -2.959622144176933],
              [-78.6091076097578, -2.960308119133458],
              [-78.61110652602895, -2.96099929086995],
              [-78.6125946491201, -2.962033114808548],
              [-78.61293763659836, -2.963506821026272],
              [-78.60876462227955, -2.96525176563091],
              [-78.60722352556027, -2.966233789407909],
              [-78.60310365250764, -2.967842779542934],
              [-78.60139458244858, -2.968416604634854],
              [-78.59922065203897, -2.968421633776757],
              [-78.59767150869264, -2.969918474047233],
              [-78.59698452790774, -2.971869445832169],
              [-78.59527562548674, -2.971070315182658],
              [-78.59230055277088, -2.967845629390013],
              [-78.58783752460192, -2.963704969217474],
              [-78.5858915819589, -2.963013965119046],
              [-78.58366350445473, -2.963135837991331],
              [-78.58148957404512, -2.967044654721803],
              [-78.5796054898476, -2.967618647451843],
              [-78.57771252083269, -2.967967669900361],
              [-78.57685857253638, -2.970207314430922],
              [-78.57834652798947, -2.971475496382538],
              [-78.58017763855888, -2.972049489112521],
              [-78.58429751161151, -2.972338329496267],
              [-78.5858915819589, -2.973254303876104],
              [-78.58715054381703, -2.974866143858264],
              [-78.58892851312027, -2.976018152631752],
              [-78.59007264290477, -2.977511807779024],
              [-78.58978262905464, -2.979408632469358],
              [-78.58972965542651, -2.981424647823019],
              [-78.59144559864619, -2.982802632706353],
              [-78.59321552132236, -2.983954306203714],
              [-78.59424548958552, -2.985963448396717],
              [-78.59573361267668, -2.986996266506992],
              [-78.5976795553197, -2.987001128010832],
              [-78.60082260137536, -2.984232417751343],
              [-78.60276753819, -2.984635252018336],
              [-78.60191358989368, -2.986540290973835],
              [-78.60179859018201, -2.987859267258727],
              [-78.60391250616483, -2.988894097025707],
              [-78.6059726103292, -2.988149281108861],
              [-78.60826154045048, -2.988317086810582],
              [-78.61003163076472, -2.988781108970841],
              [-78.61214454091916, -2.988659236098556],
              [-78.61386148996722, -2.988085243368573],
              [-78.61534860722999, -2.986651099733876],
              [-78.6174085437563, -2.986709102503937],
              [-78.61912549280436, -2.987283262871983],
              [-78.62129959085203, -2.98693524625179],
              [-78.62239862599742, -2.98823896747291],
              [-78.62192555138175, -2.991473711549418],
              [-78.62192555138175, -2.993434573980096],
              [-78.62284051993323, -2.99521774006331],
              [-78.62467163050265, -2.994751538608227],
              [-78.62656359368917, -2.993490565093339],
              [-78.62792162964293, -2.993511687489388],
              [-78.62844851607703, -2.995844538783331],
              [-78.6285095363322, -2.998203541615226],
              [-78.62982951844549, -2.999806329141961],
              [-78.63147756824938, -3.000623061788133],
              [-78.63279755036268, -3.002406898423601],
              [-78.63251457731121, -3.004359043674924],
              [-78.6311416215698, -3.005566875923648],
              [-78.62741854781386, -3.006778899123958],
              [-78.62553362542602, -3.007985557906238],
              [-78.62399252870674, -3.009247704887571],
              [-78.62433652201338, -3.011436555084799],
              [-78.62530563765944, -3.013616352826659],
              [-78.62553362542602, -3.016094211045697],
              [-78.62685360753932, -3.018220867521336],
              [-78.62873852992716, -3.019941839882904],
              [-78.63011165330664, -3.021499700408583],
              [-78.6346895135492, -3.023796341880768],
              [-78.63571964945042, -3.02621217401628],
              [-78.63589449595082, -3.028978202066753],
              [-78.6372676193303, -3.032713848677417],
              [-78.63898456837836, -3.033459670422701],
              [-78.63841258730514, -3.035590517849926],
              [-78.63635265077883, -3.039898145533869],
              [-78.63343859831814, -3.044211975826158],
              [-78.63315562526667, -3.046633842931897],
              [-78.63372760633989, -3.049337509622717],
              [-78.6344145871248, -3.051345310711213],
              [-78.63601653646117, -3.05364513730666],
              [-78.63806959982688, -3.054743166623666],
              [-78.64053354408664, -3.055083304254822],
              [-78.64179250594475, -3.056504875034705],
              [-78.6384206339322, -3.061586822934714],
              [-78.63533056150466, -3.066704310104228],
              [-78.63520852099431, -3.067716173456517],
              [-78.63653554390629, -3.069526999820198],
              [-78.63847360756031, -3.069178983200061],
              [-78.64099153127657, -3.069003969061555],
              [-78.64311265569611, -3.0700931135612],
              [-78.64425661784257, -3.073313776040322],
              [-78.64362361651416, -3.075562640664373],
              [-78.6383666544757, -3.081769272333531],
              [-78.63590958337656, -3.083498961874398],
              [-78.63407864044521, -3.085167798798182],
              [-78.6322475298758, -3.087178785009939],
              [-78.62847165012981, -3.090578652579097],
              [-78.62549557158556, -3.092297445645897],
              [-78.62287153297501, -3.093449286781322],
              [-78.62063557648185, -3.095116112048345],
              [-78.61801153787131, -3.097766805111007],
              [-78.61269355557766, -3.102599642848418],
              [-78.60931363693804, -3.104264456458679],
              [-78.60411065435608, -3.104957472213869],
              [-78.60079963496064, -3.107318319064461],
              [-78.59748056893812, -3.108178302331055],
              [-78.59319255490765, -3.108412157429882],
              [-78.58946964878977, -3.108069169951591],
              [-78.58586861554419, -3.107030149233026],
              [-78.58032264548396, -3.105879481563989],
              [-78.58049061882375, -3.107777144444697],
              [-78.58375553775168, -3.111056815522261],
              [-78.58455651241988, -3.113354798098953],
              [-78.58615862939432, -3.114966805719178],
              [-78.5899276359797, -3.117099664803163],
              [-78.59159060557126, -3.118531461504915],
              [-78.59519163881684, -3.122615962926147],
              [-78.59633660679168, -3.124516978568124],
              [-78.59702258174819, -3.126524947294683],
              [-78.59811357026652, -3.128768615138767],
              [-78.5982285699782, -3.131354432270825],
              [-78.59788558249994, -3.134752288183222],
              [-78.59679459398161, -3.139349929717298],
              [-78.59159060557126, -3.145276270544002],
              [-78.58947752777877, -3.148037101814509],
              [-78.58604463551103, -3.151951953515265],
              [-78.58250462252062, -3.154997601855939],
              [-78.57878154876468, -3.157818615191275],
              [-78.5710064953719, -3.162247109919122],
              [-78.57038154067055, -3.165525942806312],
              [-78.57112149508357, -3.168573602803747],
              [-78.57221265123997, -3.172083273304622],
              [-78.5736385806095, -3.174897078203287],
              [-78.57546952354085, -3.176447059739928],
              [-78.5778126007567, -3.176507074166693],
              [-78.57998653116631, -3.175816237706329],
              [-78.58267963665908, -3.175642061758197],
              [-78.58667763683943, -3.176221083630082],
              [-78.59062953655227, -3.178462907455469],
              [-78.59423056979786, -3.182031754192849],
              [-78.59769464274542, -3.183965626895315],
              [-78.5993805787517, -3.183634541719528],
              [-78.60041054701486, -3.181793708142436],
              [-78.59989153956975, -3.17823106401346],
              [-78.59783160304345, -3.168220892317834],
              [-78.59696960812008, -3.162127248703598],
              [-78.59708360200337, -3.157120402656119],
              [-78.59748861556518, -3.15263792847179],
              [-78.59874757742331, -3.147001266219092],
              [-78.5997694990594, -3.140965625374918],
              [-78.60091362884393, -3.136476110391868],
              [-78.60205859681875, -3.132565282004634],
              [-78.6058346442028, -3.123933095192342],
              [-78.60811653352542, -3.11962747916516],
              [-78.61618059493996, -3.111050109999724],
              [-78.61824053146627, -3.109843115941317],
              [-78.62121560418214, -3.108982126846342],
              [-78.62442050868329, -3.107200301867636],
              [-78.62756355473894, -3.106330427955299],
              [-78.63196556265271, -3.105644285360711],
              [-78.63522360842005, -3.103111609494817],
              [-78.63848165418737, -3.102874569272842],
              [-78.63796951990287, -3.106100428531931],
              [-78.63671156387313, -3.10886427728758],
              [-78.6348796151134, -3.110937960135118],
              [-78.62996664638158, -3.115419763767193],
              [-78.62922652433049, -3.117605428841273],
              [-78.6299135051154, -3.122722077820413],
              [-78.62870751688538, -3.126353788831693],
              [-78.62928050378699, -3.129861615313871],
              [-78.63197360927977, -3.132100254016052],
              [-78.63449052716763, -3.13192557515373],
              [-78.63660461078851, -3.132617417442475],
              [-78.63843555371986, -3.132440391647208],
              [-78.64244059469888, -3.130949586347072],
              [-78.64506563913781, -3.130602407917195],
              [-78.64655258876252, -3.133191410172458],
              [-78.64530150589339, -3.134914226552723],
              [-78.6445005312252, -3.137216902995249],
              [-78.64260856803868, -3.140494730054058],
              [-78.6382065601249, -3.146883249022267],
              [-78.63706159215006, -3.149412069212644],
              [-78.63632163773704, -3.152284882709694],
              [-78.63620764385375, -3.155619706710183],
              [-78.63832860063525, -3.156943544498915],
              [-78.64101449769129, -3.15619973441045],
              [-78.64347056296204, -3.156196549287245],
              [-78.64610264819964, -3.156428728005437],
              [-78.64901753885066, -3.15573872973539],
              [-78.6506196558251, -3.151421881957958],
              [-78.65222160516149, -3.148605059574152],
              [-78.65485352276102, -3.148657195011992],
              [-78.65782960130527, -3.149350043129118],
              [-78.6605755127881, -3.148425351569927],
              [-78.6625745966973, -3.148484192530304],
              [-78.66286461054744, -3.1504458931513],
              [-78.66131563483917, -3.152223862454491],
              [-78.66034651919313, -3.156198560944006],
              [-78.65651649235258, -3.164478037270385],
              [-78.65542550383424, -3.167639691151066],
              [-78.65319859979653, -3.170689530443326],
              [-78.64953654629576, -3.172931354268712],
              [-78.64547752586024, -3.175815231877948],
              [-78.64524853226527, -3.17851705455007],
              [-78.64743050930194, -3.180139791006297],
              [-78.6499935276573, -3.177596889218592],
              [-78.65125248951543, -3.175521027076229],
              [-78.65365558115806, -3.172760531081849],
              [-78.65599849073585, -3.171039391082218],
              [-78.65782960130527, -3.170112687866265],
              [-78.65926357730186, -3.168099857635866],
              [-78.66086552663823, -3.164940047773825],
              [-78.66177362202912, -3.162402007489959],
              [-78.66349057107716, -3.161656185744675],
              [-78.66606850922021, -3.160911202189766],
              [-78.66772460565116, -3.158610537404002],
              [-78.66921256110425, -3.157285526148826],
              [-78.6723556071599, -3.158259335660659],
              [-78.6743625700581, -3.157224505893623],
              [-78.68128149585974, -3.151591028764187],
              [-78.68311361225753, -3.151992019012482],
              [-78.68425757440399, -3.153720702724968],
              [-78.68665362524794, -3.154232669371424],
              [-78.68877458202944, -3.15446384226118],
              [-78.69008651751568, -3.156590666374939],
              [-78.68877458202944, -3.159353341664143],
              [-78.69088749218388, -3.161654174087914],
              [-78.69078053909925, -3.16556801996029],
              [-78.68940758335783, -3.168213851519113],
              [-78.68728662657635, -3.170685674767867],
              [-78.68757664042649, -3.174193333611981],
              [-78.68837761509468, -3.176490980912604],
              [-78.69078053909925, -3.175574335980457],
              [-78.69169651347912, -3.169592674592764],
              [-78.69374857101644, -3.167346827453855],
              [-78.69420655820637, -3.163432981581479],
              [-78.69403858486658, -3.158714473003499],
              [-78.6954655200645, -3.156358487656746],
              [-78.6975246184005, -3.156299479058362],
              [-78.69866958637533, -3.15900532504395],
              [-78.69992854823344, -3.160671312120655],
              [-78.70056154956185, -3.162977173686386],
              [-78.70072952290164, -3.167171645677342],
              [-78.70393358921247, -3.174708150105516],
              [-78.70725265523497, -3.174707144277136],
              [-78.70868663123156, -3.17642794900064],
              [-78.70925861230478, -3.181147631045064],
              [-78.71063257387459, -3.185516446622273],
              [-78.71223452321097, -3.189081102408011],
              [-78.71422556049312, -3.189583010770662],
              [-78.71503458178836, -3.188970293647969],
              [-78.71549256897829, -3.186666443739],
              [-78.7153245956385, -3.184026647150461],
              [-78.71360764659045, -3.180515970821205],
              [-78.71142550191571, -3.174300286696621],
              [-78.70908359816632, -3.171719331068459],
              [-78.7078246363082, -3.16970046586772],
              [-78.70977057895122, -3.168900832304018],
              [-78.70925157150612, -3.167109787231823],
              [-78.70764962216973, -3.163952156664664],
              [-78.70570351188864, -3.161307330934221],
              [-78.70542154466555, -3.159237503762142],
              [-78.70565053826053, -3.157276641331464],
              [-78.70484956359233, -3.15538551633523],
              [-78.70370459561751, -3.153827655809607],
              [-78.70370459561751, -3.151070847852623],
              [-78.70461252337032, -3.148940168063461],
              [-78.70410156255227, -3.146579321212869],
              [-78.70249961321588, -3.142960685970593],
              [-78.70220959936574, -3.13990481170805],
              [-78.70301057403393, -3.136688004904386],
              [-78.70466650282681, -3.137495852733139],
              [-78.70644363393973, -3.136569149517243],
              [-78.70764157554268, -3.134900144955395],
              [-78.71015949925892, -3.136396985225815],
              [-78.71324957168646, -3.136394135378737],
              [-78.71473652131117, -3.138584997232783],
              [-78.7165066116254, -3.13933098661613],
              [-78.71833755455675, -3.139677997407887],
              [-78.7206266523161, -3.142087794573058],
              [-78.72228962190765, -3.144676461552194],
              [-78.72520451255866, -3.145197313016013],
              [-78.72634864234318, -3.146746456362337],
              [-78.72846959912467, -3.146054278797465],
              [-78.7317884975091, -3.146631456650653],
              [-78.73441354194803, -3.147604260334106],
              [-78.73710664744081, -3.146634306497731],
              [-78.73905158425545, -3.147090449668951],
              [-78.74191249544995, -3.151119127614948],
              [-78.74425456683743, -3.152606747791936],
              [-78.74637552361891, -3.151806778952107],
              [-78.74860360112308, -3.150311112148074],
              [-78.74988552939593, -3.148569352666641],
              [-78.74533851455708, -3.143750596526559],
              [-78.74287191342414, -3.140585919469102],
              [-78.74353672430695, -3.139754672670631],
              [-78.75846648049497, -3.132781493133714],
              [-78.76229955277013, -3.140025042194788],
              [-78.77250504688864, -3.139620603575579],
              [-78.7810011342751, -3.147024336869504],
              [-78.77701458614139, -3.155923726456416],
              [-78.77999477160809, -3.162576558112353],
              [-78.7790376210292, -3.162896204501635],
              [-78.77944162876264, -3.164741061392249],
              [-78.77841149286141, -3.166402019327052],
              [-78.7765195296749, -3.165829032425449],
              [-78.7755505816669, -3.163936901600835],
              [-78.77383463844724, -3.16278506046541],
              [-78.77171351402768, -3.16307004517364],
              [-78.76982859163984, -3.16215323260343],
              [-78.76971459775655, -3.164105880769],
              [-78.77080558627488, -3.165716882560844],
              [-78.77383463844724, -3.168769571700182],
              [-78.77503962084886, -3.170435726414951],
              [-78.77561160192208, -3.172443527503447],
              [-78.77698556349188, -3.174290228412815],
              [-78.77790053204336, -3.175956215489521],
              [-78.77812952563833, -3.178316056511733],
              [-78.77886964768942, -3.180269878143747],
              [-78.77996063620775, -3.181880544659407],
              [-78.78070059062077, -3.183889519214404],
              [-78.77898364157271, -3.184817395896744],
              [-78.77704658374705, -3.184812534392904],
              [-78.77538260832712, -3.185731358619876],
              [-78.7752156408157, -3.187801353430018],
              [-78.77629858270699, -3.189756180890413],
              [-78.77790053204336, -3.190626892993066],
              [-78.77881650642323, -3.192636035186126],
              [-78.78219558687252, -3.195454701588574],
              [-78.7839655095487, -3.196606710362062],
              [-78.78602561371308, -3.19740667920189],
              [-78.78762856887785, -3.198385350217563],
              [-78.78860455768451, -3.200114201568113],
              [-78.78757458942134, -3.201947156156223],
              [-78.78717058168792, -3.203791174856576],
              [-78.78654462115819, -3.20551583525554],
              [-78.78443154336568, -3.206271044732375],
              [-78.78454553724897, -3.208449669007734],
              [-78.78345454873065, -3.210408687419715],
              [-78.7816236057993, -3.210350684649711],
              [-78.77968554214527, -3.211159538306902],
              [-78.77825156614868, -3.21271136386224],
              [-78.7781375722654, -3.214899375869209],
              [-78.77945755437868, -3.216510042384868],
              [-78.78145563245951, -3.21713902039977],
              [-78.78380558283597, -3.217424005108001],
              [-78.78540753217234, -3.218457996684663],
              [-78.78740661608155, -3.218634016651549],
              [-78.79175565036721, -3.218228164899415],
              [-78.7912366429221, -3.220468815258357],
              [-78.79015353339277, -3.221966828995221],
              [-78.79164165648392, -3.223235681499148],
              [-78.79307563248051, -3.225019518134559],
              [-78.79152665677225, -3.226281329839765],
              [-78.78969554620284, -3.225934319048008],
              [-78.78781162964337, -3.225939348189911],
              [-78.78598051907396, -3.229334186617166],
              [-78.7840955966861, -3.229275345656845],
              [-78.78254662097784, -3.227780181767002],
              [-78.78060151652514, -3.227378185690327],
              [-78.7786555738821, -3.227663170398557],
              [-78.77522251397632, -3.230079170172132],
              [-78.77333859741685, -3.229794017825839],
              [-78.7730485835667, -3.227958381028714],
              [-78.77322359770518, -3.225771374850126],
              [-78.77288061022692, -3.223872538502974],
              [-78.77127849325247, -3.222494721257704],
              [-78.76938653006594, -3.221857696615814],
              [-78.76721159382795, -3.221572879545647],
              [-78.76480850218532, -3.22099771334922],
              [-78.76280958591418, -3.220821861020397],
              [-78.7612076365778, -3.221921566718038],
              [-78.76069650812168, -3.223935738053001],
              [-78.75989553345349, -3.225597366540057],
              [-78.75703462225898, -3.22835735962019],
              [-78.75559964043401, -3.230026028905911],
              [-78.75520351168957, -3.231869041777884],
              [-78.75588965428416, -3.234058730165486],
              [-78.75737760973725, -3.235209733110594],
              [-78.7592085526686, -3.235611561549206],
              [-78.76092550171666, -3.236582688852025],
              [-78.76241261897943, -3.240436352659515],
              [-78.76550252376889, -3.243146054320619],
              [-78.76579253761903, -3.245036173488415],
              [-78.76544955014077, -3.246942888824549],
              [-78.76435856162244, -3.248494714379888],
              [-78.76178749664001, -3.249121848376092],
              [-78.76029954118692, -3.250565715018411],
              [-78.76093254251532, -3.254589363822504],
              [-78.75990257425217, -3.256141021739779],
              [-78.75841562462746, -3.257413227004974],
              [-78.7573856563643, -3.25896488492225],
              [-78.75807163132082, -3.260855842280364],
              [-78.75732463610913, -3.262644540419672],
              [-78.75372360286354, -3.264597523861369],
              [-78.75360860315186, -3.266730550583475],
              [-78.75486756500999, -3.268053214905763],
              [-78.75658451405805, -3.268744386642254],
              [-78.75772864384255, -3.270238041789526],
              [-78.75772864384255, -3.272245004687704],
              [-78.75910159958397, -3.27380303285139],
              [-78.76133755607714, -3.274151720023838],
              [-78.76293950541353, -3.275293838151583],
              [-78.76305349929682, -3.277192674498735],
              [-78.76219150437345, -3.279034513904207],
              [-78.76202353103365, -3.281565345751346],
              [-78.76316849900849, -3.283231165189989],
              [-78.76482358961105, -3.28455416478846],
              [-78.76470959572777, -3.286515865409456],
              [-78.76288552595702, -3.287840709026568],
              [-78.7623135448838, -3.293470498118666],
              [-78.76236752434029, -3.295712321944052],
              [-78.76403049393186, -3.296575322695787],
              [-78.765518617023, -3.295367155170936],
              [-78.76683055250925, -3.293815329615597],
              [-78.76694454639254, -3.291980363370726],
              [-78.76677657305275, -3.290018830387794],
              [-78.76625857143603, -3.288010694023171],
              [-78.76614357172436, -3.286048993402119],
              [-78.76774552106073, -3.285013157806759],
              [-78.76860064282349, -3.283180203218649],
              [-78.7673415133273, -3.281450178401599],
              [-78.76654053865911, -3.279496356769585],
              [-78.7680284941122, -3.278116695505616],
              [-78.76837164922853, -3.276272844443383],
              [-78.76797451465569, -3.274256996727843],
              [-78.76659351228723, -3.270177021534323],
              [-78.76659351228723, -3.267817180512111],
              [-78.76785264178342, -3.266211543138354],
              [-78.7694545911198, -3.26517537226681],
              [-78.77071355297792, -3.263388685784264],
              [-78.77254449590927, -3.263211659989054],
              [-78.77408559262854, -3.261777683992477],
              [-78.77442958593518, -3.259644824908435],
              [-78.77609255552674, -3.258266001834841],
              [-78.77946459517736, -3.26027983789362],
              [-78.78141053782038, -3.259822856532082],
              [-78.78255449996684, -3.258036002411473],
              [-78.78450061024792, -3.257804997159724],
              [-78.78861964511023, -3.25763316814448],
              [-78.79045058804158, -3.25688717876119],
              [-78.7917635293562, -3.255218174199342],
              [-78.7937086338089, -3.254236318060407],
              [-78.79491462203892, -3.256192318987303],
              [-78.79640157166362, -3.257342986656283],
              [-78.79834751430664, -3.257401995254668],
              [-78.80006463099276, -3.258382007374848],
              [-78.80068958569412, -3.260217811810037],
              [-78.7986905017849, -3.260909989374909],
              [-78.7986905017849, -3.262808825722061],
              [-78.79988861102592, -3.264420498066158],
              [-78.80120859313922, -3.265796303654668],
              [-78.8021775411472, -3.267354331818353],
              [-78.80172760058433, -3.27132115131883],
              [-78.80321455020903, -3.272752948020639],
              [-78.80429849792868, -3.274535946465733],
              [-78.80292554218727, -3.276205621579834],
              [-78.80097959954425, -3.277131654243533],
              [-78.80138360727769, -3.279085643513611],
              [-78.80120859313922, -3.281101491229208],
              [-78.79955249670827, -3.281902298259297],
              [-78.79863752815679, -3.283798284759371],
              [-78.79955249670827, -3.285933993690435],
              [-78.80133063364957, -3.286967985267154],
              [-78.80264256913581, -3.288398776140525],
              [-78.80298656244244, -3.290189485936594],
              [-78.80435951818386, -3.29179361456778],
              [-78.80619062875327, -3.291742484958377],
              [-78.8080825919398, -3.292831461819958],
              [-78.8079685980565, -3.296682108142363],
              [-78.80916553383108, -3.298529144327802],
              [-78.8110574970176, -3.302148785398458],
              [-78.81282758733184, -3.302722778128441],
              [-78.81460555663507, -3.302201759026559],
              [-78.81546051075976, -3.298352956722908],
              [-78.81694762802253, -3.296856284090495],
              [-78.81877857095388, -3.295992277510379],
              [-78.81983955225881, -3.294575232958209],
              [-78.81780962294589, -3.294155299608747],
              [-78.81568950435472, -3.294267449473296],
              [-78.81403357556184, -3.293459266368359],
              [-78.81534551104808, -3.291789591254258],
              [-78.81717662161749, -3.291043434232904],
              [-78.81843558347562, -3.289202768293876],
              [-78.81843558347562, -3.28719580539564],
              [-78.81803157574218, -3.28535111614309],
              [-78.8170626277342, -3.283568117697939],
              [-78.8165436202891, -3.281731139796307],
              [-78.81716958081883, -3.279653433635247],
              [-78.81716958081883, -3.277818467390375],
              [-78.8165436202891, -3.276090621868207],
              [-78.8152844907929, -3.274595960892555],
              [-78.81448351612472, -3.272469807331106],
              [-78.81533863788748, -3.27045597127227],
              [-78.81716958081883, -3.271255940112098],
              [-78.81883255041038, -3.270509280176498],
              [-78.8207166346079, -3.271200116636862],
              [-78.8213506417647, -3.273271787827639],
              [-78.82163260898777, -3.275459799834607],
              [-78.82260859779443, -3.277007602076424],
              [-78.82443954072578, -3.278277460408674],
              [-78.82558450870062, -3.27994344748538],
              [-78.82604249589055, -3.281779419558632],
              [-78.82723959930317, -3.2835632561941],
              [-78.82895654835123, -3.284597080132755],
              [-78.83004753686957, -3.283045086939296],
              [-78.8298185432746, -3.280865456835556],
              [-78.82878857501144, -3.275104742415749],
              [-78.83072663866547, -3.274186756379152],
              [-78.83227561437374, -3.275220915593877],
              [-78.8341595309332, -3.274935930885647],
              [-78.83564765402436, -3.276023566642721],
              [-78.83490753197327, -3.277694582861329],
              [-78.83473955863349, -3.279764577671472],
              [-78.83559451275818, -3.281610272752403],
              [-78.83696763613766, -3.283276259829108],
              [-78.8361125143749, -3.285000920228129],
              [-78.83496855222845, -3.286670930618357],
              [-78.84034755477727, -3.28989259892586],
              [-78.84085851559531, -3.291791602911019],
              [-78.83920258680244, -3.29317143181305],
              [-78.8407515625107, -3.297197092273905],
              [-78.84057654837224, -3.299439083737354],
              [-78.84240749130359, -3.299895059270568],
              [-78.84338364774831, -3.301505725786228],
              [-78.84338364774831, -3.303639758336715],
              [-78.84446759546796, -3.305422421505682],
              [-78.84629853839931, -3.305878397038896],
              [-78.84767149414073, -3.307255376093792],
              [-78.84710655386617, -3.309270385619072],
              [-78.84779353465107, -3.311685044288083],
              [-78.84722155357785, -3.313410878153547],
              [-78.84738952691764, -3.315598722522452],
              [-78.84876265029712, -3.317265715427482],
              [-78.8509976009619, -3.31802058962819],
              [-78.85168458174681, -3.319802582244961],
              [-78.85265352975479, -3.321694377793392],
              [-78.85465965446267, -3.32221422342883],
              [-78.85614760991575, -3.320834394526798],
              [-78.85694858458395, -3.318937402198401],
              [-78.85803253230361, -3.317032530880965],
              [-78.85992449549013, -3.317840713985902],
              [-78.8614655922094, -3.321523219330402],
              [-78.86269354102575, -3.322801291927817],
              [-78.8643265034039, -3.321059364808264],
              [-78.86501365182687, -3.319108560661391],
              [-78.8670725825248, -3.319339398275019],
              [-78.86901852516783, -3.320021517556086],
              [-78.87091065599242, -3.319564536194548],
              [-78.8739395405267, -3.31714853642103],
              [-78.87577065109612, -3.317260686285579],
              [-78.87696859269907, -3.318872526267739],
              [-78.87737260043251, -3.320943359268199],
              [-78.87674764573116, -3.324974048870956],
              [-78.87812060147257, -3.326695021232524],
              [-78.87794458150573, -3.328763674938159],
              [-78.87628949090316, -3.329628854984719],
              [-78.87569454341522, -3.331172633913013],
              [-78.87789160787761, -3.332624547182434],
              [-78.87978357106414, -3.332854378967738],
              [-78.88201164856831, -3.333600535989092],
              [-78.88321663096993, -3.335094191136363],
              [-78.88510155335779, -3.335379343482657],
              [-78.8864745090992, -3.33681901917339],
              [-78.8864745090992, -3.338772170253151],
              [-78.88750464500042, -3.340328857312329],
              [-78.88590252802597, -3.341419678192608],
              [-78.88378861204315, -3.341016843925615],
              [-78.8821256424516, -3.342170696717858],
              [-78.88035555213736, -3.341135699312758],
              [-78.87834959506755, -3.340209834287123],
              [-78.87400056078188, -3.33952302114028],
              [-78.87211664422242, -3.339464012541896],
              [-78.8725126053288, -3.341426886629336],
              [-78.87406158103705, -3.342632874859362],
              [-78.87606854393526, -3.34349554033497],
              [-78.8750385756721, -3.345048706994817],
              [-78.87062852113127, -3.345626555400258],
              [-78.87005654005804, -3.347577359547131],
              [-78.86914056567818, -3.349302187584215],
              [-78.86702765552374, -3.348845206222677],
              [-78.86519654495433, -3.347639217992651],
              [-78.86284659457787, -3.347064387072351],
              [-78.86135863912479, -3.348155375590693],
              [-78.8606185170737, -3.350224029296271],
              [-78.8586115541755, -3.350174240791375],
              [-78.8577576058792, -3.348563238999589],
              [-78.85597963657597, -3.347293548305345],
              [-78.85501052092991, -3.348846212051058],
              [-78.8548435534185, -3.350980076963424],
              [-78.8535235713052, -3.35270289334369],
              [-78.85449956011186, -3.354540709435696],
              [-78.85466753345166, -3.356782868537209],
              [-78.85592649530979, -3.35828674960635],
              [-78.85655949663818, -3.360294383056782],
              [-78.85655949663818, -3.362428247969149],
              [-78.85810059335745, -3.363860212309021],
              [-78.86038952347873, -3.363403063309363],
              [-78.86251064789829, -3.363914024127439],
              [-78.86439557028613, -3.363747224254098],
              [-78.86582954628273, -3.364952206655687],
              [-78.86685951454588, -3.366962019401001],
              [-78.86806449694751, -3.368464894641761],
              [-78.8700106072286, -3.369210716386988],
              [-78.8717195096496, -3.368517700631799],
              [-78.87378665461264, -3.368404880214939],
              [-78.87538960977741, -3.369438871791658],
              [-78.87733454659205, -3.369777668318306],
              [-78.87944762438455, -3.366500847087821],
              [-78.88076760649786, -3.365627117500026],
              [-78.88208054781248, -3.367708846974608],
              [-78.8824845555459, -3.369897697171893],
              [-78.88391149074383, -3.371102511935476],
              [-78.8882595192011, -3.371853530460669],
              [-78.89054861696044, -3.372771516497323],
              [-78.8915785852236, -3.374383356479427],
              [-78.89020562948218, -3.375646174013013],
              [-78.88848851279607, -3.376682344884557],
              [-78.8888325061027, -3.378753177885017],
              [-78.88997663588722, -3.379017040197198],
              [-78.89305849404964, -3.377731088610801],
              [-78.89484350415154, -3.377717007013473],
              [-78.89524063872436, -3.379842825298795],
              [-78.89798755603557, -3.381104804642064],
              [-78.89965052562714, -3.383232634584203],
              [-78.8983385901409, -3.385309502554946],
              [-78.89627865361457, -3.385304473413044],
              [-78.89215861292388, -3.389626182694315],
              [-78.88918253437964, -3.393244985574654],
              [-78.88891565458228, -3.39491683998358],
              [-78.89158663185066, -3.395663835195251],
              [-78.8940505761104, -3.397504836410405],
              [-78.89604949238155, -3.396929670213979],
              [-78.89822359042923, -3.398082684815904],
              [-78.90125264260158, -3.397447839468782],
              [-78.902625598343, -3.39589584627538],
              [-78.9060055169826, -3.397901803345178],
              [-78.90686751190597, -3.399855624977192],
              [-78.90863760222021, -3.402617294438016],
              [-78.91127052564813, -3.402731120683256],
              [-78.9116135131264, -3.404521998117389],
              [-78.91270450164473, -3.406015988540787],
              [-78.91453561221414, -3.405324146252042],
              [-78.91716752981368, -3.402563314981535],
              [-78.91911364009476, -3.4017532878579],
              [-78.91968562116799, -3.399802483711028],
              [-78.92128757050438, -3.398937471302531],
              [-78.92174555769431, -3.396343775181492],
              [-78.92356862163666, -3.395543973979727],
              [-78.9260325658964, -3.395421933469379],
              [-78.92500259763325, -3.393584955567746],
              [-78.92259950599062, -3.391862139187481],
              [-78.92448459601653, -3.390655312767137],
              [-78.92780265621064, -3.39099628858861],
              [-78.92780265621064, -3.388863429504624],
              [-78.927574500806, -3.386566452756313],
              [-78.92768849468929, -3.384206611734101],
              [-78.92986259273697, -3.383804615657425],
              [-78.93123655430676, -3.387026451602992],
              [-78.93129757456194, -3.389612268734993],
              [-78.93329649083307, -3.391162082633628],
              [-78.93507362194599, -3.391571957699284],
              [-78.93633258380412, -3.393528126264187],
              [-78.93770553954553, -3.39507676669632],
              [-78.94045262449481, -3.395995926199362],
              [-78.9397656437099, -3.397892583251689],
              [-78.93759154566224, -3.398123756141501],
              [-78.93742357232244, -3.400138430390598],
              [-78.93936951496546, -3.400486447010792],
              [-78.94205457383119, -3.402607403792274],
              [-78.94491565266377, -3.402957432069172],
              [-78.9452596459704, -3.40508224452617],
              [-78.94451851809093, -3.407214935972092],
              [-78.94526651913101, -3.408879917220418],
              [-78.94469453805779, -3.411066588122878],
              [-78.9447475116859, -3.414058424645077],
              [-78.94641165474391, -3.416132442768742],
              [-78.94841761181372, -3.417393081007503],
              [-78.94892857263177, -3.419347070277581],
              [-78.94847058544184, -3.421597778920386],
              [-78.95059154222332, -3.422975931441727],
              [-78.95339160080073, -3.423605747646945],
              [-78.95436859543577, -3.425678424666103],
              [-78.95608554448383, -3.428267259283245],
              [-78.95803064893653, -3.427404426169574],
              [-78.96020558517452, -3.426079582552461],
              [-78.96244053583929, -3.426708560567306],
              [-78.96198254864936, -3.428669422997984],
              [-78.96186855476607, -3.430965226279909],
              [-78.96363864508031, -3.433157093962336],
              [-78.9637605179526, -3.440922591985441],
              [-78.96518661496019, -3.442065213027433],
              [-78.96707958397509, -3.443045225147614],
              [-78.9687345069396, -3.444143086826557],
              [-78.9726865742905, -3.449828699393777],
              [-78.97486151052848, -3.451560735867588],
              [-78.97646362750294, -3.453227561134611],
              [-78.97858458428442, -3.454949539324559],
              [-78.97995754002584, -3.456733208321964],
              [-78.9787595984229, -3.459723033187458],
              [-78.98001856028101, -3.461795877844622],
              [-78.97910359172953, -3.463466894063288],
              [-78.9786456045396, -3.466747570969176],
              [-78.98024755387598, -3.467953559199202],
              [-78.9814525362776, -3.471572194441478],
              [-78.97985058694123, -3.472608030036838],
              [-78.9785916250831, -3.4747398832925],
              [-78.98076656132109, -3.474390860843926],
              [-78.98362764015366, -3.471975196346534],
              [-78.98654152497629, -3.472324218795052],
              [-78.98997458488208, -3.470650352729365],
              [-78.9919205275251, -3.471169192536422],
              [-78.99541460768607, -3.467832356879171],
              [-78.99375163809451, -3.467087373324262],
              [-78.99129456699538, -3.46702735889744],
              [-78.98854865551255, -3.466108534670468],
              [-78.98768649295111, -3.464208860133056],
              [-78.98567953005292, -3.464556709115129],
              [-78.98356661989848, -3.462888710381662],
              [-78.98333762630352, -3.460474051712652],
              [-78.98545053645795, -3.458743188705284],
              [-78.98442056819479, -3.456679228865482],
              [-78.98682349219936, -3.456214368514907],
              [-78.98885358915035, -3.45746628957437],
              [-78.98986059099879, -3.457713052804081],
              [-78.9912875261967, -3.456558194183515],
              [-78.99062351182653, -3.455029502680929],
              [-78.99226351500336, -3.453450352121195],
              [-78.99398063168948, -3.452242519872527],
              [-78.99394257784903, -3.453607428986857],
              [-78.99476651893194, -3.454893380573253],
              [-78.99626956181076, -3.455638196490099],
              [-78.99761955113746, -3.455921337179632],
              [-78.9986726534534, -3.455869369379911],
              [-78.99898563371826, -3.453130666333834],
              [-78.99993161531152, -3.452372439371857],
              [-79.00004560919481, -3.44976080597803],
              [-78.99747079870728, -3.447235836599251],
              [-79.00649939174326, -3.440834423274074],
              [-79.00929679962434, -3.41945461967132],
              [-79.01272239059784, -3.378242664144434],
              [-79.00735760823444, -3.363633836658452],
              [-79.00568678745597, -3.35808441237707],
              [-79.06123568653483, -3.386705834283356],
              [-79.0680782737314, -3.415432576902457],
              [-79.0724032822406, -3.444644062335587],
              [-79.03729841690901, -3.464404824366689],
              [-79.02758857423676, -3.495073622863587],
              [-79.0470498986077, -3.521965946498301],
              [-79.04971634858182, -3.518754737927314],
              [-79.05296506357823, -3.524048940143662],
              [-79.05243826140875, -3.577255959260542],
              [-79.0534918657477, -3.605703276412141],
              [-79.08668040242458, -3.623087748004785],
              [-79.11776173042354, -3.634677395733215],
              [-79.11792350018246, -3.634644306464345],
              [-79.12513696079617, -3.640999021766903],
              [-79.1483112917403, -3.649952740540773],
              [-79.1143357786377, -3.653505735375033],
              [-79.11951390544937, -3.657719832703371],
              [-79.16768545770582, -3.666916727260838],
              [-79.15029552384236, -3.692188134389669],
              [-79.13752100402561, -3.71594009308996],
              [-79.16205428605116, -3.720821894608282],
              [-79.2225017340745, -3.694442630344444],
              [-79.24463575916627, -3.670554062408198],
              [-79.24755765767789, -3.661325439965212],
              [-79.25681514667635, -3.656142657691593],
              [-79.26462633900549, -3.646022989823642],
              [-79.27958308290805, -3.665400365055244],
              [-79.2836577881565, -3.668545464846182],
              [-79.30251405090506, -3.693687143200001],
              [-79.30228463276605, -3.693759516384944],
              [-79.30129959150395, -3.695530444889528],
              [-79.30181155815039, -3.697375469418205],
              [-79.30178828339777, -3.697425562653264],
              [-79.30170785306382, -3.697425562652184],
              [-79.30170785306152, -3.697598669358397],
              [-79.30013249530472, -3.700989243156641],
              [-79.29994960217726, -3.703051023701676],
              [-79.29799661873557, -3.703218829403397],
              [-79.29605855508153, -3.702121135362518],
              [-79.2946165324579, -3.703547064732049],
              [-79.29501349939265, -3.705382701529174],
              [-79.29563158093337, -3.707111720517787],
              [-79.2958986283688, -3.711190522244863],
              [-79.29749253107812, -3.71212426625948],
              [-79.30356555521051, -3.711693268797774],
              [-79.305046637503, -3.71325129696146],
              [-79.30595355942742, -3.715206124421854],
              [-79.30709064841326, -3.716708832024551],
              [-79.30696056127586, -3.718716800751167],
              [-79.30620551943711, -3.72066760489804],
              [-79.30431355625059, -3.721116707270596],
              [-79.30299357413729, -3.722542469002065],
              [-79.30104863732265, -3.722475581414642],
              [-79.30029258965553, -3.724319264838812],
              [-79.29902658699872, -3.725862373214852],
              [-79.29741659103529, -3.72728813494632],
              [-79.2963715353464, -3.728832081512678],
              [-79.29631051509122, -3.73073091785983],
              [-79.29813357903357, -3.73136794250172],
              [-79.30065150274983, -3.731896840592583],
              [-79.30236861943595, -3.732885737530182],
              [-79.30051454245181, -3.736779466834889],
              [-79.299011499573, -3.738323413401247],
              [-79.29734752415305, -3.739585392744516],
              [-79.29734065099244, -3.74171003756345],
              [-79.29794364510745, -3.745671660283961],
              [-79.2979355984804, -3.747860510481246],
              [-79.29814950464963, -3.749985658214314],
              [-79.29981951503986, -3.74884069023949],
              [-79.30108652352504, -3.747188617122049],
              [-79.30138357817385, -3.745289948413017],
              [-79.30309264823292, -3.746106681059189],
              [-79.3041155756974, -3.748351522369717],
              [-79.30566354557727, -3.747323733401402],
              [-79.30728158816778, -3.745436966994816],
              [-79.30894455775933, -3.744174987651547],
              [-79.31108060196655, -3.740968239131689],
              [-79.31297256515307, -3.739941120715628],
              [-79.31418660001013, -3.738515358984159],
              [-79.31743659915041, -3.741170242998408],
              [-79.31753550560798, -3.743069917535877],
              [-79.31706963942905, -3.744904716142685],
              [-79.31809256689353, -3.746922575515043],
              [-79.3175044925662, -3.749562372103583],
              [-79.31852758766874, -3.751227520989971],
              [-79.31932051570988, -3.753020410080865],
              [-79.3201905572603, -3.751294240939274],
              [-79.31990859003722, -3.749222402110433],
              [-79.3202205644737, -3.745208811590203],
              [-79.32154054658699, -3.743720017946771],
              [-79.32081551196164, -3.739866354139224],
              [-79.32242550792508, -3.738612253784993],
              [-79.32449349107846, -3.737983443408154],
              [-79.3235396304962, -3.734246623330989],
              [-79.32228854762707, -3.732571751436922],
              [-79.32321156280561, -3.730800990570458],
              [-79.32498953210883, -3.731383029927485],
              [-79.32437161820617, -3.7291941797302],
              [-79.32454663234464, -3.727358207656948],
              [-79.32654554861577, -3.728285246149028],
              [-79.32733965012335, -3.730067909318052],
              [-79.32922356668283, -3.730938789058769],
              [-79.32972765434026, -3.732956816069191],
              [-79.3316725911549, -3.733656872623044],
              [-79.3336795540531, -3.73326275553535],
              [-79.33406864199887, -3.735162597710882],
              [-79.33474757615672, -3.736881390777626],
              [-79.33618959878035, -3.735455461408094],
              [-79.33785256837191, -3.734599669093086],
              [-79.33940154408018, -3.733345568738798],
              [-79.34105663468274, -3.734732773715621],
              [-79.34122819965496, -3.734837473116467],
              [-79.33986307253105, -3.770237905096666],
              [-79.32770704075827, -3.788290169290214],
              [-79.32336793016933, -3.791285880035603],
              [-79.32686358826727, -3.768305102981987],
              [-79.29877559793148, -3.762303986135033],
              [-79.2703507108833, -3.782159493492003],
              [-79.27092116541755, -3.76771944471874],
              [-79.28611697627754, -3.748110715356585],
              [-79.28524752386753, -3.733525684383692],
              [-79.28703468344771, -3.703542576526931],
              [-79.27464751772679, -3.699097925112975],
              [-79.2602942784982, -3.702941935704384],
              [-79.23400093871025, -3.70645852436675],
              [-79.22223137929956, -3.723308185611268],
              [-79.2310651584682, -3.747927834331371],
              [-79.20083570532172, -3.7847713751016],
              [-79.19211011134458, -3.803782989486605],
              [-79.17956738712299, -3.804530815313813],
              [-79.16282392452558, -3.799360371574493],
              [-79.16623078673422, -3.764109431021545],
              [-79.1721788034873, -3.747532547723182],
              [-79.15655421584256, -3.739042056168101],
              [-79.12586756243422, -3.74252339071258],
              [-79.12756217934586, -3.770955762162654],
              [-79.11411585011581, -3.775780275324326],
              [-79.10001071130098, -3.76930631403366],
              [-79.08836982999178, -3.727047313704533],
              [-79.0742770300635, -3.722695644738895],
              [-79.03049129977242, -3.724864207291578],
              [-78.99473952325536, -3.717384183906254],
              [-78.9771257669617, -3.715509579004788],
              [-78.97684178317516, -3.715743934556783],
              [-78.97903429165812, -3.732501550379867],
              [-78.99088040306656, -3.786020847833063],
              [-78.99194288553466, -3.788000928796359],
              [-79.00355126778058, -3.793421387854525],
              [-79.02145370672906, -3.798015868991881],
              [-79.01077129355873, -3.81442044814667],
              [-79.00757000668986, -3.830813129188065],
              [-78.99598597173657, -3.838811385990011],
              [-78.99866285439393, -3.850752805756569],
              [-78.99176589179248, -3.852522843921307],
              [-78.99129284574558, -3.853210910898633],
              [-78.99320442785779, -3.876208415315279],
              [-78.98402670078623, -3.899586240693911],
              [-78.98281396192351, -3.905593526585843],
              [-78.98041668754483, -3.910416277207393],
              [-78.98041668748435, -3.914026289398826],
              [-78.97680667442373, -3.914026289337737],
              [-78.96971312172904, -3.938858343292057],
              [-79.00207676536864, -3.942906387297609],
              [-79.01003756682697, -3.945966436828592],
              [-79.02279710337775, -3.944379124552472],
              [-79.02895223769748, -3.922851694771847],
              [-79.03101151399973, -3.91964093445035],
              [-79.03617226357805, -3.937690995497078],
              [-79.0455496161228, -3.944511768200115],
              [-79.07136945039649, -3.929334029371753],
              [-79.09393247326065, -3.89474454278901],
              [-79.07889045879128, -3.869155408270017],
              [-79.05783234311265, -3.865490752001572],
              [-79.05462158204887, -3.861481487920793],
              [-79.0506123171072, -3.858270727500614],
              [-79.04604956395683, -3.847442894226784],
              [-79.05462158232939, -3.843830678795344],
              [-79.05805929136692, -3.839537619917323],
              [-79.06813335159765, -3.85612331696404],
              [-79.08349133077407, -3.854212989710729],
              [-79.086712447541, -3.869100764654893],
              [-79.09135408164225, -3.876997225725231],
              [-79.10315713220099, -3.874316157285588],
              [-79.09787629790526, -3.897915642429464],
              [-79.09031629047864, -3.911465526942233],
              [-79.09077084708744, -3.917122035312629],
              [-79.0745456183991, -3.935954735378563],
              [-79.05816615338415, -3.950066480771889],
              [-79.06150779496477, -3.959285849123189],
              [-79.07608115142855, -3.965788454332245],
              [-79.0778870392142, -3.97178648609506],
              [-79.08311036486415, -3.980263757244641],
              [-79.08510706534213, -3.971786486212744],
              [-79.0870462575673, -3.970115886119237],
              [-79.09753565503304, -3.946608502202247],
              [-79.13604319596796, -3.916818410735162],
              [-79.16655600305555, -3.907631652881091],
              [-79.18336821180417, -3.861232508367417],
              [-79.19980232027415, -3.865730481570634],
              [-79.19579724095328, -3.915577469961542],
              [-79.18775513471995, -3.922506207844406],
              [-79.16509141784434, -3.936701240798418],
              [-79.15008730095118, -3.94290638969942],
              [-79.1500873007274, -3.957346438475632],
              [-79.1392572615341, -3.957346438305528],
              [-79.13602292413893, -3.965573846416127],
              [-79.12999977187917, -4.002239354690428],
              [-79.12842722131212, -4.022326657623502],
              [-79.14490473637129, -4.023899428092852],
              [-79.14948754000689, -4.05087757105317],
              [-79.16476002805275, -4.057128109989281],
              [-79.16970991446455, -4.074904070994042],
              [-79.17461022497574, -4.087369013081354],
              [-79.17277943873246, -4.099757277556262],
              [-79.18164604848263, -4.106600965367215],
              [-79.1692009336009, -4.121999482760548],
              [-79.17074263640319, -4.13243203023403],
              [-79.16940893208863, -4.141457060699395],
              [-79.17065846711634, -4.149912738697457],
              [-79.16523837959754, -4.158898108317714],
              [-79.13795858770732, -4.166494468978438],
              [-79.13311358156601, -4.17833232993026],
              [-79.13564724480814, -4.184777206479256],
              [-79.13555123812581, -4.184760896182368],
              [-79.13567001679411, -4.184835132850051],
              [-79.13564724480814, -4.184777206479256],
              [-79.14801149546516, -4.187529664713124],
              [-79.16484838141032, -4.185813675307689],
              [-79.18240004266427, -4.1982830243584],
              [-79.12678922775206, -4.206566397364778],
              [-79.13143372616689, -4.219414726976727],
              [-79.13993986336583, -4.229654699454476],
              [-79.15533529986638, -4.236331812111847],
              [-79.14844930561512, -4.240899411654564],
              [-79.1340092532167, -4.247161848336975],
              [-79.13906468156257, -4.254210450113604],
              [-79.15533529940198, -4.264246390051722],
              [-79.14841162738301, -4.269809475133674],
              [-79.13772723140629, -4.268720566244858],
              [-79.13708428585603, -4.275027511366978],
              [-79.13813904230868, -4.285374567821871],
              [-79.13400925209922, -4.313099655303628],
              [-79.12602950132639, -4.324187486810143],
              [-79.14072911124633, -4.336397718740528],
              [-79.11956919909056, -4.353974494056417],
              [-79.12298663670069, -4.371588727201398],
              [-79.13256251353687, -4.379542916836421],
              [-79.12640848094765, -4.395920174200261],
              [-79.13039923727939, -4.413845964805665],
              [-79.13203722767184, -4.423038011267015],
              [-79.15219019622151, -4.421530902037672],
              [-79.18406711419334, -4.415166228988382],
              [-79.18618742404988, -4.412207975622637],
              [-79.1838011667214, -4.406526789396884],
              [-79.19735832588448, -4.403047658001284],
              [-79.19744154489744, -4.402901861075751],
              [-79.20280135261682, -4.405308305357912],
              [-79.20062747642952, -4.408597963672843],
              [-79.21756968388928, -4.403880094016031],
              [-79.23830015769418, -4.395730685725741],
              [-79.24394763362625, -4.394157915612924],
              [-79.24608314671062, -4.395632415258657],
              [-79.25116765943177, -4.41581798891132],
              [-79.25791814595618, -4.414085694351533],
              [-79.26584942259693, -4.392889211734445],
              [-79.27119745942753, -4.400635402543583],
              [-79.27430223741791, -4.444797679488153],
              [-79.2692177243065, -4.448308098979929],
              [-79.27321795539284, -4.453490881449136],
              [-79.27335574734259, -4.453416277013541],
              [-79.27019443095588, -4.459299838066601],
              [-79.26601195449153, -4.460116583857897],
              [-79.26529663512288, -4.458770120225893],
              [-79.26376358503067, -4.458078948489401],
              [-79.26272557014046, -4.456838091542181],
              [-79.2619705283017, -4.455416353124235],
              [-79.26168051445157, -4.453688339963946],
              [-79.26085657336866, -4.452565332575489],
              [-79.25978050463799, -4.451667630744566],
              [-79.25915554993665, -4.450788704376748],
              [-79.25815558888688, -4.448164833404235],
              [-79.25797252812136, -4.446563889896254],
              [-79.25714858703844, -4.445385897222877],
              [-79.25237257860464, -4.443331995666881],
              [-79.25094564340672, -4.442270343809696],
              [-79.24993864155829, -4.441780170111542],
              [-79.24851958534936, -4.440275450852084],
              [-79.24708560935277, -4.438264464640326],
              [-79.24468955850881, -4.435998668571813],
              [-79.24436953744527, -4.43798652073076],
              [-79.24394960409579, -4.438998551721113],
              [-79.24253054788686, -4.440225327071005],
              [-79.24176762705912, -4.441118335036151],
              [-79.24291963583264, -4.441980329959506],
              [-79.24398061713757, -4.440653474685632],
              [-79.24503355181544, -4.439914526101006],
              [-79.24646752781203, -4.440623299834158],
              [-79.24839754483901, -4.442861267984085],
              [-79.24993864155829, -4.443461244613957],
              [-79.25122056983113, -4.444739149573252],
              [-79.25370060734498, -4.446110764210175],
              [-79.25484456949144, -4.446989690577993],
              [-79.2556225777449, -4.448393826999222],
              [-79.25580563851042, -4.44979561648762],
              [-79.25638549857264, -4.450881408225939],
              [-79.25464658893824, -4.451791347635549],
              [-79.2532885529845, -4.45141751475353],
              [-79.25093055598099, -4.454169293568611],
              [-79.25180059753141, -4.45570837863113],
              [-79.2533185601979, -4.456173238981648],
              [-79.25672161289029, -4.456435257275189],
              [-79.25837754168317, -4.458346163562908],
              [-79.25947557100018, -4.459198938392774],
              [-79.25940650411795, -4.460645990158298],
              [-79.26068860002886, -4.461516702261008],
              [-79.25907860406542, -4.462552705494431],
              [-79.25741563447387, -4.462521860090703],
              [-79.25492050953427, -4.464242664814265],
              [-79.25093055598099, -4.464996533186536],
              [-79.25243359885981, -4.466298410388958],
              [-79.25381460122829, -4.466473424527408],
              [-79.25569952361613, -4.467318488006356],
              [-79.25684365340064, -4.468677362150459],
              [-79.25831551559962, -4.469531142808705],
              [-79.2596286245523, -4.469805063404692],
              [-79.25990254514834, -4.47099093506705],
              [-79.26141363465422, -4.47160013179041],
              [-79.26071961307063, -4.472882060063284],
              [-79.25923165761755, -4.472860937667235],
              [-79.25887257688518, -4.474631698533756],
              [-79.257705648324, -4.474321735754017],
              [-79.25685857318831, -4.473098816079585],
              [-79.25492050953427, -4.474368674411835],
              [-79.25349458016474, -4.474193995549513],
              [-79.25291455246446, -4.475097899988782],
              [-79.25180864415846, -4.474298769339327],
              [-79.25101454265088, -4.475462848053382],
              [-79.24993864155829, -4.475984705345581],
              [-79.24868051789048, -4.475665857748481],
              [-79.24895460612457, -4.472944924337128],
              [-79.24725358269257, -4.472650887173472],
              [-79.24561357951572, -4.472963867438352],
              [-79.2455445126335, -4.474021998896205],
              [-79.24444564512618, -4.474145715787188],
              [-79.24374357691555, -4.475753867731953],
              [-79.24236257454707, -4.47654578994468],
              [-79.24233256733368, -4.478109517802523],
              [-79.24039450367964, -4.478303475042196],
              [-79.23751850505941, -4.478260559697901],
              [-79.236282509616, -4.478068614114989],
              [-79.23516855468296, -4.477522616941656],
              [-79.2342225730897, -4.478867409488373],
              [-79.23419156004792, -4.480658454560569],
              [-79.23366551180413, -4.482212124134605],
              [-79.23266555075436, -4.481016194188442],
              [-79.23200254221257, -4.479549528769439],
              [-79.23120860834305, -4.478634560217927],
              [-79.2305675603876, -4.4774216988273],
              [-79.22566951144344, -4.476867655026922],
              [-79.22446452904181, -4.475597796694672],
              [-79.22285453307838, -4.475232680992008],
              [-79.22161853763498, -4.476070703672235],
              [-79.2211224966046, -4.474242945864091],
              [-79.22109953018987, -4.472994880480144],
              [-79.21942851397125, -4.47313586409166],
              [-79.21767451691113, -4.472416864436582],
              [-79.21590459423496, -4.472151158105703],
              [-79.21453063266516, -4.471650087933426],
              [-79.21393551753914, -4.473447838528159],
              [-79.21389059053809, -4.475075771764409],
              [-79.21305860282811, -4.476374799119753],
              [-79.21228059457465, -4.479574339202827],
              [-79.21112858580113, -4.48062023308205],
              [-79.21033465193162, -4.482065273190869],
              [-79.21013650374037, -4.487968982878954],
              [-79.20965555013572, -4.489567579454047],
              [-79.20940359012603, -4.491230549045611],
              [-79.20942655654075, -4.492840377370953],
              [-79.20964850933704, -4.494106212389738],
              [-79.20794664771472, -4.493189567457591],
              [-79.20700854511044, -4.491866400221056],
              [-79.20665751100513, -4.48824089181818],
              [-79.20591755659211, -4.486655873926225],
              [-79.20451358780892, -4.485631102443051],
              [-79.203086652611, -4.485997894526349],
              [-79.20219465047424, -4.485273027539051],
              [-79.20167564302913, -4.483526238915715],
              [-79.20209457055023, -4.480679409318554],
              [-79.20247661769733, -4.479712305329258],
              [-79.20008056685336, -4.479064551851252],
              [-79.19941755831157, -4.478303475042196],
              [-79.20024854019316, -4.476949630040053],
              [-79.20068356096837, -4.475865682320375],
              [-79.19918856471661, -4.475165793404585],
              [-79.19785349517757, -4.474999831721561],
              [-79.19763958900833, -4.471454957227309],
              [-79.19624349921413, -4.47042096565059],
              [-79.19608256667301, -4.46764437640212],
              [-79.19572449176903, -4.466495552751837],
              [-79.19480164422855, -4.464991504044633],
              [-79.1939465224658, -4.464067650675759],
              [-79.19348954110424, -4.462927544204774],
              [-79.1923216067147, -4.463276734291412],
              [-79.19135249106864, -4.46187276550819],
              [-79.19002563579474, -4.463171625225471],
              [-79.1886365867992, -4.463946615993791],
              [-79.18704955725049, -4.463806470572592],
              [-79.18546252770177, -4.463929684549385],
              [-79.18430364576766, -4.464306534916489],
              [-79.18325758425038, -4.464999550671678],
              [-79.17980155792988, -4.464846664757602],
              [-79.17897761684696, -4.466354569140321],
              [-79.17909261655863, -4.467629456614475],
              [-79.17831460830517, -4.468387348300269],
              [-79.17692555930964, -4.466648438665914],
              [-79.17523157667631, -4.466779363993624],
              [-79.17383565452016, -4.467499201839018],
              [-79.17346953298912, -4.466060364338546],
              [-79.17338554631922, -4.463908562153335],
              [-79.17034962098626, -4.463367761759969],
              [-79.16892955894895, -4.462640715477846],
              [-79.16799950297174, -4.461345711436024],
              [-79.16477951104486, -4.460478016818513],
              [-79.16481756488531, -4.461969827947087],
              [-79.16353563661247, -4.462093880114196],
              [-79.16049954364142, -4.460268972153131],
              [-79.16002663666383, -4.461361637052107],
              [-79.15673053705605, -4.462510628340453],
              [-79.1558375290909, -4.463186545013173],
              [-79.15631865033362, -4.466831331793344],
              [-79.15729564496866, -4.467665331160106],
              [-79.15842451968938, -4.468292129880126],
              [-79.1598735831117, -4.470935949782188],
              [-79.16073658386345, -4.473206774992605],
              [-79.15940050849603, -4.47418997223599],
              [-79.15823357993487, -4.474638906970483],
              [-79.1572114906607, -4.475784880773688],
              [-79.15740963885196, -4.476879557329426],
              [-79.15865351328435, -4.476475717234052],
              [-79.16036962414209, -4.477401749897751],
              [-79.16089651057618, -4.478895572683086],
              [-79.16203359956202, -4.479684477410672],
              [-79.1631626419208, -4.481043183916654],
              [-79.16505460510733, -4.482314215715405],
              [-79.16624450008322, -4.482643121596311],
              [-79.16873962502281, -4.481365384275023],
              [-79.17148553650564, -4.480702208095181],
              [-79.17206556420592, -4.479094391426599],
              [-79.1732324927671, -4.478419480582261],
              [-79.17380564730676, -4.482028225178794],
              [-79.17352250661723, -4.484884945421697],
              [-79.1742175340292, -4.485844840974266],
              [-79.17539250921742, -4.485847858459408],
              [-79.17683453184107, -4.484992904334717],
              [-79.18022953790641, -4.483770990488665],
              [-79.18172453415818, -4.484886957078459],
              [-79.18364751038648, -4.484822919338114],
              [-79.18001563173718, -4.489123673861457],
              [-79.18040455204488, -4.49042655689226],
              [-79.17893956300651, -4.491191489376774],
              [-79.17837562856035, -4.492338469008359],
              [-79.18155656081838, -4.493214377891036],
              [-79.18309061673898, -4.494058267903483],
              [-79.18477655274526, -4.493981489670318],
              [-79.1859436489445, -4.494391364736032],
              [-79.18609653485856, -4.495386129005794],
              [-79.18785857854574, -4.496639055893638],
              [-79.18845352603368, -4.49795115901793],
              [-79.1902385361356, -4.498723970491426],
              [-79.19263458697955, -4.499182125319408],
              [-79.1939396493052, -4.500893877587487],
              [-79.19377854912601, -4.502005820863815],
              [-79.19274154006418, -4.50235568150265],
              [-79.190368623273, -4.501427804820253],
              [-79.18917856065904, -4.501243738226378],
              [-79.18743864519627, -4.501664845042285],
              [-79.18466959966064, -4.501657971881684],
              [-79.18315163699415, -4.50250387355095],
              [-79.18253355545342, -4.504726754275168],
              [-79.18287654293168, -4.50668241992588],
              [-79.18164859411533, -4.506993556172006],
              [-79.18138154667992, -4.508105499448334],
              [-79.17971757125997, -4.509077129665286],
              [-79.17862658274164, -4.5104492472164],
              [-79.1780316352537, -4.51191188932188],
              [-79.17819960859349, -4.513576032379888],
              [-79.17866564241048, -4.514942953151035],
              [-79.17711649906416, -4.519296513664415],
              [-79.1794055968235, -4.520586320926213],
              [-79.1809765331181, -4.520599396695218],
              [-79.18218252134811, -4.520457574893328],
              [-79.18376150426977, -4.519774617421945],
              [-79.18482952637338, -4.518574664162259],
              [-79.18651563001772, -4.518343491272446],
              [-79.18785857854574, -4.517958761916361],
              [-79.18906356094736, -4.518440721349407],
              [-79.1881175793541, -4.519297519492795],
              [-79.18658452926189, -4.522096404603701],
              [-79.18607356844383, -4.52376809137462],
              [-79.18370853064165, -4.526292888251476],
              [-79.18204455522171, -4.528341760665569],
              [-79.18196056855182, -4.530448803487786],
              [-79.18325054345172, -4.531463516687154],
              [-79.18366963861088, -4.530271777692519],
              [-79.18434857276873, -4.529359826626205],
              [-79.1865766502729, -4.528868647099671],
              [-79.18798849804509, -4.52818485143797],
              [-79.19034565685827, -4.527360072164754],
              [-79.19068965016491, -4.529041649581359],
              [-79.18883557318078, -4.531233684901849],
              [-79.18970460890282, -4.533749596961343],
              [-79.19048362298467, -4.534438254126883],
              [-79.1946256242617, -4.534168356844361],
              [-79.19724262207357, -4.533208628929913],
              [-79.19840955063475, -4.532035497760376],
              [-79.19897449090931, -4.530346711906986],
              [-79.19907356500492, -4.528854733140406],
              [-79.1988826252504, -4.525509012665736],
              [-79.19977563321555, -4.525041134830076],
              [-79.20047753378812, -4.524093141580011],
              [-79.20126358866865, -4.521030394156867],
              [-79.20326250493979, -4.521206414123753],
              [-79.20379659981063, -4.522645251624169],
              [-79.20341455266353, -4.523910080814517],
              [-79.20267459825051, -4.525075165357009],
              [-79.20281256437691, -4.528492802560947],
              [-79.2031785182699, -4.531333764825831],
              [-79.20323149189801, -4.533783459850213],
              [-79.20294952467492, -4.535483309815731],
              [-79.20104951486135, -4.537820184423254],
              [-79.20046261400046, -4.538939000860125],
              [-79.20117960199875, -4.540388064282467],
              [-79.2021555908054, -4.541818855155839],
              [-79.20246857107027, -4.543410578570331],
              [-79.20191150978471, -4.544829634779262],
              [-79.2012555420416, -4.545830601657428],
              [-79.20010353326809, -4.546985627916058],
              [-79.19933357164167, -4.548285493461719],
              [-79.19934061244035, -4.549524338752178],
              [-79.1990505985902, -4.550690093846924],
              [-79.20015751272459, -4.550449197949433],
              [-79.20094256177673, -4.551417978319364],
              [-79.20413254649021, -4.551715200606225],
              [-79.20522353500854, -4.551374224784752],
              [-79.20637554378204, -4.549921138048887],
              [-79.20584061072088, -4.548509290276741],
              [-79.20714550540846, -4.547654503790113],
              [-79.20791664050132, -4.546055571938837],
              [-79.20989963115642, -4.546883536335315],
              [-79.21215050743724, -4.547151421961019],
              [-79.21350854339099, -4.548257330267006],
              [-79.21387449728397, -4.549506401479391],
              [-79.21458461212166, -4.550448192121053],
              [-79.2165835283928, -4.549847377300864],
              [-79.21761349665596, -4.549027459531487],
              [-79.22009252834144, -4.548798298298493],
              [-79.21868151875957, -4.546877333726911],
              [-79.21820056515492, -4.545529691333115],
              [-79.21745256411484, -4.544089512728192],
              [-79.21768960433685, -4.542074838479039],
              [-79.2168576166269, -4.540742786425199],
              [-79.21453063266516, -4.53853113745123],
              [-79.21362253727428, -4.536151347499469],
              [-79.21585849376744, -4.534872436711737],
              [-79.21585061477846, -4.533371405489731],
              [-79.21494251938758, -4.531949667071785],
              [-79.21337158309298, -4.531439544444027],
              [-79.21265459509468, -4.530361799332752],
              [-79.21525549965244, -4.527682943075376],
              [-79.21527058707817, -4.526570832160985],
              [-79.21153259353457, -4.525304997142257],
              [-79.2106705986112, -4.524435123229921],
              [-79.21238754765926, -4.523525351458431],
              [-79.21611749457581, -4.522477110646321],
              [-79.21598858090485, -4.521084541251469],
              [-79.21712449642425, -4.520761502702783],
              [-79.21836049186764, -4.519725331831239],
              [-79.21813954489974, -4.518512638078676],
              [-79.21955860110866, -4.517322575464732],
              [-79.22023049446783, -4.516013825101709],
              [-79.22014650779793, -4.514602983157886],
              [-79.22043652164807, -4.512966835656528],
              [-79.21910061391873, -4.510079102371833],
              [-79.21827650519775, -4.509155249002958],
              [-79.22013058218188, -4.508219325693517],
              [-79.22011549475616, -4.506871348023594],
              [-79.22170252430487, -4.50742740348079],
              [-79.22294656637533, -4.508180433662744],
              [-79.2245716497645, -4.508881328406972],
              [-79.22611961964438, -4.508116228284393],
              [-79.2276305415122, -4.508662225457726],
              [-79.23085757423775, -4.509213251773019],
              [-79.23173549477715, -4.508365338446993],
              [-79.22978955213414, -4.504454510059759],
              [-79.22972853187896, -4.502744601810377],
              [-79.22915655080574, -4.50126988976433],
              [-79.22890459079605, -4.499704820801924],
              [-79.2275926553098, -4.49943090020588],
              [-79.2263105593989, -4.498694969106396],
              [-79.22478455010535, -4.49832096858637],
              [-79.22345752719337, -4.497386218743372],
              [-79.22276249978142, -4.496354238823471],
              [-79.224159595404, -4.494757318629013],
              [-79.22568560469755, -4.493811337035709],
              [-79.22748553458712, -4.493987357002595],
              [-79.22838558335094, -4.493510259073389],
              [-79.22843151618038, -4.492271413782873],
              [-79.2300185457291, -4.491624498495241],
              [-79.23173549477715, -4.491917529830516],
              [-79.23311649714563, -4.491370694466809],
              [-79.23509261464011, -4.487685842189421],
              [-79.23583960985181, -4.486737848939356],
              [-79.23950149571449, -4.486393855632741],
              [-79.24062349727461, -4.485050068914404],
              [-79.24452259099739, -4.482473136599765],
              [-79.24613158113245, -4.482956269499255],
              [-79.24776454351061, -4.48311435219324],
              [-79.24935961968639, -4.483036065217505],
              [-79.25045060820472, -4.483952207235461],
              [-79.25112954236256, -4.485535884022909],
              [-79.25239554501935, -4.48664195996696],
              [-79.25247953168925, -4.487980885181457],
              [-79.25354755379286, -4.488091693941556],
              [-79.25395156152629, -4.489086625849382],
              [-79.25349458016474, -4.490234611309347],
              [-79.25437954150283, -4.491728434094682],
              [-79.25554663770207, -4.491613602021062],
              [-79.25713349961272, -4.492739459256654],
              [-79.25793464191898, -4.494169411939708],
              [-79.25917063736237, -4.494073355329249],
              [-79.26013153874331, -4.495260400458051],
              [-79.2602995120831, -4.497412035005198],
              [-79.26160457440872, -4.498699160057981],
              [-79.2627414957565, -4.49936183332369],
              [-79.26313058370226, -4.497762230920159],
              [-79.26294752293674, -4.496062380954641],
              [-79.2637325719889, -4.49457124037832],
              [-79.26274853655518, -4.493376483898601],
              [-79.26152762853751, -4.492658490071904],
              [-79.26300854319192, -4.49197553260052],
              [-79.26386249148823, -4.490666614599434],
              [-79.26428259247578, -4.489292653029622],
              [-79.26570852184531, -4.488355891529864],
              [-79.26738758469098, -4.488314652566203],
              [-79.26895852098558, -4.48760386717629],
              [-79.2693095550909, -4.485887085766308],
              [-79.27043960327806, -4.484967088072892],
              [-79.27115659127635, -4.483549037692342],
              [-79.27160653183924, -4.480377325527797],
              [-79.27227758700809, -4.478705638756935],
              [-79.2729335547512, -4.475370647118382],
              [-79.27438362400191, -4.475907759474353],
              [-79.27527663196705, -4.476777633386746],
              [-79.27633660744361, -4.477060774076278],
              [-79.27750353600477, -4.47657479132971],
              [-79.27930463936079, -4.476470688092149],
              [-79.28060953404837, -4.477368725199199],
              [-79.28202054363022, -4.476386701422257],
              [-79.28337053295692, -4.477537704367364],
              [-79.28452254173042, -4.477305525649228],
              [-79.28575853717383, -4.478574378153098],
              [-79.28796364826329, -4.480234497897584],
              [-79.28757456031751, -4.481355158353153],
              [-79.28832256135759, -4.483445102092901],
              [-79.28852858853783, -4.485010003417187],
              [-79.2884295144422, -4.486546909184881],
              [-79.28794051421049, -4.488137962047119],
              [-79.28811653417735, -4.489719627177806],
              [-79.28873461571807, -4.491077495493471],
              [-79.28916963649328, -4.492706602196165],
              [-79.28846756828266, -4.495761302992264],
              [-79.2886274949954, -4.497181197391512],
              [-79.28986349043879, -4.498377127337676],
              [-79.29122152639255, -4.499302992363312],
              [-79.29219064203859, -4.499640112509383],
              [-79.29296160949339, -4.5012869888468],
              [-79.29235861537839, -4.503147771353497],
              [-79.29212157515636, -4.505100587157131],
              [-79.29320552287602, -4.50722824946115],
              [-79.29487653909463, -4.508263414504256],
              [-79.29693563743064, -4.509018121066902],
              [-79.3009566040257, -4.509832339142122],
              [-79.3029095874674, -4.510524013792804],
              [-79.3048625709091, -4.510700033759633],
              [-79.30693055406246, -4.511745089448539],
              [-79.30532055809903, -4.513116033533208],
              [-79.30135357096046, -4.513341842004934],
              [-79.29968255474185, -4.514250775586163],
              [-79.2987595395633, -4.516147600276497],
              [-79.29852249934127, -4.518217595086639],
              [-79.29875149293625, -4.5203973928285],
              [-79.29920160113718, -4.522406199745376],
              [-79.2977676251406, -4.524012004757196],
              [-79.29454059241505, -4.526645095823199],
              [-79.2937855505763, -4.528315944403744],
              [-79.29671452282466, -4.531595447843245],
              [-79.29888962670071, -4.534928427825037],
              [-79.29894260032883, -4.536945113730894],
              [-79.29715759022692, -4.53791707922403],
              [-79.29721056385505, -4.54009587113751],
              [-79.29909565388095, -4.543609732589971],
              [-79.3003536099107, -4.545447548681921],
              [-79.30236861943595, -4.546772559937153],
              [-79.30426058262248, -4.546605424787685],
              [-79.30627458631935, -4.546899461951341],
              [-79.30787653565572, -4.54822329974013],
              [-79.30982951909742, -4.548517504541849],
              [-79.31166850865583, -4.549380170017457],
              [-79.31160765603872, -4.551279006364609],
              [-79.31292763815202, -4.552945999269696],
              [-79.3169475989187, -4.552838040356676],
              [-79.31873361484898, -4.55203723332653],
              [-79.32253262864776, -4.549460133373827],
              [-79.32391363101624, -4.548261521218592],
              [-79.32524149211852, -4.546537363733819],
              [-79.32696564960332, -4.54768954014537],
              [-79.32603458779772, -4.549703208566143],
              [-79.32545456009744, -4.551592154267496],
              [-79.32395956384568, -4.553144147460898],
              [-79.3220595540321, -4.553998933947526],
              [-79.32125053273685, -4.555831720897629],
              [-79.31986953036838, -4.557673727941165],
              [-79.31836665512762, -4.559333512409523],
              [-79.31710065247083, -4.561338463650941],
              [-79.31520064265725, -4.562310429144077],
              [-79.31370564640548, -4.563916234155897],
              [-79.31259957046142, -4.567766042287985],
              [-79.31253855020624, -4.569718858091619],
              [-79.31270551771766, -4.571789691092079],
              [-79.31396464721385, -4.574261514340776],
              [-79.31453662828707, -4.57604317168142],
              [-79.31475858108337, -4.57805231387448],
              [-79.31246160433503, -4.577703123787842],
              [-79.30994451880909, -4.573844430838392],
              [-79.30821952313399, -4.572294616939814],
              [-79.30683852076551, -4.574298394714788],
              [-79.30464950293018, -4.575559200591613],
              [-79.30217751204337, -4.575607312715931],
              [-79.29987349449635, -4.576750269033994],
              [-79.2976375380032, -4.577088395008388],
              [-79.29670764966404, -4.579211028170505],
              [-79.29693563743064, -4.581797851130943],
              [-79.2965856091537, -4.58364186983124],
              [-79.29646256281497, -4.585766682288181],
              [-79.29656951589959, -4.587891327107059],
              [-79.29771465151248, -4.589784296121991],
              [-79.30063658296216, -4.593589009614902],
              [-79.30138357817385, -4.59547979933501],
              [-79.29844655929844, -4.595879783754924],
              [-79.2966685899952, -4.595188779656496],
              [-79.29506664065883, -4.593919088962309],
              [-79.29299161670679, -4.59454689351071],
              [-79.29178663430515, -4.59591884342376],
              [-79.2930445903349, -4.597584830500466],
              [-79.29470856575485, -4.59902651784796],
              [-79.29579150764611, -4.600521514099739],
              [-79.29544852016785, -4.602419344618454],
              [-79.29428863240535, -4.603854158805404],
              [-79.29417463852207, -4.606095982630791],
              [-79.29243455542122, -4.610685912813949],
              [-79.28910056961105, -4.612512497155592],
              [-79.28759752673224, -4.614001458437087],
              [-79.28621652436377, -4.615779427740335],
              [-79.28466050785683, -4.617095218902023],
              [-79.27579463358377, -4.621847925645],
              [-79.27384165014207, -4.622014893156404],
              [-79.2718276464452, -4.621946832102537],
              [-79.26798253217892, -4.620562979886984],
              [-79.2662585423322, -4.619528150120004],
              [-79.26419055917883, -4.619342239507375],
              [-79.26435853251861, -4.621187934588363],
              [-79.2657316558981, -4.622564913643316],
              [-79.2671124906285, -4.624295776650627],
              [-79.26814262652972, -4.625961763727332],
              [-79.27009560997142, -4.626427629906232],
              [-79.27440659041663, -4.625118711905145],
              [-79.2788925850003, -4.624550586507382],
              [-79.28302754547866, -4.625536801235967],
              [-79.28464558806915, -4.624392839089467],
              [-79.28653755125568, -4.624912852362968],
              [-79.2884295144422, -4.626011719870291],
              [-79.29044351813907, -4.626187572199115],
              [-79.29257151571923, -4.625966625231172],
              [-79.29832451878809, -4.623792862459652],
              [-79.29993451475153, -4.624664748028749],
              [-79.29906464083918, -4.62649870844524],
              [-79.29779059155533, -4.628447500935351],
              [-79.2987595395633, -4.632239138659315],
              [-79.29898853315827, -4.634075110732567],
              [-79.29972865520935, -4.635975958736481],
              [-79.30156764476776, -4.636558836283825],
              [-79.30265863328609, -4.638224823360531],
              [-79.30443559676094, -4.637885691557756],
              [-79.30616763323472, -4.637310692999392],
              [-79.30846360415468, -4.637660721276347],
              [-79.31030259371309, -4.637212792370235],
              [-79.31208056301631, -4.638301601593696],
              [-79.31414754034131, -4.638541826938933],
              [-79.31627654374985, -4.637850655202442],
              [-79.31967959644224, -4.634821100115914],
              [-79.32094559909905, -4.63292511361584],
              [-79.32192963453276, -4.631146138484212],
              [-79.322738655828, -4.628851508668788],
              [-79.32263153510533, -4.626671543288865],
              [-79.3220595540321, -4.624653683916563],
              [-79.32270060198755, -4.622765911681597],
              [-79.32459256517407, -4.622706735445149],
              [-79.32545456009744, -4.62438177497728],
              [-79.32573652732053, -4.626335596609294],
              [-79.32756763788994, -4.630183393084621],
              [-79.32882659974807, -4.631623236413418],
              [-79.33089458290144, -4.632379284080571],
              [-79.33077254239109, -4.63455790835593],
              [-79.32910152617248, -4.636163881005871],
              [-79.327720523804, -4.637823833112293],
              [-79.32696564960332, -4.639775643087546],
              [-79.32765162455983, -4.641503488609715],
              [-79.32926162052327, -4.642945511233336],
              [-79.33132960367665, -4.642886334996888],
              [-79.33575457800515, -4.643358236146128],
              [-79.33759356756354, -4.644167425079388],
              [-79.33850853611503, -4.645951094076793],
              [-79.33711965475757, -4.647213911610379],
              [-79.33488453645472, -4.647208211916222],
              [-79.33275553304618, -4.647032024311329],
              [-79.3306885557212, -4.646566158132373],
              [-79.3287355722795, -4.645874148205564],
              [-79.3267825888378, -4.645869119063661],
              [-79.32602654117066, -4.647819923210534],
              [-79.32430255132394, -4.649137055476785],
              [-79.32619451451046, -4.649827724299087],
              [-79.32820851820733, -4.649715909710608],
              [-79.33211549091911, -4.651161788009688],
              [-79.33406864199887, -4.652089664692085],
              [-79.33820360247722, -4.653473684545702],
              [-79.34130054806538, -4.655949531107979],
              [-79.34675549065703, -4.658666273567746],
              [-79.34841963371504, -4.657639155151685],
              [-79.35882559887898, -4.657202290357759],
              [-79.36285360627272, -4.657383171828428],
              [-79.36503558330938, -4.658247346046608],
              [-79.36681355261261, -4.659345040087487],
              [-79.3695675107225, -4.66250786743467],
              [-79.37277962366038, -4.665101731193772],
              [-79.37202458182163, -4.666763694956956],
              [-79.37047560611337, -4.665783682836775],
              [-79.3682936290767, -4.665777815504555],
              [-79.36697364696342, -4.663884678851559],
              [-79.36508151613883, -4.663419986139104],
              [-79.36330455266398, -4.662094974883871],
              [-79.35939053915352, -4.662085922428446],
              [-79.35714754186169, -4.662651030341067],
              [-79.35501853845314, -4.663513695816675],
              [-79.35311852863957, -4.664657825601182],
              [-79.34960164970194, -4.667805565522599],
              [-79.34764849862218, -4.66883134283421],
              [-79.3455736423082, -4.669459482658738],
              [-79.34350549151677, -4.66940030642229],
              [-79.34190354218038, -4.668248465286808],
              [-79.33971351851666, -4.668414426969832],
              [-79.33764654119167, -4.668292386459484],
              [-79.3356325374948, -4.668749367821079],
              [-79.33345056045813, -4.668915329504102],
              [-79.33242059219498, -4.667131660506698],
              [-79.33058160263657, -4.665752669794983],
              [-79.32868963945005, -4.664708787572522],
              [-79.32707260268793, -4.665798770262484],
              [-79.32821656483439, -4.667872620748085],
              [-79.32994055468112, -4.668789600956359],
              [-79.33085652906098, -4.670057447631848],
              [-79.33183251786764, -4.670693466445357],
              [-79.3344726497323, -4.67323100381509],
              [-79.33556363825063, -4.674896990891796],
              [-79.33595255855833, -4.677999803812156],
              [-79.33744856063848, -4.679721614364041],
              [-79.34031651263166, -4.68099449018149],
              [-79.3432464907084, -4.681235553716988],
              [-79.3443984994819, -4.681121559833741],
              [-79.34572552239388, -4.680491575990459],
              [-79.34767951166396, -4.679808618519075],
              [-79.34951749539398, -4.6794066224424],
              [-79.35153149909084, -4.678606653602628],
              [-79.35314149505429, -4.677461853265868],
              [-79.35436257071002, -4.673676082874124],
              [-79.35602554030157, -4.674539083625859],
              [-79.35711652881992, -4.6761507559699],
              [-79.35913053251679, -4.675811959443251],
              [-79.36096952207518, -4.676503801731997],
              [-79.36498260968126, -4.678465837629119],
              [-79.36935461038165, -4.679624551925201],
              [-79.37142158770664, -4.680090585742164],
              [-79.37555654818499, -4.680217655394415],
              [-79.37722052360493, -4.679235799255537],
              [-79.37796751881662, -4.681597484296447],
              [-79.37773165206104, -4.683785328665351],
              [-79.37606851483143, -4.685047140370557],
              [-79.37400053167805, -4.683831261494788],
              [-79.37221552157615, -4.683311248221287],
              [-79.37026253813445, -4.684681186477576],
              [-79.36836252832087, -4.685201199751077],
              [-79.3662945451675, -4.684961309681967],
              [-79.36428054147063, -4.684043491283376],
              [-79.36180855058383, -4.683920444944647],
              [-79.3599705668538, -4.684947228084638],
              [-79.35852854423015, -4.686155227971369],
              [-79.35680354855505, -4.687235990567842],
              [-79.3553615259314, -4.688787983761245],
              [-79.35340150169102, -4.689298944579321],
              [-79.35087553134773, -4.689175060050275],
              [-79.35023465103033, -4.691298866678835],
              [-79.35074661767676, -4.693252520672786],
              [-79.35206559396168, -4.694521540814719],
              [-79.35401958323176, -4.695159571285046],
              [-79.35470555818829, -4.696888254997532],
              [-79.35326353556464, -4.698087202428894],
              [-79.35131055212294, -4.698716180443739],
              [-79.35021252280593, -4.70032315892206],
              [-79.35026549643405, -4.702159130995312],
              [-79.3468704903687, -4.703985882975076],
              [-79.34571864923326, -4.705592693815277],
              [-79.34503150081031, -4.70375504536139],
              [-79.34601553624401, -4.701805079404835],
              [-79.34561957513763, -4.699851090134757],
              [-79.34321564530468, -4.693813772909948],
              [-79.34091950674666, -4.693175742439621],
              [-79.33890550304979, -4.692826719991047],
              [-79.33735652734153, -4.693853503131038],
              [-79.33728762809736, -4.695924503769504],
              [-79.33670760039708, -4.697876481382821],
              [-79.33458764944398, -4.695972448255759],
              [-79.3327484922475, -4.696546440985742],
              [-79.33130663726192, -4.697745388417047],
              [-79.32905559334303, -4.701131006750813],
              [-79.32710260990133, -4.701759146575398],
              [-79.32554659339439, -4.703021964108984],
              [-79.32410457077074, -4.704681916215407],
              [-79.32220456095718, -4.704794736632209],
              [-79.32266958894577, -4.702381921981896],
              [-79.32331063690123, -4.700484091463125],
              [-79.3235475094852, -4.698188288181257],
              [-79.32401253747379, -4.696010502096215],
              [-79.32199853377692, -4.695480598176971],
              [-79.32051057832383, -4.693985601925192],
              [-79.31883956210523, -4.695365430827223],
              [-79.31768755333171, -4.697080535856571],
              [-79.31549853549637, -4.696442505386244],
              [-79.31407160029846, -4.695064688140974],
              [-79.3138426067035, -4.693056551776351],
              [-79.31327062563027, -4.69120985086704],
              [-79.3113706158167, -4.691729864140484],
              [-79.31092050761575, -4.689713010596563],
              [-79.30896752417405, -4.689535817163232],
              [-79.30782356202761, -4.687698001071283],
              [-79.30592355221403, -4.68815397660444],
              [-79.3041995623673, -4.688836934075823],
              [-79.30229955255372, -4.688253888890415],
              [-79.30028554885685, -4.688710032061636],
              [-79.29844655929844, -4.689746035295116],
              [-79.2944185519047, -4.68973580937319],
              [-79.29274753568609, -4.691277911920849],
              [-79.29279363615359, -4.695645554031614],
              [-79.29313662363185, -4.697771204678872],
              [-79.29370860470507, -4.699843211145776],
              [-79.29490654630803, -4.70156401586928],
              [-79.29553250683776, -4.703518005139358],
              [-79.29621864943233, -4.707317689490367],
              [-79.29643255560157, -4.713349307021076],
              [-79.29665350256948, -4.715412093394434],
              [-79.2983706192556, -4.719150757490297],
              [-79.29853859259539, -4.721103740931994],
              [-79.2965856091537, -4.720990752877071],
              [-79.29509765370061, -4.718680867997875],
              [-79.29389149783253, -4.717131892289615],
              [-79.29343451647098, -4.714942203902012],
              [-79.2932125636747, -4.712935241003777],
              [-79.29087049228723, -4.707413746100883],
              [-79.29070251894744, -4.705576600561187],
              [-79.28910056961105, -4.701722098563323],
              [-79.2880706013479, -4.700001126201755],
              [-79.28577463042794, -4.699760230304264],
              [-79.28491263550458, -4.697868267117713],
              [-79.2835995265519, -4.696545435157361],
              [-79.28296652522349, -4.694698566609929],
              [-79.28384360757259, -4.690740967202942],
              [-79.28286761876593, -4.689129127220781],
              [-79.2804566481343, -4.689067939327572],
              [-79.27821365084246, -4.688313065126863],
              [-79.2762065203062, -4.68738502080646],
              [-79.27465855042632, -4.685835039269762],
              [-79.27259056727294, -4.685315193634381],
              [-79.27097353051083, -4.686396291506924],
              [-79.27160653183924, -4.688124137029149],
              [-79.27275049398568, -4.689908979492998],
              [-79.27165263230674, -4.691632634063581],
              [-79.26992763663164, -4.692370576819826],
              [-79.26848561400799, -4.693803546988022],
              [-79.26784557188091, -4.695755356963275],
              [-79.26905055428253, -4.697422349868361],
              [-79.26985152895072, -4.699205348313512],
              [-79.26834865370996, -4.70052214530358],
              [-79.26668551648035, -4.701611960355478],
              [-79.26627362975792, -4.703563770330732],
              [-79.26517459461253, -4.70534274546236],
              [-79.26305363783105, -4.705390689948558],
              [-79.26155059495223, -4.706653507482145],
              [-79.26016959258375, -4.708259647770149],
              [-79.25821660914205, -4.708598444296797],
              [-79.25631760515687, -4.707734437716681],
              [-79.25424962200348, -4.707150721978962],
              [-79.2518306047448, -4.707831667793585],
              [-79.25079359568298, -4.70961047528715],
              [-79.25222757167957, -4.711513167309761],
              [-79.2544705689714, -4.711464216995125],
              [-79.25637057878498, -4.711125420468477],
              [-79.26045256563522, -4.709978440836892],
              [-79.26235156962042, -4.709639476672123],
              [-79.26280251601167, -4.711828159231345],
              [-79.26555664175963, -4.714937007122046],
              [-79.26641059005594, -4.71672000556714],
              [-79.26773057216924, -4.718160687086311],
              [-79.26950854147246, -4.719250669776272],
              [-79.2712246523302, -4.719417637287677],
              [-79.27239258671976, -4.716672061080942],
              [-79.27245360697493, -4.714437948606474],
              [-79.27372765625879, -4.712886290689198],
              [-79.27561157281825, -4.71193343593535],
              [-79.27607760663524, -4.714157993040203],
              [-79.27606956000818, -4.715994132751518],
              [-79.2772215687817, -4.717605972733622],
              [-79.27899953808492, -4.718586655406057],
              [-79.28388249960335, -4.720036557018716],
              [-79.28543063712131, -4.721650576295701],
              [-79.28415658783744, -4.723021352742251],
              [-79.28306559931912, -4.72503535643915],
              [-79.28248557161884, -4.727157989601267],
              [-79.28041054766679, -4.72737927184528],
              [-79.27886157195853, -4.726055266418484],
              [-79.2770235882285, -4.726973084817018],
              [-79.27534452538283, -4.730929007843429],
              [-79.27487949739422, -4.732763806450237],
              [-79.27458965118215, -4.734950477352697],
              [-79.27049257690618, -4.739190211620837],
              [-79.26853959346448, -4.738724177803817],
              [-79.26652558976761, -4.739008156683667],
              [-79.26629659617265, -4.740898275851521],
              [-79.26807355964749, -4.742511959852322],
              [-79.26720452392546, -4.744236787889406],
              [-79.26714350367028, -4.746306615061485],
              [-79.2681736395715, -4.748145772257942],
              [-79.26816559294444, -4.750152567518057],
              [-79.26862358013437, -4.751989210143563],
              [-79.27075157771455, -4.751307426138681],
              [-79.27264354090106, -4.751140458627219],
              [-79.27407852272604, -4.752464296416008],
              [-79.27471152405444, -4.754192141938177],
              [-79.27470364506544, -4.756200110664793],
              [-79.27371960963173, -4.758149908983285],
              [-79.27486457760656, -4.760169612374284],
              [-79.27561157281825, -4.761952443181372],
              [-79.27480355735139, -4.763731585951064],
              [-79.27530663918044, -4.76769304103351],
              [-79.2744976178852, -4.769816009471754],
              [-79.27610057304996, -4.773436656370791],
              [-79.27706952105795, -4.775220660644322],
              [-79.27861061777722, -4.7793661823207],
              [-79.28068564172926, -4.778909200959163],
              [-79.28257760491579, -4.779141379677355],
              [-79.28372961368929, -4.777425436457634],
              [-79.28230251085331, -4.776038399118875],
              [-79.28293651801009, -4.77396974541324],
              [-79.28219555776869, -4.771960938496363],
              [-79.28110456925036, -4.77012194893797],
              [-79.28231860410743, -4.768516143926092],
              [-79.28071564894266, -4.767128268397016],
              [-79.28037249382633, -4.765003455940075],
              [-79.2803805404534, -4.762995487213516],
              [-79.2814785697704, -4.761216679719951],
              [-79.28302754547866, -4.760126864668052],
              [-79.28401158091238, -4.758410921448387],
              [-79.28482864883468, -4.754163308191266],
              [-79.28592651051362, -4.752267321691193],
              [-79.2858735368855, -4.749970512580944],
              [-79.28507256221731, -4.747898673752104],
              [-79.28363758039234, -4.746691847331761],
              [-79.28266863238436, -4.744908010696292],
              [-79.2830194988516, -4.742892833533006],
              [-79.28308051910678, -4.741003217279342],
              [-79.28520164352634, -4.741813076764913],
              [-79.2864765310005, -4.739917090264896],
              [-79.28779550728542, -4.741413259983062],
              [-79.28842163545322, -4.743601942542284],
              [-79.29031359863974, -4.744809942429072],
              [-79.29232760233661, -4.74532978806451],
              [-79.29428058577831, -4.744647836421507],
              [-79.29508960707355, -4.742751011731116],
              [-79.29504350660604, -4.739016370948775],
              [-79.29676850228115, -4.737872408802332],
              [-79.29855351238307, -4.73759630891152],
              [-79.29988053529503, -4.735872486702817],
              [-79.30091855018524, -4.733460845518948],
              [-79.30293255388212, -4.732832873332427],
              [-79.30477154344052, -4.733877761383269],
              [-79.30626653969229, -4.735083581975232],
              [-79.30821952313399, -4.734057804663678],
              [-79.30902854442922, -4.732106832878742],
              [-79.3110425481261, -4.732915854173996],
              [-79.31270551771766, -4.733833504934466],
              [-79.31443051339276, -4.732688704597706],
              [-79.31547556908166, -4.72861392618421],
              [-79.31737557889524, -4.728736134332621],
              [-79.31916058899714, -4.728053009223174],
              [-79.32042659165394, -4.726329354652592],
              [-79.32147265317121, -4.723917378192539],
              [-79.322738655828, -4.722482564005645],
              [-79.3246916392697, -4.722035640927913],
              [-79.32646960857294, -4.723124450151431],
              [-79.32836961838652, -4.723825512533665],
              [-79.3295826474152, -4.722390363070645],
              [-79.33039049524399, -4.720719514490099],
              [-79.33206151146261, -4.719638584255563],
              [-79.333900501021, -4.718720765857029],
              [-79.33573949057941, -4.719756601452389],
              [-79.3375776419475, -4.720456658006242],
              [-79.33941663150591, -4.72051549896662],
              [-79.34131664131948, -4.719832541495236],
              [-79.34326962476118, -4.72057869851659],
              [-79.3451076084912, -4.721044564695546],
              [-79.34700057750611, -4.720135631114317],
              [-79.35074661767676, -4.71899686574784],
              [-79.35269960111846, -4.718195723441625],
              [-79.35430959708191, -4.717159887846208],
              [-79.35604062772731, -4.71630510135958],
              [-79.36052662231099, -4.714363182030127],
              [-79.36576849692372, -4.711038416313443],
              [-79.37129250639761, -4.70830239547638],
              [-79.37480150634624, -4.706926589887871],
              [-79.37681551004312, -4.70658863155154],
              [-79.3787155198567, -4.705905674080157],
              [-79.38089749689335, -4.705847838948159],
              [-79.3825074928568, -4.706944694798722],
              [-79.38095063815955, -4.708433488442154],
              [-79.37416062602885, -4.712957201590143],
              [-79.37265758315003, -4.714616986058502],
              [-79.37144455412135, -4.716223964536766],
              [-79.37069655308127, -4.717948960211913],
              [-79.37057451257094, -4.71983857646552],
              [-79.37074265354879, -4.72208157375735],
              [-79.36849965625696, -4.721968585702427],
              [-79.36683651902733, -4.720870724023484],
              [-79.36286165289977, -4.724016284650133],
              [-79.3609616430862, -4.725051282055176],
              [-79.35640758744867, -4.729290848685253],
              [-79.35611757359852, -4.731243999765013],
              [-79.35628554693832, -4.733540641237255],
              [-79.35674252829988, -4.735548609963814],
              [-79.35696464873423, -4.737846257264437],
              [-79.35517863280394, -4.738185389067212],
              [-79.3534625219462, -4.736807571821942],
              [-79.35179150572759, -4.735826386235317],
              [-79.34989954254107, -4.735532684347788],
              [-79.34880050739568, -4.737483320856597],
              [-79.3467335300707, -4.737478291714694],
              [-79.34293351044353, -4.737125581228725],
              [-79.34259052296528, -4.739023244109433],
              [-79.34499361460792, -4.742528891296786],
              [-79.34469555413071, -4.746379872895261],
              [-79.34515354132066, -4.748505523542576],
              [-79.34513862153298, -4.752294143781398],
              [-79.34461961408788, -4.754137994843632],
              [-79.34266663064618, -4.754875267047623],
              [-79.34088162054428, -4.755901044359177],
              [-79.34058356006707, -4.757916892074775],
              [-79.34058356006707, -4.759987725075234],
              [-79.34109452088512, -4.761824535338803],
              [-79.34315462504951, -4.765853213284799],
              [-79.34728958552786, -4.766668269550337],
              [-79.3490675548311, -4.767929075427162],
              [-79.35113553798448, -4.76880196682464],
              [-79.35298156834155, -4.768001159794551],
              [-79.35408060348693, -4.766051193837995],
              [-79.3555294992712, -4.76638931981239],
              [-79.35591154641828, -4.768868016221745],
              [-79.35590349979122, -4.770939016860268],
              [-79.35797165058267, -4.77087984062382],
              [-79.35918451197328, -4.769337067523907],
              [-79.36321251936702, -4.768884947666152],
              [-79.36510465019161, -4.769811986158231],
              [-79.36665362589987, -4.771361800056809],
              [-79.36832464211848, -4.770163020263567],
              [-79.3671795065056, -4.768090007968283],
              [-79.36581459739124, -4.761937523393726],
              [-79.36450165607661, -4.757919909559917],
              [-79.36617250465716, -4.757001923523262],
              [-79.36806463548174, -4.757810777180453],
              [-79.37047560611337, -4.761261606721007],
              [-79.3723675692999, -4.762758614629547],
              [-79.3743205527416, -4.763911461593352],
              [-79.37770851800826, -4.766559137170873],
              [-79.37966150144996, -4.76679936251611],
              [-79.38052349637333, -4.764794243636629],
              [-79.3825375000702, -4.764681255581706],
              [-79.38455150376706, -4.765318280223653],
              [-79.38558951865728, -4.762906639039784],
              [-79.3856585855395, -4.75917283644776],
              [-79.38537561248805, -4.757218847177683],
              [-79.38423165034159, -4.75566098665206],
              [-79.38578749921047, -4.754172025370565],
              [-79.38659652050572, -4.752393217877],
              [-79.3864895674211, -4.749634565901317],
              [-79.3858565660927, -4.747743608543203],
              [-79.38724561508822, -4.746254647261708],
              [-79.38879358496811, -4.747289812304871],
              [-79.39126557585492, -4.747177662440265],
              [-79.39315753904144, -4.748040495553937],
              [-79.3934405120929, -4.752065317824474],
              [-79.3948666091005, -4.753796180831841],
              [-79.3954395960021, -4.75563299109541],
              [-79.39514153552491, -4.761545585600857],
              [-79.39611065117096, -4.763221463323305],
              [-79.39807151360165, -4.76316144889654],
              [-79.40013849092664, -4.762597514450363],
              [-79.40486152573233, -4.758809900039921],
              [-79.40647152169576, -4.757900966458749],
              [-79.40958355470964, -4.755204172928529],
              [-79.41281159326358, -4.751992227628705],
              [-79.41430658951535, -4.753262085961012],
              [-79.41355154767659, -4.755159245927473],
              [-79.4124525125312, -4.75699203287752],
              [-79.41279550000947, -4.758891875053052],
              [-79.412612606882, -4.763087520510453],
              [-79.41358960151705, -4.765097500893773],
              [-79.41288753330642, -4.769056106129199],
              [-79.41472652286483, -4.769522139946162],
              [-79.4131086479124, -4.771127777319975],
              [-79.41126261755531, -4.774685559945112],
              [-79.40976762130354, -4.776346685517979],
              [-79.40889757975313, -4.778125493011544],
              [-79.40722656353452, -4.778981452964615],
              [-79.40527358009282, -4.778524303964957],
              [-79.40326661719462, -4.777714444479386],
              [-79.40102361990279, -4.77764655106364],
              [-79.399291583429, -4.778220543793623],
              [-79.39526357603526, -4.780218286598313],
              [-79.39359255981664, -4.781363254573137],
              [-79.39301253211636, -4.783143067895082],
              [-79.39307486125398, -4.783309811266404],
              [-79.39301300048828, -4.783499717712402],
              [-79.3936996459961, -4.785336971282959],
              [-79.39484405517578, -4.787184238433838],
              [-79.39639282226562, -4.788797855377197],
              [-79.39817810058594, -4.790176391601562],
              [-79.40007019042969, -4.791501045227051],
              [-79.40184783935547, -4.792771339416504],
              [-79.40409088134766, -4.793120384216309],
              [-79.40621948242188, -4.793125152587891],
              [-79.40799713134766, -4.793934345245361],
              [-79.40804805242148, -4.794019544132203],
              [-79.4082794982124, -4.796046002017306],
              [-79.40701265736529, -4.797535969127182],
              [-79.40355663104476, -4.799769913963587],
              [-79.40251962198293, -4.801430536622206],
              [-79.40182459457098, -4.803672528085656],
              [-79.40152753992216, -4.80585936662618],
              [-79.40152753992216, -4.806216676837217],
              [-79.40152740478516, -4.806217670440674],
              [-79.40152740478516, -4.807813457194779],
              [-79.39985652370355, -4.809065947507975],
              [-79.39807855440031, -4.806936273547194],
              [-79.39617955041513, -4.806588256927],
              [-79.39514153552491, -4.808141255948783],
              [-79.39358551901798, -4.809630217230279],
              [-79.39243351024446, -4.811064863779109],
              [-79.39076249402585, -4.810138160563156],
              [-79.38967150550752, -4.811690824308812],
              [-79.38777149569394, -4.811912944743199],
              [-79.38703154128092, -4.810075128651249],
              [-79.38594055276259, -4.808174280647336],
              [-79.38456759702117, -4.806507287742249],
              [-79.38295760105774, -4.805065432756692],
              [-79.38128658483913, -4.806038404078208],
              [-79.38013457606561, -4.807871358666318],
              [-79.37863957981385, -4.809079023276922],
              [-79.37702958385042, -4.807574136379401],
              [-79.37606063584244, -4.805446474075382],
              [-79.3738784911677, -4.802059514637108],
              [-79.37325253063798, -4.800041655264749],
              [-79.37239858234167, -4.798258992095725],
              [-79.37032355838963, -4.798542803337511],
              [-79.36399052762049, -4.803356362697627],
              [-79.36127462335105, -4.806677440376859],
              [-79.36046560205581, -4.808511233155286],
              [-79.35851261861411, -4.808913061593898],
              [-79.35724661595731, -4.807590061995484],
              [-79.35691050163966, -4.805573208451506],
              [-79.3554155053879, -4.804421367316081],
              [-79.35363753608468, -4.804769383936218],
              [-79.34967054894611, -4.804298488615416],
              [-79.34777053913253, -4.804927298992197],
              [-79.34712949117707, -4.806706441761889],
              [-79.34540550133033, -4.807561228248517],
              [-79.34213253577535, -4.805537333905875],
              [-79.34155250807507, -4.807263167771339],
              [-79.34177362268103, -4.811856953629956],
              [-79.34199557547733, -4.814153762740204],
              [-79.34264394359376, -4.816017196796607],
              [-79.34256842846783, -4.816159909977678],
              [-79.34273529052734, -4.816639423370361],
              [-79.34457397460938, -4.817620754241943],
              [-79.34642028808594, -4.817281246185303],
              [-79.34691009151207, -4.818977356022434],
              [-79.34565762897809, -4.820655269763336],
              [-79.34569396863834, -4.820965354009289],
              [-79.34565734863281, -4.821014404296875],
              [-79.34588623046875, -4.822968006134033],
              [-79.34639601798818, -4.824507989265671],
              [-79.34576458206271, -4.826233761607909],
              [-79.34569551518048, -4.829959517572831],
              [-79.34391050507857, -4.830479530846333],
              [-79.34253654350877, -4.828866517397785],
              [-79.34063753952358, -4.829495663050693],
              [-79.33959952463337, -4.831328617638803],
              [-79.33792850841475, -4.832246436037394],
              [-79.33612857852518, -4.830533510302871],
              [-79.33576309835402, -4.829597627552395],
              [-79.33644104003906, -4.827828407287598],
              [-79.33645236736695, -4.827718422587776],
              [-79.3360636523018, -4.82845304049104],
              [-79.33644055296166, -4.827468751222909],
              [-79.33667759318368, -4.825172947941041],
              [-79.33380863536212, -4.822118247144942],
              [-79.3321454981325, -4.820685109338683],
              [-79.33025353494598, -4.819812217941148],
              [-79.32852954509924, -4.82044119595605],
              [-79.32881151232233, -4.822684025609817],
              [-79.32955163437342, -4.824586047080118],
              [-79.329887581053, -4.826593680530607],
              [-79.33005555439279, -4.82866468116913],
              [-79.32982656079783, -4.830553459232419],
              [-79.32727862986818, -4.835665079069713],
              [-79.32168555351207, -4.841393774619291],
              [-79.31984756978204, -4.84305255325927],
              [-79.31724549175783, -4.846608324227702],
              [-79.31620764450568, -4.8485053165561],
              [-79.31153858915648, -4.853836877532899],
              [-79.3104395540111, -4.85584166113631],
              [-79.31048405637938, -4.856123265194314],
              [-79.31044006347656, -4.856203556060791],
              [-79.31077575683594, -4.858329296112061],
              [-79.31155395507812, -4.85869312286377],
              [-79.31324768066406, -4.858281135559082],
              [-79.31514739990234, -4.857019901275635],
              [-79.31562042236328, -4.85477876663208],
              [-79.3174057006836, -4.853517055511475],
              [-79.31918334960938, -4.852834224700928],
              [-79.32068634033203, -4.851399898529053],
              [-79.32344818115234, -4.850150108337402],
              [-79.32598114013672, -4.849351406097412],
              [-79.32805633544922, -4.848090648651123],
              [-79.32978057861328, -4.846602916717529],
              [-79.331298828125, -4.846470832824707],
              [-79.33154589469781, -4.846665590771559],
              [-79.33228156665817, -4.84832002950406],
              [-79.33253479003906, -4.849250316619873],
              [-79.33270263671875, -4.851203918457031],
              [-79.33454132080078, -4.852184772491455],
              [-79.33539581298828, -4.854258060455322],
              [-79.3357925415039, -4.856492519378662],
              [-79.33722686767578, -4.857879638671875],
              [-79.33855438232422, -4.8560471534729],
              [-79.33924865722656, -4.853978157043457],
              [-79.3392562866211, -4.851852893829346],
              [-79.34017944335938, -4.850128173828125],
              [-79.34168243408203, -4.848639488220215],
              [-79.34266662597656, -4.846571445465088],
              [-79.3443374633789, -4.845083236694336],
              [-79.34583282470703, -4.843766689300537],
              [-79.3470458984375, -4.842042446136475],
              [-79.34819793701172, -4.840037822723389],
              [-79.34866333007812, -4.837344169616699],
              [-79.3492431640625, -4.835500717163086],
              [-79.35108947753906, -4.834591865539551],
              [-79.35310363769531, -4.834650993347168],
              [-79.35477447509766, -4.833624362945557],
              [-79.35586547851562, -4.831899642944336],
              [-79.357421875, -4.830872535705566],
              [-79.35932159423828, -4.829837322235107],
              [-79.3604736328125, -4.828176021575928],
              [-79.3609390258789, -4.826169490814209],
              [-79.36100769042969, -4.823927402496338],
              [-79.36318969726562, -4.823588848114014],
              [-79.36514282226562, -4.823883056640625],
              [-79.36744689941406, -4.823716640472412],
              [-79.36854553222656, -4.821991920471191],
              [-79.37049865722656, -4.821770668029785],
              [-79.37227630615234, -4.822399139404297],
              [-79.37342071533203, -4.824182987213135],
              [-79.37503051757812, -4.825398921966553],
              [-79.3770980834961, -4.825919151306152],
              [-79.37911224365234, -4.826439380645752],
              [-79.3810043334961, -4.827248573303223],
              [-79.38279789304633, -4.828488157380341],
              [-79.3825535933243, -4.830283561949898],
              [-79.37892154703694, -4.832282310583025],
              [-79.37500753352649, -4.833828436444207],
              [-79.37431351194292, -4.835608249766096],
              [-79.37438448161969, -4.83578613344897],
              [-79.37431335449219, -4.835968494415283],
              [-79.375, -4.837687969207764],
              [-79.37530190130197, -4.839406290784788],
              [-79.37464862043218, -4.841350691636933],
              [-79.37274961644698, -4.84077669890695],
              [-79.37045263969866, -4.84077083157473],
              [-79.37010160559333, -4.842542598269631],
              [-79.37014837788286, -4.842668682487395],
              [-79.37010192871094, -4.842903137207031],
              [-79.37090301513672, -4.844921588897705],
              [-79.37177296032158, -4.847402120624931],
              [-79.37152049416419, -4.849102108062766],
              [-79.37156087213255, -4.849194413000834],
              [-79.37152099609375, -4.849462985992432],
              [-79.37237548828125, -4.851418018341064],
              [-79.37253570197889, -4.853386553120383],
              [-79.37248256901157, -4.855189716706718],
              [-79.37249078808297, -4.855263238198977],
              [-79.37248229980469, -4.855551242828369],
              [-79.37271118164062, -4.857622623443604],
              [-79.37289879752176, -4.859279223152587],
              [-79.3719486417788, -4.861102311212164],
              [-79.37205937907196, -4.861250849692034],
              [-79.3719482421875, -4.861464023590088],
              [-79.37310028076172, -4.86290454864502],
              [-79.37414725769901, -4.86441003880969],
              [-79.37395459884861, -4.866052998508337],
              [-79.37290954315972, -4.868003802655153],
              [-79.37221552157615, -4.869719578236811],
              [-79.3722856516877, -4.869908408532291],
              [-79.3722152709961, -4.870082378387451],
              [-79.3729019165039, -4.871929168701172],
              [-79.37456512451172, -4.872737884521484],
              [-79.37635040283203, -4.873999118804932],
              [-79.37650568679068, -4.875706360054843],
              [-79.37639658252202, -4.87760594330382],
              [-79.376414929293, -4.877642896164115],
              [-79.37639617919922, -4.877969264984131],
              [-79.37725067138672, -4.87968921661377],
              [-79.37903594970703, -4.880842208862305],
              [-79.38029479980469, -4.882400512695312],
              [-79.38038162592885, -4.883893224320896],
              [-79.37913562084424, -4.885189386389868],
              [-79.37792158598718, -4.886859229142033],
              [-79.37768555159354, -4.88892117732513],
              [-79.37772074548072, -4.888978306189279],
              [-79.377685546875, -4.889285087585449],
              [-79.37889099121094, -4.891241550445557],
              [-79.38072967529297, -4.892050743103027],
              [-79.38268280029297, -4.892977714538574],
              [-79.38370242170404, -4.894743642176934],
              [-79.38365162264131, -4.89646774003711],
              [-79.3836612402056, -4.896509903129189],
              [-79.38365173339844, -4.896832466125488],
              [-79.38410949707031, -4.89884090423584],
              [-79.38571166992188, -4.900110721588135],
              [-79.38790130615234, -4.900288105010986],
              [-79.38990783691406, -4.900121212005615],
              [-79.39192199707031, -4.89932107925415],
              [-79.39263627137717, -4.901008127140835],
              [-79.39162465658728, -4.902861288147221],
              [-79.39099165525889, -4.904985094775782],
              [-79.38960260626335, -4.906645885072521],
              [-79.38137057150902, -4.912774397404007],
              [-79.37952454115194, -4.914036209109213],
              [-79.37583952123646, -4.916088099008448],
              [-79.37416850501785, -4.917404225446319],
              [-79.37381763855059, -4.919184876958525],
              [-79.37389082353427, -4.91917946739583],
              [-79.37381744384766, -4.919551849365234],
              [-79.37606048583984, -4.919385433197021],
              [-79.37773132324219, -4.918584823608398],
              [-79.37963104248047, -4.91801929473877],
              [-79.3814697265625, -4.917562961578369],
              [-79.38348388671875, -4.918200492858887],
              [-79.38555145263672, -4.918495178222656],
              [-79.38751220703125, -4.917523384094238],
              [-79.3891830444336, -4.91631555557251],
              [-79.3911361694336, -4.915235042572021],
              [-79.39309692382812, -4.914778709411621],
              [-79.39522552490234, -4.914837837219238],
              [-79.3969497680664, -4.913639545440674],
              [-79.39884948730469, -4.912838935852051],
              [-79.4001679927877, -4.914558518508721],
              [-79.40016162497942, -4.916093295788414],
              [-79.39958159727914, -4.918044938125604],
              [-79.39952057702396, -4.920223897677147],
              [-79.39953088529123, -4.920232967150458],
              [-79.39952087402344, -4.920590400695801],
              [-79.4009017944336, -4.921805858612061],
              [-79.40274047851562, -4.921692371368408],
              [-79.40457916259766, -4.920774459838867],
              [-79.40653991699219, -4.920779228210449],
              [-79.40795898571598, -4.921990439752589],
              [-79.40715749665229, -4.925586639333801],
              [-79.40722797335351, -4.925615076061743],
              [-79.40715789794922, -4.925953388214111],
              [-79.40916442871094, -4.92676305770874],
              [-79.41129302978516, -4.926939964294434],
              [-79.41275071393898, -4.927967921869717],
              [-79.40961456775142, -4.930077327783238],
              [-79.40834856509463, -4.931791929898395],
              [-79.4074245440877, -4.933517763763859],
              [-79.40569250761392, -4.934661725910303],
              [-79.40362553028893, -4.93575087040989],
              [-79.4020076553365, -4.936949650203189],
              [-79.40045163882957, -4.938437605656247],
              [-79.39929963005605, -4.940216413149813],
              [-79.39871960235577, -4.942168223125066],
              [-79.39830754799529, -4.946245013195323],
              [-79.39749594215453, -4.949611821303893],
              [-79.3971939086914, -4.950318813323975],
              [-79.39695654131268, -4.951849433500498],
              [-79.39679763195585, -4.952508641806105],
              [-79.39529458907704, -4.953535592584103],
              [-79.39459956166507, -4.955369385362587],
              [-79.39436352727144, -4.959510045535069],
              [-79.39297464591398, -4.960998839178501],
              [-79.3908466483338, -4.961797802189949],
              [-79.3896946395603, -4.963920602990129],
              [-79.38916758548814, -4.966270553366542],
              [-79.38784056257617, -4.967596402812092],
              [-79.38616954635756, -4.968849162061872],
              [-79.38381154935405, -4.969594313254845],
              [-79.38122556458396, -4.969587272456181],
              [-79.37920351426003, -4.970613049767735],
              [-79.37707551667987, -4.972099999392469],
              [-79.37545764172744, -4.973822983410798],
              [-79.375053633994, -4.976000937133961],
              [-79.37509938137876, -4.97612410402983],
              [-79.37505340576172, -4.976371765136719],
              [-79.37574005126953, -4.978218555450439],
              [-79.37740325927734, -4.979081630706787],
              [-79.37987518310547, -4.979720592498779],
              [-79.38206481933594, -4.978975296020508],
              [-79.38448333740234, -4.978293895721436],
              [-79.38723754882812, -4.978301048278809],
              [-79.38954162597656, -4.978080272674561],
              [-79.39173126220703, -4.976304531097412],
              [-79.39346313476562, -4.974870681762695],
              [-79.39398193359375, -4.972403049468994],
              [-79.39491271972656, -4.970623970031738],
              [-79.3964614868164, -4.969479084014893],
              [-79.39859771728516, -4.967585563659668],
              [-79.40032958984375, -4.965871334075928],
              [-79.40031205277813, -4.96551796220364],
              [-79.40032959831922, -4.965501597568561],
              [-79.4002322061038, -4.963531553564248],
              [-79.40131378173828, -4.96138858795166],
              [-79.40200805664062, -4.95949125289917],
              [-79.40328345481679, -4.958648478773955],
              [-79.40504454642803, -4.961218870616859],
              [-79.4079894922689, -4.963805994864592],
              [-79.41118620664346, -4.965272911833253],
              [-79.41555022149778, -4.966777074770011],
              [-79.41952513904788, -4.967959084663243],
              [-79.42309569438231, -4.968801018348193],
              [-79.4260177492093, -4.970441035264977],
              [-79.43012999220065, -4.973065193182244],
              [-79.43261717692137, -4.975546919568733],
              [-79.4350051777586, -4.978480277794469],
              [-79.43607329346423, -4.982044890172316],
              [-79.43629454418107, -4.985173249630134],
              [-79.43546294330184, -4.988759717979558],
              [-79.43294524068756, -4.991473663731695],
              [-79.4295425302799, -4.993977128070438],
              [-79.425567615247, -4.995871113830852],
              [-79.42141722184029, -4.997486662932625],
              [-79.4169311394511, -4.998554250377804],
              [-79.41242217187929, -4.998674869681511],
              [-79.40936278115242, -4.998659411686786],
              [-79.40556334546535, -4.997755929688219],
              [-79.40242766377831, -4.997010315584181],
              [-79.39891813917599, -4.996889586050848],
              [-79.39569090963737, -4.996595556309297],
              [-79.39111327102317, -4.995985836099609],
              [-79.38777922903299, -4.996143493179262],
              [-79.38515471214781, -4.999309185291457],
              [-79.38412474632969, -5.003790195574823],
              [-79.38222502297931, -5.006887910488871],
              [-79.3793716374499, -5.009053907191922],
              [-79.37627409710863, -5.010211251613596],
              [-79.37294768246132, -5.011316000921241],
              [-79.37024687906741, -5.012804437139648],
              [-79.36727141201654, -5.014457081857781],
              [-79.36435698220703, -5.016849007695107],
              [-79.36457823709739, -5.019968658153365],
              [-79.36647032818055, -5.023022982699779],
              [-79.36891173067046, -5.025730818090584],
              [-79.37174223405945, -5.028760750926888],
              [-79.3744048930183, -5.031521163117468],
              [-79.37691496951935, -5.034959107159843],
              [-79.3783035197977, -5.03812494833462],
              [-79.37941740688937, -5.041464016644998],
              [-79.3786926124821, -5.044599000525361],
              [-79.37685392760135, -5.047470899137807],
              [-79.37674712211809, -5.050998621164923],
              [-79.37786101238831, -5.054337679501234],
              [-79.37947081101215, -5.057556102927436],
              [-79.38069151635435, -5.060443389984902],
              [-79.38108061561525, -5.063850467902157],
              [-79.38080596090316, -5.067090658259793],
              [-79.38080595630979, -5.07016661865618],
              [-79.38007353520395, -5.073310696981999],
              [-79.37934874490465, -5.076445708161694],
              [-79.37819670803854, -5.08043155032058],
              [-79.3767471188544, -5.083634646432674],
              [-79.37602233174455, -5.08676967711333],
              [-79.37619017861019, -5.090124147891338],
              [-79.37709807542838, -5.094357578884125],
              [-79.37826537211761, -5.097470693981787],
              [-79.3798751738897, -5.100689020918882],
              [-79.38182067171306, -5.103517158349121],
              [-79.38403319400862, -5.106172061147385],
              [-79.3861465359119, -5.109269616655923],
              [-79.38776396900303, -5.112487812006375],
              [-79.388931268279, -5.115609855001879],
              [-79.390678398192, -5.120279392900519],
              [-79.39169310234398, -5.124060935534835],
              [-79.39282225140983, -5.128355833156659],
              [-79.39354704556774, -5.131363606592608],
              [-79.3946761970947, -5.135649458716837],
              [-79.39441679659286, -5.139845659934639],
              [-79.39487456507882, -5.143973767353614],
              [-79.39476775251694, -5.147492397246447],
              [-79.39478300813424, -5.151515316608791],
              [-79.39495085957252, -5.154869681745404],
              [-79.39495085972057, -5.157936560709172],
              [-79.39489744831927, -5.16123833583692],
              [-79.39513396032378, -5.165313839878231],
              [-79.39530180857695, -5.168668199709439],
              [-79.39514159117543, -5.172412706783971],
              [-79.39500426541801, -5.177104180159131],
              [-79.3946761966173, -5.180579250955554],
              [-79.3947906412704, -5.184150466237122],
              [-79.39490507885753, -5.187730700526308],
              [-79.39490508128726, -5.19079757836701],
              [-79.39530180742577, -5.194204457109329],
              [-79.39501952106981, -5.197444687887645],
              [-79.39458464080606, -5.201362503936521],
              [-79.3938598511055, -5.204506609642236],
              [-79.39179228848465, -5.207326356544366],
              [-79.38921355680023, -5.209319817112794],
              [-79.38525389699481, -5.212161817281674],
              [-79.38255308599405, -5.213650995943781],
              [-79.37852476896985, -5.21577184677402],
              [-79.37581633539239, -5.217261075468676],
              [-79.37329101056973, -5.219028661024199],
              [-79.37335203568831, -5.22282585869263],
              [-79.37503813691121, -5.226774071327013],
              [-79.37761686905644, -5.230932507065659],
              [-79.37938689537742, -5.233481916003126],
              [-79.38155364174796, -5.236353260743358],
              [-79.38416288826059, -5.239338740367254],
              [-79.38705443332972, -5.2421508184981],
              [-79.38994597740304, -5.24496286145413],
              [-79.39188384084376, -5.247781585991639],
              [-79.39181517647106, -5.253203245097979],
              [-79.39017485322522, -5.258257141438162],
              [-79.38862608303158, -5.261912263119561],
              [-79.38790129854632, -5.265047422115943],
              [-79.38593291035714, -5.270500570575842],
              [-79.38467405902902, -5.274929427657699],
              [-79.38367460996233, -5.278237938783467],
              [-79.38234709535287, -5.281945673133566],
              [-79.3807220324744, -5.2848707488114],
              [-79.3787765352461, -5.288194997556078],
              [-79.37650297870397, -5.29191847751433],
              [-79.37442015233871, -5.29685838364365],
              [-79.37217711525201, -5.302475980691461],
              [-79.3713989162195, -5.305846137063127],
              [-79.37012480417597, -5.309328060729985],
              [-79.36843870734579, -5.314598979511795],
              [-79.36766051406717, -5.317969159605886],
              [-79.36662291137847, -5.322450714724663],
              [-79.36634825304316, -5.325691043591857],
              [-79.3663101086634, -5.329939995436072],
              [-79.36683654145479, -5.334798327443551],
              [-79.36647032810603, -5.339437496686589],
              [-79.36643981029738, -5.343686393398138],
              [-79.36622619120467, -5.347656959169915],
              [-79.3661880363318, -5.351905911610139],
              [-79.36617277638507, -5.357101858635223],
              [-79.36594389630567, -5.363201388634891],
              [-79.36590575560508, -5.367450342424054],
              [-79.36624907295399, -5.371074145710761],
              [-79.36659239403183, -5.374697945317717],
              [-79.366928092969, -5.378330816494445],
              [-79.36732482279838, -5.381737732531588],
              [-79.3679275380059, -5.387317111775671],
              [-79.36817931407806, -5.392339733934779],
              [-79.36840055921621, -5.395468262855541],
              [-79.36852263065292, -5.399039509574185],
              [-79.3683090160933, -5.403010077753874],
              [-79.36832426872645, -5.407033119929318],
              [-79.36849974787187, -5.410387486606047],
              [-79.36802672803034, -5.415478476180886],
              [-79.3675994801778, -5.419396471858309],
              [-79.36671446310011, -5.423209536270948],
              [-79.36598967424648, -5.426344907628993],
              [-79.3654937623254, -5.429541770968195],
              [-79.36434935208241, -5.434475282787766],
              [-79.36305998370268, -5.440077380485524],
              [-79.36132049287978, -5.445583532574151],
              [-79.36054227913039, -5.448944842888773],
              [-79.35971069277109, -5.452532061803098],
              [-79.35843657295898, -5.456014253715493],
              [-79.35716246981511, -5.459496454275156],
              [-79.35588834816534, -5.46297866373197],
              [-79.35456084819792, -5.466686786893378],
              [-79.35317992269424, -5.470611804973197],
              [-79.35152435310908, -5.474719246214049],
              [-79.35030363880973, -5.477975587257189],
              [-79.34902952887198, -5.48145784327149],
              [-79.34770202374645, -5.485166015734944],
              [-79.34645843022288, -5.49054242891367],
              [-79.34516143490046, -5.496153786408975],
              [-79.34438323714951, -5.499515196010615],
              [-79.34316252804653, -5.502771591311274],
              [-79.34228514873148, -5.506584778463417],
              [-79.34124754839728, -5.511075705383064],
              [-79.34046935344814, -5.514437141525989],
              [-79.33907316432398, -5.520491315815287],
              [-79.33824918951399, -5.524078624320635],
              [-79.33736418225914, -5.527891907827389],
              [-79.33638000300694, -5.532147948269902],
              [-79.33535003452194, -5.53663887659541],
              [-79.33436583739297, -5.540894935717799],
              [-79.333656293777, -5.544986612253832],
              [-79.3328323264829, -5.548573963628707],
              [-79.3321075394387, -5.551718567414388],
              [-79.33096312517382, -5.556652405479603],
              [-79.32982633587643, -5.561586199635173],
              [-79.32910155431162, -5.564721802752932],
              [-79.32833098787653, -5.568083271971938],
              [-79.32710264983177, -5.571339864565127],
              [-79.32637785750545, -5.574484505294466],
              [-79.32469176355973, -5.579756169634407],
              [-79.32396696275923, -5.582900824878227],
              [-79.32272538357775, -5.586678941275856],
              [-79.32139587402344, -5.586514472961426],
              [-79.31816101074219, -5.582616329193115],
              [-79.31517028808594, -5.58284854888916],
              [-79.30987548828125, -5.583770751953125],
              [-79.3052749633789, -5.586986541748047],
              [-79.30342864990234, -5.585611343383789],
              [-79.3004379272461, -5.586761474609375],
              [-79.29491424560547, -5.587454795837402],
              [-79.29376983642578, -5.589290618896484],
              [-79.29216766357422, -5.594569683074951],
              [-79.29148864746094, -5.598699569702148],
              [-79.28919219970703, -5.602373600006104],
              [-79.2857437133789, -5.606735706329346],
              [-79.2855224609375, -5.610866546630859],
              [-79.28760528564453, -5.616142749786377],
              [-79.29106903076172, -5.619581699371338],
              [-79.29130554199219, -5.623482704162598],
              [-79.28878021240234, -5.628303527832031],
              [-79.28441619873047, -5.63266658782959],
              [-79.27981567382812, -5.636112689971924],
              [-79.27568054199219, -5.640017509460449],
              [-79.26809692382812, -5.647826671600342],
              [-79.26696014404297, -5.654252529144287],
              [-79.26996612548828, -5.661365509033203],
              [-79.27389526367188, -5.666640758514404],
              [-79.27828979492188, -5.676276683807373],
              [-79.27972609878404, -5.679784737513875],
              [-79.27960204968166, -5.680027194478505],
              [-79.277969352985, -5.68508235059543],
              [-79.27693174760377, -5.689564744842018],
              [-79.27615355437818, -5.692935568437965],
              [-79.27551268568413, -5.697748828539675],
              [-79.27530669015314, -5.701719853299619],
              [-79.27526855202801, -5.705969299008459],
              [-79.2750625589239, -5.709940326592704],
              [-79.27522292849933, -5.712998232966191],
              [-79.26983642578125, -5.710023403167725],
              [-79.26570129394531, -5.714388370513916],
              [-79.26594543457031, -5.722192764282227],
              [-79.2657241821289, -5.727931499481201],
              [-79.26713562011719, -5.739408493041992],
              [-79.27058477721995, -5.745903780738238],
              [-79.2682876450265, -5.748540282712041],
              [-79.26531982480859, -5.751151720935982],
              [-79.26273345922947, -5.75314683185504],
              [-79.26015470979156, -5.75513286537595],
              [-79.25734710488139, -5.757075503091316],
              [-79.25499725288701, -5.76007026881252],
              [-79.25360107629163, -5.763048887583708],
              [-79.25155639096674, -5.766826287133467],
              [-79.25050353203265, -5.770361707941564],
              [-79.24971006888273, -5.775852937831285],
              [-79.24967193282225, -5.780093498282852],
              [-79.24979400195575, -5.783674261469466],
              [-79.24975585308358, -5.787923843287816],
              [-79.24999237014283, -5.791999946912445],
              [-79.25044250102971, -5.79518127882833],
              [-79.2505645625479, -5.798753013322966],
              [-79.25152586369215, -5.802760500019778],
              [-79.25174712963523, -5.805889410620147],
              [-79.25219724943955, -5.809070725374009],
              [-79.25259398031218, -5.812477984156858],
              [-79.25253816412896, -5.814317140396227],
              [-79.25023651123047, -5.814956665039062],
              [-79.24517059326172, -5.813583374023438],
              [-79.24102783203125, -5.816571712493896],
              [-79.24019800428073, -5.819901828544796],
              [-79.24011992633555, -5.819464708030493],
              [-79.23894500064718, -5.816351947694662],
              [-79.23772430315111, -5.813465069298124],
              [-79.2356719870256, -5.811089657758474],
              [-79.23272703370537, -5.808504394072674],
              [-79.23040769938996, -5.806302356622881],
              [-79.22775268648039, -5.804490760573421],
              [-79.22453308051412, -5.802078879009326],
              [-79.22193144931526, -5.800041273882257],
              [-79.22008513316574, -5.796770985599238],
              [-79.21957397395725, -5.792868186018429],
              [-79.2198486217967, -5.78961814469185],
              [-79.22023009562092, -5.785925221615205],
              [-79.22033690406151, -5.782405768681455],
              [-79.21883391519928, -5.779683227294116],
              [-79.21554563197216, -5.779617461042533],
              [-79.21251677148993, -5.781507584279429],
              [-79.20980834808205, -5.782998343191638],
              [-79.20664215558486, -5.78342786044098],
              [-79.20322416435671, -5.782857745478326],
              [-79.19964599869392, -5.782018118598242],
              [-79.19638823711414, -5.780779100872775],
              [-79.19258116216048, -5.779877948807518],
              [-79.19051359201362, -5.782699242356677],
              [-79.18940732826847, -5.786460802112301],
              [-79.18863675611126, -5.789822893302387],
              [-79.18820189964954, -5.793741903469508],
              [-79.18842316255117, -5.796871093722787],
              [-79.18864440508422, -5.800000282134858],
              [-79.18843840553731, -5.803971735158674],
              [-79.1904678325354, -5.808467946844661],
              [-79.19253538168194, -5.811799940420132],
              [-79.19422148809772, -5.815739254342112],
              [-79.19516753362714, -5.818799856221705],
              [-79.19596097723331, -5.82253827171234],
              [-79.1951522620006, -5.827073583961262],
              [-79.19444274516741, -5.831166061839402],
              [-79.19361876689068, -5.834754140676894],
              [-79.19318389053545, -5.838673152015485],
              [-79.19335173591473, -5.842028267466522],
              [-79.19350433616107, -5.847503781624275],
              [-79.19335174011073, -5.851249259195408],
              [-79.19342039555559, -5.855047215703585],
              [-79.19443511420033, -5.858838079477218],
              [-79.195465081949, -5.863576176414078],
              [-79.19569397621503, -5.866696232287921],
              [-79.19598389312688, -5.870555669604163],
              [-79.19565581743981, -5.874022762105326],
              [-79.19174192593836, -5.876650657523831],
              [-79.18871306415721, -5.878532054322468],
              [-79.18518064664045, -5.880543545705978],
              [-79.18207547765078, -5.881703699887853],
              [-79.1793212802091, -5.883411595151033],
              [-79.17654419648221, -5.887258035646309],
              [-79.17345425951345, -5.892442197163],
              [-79.17152403387762, -5.896715405528633],
              [-79.16925049468263, -5.900431792823564],
              [-79.167373633056, -5.904488080423207],
              [-79.16559601669958, -5.908092495229635],
              [-79.16397093527361, -5.911019063310089],
              [-79.16302489108476, -5.914102950400516],
              [-79.16207884443325, -5.917195865864737],
              [-79.16124724980439, -5.92078418357068],
              [-79.15952299574498, -5.924162668671864],
              [-79.15790555512497, -5.927089215469988],
              [-79.15549468199897, -5.929363060600427],
              [-79.15319824323949, -5.932132310523161],
              [-79.15040586168382, -5.935022591385661],
              [-79.14727018348208, -5.937356030332167],
              [-79.1433029291091, -5.940201002637015],
              [-79.13896177500429, -5.943671373074678],
              [-79.13484191746787, -5.947194225536899],
              [-79.13254547904245, -5.949963510198366],
              [-79.12980650223267, -5.952636863868835],
              [-79.12646484370606, -5.955865156486658],
              [-79.12394714172001, -5.958581977693653],
              [-79.12120818805668, -5.961246322144959],
              [-79.11856841324635, -5.963467785066371],
              [-79.11503601873808, -5.965470349162675],
              [-79.11043548493126, -5.966984611438332],
              [-79.10678099175031, -5.96849178106154],
              [-79.10288236657325, -5.968990131847344],
              [-79.09977721516091, -5.970150279204199],
              [-79.09633635857135, -5.970762484329382],
              [-79.09305573533649, -5.971644186747854],
              [-79.08956145422879, -5.972473326144854],
              [-79.08650969874036, -5.973407440358234],
              [-79.08238982131654, -5.973862208909376],
              [-79.07926940490287, -5.974074906066518],
              [-79.07575224320085, -5.973947628907523],
              [-79.07141112542367, -5.974349822846579],
              [-79.066619854005, -5.974638011344094],
              [-79.06189728493369, -5.975647568489324],
              [-79.05844880094254, -5.976259642149389],
              [-79.0549468998422, -5.977088670492208],
              [-79.05043028985637, -5.977203250631463],
              [-79.0463638220194, -5.977431788238479],
              [-79.04184723019152, -5.977546300074149],
              [-79.03810881347822, -5.977375297695879],
              [-79.03481291926083, -5.977309304783418],
              [-79.03062440139232, -5.97703326690483],
              [-79.02671813601476, -5.9765836602727],
              [-79.02101135233956, -5.975705022592285],
              [-79.01659392535649, -5.975376394465509],
              [-79.0122375399761, -5.974821668434321],
              [-79.00798034467066, -5.973814950512491],
              [-79.00372313938666, -5.972817213622242],
              [-78.99901581937819, -5.971705391006181],
              [-78.9949798627615, -5.970751076624534],
              [-78.99028013603069, -5.969639099902194],
              [-78.98719022391181, -5.968677836174986],
              [-78.98326112061211, -5.967280415838391],
              [-78.97923279226252, -5.966325890047643],
              [-78.97519681907318, -5.965371380563667],
              [-78.97183227954359, -5.964583455784359],
              [-78.96714783441593, -5.964418726821534],
              [-78.96408081702764, -5.964404747626383],
              [-78.96006010028053, -5.964406645824122],
              [-78.95664976093813, -5.963835541404076],
              [-78.95268249782352, -5.963611369448749],
              [-78.94949340051906, -5.963092772912771],
              [-78.9449310177886, -5.963432376271395],
              [-78.94175718990478, -5.963870296547408],
              [-78.93869018834417, -5.963856172067241],
              [-78.93532560549774, -5.963058956837006],
              [-78.93203735824773, -5.962992222864098],
              [-78.92827605522872, -5.96187287594977],
              [-78.92555235672074, -5.959338123956554],
              [-78.92326355829427, -5.955951850335542],
              [-78.92253874248797, -5.952942510281154],
              [-78.92254638903182, -5.949873798856619],
              [-78.92327118274962, -5.946727782020997],
              [-78.92506409407322, -5.944079596375627],
              [-78.92830656955512, -5.944372407288562],
              [-78.9301528815224, -5.941489185547705],
              [-78.93103025297351, -5.937674229785353],
              [-78.9328308202793, -5.935026007210208],
              [-78.93670652659621, -5.933572407049626],
              [-78.94029235636047, -5.934422304384801],
              [-78.94377134060245, -5.935715170379412],
              [-78.94709777269006, -5.937685983941063],
              [-78.95068360469813, -5.938535785259032],
              [-78.9533920148502, -5.937045304328973],
              [-78.95603180936938, -5.934824277263597],
              [-78.95696258930579, -5.930783393866765],
              [-78.95784759517335, -5.926968472192962],
              [-78.96006774335197, -5.923477935274819],
              [-78.96370694947942, -5.921024157638064],
              [-78.96700284909815, -5.921090807957605],
              [-78.97015379123336, -5.922782969495509],
              [-78.97318265640406, -5.923970580785438],
              [-78.97621915632604, -5.922080623921463],
              [-78.97818754828728, -5.919710993682377],
              [-78.98048401326399, -5.916941917648759],
              [-78.98289487605058, -5.914668390439204],
              [-78.98646544526743, -5.91149307721618],
              [-78.98970793506317, -5.908708190704622],
              [-78.99246213882012, -5.90699173504151],
              [-78.99556730805271, -5.905832281899857],
              [-78.99896240660115, -5.905446851783008],
              [-79.00342557925623, -5.905559081630342],
              [-79.00694273610956, -5.90567810245685],
              [-79.01089475733387, -5.904954678084582],
              [-79.01377105590757, -5.903742661531671],
              [-79.01531980438271, -5.900085579972411],
              [-79.01654813992576, -5.896827898222167],
              [-79.01772308101961, -5.893787195537913],
              [-79.01982880574987, -5.889792212595524],
              [-79.02267455931083, -5.88667628857125],
              [-79.02542876363606, -5.884968833246419],
              [-79.02882384662077, -5.884583318652002],
              [-79.03211973165311, -5.884649718762071],
              [-79.0365982075827, -5.885709222495638],
              [-79.03963470488483, -5.886896454046481],
              [-79.04366301488062, -5.887850854244714],
              [-79.04719542052223, -5.888917110328452],
              [-79.05055235641063, -5.889713888896912],
              [-79.05503845668981, -5.890764112150954],
              [-79.05884550715382, -5.891665832942889],
              [-79.06218719488562, -5.89150608970712],
              [-79.06551358682958, -5.890398927406431],
              [-79.06803129134316, -5.887682386892795],
              [-79.06930541865786, -5.884198882817532],
              [-79.07008359323856, -5.880836317852529],
              [-79.06986234900407, -5.877706647946301],
              [-79.06781005812574, -5.875330285639422],
              [-79.06546781261035, -5.87217995784532],
              [-79.06412505982654, -5.868787708312526],
              [-79.06278990303629, -5.865395388973414],
              [-79.06294249124144, -5.861649338336255],
              [-79.06358336915905, -5.856825893511814],
              [-79.06451416166895, -5.852794458362759],
              [-79.06574247171076, -5.849527906247802],
              [-79.06719206371292, -5.846322923422988],
              [-79.06896973611586, -5.842718517375292],
              [-79.0696945046178, -5.839581972841566],
              [-79.06945036809012, -5.83550494549118],
              [-79.06871030578745, -5.831548815671703],
              [-79.06658170685526, -5.827494430477488],
              [-79.0640640029362, -5.824065477689751],
              [-79.06234741830612, -5.821289463716381],
              [-79.06217193871453, -5.817933812497948],
              [-79.06295013434762, -5.814571287192008],
              [-79.06427762737508, -5.810861899350235],
              [-79.06516264313964, -5.807047419750084],
              [-79.06498716986297, -5.803700800972234],
              [-79.06354523545765, -5.800751318961853],
              [-79.06182098400699, -5.797984330647837],
              [-79.05987549299371, -5.795155745327114],
              [-79.05758667720742, -5.791779135976903],
              [-79.05477905500678, -5.787566976102567],
              [-79.0523376547824, -5.784859176762951],
              [-79.04972077422288, -5.781881880811898],
              [-79.04821777569816, -5.779158304205633],
              [-79.04737853335372, -5.775644879129072],
              [-79.0469817940625, -5.772245605761767],
              [-79.04701995427088, -5.767994961295947],
              [-79.04730223343611, -5.764744259386815],
              [-79.04690551243701, -5.761344979605194],
              [-79.04673767173722, -5.757989189686138],
              [-79.04739378494872, -5.754122191363346],
              [-79.04822538525042, -5.750533700020327],
              [-79.05044555787397, -5.747034619249696],
              [-79.05261993480653, -5.743770500026482],
              [-79.05496977430498, -5.740775865262315],
              [-79.05718992627179, -5.737276812800983],
              [-79.05936432542184, -5.73400369572028],
              [-79.0615387070108, -5.730730587631985],
              [-79.06286622257937, -5.727021327253883],
              [-79.06242368911127, -5.723848027086165],
              [-79.05899048404247, -5.72232926857258],
              [-79.05563353395097, -5.721531861335483],
              [-79.05330658876437, -5.719328407865171],
              [-79.05341336684087, -5.715799235959615],
              [-79.05413817603961, -5.71266275706275],
              [-79.05491636632406, -5.70930030380927],
              [-79.05574797368133, -5.705711876990334],
              [-79.05874634353512, -5.70191865339425],
              [-79.06192015680946, -5.698412970227341],
              [-79.06404111424256, -5.695365931804926],
              [-79.06587216954003, -5.691544889026485],
              [-79.06735230094415, -5.687157826903281],
              [-79.06899262270034, -5.682101876331935],
              [-79.06853485757523, -5.677972149559864],
              [-79.06813812714319, -5.674572925705483],
              [-79.068191514278, -5.671269792260309],
              [-79.06774901120701, -5.668087459538386],
              [-79.06504820692547, -5.666509215403014],
              [-79.06105039324919, -5.667457753518669],
              [-79.05706786581008, -5.669353691051459],
              [-79.05504609020149, -5.671948763675994],
              [-79.05325317801882, -5.674605404164923],
              [-79.05113220429399, -5.677652390109956],
              [-79.04933927360835, -5.680300019246409],
              [-79.04727172565127, -5.683121040748749],
              [-79.04353332019342, -5.68602598756434],
              [-79.0401000968507, -5.68758418497008],
              [-79.03649901630119, -5.688854774268902],
              [-79.03240202109461, -5.690255164869066],
              [-79.02922819968475, -5.69069249893853],
              [-79.02597044133199, -5.692520161441392],
              [-79.022560104376, -5.695025718129695],
              [-79.02026366387075, -5.697794224050686],
              [-79.02100372874685, -5.701759804973092],
              [-79.02349853987343, -5.704242108752332],
              [-79.02604672860726, -5.706498402648855],
              [-79.0266647199059, -5.709959431222025],
              [-79.02568052756952, -5.714216822202352],
              [-79.02462766316536, -5.717752751643312],
              [-79.02307129094036, -5.721409541779131],
              [-79.0212249601416, -5.724283252223322],
              [-79.01844786539498, -5.728120099404348],
              [-79.01605222653237, -5.731340614026172],
              [-79.01315307484504, -5.734681991584401],
              [-79.0094680734845, -5.737361076817143],
              [-79.00632475721336, -5.739693426724065],
              [-79.00373841570311, -5.741678934586175],
              [-79.00257108814854, -5.744719398441231],
              [-79.00218200316381, -5.748413205398693],
              [-79.00270080588203, -5.752317229667884],
              [-79.00378416184222, -5.756830953129353],
              [-79.00462342286956, -5.760344648872441],
              [-79.00329589437466, -5.764054086482778],
              [-79.0011215219816, -5.767327287856362],
              [-78.99922179160366, -5.770427095491467],
              [-78.99760434690941, -5.77335345409861],
              [-78.99666593951771, -5.777394099306009],
              [-78.9962310778052, -5.781313881690322],
              [-78.99652097593375, -5.785165383027138],
              [-78.9961929245466, -5.788633180807762],
              [-78.9958114586045, -5.792326975583983],
              [-78.99477385113701, -5.796819582869989],
              [-78.99384306377756, -5.800860208018213],
              [-78.99279020784995, -5.804396318490091],
              [-78.99121856222482, -5.807096802924165],
              [-78.98905943252842, -5.811317608312389],
              [-78.98711394438644, -5.814643461446272],
              [-78.98521422919055, -5.817743379099647],
              [-78.98336792601245, -5.820617305683299],
              [-78.98176573778663, -5.82450029507745],
              [-78.97886658646475, -5.827841953063009],
              [-78.97702027376727, -5.830715902372719],
              [-78.97539521337809, -5.833642442515727],
              [-78.97260284728596, -5.836532121474976],
              [-78.97020720117492, -5.839752924132664],
              [-78.96791075243756, -5.84252178350342],
              [-78.9655532939721, -5.844569078243683],
              [-78.96258545125423, -5.847180241300393],
              [-78.95987702067563, -5.848670464136111],
              [-78.95689390271477, -5.850325074910544],
              [-78.95378876796974, -5.851493184677533],
              [-78.95034025748012, -5.852095120816405],
              [-78.94672391912907, -5.852418441105706],
              [-78.94338227235778, -5.85257735239164],
              [-78.93957517653892, -5.851683558668696],
              [-78.93621064367223, -5.85088590089902],
              [-78.93240354718269, -5.849983017448196],
              [-78.92964936161627, -5.848621449041382],
              [-78.92575073109548, -5.846049436965671],
              [-78.92298126254424, -5.843740243989857],
              [-78.92121125745668, -5.841189377322939],
              [-78.91998290847386, -5.83830076940513],
              [-78.91867827453493, -5.833733935093644],
              [-78.91728969857877, -5.830557600872509],
              [-78.91523738544709, -5.828180121471603],
              [-78.91203310709659, -5.826713191731279],
              [-78.90877534100994, -5.825472244726597],
              [-78.90543361709746, -5.825630829790959],
              [-78.90245053048004, -5.827294200517693],
              [-78.89963531036864, -5.829227174831715],
              [-78.89644621073687, -5.831785516211679],
              [-78.89351654574546, -5.833222862942696],
              [-78.89019012479312, -5.834328995528228],
              [-78.8870162950349, -5.834766147689228],
              [-78.882781976385, -5.834714189933754],
              [-78.87903597229078, -5.834532465585275],
              [-78.87584684270986, -5.834021938765848],
              [-78.87271115545549, -5.833276345424071],
              [-78.86962889854445, -5.832313736186655],
              [-78.86654665062504, -5.831342076859949],
              [-78.8633956571333, -5.829657803333178],
              [-78.86127472353185, -5.826549328045792],
              [-78.85927584397388, -5.823945457049478],
              [-78.85704039931568, -5.820341281345013],
              [-78.85542294529533, -5.817120977178498],
              [-78.8539809834153, -5.814179269170181],
              [-78.85158535787434, -5.811244059004125],
              [-78.84936522031505, -5.808587439376781],
              [-78.84690095480441, -5.804930542779439],
              [-78.84551241290791, -5.801762741699403],
              [-78.84422299867546, -5.798142937128087],
              [-78.84346771485234, -5.793228726176151],
              [-78.8432312038254, -5.789150433436268],
              [-78.84228516533427, -5.786087898959546],
              [-78.84184266376084, -5.782913627590744],
              [-78.84182738427782, -5.778878956383735],
              [-78.84164427254565, -5.774583656529416],
              [-78.8421783315017, -5.770211293929276],
              [-78.84255982367802, -5.766516942256858],
              [-78.8432082928256, -5.761701524642716],
              [-78.84355925262129, -5.756102830794021],
              [-78.84383393292002, -5.752851499758346],
              [-78.84454347461141, -5.7487668168922],
              [-78.84520724094126, -5.744899079499758],
              [-78.84553527552396, -5.741421739599498],
              [-78.84597017669347, -5.737501393479776],
              [-78.8470611569447, -5.73279134850353],
              [-78.84783936507709, -5.729428333028234],
              [-78.84882356872306, -5.725161309529318],
              [-78.84981537081629, -5.720903268922672],
              [-78.8509750577587, -5.716914896189097],
              [-78.85180660915512, -5.713325889522826],
              [-78.8527374314661, -5.709284910073133],
              [-78.85303499238844, -5.703912301831199],
              [-78.85272978562871, -5.699112387779251],
              [-78.85261534253523, -5.695529735820367],
              [-78.85221864255244, -5.692129495964021],
              [-78.85163879485594, -5.687493879935883],
              [-78.85092161603326, -5.684483901092255],
              [-78.85023501520257, -5.680300296845269],
              [-78.85006712238207, -5.676943643527144],
              [-78.8500518533732, -5.672918027143664],
              [-78.84997556932711, -5.668170714561513],
              [-78.85041043584427, -5.664250416916918],
              [-78.85012051144882, -5.660389100312503],
              [-78.85055540666228, -5.656468806376624],
              [-78.85049435216762, -5.652669151272232],
              [-78.85064695208487, -5.648922234946352],
              [-78.85098266627787, -5.645453919321985],
              [-78.85147095394557, -5.641307624469518],
              [-78.85179902787057, -5.637830338516663],
              [-78.8523559349205, -5.634414722994305],
              [-78.85291291907659, -5.630999110620487],
              [-78.85329435216399, -5.627304849887739],
              [-78.8540573354825, -5.622985257920166],
              [-78.8546142728348, -5.619569657622591],
              [-78.85554501223186, -5.61552878149883],
              [-78.85581209294745, -5.611329822642745],
              [-78.85619350802233, -5.60763557991245],
              [-78.85739134091989, -5.602473708952489],
              [-78.85858156977021, -5.597320925713928],
              [-78.859573364972, -5.59305402730952],
              [-78.860832181019, -5.588622907288641],
              [-78.86225125045449, -5.583513780273162],
              [-78.86313629586952, -5.579698918506765],
              [-78.86462399571626, -5.575320479417546],
              [-78.86579895224675, -5.57227997368239],
              [-78.86657716876346, -5.568917141003777],
              [-78.86685943144477, -5.565674910442998],
              [-78.86713411385388, -5.562423705337272],
              [-78.86762240008908, -5.558277515533478],
              [-78.8684539902091, -5.554688696830762],
              [-78.8693924123358, -5.550656904821595],
              [-78.86966705984469, -5.547405712271188],
              [-78.8703460591177, -5.5414169702498],
              [-78.87098692872172, -5.536592849643427],
              [-78.87114712108975, -5.532845980186851],
              [-78.87213894244879, -5.52858821824882],
              [-78.87262725695048, -5.524442065006902],
              [-78.87340548367709, -5.52107929045598],
              [-78.87429049038695, -5.517264521762459],
              [-78.87516783148521, -5.513449809353563],
              [-78.87599942399905, -5.50986105213265],
              [-78.87709045952651, -5.505151383657871],
              [-78.87782289512546, -5.502005559589483],
              [-78.87925716957437, -5.497853284686957],
              [-78.8801422022575, -5.494038555562087],
              [-78.88141628397968, -5.490555263216701],
              [-78.88264467866404, -5.487297924478607],
              [-78.8844375270143, -5.484641660379254],
              [-78.88695527676637, -5.481926531642725],
              [-78.88947293448379, -5.479211408706874],
              [-78.89319610604441, -5.478438030105923],
              [-78.89655300386569, -5.479228487786259],
              [-78.89981078922807, -5.480479881701095],
              [-78.90334316256467, -5.481548939737745],
              [-78.90737153249489, -5.482506415999222],
              [-78.91034695290328, -5.483922032362748],
              [-78.91392513770938, -5.484765058973943],
              [-78.91818239497385, -5.485775087776003],
              [-78.92266083685836, -5.486837775851516],
              [-78.9271850427934, -5.487674480481031],
              [-78.93189237492687, -5.488780698370584],
              [-78.93530273263474, -5.489353897479546],
              [-78.94022369882808, -5.490521797401875],
              [-78.94363404161562, -5.491094935879563],
              [-78.94721221631123, -5.491937697704429],
              [-78.95101926861219, -5.492842091065362],
              [-78.95430755466609, -5.492910548878451],
              [-78.95751191226924, -5.491300864526527],
              [-78.96054840239654, -5.489421535384441],
              [-78.96353148814993, -5.487759161203853],
              [-78.96716306412358, -5.488384840654326],
              [-78.97035216063266, -5.48890513607022],
              [-78.97391509289997, -5.488800196826823],
              [-78.97713470674745, -5.488137978011549],
              [-78.98025512646252, -5.487936679032756],
              [-78.98358152525677, -5.486831502298205],
              [-78.98611450034515, -5.485063773501021],
              [-78.98828888609218, -5.481791291832385],
              [-78.98968505690907, -5.478812806717874],
              [-78.99163817195036, -5.475487669717464],
              [-78.99330901971409, -5.472335895692357],
              [-78.99665070460419, -5.469100783850108],
              [-79.00012206870231, -5.466370196997497],
              [-79.00287626867446, -5.464664189977304],
              [-79.00627136809165, -5.464280521339675],
              [-79.0098342766449, -5.464175476609642],
              [-79.0135040065945, -5.463627492791807],
              [-79.01689908497247, -5.463243772792225],
              [-79.02061461247487, -5.462469835032844],
              [-79.02483368337836, -5.46157518405716],
              [-79.02910614633367, -5.460463568298295],
              [-79.03282926615387, -5.459680502871347],
              [-79.03665160729874, -5.458463586945544],
              [-79.03982542590056, -5.458027110840198],
              [-79.04331970630653, -5.457200420129258],
              [-79.04752350988929, -5.455358223041108],
              [-79.05027771396391, -5.453643107086487],
              [-79.05313871442476, -5.451476063016798],
              [-79.05516052050696, -5.448881525928214],
              [-79.05654142252551, -5.444946728932272],
              [-79.05831909391527, -5.441352181535592],
              [-79.0593566743909, -5.436860226851559],
              [-79.06089017514623, -5.43225665267698],
              [-79.0619277931236, -5.427773742345023],
              [-79.06291959864845, -5.42350772606889],
              [-79.06395719706016, -5.419024833985784],
              [-79.06494138518255, -5.414758886188554],
              [-79.0667038063158, -5.410207975096528],
              [-79.06742859783694, -5.407071785865988],
              [-79.06906890266401, -5.402016375532575],
              [-79.07091521274384, -5.396066228709027],
              [-79.07278440487634, -5.391072484601535],
              [-79.07401273428492, -5.387806617801278],
              [-79.07616422907418, -5.383587063807356],
              [-79.0785827407778, -5.378246777083722],
              [-79.08189391843342, -5.3731171056324],
              [-79.0851516661623, -5.368213409889696],
              [-79.08858491020267, -5.363579272779279],
              [-79.09156798183673, -5.358848908911747],
              [-79.09444428582816, -5.354561440134599],
              [-79.09700009928696, -5.350673174318596],
              [-79.09955597146691, -5.34677589805616],
              [-79.10195160458167, -5.343565501666554],
              [-79.10449980340437, -5.339668301866166],
              [-79.1068496477155, -5.33667479799053],
              [-79.10935971984465, -5.333959958859538],
              [-79.11209870194617, -5.331297734448935],
              [-79.11446380303398, -5.32925161488142],
              [-79.11754608544973, -5.327146553182529],
              [-79.12025452675556, -5.325657588340365],
              [-79.12355805307915, -5.323605182987015],
              [-79.12641907820833, -5.321438441260183],
              [-79.12877655158614, -5.319392382436197],
              [-79.13078306723675, -5.315850816419025],
              [-79.13234710360356, -5.313142368649608],
              [-79.13442230464878, -5.310322220721685],
              [-79.13688657393408, -5.307833329986853],
              [-79.13946531878159, -5.30583995182289],
              [-79.14244841803082, -5.304177718707861],
              [-79.14572144233898, -5.303298547713823],
              [-79.1500701945193, -5.303855827250579],
              [-79.15331266881493, -5.304149824448312],
              [-79.15694428368425, -5.304775031761819],
              [-79.16052245651709, -5.305617122856907],
              [-79.16422271160012, -5.306972667002526],
              [-79.16757963220265, -5.307762055699722],
              [-79.17070769966557, -5.308507852868914],
              [-79.17439268219596, -5.308906992279073],
              [-79.17794036629945, -5.307854373546903],
              [-79.18052672046709, -5.305860873399086],
              [-79.183036811852, -5.303146076816663],
              [-79.18426512019275, -5.299889588651267],
              [-79.18319701704647, -5.296323837751549],
              [-79.18170929051576, -5.293609046821777],
              [-79.1784362842443, -5.291411574510374],
              [-79.17540740473343, -5.290222970997292],
              [-79.1722793545142, -5.28947712988318],
              [-79.16792297519393, -5.288919996227683],
              [-79.16468809329072, -5.288625968575909],
              [-79.1608963103318, -5.288678536815193],
              [-79.15671537461115, -5.288399844765162],
              [-79.15344236822969, -5.289279001350731],
              [-79.14900205813076, -5.290120859535045],
              [-79.14572907202147, -5.290999990373287],
              [-79.14141080310652, -5.292346290665551],
              [-79.13831328985573, -5.293503943553212],
              [-79.13488004788277, -5.295051834135311],
              [-79.13217925839731, -5.296540682948218],
              [-79.12809751113693, -5.298886948219242],
              [-79.1235656590987, -5.301127935221227],
              [-79.11993409253101, -5.303579467987844],
              [-79.11717224348995, -5.305294300350154],
              [-79.11447143111239, -5.30678313825915],
              [-79.10997771108518, -5.307841771864595],
              [-79.10496519339982, -5.308082692681396],
              [-79.10107419953005, -5.308577804009707],
              [-79.09757996412267, -5.30940413443244],
              [-79.09337615835108, -5.311245817818713],
              [-79.09084319371104, -5.313013235733266],
              [-79.08715819391101, -5.315690675444536],
              [-79.08479307973334, -5.317736719084166],
              [-79.08255767916833, -5.320278215130429],
              [-79.08053589841468, -5.32287244289685],
              [-79.07803344430664, -5.326543705675292],
              [-79.07596585904619, -5.329363849404668],
              [-79.07439420928448, -5.332063399107509],
              [-79.07238006342567, -5.334666627976233],
              [-79.06986236191597, -5.337381491267771],
              [-79.06767271827727, -5.339706153354131],
              [-79.06448365096982, -5.342263048460068],
              [-79.06152342909287, -5.344872573918455],
              [-79.05840299947182, -5.348150947836412],
              [-79.0551605004196, -5.350933813048943],
              [-79.0522002962188, -5.353543353814907],
              [-79.04950713115417, -5.355979670727453],
              [-79.04709626430264, -5.358251686406668],
              [-79.04485319720528, -5.360793321678584],
              [-79.0428543106194, -5.364344061983214],
              [-79.0407333459903, -5.367390261973293],
              [-79.0387115250825, -5.369984598576711],
              [-79.03636930377857, -5.372978133169531],
              [-79.03390502069952, -5.375476144725715],
              [-79.03021239040834, -5.378144731405835],
              [-79.02767944266525, -5.379912228431388],
              [-79.02458191047732, -5.381069797338062],
              [-79.0214767241794, -5.382227409625021],
              [-79.01825714474094, -5.382889421659748],
              [-79.01521300386543, -5.383821000288302],
              [-79.01176452848203, -5.384421327864019],
              [-79.00749204979051, -5.385541475613959],
              [-79.00354765908847, -5.386262358270354],
              [-78.99983214777022, -5.387035864450925],
              [-78.99621582378401, -5.387366489319802],
              [-78.99321745251099, -5.388072077397537],
              [-78.98920440504583, -5.38807135488321],
              [-78.98603821092016, -5.388507271222056],
              [-78.98286437912336, -5.38893419940066],
              [-78.97975158726643, -5.389144115836377],
              [-78.97523498982692, -5.389254902868176],
              [-78.97134398854845, -5.389758670417311],
              [-78.96767425632446, -5.390306097968714],
              [-78.96411133823854, -5.390410580739627],
              [-78.96071625536018, -5.390784703547547],
              [-78.95711518507755, -5.392062670385652],
              [-78.95385739897988, -5.393897955069819],
              [-78.95111845522305, -5.396560181855896],
              [-78.94810485622027, -5.399395681450319],
              [-78.94546508840843, -5.401606007269834],
              [-78.94171905297122, -5.404509457382294],
              [-78.9389648386839, -5.406215110988131],
              [-78.93488311185362, -5.408561168948564],
              [-78.9309539821235, -5.410238346737049],
              [-78.92717743879152, -5.411237611684984],
              [-78.92340850065072, -5.412236812502298],
              [-78.91968535780661, -5.413010062363792],
              [-78.91586301168999, -5.414235219122255],
              [-78.91208646080278, -5.41523442482263],
              [-78.90903471097981, -5.416165751014245],
              [-78.90476228292204, -5.417285467621476],
              [-78.9017639159889, -5.417990783116],
              [-78.89859767865104, -5.41842639719773],
              [-78.89443202898813, -5.419094084070743],
              [-78.89091487748972, -5.418972321645231],
              [-78.8874053944372, -5.418841462348931],
              [-78.88381957108462, -5.417998021019952],
              [-78.88024142519473, -5.417145475890369],
              [-78.87714384333975, -5.41523377566606],
              [-78.8732528812886, -5.412650169274455],
              [-78.86986544701283, -5.409954982425688],
              [-78.86623380073584, -5.406250385173055],
              [-78.86391445896246, -5.404044743003189],
              [-78.86129758969554, -5.401064721347696],
              [-78.85878752325382, -5.397623653124279],
              [-78.85793303332764, -5.393161098690455],
              [-78.85787200182112, -5.389361439785312],
              [-78.85820006251683, -5.385893282502985],
              [-78.85886383192744, -5.382025844533045],
              [-78.86006163219203, -5.376864277592969],
              [-78.86078639771199, -5.373718562885747],
              [-78.86174007902935, -5.370634586293259],
              [-78.86334228093168, -5.366752204745774],
              [-78.86491393574101, -5.364052454708478],
              [-78.86681367252969, -5.360953483077476],
              [-78.8694229419906, -5.356839102176784],
              [-78.87076566124051, -5.354077639259953],
              [-78.87249756732714, -5.350699945155072],
              [-78.87372592260368, -5.34744278379549],
              [-78.87450412013199, -5.344080162616756],
              [-78.87522886758416, -5.340943535763773],
              [-78.87615205091264, -5.335955318233077],
              [-78.87619019943193, -5.331703805300497],
              [-78.87618258064444, -5.327678232436988],
              [-78.87601469480737, -5.324321597433446],
              [-78.87490083407533, -5.320979943656584],
              [-78.87428285046322, -5.317517820454276],
              [-78.8733368073643, -5.314454901411813],
              [-78.87267300146566, -5.311218705389153],
              [-78.87100984824782, -5.308223453747327],
              [-78.8691177521044, -5.305175464115354],
              [-78.86700439785677, -5.302065657303792],
              [-78.86478420924946, -5.299407804888101],
              [-78.86301421756757, -5.296864458239076],
              [-78.8602829235522, -5.293370316074127],
              [-78.85851290585684, -5.290826930532945],
              [-78.85707094613844, -5.287875304022806],
              [-78.85551450559683, -5.284427931002217],
              [-78.85434720165517, -5.281312095409937],
              [-78.85328672797166, -5.277744270177554],
              [-78.8533935690604, -5.274223347903146],
              [-78.8538284508278, -5.270303234184725],
              [-78.85540008296098, -5.267603650835959],
              [-78.85838314509516, -5.265942363873035],
              [-78.86148073063355, -5.264785831403415],
              [-78.86480710352913, -5.263682027471825],
              [-78.86846160565021, -5.262179023612942],
              [-78.87149044489273, -5.260291779930629],
              [-78.87382505288984, -5.256350905042186],
              [-78.87454988405979, -5.253214345557781],
              [-78.87559511929864, -5.248721888152291],
              [-78.87631988897623, -5.245585339531293],
              [-78.87670899259611, -5.241891262876324],
              [-78.87586977938425, -5.238376302350162],
              [-78.87435911801488, -5.234703023624597],
              [-78.87279508304134, -5.231255710936745],
              [-78.8723373426555, -5.227124583764178],
              [-78.8731689269802, -5.223536078829276],
              [-78.87432858996993, -5.219548404995532],
              [-78.87689971812695, -5.216608073653092],
              [-78.87936401501577, -5.214119711258202],
              [-78.88267518045521, -5.212068394047892],
              [-78.8864135865521, -5.209165992051373],
              [-78.88965604259585, -5.206383997987541],
              [-78.89306638810487, -5.203880772098712],
              [-78.8958282099312, -5.202166812468846],
              [-78.8983612211524, -5.20040913946845],
              [-78.90072630878406, -5.19836368068316],
              [-78.9032592989489, -5.196596983543403],
              [-78.90567013861966, -5.194334626656283],
              [-78.90785980068527, -5.192010463917889],
              [-78.91020962156954, -5.189017409660596],
              [-78.91182711309871, -5.186092081571479],
              [-78.91331481417603, -5.181714429325004],
              [-78.91485592605926, -5.177101790547805],
              [-78.91558071940692, -5.173965398305024],
              [-78.91641997665874, -5.170377019690665],
              [-78.91740417021245, -5.166110827494917],
              [-78.91801450709163, -5.162469722225678],
              [-78.9188155912739, -5.156986215000902],
              [-78.91941833428687, -5.153336141722189],
              [-78.91970060687105, -5.150094202044986],
              [-78.92000580361588, -5.144722076964388],
              [-78.92035673011722, -5.139124033273917],
              [-78.92067719114128, -5.134708536169525],
              [-78.92109679082034, -5.129832126600649],
              [-78.92173768175641, -5.125017541672253],
              [-78.92272948262296, -5.120751371041279],
              [-78.92301177809925, -5.117509451620267],
              [-78.92365264104909, -5.112694884243249],
              [-78.92393493922928, -5.10944394462961],
              [-78.92507934969434, -5.104508972425807],
              [-78.92591094339572, -5.100920733423156],
              [-78.92663572965314, -5.097784431104638],
              [-78.92758940119964, -5.094691861665011],
              [-78.92942047833097, -5.090871892801696],
              [-78.93077087255742, -5.088110856855781],
              [-78.93286132217997, -5.083170161870889],
              [-78.93517304249707, -5.078273260532145],
              [-78.93652341790144, -5.075512251088471],
              [-78.93836973557119, -5.072639915168112],
              [-78.94004059562567, -5.069488869455079],
              [-78.94278718092049, -5.066827663180984],
              [-78.94580077432185, -5.06399335053253],
              [-78.94893645037598, -5.061663716382254],
              [-78.9520187462195, -5.059551017289096],
              [-78.95487974920043, -5.057394545103173],
              [-78.95786285557833, -5.055733715737072],
              [-78.96205137373386, -5.052946387525776],
              [-78.96498106038409, -5.05151151053121],
              [-78.96751403929038, -5.049745129669603],
              [-78.97076415137091, -5.047920207003892],
              [-78.97440336748612, -5.045470169408097],
              [-78.97698974718867, -5.043477836825747],
              [-78.97934723900651, -5.041432759431792],
              [-78.9816436791976, -5.038666061703584],
              [-78.98438261208571, -5.036004957282716],
              [-78.98662565733812, -5.033464223481842],
              [-78.9880458510031, -5.030682842499582],
              [-78.97663108445238, -5.023730992194258],
              [-78.97440336947864, -5.025109659179295],
              [-78.97187040355826, -5.026875978214509],
              [-78.9677887058021, -5.029220325337715],
              [-78.96287535325, -5.032075147138961],
              [-78.95961762336265, -5.033909057572572],
              [-78.95691679035085, -5.035396616130164],
              [-78.95416260286002, -5.037110129360651],
              [-78.9509582582863, -5.038709051754619],
              [-78.94802856197191, -5.04014384445133],
              [-78.94492337869153, -5.04129991397689],
              [-78.94175721277328, -5.041734315409609],
              [-78.93833158187833, -5.043289433215784],
              [-78.93457793402753, -5.045234630541235],
              [-78.93215940854299, -5.04750567177607],
              [-78.93054198741491, -5.050430721787505],
              [-78.92935943840872, -5.053461446892774],
              [-78.92858124431753, -5.056823668953975],
              [-78.92762756546436, -5.05991619530098],
              [-78.92684937887215, -5.063278427746803],
              [-78.92612455835946, -5.066423727365128],
              [-78.92551423051755, -5.070064741646547],
              [-78.92457580514245, -5.074095856582292],
              [-78.9231261990873, -5.077308761430582],
              [-78.92195128222518, -5.08033948948027],
              [-78.9204483077336, -5.083769348711805],
              [-78.91839598103626, -5.087536511264619],
              [-78.91651152208689, -5.091591483876096],
              [-78.91450502522348, -5.095132748453496],
              [-78.91305538456, -5.098345704000805],
              [-78.91188051389321, -5.101376477597364],
              [-78.9100341410627, -5.104248889075182],
              [-78.90813448585827, -5.107347276284052],
              [-78.90570067324511, -5.111739615111138],
              [-78.90376282454868, -5.116011608166284],
              [-78.90198518909027, -5.119614726367083],
              [-78.90044401194827, -5.124218278171595],
              [-78.89944458225632, -5.12752783764831],
              [-78.89761351826245, -5.131356958448801],
              [-78.89578244567193, -5.135186090278177],
              [-78.89393612342741, -5.138058581166214],
              [-78.8920364478662, -5.141157053059155],
              [-78.88939668697184, -5.143366488374048],
              [-78.8860015633694, -5.143748266712112],
              [-78.88219452694428, -5.145919695087755],
              [-78.87978364601227, -5.148190915208521],
              [-78.87776181141761, -5.150784703779126],
              [-78.87498474185342, -5.154619680693627],
              [-78.87182615613253, -5.159079800721413],
              [-78.86889651390631, -5.163592697412852],
              [-78.86573788067577, -5.168043825208177],
              [-78.86230467575666, -5.172677169794765],
              [-78.85974885242112, -5.176565024685625],
              [-78.85701751281144, -5.180174148626104],
              [-78.85545348468088, -5.182882554948608],
              [-78.85343169872384, -5.185476419128045],
              [-78.85103604899265, -5.188695388231307],
              [-78.84901430379522, -5.191289264656374],
              [-78.8469391074894, -5.194118158852433],
              [-78.84481808642974, -5.197163973109607],
              [-78.84280396626387, -5.199757821162536],
              [-78.84078216577679, -5.202351721609248],
              [-78.83859249772465, -5.204675857874667],
              [-78.83657073936436, -5.207269768410995],
              [-78.83336637402053, -5.20887798157234],
              [-78.83009339965912, -5.209755457102403],
              [-78.82542418354163, -5.210542131389507],
              [-78.82115170411487, -5.211660352469706],
              [-78.81805415966659, -5.212816547639223],
              [-78.81439973378927, -5.214319134004604],
              [-78.80897521608595, -5.216337954156161],
              [-78.80509951278277, -5.217787698647787],
              [-78.80084227769248, -5.21985357019619],
              [-78.79763792861756, -5.221461711950695],
              [-78.7944259730504, -5.223060868098886],
              [-78.79100036523725, -5.224616177040895],
              [-78.78807065987708, -5.226051130235767],
              [-78.78530884253644, -5.227764902286091],
              [-78.78234865504736, -5.230373549248279],
              [-78.779151891526, -5.232929466026419],
              [-78.77679441187124, -5.234965772069414],
              [-78.77339169321165, -5.238425650304748],
              [-78.77021791062491, -5.241929290659278],
              [-78.76864621553798, -5.244637904568478],
              [-78.76786807009498, -5.248000718524883],
              [-78.76713563570199, -5.251137580994962],
              [-78.76592256136165, -5.255351590914982],
              [-78.76493833018354, -5.259609362080411],
              [-78.76394656156076, -5.263876213717776],
              [-78.76235201902529, -5.268706411298743],
              [-78.76113890156861, -5.272911430616029],
              [-78.75910188771596, -5.277636005341018],
              [-78.75761415760927, -5.282014228412979],
              [-78.7561111385851, -5.285444687312718],
              [-78.75455473962384, -5.289101160870565],
              [-78.75299833475685, -5.292757643179717],
              [-78.75104521065913, -5.296082528640055],
              [-78.74888611915921, -5.300302382826323],
              [-78.74645231904098, -5.304695437495415],
              [-78.74401090084429, -5.309088552565181],
              [-78.74152375663783, -5.313707645760334],
              [-78.73941804562251, -5.31770154242852],
              [-78.73791501725047, -5.321132085839961],
              [-78.73581698367816, -5.32607384556457],
              [-78.73454285611881, -5.32956620938532],
              [-78.73242950162141, -5.333560196381701],
              [-78.73075869713807, -5.336711941042076],
              [-78.72873690537418, -5.339306116553891],
              [-78.72621918666978, -5.342020670059678],
              [-78.72306058762835, -5.343403031819949],
              [-78.71918484539076, -5.344852933552009],
              [-78.71585844556897, -5.345956438431751],
              [-78.71236420309948, -5.346781090427785],
              [-78.70880888466206, -5.347831793185991],
              [-78.70639797604099, -5.350103339383222],
              [-78.7037200853368, -5.353486927503227],
              [-78.70214846156125, -5.356195765970311],
              [-78.70052338951321, -5.359121602072126],
              [-78.69686891322304, -5.36062429316206],
              [-78.69387052145588, -5.361328549577939],
              [-78.69021606238817, -5.362831220570605],
              [-78.68698122245327, -5.362534776272879],
              [-78.68395232648614, -5.361343295629548],
              [-78.68102262110496, -5.359699791338421],
              [-78.6772155820065, -5.358792816043349],
              [-78.67399594970652, -5.359453174039177],
              [-78.67044830536997, -5.360503701020582],
              [-78.66728973795387, -5.361885923379749],
              [-78.6645813211192, -5.363373776782786],
              [-78.66037750954618, -5.365213667850012],
              [-78.65710452386372, -5.366090970360282],
              [-78.65358733810235, -5.365967578476415],
              [-78.65042110941802, -5.36640182527371],
              [-78.64736935933668, -5.367331936157608],
              [-78.64400483000988, -5.369609141574535],
              [-78.64170839156952, -5.372376531983472],
              [-78.63997646473882, -5.375754491741443],
              [-78.63986968052818, -5.379276231752019],
              [-78.64098359077047, -5.382619129626426],
              [-78.64220424950095, -5.385518984129971],
              [-78.64337923627386, -5.389583769437492],
              [-78.64406584625799, -5.39376884154216],
              [-78.64328002184406, -5.397141160419775],
              [-78.6416092036955, -5.40029308634005],
              [-78.64042665405579, -5.403324717379198],
              [-78.63959504281483, -5.406914010798858],
              [-78.63903807918064, -5.410330117854315],
              [-78.64009854365847, -5.413899013268553],
              [-78.64093779033145, -5.417415059525108],
              [-78.6416092158538, -5.420652227460915],
              [-78.64182284183288, -5.423774780389189],
              [-78.64160916597693, -5.427748582830835],
              [-78.64072415627348, -5.431563927341979],
              [-78.63803100135658, -5.434008837164567],
              [-78.63453676915331, -5.434833449381183],
              [-78.63083648030526, -5.433483409165048],
              [-78.62757871419008, -5.432229987967516],
              [-78.6248779060422, -5.430648163760346],
              [-78.62163544151774, -5.430351635623056],
              [-78.61741637317155, -5.431243700995565],
              [-78.61399843815896, -5.433747084416741],
              [-78.61090847740867, -5.435851237202051],
              [-78.60688018915958, -5.437970035115588],
              [-78.6030655168951, -5.440141719351201],
              [-78.59980774241353, -5.441975999905115],
              [-78.59654235522619, -5.443801292230316],
              [-78.59333801323355, -5.445409521079038],
              [-78.59023288170077, -5.446565704540249],
              [-78.58752443976324, -5.448053585345852],
              [-78.5837707406824, -5.449999144283026],
              [-78.58050534409716, -5.451833437825868],
              [-78.57702634037958, -5.453605794725964],
              [-78.57359311228151, -5.455161173722259],
              [-78.56988524673008, -5.456880701813232],
              [-78.56672665959734, -5.458262877624716],
              [-78.56385042290489, -5.459471814657707],
              [-78.5605926755401, -5.46130602610734],
              [-78.55727388479092, -5.463357296838053],
              [-78.55341338945412, -5.465754887922664],
              [-78.55065154134334, -5.467468765420741],
              [-78.54640962205713, -5.470482602401161],
              [-78.54359435533287, -5.472422524969914],
              [-78.54078673872613, -5.474362400553752],
              [-78.53768156760171, -5.475518471821038],
              [-78.53440092684023, -5.476395657229479],
              [-78.52951050618324, -5.477128828235433],
              [-78.52611541464863, -5.47750100118004],
              [-78.52209473718652, -5.477497598106251],
              [-78.51775362503699, -5.477884326622319],
              [-78.51419069094091, -5.477986535719905],
              [-78.51101681855266, -5.478411510018492],
              [-78.50740054553692, -5.478739737677755],
              [-78.50288390583539, -5.478847475504098],
              [-78.49898531773023, -5.479348876491167],
              [-78.49581912016535, -5.479773736110492],
              [-78.49288173957322, -5.481208591173075],
              [-78.48957064624253, -5.483259656235012],
              [-78.48593138172431, -5.485709949461385],
              [-78.48284150408821, -5.487822925800037],
              [-78.48031612533468, -5.490537618086307],
              [-78.4786453447887, -5.493689801455171],
              [-78.47885897919524, -5.496821967593592],
              [-78.48168180593368, -5.498909473629856],
              [-78.48443602443064, -5.500274958897552],
              [-78.48818967194968, -5.50140882354563],
              [-78.49199679079788, -5.50230756501305],
              [-78.49578856056436, -5.502267261129114],
              [-78.49925234298469, -5.502617142288564],
              [-78.49831390280588, -5.506649955085414],
              [-78.49591061752976, -5.5098696716458],
              [-78.49384305151897, -5.512690107497553],
              [-78.4914932484705, -5.515683774566908],
              [-78.48947140929613, -5.518287234066323],
              [-78.48728942746563, -5.521559852562212],
              [-78.48578643161363, -5.524991026943929],
              [-78.48461150652622, -5.528022962104098],
              [-78.48361970942472, -5.532281899288433],
              [-78.48329924481483, -5.536708462341246],
              [-78.48381043004201, -5.540615422588029],
              [-78.48481750121599, -5.544411095305808],
              [-78.48587797788956, -5.547980694580079],
              [-78.48727416191535, -5.551150984493376],
              [-78.48921200900483, -5.553983952657309],
              [-78.49275969882171, -5.556003713821137],
              [-78.4963607591526, -5.557806406033514],
              [-78.50006103876443, -5.559156983673434],
              [-78.50321200496165, -5.560853923466926],
              [-78.5071411386623, -5.562266282051028],
              [-78.51062010936715, -5.563563899451691],
              [-78.51387022535411, -5.56480867409866],
              [-78.517524701494, -5.566385102776558],
              [-78.52112575519371, -5.568187555678333],
              [-78.52394867885775, -5.570274549664702],
              [-78.52599331998084, -5.572655129020677],
              [-78.52688597345491, -5.575945556281697],
              [-78.52760313991033, -5.578957114243208],
              [-78.5270614601526, -5.583330659194702],
              [-78.52657314707594, -5.587478149086225],
              [-78.52584073634772, -5.590615802358394],
              [-78.52510831143985, -5.593762489015568],
              [-78.52382659092234, -5.597246578609114],
              [-78.52282716464104, -5.600557413594752],
              [-78.52082059564572, -5.604109086252989],
              [-78.51779937677492, -5.606935475503628],
              [-78.51436612867151, -5.608491165196301],
              [-78.51082608192667, -5.610489941920685],
              [-78.50760647780008, -5.611150390319948],
              [-78.50443269897073, -5.611575777147634],
              [-78.50075529644808, -5.612130585108795],
              [-78.49736020646331, -5.612503087327699],
              [-78.49295042300625, -5.612168284196138],
              [-78.48937990730572, -5.612270895593343],
              [-78.48602292314418, -5.611469219747579],
              [-78.4819946178497, -5.610518011126427],
              [-78.47840881518296, -5.60966346242064],
              [-78.47471619107519, -5.609261021432442],
              [-78.47092441052914, -5.609310645722605],
              [-78.46736144667764, -5.609404038449957],
              [-78.46385954253951, -5.610228488235928],
              [-78.45913697617677, -5.61124075545522],
              [-78.45596312241966, -5.611674929869395],
              [-78.4524078353015, -5.612725400328147],
              [-78.44850918811531, -5.613218008009522],
              [-78.44511413596777, -5.613599258907534],
              [-78.44194030116999, -5.614024340294612],
              [-78.437942490341, -5.614968990576247],
              [-78.43468477236034, -5.61680327602643],
              [-78.4313125839954, -5.619080719737773],
              [-78.42817686521697, -5.621410952239733],
              [-78.42527005700285, -5.624751251882704],
              [-78.42247009586534, -5.627630372928614],
              [-78.4206237960047, -5.630512925214089],
              [-78.41961669297696, -5.633824017172236],
              [-78.41911318933184, -5.637014756336105],
              [-78.41995241047094, -5.640531728679168],
              [-78.4216155824736, -5.643538131452042],
              [-78.42423247105228, -5.646520833759807],
              [-78.42807004807997, -5.649333749660415],
              [-78.43279265166791, -5.651400886696666],
              [-78.43634030921709, -5.653420746492983],
              [-78.44038388804124, -5.655329262385487],
              [-78.44465639090744, -5.657290554864398],
              [-78.44925688253923, -5.658861544434662],
              [-78.45251463522067, -5.660106369902032],
              [-78.45609282560913, -5.660960957961098],
              [-78.45907590532244, -5.662378942848964],
              [-78.46233366982945, -5.66362368793772],
              [-78.46437833322759, -5.66600443318217],
              [-78.46549226220016, -5.66934792902901],
              [-78.46548461643468, -5.672418208247439],
              [-78.46527097027392, -5.676392733069637],
              [-78.46443935822845, -5.679991704547071],
              [-78.46129609479279, -5.68232227742093],
              [-78.45750429613103, -5.682363073203764],
              [-78.4542312921965, -5.683249577385363],
              [-78.45056911583556, -5.684743423043813],
              [-78.44724270732738, -5.685846947740504],
              [-78.444152800827, -5.687960440921222],
              [-78.44264986722223, -5.691391906503211],
              [-78.44420626573408, -5.694841205471056],
              [-78.44779206195895, -5.695686647156615],
              [-78.45034028531391, -5.697956028154953],
              [-78.44934846325692, -5.702215219660757],
              [-78.44767762917276, -5.705367770914186],
              [-78.44542694058929, -5.707909747745608],
              [-78.44258120149937, -5.711024204812633],
              [-78.43965908475421, -5.713407666798049],
              [-78.43667600196996, -5.715069064594336],
              [-78.43355561671127, -5.715277386398737],
              [-78.42924496428847, -5.714490431234484],
              [-78.42464444323404, -5.712928545845104],
              [-78.42150875994905, -5.712179655578463],
              [-78.41850282854469, -5.712892916381653],
              [-78.41772458034798, -5.716256910855286],
              [-78.4183044110844, -5.720895148147505],
              [-78.41921230622398, -5.725134077305087],
              [-78.41876986391235, -5.729055966023973],
              [-78.41781615571873, -5.732141073612535],
              [-78.41535951776916, -5.735587357221807],
              [-78.41351316254348, -5.738461075621897],
              [-78.41020199567096, -5.737442327381408],
              [-78.40756226779372, -5.739652514641669],
              [-78.40435029845803, -5.741261059198185],
              [-78.40073394386512, -5.741580616363948],
              [-78.39714813704221, -5.740735008081577],
              [-78.39383694467588, -5.739707124529152],
              [-78.3902511359107, -5.738861459853172],
              [-78.38603211776615, -5.736673925722705],
              [-78.3830489902822, -5.735255684011462],
              [-78.38029482524756, -5.73389024992642],
              [-78.37754059703268, -5.732524795181157],
              [-78.373283382418, -5.731511358675364],
              [-78.36909483734875, -5.731228986705041],
              [-78.36459353759088, -5.732294043061406],
              [-78.36114500895276, -5.732892303733476],
              [-78.35765837511494, -5.734673843540522],
              [-78.35494230504898, -5.736161856938679],
              [-78.35164644759399, -5.739161466185484],
              [-78.34941862421948, -5.742660557730697],
              [-78.34806827089612, -5.74542298389124],
              [-78.34722902409226, -5.749013283854304],
              [-78.34756471421936, -5.752641845696397],
              [-78.34807584035865, -5.756549339775781],
              [-78.34824373719398, -5.759907946735455],
              [-78.34690859445772, -5.763618579872684],
              [-78.34449003635304, -5.765881570084491],
              [-78.34104156817165, -5.766488867889735],
              [-78.33641812182857, -5.767048856496093],
              [-78.33216093135186, -5.766035218232945],
              [-78.32812496619444, -5.765074433056109],
              [-78.32454686361724, -5.764228343911071],
              [-78.32028966713254, -5.763214592201032],
              [-78.3169249875915, -5.762412353582849],
              [-78.3138045611335, -5.762620192969544],
              [-78.30811312607722, -5.762689432194962],
              [-78.30292508393168, -5.762638283012724],
              [-78.29907994772138, -5.762913471924189],
              [-78.29557033705953, -5.763737623768914],
              [-78.2927093367947, -5.765894692392226],
              [-78.29040533650424, -5.768671721751328],
              [-78.28868865211896, -5.772998807788579],
              [-78.28763583306184, -5.776536299842916],
              [-78.28664396618849, -5.780795981266847],
              [-78.2847366296236, -5.783904912087548],
              [-78.28356167778225, -5.786937351130226],
              [-78.28188325866661, -5.790090124613531],
              [-78.28030395017535, -5.792790740523603],
              [-78.2784576790505, -5.795673542607566],
              [-78.27677922448991, -5.798826330770778],
              [-78.2746505072865, -5.801873350782089],
              [-78.27259061992511, -5.805642523536091],
              [-78.27046200713531, -5.808689553434244],
              [-78.26793667619376, -5.811413737604123],
              [-78.26535040295605, -5.813397660805793],
              [-78.26168827291112, -5.814900080528017],
              [-78.2582397431713, -5.815498056825987],
              [-78.25466912624132, -5.815599960950844],
              [-78.25130467094282, -5.814797452316719],
              [-78.24742887129788, -5.813166912631029],
              [-78.24394982923921, -5.811868240723464],
              [-78.24002080703002, -5.810463735910667],
              [-78.23583223947226, -5.810171636023939],
              [-78.23158257519509, -5.810114689380354],
              [-78.22853082206655, -5.811044405272428],
              [-78.22542566281804, -5.81220022157683],
              [-78.22329711426528, -5.815247225810888],
              [-78.22308344953159, -5.819222645821265],
              [-78.22426605395933, -5.823298463472669],
              [-78.22594447127518, -5.827254008366412],
              [-78.2278366031846, -5.8303050860772],
              [-78.22907254632867, -5.834154749391241],
              [-78.22946168300642, -5.837557657719126],
              [-78.22895043257192, -5.840757999665506],
              [-78.22811884480758, -5.844348571940871],
              [-78.22733303097098, -5.847713079434156],
              [-78.22517400011634, -5.851934523140848],
              [-78.2241135170703, -5.855472234840467],
              [-78.22158813262698, -5.858187413224614],
              [-78.21956633848879, -5.8607913092202],
              [-78.21726233704818, -5.863559412410618],
              [-78.21563725469784, -5.866486196391003],
              [-78.21311192863774, -5.869201384430585],
              [-78.21052550244158, -5.87119433572617],
              [-78.20783996624027, -5.874587874674689],
              [-78.2057113753602, -5.877634987650634],
              [-78.20314031768542, -5.880576256327967],
              [-78.20128637233707, -5.88345018365772],
              [-78.1988144408937, -5.885939266043805],
              [-78.19579310622984, -5.888774735509983],
              [-78.19325258568617, -5.890541611039742],
              [-78.19020838706177, -5.892419760130741],
              [-78.18727875556131, -5.893854651627654],
              [-78.18376920463196, -5.894687669785167],
              [-78.18054203409251, -5.895338404955972],
              [-78.17714685169403, -5.895719105087213],
              [-78.17379767422406, -5.895864674749069],
              [-78.170677169905, -5.896072151069022],
              [-78.16705316835893, -5.896399902713194],
              [-78.16377251210278, -5.89727667939485],
              [-78.15976717724624, -5.898220788726094],
              [-78.15660858012266, -5.899602679532947],
              [-78.15424340969395, -5.901639457682873],
              [-78.15266416663599, -5.90434924132124],
              [-78.15383142354814, -5.907467983757684],
              [-78.15755465089063, -5.909777295327527],
              [-78.16104892484927, -5.912024640027185],
              [-78.16381075436728, -5.913390914515365],
              [-78.16784674801593, -5.914352452785198],
              [-78.17110447804424, -5.915598417949809],
              [-78.17470551691343, -5.917402416129348],
              [-78.1754379837149, -5.921363560187095],
              [-78.17489622553578, -5.925738474774214],
              [-78.17411048263197, -5.929103133492906],
              [-78.17327881418626, -5.932693876287994],
              [-78.17299649676914, -5.935947260683492],
              [-78.17293545890965, -5.939244538966992],
              [-78.17287448254137, -5.942550849681208],
              [-78.17359161811257, -5.945563640700629],
              [-78.17508701401131, -5.948282902085436],
              [-78.1783828582759, -5.951434374506122],
              [-78.18146515015437, -5.95240115033748],
              [-78.18495184910387, -5.953708804051638],
              [-78.18820956096967, -5.954954524042866],
              [-78.19194797618631, -5.955131595506532],
              [-78.19683839226573, -5.953450301152759],
              [-78.19925689968136, -5.951178155405543],
              [-78.20182802461088, -5.948245756324995],
              [-78.20433803199913, -5.944573057072824],
              [-78.20645147387636, -5.94057750030506],
              [-78.2085799730099, -5.937530262790337],
              [-78.21119686009482, -5.933414375717006],
              [-78.21447752260079, -5.929457222748615],
              [-78.21794891313382, -5.926727358115365],
              [-78.22225184867304, -5.92348684608893],
              [-78.22717283960398, -5.920622081271377],
              [-78.22971344746836, -5.91886406691069],
              [-78.23207857778448, -5.916818042925959],
              [-78.23717502910127, -5.9142412532778],
              [-78.23988344541637, -5.912753208694218],
              [-78.24420163017648, -5.910460929475777],
              [-78.24865720690018, -5.909621988021479],
              [-78.25165561505, -5.908908969673706],
              [-78.2577819530694, -5.907996957742149],
              [-78.26113132778468, -5.907850850856642],
              [-78.2663040390304, -5.906944357809207],
              [-78.27065274935195, -5.906557492304682],
              [-78.2747268722674, -5.90633478710176],
              [-78.2778472693452, -5.906135746044131],
              [-78.28281401142932, -5.906124477866052],
              [-78.28650659690982, -5.906527131552885],
              [-78.28969576742284, -5.907050151332077],
              [-78.29405969470443, -5.90761134031645],
              [-78.29802703582378, -5.907840676921573],
              [-78.3018340610954, -5.908748303029784],
              [-78.30537414874341, -5.909820052887315],
              [-78.30840295024393, -5.911012229912882],
              [-78.31312566225526, -5.913088385287347],
              [-78.31723017254413, -5.914770796349986],
              [-78.32043457130338, -5.916241800662061],
              [-78.3239136043472, -5.917539534602822],
              [-78.32727819685869, -5.918341201812062],
              [-78.33191678392974, -5.918728778033587],
              [-78.33699034981527, -5.918273788039134],
              [-78.34120180660054, -5.916433148473061],
              [-78.34590146299772, -5.913514840014297],
              [-78.3486099088736, -5.912035454708063],
              [-78.35171509770146, -5.910869756960861],
              [-78.35470578786826, -5.909217062901481],
              [-78.35763547490723, -5.907781476581502],
              [-78.3607940360864, -5.906398684401846],
              [-78.36367797549676, -5.905189134703381],
              [-78.36895749935407, -5.903829592655686],
              [-78.37274929443436, -5.903788527444097],
              [-78.37631987731098, -5.903685564588698],
              [-78.38073727871132, -5.904019938924801],
              [-78.38522336759341, -5.90508535100451],
              [-78.38913727127186, -5.905540116897162],
              [-78.39327237727063, -5.906047684415419],
              [-78.39656831670321, -5.906117845560575],
              [-78.40003201998427, -5.906466910069897],
              [-78.40327450489606, -5.906763122302753],
              [-78.40696715292361, -5.907164919075593],
              [-78.41081239169932, -5.906897489327792],
              [-78.41460419696271, -5.906847096059653],
              [-78.41855618486487, -5.906127434915397],
              [-78.4222870100338, -5.905354931224745],
              [-78.4258346703877, -5.904303594691447],
              [-78.42932129982418, -5.902521158092616],
              [-78.43280795315212, -5.900747742435689],
              [-78.43601989588697, -5.899138560932488],
              [-78.43917846287246, -5.897755458905348],
              [-78.44188692550462, -5.896266769541584],
              [-78.44657135760177, -5.892399919339751],
              [-78.44910428687834, -5.890641401124068],
              [-78.45339202568579, -5.886442888112927],
              [-78.45806886682168, -5.882585110747403],
              [-78.46247097884496, -5.878891577596709],
              [-78.46784212452455, -5.876140178138248],
              [-78.47260286603553, -5.873952412831305],
              [-78.47759248496597, -5.871817383111375],
              [-78.48097230182195, -5.87048699373496],
              [-78.48513031986023, -5.868871814384889],
              [-78.48839568513498, -5.867045472888836],
              [-78.49151609842443, -5.863757199892486],
              [-78.49495698415899, -5.859130521687368],
              [-78.49715421474554, -5.856805179825252],
              [-78.5003433257334, -5.854247899886135],
              [-78.50382992921088, -5.852465313096289],
              [-78.50939943031824, -5.851897713228786],
              [-78.51599121552316, -5.852036989553322],
              [-78.51922606008088, -5.852332719904092],
              [-78.52588654331444, -5.853202831777041],
              [-78.53119661554753, -5.853747275937339],
              [-78.53757476673618, -5.854781535342716],
              [-78.54116056969728, -5.855634724605114],
              [-78.54496767949671, -5.856540659156726],
              [-78.54855349142774, -5.857384757983865],
              [-78.55207063212296, -5.857506924057788],
              [-78.55725857752711, -5.857555249602673],
              [-78.56105043482572, -5.857504057780962],
              [-78.56412505913528, -5.857520610506789],
              [-78.56869506040486, -5.857184511803974],
              [-78.57319641074007, -5.856126494375994],
              [-78.57788087409457, -5.856286194297933],
              [-78.58300779162829, -5.855603331216228],
              [-78.58702088758162, -5.855613758646022],
              [-78.5912628172449, -5.855667834019737],
              [-78.59443663096937, -5.855241176270624],
              [-78.59921266899765, -5.854000648949483],
              [-78.60327148684236, -5.852827907303454],
              [-78.60665129294749, -5.851506028558815],
              [-78.60958102590378, -5.850069664420946],
              [-78.61296079353266, -5.84873873806503],
              [-78.61634060495064, -5.847407800654696],
              [-78.6195984092869, -5.848650785287298],
              [-78.62259670618467, -5.8510149593456],
              [-78.62554168474368, -5.853605159763095],
              [-78.62829587288914, -5.858047405164524],
              [-78.6306533903271, -5.862158131118131],
              [-78.63288878724859, -5.865764026166241],
              [-78.63529965637333, -5.869648633787317],
              [-78.63701627386997, -5.872427239068345],
              [-78.63925931444768, -5.876033007703864],
              [-78.64137267180162, -5.879134007755774],
              [-78.64423370078313, -5.883123903477513],
              [-78.64733121078427, -5.888123380374083],
              [-78.64962006350682, -5.8915030194742],
              [-78.65155789948639, -5.894325183949912],
              [-78.64984890897665, -5.89866093971156],
              [-78.64782717318683, -5.901256299142688],
              [-78.64547731923669, -5.904251061661788],
              [-78.6431350510106, -5.907245779628892],
              [-78.64078522444858, -5.910240552406711],
              [-78.63845059498462, -5.914183210830358],
              [-78.63610079492398, -5.91717799619305],
              [-78.63272091166806, -5.92158797818832],
              [-78.6309204371382, -5.92423609257856],
              [-78.62907407790765, -5.927119252507278],
              [-78.62722778664853, -5.929993388731436],
              [-78.62537380330696, -5.932867580583301],
              [-78.62330629159841, -5.935689006575628],
              [-78.62150573704375, -5.938346173228307],
              [-78.61801907865473, -5.943199311800584],
              [-78.61497498194782, -5.948157906870929],
              [-78.61210631535496, -5.953404283567273],
              [-78.60918429510328, -5.958867714965058],
              [-78.60700985310083, -5.962132292720801],
              [-78.60527800970264, -5.965511348413296],
              [-78.60293578429896, -5.968506177970299],
              [-78.60068511693466, -5.971058004000541],
              [-78.59871673906511, -5.973427407980348],
              [-78.5964202499257, -5.976196230944507],
              [-78.59445189832138, -5.978574669747433],
              [-78.59149931876564, -5.982133304858254],
              [-78.5883407538813, -5.986596180981824],
              [-78.58610531007277, -5.990095962685729],
              [-78.58465576663602, -5.993301680831005],
              [-78.58244325049552, -5.997749362780006],
              [-78.58071136870556, -6.001128494199955],
              [-78.57904049315825, -6.004281509161104],
              [-78.5761871357152, -6.00739712991142],
              [-78.57383730680351, -6.010392081017973],
              [-78.57148745581696, -6.013387036994987],
              [-78.56946564479534, -6.015982572930715],
              [-78.56707001973597, -6.019203563731684],
              [-78.56494141636665, -6.022251260454647],
              [-78.56249234451258, -6.02568931060429],
              [-78.560813929338, -6.028851451724206],
              [-78.55918885818178, -6.031778492257884],
              [-78.55778502374963, -6.034758265074735],
              [-78.55628203123865, -6.038190149877297],
              [-78.55450436302756, -6.041795393439651],
              [-78.55277248768553, -6.045174614982788],
              [-78.55132295153274, -6.048380440000446],
              [-78.54779816783488, -6.051338005934073],
              [-78.54520413881265, -6.053323352033107],
              [-78.54222104703307, -6.054986185466692],
              [-78.53911590096, -6.056144168251747],
              [-78.53456116883243, -6.057438012234516],
              [-78.53143311275679, -6.057639001890054],
              [-78.52635194396647, -6.057148397064473],
              [-78.52288056702176, -6.056800774615905],
              [-78.51829525482593, -6.056189476596989],
              [-78.51377104930647, -6.056300102399115],
              [-78.50959777157578, -6.056968253487697],
              [-78.5056457190936, -6.057698153900049],
              [-78.50209042849427, -6.058750534286662],
              [-78.4991073406295, -6.060404191806178],
              [-78.4950866885993, -6.063482212551919],
              [-78.49261470953101, -6.065972326675976],
              [-78.49037173241102, -6.0685151427721],
              [-78.48748014161198, -6.072804899871698],
              [-78.48518374499214, -6.075573814294982],
              [-78.48223113315245, -6.079141592509579],
              [-78.47970579678493, -6.081857811699709],
              [-78.47623446092037, -6.084589161280196],
              [-78.47307586690673, -6.085973031899166],
              [-78.47003939904279, -6.087852755253906],
              [-78.46762084024694, -6.090125817123157],
              [-78.46466825633905, -6.093684577351153],
              [-78.46205138528195, -6.097801046257604],
              [-78.46110533658604, -6.100895408914647],
              [-78.45954128645474, -6.104544586673844],
              [-78.45843502879012, -6.108308215817307],
              [-78.45668790318105, -6.113818784470268],
              [-78.45479583396639, -6.117867423034582],
              [-78.45207213193818, -6.121478949626338],
              [-78.44961546282374, -6.124926220532871],
              [-78.44676206798349, -6.128041934154052],
              [-78.4441909470348, -6.13098426069687],
              [-78.44133757481578, -6.134090949754799],
              [-78.43897246250098, -6.136137962720814],
              [-78.43595123664367, -6.138974825762663],
              [-78.43259428485418, -6.142202148957288],
              [-78.4286422394262, -6.146002205312449],
              [-78.42485806446318, -6.150081127356701],
              [-78.4200744402603, -6.154392224148259],
              [-78.41782382365531, -6.156944206412576],
              [-78.41529847921868, -6.159660499905019],
              [-78.41260524932791, -6.162097925685334],
              [-78.41013339245404, -6.164588116428046],
              [-78.40771482695469, -6.166861234060875],
              [-78.4054717986343, -6.169404144920636],
              [-78.40168764021999, -6.173483086574928],
              [-78.3977813494592, -6.177057098298513],
              [-78.39510342990296, -6.180451676090914],
              [-78.3919220088533, -6.183957844123243],
              [-78.38995358610086, -6.186327429426552],
              [-78.38639068307711, -6.190459159356128],
              [-78.38272860088883, -6.195043065672389],
              [-78.37928768409357, -6.19967072261463],
              [-78.37629696338385, -6.204403886108837],
              [-78.37422179427114, -6.207234743709774],
              [-78.37147522329009, -6.209898300900868],
              [-78.36984252689015, -6.212825682448671],
              [-78.36782072428407, -6.215421406718206],
              [-78.36492153259813, -6.218763261207446],
              [-78.36234280907765, -6.221705715621623],
              [-78.35966491689656, -6.225100352229499],
              [-78.35626223510583, -6.228553794608087],
              [-78.3524780351172, -6.23263280697481],
              [-78.34984592054212, -6.235792356865448],
              [-78.34732058610072, -6.238517739392446],
              [-78.34480285409735, -6.241234043927874],
              [-78.34222413393624, -6.244176515412589],
              [-78.33964538691167, -6.247118989336366],
              [-78.33719632140547, -6.250557355481616],
              [-78.33478547926357, -6.253778735569444],
              [-78.33248907158244, -6.256547833047351],
              [-78.33046729429853, -6.259152627435979],
              [-78.3274764981176, -6.263885916815315],
              [-78.32518763774281, -6.267612290858674],
              [-78.32202915730377, -6.272066693164363],
              [-78.31886293406833, -6.276530184258141],
              [-78.31662749547573, -6.280021426347288],
              [-78.31445313445774, -6.283295534089619],
              [-78.31209562197938, -6.286290835302578],
              [-78.3103867219756, -6.290627704914932],
              [-78.308708251317, -6.29378127597224],
              [-78.30726627533237, -6.297935839440023],
              [-78.30632778439505, -6.301978838718394],
              [-78.30559539627677, -6.305117392047507],
              [-78.30487054056806, -6.30826493225968],
              [-78.30236046242082, -6.311929571055222],
              [-78.30078884657382, -6.314639954510548],
              [-78.30314640409343, -6.318743327021399],
              [-78.30488584033705, -6.322471282510638],
              [-78.3058319660678, -6.325535946126675],
              [-78.30556490726487, -6.329737259739957],
              [-78.30442046709369, -6.334675713624462],
              [-78.30225368342333, -6.338898177286107],
              [-78.2990189112244, -6.342639755572688],
              [-78.29589075547823, -6.345920051154171],
              [-78.2931442693438, -6.348583773806951],
              [-78.28949735664959, -6.351036467213095],
              [-78.28668218225941, -6.352978095675142],
              [-78.28392027543433, -6.354693590599875],
              [-78.28104394259992, -6.356852356804324],
              [-78.27632910453681, -6.358815056088369],
              [-78.27293402439332, -6.359188817400429],
              [-78.26847846332598, -6.36002989052593],
              [-78.26419065434771, -6.361149857235142],
              [-78.26165009303145, -6.362918083125828],
              [-78.25912480461686, -6.36563455080393],
              [-78.25633996301978, -6.369472607892138],
              [-78.25409697435856, -6.372024723366039],
              [-78.25001524513944, -6.375320025711926],
              [-78.24569707830642, -6.377614265899317],
              [-78.24192810237882, -6.379561815721894],
              [-78.23881529811442, -6.380719439064507],
              [-78.23520653335345, -6.381997584643201],
              [-78.23199466885951, -6.383607407296917],
              [-78.22860717488258, -6.384929295075451],
              [-78.2256165004967, -6.386591901109975],
              [-78.22192384940847, -6.389270499125297],
              [-78.21867365081667, -6.392054704481975],
              [-78.21586605604267, -6.394944522049758],
              [-78.21363833843525, -6.398435869128566],
              [-78.21279911961271, -6.402026915741366],
              [-78.21231072230329, -6.40617576723123],
              [-78.21187593752384, -6.410098479858061],
              [-78.21081546655586, -6.413636731557141],
              [-78.2087936123217, -6.416241660241365],
              [-78.20536806871914, -6.418746996847759],
              [-78.20137786668771, -6.420641662634821],
              [-78.19811250689091, -6.422468553550295],
              [-78.19522856637273, -6.423678850304072],
              [-78.19166570764227, -6.424730792401419],
              [-78.1885452335113, -6.424939941747384],
              [-78.18491364070758, -6.4252696792697],
              [-78.18099978069183, -6.424815322336342],
              [-78.1769866793156, -6.425761590642797],
              [-78.17508700893865, -6.428862522640521],
              [-78.1752013518565, -6.432438699620797],
              [-78.17545319315201, -6.437477291656723],
              [-78.1760177431318, -6.441159052813726],
              [-78.1764068080731, -6.444570926620716],
              [-78.17630007824958, -6.448094278645418],
              [-78.17579656686593, -6.451294934446214],
              [-78.17412561492374, -6.455397096029847],
              [-78.1702728292451, -6.458745099474541],
              [-78.16678613351239, -6.460528176365017],
              [-78.16396335610224, -6.462460664400613],
              [-78.15991980101938, -6.464581391560783],
              [-78.15565490901437, -6.466649281034641],
              [-78.15243537293321, -6.468259027625756],
              [-78.14911653903663, -6.470311984284939],
              [-78.1454696809669, -6.472764407147188],
              [-78.14221950487693, -6.475548578190318],
              [-78.13946537996158, -6.478212247919769],
              [-78.13665003426564, -6.480153666120076],
              [-78.13353730180135, -6.481311056095015],
              [-78.12963871013424, -6.481804850385402],
              [-78.12538916229694, -6.481749771932133],
              [-78.1221390043821, -6.481453645536559],
              [-78.11877439874847, -6.4806523518173],
              [-78.11572259665829, -6.478512219107061],
              [-78.11272432049918, -6.476145900653421],
              [-78.1088714614888, -6.473333131495555],
              [-78.10599514197457, -6.471471851295684],
              [-78.10227968156333, -6.470121527770649],
              [-78.09907529651534, -6.468650618245774],
              [-78.09413146201642, -6.467479339120936],
              [-78.0894927608111, -6.467083006156829],
              [-78.08579253541075, -6.466680928553126],
              [-78.08189388246632, -6.467183357061811],
              [-78.07815545229617, -6.467955788989727],
              [-78.07425684126298, -6.468449133066047],
              [-78.07069390110334, -6.469500493251741],
              [-78.06739806458994, -6.469430074788888],
              [-78.06941220791352, -6.47298611005392],
              [-78.07019799274882, -6.476730227062307],
              [-78.0698775882409, -6.481149508773245],
              [-78.06878655241644, -6.485871563765889],
              [-78.06758115867906, -6.491027923138119],
              [-78.0664901211163, -6.495749991705664],
              [-78.06570428995666, -6.499115198347924],
              [-78.06402584546477, -6.50226901794512],
              [-78.06217197251951, -6.505143867037398],
              [-78.06172947665063, -6.509067080055142],
              [-78.06217951348813, -6.512253167839236],
              [-78.06270595194218, -6.51711901395869],
              [-78.06288913930128, -6.521417832316984],
              [-78.06360632234579, -6.524430648865087],
              [-78.0650023878039, -6.527610963203111],
              [-78.06678770763108, -6.53111409666452],
              [-78.06829067252504, -6.533833027131539],
              [-78.06918337284264, -6.537124776106686],
              [-78.06940460432826, -6.54025801857692],
              [-78.0686187233235, -6.54362324895772],
              [-78.06636806177707, -6.546166356606517],
              [-78.06372065147674, -6.548386682311159],
              [-78.05988314887341, -6.549611547371885],
              [-78.05638120605556, -6.550436872719008],
              [-78.05297091067908, -6.550809943922234],
              [-78.0497437127934, -6.55147095531183],
              [-78.04720308824716, -6.553238910795449],
              [-78.04596717013938, -6.556498514331995],
              [-78.04640967448506, -6.559684684172923],
              [-78.04918673387797, -6.561998452609283],
              [-78.05283356092268, -6.562626652722444],
              [-78.05597690980302, -6.563366296935025],
              [-78.0585784819694, -6.565410049795578],
              [-78.05992132170296, -6.568807465791409],
              [-78.05976105537432, -6.572557340630041],
              [-78.05882263450017, -6.576601050838254],
              [-78.05702202665225, -6.579249850875282],
              [-78.05337527561723, -6.581702173261281],
              [-78.0501403630992, -6.582354290949213],
              [-78.0470276442989, -6.583511526366561],
              [-78.04517361548066, -6.586395522509179],
              [-78.04216762431854, -6.58710041154458],
              [-78.03787995470331, -6.588219606219961],
              [-78.03337102682335, -6.589285915923018],
              [-78.03024291084877, -6.589485602596174],
              [-78.0268402195961, -6.58986761189607],
              [-78.02134706646252, -6.589042706181401],
              [-78.01692957933197, -6.58869905480234],
              [-78.01378628097709, -6.587959210149782],
              [-78.01064307008755, -6.587210306990897],
              [-78.0073241907403, -6.586191421123294],
              [-78.00461574609075, -6.584599695269761],
              [-78.00223540727588, -6.582617514294549],
              [-77.9997329189174, -6.580130144635526],
              [-77.996833802517, -6.577310872075517],
              [-77.99316403802646, -6.574776236989904],
              [-77.98912053961422, -6.573824752003437],
              [-77.98552709582296, -6.576050538860255],
              [-77.98479462555947, -6.579189743114505],
              [-77.98400879161642, -6.582564155497074],
              [-77.98390196739773, -6.586088091564482],
              [-77.98289485481166, -6.58940059390348],
              [-77.97810358568954, -6.590639796415726],
              [-77.97482306596555, -6.591517620261612],
              [-77.97470858001982, -6.595041628835578],
              [-77.97639465576154, -6.598997556477044],
              [-77.97820287918228, -6.603449612915268],
              [-77.97842406008351, -6.60658313454638],
              [-77.97685999025055, -6.610242290069452],
              [-77.97448734764926, -6.612280140429395],
              [-77.97203065296948, -6.615727823240307],
              [-77.97053522218927, -6.620109316102831],
              [-77.96925351568015, -6.623595150556517],
              [-77.96852105045798, -6.626743437014674],
              [-77.96736901752776, -6.631683012254808],
              [-77.96620946642155, -6.635674045562424],
              [-77.96486663463368, -6.639377085885055],
              [-77.96408850311778, -6.642751516361873],
              [-77.96554562457086, -6.646645573782821],
              [-77.96676636866, -6.649538236992634],
              [-77.96698763006403, -6.652671781800263],
              [-77.9653243918922, -6.656783304011707],
              [-77.96215827195104, -6.65816647568022],
              [-77.96070104884765, -6.661373344356977],
              [-77.9598618188724, -6.664964982675136],
              [-77.96047968822526, -6.668430431805277],
              [-77.96187585220534, -6.671602102687404],
              [-77.96282201465704, -6.674668057512631],
              [-77.96288302431597, -6.678471105973722],
              [-77.96282189756609, -6.68177800586267],
              [-77.96209717575994, -6.684917252148071],
              [-77.96125799267234, -6.688508903106273],
              [-77.96176908418875, -6.692417652626947],
              [-77.96244050277542, -6.695656896653996],
              [-77.96450802588413, -6.698987081920889],
              [-77.967956530435, -6.701468713192299],
              [-77.97078708125373, -6.703556544634328],
              [-77.97296143744184, -6.706443333403392],
              [-77.97245782664581, -6.709635443902565],
              [-77.97144317434753, -6.712957135086744],
              [-77.9746781002055, -6.715376716980179],
              [-77.9770050120222, -6.717584967957777],
              [-77.97789764163507, -6.720876988154665],
              [-77.97701257614214, -6.724694771083125],
              [-77.97537994908467, -6.727622731304638],
              [-77.974029552919, -6.730386361878717],
              [-77.97109991396316, -6.732771136227665],
              [-77.9676208188151, -6.735502544286669],
              [-77.96347051314862, -6.738066370217275],
              [-77.96008293578878, -6.739396894516791],
              [-77.9556197683111, -6.740237035268835],
              [-77.95239258275188, -6.740898010948876],
              [-77.94915770002645, -6.741549981136119],
              [-77.94597627534544, -6.741984786000732],
              [-77.94201666968021, -6.742704333172902],
              [-77.93850713999589, -6.743529554221057],
              [-77.93546300744919, -6.745408982909568],
              [-77.93264007063527, -6.747350312764733],
              [-77.92985535353745, -6.751188647393403],
              [-77.9281845734565, -6.75530035496373],
              [-77.92624660114795, -6.759576296542252],
              [-77.92417903851896, -6.763347090296439],
              [-77.92256926060857, -6.767232584629805],
              [-77.92134854401654, -6.771450022319945],
              [-77.92213446590986, -6.775185593205358],
              [-77.92325585491224, -6.778530651383413],
              [-77.92559811369813, -6.781687646221342],
              [-77.92804717328295, -6.784401281446116],
              [-77.93093106432772, -6.786272057337325],
              [-77.93397524272562, -6.787464252798814],
              [-77.93756870709366, -6.788318819159768],
              [-77.94183345612862, -6.789322861721805],
              [-77.9458618371254, -6.789334518638126],
              [-77.95001227597751, -6.789842285750874],
              [-77.95382692681656, -6.790749569789914],
              [-77.95683283938317, -6.793116271827255],
              [-77.95895388448427, -6.7962201264625],
              [-77.9595184897056, -6.79991167605661],
              [-77.95941921245357, -6.804384229660692],
              [-77.95837403662082, -6.808880645791374],
              [-77.9578705106833, -6.812072817671113],
              [-77.95641323555775, -6.815279869297357],
              [-77.95323180560268, -6.815714924783819],
              [-77.9497604247776, -6.815365749364052],
              [-77.94557187890162, -6.816032770440628],
              [-77.94185641432016, -6.817762931478087],
              [-77.93910217010081, -6.820426795287336],
              [-77.93714142492128, -6.823754368065636],
              [-77.93613431117704, -6.827067098915936],
              [-77.93608099232915, -6.830364984506097],
              [-77.93636318760304, -6.834229991200544],
              [-77.93727873676494, -6.838470573257452],
              [-77.93846132433727, -6.842537860562868],
              [-77.9394226400447, -6.846561317913002],
              [-77.94031523875698, -6.849853402604957],
              [-77.9412079445019, -6.853136445125565],
              [-77.94210058561121, -6.856428515262618],
              [-77.94367217916422, -6.859879029211575],
              [-77.94516760985027, -6.862607249603412],
              [-77.94679266994913, -6.865831548202594],
              [-77.94851686296958, -6.868612540559354],
              [-77.94985957174733, -6.872010200567178],
              [-77.9502486592799, -6.875413729374482],
              [-77.94884491646437, -6.878403701207632],
              [-77.9464873567779, -6.881399555677756],
              [-77.9440689009666, -6.883664004710725],
              [-77.94113927791032, -6.886049044630857],
              [-77.93894192623908, -6.888375366720148],
              [-77.93696588149197, -6.890745498059538],
              [-77.93516547347559, -6.893403649968203],
              [-77.9339828018513, -6.896437449796347],
              [-77.93397522247764, -6.899518230308249],
              [-77.935203601283, -6.902410712839763],
              [-77.93687441521078, -6.905417905700745],
              [-77.93859860991168, -6.90818986057782],
              [-77.94094086110823, -6.911355494310139],
              [-77.94462578371635, -6.913880393406309],
              [-77.94861602022378, -6.915066280075949],
              [-77.95160675948213, -6.916484184090558],
              [-77.95363610378823, -6.920988712108234],
              [-77.95403294885426, -6.924401190648352],
              [-77.95375057755926, -6.927646240283583],
              [-77.95346826802783, -6.930900324901552],
              [-77.95325474063465, -6.934876722332151],
              [-77.95226294287632, -6.939138028702876],
              [-77.95121760125028, -6.943625529853159],
              [-77.95026401406665, -6.946721187513194],
              [-77.94840239515246, -6.949596646722356],
              [-77.94632725413199, -6.952419231257807],
              [-77.94357301065928, -6.955083424837257],
              [-77.9400406100729, -6.958041552257207],
              [-77.93683625262688, -6.960600120976978],
              [-77.93413545251862, -6.963038108650618],
              [-77.93133543459115, -6.965928428416589],
              [-77.92807770057864, -6.968713170684829],
              [-77.92543027253048, -6.970933987485793],
              [-77.9226608135402, -6.972640589989047],
              [-77.91909795199658, -6.973692574653155],
              [-77.91591637308014, -6.974127834353431],
              [-77.91254431914734, -6.973326516372119],
              [-77.90943142474867, -6.971412318261318],
              [-77.90587620658512, -6.969383361651225],
              [-77.90304569053292, -6.967295703788327],
              [-77.8990173986135, -6.964212522017236],
              [-77.89589688492444, -6.961340556295957],
              [-77.89311219674362, -6.95901761725879],
              [-77.88999175039493, -6.956145582914312],
              [-77.88732907401216, -6.954336821161991],
              [-77.8840484719389, -6.952134271216192],
              [-77.8807678091303, -6.949940721048977],
              [-77.87761689594927, -6.949191921132612],
              [-77.87496191711185, -6.947383007234483],
              [-77.87091828203458, -6.946422731958982],
              [-77.86754610710118, -6.945620980174115],
              [-77.86436465726712, -6.946055833486923],
              [-77.86135096836831, -6.94676070327052],
              [-77.85829163903433, -6.94770074248752],
              [-77.85472874039128, -6.948752222821953],
              [-77.85156255199622, -6.950135589910696],
              [-77.84794623461444, -6.951404195641073],
              [-77.84484867949764, -6.953518961745368],
              [-77.84169772247662, -6.95585088189514],
              [-77.83854673044134, -6.95818279345028],
              [-77.83582311150322, -6.959671863434726],
              [-77.83288567185323, -6.961107994786232],
              [-77.83154345754909, -6.961515403658428],
              [-77.83059692382812, -6.959227085113525],
              [-77.82561083563783, -6.958692272161496],
              [-77.82022094726562, -6.958114147186279],
              [-77.81099700927734, -6.958378791809082],
              [-77.81139373779297, -6.948984146118164],
              [-77.8121109008789, -6.946850776672363],
              [-77.81853485107422, -6.939451217651367],
              [-77.82450103759766, -6.931130886077881],
              [-77.83392333984375, -6.921875953674316],
              [-77.8468017578125, -6.913300037384033],
              [-77.86200714111328, -6.90978479385376],
              [-77.87145233154297, -6.909287929534912],
              [-77.87515258789062, -6.91134786605835],
              [-77.88988494873047, -6.904839038848877],
              [-77.89793395996094, -6.899277210235596],
              [-77.8985824584961, -6.888905048370361],
              [-77.9020004272461, -6.878060817718506],
              [-77.90497589111328, -6.871828079223633],
              [-77.90510559082031, -6.869681835174561],
              [-77.90541076660156, -6.864682197570801],
              [-77.90418243408203, -6.843027114868164],
              [-77.90227508544922, -6.826906204223633],
              [-77.90107727050781, -6.814468860626221],
              [-77.89986419677734, -6.796964168548584],
              [-77.89842224121094, -6.782226085662842],
              [-77.89629364013672, -6.765647888183594],
              [-77.8960189819336, -6.753900051116943],
              [-77.8980484008789, -6.742835998535156],
              [-77.90054321289062, -6.731770038604736],
              [-77.9060287475586, -6.718389987945557],
              [-77.91338348388672, -6.712141990661621],
              [-77.92144012451172, -6.709578037261963],
              [-77.9276351928711, -6.701952934265137],
              [-77.9278335571289, -6.693201065063477],
              [-77.92639923095703, -6.680539131164551],
              [-77.92589569091797, -6.667874813079834],
              [-77.9244613647461, -6.655214786529541],
              [-77.9239730834961, -6.647157192230225],
              [-77.92391967773438, -6.632880210876465],
              [-77.9250259399414, -6.6197509765625],
              [-77.92867279052734, -6.608684062957764],
              [-77.93508911132812, -6.598527908325195],
              [-77.9433364868164, -6.58721399307251],
              [-77.95160675048828, -6.578894138336182],
              [-77.95916748046875, -6.568044185638428],
              [-77.96443939208984, -6.560426235198975],
              [-77.96971130371094, -6.552117824554443],
              [-77.97451782226562, -6.543581008911133],
              [-77.97956085205078, -6.536654949188232],
              [-77.98670196533203, -6.536858081817627],
              [-77.9915542602539, -6.540751934051514],
              [-78.00333404541016, -6.548303127288818],
              [-78.00677490234375, -6.543224811553955],
              [-78.00881958007812, -6.536540985107422],
              [-78.01040649414062, -6.528937816619873],
              [-78.01152801513672, -6.522027969360352],
              [-78.01194763183594, -6.510746955871582],
              [-78.01239013671875, -6.505681037902832],
              [-78.01211547851562, -6.493253231048584],
              [-78.01069641113281, -6.484742164611816],
              [-78.01090240478516, -6.477836132049561],
              [-78.01317596435547, -6.469541072845459],
              [-78.01774597167969, -6.461008071899414],
              [-78.0237045288086, -6.45200777053833],
              [-78.02964782714844, -6.440018177032471],
              [-78.03675079345703, -6.428712844848633],
              [-78.04108428955078, -6.41765022277832],
              [-78.04103088378906, -6.404994010925293],
              [-78.03710174560547, -6.401557922363281],
              [-78.03204345703125, -6.404798984527588],
              [-78.02333068847656, -6.414728164672852],
              [-78.01669311523438, -6.425570011138916],
              [-78.010498046875, -6.433419227600098],
              [-78.00224304199219, -6.442196846008301],
              [-77.99305725097656, -6.450747966766357],
              [-77.98548889160156, -6.459753036499023],
              [-77.98090362548828, -6.466676235198975],
              [-77.97725677490234, -6.476586818695068],
              [-77.97314453125, -6.484427928924561],
              [-77.96807098388672, -6.483987808227539],
              [-77.95724487304688, -6.484029769897461],
              [-77.94710540771484, -6.484529972076416],
              [-77.93836212158203, -6.487095832824707],
              [-77.94114685058594, -6.492379188537598],
              [-77.94876098632812, -6.495572090148926],
              [-77.95222473144531, -6.497169971466064],
              [-77.9584732055664, -6.504510879516602],
              [-77.96308135986328, -6.506333827972412],
              [-77.96768951416016, -6.505165100097656],
              [-77.97113037109375, -6.501928806304932],
              [-77.97551727294922, -6.503522872924805],
              [-77.97691345214844, -6.506509780883789],
              [-77.97578430175781, -6.513420104980469],
              [-77.97142791748047, -6.518961906433105],
              [-77.96478271484375, -6.528656005859375],
              [-77.956298828125, -6.539968967437744],
              [-77.94895935058594, -6.549205780029297],
              [-77.94001770019531, -6.560980796813965],
              [-77.93268585205078, -6.572521209716797],
              [-77.92396545410156, -6.582684993743896],
              [-77.91777801513672, -6.59168815612793],
              [-77.91525268554688, -6.594922065734863],
              [-77.90449523925781, -6.612923145294189],
              [-77.89788055419922, -6.632750988006592],
              [-77.8979721069336, -6.6580810546875],
              [-77.8978042602539, -6.675582885742188],
              [-77.8957748413086, -6.686645984649658],
              [-77.88751983642578, -6.698654174804688],
              [-77.87718963623047, -6.710669994354248],
              [-77.87052154541016, -6.714381217956543],
              [-77.86361694335938, -6.716940879821777],
              [-77.86479187011719, -6.722233772277832],
              [-77.86734771728516, -6.728903770446777],
              [-77.86344909667969, -6.73329496383667],
              [-77.86166381835938, -6.735614776611328],
              [-77.85794067382812, -6.740457057952881],
              [-77.8554458618164, -6.751752853393555],
              [-77.85226440429688, -6.764665126800537],
              [-77.85487365722656, -6.784235954284668],
              [-77.85674285888672, -6.792521953582764],
              [-77.85562133789062, -6.800819873809814],
              [-77.8487319946289, -6.808450222015381],
              [-77.84184265136719, -6.815156936645508],
              [-77.83356475830078, -6.821410179138184],
              [-77.82344818115234, -6.827899932861328],
              [-77.81494140625, -6.834153175354004],
              [-77.80941772460938, -6.837629795074463],
              [-77.80113983154297, -6.841808795928955],
              [-77.79491424560547, -6.841372013092041],
              [-77.78799438476562, -6.840476989746094],
              [-77.78506469726562, -6.841568470001221],
              [-77.77924346923828, -6.843737125396729],
              [-77.7762680053711, -6.849277973175049],
              [-77.77098846435547, -6.856210231781006],
              [-77.76410675048828, -6.865684032440186],
              [-77.75884246826172, -6.876995086669922],
              [-77.75358581542969, -6.889459133148193],
              [-77.73544311523438, -6.909346103668213],
              [-77.71708039807034, -6.934690950049496],
              [-77.71685791015625, -6.934998035430908],
              [-77.71548461914062, -6.939383029937744],
              [-77.7162094116211, -6.947677135467529],
              [-77.71807861328125, -6.955737113952637],
              [-77.71534729003906, -6.964966773986816],
              [-77.7059097290039, -6.970304012298584],
              [-77.7001724243164, -6.977470874786377],
              [-77.70572662353516, -6.985517024993896],
              [-77.72370147705078, -6.982452869415283],
              [-77.73178100585938, -6.984496116638184],
              [-77.73457336425781, -6.993014812469482],
              [-77.73182678222656, -6.999478816986084],
              [-77.72538757324219, -7.004114151000977],
              [-77.71479034423828, -7.006460189819336],
              [-77.7032699584961, -7.00788688659668],
              [-77.68623352050781, -7.016479969024658],
              [-77.6818618774414, -7.018802165985107],
              [-77.67774200439453, -7.027577877044678],
              [-77.6761703491211, -7.039571762084961],
              [-77.6704330444336, -7.049276828765869],
              [-77.66053009033203, -7.051620006561279],
              [-77.65037536621094, -7.050044059753418],
              [-77.63861846923828, -7.048474788665771],
              [-77.6273422241211, -7.056587219238281],
              [-77.61605834960938, -7.059395790100098],
              [-77.61439514160156, -7.045107841491699],
              [-77.61022186279297, -7.03797721862793],
              [-77.60285949707031, -7.04261589050293],
              [-77.58790588378906, -7.052124977111816],
              [-77.57615661621094, -7.056319236755371],
              [-77.56625366210938, -7.060276031494141],
              [-77.55683898925781, -7.070917129516602],
              [-77.54349517822266, -7.080881118774414],
              [-77.5472412109375, -7.09954309463501],
              [-77.55831146240234, -7.100884914398193],
              [-77.56869506835938, -7.101538181304932],
              [-77.57009887695312, -7.109372138977051],
              [-77.56967163085938, -7.119750022888184],
              [-77.57337951660156, -7.126423835754395],
              [-77.57294464111328, -7.133112907409668],
              [-77.56211853027344, -7.138688087463379],
              [-77.55107116699219, -7.144956111907959],
              [-77.54048919677734, -7.152375221252441],
              [-77.5306167602539, -7.165557861328125],
              [-77.52605438232422, -7.181718826293945],
              [-77.52452087402344, -7.208710193634033],
              [-77.52735900878906, -7.231072902679443],
              [-77.52992248535156, -7.240982055664062],
              [-77.53131866455078, -7.243052959442139],
              [-77.53547668457031, -7.247651100158691],
              [-77.5472412109375, -7.247146129608154],
              [-77.55231475830078, -7.24735689163208],
              [-77.55440521240234, -7.25173282623291],
              [-77.54936218261719, -7.26167106628418],
              [-77.54154205322266, -7.269773960113525],
              [-77.53651428222656, -7.283635139465332],
              [-77.52985382080078, -7.293581008911133],
              [-77.5213623046875, -7.307455062866211],
              [-77.51978302001953, -7.319228172302246],
              [-77.52281951904297, -7.330292224884033],
              [-77.5256118774414, -7.33928108215332],
              [-77.5346450805664, -7.349862098693848],
              [-77.54271697998047, -7.351215839385986],
              [-77.5533218383789, -7.34863805770874],
              [-77.55471801757812, -7.352324962615967],
              [-77.54830169677734, -7.36504077911377],
              [-77.54255676269531, -7.37221622467041],
              [-77.5429458618164, -7.37750244140625],
              [-77.54375457763672, -7.388596057891846],
              [-77.53941345214844, -7.400612831115723],
              [-77.52628326416016, -7.407815933227539],
              [-77.51777648925781, -7.41638708114624],
              [-77.51366424560547, -7.430481910705566],
              [-77.5139389038086, -7.444560050964355],
              [-77.51329803466797, -7.459796905517578],
              [-77.5135726928711, -7.47549295425415],
              [-77.51038360595703, -7.489123821258545],
              [-77.50694274902344, -7.494677066802979],
              [-77.5009536743164, -7.498623847961426],
              [-77.49241638183594, -7.496578216552734],
              [-77.48847198486328, -7.489204883575439],
              [-77.48040008544922, -7.491774082183838],
              [-77.4716796875, -7.506580829620361],
              [-77.46065521240234, -7.523935794830322],
              [-77.45547485351562, -7.535245418548584],
              [-77.45217895507812, -7.54243803024292],
              [-77.44760131835938, -7.556307792663574],
              [-77.44511413574219, -7.572248935699463],
              [-77.4502182006836, -7.583545207977295],
              [-77.45738983154297, -7.586273670196533],
              [-77.45905303955078, -7.6002197265625],
              [-77.45812225341797, -7.602682113647461],
              [-77.44961547851562, -7.612642765045166],
              [-77.44849395751953, -7.622578144073486],
              [-77.45220947265625, -7.630415916442871],
              [-77.45589447021484, -7.630171775817871],
              [-77.46231079101562, -7.62752628326416],
              [-77.46719360351562, -7.62551212310791],
              [-77.47643280029297, -7.629172801971436],
              [-77.47899627685547, -7.637247085571289],
              [-77.475341796875, -7.651349067687988],
              [-77.4659423828125, -7.67124605178833],
              [-77.45352172851562, -7.684688091278076],
              [-77.44490814208984, -7.683812141418457],
              [-77.44474792480469, -7.683795928955078],
              [-77.43135833740234, -7.681303977966309],
              [-77.4202880859375, -7.684347152709961],
              [-77.40946197509766, -7.689929962158203],
              [-77.40116882324219, -7.696659088134766],
              [-77.39335632324219, -7.70615816116333],
              [-77.3901596069336, -7.71841287612915],
              [-77.39804077148438, -7.731782913208008],
              [-77.40382385253906, -7.737306118011475],
              [-77.40617370605469, -7.751389980316162],
              [-77.3995132446289, -7.762504100799561],
              [-77.38960266113281, -7.766235828399658],
              [-77.38176727294922, -7.769730091094971],
              [-77.37323760986328, -7.7743821144104],
              [-77.36332702636719, -7.779732227325439],
              [-77.36311340332031, -7.783892154693604],
              [-77.36497497558594, -7.789660930633545],
              [-77.36524200439453, -7.803754806518555],
              [-77.36042785644531, -7.813707828521729],
              [-77.35580444335938, -7.812107086181641],
              [-77.3460922241211, -7.806597232818604],
              [-77.33387756347656, -7.811492919921875],
              [-77.33112335205078, -7.815893173217773],
              [-77.3246841430664, -7.824929237365723],
              [-77.31498718261719, -7.823808193206787],
              [-77.31219482421875, -7.815961837768555],
              [-77.31124877929688, -7.806490898132324],
              [-77.30338287353516, -7.800281047821045],
              [-77.28606414794922, -7.79618501663208],
              [-77.26114654541016, -7.798584938049316],
              [-77.2512435913086, -7.806939125061035],
              [-77.25080108642578, -7.815951824188232],
              [-77.25914001464844, -7.826783180236816],
              [-77.26446533203125, -7.834157943725586],
              [-77.2665786743164, -7.845242023468018],
              [-77.26013946533203, -7.855663776397705],
              [-77.24285125732422, -7.863814830780029],
              [-77.23363494873047, -7.869394779205322],
              [-77.2403564453125, -7.879076957702637],
              [-77.25606536865234, -7.885723114013672],
              [-77.26922607421875, -7.885907173156738],
              [-77.28144836425781, -7.882396221160889],
              [-77.29182434082031, -7.87796688079834],
              [-77.29622650146484, -7.884191989898682],
              [-77.29393768310547, -7.892519950866699],
              [-77.29741668701172, -7.897823810577393],
              [-77.29996490478516, -7.901280879974365],
              [-77.29560089111328, -7.908462047576904],
              [-77.28821563720703, -7.910337924957275],
              [-77.28685760498047, -7.920743942260742],
              [-77.2931137084961, -7.928349018096924],
              [-77.30329132080078, -7.935478210449219],
              [-77.30770874023438, -7.945104122161865],
              [-77.30816650390625, -7.946094036102295],
              [-77.30794525146484, -7.950255870819092],
              [-77.30010223388672, -7.953519821166992],
              [-77.2936019897461, -7.95194673538208],
              [-77.2878646850586, -7.950559139251709],
              [-77.27884674072266, -7.945736885070801],
              [-77.26912689208984, -7.935832023620605],
              [-77.26197052001953, -7.936781883239746],
              [-77.24723052978516, -7.948392868041992],
              [-77.23362731933594, -7.95468282699585],
              [-77.22369384765625, -7.951019763946533],
              [-77.22505187988281, -7.94038200378418],
              [-77.22825622558594, -7.932048797607422],
              [-77.22569274902344, -7.921887874603271],
              [-77.2173843383789, -7.923072814941406],
              [-77.20909118652344, -7.929806232452393],
              [-77.2008056640625, -7.936307907104492],
              [-77.18949890136719, -7.939583778381348],
              [-77.18050384521484, -7.942622184753418],
              [-77.17335510253906, -7.945189952850342],
              [-77.1631851196289, -7.940141201019287],
              [-77.1580810546875, -7.929526805877686],
              [-77.15989685058594, -7.91611385345459],
              [-77.15918731689453, -7.909413814544678],
              [-77.1543197631836, -7.900879859924316],
              [-77.14854431152344, -7.900207042694092],
              [-77.13724517822266, -7.903945922851562],
              [-77.12548828125, -7.911384105682373],
              [-77.11004638671875, -7.919066905975342],
              [-77.10291290283203, -7.926720142364502],
              [-77.09831237792969, -7.935519218444824],
              [-77.09417724609375, -7.942699909210205],
              [-77.08702850341797, -7.94688606262207],
              [-77.0833511352539, -7.95198392868042],
              [-77.08476257324219, -7.961918830871582],
              [-77.09101104736328, -7.970681190490723],
              [-77.09333038330078, -7.975758075714111],
              [-77.0926742553711, -7.98824405670166],
              [-77.08946228027344, -7.997964859008789],
              [-77.08418273925781, -8.010467529296875],
              [-77.07611846923828, -8.017894744873047],
              [-77.06851196289062, -8.02208423614502],
              [-77.05652618408203, -8.02767562866211],
              [-77.0459213256836, -8.034880638122559],
              [-77.03878021240234, -8.042074203491211],
              [-77.03925323486328, -8.048084259033203],
              [-77.04411315917969, -8.052227973937988],
              [-77.05381774902344, -8.054043769836426],
              [-77.06258392333984, -8.052393913269043],
              [-77.07318878173828, -8.047500610351562],
              [-77.08679962158203, -8.042596817016602],
              [-77.09833526611328, -8.041399002075195],
              [-77.1126480102539, -8.039730072021484],
              [-77.1202621459961, -8.038778305053711],
              [-77.13042449951172, -8.041053771972656],
              [-77.14058685302734, -8.04124927520752],
              [-77.14845275878906, -8.048389434814453],
              [-77.15953826904297, -8.05112361907959],
              [-77.16386413574219, -8.050202369689941],
              [-77.16946411132812, -8.0490083694458],
              [-77.17707824707031, -8.049212455749512],
              [-77.18771362304688, -8.052642822265625],
              [-77.19209289550781, -8.051933288574219],
              [-77.20085144042969, -8.047045707702637],
              [-77.21239471435547, -8.045387268066406],
              [-77.22071838378906, -8.049750328063965],
              [-77.2235107421875, -8.057140350341797],
              [-77.22493743896484, -8.073322296142578],
              [-77.23027801513672, -8.085097312927246],
              [-77.23951721191406, -8.088764190673828],
              [-77.24761199951172, -8.09312915802002],
              [-77.25386810302734, -8.100506782531738],
              [-77.25133514404297, -8.104909896850586],
              [-77.2471923828125, -8.110243797302246],
              [-77.23982238769531, -8.114665031433105],
              [-77.23200988769531, -8.130651473999023],
              [-77.23017883300781, -8.135514259338379],
              [-77.2244644165039, -8.159358024597168],
              [-77.2205581665039, -8.166080474853516],
              [-77.21063232421875, -8.168890953063965],
              [-77.19976043701172, -8.160833358764648],
              [-77.18658447265625, -8.153477668762207],
              [-77.17665100097656, -8.15212631225586],
              [-77.17157745361328, -8.15538215637207],
              [-77.17459869384766, -8.162541389465332],
              [-77.18431854248047, -8.172452926635742],
              [-77.19126892089844, -8.179137229919434],
              [-77.19220733642578, -8.187692642211914],
              [-77.19062042236328, -8.197182655334473],
              [-77.18695831298828, -8.209457397460938],
              [-77.1846694946289, -8.219643592834473],
              [-77.17913818359375, -8.222208023071289],
              [-77.17497253417969, -8.220141410827637],
              [-77.16942596435547, -8.217615127563477],
              [-77.16433715820312, -8.214162826538086],
              [-77.15693664550781, -8.209562301635742],
              [-77.14815521240234, -8.206816673278809],
              [-77.14352416992188, -8.206833839416504],
              [-77.14053344726562, -8.206844329833984],
              [-77.13893127441406, -8.211013793945312],
              [-77.13916778564453, -8.213558197021484],
              [-77.14472961425781, -8.222559928894043],
              [-77.14867401123047, -8.230180740356445],
              [-77.14930725097656, -8.233222961425781],
              [-77.15031433105469, -8.238040924072266],
              [-77.15036010742188, -8.239507675170898],
              [-77.15055847167969, -8.246370315551758],
              [-77.14896392822266, -8.254010200500488],
              [-77.14366912841797, -8.26073932647705],
              [-77.14044189453125, -8.263989448547363],
              [-77.12843322753906, -8.261717796325684],
              [-77.12287902832031, -8.258960723876953],
              [-77.11479187011719, -8.255056381225586],
              [-77.10369110107422, -8.248385429382324],
              [-77.09375762939453, -8.248188972473145],
              [-77.07528686523438, -8.249873161315918],
              [-77.06605529785156, -8.25059986114502],
              [-77.04734802246094, -8.250664710998535],
              [-77.04203796386719, -8.251147270202637],
              [-77.04019165039062, -8.25300407409668],
              [-77.04576110839844, -8.262932777404785],
              [-77.05731964111328, -8.267983436584473],
              [-77.0718765258789, -8.270708084106445],
              [-77.08019256591797, -8.274380683898926],
              [-77.08251953125, -8.281314849853516],
              [-77.08438873291016, -8.290101051330566],
              [-77.0892562866211, -8.296099662780762],
              [-77.09019470214844, -8.301419258117676],
              [-77.0839614868164, -8.305143356323242],
              [-77.07818603515625, -8.302387237548828],
              [-77.06732177734375, -8.29964828491211],
              [-77.06108856201172, -8.298744201660156],
              [-77.0622787475586, -8.313087463378906],
              [-77.06783294677734, -8.318853378295898],
              [-77.07524108886719, -8.326462745666504],
              [-77.07250213623047, -8.339664459228516],
              [-77.06465148925781, -8.340617179870605],
              [-77.05609893798828, -8.338333129882812],
              [-77.04939270019531, -8.3346529006958],
              [-77.03717803955078, -8.345573425292969],
              [-77.0279312133789, -8.341670989990234],
              [-77.02123260498047, -8.340999603271484],
              [-77.01869201660156, -8.340545654296875],
              [-77.0186996459961, -8.34447956085205],
              [-77.01964569091797, -8.355354309082031],
              [-77.01851654052734, -8.36577320098877],
              [-77.01251983642578, -8.368803024291992],
              [-77.00444030761719, -8.371145248413086],
              [-76.99889373779297, -8.37047004699707],
              [-76.98941040039062, -8.365180015563965],
              [-76.98316955566406, -8.361498832702637],
              [-76.97599792480469, -8.356895446777344],
              [-76.97091674804688, -8.354829788208008],
              [-76.96537017822266, -8.354618072509766],
              [-76.95890808105469, -8.3551025390625],
              [-76.95474243164062, -8.352572441101074],
              [-76.95150756835938, -8.34957504272461],
              [-76.94847869873047, -8.340097427368164],
              [-76.9459228515625, -8.330848693847656],
              [-76.94544219970703, -8.323213577270508],
              [-76.94635009765625, -8.315112113952637],
              [-76.95026397705078, -8.3102388381958],
              [-76.9530258178711, -8.305832862854004],
              [-76.95369720458984, -8.29819393157959],
              [-76.95091247558594, -8.291030883789062],
              [-76.94673919677734, -8.28317928314209],
              [-76.94141387939453, -8.27486801147461],
              [-76.9374771118164, -8.270254135131836],
              [-76.93123626708984, -8.268194198608398],
              [-76.9261474609375, -8.264046669006348],
              [-76.92058563232422, -8.253655433654785],
              [-76.91455841064453, -8.243496894836426],
              [-76.90807342529297, -8.235884666442871],
              [-76.901611328125, -8.236371040344238],
              [-76.89376068115234, -8.237092018127441],
              [-76.88567352294922, -8.234345436096191],
              [-76.87596893310547, -8.23137092590332],
              [-76.86627197265625, -8.230249404907227],
              [-76.85541534423828, -8.22982406616211],
              [-76.85173034667969, -8.231687545776367],
              [-76.84226989746094, -8.236578941345215],
              [-76.83927154541016, -8.238208770751953],
              [-76.8365249633789, -8.250018119812012],
              [-76.8404769897461, -8.261109352111816],
              [-76.84994506835938, -8.261770248413086],
              [-76.86009979248047, -8.261964797973633],
              [-76.86957550048828, -8.264476776123047],
              [-76.8751220703125, -8.267928123474121],
              [-76.88043975830078, -8.271147727966309],
              [-76.8841552734375, -8.279464721679688],
              [-76.88648986816406, -8.293107986450195],
              [-76.88951873779297, -8.307211875915527],
              [-76.88815307617188, -8.315777778625488],
              [-76.88447570800781, -8.32481575012207],
              [-76.87895202636719, -8.33154582977295],
              [-76.87364959716797, -8.335729598999023],
              [-76.871337890625, -8.335738182067871],
              [-76.8671875, -8.339454650878906],
              [-76.86604309082031, -8.345245361328125],
              [-76.86421203613281, -8.350573539733887],
              [-76.8609848022461, -8.353592872619629],
              [-76.85520935058594, -8.354308128356934],
              [-76.85012817382812, -8.352705955505371],
              [-76.8425064086914, -8.352964401245117],
              [-76.83489227294922, -8.355303764343262],
              [-76.82496643066406, -8.359272956848145],
              [-76.82220458984375, -8.365069389343262],
              [-76.83861541748047, -8.370566368103027],
              [-76.8467025756836, -8.370768547058105],
              [-76.86263275146484, -8.36978816986084],
              [-76.86725616455078, -8.373475074768066],
              [-76.8732681274414, -8.374610900878906],
              [-76.88318634033203, -8.370409965515137],
              [-76.89035034179688, -8.37015438079834],
              [-76.89566040039062, -8.372218132019043],
              [-76.89844512939453, -8.378226280212402],
              [-76.90308380126953, -8.387930870056152],
              [-76.90908813476562, -8.386521339416504],
              [-76.91854858398438, -8.381397247314453],
              [-76.93263244628906, -8.379727363586426],
              [-76.9356460571289, -8.385734558105469],
              [-76.93358612060547, -8.395231246948242],
              [-76.93384552001953, -8.407498359680176],
              [-76.94170379638672, -8.408859252929688],
              [-76.95348358154297, -8.410438537597656],
              [-76.96503448486328, -8.412249565124512],
              [-76.97982788085938, -8.416133880615234],
              [-76.97891998291016, -8.424469947814941],
              [-76.97085571289062, -8.430517196655273],
              [-76.97480010986328, -8.441383361816406],
              [-76.9873046875, -8.453841209411621],
              [-77.00072479248047, -8.465832710266113],
              [-77.00537109375, -8.474845886230469],
              [-77.00157928466797, -8.484024047851562],
              [-77.00077056884766, -8.48597526550293],
              [-76.99085235595703, -8.49110221862793],
              [-76.97953796386719, -8.49299430847168],
              [-76.96590423583984, -8.489800453186035],
              [-76.95896911621094, -8.489359855651855],
              [-76.95481872558594, -8.491922378540039],
              [-76.95159149169922, -8.495406150817871],
              [-76.946044921875, -8.494729995727539],
              [-76.93934631347656, -8.493826866149902],
              [-76.93264770507812, -8.493387222290039],
              [-76.92293548583984, -8.491106033325195],
              [-76.91438293457031, -8.486968040466309],
              [-76.91644287109375, -8.479320526123047],
              [-76.92544555664062, -8.476048469543457],
              [-76.93236541748047, -8.471857070922852],
              [-76.93190002441406, -8.469544410705566],
              [-76.93054962158203, -8.469193458557129],
              [-76.9275131225586, -8.468400955200195],
              [-76.91827392578125, -8.46889591217041],
              [-76.90811157226562, -8.468931198120117],
              [-76.9025650024414, -8.467793464660645],
              [-76.89192199707031, -8.461347579956055],
              [-76.8824462890625, -8.456750869750977],
              [-76.87528991699219, -8.459321975708008],
              [-76.86814880371094, -8.470227241516113],
              [-76.87256622314453, -8.484103202819824],
              [-76.88298034667969, -8.492170333862305],
              [-76.88692474365234, -8.500260353088379],
              [-76.89077758789062, -8.511311531066895],
              [-76.89087677001953, -8.511590957641602],
              [-76.890625, -8.511712074279785],
              [-76.88602447509766, -8.513922691345215],
              [-76.87586975097656, -8.516274452209473],
              [-76.8656997680664, -8.515382766723633],
              [-76.85160827636719, -8.512420654296875],
              [-76.83843994140625, -8.511308670043945],
              [-76.8194808959961, -8.50442886352539],
              [-76.80885314941406, -8.50214958190918],
              [-76.78944396972656, -8.500134468078613],
              [-76.77604675292969, -8.49832820892334],
              [-76.75686645507812, -8.49468994140625],
              [-76.7467041015625, -8.494725227355957],
              [-76.72937774658203, -8.4931640625],
              [-76.72338104248047, -8.498510360717773],
              [-76.71438598632812, -8.503402709960938],
              [-76.70722961425781, -8.508289337158203],
              [-76.70285034179688, -8.512240409851074],
              [-76.69939422607422, -8.518272399902344],
              [-76.70195770263672, -8.52729320526123],
              [-76.70658111572266, -8.532370567321777],
              [-76.71328735351562, -8.534199714660645],
              [-76.7241439819336, -8.534163475036621],
              [-76.73430633544922, -8.534128189086914],
              [-76.74701690673828, -8.538947105407715],
              [-76.75464630126953, -8.54123592376709],
              [-76.76712799072266, -8.54420280456543],
              [-76.77613830566406, -8.545792579650879],
              [-76.79068756103516, -8.544816017150879],
              [-76.79784393310547, -8.542244911193848],
              [-76.81053924560547, -8.539191246032715],
              [-76.8151626586914, -8.537322044372559],
              [-76.82301330566406, -8.538915634155273],
              [-76.8274154663086, -8.54422664642334],
              [-76.82997131347656, -8.551396369934082],
              [-76.83368682861328, -8.561803817749023],
              [-76.83902740478516, -8.574291229248047],
              [-76.842041015625, -8.580995559692383],
              [-76.85894775390625, -8.601780891418457],
              [-76.87051391601562, -8.6072998046875],
              [-76.87928771972656, -8.605879783630371],
              [-76.8896713256836, -8.600285530090332],
              [-76.89108276367188, -8.59972858428955],
              [-76.90213775634766, -8.595379829406738],
              [-76.9088363647461, -8.593273162841797],
              [-76.92176055908203, -8.589290618896484],
              [-76.92869567871094, -8.589962005615234],
              [-76.93262481689453, -8.592033386230469],
              [-76.93795013427734, -8.596182823181152],
              [-76.94442749023438, -8.59986686706543],
              [-76.95111846923828, -8.594749450683594],
              [-76.95458221435547, -8.593810081481934],
              [-76.95551300048828, -8.597513198852539],
              [-76.9552993774414, -8.606546401977539],
              [-76.95738983154297, -8.610939025878906],
              [-76.96015930175781, -8.610466957092285],
              [-76.96778106689453, -8.607661247253418],
              [-76.98370361328125, -8.601585388183594],
              [-76.99224853515625, -8.598775863647461],
              [-76.99848937988281, -8.600607872009277],
              [-77.00428771972656, -8.607998847961426],
              [-77.00360870361328, -8.615181922912598],
              [-77.00062561035156, -8.625383377075195],
              [-77.00617980957031, -8.628838539123535],
              [-77.01335144042969, -8.631592750549316],
              [-77.02053833007812, -8.642918586730957],
              [-77.02888488769531, -8.654935836791992],
              [-77.02912139892578, -8.65910530090332],
              [-77.02406311035156, -8.668155670166016],
              [-77.01876068115234, -8.672807693481445],
              [-77.00467681884766, -8.679342269897461],
              [-76.99659729003906, -8.6814546585083],
              [-76.99151611328125, -8.685178756713867],
              [-76.99060821533203, -8.690279006958008],
              [-76.99246978759766, -8.695832252502441],
              [-76.98857116699219, -8.709978103637695],
              [-76.98766326904297, -8.719017028808594],
              [-76.98490142822266, -8.723427772521973],
              [-76.97311401367188, -8.721383094787598],
              [-76.96617889404297, -8.720479965209961],
              [-76.9617919921875, -8.722349166870117],
              [-76.9560317993164, -8.729782104492188],
              [-76.95281982421875, -8.739755630493164],
              [-76.94706726074219, -8.749738693237305],
              [-76.93622589111328, -8.761362075805664],
              [-76.92929077148438, -8.760457992553711],
              [-76.92511749267578, -8.754447937011719],
              [-76.92625427246094, -8.744481086730957],
              [-76.92784881591797, -8.734280586242676],
              [-76.92713928222656, -8.72663688659668],
              [-76.92296600341797, -8.72016429901123],
              [-76.91695404052734, -8.717867851257324],
              [-76.91024780273438, -8.715805053710938],
              [-76.90908813476562, -8.713260650634766],
              [-76.91625213623047, -8.711614608764648],
              [-76.91854095458984, -8.702340126037598],
              [-76.9115982055664, -8.697266578674316],
              [-76.90235137939453, -8.69567584991455],
              [-76.8933334350586, -8.692927360534668],
              [-76.88809967041016, -8.691414833068848],
              [-76.8766860961914, -8.688118934631348],
              [-76.8707046508789, -8.699028015136719],
              [-76.87487030029297, -8.704805374145508],
              [-76.88204193115234, -8.708950996398926],
              [-76.88777923583984, -8.712417602539062],
              [-76.88851928710938, -8.71286678314209],
              [-76.89222717285156, -8.716329574584961],
              [-76.88970184326172, -8.724678993225098],
              [-76.88832092285156, -8.728158950805664],
              [-76.88672637939453, -8.737664222717285],
              [-76.88720703125, -8.748088836669922],
              [-76.88631439208984, -8.759908676147461],
              [-76.88494110107422, -8.766864776611328],
              [-76.87616729736328, -8.77013874053955],
              [-76.87300872802734, -8.767110824584961],
              [-76.87014770507812, -8.76436710357666],
              [-76.8680419921875, -8.754409790039062],
              [-76.85437774658203, -8.740091323852539],
              [-76.84696197509766, -8.729227066040039],
              [-76.84233093261719, -8.72344970703125],
              [-76.83283996582031, -8.713056564331055],
              [-76.82659912109375, -8.712846755981445],
              [-76.82429504394531, -8.716097831726074],
              [-76.82638549804688, -8.723967552185059],
              [-76.83219146728516, -8.736923217773438],
              [-76.83567810058594, -8.746642112731934],
              [-76.83037567138672, -8.752684593200684],
              [-76.8144302368164, -8.754129409790039],
              [-76.8031005859375, -8.74698543548584],
              [-76.79358673095703, -8.728713035583496],
              [-76.79148864746094, -8.717599868774414],
              [-76.7886962890625, -8.71065902709961],
              [-76.78498840332031, -8.704184532165527],
              [-76.78083038330078, -8.702576637268066],
              [-76.77598571777344, -8.70583724975586],
              [-76.77277374267578, -8.718589782714844],
              [-76.7704849243164, -8.730646133422852],
              [-76.76773834228516, -8.745019912719727],
              [-76.76521301269531, -8.754759788513184],
              [-76.76314544677734, -8.75916862487793],
              [-76.75691986083984, -8.765445709228516],
              [-76.7520751953125, -8.769865036010742],
              [-76.74327850341797, -8.763406753540039],
              [-76.73587036132812, -8.755785942077637],
              [-76.72940826416016, -8.761137008666992],
              [-76.72850799560547, -8.77064037322998],
              [-76.72898864746094, -8.780138969421387],
              [-76.72923278808594, -8.788711547851562],
              [-76.7253189086914, -8.7972993850708],
              [-76.71792602539062, -8.798251152038574],
              [-76.71214294433594, -8.794330596923828],
              [-76.70613098144531, -8.792730331420898],
              [-76.70104217529297, -8.791125297546387],
              [-76.6971206665039, -8.790905952453613],
              [-76.69271850585938, -8.784433364868164],
              [-76.69085693359375, -8.77772045135498],
              [-76.68807220458984, -8.77100944519043],
              [-76.68436431884766, -8.765692710876465],
              [-76.68042755126953, -8.759913444519043],
              [-76.6781005859375, -8.754361152648926],
              [-76.68040466308594, -8.749486923217773],
              [-76.68502044677734, -8.745532989501953],
              [-76.68339538574219, -8.741830825805664],
              [-76.67760467529297, -8.731657028198242],
              [-76.67250061035156, -8.720321655273438],
              [-76.6664810180664, -8.71316146850586],
              [-76.66139221191406, -8.710630416870117],
              [-76.65538787841797, -8.712503433227539],
              [-76.64545440673828, -8.715085983276367],
              [-76.63783264160156, -8.71372127532959],
              [-76.63020324707031, -8.710736274719238],
              [-76.61769104003906, -8.691551208496094],
              [-76.61743927001953, -8.67834758758545],
              [-76.613037109375, -8.671181678771973],
              [-76.6040267944336, -8.671908378601074],
              [-76.59363555908203, -8.675186157226562],
              [-76.5857925415039, -8.679150581359863],
              [-76.57839965820312, -8.678481101989746],
              [-76.57051849365234, -8.665767669677734],
              [-76.56149291992188, -8.654217720031738],
              [-76.5547866821289, -8.651229858398438],
              [-76.53446197509766, -8.650605201721191],
              [-76.52083587646484, -8.654356956481934],
              [-76.51229858398438, -8.661334991455078],
              [-76.51209259033203, -8.677085876464844],
              [-76.51787567138672, -8.684478759765625],
              [-76.52159881591797, -8.69813346862793],
              [-76.52299499511719, -8.70438289642334],
              [-76.52508544921875, -8.713178634643555],
              [-76.53224182128906, -8.712459564208984],
              [-76.54125213623047, -8.711271286010742],
              [-76.541259765625, -8.716830253601074],
              [-76.5433578491211, -8.726090431213379],
              [-76.55213165283203, -8.723511695861816],
              [-76.56230163574219, -8.727646827697754],
              [-76.56578063964844, -8.734122276306152],
              [-76.56856536865234, -8.743612289428711],
              [-76.56395721435547, -8.75173568725586],
              [-76.55725860595703, -8.75338077545166],
              [-76.55264282226562, -8.75478744506836],
              [-76.5489501953125, -8.758275032043457],
              [-76.54826354980469, -8.761983871459961],
              [-76.54481506347656, -8.773348808288574],
              [-76.54066467285156, -8.778461456298828],
              [-76.53328704833984, -8.786827087402344],
              [-76.53006744384766, -8.800277709960938],
              [-76.53630828857422, -8.80002498626709],
              [-76.54808807373047, -8.79697322845459],
              [-76.55732727050781, -8.794624328613281],
              [-76.56195068359375, -8.798084259033203],
              [-76.561279296875, -8.808513641357422],
              [-76.55944061279297, -8.817094802856445],
              [-76.56061553955078, -8.83006763458252],
              [-76.56686401367188, -8.835144996643066],
              [-76.57240295410156, -8.83188247680664],
              [-76.57933044433594, -8.82768726348877],
              [-76.58393859863281, -8.820487976074219],
              [-76.5920181274414, -8.813277244567871],
              [-76.6021728515625, -8.8076810836792],
              [-76.61441802978516, -8.806943893432617],
              [-76.63105773925781, -8.806424140930176],
              [-76.643310546875, -8.808932304382324],
              [-76.64747619628906, -8.812162399291992],
              [-76.64864349365234, -8.818879127502441],
              [-76.64795684814453, -8.824906349182129],
              [-76.64704132080078, -8.830702781677246],
              [-76.64705657958984, -8.837656021118164],
              [-76.64636993408203, -8.841829299926758],
              [-76.6449966430664, -8.849249839782715],
              [-76.64476776123047, -8.852496147155762],
              [-76.64339447021484, -8.858989715576172],
              [-76.64435577392578, -8.87891960144043],
              [-76.64990997314453, -8.884231567382812],
              [-76.65616607666016, -8.89301872253418],
              [-76.65433502197266, -8.906005859375],
              [-76.65690612792969, -8.920600891113281],
              [-76.66593933105469, -8.933089256286621],
              [-76.6751937866211, -8.937694549560547],
              [-76.68790435791016, -8.935564994812012],
              [-76.69921875, -8.929267883300781],
              [-76.7135238647461, -8.918556213378906],
              [-76.72460174560547, -8.911101341247559],
              [-76.7303695678711, -8.905055046081543],
              [-76.74398803710938, -8.893881797790527],
              [-76.76568603515625, -8.880365371704102],
              [-76.77999877929688, -8.873130798339844],
              [-76.78693389892578, -8.87380313873291],
              [-76.79341888427734, -8.881430625915527],
              [-76.79181671142578, -8.888854026794434],
              [-76.78189849853516, -8.89746379852295],
              [-76.77405548095703, -8.90467643737793],
              [-76.76667785644531, -8.915364265441895],
              [-76.76183319091797, -8.922102928161621],
              [-76.7593002319336, -8.924661636352539],
              [-76.75838470458984, -8.930459976196289],
              [-76.76024627685547, -8.935322761535645],
              [-76.7669448852539, -8.934372901916504],
              [-76.7768783569336, -8.93364429473877],
              [-76.78082275390625, -8.93803596496582],
              [-76.77690124511719, -8.945236206054688],
              [-76.77205657958984, -8.95035171508789],
              [-76.76375579833984, -8.959190368652344],
              [-76.75289916992188, -8.964095115661621],
              [-76.7459716796875, -8.967827796936035],
              [-76.73397064208984, -8.974592208862305],
              [-76.72796630859375, -8.979480743408203],
              [-76.72889709472656, -8.983187675476074],
              [-76.73283386230469, -8.988275527954102],
              [-76.73723602294922, -8.99312973022461],
              [-76.73932647705078, -8.998225212097168],
              [-76.73748779296875, -9.003795623779297],
              [-76.73380279541016, -9.008678436279297],
              [-76.72964477539062, -9.013096809387207],
              [-76.72526550292969, -9.017980575561523],
              [-76.72042083740234, -9.025185585021973],
              [-76.71720123291016, -9.031689643859863],
              [-76.70935821533203, -9.042383193969727],
              [-76.69829559326172, -9.059118270874023],
              [-76.6883773803711, -9.073993682861328],
              [-76.682861328125, -9.089086532592773],
              [-76.67987823486328, -9.10254955291748],
              [-76.6775894165039, -9.115313529968262],
              [-76.6734390258789, -9.122518539428711],
              [-76.66998291015625, -9.128096580505371],
              [-76.66815948486328, -9.144108772277832],
              [-76.67048645019531, -9.15361213684082],
              [-76.67227172851562, -9.156922340393066],
              [-76.67373657226562, -9.159632682800293],
              [-76.67675018310547, -9.166351318359375],
              [-76.6786117553711, -9.17237663269043],
              [-76.68116760253906, -9.18295669555664],
              [-76.7072871461687, -9.182978639690884],
              [-76.7070160468816, -9.184462631301649],
              [-76.70545205874024, -9.18907197596619],
              [-76.70270525514783, -9.193641698024527],
              [-76.69976056142177, -9.196024375107454],
              [-76.69596096757198, -9.197968037147398],
              [-76.691619820045, -9.201207102639048],
              [-76.68713389484121, -9.206074230715767],
              [-76.68521125439548, -9.209175335569283],
              [-76.68368542465694, -9.212609106833023],
              [-76.68210604298496, -9.216269157937266],
              [-76.6808090479568, -9.219756104021423],
              [-76.6799088853343, -9.22357572292582],
              [-76.67934409101554, -9.226995960067745],
              [-76.68036645651343, -9.231746887413147],
              [-76.68255624892866, -9.234638882968486],
              [-76.6865311802087, -9.236998088636126],
              [-76.69122312237985, -9.238350290119588],
              [-76.69325243659661, -9.241912001623799],
              [-76.69025408550311, -9.244520959721221],
              [-76.6868667785502, -9.247755573550712],
              [-76.683982743366, -9.25086122553771],
              [-76.68048086357392, -9.253590026499282],
              [-76.67720044283348, -9.25637201359522],
              [-76.67572794171087, -9.259579510101064],
              [-76.67388148785459, -9.26436193420736],
              [-76.67230214693971, -9.268021989734471],
              [-76.66919720046789, -9.271083395663883],
              [-76.66561113884168, -9.272130811961947],
              [-76.66199502595184, -9.271270616190261],
              [-76.65734853438273, -9.269691845844738],
              [-76.6528245054406, -9.268627800362422],
              [-76.64933025260372, -9.268273219619486],
              [-76.64539339680817, -9.26876148791307],
              [-76.64202876755854, -9.269861906252954],
              [-76.63833604087525, -9.271361621217249],
              [-76.63459024318566, -9.273078540230077],
              [-76.62847907271711, -9.274715785937797],
              [-76.62298594920078, -9.276738916839466],
              [-76.61869813418521, -9.279751085678381],
              [-76.61506650368851, -9.281973638309616],
              [-76.61133563334327, -9.284648685537228],
              [-76.60677341066743, -9.28878313241611],
              [-76.60131843386361, -9.292713586019165],
              [-76.5985945951313, -9.29514893478929],
              [-76.5958710024846, -9.29758426751137],
              [-76.59153740073762, -9.301780842518088],
              [-76.58731084248784, -9.305515781321535],
              [-76.58331289070382, -9.309312960053525],
              [-76.5802611873195, -9.312147496923469],
              [-76.57671357885306, -9.316051126766954],
              [-76.57395169663042, -9.319661927459993],
              [-76.5726775520728, -9.325056397958306],
              [-76.57450879920856, -9.330473303252178],
              [-76.57724002318744, -9.33610319655865],
              [-76.57886499472895, -9.339332893052617],
              [-76.58031463613237, -9.342283065906644],
              [-76.58218377680343, -9.346524312947778],
              [-76.58411399235355, -9.351488598876788],
              [-76.58598320259549, -9.355720766617441],
              [-76.58744814977499, -9.35962922850771],
              [-76.58943953080843, -9.364367144657292],
              [-76.59113297989576, -9.368328801862894],
              [-76.59254442301486, -9.372454460418297],
              [-76.59413158666679, -9.376859604374705],
              [-76.59413919075476, -9.380892107713933],
              [-76.59335320434867, -9.385217515934906],
              [-76.5920027701274, -9.388930640053319],
              [-76.59075921953672, -9.392191203286247],
              [-76.5906906756886, -9.395500726922668],
              [-76.59236915530512, -9.398504006980273],
              [-76.59371944076807, -9.401906583735444],
              [-76.59501644865242, -9.405535429913924],
              [-76.59625245735127, -9.40938153922364],
              [-76.5986786627655, -9.413276495294305],
              [-76.60114276306092, -9.415995855784635],
              [-76.60408796922869, -9.420729258570788],
              [-76.60550698764509, -9.42485476039032],
              [-76.60568228824184, -9.429166753527952],
              [-76.6048888795728, -9.432533806581889],
              [-76.60087587530884, -9.435382073175333],
              [-76.59635167466494, -9.438341122528168],
              [-76.59309392790449, -9.438997839869758],
              [-76.58908847879172, -9.438753752026308],
              [-76.58518974412631, -9.438075141234426],
              [-76.58044440067157, -9.436948209149303],
              [-76.57649232418558, -9.436486734099942],
              [-76.5733109756217, -9.438815629187957],
              [-76.57081610632008, -9.441304185850093],
              [-76.56771854112934, -9.44531441395852],
              [-76.56500997782318, -9.448708060612615],
              [-76.56268300120722, -9.451476122368131],
              [-76.5597304574001, -9.453858160894029],
              [-76.55631267652015, -9.455184365843635],
              [-76.5523606601221, -9.45472264703197],
              [-76.54749308647034, -9.45309853517551],
              [-76.54367075281597, -9.450059246338038],
              [-76.54159554057854, -9.446713868809967],
              [-76.54001617077735, -9.44325779977681],
              [-76.53813920905193, -9.438076042312904],
              [-76.53615581928572, -9.433328734496193],
              [-76.53388987608444, -9.428763472535445],
              [-76.53159324767495, -9.426323141785415],
              [-76.5280990226184, -9.425967604101604],
              [-76.52314748765102, -9.426694313066534],
              [-76.51901255929086, -9.429035925121081],
              [-76.51510630704001, -9.431430763907384],
              [-76.51234441339396, -9.435041304497885],
              [-76.50917806499153, -9.438319017180621],
              [-76.50532532799225, -9.440496537214145],
              [-76.50048052511856, -9.44077041266319],
              [-76.49443818142757, -9.440045657791726],
              [-76.49104319692259, -9.439246289993488],
              [-76.48765558321354, -9.438437813875003],
              [-76.48424542143354, -9.43667998987173],
              [-76.48073591445328, -9.435374645222387],
              [-76.47632604971743, -9.434805442356268],
              [-76.47256470547389, -9.435571689158738],
              [-76.46943676499265, -9.437673475225607],
              [-76.46671290616244, -9.440117151930192],
              [-76.46395124783709, -9.443727395474617],
              [-76.46207432870611, -9.446601538917133],
              [-76.45952593901579, -9.449315708738995],
              [-76.45632929505739, -9.450694325346307],
              [-76.45308689879595, -9.452299158040894],
              [-76.44912728369995, -9.454919640794655],
              [-76.445053057782, -9.457983622871629],
              [-76.44193273752299, -9.46009422626025],
              [-76.43756852570827, -9.462372663250843],
              [-76.43381498408135, -9.4640969871569],
              [-76.42971813481566, -9.465262060484111],
              [-76.42630008369093, -9.466578047780795],
              [-76.42091357189884, -9.46814612486635],
              [-76.41552728458655, -9.46971412507188],
              [-76.41193383156232, -9.470759426767101],
              [-76.40805815424372, -9.47197763095098],
              [-76.40451052741237, -9.471847184280646],
              [-76.40103163549699, -9.469365433998101],
              [-76.39794928141053, -9.466258143585952],
              [-76.39536285048108, -9.463031296251962],
              [-76.39351648808606, -9.45974723160802],
              [-76.39220427074744, -9.45516798291189],
              [-76.39147199893308, -9.451201305187624],
              [-76.39107510427648, -9.446835433210556],
              [-76.3902817076484, -9.443086004733631],
              [-76.38904569693733, -9.439238853609382],
              [-76.38686359377697, -9.436344869765637],
              [-76.38373571844257, -9.434413076340821],
              [-76.3784257159682, -9.433629141310313],
              [-76.37431321815983, -9.433844155296343],
              [-76.370239287314, -9.432874416128312],
              [-76.36788954174669, -9.429709665967664],
              [-76.36539464152013, -9.425080582649274],
              [-76.36309049650728, -9.421689548079947],
              [-76.35970317427392, -9.420879770358777],
              [-76.35637677410648, -9.420802163098447],
              [-76.3515702176201, -9.419898295056072],
              [-76.34693154121624, -9.418324571565321],
              [-76.34362792171642, -9.416112720292958],
              [-76.34071353400144, -9.413284461065407],
              [-76.33757019609527, -9.410402783715714],
              [-76.33415211302943, -9.40769391801918],
              [-76.33157358721434, -9.404475462464903],
              [-76.32751477056081, -9.40042158904958],
              [-76.3247681746538, -9.39787284997433],
              [-76.32281499125575, -9.395031751541442],
              [-76.32293705317345, -9.391504682188533],
              [-76.32444760683893, -9.387122411175762],
              [-76.32652277710702, -9.382394403057557],
              [-76.32629384036932, -9.378308010353912],
              [-76.32310486787127, -9.375652376279767],
              [-76.31913754716686, -9.374229402501559],
              [-76.31574252732888, -9.373428195786774],
              [-76.31466672394649, -9.368901830561242],
              [-76.31325525030408, -9.364765534592097],
              [-76.3120803570967, -9.36164112909087],
              [-76.3106307125629, -9.358689542120555],
              [-76.30710604796909, -9.356423795974829],
              [-76.30359637683915, -9.355116477583532],
              [-76.2998732468656, -9.354705129535196],
              [-76.29645529963047, -9.356028825850414],
              [-76.29379272925898, -9.35919445190619],
              [-76.2907485511707, -9.362976358175452],
              [-76.28887182974881, -9.365849729855661],
              [-76.28634650374836, -9.37047069780806],
              [-76.28453813316092, -9.374076268786643],
              [-76.28222648672863, -9.377792170813766],
              [-76.28006741787402, -9.380838348133334],
              [-76.27757251819875, -9.383334121462472],
              [-76.27332293016671, -9.386116528220436],
              [-76.2698515989966, -9.387666280288286],
              [-76.26648700635405, -9.388763480305046],
              [-76.26279456687543, -9.390250662470244],
              [-76.26006308999678, -9.392692923746479],
              [-76.25853743176911, -9.396125585542038],
              [-76.25705731510071, -9.39933201146553],
              [-76.25626385023239, -9.402698762589607],
              [-76.25462336256932, -9.406583965648668],
              [-76.25344079446253, -9.410567009468506],
              [-76.25258634355833, -9.414160045288028],
              [-76.25197599639458, -9.418765010083133],
              [-76.25197601191455, -9.422798108832893],
              [-76.25305925599747, -9.4273247097374],
              [-76.2545777651623, -9.431008770602636],
              [-76.25692744556058, -9.434174413177027],
              [-76.25978076006582, -9.436280224957903],
              [-76.26374066923898, -9.437694784897255],
              [-76.26750936339107, -9.436930679209201],
              [-76.2725600523543, -9.435754977924773],
              [-76.27806846677817, -9.434695102033123],
              [-76.28369905701302, -9.43413204765594],
              [-76.28786474405969, -9.43370086604933],
              [-76.2916794236976, -9.432710278232983],
              [-76.29541023214055, -9.433121574534326],
              [-76.2967528451409, -9.436525716088882],
              [-76.29636385704507, -9.441184121928705],
              [-76.29530342949798, -9.445673191477583],
              [-76.29272452922646, -9.450520513998468],
              [-76.29056548017228, -9.453566790369187],
              [-76.28926841122305, -9.457052985618702],
              [-76.28954321325033, -9.460913200288537],
              [-76.29195408702182, -9.463861287642164],
              [-76.29496013568662, -9.465287989748338],
              [-76.29579926423183, -9.468802483796164],
              [-76.29799644822702, -9.472646525633227],
              [-76.30142212357545, -9.47535565792581],
              [-76.30562580314988, -9.477781530187388],
              [-76.31033315976322, -9.480087906596095],
              [-76.31391157132, -9.482127170603462],
              [-76.31800076140352, -9.484046916755451],
              [-76.32173917643988, -9.485407294892683],
              [-76.32733167221961, -9.486019116470406],
              [-76.33295439429952, -9.485464246336464],
              [-76.33903514759074, -9.485007004391383],
              [-76.34285726510755, -9.484015778977632],
              [-76.3473434382022, -9.482235265979241],
              [-76.35126504474285, -9.480791450529408],
              [-76.3550719530347, -9.478850704832746],
              [-76.35877987408752, -9.478311883653873],
              [-76.36322781334901, -9.477706849647827],
              [-76.36666861166717, -9.478290245033746],
              [-76.3696289125013, -9.479933443203004],
              [-76.37063591427287, -9.483736348652528],
              [-76.37153618988216, -9.487033292357424],
              [-76.37399282241175, -9.489754454511033],
              [-76.37768566363415, -9.491340433903323],
              [-76.38164510954961, -9.492753512276519],
              [-76.38501752376561, -9.495687976687579],
              [-76.38803849568617, -9.498063171990307],
              [-76.39107504765384, -9.501396757018073],
              [-76.39315044990485, -9.504734140079332],
              [-76.39586638273789, -9.509416245402674],
              [-76.3979568454087, -9.513702963392015],
              [-76.39942928117621, -9.5176123644875],
              [-76.40129077223503, -9.521845712429283],
              [-76.40399939583803, -9.525578309810143],
              [-76.40713499435465, -9.52845918429631],
              [-76.41152941297467, -9.532103514392873],
              [-76.41540530027152, -9.534927042743178],
              [-76.4191055563841, -9.537461884437317],
              [-76.42207341227969, -9.540062965859539],
              [-76.42487333620147, -9.542384395817228],
              [-76.4277190782643, -9.544488598148876],
              [-76.43084709296129, -9.54641981088609],
              [-76.43370054977747, -9.54851487998449],
              [-76.43762960445494, -9.551102792172845],
              [-76.44081114870595, -9.55280762439479],
              [-76.44422924322713, -9.555515110868495],
              [-76.44680036567343, -9.557792063179313],
              [-76.4476548021488, -9.562264326104053],
              [-76.44623574232412, -9.565245075889285],
              [-76.44321428007389, -9.566903302217426],
              [-76.43965916001125, -9.56677338599998],
              [-76.43547066476987, -9.565298778052275],
              [-76.43138112211996, -9.563380642585345],
              [-76.42786410789641, -9.562074965042488],
              [-76.42423256206416, -9.560263357047502],
              [-76.4203034708433, -9.55767529528562],
              [-76.41597760415105, -9.554745193571806],
              [-76.41097251878443, -9.551664065937722],
              [-76.40744007147589, -9.549399740370982],
              [-76.40425871946414, -9.5477036158207],
              [-76.40081776874473, -9.547120698634393],
              [-76.39871216417562, -9.549941309032821],
              [-76.39813997244653, -9.553361580092805],
              [-76.39995569609721, -9.557830184601201],
              [-76.40340414203602, -9.562436855983753],
              [-76.40713499145353, -9.566879575012633],
              [-76.41197968318629, -9.570639545843477],
              [-76.41635889817246, -9.57334339942082],
              [-76.4197310292477, -9.576277364189702],
              [-76.42140956078113, -9.57928152768579],
              [-76.42321009107695, -9.582791537653087],
              [-76.42563634377805, -9.586687770738653],
              [-76.42811580313075, -9.590357705840836],
              [-76.43183135043627, -9.593850706475802],
              [-76.43412013343593, -9.596291644014254],
              [-76.43501290751873, -9.599588308195731],
              [-76.43466935934343, -9.60306189527129],
              [-76.4337157876209, -9.607107663318901],
              [-76.43206789821515, -9.610035087929576],
              [-76.42913048771062, -9.61337473700675],
              [-76.4253770205739, -9.615090154690673],
              [-76.41994474434746, -9.613801407686033],
              [-76.41618358364099, -9.611493005669942],
              [-76.41083531455382, -9.607852792391084],
              [-76.40661631499837, -9.60447915590762],
              [-76.4032439639878, -9.601545091896023],
              [-76.399963286966, -9.597209071421084],
              [-76.3983917229771, -9.593752249466828],
              [-76.39601886077445, -9.589629519412085],
              [-76.39375300800405, -9.585063263715353],
              [-76.39125050069079, -9.579485199874021],
              [-76.38985449920858, -9.576307918699444],
              [-76.38845055577096, -9.573130659557902],
              [-76.3865585826461, -9.566980428515832],
              [-76.38506322035349, -9.561172202208372],
              [-76.38367455779448, -9.554911420937916],
              [-76.38140105819222, -9.549395609238058],
              [-76.37878415126569, -9.544260909122578],
              [-76.37528214891164, -9.539871145649412],
              [-76.37197107523109, -9.536719384124094],
              [-76.36889659602546, -9.534561312991745],
              [-76.3651580733116, -9.533201540635558],
              [-76.36144247614612, -9.533740536882839],
              [-76.35786441930537, -9.535734786097649],
              [-76.35401920566923, -9.538860344690866],
              [-76.35187543372086, -9.542856381743407],
              [-76.35065460665174, -9.5480243452463],
              [-76.35117342671766, -9.55289619538283],
              [-76.35175311935623, -9.55753270196334],
              [-76.35140986755744, -9.561015366556095],
              [-76.34850312587432, -9.56316971414373],
              [-76.34274286099775, -9.562278739623883],
              [-76.33781444935705, -9.560869043230014],
              [-76.33525077746853, -9.558600201268899],
              [-76.33130655430709, -9.555052592084241],
              [-76.32826999594484, -9.551727523927873],
              [-76.32564551835362, -9.549675844963796],
              [-76.32173162495398, -9.548035955977488],
              [-76.31818397202245, -9.54790473446321],
              [-76.31491856996634, -9.548550068964405],
              [-76.3110657228766, -9.55072578149653],
              [-76.30861666097397, -9.552986603451407],
              [-76.30566411975893, -9.555366856704241],
              [-76.30339820304901, -9.558865796073828],
              [-76.30103314583151, -9.562808184101877],
              [-76.29917153908467, -9.566640168003723],
              [-76.29837035946815, -9.570007009642818],
              [-76.2977982748878, -9.57342724975982],
              [-76.29813382807653, -9.577070159471074],
              [-76.29927839320108, -9.582319617281163],
              [-76.30032340244058, -9.588030610367257],
              [-76.30118570476716, -9.593452918175515],
              [-76.30210868266745, -9.598648926289282],
              [-76.30249786102436, -9.602065551755508],
              [-76.30339818988747, -9.60631216502711],
              [-76.3038024244115, -9.610678196406031],
              [-76.30300153164146, -9.614045047005947],
              [-76.29611210409628, -9.612886373114684],
              [-76.2894820901025, -9.610596262737833],
              [-76.28634640345719, -9.608672823500713],
              [-76.28112017962685, -9.605527302300361],
              [-76.27667229873516, -9.602098496374069],
              [-76.27283485764308, -9.598106731976728],
              [-76.2695084316196, -9.593995485163873],
              [-76.26550296415381, -9.589714865101268],
              [-76.26248180006755, -9.587338612529853],
              [-76.25896464936007, -9.586031170547471],
              [-76.25556950070589, -9.585220597673672],
              [-76.25036624759606, -9.5839824639452],
              [-76.24674233737036, -9.583127410544868],
              [-76.24277502660301, -9.581712896004108],
              [-76.23844147891658, -9.57783095856548],
              [-76.23522962621148, -9.574216247283797],
              [-76.23301691254844, -9.56942238442079],
              [-76.23246011659917, -9.56573489335915],
              [-76.23133855725946, -9.56238398552022],
              [-76.22886667199124, -9.558712306013993],
              [-76.22595202010793, -9.555883147389077],
              [-76.22305304756318, -9.55400340223433],
              [-76.21824639938202, -9.553097903053448],
              [-76.2144547550217, -9.551962719450186],
              [-76.21154027139644, -9.549133416927488],
              [-76.20929713832521, -9.546464523599308],
              [-76.20846565844155, -9.542940680311283],
              [-76.20706177059198, -9.539762447390963],
              [-76.204551740837, -9.537266250787502],
              [-76.20074471047083, -9.535172392779318],
              [-76.19633494042836, -9.534599772043617],
              [-76.19274128911334, -9.535642990086114],
              [-76.18939221724496, -9.537689107716668],
              [-76.18502815353465, -9.539973770554168],
              [-76.18172464405636, -9.541802637555186],
              [-76.17825330504807, -9.5433517123597],
              [-76.17477421363711, -9.5448917392245],
              [-76.1707304644332, -9.545827752630096],
              [-76.1669616870055, -9.546590981957179],
              [-76.16370383210989, -9.54724394755844],
              [-76.15970622020812, -9.547953608770555],
              [-76.1552124710291, -9.548782433452514],
              [-76.15042110552764, -9.548825438939092],
              [-76.14437873309964, -9.548094789294698],
              [-76.14075477295538, -9.54723855125091],
              [-76.13735958830884, -9.546426741320005],
              [-76.13295736673949, -9.545853297590192],
              [-76.12929546662666, -9.54617266635618],
              [-76.12553394196328, -9.546935434133712],
              [-76.12210846840152, -9.548257755678794],
              [-76.1192626026832, -9.550184074528842],
              [-76.11666114065451, -9.554081001523729],
              [-76.11540992719632, -9.557340562010353],
              [-76.11501313017473, -9.561040495321572],
              [-76.11691276155108, -9.564100029946031],
              [-76.12175759439916, -9.567864458123337],
              [-76.12609868067969, -9.57174800144323],
              [-76.1271591070978, -9.575325641630348],
              [-76.12427508045144, -9.578427753136394],
              [-76.12086497225016, -9.5807086200294],
              [-76.11740114313716, -9.583206667115144],
              [-76.11891942195894, -9.58688233468964],
              [-76.12311558900258, -9.588360880701977],
              [-76.12651058482392, -9.589163742689344],
              [-76.1313630405222, -9.589844253062818],
              [-76.13536065993574, -9.589134857627196],
              [-76.13968667850739, -9.588035485030638],
              [-76.14497385087094, -9.58691480137092],
              [-76.15048214193243, -9.585856608437554],
              [-76.15390014459254, -9.584534004372363],
              [-76.15834814534813, -9.58393137289929],
              [-76.1618501111511, -9.58429041854433],
              [-76.1650848470299, -9.585771660240848],
              [-76.16501613362857, -9.589081634987442],
              [-76.16252902027065, -9.592517293415755],
              [-76.1585082368253, -9.595361233593247],
              [-76.15532675411768, -9.597686881053452],
              [-76.15311427628659, -9.600958796826461],
              [-76.15436549184398, -9.605765523173128],
              [-76.1572038051342, -9.610946738766943],
              [-76.15943908456276, -9.613615984199301],
              [-76.16262049090587, -9.615323469946166],
              [-76.16751856713981, -9.61482767893903],
              [-76.1730271548894, -9.613760041020512],
              [-76.17881788904108, -9.612528583862765],
              [-76.18523408712021, -9.611683737280801],
              [-76.19064331645866, -9.611068317470554],
              [-76.19396974226753, -9.611147275274572],
              [-76.19812022024894, -9.609767438962596],
              [-76.20290384267746, -9.608765156280585],
              [-76.20735934297822, -9.609111344471312],
              [-76.21070110022457, -9.610148632918385],
              [-76.2148513076949, -9.612792673740673],
              [-76.21786505761641, -9.614219880355245],
              [-76.22212230323873, -9.612387245572485],
              [-76.22667700618658, -9.611331190208668],
              [-76.22993472670167, -9.610686620159852],
              [-76.23273477164581, -9.613009688352541],
              [-76.23029331596209, -9.6162285795884],
              [-76.22814192370647, -9.619274614237838],
              [-76.22711937219012, -9.622587914515123],
              [-76.22869118338069, -9.626045717287349],
              [-76.23217770014351, -9.629487634481954],
              [-76.23662560049952, -9.632917019607056],
              [-76.24081412065928, -9.63438499168384],
              [-76.24452973269703, -9.633847126605875],
              [-76.24822984638458, -9.632350749575174],
              [-76.25154865579262, -9.631479667649227],
              [-76.2567213976187, -9.63080979092061],
              [-76.26325989237968, -9.630469421261813],
              [-76.26766978592538, -9.631041022140995],
              [-76.2709580156576, -9.632294897584844],
              [-76.27196492487643, -9.636098142913264],
              [-76.27170573744193, -9.641253409185678],
              [-76.27026360168077, -9.64731730351668],
              [-76.26898946773734, -9.652702378125163],
              [-76.26814263655405, -9.656304474478045],
              [-76.2671279639562, -9.660567302226916],
              [-76.26567845462336, -9.665681739578],
              [-76.26342786775547, -9.670129963673656],
              [-76.26144416697191, -9.673455908792677],
              [-76.25885021166737, -9.67734472953411],
              [-76.25604254484968, -9.682138587125662],
              [-76.25279245861617, -9.687765973130901],
              [-76.25126636481951, -9.691198722305769],
              [-76.24978640004554, -9.694405232327437],
              [-76.24749767588236, -9.700038126725266],
              [-76.24603283111557, -9.704194056693154],
              [-76.2441711372906, -9.708025888643816],
              [-76.24111949276313, -9.711807775289886],
              [-76.23739612818602, -9.715429303047067],
              [-76.23348246755005, -9.717821673485767],
              [-76.22944641396077, -9.71970805162169],
              [-76.22471610896258, -9.720484614717464],
              [-76.22145848086731, -9.72112925911887],
              [-76.21917732899684, -9.72367842022131],
              [-76.21979516444391, -9.727139599534626],
              [-76.21701046997083, -9.72979906227347],
              [-76.21457669196124, -9.733017912462735],
              [-76.21118153774755, -9.73624920438377],
              [-76.20884715508434, -9.739015531785588],
              [-76.20552056821178, -9.742969961980725],
              [-76.20323940693405, -9.74551000238239],
              [-76.20046240643953, -9.748169333488942],
              [-76.19739541520042, -9.751001479012704],
              [-76.19398510753355, -9.753274148944149],
              [-76.1898879788342, -9.75443691720982],
              [-76.18633261859793, -9.754304624172486],
              [-76.1821517431665, -9.753785619805772],
              [-76.17885594462142, -9.752531028977533],
              [-76.17326348730376, -9.751908274621911],
              [-76.1688689792053, -9.752285085725218],
              [-76.16527554693658, -9.753328281018872],
              [-76.16146843420749, -9.755276463284666],
              [-76.15764628478135, -9.756266105831587],
              [-76.15391528709141, -9.75585373983529],
              [-76.14994808166556, -9.75442931554437],
              [-76.14698803145454, -9.752784422385439],
              [-76.1429442226883, -9.749678168584563],
              [-76.14030469915762, -9.746684725753113],
              [-76.13664236669428, -9.742962180542495],
              [-76.13359067103175, -9.738676890955409],
              [-76.13065350202888, -9.733948076130893],
              [-76.12913508275807, -9.730263534583969],
              [-76.12796011445229, -9.727138518152966],
              [-76.12651058398211, -9.724186217716413],
              [-76.1237563295847, -9.720677527235056],
              [-76.12050638784903, -9.718246558353218],
              [-76.11700444021061, -9.717887244446205],
              [-76.11396797050267, -9.722617644510507],
              [-76.1115722303249, -9.728693364841776],
              [-76.10968770623272, -9.731566155059575],
              [-76.10764302218958, -9.73416816397009],
              [-76.10525519088965, -9.737160128635175],
              [-76.10297379665509, -9.739699580588061],
              [-76.09907542252508, -9.739006790006428],
              [-76.0954130941284, -9.739326028139695],
              [-76.09228525379302, -9.741425019090183],
              [-76.09080495304285, -9.744631030747312],
              [-76.08850097375183, -9.749304659927073],
              [-76.08634179442308, -9.752350048137233],
              [-76.0834656788409, -9.756410541205257],
              [-76.08069603819361, -9.760027562973196],
              [-76.07841487330582, -9.76256688268283],
              [-76.07489784645212, -9.765290957716836],
              [-76.0706863195082, -9.766895830149945],
              [-76.06636034731385, -9.768003636351587],
              [-76.06114967015219, -9.77079618979056],
              [-76.0573959045019, -9.772517045301312],
              [-76.05245219836509, -9.77418728695396],
              [-76.04755404407656, -9.774681697402912],
              [-76.0441512248927, -9.77386922117651],
              [-76.0396653069453, -9.771613095073093],
              [-76.03607174583198, -9.76862168136411],
              [-76.03360753797467, -9.7658980954397],
              [-76.0313034132313, -9.762504797486546],
              [-76.02849570695115, -9.75922151899341],
              [-76.02542115863652, -9.757069430512969],
              [-76.0205460197395, -9.75542913326943],
              [-76.01544943156424, -9.75374420432285],
              [-76.01159671863009, -9.752824192017048],
              [-76.00730130282216, -9.7518059650282],
              [-76.00322711075424, -9.750832247466558],
              [-76.00032053155435, -9.748950715852],
              [-75.99685664228943, -9.74741443479147],
              [-75.99225621495498, -9.744660560651925],
              [-75.98884586129468, -9.742897948493479],
              [-75.9853286150804, -9.741587779399396],
              [-75.98232268305492, -9.740158535377155],
              [-75.97896588676348, -9.738169573995037],
              [-75.97521218640833, -9.735847100822259],
              [-75.97005461536692, -9.73438770215047],
              [-75.96622458803603, -9.73441664564362],
              [-75.96281432794113, -9.73668715212823],
              [-75.95867150257439, -9.739022991057865],
              [-75.9566115558084, -9.741624258824507],
              [-75.95605480659998, -9.745993837355261],
              [-75.95610043232672, -9.749810072561708],
              [-75.95739760157043, -9.75343288057877],
              [-75.95817552418055, -9.75718374971845],
              [-75.95829761348642, -9.761723244894732],
              [-75.95744321689084, -9.76531591820191],
              [-75.95505516780571, -9.768306981956515],
              [-75.95181281837183, -9.770857290716904],
              [-75.94834889358785, -9.773353929299526],
              [-75.94443502553047, -9.77574326419031],
              [-75.94061288841806, -9.776730556783198],
              [-75.93621063799003, -9.777104444540223],
              [-75.93124396421292, -9.776864859981913],
              [-75.92629248015474, -9.777583779559214],
              [-75.921897877706, -9.777957463578902],
              [-75.91818239118032, -9.778501087002073],
              [-75.91458135882579, -9.779541761044129],
              [-75.91114797738243, -9.77990367906139],
              [-75.90540308052971, -9.780904779603334],
              [-75.90202321541906, -9.781049417903004],
              [-75.8983842144569, -9.779232238334599],
              [-75.89860546204197, -9.783319377455305],
              [-75.89535511073093, -9.784919635838206],
              [-75.89194496169173, -9.787189515198811],
              [-75.88791656984064, -9.790030730925944],
              [-75.88417827783849, -9.792699319459272],
              [-75.87981427235214, -9.795930201291885],
              [-75.87493908646567, -9.798321289337915],
              [-75.87174229397444, -9.799695112118577],
              [-75.86769094119417, -9.800627951995901],
              [-75.86374666724565, -9.80110828744347],
              [-75.85947430156084, -9.801987332683964],
              [-75.85558328829924, -9.802250391171604],
              [-75.85224900992749, -9.802168345668596],
              [-75.8486937660728, -9.802032557607154],
              [-75.84451295539965, -9.801509408456083],
              [-75.84078205723323, -9.801093617284403],
              [-75.83718104367834, -9.80213351012664],
              [-75.83382414135001, -9.804176625232722],
              [-75.83132921961823, -9.807619383549639],
              [-75.82958215723473, -9.811946587720366],
              [-75.82846063234061, -9.816661138865726],
              [-75.82754515911869, -9.820479850246377],
              [-75.82777416324325, -9.824567076843207],
              [-75.82867438159855, -9.828815446765395],
              [-75.83042920373313, -9.833504858873841],
              [-75.83380899673664, -9.837403446467436],
              [-75.83811176751601, -9.838423795231797],
              [-75.84245292511969, -9.838277349644425],
              [-75.84618367429565, -9.83869308791721],
              [-75.84996799545759, -9.838882556608725],
              [-75.85358421740926, -9.839750685251824],
              [-75.85717012186177, -9.841785487843326],
              [-75.85979461910438, -9.843840783555393],
              [-75.8639754047982, -9.844363697096716],
              [-75.86837015369387, -9.843990684261541],
              [-75.87296296867557, -9.845796626197084],
              [-75.87516028341209, -9.849643538895588],
              [-75.87757099050128, -9.852585512707847],
              [-75.88153840500266, -9.849979652217899],
              [-75.88346090472285, -9.845923078807264],
              [-75.88442990444594, -9.841887081312999],
              [-75.8863219061646, -9.835931412844126],
              [-75.88739021291501, -9.831433924308945],
              [-75.88947290298209, -9.826707678577494],
              [-75.89144903751007, -9.822433885929586],
              [-75.89344020904913, -9.81910962645609],
              [-75.89706423246533, -9.815943824165025],
              [-75.90055096804798, -9.81535578353882],
              [-75.90363308871807, -9.817509000083811],
              [-75.90571606421027, -9.821808191154584],
              [-75.90657799181844, -9.827232041893458],
              [-75.90677643582889, -9.833444457857409],
              [-75.90598291439456, -9.837769420690742],
              [-75.90451809706187, -9.841924351474514],
              [-75.90416708625114, -9.845407073276588],
              [-75.90692149532788, -9.848908600718959],
              [-75.909782470426, -9.85196667769328],
              [-75.91284185261247, -9.857203687678108],
              [-75.91475689464879, -9.861222885247987],
              [-75.91470338093475, -9.865482545127678],
              [-75.91324607187636, -9.870596104840526],
              [-75.91156767879293, -9.87564695412133],
              [-75.90881349213436, -9.881161925156372],
              [-75.90656275070081, -9.885608383440522],
              [-75.90435034969943, -9.888879064013874],
              [-75.90150440834101, -9.891762558392879],
              [-75.89894866325317, -9.894473428782472],
              [-75.89436339097392, -9.897650911610157],
              [-75.89099887416818, -9.899703698747997],
              [-75.88635248276609, -9.902148714293745],
              [-75.88241589311667, -9.903587966463588],
              [-75.87705988948629, -9.903972780628672],
              [-75.87141420154252, -9.903571465710238],
              [-75.86667626597826, -9.904343388261088],
              [-75.8622435996159, -9.905892216640423],
              [-75.8584290052199, -9.907837331599463],
              [-75.8563766776898, -9.910429060319395],
              [-75.85726171870793, -9.913727738607177],
              [-75.85949707584777, -9.916398943795967],
              [-75.86151111588843, -9.919007393633873],
              [-75.86380010947332, -9.921452334696674],
              [-75.86792754613839, -9.926234803806562],
              [-75.8714295389251, -9.930629962404756],
              [-75.87338253217668, -9.933473616601566],
              [-75.87411510087402, -9.937441851781077],
              [-75.87367253024337, -9.94231751335789],
              [-75.87373365074308, -9.947083335610632],
              [-75.87423693286475, -9.950997901879983],
              [-75.87488548906533, -9.957326726554738],
              [-75.8750381274352, -9.960690545484944],
              [-75.87491610874957, -9.964217867746632],
              [-75.87487041574812, -9.969427097863207],
              [-75.87396234378397, -9.973245873230171],
              [-75.87294769592022, -9.977517105608046],
              [-75.87090304324701, -9.981058496719534],
              [-75.86846149119131, -9.984275342911195],
              [-75.86563122194718, -9.988108149599174],
              [-75.8633804078086, -9.992554400652752],
              [-75.86353297613456, -9.99591822645603],
              [-75.86679841057996, -9.999301090538408],
              [-75.87166590598257, -9.999993779278766],
              [-75.87554155403183, -9.99878081135947],
              [-75.87884531064877, -9.996954348055201],
              [-75.88204195065647, -9.999613745274091],
              [-75.88633740042988, -10.003726193543137],
              [-75.88834370705193, -10.00634347045652],
              [-75.89064782843482, -10.0097376787701],
              [-75.8931656690302, -10.012235982981789],
              [-75.89724723312226, -10.01321073585691],
              [-75.90105446857402, -10.015298531813299],
              [-75.90084085587175, -10.02023686594276],
              [-75.8999785709703, -10.023829475512148],
              [-75.89804852176476, -10.026927580964786],
              [-75.89576732700945, -10.029465927857979],
              [-75.89427946076492, -10.032671344675265],
              [-75.89445496712133, -10.03698462745885],
              [-75.89551554098944, -10.04056299500717],
              [-75.897209089525, -10.044528514160382],
              [-75.89832299334795, -10.047880634670415],
              [-75.89741506345024, -10.051699451499637],
              [-75.89430224067002, -10.055705099980484],
              [-75.8907318787518, -10.058644822361696],
              [-75.88741301024716, -10.059512871536118],
              [-75.88395701765961, -10.058934291240528],
              [-75.88045514162089, -10.058572840657215],
              [-75.87696067939562, -10.059160904522932],
              [-75.87418353886743, -10.062776626393566],
              [-75.87247475041458, -10.065928291765799],
              [-75.86743928266536, -10.064964831886218],
              [-75.86255659624905, -10.063322626697023],
              [-75.86008447826596, -10.059648326684641],
              [-75.85890198394347, -10.055572742012629],
              [-75.85736842505808, -10.050928312586962],
              [-75.8565063877021, -10.045504387169524],
              [-75.85508724119711, -10.040416505881],
              [-75.85299673148408, -10.036126177921957],
              [-75.84937289045507, -10.035258246649802],
              [-75.84562679371841, -10.03792664255297],
              [-75.84368900009088, -10.041024498002766],
              [-75.84323124273402, -10.044950595790445],
              [-75.84425357469836, -10.049704937376914],
              [-75.84458152719868, -10.0533396356049],
              [-75.84231560227865, -10.056836244485963],
              [-75.839431708049, -10.060895113761902],
              [-75.83863081530814, -10.064261372770261],
              [-75.83979809944898, -10.06738750413245],
              [-75.84103403547523, -10.071245987200381],
              [-75.84017954522199, -10.074838475366079],
              [-75.83642580584845, -10.077506822271364],
              [-75.83098589958391, -10.080242682708796],
              [-75.82627865323236, -10.082913322258179],
              [-75.82274629667329, -10.084685653310498],
              [-75.81882474800177, -10.087073883440413],
              [-75.81517791414214, -10.089289563026512],
              [-75.81207281605703, -10.093294518741823],
              [-75.80961611570136, -10.09556147445737],
              [-75.8072357067382, -10.099501306824019],
              [-75.80552664527384, -10.102652685658315],
              [-75.80445859630845, -10.107140956959057],
              [-75.80371868706578, -10.11123954721351],
              [-75.80320735297919, -10.115391840639992],
              [-75.80187221165764, -10.12100217287239],
              [-75.80101006123127, -10.124594610878095],
              [-75.799995554574, -10.128865693375063],
              [-75.79875933747215, -10.13403263604314],
              [-75.7981873548658, -10.137452533629405],
              [-75.7988586340465, -10.141647163950216],
              [-75.79969799165693, -10.145172150062507],
              [-75.79975886074153, -10.149928967919424],
              [-75.79940809682554, -10.153411631890144],
              [-75.79752344442349, -10.156283060457815],
              [-75.7965621767774, -10.160327914018422],
              [-75.79565424284588, -10.164146544535509],
              [-75.79644775441268, -10.168838275670543],
              [-75.79705797919019, -10.17230955485945],
              [-75.79856877444195, -10.175995683003537],
              [-75.79890444662499, -10.179630410124036],
              [-75.79833994107106, -10.18400891269537],
              [-75.79656991748492, -10.187386508818745],
              [-75.795661932063, -10.191205138922474],
              [-75.79598989815322, -10.19483988574403],
              [-75.79711919717624, -10.19914190566811],
              [-75.79767615515827, -10.202839393582817],
              [-75.79970549827308, -10.206406663733837],
              [-75.8054504088782, -10.20944023656134],
              [-75.810325709686, -10.211074102138411],
              [-75.81371300024527, -10.210939061033812],
              [-75.81736757454843, -10.209672866586375],
              [-75.82130440237796, -10.208234094939138],
              [-75.82482906121544, -10.205512132252892],
              [-75.82846063736154, -10.202346729332605],
              [-75.83179482967597, -10.198395258107347],
              [-75.83449555942407, -10.19310692924548],
              [-75.83586122758518, -10.189395578911125],
              [-75.8381195679857, -10.184949409592699],
              [-75.84072106788769, -10.181063047259899],
              [-75.84458168737741, -10.177942123186678],
              [-75.8477248645387, -10.176794510033728],
              [-75.85191337582725, -10.17731720332769],
              [-75.85571303255406, -10.1784648621002],
              [-75.85913835193325, -10.181177985872042],
              [-75.86193851590001, -10.183503845920365],
              [-75.86513529173504, -10.186163238931849],
              [-75.86795040130944, -10.189447610434879],
              [-75.87018593166856, -10.192118539204245],
              [-75.8723755900738, -10.195006615222054],
              [-75.87478626858841, -10.197957385365164],
              [-75.87898252782023, -10.1994292482764],
              [-75.88272088189689, -10.19984420821339],
              [-75.88814536508409, -10.200191239322711],
              [-75.8919296026248, -10.200379881543698],
              [-75.89543152186097, -10.199791519405206],
              [-75.901306166345, -10.20023657370194],
              [-75.90486915355636, -10.20037141538987],
              [-75.90872193359871, -10.20129218670438],
              [-75.91223136891361, -10.201653180367018],
              [-75.91455087644601, -10.20600559925547],
              [-75.9148255104559, -10.209866349823338],
              [-75.91352083965532, -10.213351704181163],
              [-75.91186534524363, -10.216277346997902],
              [-75.90670017486437, -10.214808634264768],
              [-75.90171045929246, -10.213628739443267],
              [-75.8982161112417, -10.214217159182736],
              [-75.89600367548238, -10.217487916726965],
              [-75.89390556477088, -10.221255736651136],
              [-75.8931044500098, -10.224631126853579],
              [-75.89427181787535, -10.227756945327231],
              [-75.89595043855763, -10.23076378667101],
              [-75.89814006377095, -10.23366068608448],
              [-75.89925394722485, -10.237012710672989],
              [-75.89953616067451, -10.240873459208997],
              [-75.90077221003054, -10.244731565587346],
              [-75.90262611762189, -10.248018216133017],
              [-75.90522017398825, -10.252198085560588],
              [-75.90712745909258, -10.255258468704477],
              [-75.9086989406641, -10.258717684515293],
              [-75.90881347080128, -10.263257129977108],
              [-75.90858457217836, -10.26723684755839],
              [-75.90778351694163, -10.270603217837795],
              [-75.90607442998332, -10.273755084251697],
              [-75.90441888440824, -10.276680710521784],
              [-75.90197741777708, -10.279897905737123],
              [-75.89895632077507, -10.282501745300625],
              [-75.89537045792288, -10.284501215300505],
              [-75.89205941045148, -10.286319024751668],
              [-75.88572683900098, -10.285757622543548],
              [-75.8822173314761, -10.285396418421593],
              [-75.87734976761296, -10.284713292798596],
              [-75.87496961268909, -10.288662661283434],
              [-75.87460328771847, -10.295220130743557],
              [-75.87417615374342, -10.301045245987137],
              [-75.87366478340847, -10.305197555662039],
              [-75.87299343652985, -10.310019509300659],
              [-75.87276467412408, -10.313999229200242],
              [-75.87184910393263, -10.317818021497247],
              [-75.87263484394192, -10.321568914991277],
              [-75.8729782562178, -10.3261529941521],
              [-75.87336726671597, -10.329570348502418],
              [-75.87313080961148, -10.333550085547548],
              [-75.87313081640335, -10.337583442081119],
              [-75.872009328169, -10.34229808874585],
              [-75.8710403756019, -10.346343108885854],
              [-75.86950686561852, -10.349774700596804],
              [-75.8668366074799, -10.35293804727837],
              [-75.8638154039811, -10.35554162597692],
              [-75.85992433083719, -10.355796216569628],
              [-75.85541533673096, -10.35258876972877],
              [-75.85316470002783, -10.348968323005398],
              [-75.85038754692405, -10.344508205357167],
              [-75.84932705205341, -10.340938839779502],
              [-75.84877027346484, -10.337241534174135],
              [-75.84831991998266, -10.3331008165009],
              [-75.8476944523212, -10.328680209036053],
              [-75.84651197769924, -10.324604710580166],
              [-75.84544359536825, -10.321026296636456],
              [-75.84353640170697, -10.317956502665355],
              [-75.84163669061095, -10.31489571801638],
              [-75.83889019416196, -10.312343553214587],
              [-75.83547202528976, -10.310579882231597],
              [-75.83196253856795, -10.31021815040068],
              [-75.82846840450472, -10.310814952846233],
              [-75.82561498342184, -10.312739479619566],
              [-75.8245314388076, -10.316278261833686],
              [-75.82662964024937, -10.321527180505809],
              [-75.82848361565715, -10.324814265221216],
              [-75.83177193180867, -10.330114341489793],
              [-75.83351901517953, -10.33384479433126],
              [-75.83509075590374, -10.337304383417809],
              [-75.83666224803433, -10.340763962154606],
              [-75.83755491577519, -10.344062512474862],
              [-75.83892047515423, -10.349376520744244],
              [-75.83995064944813, -10.354130698515513],
              [-75.84006489284064, -10.35866117510527],
              [-75.84021770942341, -10.362024943236692],
              [-75.8385619900114, -10.36495030149413],
              [-75.83708964192475, -10.369114130019101],
              [-75.83647159357793, -10.373709817357966],
              [-75.83705906724128, -10.379306188124199],
              [-75.83660136701967, -10.383232240639687],
              [-75.8338089823946, -10.38588923829946],
              [-75.83042895051875, -10.386983216311892],
              [-75.82606510514509, -10.386189607750108],
              [-75.82281480608592, -10.383747171765712],
              [-75.81983959083202, -10.381141213738351],
              [-75.81755077570486, -10.37869626078371],
              [-75.81553643477984, -10.376078765348538],
              [-75.81321709845639, -10.37173471571125],
              [-75.81169903890883, -10.36804879851499],
              [-75.8101807322196, -10.364362871339896],
              [-75.80741873188988, -10.360860927602925],
              [-75.80490111294549, -10.35836217641038],
              [-75.80204771802771, -10.356253111428977],
              [-75.7983398386285, -10.353712047647958],
              [-75.79504397794027, -10.352454105072962],
              [-75.79142002247936, -10.351585806688153],
              [-75.7879255897641, -10.352173164777799],
              [-75.78421018513703, -10.353665382293475],
              [-75.77850346428114, -10.35348931651931],
              [-75.77442921321585, -10.353472011488364],
              [-75.77204120911246, -10.356462136402705],
              [-75.77249894752175, -10.361552586256678],
              [-75.77204130076734, -10.365478607529415],
              [-75.77198779494852, -10.369738270912292],
              [-75.77153013739556, -10.373664290798846],
              [-75.77062980057308, -10.378432426512578],
              [-75.76972972705033, -10.383200559581528],
              [-75.76876078053547, -10.387245368093508],
              [-75.76722709621181, -10.39067656088167],
              [-75.76255026702685, -10.392170860903944],
              [-75.7589493552519, -10.393210371320215],
              [-75.75471491398324, -10.393871502175475],
              [-75.74974065033804, -10.393629945682376],
              [-75.74605546177504, -10.392987396085195],
              [-75.73925012427202, -10.391366250264594],
              [-75.7353973238314, -10.39044360765159],
              [-75.73131549682849, -10.389476220911375],
              [-75.72746273656136, -10.388553479734712],
              [-75.7238310886311, -10.387693497935432],
              [-75.72126011543267, -10.385411233224081],
              [-75.71730810430428, -10.381865915949644],
              [-75.71448530343737, -10.377630712373223],
              [-75.71346288277455, -10.372875994748957],
              [-75.7137527927388, -10.368670026320185],
              [-75.71515666350693, -10.363783303849369],
              [-75.71720120202741, -10.360233618304934],
              [-75.72097022002848, -10.358524979255138],
              [-75.72538753665846, -10.359102892810773],
              [-75.72901145214836, -10.35997194107685],
              [-75.73208618449463, -10.357143331603863],
              [-75.73122414255327, -10.351710036340533],
              [-75.7299881792321, -10.347860228504485],
              [-75.7275008616565, -10.34322643875432],
              [-75.72484592358357, -10.339271278520334],
              [-75.72180945260662, -10.335940961471403],
              [-75.71916965257836, -10.332935300789002],
              [-75.71617885572874, -10.32842009142966],
              [-75.71500389541045, -10.325293565911046],
              [-75.71417225831551, -10.321768350498372],
              [-75.71400444015651, -10.317463902891271],
              [-75.71327199651056, -10.313486275165214],
              [-75.71215826645279, -10.310133502244547],
              [-75.71046443549444, -10.30617606706843],
              [-75.70917519729909, -10.30254330781599],
              [-75.70681767103028, -10.299365063966262],
              [-75.70380397927435, -10.297933673932285],
              [-75.70017999964294, -10.29707333255693],
              [-75.69624342761865, -10.298501698580106],
              [-75.6930924380859, -10.299647979666014],
              [-75.68955237350988, -10.301419082493302],
              [-75.68544016391202, -10.302576383510546],
              [-75.68228908888725, -10.303722564241623],
              [-75.67842097495725, -10.305883127103925],
              [-75.67381291816571, -10.308108496273501],
              [-75.67073062374388, -10.30997793219774],
              [-75.66696159020003, -10.311695013555944],
              [-75.66387950305304, -10.313564387881048],
              [-75.66222375422534, -10.316497986636058],
              [-75.66351308570967, -10.320121916161405],
              [-75.66384123557552, -10.32376590338999],
              [-75.6652449926452, -10.327896040014314],
              [-75.66608439063609, -10.331421397333552],
              [-75.66646577188624, -10.334839171605621],
              [-75.66719054678303, -10.338807906234079],
              [-75.66730488227306, -10.343347661565623],
              [-75.66679386685519, -10.347490782979278],
              [-75.66661827857702, -10.351253369375248],
              [-75.66593170333411, -10.355125538144042],
              [-75.66704569546252, -10.358469437937094],
              [-75.66810597748216, -10.362048581996463],
              [-75.6709443216722, -10.367233810119949],
              [-75.67417911368906, -10.372752794363555],
              [-75.67636105317139, -10.37565126162033],
              [-75.67860420477606, -10.378323487572722],
              [-75.68089286460659, -10.380769504532788],
              [-75.68415818434053, -10.384163017411069],
              [-75.68753049169965, -10.3871040754771],
              [-75.69113163875568, -10.390098863297345],
              [-75.69364926110197, -10.392598545254787],
              [-75.69646467893432, -10.395875318569583],
              [-75.6998062951902, -10.400950614682746],
              [-75.7021102554749, -10.404346012719886],
              [-75.70453644729773, -10.408247569047628],
              [-75.70627602988586, -10.411987830702493],
              [-75.70934285805546, -10.417226394079934],
              [-75.71195218517073, -10.422357414068015],
              [-75.71408081620368, -10.425481783032609],
              [-75.71621717863343, -10.428597073514986],
              [-75.71949013748547, -10.43293972848809],
              [-75.72195432732858, -10.435665356825325],
              [-75.72464766301543, -10.438444705128656],
              [-75.72666170511948, -10.441053744135534],
              [-75.72958366579235, -10.443886785601618],
              [-75.73245253206105, -10.446946008993676],
              [-75.73556505009785, -10.45196710714586],
              [-75.7385787859215, -10.457431532678084],
              [-75.74090581298607, -10.462725689335361],
              [-75.74243930564674, -10.467370532951076],
              [-75.74327087525207, -10.470886554061568],
              [-75.74484245399468, -10.47434657923892],
              [-75.74674979258434, -10.47741693184383],
              [-75.74960313611463, -10.479517379916595],
              [-75.75414278063049, -10.480600927525956],
              [-75.75912468979682, -10.480833258617887],
              [-75.76319120878841, -10.480859706184184],
              [-75.76827996199957, -10.48064850327862],
              [-75.77301780778073, -10.479877372899054],
              [-75.77627559214794, -10.478277782261125],
              [-75.78168498919298, -10.476718102076543],
              [-75.78704064442223, -10.475384554140538],
              [-75.79076385932665, -10.474850805508071],
              [-75.79421221641344, -10.474480482799473],
              [-75.79800406823387, -10.47466995880877],
              [-75.80133821433756, -10.474752019844027],
              [-75.80481707550263, -10.477248286955962],
              [-75.8023985876403, -10.482363955434616],
              [-75.7990417146559, -10.485365794859911],
              [-75.79460140373205, -10.48691431066466],
              [-75.79138932480426, -10.48828788684602],
              [-75.78694921282816, -10.489836300641029],
              [-75.78311158532706, -10.49082247438651],
              [-75.77951050381314, -10.491862284595252],
              [-75.77625288707898, -10.49346191531531],
              [-75.77288068504645, -10.495504926252282],
              [-75.77021026009527, -10.498667601537415],
              [-75.76770032969753, -10.50116064815831],
              [-75.76484690227728, -10.504034333362352],
              [-75.76315301253027, -10.50814417554625],
              [-75.76326753660423, -10.512683755629725],
              [-75.76631919729826, -10.516972076171907],
              [-75.76927958522103, -10.518619874250666],
              [-75.7730332382793, -10.5199944040817],
              [-75.77666483203004, -10.520853706358372],
              [-75.78021227667945, -10.520040125738715],
              [-75.7847289273686, -10.51921506627738],
              [-75.78885646584715, -10.519014912387787],
              [-75.79371633709988, -10.518749568770797],
              [-75.79818739274445, -10.519100085450212],
              [-75.80352021476395, -10.520841047134738],
              [-75.80757893913831, -10.523941678788749],
              [-75.81055439396043, -10.526556679449145],
              [-75.81020362784643, -10.530030253982897],
              [-75.80774688540423, -10.53228834265078],
              [-75.80387106020888, -10.534459585591922],
              [-75.7995453525098, -10.536514356234573],
              [-75.79639448144538, -10.537661772675277],
              [-75.79282390310081, -10.537526039212947],
              [-75.78943622470538, -10.537661113302224],
              [-75.7841719665063, -10.53760184862588],
              [-75.77804566531668, -10.536133831140171],
              [-75.77600852941269, -10.536482483262738],
              [-75.77511593148384, -10.541006418329992],
              [-75.77305595828584, -10.546066772492155],
              [-75.77020274772556, -10.549275131781918],
              [-75.76728049993154, -10.551733025318896],
              [-75.76422873765678, -10.553693814681166],
              [-75.76115425478437, -10.554677931957894],
              [-75.75777439129448, -10.557046411707596],
              [-75.75475312710643, -10.559965657110052],
              [-75.75250996519978, -10.56258458768432],
              [-75.74973289770502, -10.564363723862945],
              [-75.74703984880504, -10.566884208817948],
              [-75.74414064876377, -10.5681481070777],
              [-75.74085989748421, -10.567893559833697],
              [-75.7370758937507, -10.567766763153264],
              [-75.73379514728582, -10.567512141816758],
              [-75.73059839543038, -10.567989820675079],
              [-75.72663892399558, -10.56758295383443],
              [-75.72348018580732, -10.56783438434198],
              [-75.72093949418432, -10.56965795670386],
              [-75.71821590930819, -10.571210618884141],
              [-75.71430213061281, -10.570568361460614],
              [-75.71180717069589, -10.567851873407216],
              [-75.70872500809865, -10.564512671657539],
              [-75.70451364872963, -10.563093202441088],
              [-75.70042426939273, -10.562170803572334],
              [-75.69725022718451, -10.561454334850259],
              [-75.69409950528812, -10.561705451395117],
              [-75.6900863687308, -10.561524326986762],
              [-75.68653104200328, -10.5614506805583],
              [-75.68264784070648, -10.561775623661584],
              [-75.67913826217246, -10.561466659927703],
              [-75.675056552753, -10.56271439140065],
              [-75.67198192586585, -10.56369766430581],
              [-75.66844950808218, -10.564591440830014],
              [-75.66468814361713, -10.565422355147868],
              [-75.66126237574991, -10.565854600692477],
              [-75.65753185109939, -10.565491598753875],
              [-75.65328977362161, -10.565265266464268],
              [-75.64950575095587, -10.565128372237814],
              [-75.64610288134541, -10.564366636239287],
              [-75.64292148156844, -10.563649630474766],
              [-75.6391143581506, -10.562554021691827],
              [-75.63536077801238, -10.56123216424253],
              [-75.63172906263841, -10.560407422682097],
              [-75.62854774721012, -10.559699311360303],
              [-75.62503820967773, -10.559389741915131],
              [-75.62173454977068, -10.560327767316114],
              [-75.61914060967935, -10.562385796037512],
              [-75.617301894728, -10.565328619012663],
              [-75.61608888613648, -10.568659094249016],
              [-75.61583698415194, -10.571969604137042],
              [-75.6174774337087, -10.576415929550626],
              [-75.62068177994627, -10.580262280816173],
              [-75.62436673705109, -10.58301334962308],
              [-75.6280517587701, -10.58576437113248],
              [-75.63201127075097, -10.588333920478673],
              [-75.63529210458773, -10.590751041850142],
              [-75.63849634486988, -10.59243574001142],
              [-75.64157100109662, -10.593605175868733],
              [-75.64431773957371, -10.595182218051479],
              [-75.64585890315922, -10.59792834694103],
              [-75.64510338830578, -10.60136650241711],
              [-75.64376836783515, -10.604181826983753],
              [-75.64119710260508, -10.607207673882037],
              [-75.63789376322688, -10.610307337277623],
              [-75.6356506453948, -10.612925510982574],
              [-75.63335424810903, -10.615769870535713],
              [-75.6313858085175, -10.618215633089065],
              [-75.62914284604928, -10.620824721322629],
              [-75.62638846290098, -10.623570471136368],
              [-75.62480154183716, -10.627534780875672],
              [-75.62334447511188, -10.63201431803964],
              [-75.62258148347655, -10.635443401861583],
              [-75.62203213687404, -10.640120093436328],
              [-75.62193298100411, -10.644904415529355],
              [-75.62175746728852, -10.648947303433827],
              [-75.62332155790781, -10.652661161091896],
              [-75.62602220606311, -10.65662596216064],
              [-75.62861629296911, -10.658890734439566],
              [-75.63121786526648, -10.661164511391673],
              [-75.63426967292513, -10.663527789147484],
              [-75.63719932672406, -10.66538483697245],
              [-75.64027417286526, -10.666563282750715],
              [-75.6443633805082, -10.667477343440417],
              [-75.64830007319743, -10.666926592716422],
              [-75.65165711899894, -10.665752988718706],
              [-75.65602106387794, -10.664333062858805],
              [-75.6599044585954, -10.664008372238017],
              [-75.6633377101616, -10.665737493072054],
              [-75.66719828142718, -10.66660651848046],
              [-75.67113507105033, -10.668216908211862],
              [-75.67569731088955, -10.670196683578247],
              [-75.67991636670396, -10.671625475172995],
              [-75.68309781925733, -10.672342031302163],
              [-75.68669878284817, -10.67218944881155],
              [-75.6905364431373, -10.672090564102454],
              [-75.69427485493055, -10.672453075110061],
              [-75.69896704281089, -10.674947692463046],
              [-75.70282734297464, -10.677977675432691],
              [-75.70655809662179, -10.678331007808385],
              [-75.71001436945134, -10.674542975268725],
              [-75.71203626723444, -10.67187957651922],
              [-75.71379859846965, -10.668194838493552],
              [-75.71538537604285, -10.664230143839262],
              [-75.71608732486781, -10.661027125110804],
              [-75.71684271032589, -10.657588850669448],
              [-75.71823126166596, -10.654537996102485],
              [-75.71961978697564, -10.651487136594275],
              [-75.72168718859233, -10.648588449930868],
              [-75.7242050691712, -10.645797233772303],
              [-75.7266768712497, -10.643232194900218],
              [-75.72957624693666, -10.641959369057084],
              [-75.73291010391753, -10.641987770077886],
              [-75.73608390466927, -10.642703818462802],
              [-75.73845679092983, -10.64491389990087],
              [-75.73989873276966, -10.648120945047443],
              [-75.74340827480462, -10.650590559534816],
              [-75.74761975449017, -10.649857061759839],
              [-75.75082385842934, -10.649370097522514],
              [-75.75422657282674, -10.647978275627015],
              [-75.75700376040048, -10.646199060466095],
              [-75.7599792103315, -10.643505951883284],
              [-75.76274860859738, -10.641726709111289],
              [-75.76643384540695, -10.640153206994004],
              [-75.76958468545902, -10.639901319292836],
              [-75.77435299582882, -10.640965754191368],
              [-75.77743517179795, -10.642142819717753],
              [-75.78041079045873, -10.643763246405705],
              [-75.78298946171053, -10.647229491968046],
              [-75.78258509367757, -10.651218649986854],
              [-75.78182987314264, -10.654647991132023],
              [-75.78006731088688, -10.65833305738687],
              [-75.77767175335515, -10.661639865043139],
              [-75.77525340998419, -10.663979067031452],
              [-75.77270517995409, -10.665812141124348],
              [-75.76852428011465, -10.66967494048797],
              [-75.76461025792177, -10.67335616890991],
              [-75.76103202139318, -10.676638624911785],
              [-75.7580031236484, -10.679557974590631],
              [-75.75510402015662, -10.682992461576449],
              [-75.75212873615243, -10.68568554495158],
              [-75.7498398306514, -10.688530694737889],
              [-75.74731446582769, -10.691331178714544],
              [-75.74423983839252, -10.694476606625225],
              [-75.74199682216982, -10.697095510152801],
              [-75.7396239540031, -10.699208272789017],
              [-75.7372055932583, -10.701547213707201],
              [-75.73405470068926, -10.703960210654268],
              [-75.73168170156646, -10.706063875729543],
              [-75.72835545018218, -10.70819688338703],
              [-75.7256241521765, -10.709749690070378],
              [-75.72166452836188, -10.711504277847911],
              [-75.71839133337913, -10.713410976024253],
              [-75.7160187205277, -10.715514531159533],
              [-75.7162705323879, -10.718697267886272],
              [-75.71922290418065, -10.721521081291183],
              [-75.72225195762516, -10.722915812661812],
              [-75.72614282879995, -10.722590241109545],
              [-75.72566220637087, -10.725847017378502],
              [-75.72394549416232, -10.729305627594174],
              [-75.72152706732078, -10.731644465337224],
              [-75.71859733212595, -10.734102033303266],
              [-75.71587375702299, -10.73565475177163],
              [-75.71438592452964, -10.739167051448552],
              [-75.71514899946362, -10.742212956272086],
              [-75.71626295847585, -10.745827789237381],
              [-75.71724712114482, -10.748927436532993],
              [-75.71931449545687, -10.752512920056517],
              [-75.72085578371332, -10.75526760782174],
              [-75.72338105745878, -10.758951471877879],
              [-75.72595987081479, -10.762409098772897],
              [-75.72750098763123, -10.765163745281145],
              [-75.72901925895673, -10.769103128432333],
              [-75.73027789641047, -10.772030245790862],
              [-75.73179624328571, -10.775969609002816],
              [-75.73296342737353, -10.779349098838757],
              [-75.73293296128428, -10.78270428798801],
              [-75.73230739270875, -10.786648708108846],
              [-75.73072059506967, -10.790613484722797],
              [-75.72756962774199, -10.793026464405337],
              [-75.72367845444508, -10.793361153141849],
              [-75.71913148448432, -10.79017941161607],
              [-75.71668231122308, -10.7872278780776],
              [-75.71324907341479, -10.785490415531898],
              [-75.71022019570924, -10.78625702984087],
              [-75.70613853473579, -10.787496279711165],
              [-75.7024003030242, -10.789304438782974],
              [-75.69927207016667, -10.790523350173155],
              [-75.69586179836254, -10.791914692231142],
              [-75.69273379445762, -10.793133540013013],
              [-75.68930040836761, -10.79356625226063],
              [-75.6853178529415, -10.794352841130127],
              [-75.68092348040726, -10.794805675957724],
              [-75.67597963371996, -10.79561234810817],
              [-75.67206582764912, -10.79714019020462],
              [-75.66843426077612, -10.79847746055918],
              [-75.66406249507065, -10.799897672266528],
              [-75.66098779335903, -10.800890007215493],
              [-75.65768427240897, -10.8018285380641],
              [-75.65460209456364, -10.802820827591331],
              [-75.65157312789752, -10.803577842386495],
              [-75.64761354135594, -10.805331573097083],
              [-75.64425666417168, -10.80649616665472],
              [-75.64061743162232, -10.807842182313037],
              [-75.636482183084, -10.809315789194647],
              [-75.63249209758007, -10.810101731901769],
              [-75.62915805503859, -10.810072364249802],
              [-75.62572489742047, -10.810495341975086],
              [-75.62234502236277, -10.810701127889015],
              [-75.619064246072, -10.810445453712049],
              [-75.61583714126819, -10.81211593030948],
              [-75.61399075626676, -10.815067828860888],
              [-75.61318203714173, -10.818723061821476],
              [-75.61331165145504, -10.823561144718436],
              [-75.61412052621951, -10.828542572307764],
              [-75.61490630246416, -10.832556373780317],
              [-75.6155852850971, -10.837040652325603],
              [-75.61705012365945, -10.841206827995514],
              [-75.61811065673369, -10.845048235221418],
              [-75.6192245025927, -10.848663439178159],
              [-75.61937722204921, -10.852298659319306],
              [-75.62018572838245, -10.857280053167525],
              [-75.6201096505921, -10.86087051332528],
              [-75.61985771315834, -10.864171932339477],
              [-75.61930839737806, -10.868848575624288],
              [-75.61849965441317, -10.872512857821011],
              [-75.6176911800211, -10.876177137531291],
              [-75.61566916215523, -10.878840012151528],
              [-75.6130218677203, -10.881124292973723],
              [-75.60956572682414, -10.882749977220335],
              [-75.60613258198309, -10.883181823496695],
              [-75.60095218835696, -10.883942627024709],
              [-75.59661103605114, -10.884159097516557],
              [-75.59345257444207, -10.884418440944295],
              [-75.59030152204843, -10.884668694268838],
              [-75.58602898668957, -10.885626461848338],
              [-75.58295426760128, -10.886618073056345],
              [-75.57926931643082, -10.888189599820437],
              [-75.57618711122382, -10.889181159601652],
              [-75.57237243292894, -10.890237362176068],
              [-75.56866468362757, -10.890850181320817],
              [-75.56472003995061, -10.891400086892814],
              [-75.56143953943233, -10.891143841544856],
              [-75.55795286557682, -10.891801346481268],
              [-75.55487826271474, -10.892792683318582],
              [-75.5510634670923, -10.893857670361006],
              [-75.5480576398891, -10.89558135537848],
              [-75.54533396223474, -10.89712363701927],
              [-75.54212185354797, -10.899770089500707],
              [-75.53747547592856, -10.901369960660274],
              [-75.5329057109004, -10.90154072593859],
              [-75.52950295473894, -10.90076886438227],
              [-75.5258636549857, -10.899952151515691],
              [-75.52235421929986, -10.899641621284935],
              [-75.51745603663443, -10.900219682273441],
              [-75.51379394034323, -10.900596634557383],
              [-75.51053616598713, -10.901307488335577],
              [-75.50685118706461, -10.902878169506813],
              [-75.50424961419938, -10.904935388784235],
              [-75.50182348101981, -10.907272655776087],
              [-75.49869529689163, -10.91065107863237],
              [-75.49621568807056, -10.913214484765467],
              [-75.4934615222328, -10.915959189709056],
              [-75.49116501917439, -10.918802618592107],
              [-75.48861698000864, -10.92279498892545],
              [-75.48730481162544, -10.926577367141721],
              [-75.48654170553858, -10.9300152047337],
              [-75.48628998613994, -10.93331657113704],
              [-75.48754880702914, -10.936244737368545],
              [-75.4902802409184, -10.939016793782494],
              [-75.49343871846955, -10.940928979472499],
              [-75.49697111570131, -10.942207461800908],
              [-75.50093850717944, -10.94261699186905],
              [-75.50441734520372, -10.941960119061779],
              [-75.50929256992487, -10.940414598962311],
              [-75.51298513809547, -10.93884385993924],
              [-75.51580809193257, -10.936840449314444],
              [-75.51866151934858, -10.93363415033014],
              [-75.52050784054062, -10.930691810133672],
              [-75.5230561433464, -10.928860634713283],
              [-75.52788542043243, -10.927550044840276],
              [-75.53139488567659, -10.927851452421777],
              [-75.53469854181564, -10.93123707416453],
              [-75.53704828650416, -10.934651464700872],
              [-75.53894031390995, -10.937958117612995],
              [-75.54215242295784, -10.93964360037047],
              [-75.54581465730928, -10.939266289444815],
              [-75.5489655127447, -10.936855031867697],
              [-75.55136882450883, -10.933549771481438],
              [-75.55303182383996, -10.930318183132528],
              [-75.55555725160262, -10.92752816033027],
              [-75.55954742042834, -10.926743132342438],
              [-75.56280511004269, -10.928193181401886],
              [-75.56391894829919, -10.931808584873961],
              [-75.56732182841662, -10.932570997767668],
              [-75.57151789644115, -10.930872023617768],
              [-75.57447059273719, -10.929374292133772],
              [-75.57894134412074, -10.92750287507833],
              [-75.58184808003006, -10.9262312528267],
              [-75.5852507180456, -10.92484108876734],
              [-75.58846293299024, -10.924355615195246],
              [-75.59149157859675, -10.92359915533949],
              [-75.59679408966598, -10.923344680492006],
              [-75.60116576204945, -10.92408673913133],
              [-75.60358423184847, -10.92608060856808],
              [-75.60568239390281, -10.928473008890693],
              [-75.60780341742026, -10.931823965841621],
              [-75.60937500533834, -10.935537743552182],
              [-75.61232754105316, -10.938362476757645],
              [-75.61601267456926, -10.941113353074128],
              [-75.61939995890853, -10.943077996618571],
              [-75.62402346085632, -10.944832074851362],
              [-75.62705217124886, -10.946236661701063],
              [-75.63071432130087, -10.948019736934027],
              [-75.6332930149217, -10.951478066625201],
              [-75.63478081642675, -10.954459335885762],
              [-75.63407121121878, -10.957662215706769],
              [-75.63124834708897, -10.959666688132591],
              [-75.627632167709, -10.959818847975743],
              [-75.62480923722926, -10.961823264047501],
              [-75.62485501733838, -10.96591988724287],
              [-75.6258468366852, -10.969019780358831],
              [-75.62696074853193, -10.972634897758704],
              [-75.62696071021756, -10.976957698302948],
              [-75.62627418452466, -10.981128171052996],
              [-75.62526698707941, -10.98569721575812],
              [-75.62632738314143, -10.989538526334306],
              [-75.62648007135867, -10.993173701597504],
              [-75.6248702774325, -10.998340856109055],
              [-75.62246702259652, -11.0016376241385],
              [-75.62001788655019, -11.005178643646358],
              [-75.61716464866136, -11.00837681372011],
              [-75.61428846208761, -11.010607349895423],
              [-75.61016832791793, -11.013048364497793],
              [-75.60569770292577, -11.014929327348248],
              [-75.60137950350197, -11.016113573055959],
              [-75.59683232867039, -11.017252991703604],
              [-75.59322370516699, -11.017404764200718],
              [-75.58844751374389, -11.01632883737117],
              [-75.58428180178569, -11.014672871229665],
              [-75.58152768939426, -11.013095536716909],
              [-75.57769014884869, -11.013193299443317],
              [-75.57549273148175, -11.015584976168112],
              [-75.5747298866335, -11.019013935226914],
              [-75.57473741961473, -11.023336746976229],
              [-75.57453141371768, -11.02641195012088],
              [-75.57422646149571, -11.029948561965742],
              [-75.57268518614114, -11.033686470405472],
              [-75.57034299149404, -11.036756657038552],
              [-75.5672074623633, -11.040135795385112],
              [-75.56483472003859, -11.042247381998958],
              [-75.56228630803955, -11.044069883287127],
              [-75.55947885831598, -11.047050421032242],
              [-75.55813612181191, -11.049865452440896],
              [-75.557334826256, -11.053529577721346],
              [-75.55589306295359, -11.056806005138913],
              [-75.55508428603422, -11.060470138729242],
              [-75.55417620385626, -11.064586630980696],
              [-75.55432895313787, -11.068221866795588],
              [-75.55518336827681, -11.072977214909729],
              [-75.55652615504452, -11.076637386879735],
              [-75.55897521392596, -11.07959010619117],
              [-75.56365195294272, -11.081127856295454],
              [-75.5689315901157, -11.082067521640866],
              [-75.57224279185121, -11.081129776645243],
              [-75.57565294286542, -11.079739630912677],
              [-75.57931511998406, -11.079361839681594],
              [-75.58247366414759, -11.0812729879677],
              [-75.58449553368824, -11.085085443520832],
              [-75.58732593886677, -11.0874041293091],
              [-75.59199512956117, -11.086770999910746],
              [-75.59563451681319, -11.085425397788168],
              [-75.6000596408152, -11.085941151260243],
              [-75.60268402620765, -11.089173488279949],
              [-75.60246272169077, -11.093442455707022],
              [-75.60079201238976, -11.096674314251917],
              [-75.59816731916749, -11.099926147011246],
              [-75.59632110846776, -11.102868940423694],
              [-75.59839638283249, -11.10646413181878],
              [-75.6010969982141, -11.108267449097735],
              [-75.60390459396778, -11.10961835206854],
              [-75.60488903649147, -11.112718286056806],
              [-75.60208884154385, -11.115690159622515],
              [-75.59778604020873, -11.117851086045627],
              [-75.59442891365718, -11.119015303494617],
              [-75.59084331314277, -11.120134716142514],
              [-75.58616643855453, -11.120767867451308],
              [-75.58369453526014, -11.123331983986496],
              [-75.58273307241163, -11.127674726503702],
              [-75.58435057486179, -11.13116237396662],
              [-75.58683008124632, -11.13508241472269],
              [-75.59016413380913, -11.13727344858803],
              [-75.59471126084661, -11.138295435213546],
              [-75.5978699784678, -11.140206378191056],
              [-75.59829714659315, -11.14366012991239],
              [-75.59690863081806, -11.148871927992813],
              [-75.59494005523247, -11.15131757246562],
              [-75.59094991991022, -11.15425554222676],
              [-75.58584581339547, -11.155757728703229],
              [-75.58215330943848, -11.157329441204428],
              [-75.57823942623816, -11.15884727614633],
              [-75.57348626771672, -11.160900234385107],
              [-75.57022869230468, -11.161611841468938],
              [-75.56610879352422, -11.161890955946198],
              [-75.56201175681707, -11.160967185189822],
              [-75.55860136002246, -11.160204861654075],
              [-75.55532061451288, -11.157778251412035],
              [-75.55149837351708, -11.156681996497001],
              [-75.548240733467, -11.155231962246152],
              [-75.54467772325874, -11.15514801922326],
              [-75.54142012570662, -11.155859313838256],
              [-75.53911604683279, -11.158703136200561],
              [-75.53848279811415, -11.162647256882375],
              [-75.53888692034164, -11.167303951095368],
              [-75.54164889372984, -11.171042991207807],
              [-75.54483033017932, -11.171760702434792],
              [-75.5482405488823, -11.172523177949508],
              [-75.551933236664, -11.17311326653089],
              [-75.55559547444237, -11.172735710148745],
              [-75.55958562679135, -11.174111936016208],
              [-75.56375884232457, -11.177929505249105],
              [-75.56687150816506, -11.182228326006488],
              [-75.5679779253543, -11.185843620758424],
              [-75.5684890026946, -11.190038824275254],
              [-75.56934352694877, -11.194794066478849],
              [-75.56914516368509, -11.197869225197365],
              [-75.57109074339058, -11.200949335991911],
              [-75.57314308410267, -11.203568010071017],
              [-75.57564536549674, -11.206294322292072],
              [-75.57769005707408, -11.208912975212103],
              [-75.58067316549347, -11.212705435803583],
              [-75.58238985975392, -11.215731644144974],
              [-75.58423615546165, -11.219273062407275],
              [-75.58580774884513, -11.222986831265136],
              [-75.5874253019141, -11.226474412716792],
              [-75.58898924969887, -11.230188174249506],
              [-75.59066022504548, -11.233440498166495],
              [-75.59238431082578, -11.236466619193408],
              [-75.59387216391285, -11.239447982068718],
              [-75.59581743944571, -11.242527897732199],
              [-75.59731278594103, -11.245500180563303],
              [-75.59852597530431, -11.248653889465617],
              [-75.59880823744456, -11.252804240455522],
              [-75.5979995688741, -11.256459399096123],
              [-75.59661103065719, -11.259509812662268],
              [-75.5945587788025, -11.263375454726011],
              [-75.59109500178302, -11.265001133299892],
              [-75.58766166733201, -11.265423940235609],
              [-75.58366382709272, -11.264048178727615],
              [-75.57989488403857, -11.262726175429963],
              [-75.5765839617913, -11.261502712050875],
              [-75.57360090234069, -11.264194381877479],
              [-75.57130436049091, -11.26703844273185],
              [-75.57016744911537, -11.271110169629544],
              [-75.56980895746872, -11.274872923060853],
              [-75.56918337859162, -11.278808012948794],
              [-75.56801615176177, -11.281912143831489],
              [-75.56503309362358, -11.284603737481875],
              [-75.56124126267683, -11.286636664444785],
              [-75.557220497631, -11.288615714761416],
              [-75.5541914639154, -11.28937199653147],
              [-75.54956049429862, -11.287617143574067],
              [-75.54713439768044, -11.285623026837728],
              [-75.54440308219316, -11.282851711493537],
              [-75.5429152114629, -11.27987913972094],
              [-75.54084013551423, -11.27629267338391],
              [-75.53739939354467, -11.272392142599488],
              [-75.53345478427816, -11.270789516020319],
              [-75.5300445596296, -11.270017882391963],
              [-75.52784720043825, -11.272409276173697],
              [-75.5270384592622, -11.27607332440002],
              [-75.52569588782876, -11.278888229001831],
              [-75.52205662836626, -11.280233089363213],
              [-75.51963054440687, -11.282570568295544],
              [-75.51609031094594, -11.283453966621684],
              [-75.51251972173291, -11.283378765085907],
              [-75.50910939198675, -11.282606895693958],
              [-75.50566876979923, -11.280876425899152],
              [-75.50210560676095, -11.278630641248684],
              [-75.4998856281862, -11.275731418870231],
              [-75.497886519791, -11.272877024866492],
              [-75.4947737807293, -11.270738911399311],
              [-75.49113451485164, -11.269921990360368],
              [-75.48724351583368, -11.270244922960748],
              [-75.48405453470981, -11.269535697554334],
              [-75.48276507689123, -11.265640027457154],
              [-75.48335268675008, -11.26193121719672],
              [-75.48410793560193, -11.258493439053563],
              [-75.48160544791082, -11.255775393561365],
              [-75.47872927895361, -11.25368193202957],
              [-75.47592164746109, -11.252329903423208],
              [-75.4732436215589, -11.24933171142843],
              [-75.4707870253171, -11.246378360236767],
              [-75.4668959771621, -11.244539600036974],
              [-75.46348563599639, -11.243767181836024],
              [-75.46017450113078, -11.244703808726932],
              [-75.45658106218016, -11.245821704586687],
              [-75.453803978862, -11.247598496579254],
              [-75.45061486371718, -11.25120269440395],
              [-75.44831087068341, -11.254045865034668],
              [-75.44590762422789, -11.257350391259072],
              [-75.44216927666685, -11.259146607249798],
              [-75.44019321093532, -11.261591312315868],
              [-75.43974295605712, -11.265806296501808],
              [-75.44305414967766, -11.269192725090319],
              [-75.4467392295422, -11.271945499826238],
              [-75.45088952639544, -11.274806042558364],
              [-75.45379642995309, -11.277858342382867],
              [-75.45657346366896, -11.280404370731844],
              [-75.45819099077696, -11.283892694779167],
              [-75.45965587871487, -11.288059511545072],
              [-75.46079247734596, -11.292642832475716],
              [-75.46202859513343, -11.296764769862692],
              [-75.4629668230659, -11.300091332826886],
              [-75.46344763624913, -11.303328171878642],
              [-75.46268458482758, -11.306756869833261],
              [-75.4619292483204, -11.310185553680082],
              [-75.46035758841421, -11.312955327658331],
              [-75.4583358737207, -11.315626312416923],
              [-75.45666492807452, -11.318848399374232],
              [-75.45681761079057, -11.322492712687689],
              [-75.45724491045593, -11.325946685856406],
              [-75.4580765373594, -11.329734641742311],
              [-75.45997617262344, -11.333041625238078],
              [-75.46098330022485, -11.337118688652248],
              [-75.46022787817881, -11.340547365061557],
              [-75.45860285426278, -11.343543302347214],
              [-75.45590222316119, -11.34606158779444],
              [-75.45203400204107, -11.347351720155862],
              [-75.44760131548223, -11.346833951737283],
              [-75.44393907557415, -11.345048861753927],
              [-75.44049833880088, -11.343317655318799],
              [-75.437416188696, -11.342146576458967],
              [-75.43405137542268, -11.34114770775285],
              [-75.43033587032505, -11.34175002192518],
              [-75.42778003222665, -11.343580536606948],
              [-75.4249723928042, -11.346550882141775],
              [-75.42218788334547, -11.350488830970285],
              [-75.42092121960782, -11.35404476286671],
              [-75.42011263886972, -11.35770855995832],
              [-75.4203109382762, -11.36110865721768],
              [-75.42111962272091, -11.366099548111361],
              [-75.42195120009535, -11.369887608531291],
              [-75.42316423247942, -11.373042068281055],
              [-75.42285926072296, -11.376578534987834],
              [-75.42185207373927, -11.381156016366857],
              [-75.42093669552943, -11.385272141723561],
              [-75.42113504899253, -11.38868127459362],
              [-75.4211654317658, -11.39181029723641],
              [-75.42259975389975, -11.395009652293325],
              [-75.42421728757884, -11.398498127257565],
              [-75.42459857640668, -11.402187338375887],
              [-75.42434682136413, -11.405488591370103],
              [-75.42206572872749, -11.409299205801792],
              [-75.41993711971753, -11.412431320601604],
              [-75.41796872169547, -11.414866840748575],
              [-75.41629792378488, -11.418088713589169],
              [-75.4148560660959, -11.421373558444365],
              [-75.41345967046635, -11.424423200677968],
              [-75.41265110052689, -11.428077926219984],
              [-75.41386401530872, -11.431232407261364],
              [-75.41636663135964, -11.43396000606635],
              [-75.41965494269212, -11.436378977175075],
              [-75.42271434341912, -11.438753022022647],
              [-75.4259032492249, -11.443785635419955],
              [-75.42700963981436, -11.447401425077235],
              [-75.42506400510673, -11.450804614859884],
              [-75.42210387391822, -11.452301078962781],
              [-75.41870891173653, -11.452504915167989],
              [-75.41737366974488, -11.455319354591344],
              [-75.41661059937934, -11.458756975547763],
              [-75.41590131764843, -11.461959386655314],
              [-75.41486370338662, -11.465569219898006],
              [-75.41227717153154, -11.468584368220089],
              [-75.41136945443711, -11.47270948607866],
              [-75.41060625864905, -11.476138047931492],
              [-75.40946947304465, -11.480200177032833],
              [-75.40812686754751, -11.48302363008313],
              [-75.40364825190527, -11.484901865426318],
              [-75.40045931233018, -11.484182779469645],
              [-75.39696499534965, -11.482677288164291],
              [-75.39453878743679, -11.480691005288687],
              [-75.39203652055588, -11.477963234416556],
              [-75.38851922334244, -11.475490011193399],
              [-75.385589608774, -11.473630927914732],
              [-75.38282773512097, -11.47205194352651],
              [-75.38002006502076, -11.470699079908474],
              [-75.37587727968257, -11.47000808783942],
              [-75.37220751233944, -11.470383572324272],
              [-75.36669157111936, -11.471539079755022],
              [-75.3627167694782, -11.471128039649294],
              [-75.35955823713002, -11.471376120989548],
              [-75.35558315856456, -11.470964983768727],
              [-75.35239419479751, -11.470245363627667],
              [-75.34872438419245, -11.47062055410063],
              [-75.34548935894163, -11.472297458557513],
              [-75.34363541835513, -11.475238805557295],
              [-75.3425979808622, -11.478848408018397],
              [-75.34358222147128, -11.481949193702901],
              [-75.34452063845518, -11.485285161511088],
              [-75.34198764891042, -11.490234969740403],
              [-75.33947751059931, -11.494000023859199],
              [-75.33815763110097, -11.497781765694642],
              [-75.33894356190993, -11.501805235308925],
              [-75.3418961275602, -11.504632417058907],
              [-75.3446045115582, -11.506437934965149],
              [-75.34777068986912, -11.508351402638036],
              [-75.35211956086575, -11.510290537048807],
              [-75.35520181059529, -11.511471495993739],
              [-75.35762788893362, -11.515619506539739],
              [-75.35899349604011, -11.520248111114055],
              [-75.36053460198121, -11.525156838161866],
              [-75.3619231377206, -11.528591650500843],
              [-75.36346434399783, -11.533500356316422],
              [-75.36513530744799, -11.5367629689453],
              [-75.36759172819437, -11.539717097836936],
              [-75.37004103593905, -11.542671212914062],
              [-75.37246705099773, -11.544657680830388],
              [-75.37532815911047, -11.547945828047286],
              [-75.37715146011462, -11.55051181592215],
              [-75.37975308122174, -11.552778338110613],
              [-75.38324733198101, -11.554284004917184],
              [-75.38655866979805, -11.555509524354749],
              [-75.39060965464702, -11.556661662016928],
              [-75.39425666619945, -11.55747968309867],
              [-75.39723963800375, -11.559112479844899],
              [-75.40050499268497, -11.560573028944058],
              [-75.40303038989887, -11.564259259989571],
              [-75.40295412719207, -11.567849617582059],
              [-75.40047455218281, -11.570412352974156],
              [-75.39611818837628, -11.5727967717255],
              [-75.39302837536773, -11.573777674490112],
              [-75.38935844239788, -11.574153413370189],
              [-75.38457500879365, -11.573083631839593],
              [-75.37934118632081, -11.571905838581698],
              [-75.37463389181879, -11.571568312541576],
              [-75.37142181559429, -11.572042709868967],
              [-75.36810313493751, -11.572978425105418],
              [-75.36437986359456, -11.573580003150854],
              [-75.36099255736805, -11.573783201607977],
              [-75.35709377647376, -11.574104557085926],
              [-75.35400394886227, -11.572923652087495],
              [-75.35015866464194, -11.570857364250335],
              [-75.34732832709363, -11.568545633969796],
              [-75.34448999951931, -11.566224840379393],
              [-75.34163678816553, -11.562936378812346],
              [-75.33930972995171, -11.560497362160831],
              [-75.33634942445072, -11.557670189003378],
              [-75.33308415396472, -11.554047503399609],
              [-75.3302842422486, -11.550532771013188],
              [-75.32767500174407, -11.548265801765732],
              [-75.32476798740949, -11.545212352923727],
              [-75.32183087188038, -11.543352653908814],
              [-75.3188247492399, -11.540751428810644],
              [-75.31505577083655, -11.539417096444708],
              [-75.31082913635808, -11.540154178416051],
              [-75.30821998125835, -11.54220080282603],
              [-75.30545792833853, -11.54494391916507],
              [-75.30342856834406, -11.547613896529425],
              [-75.30175772117401, -11.550835144435121],
              [-75.30054465544421, -11.554164431667845],
              [-75.2995756890684, -11.558515383134216],
              [-75.29744718980801, -11.563799008585875],
              [-75.29549405595434, -11.567201381342965],
              [-75.29376994074015, -11.570657770401164],
              [-75.29164129966524, -11.573779945697021],
              [-75.28928383828875, -11.57685711672333],
              [-75.28690325965803, -11.578957588752559],
              [-75.28252414552175, -11.580372689389565],
              [-75.27908329700912, -11.58080107417656],
              [-75.27516185245462, -11.580153692352383],
              [-75.27040850658061, -11.577888237849656],
              [-75.26754770744505, -11.574599038148508],
              [-75.26548005916736, -11.571010644472029],
              [-75.26367942150237, -11.567241119756748],
              [-75.26247412642029, -11.564086006541485],
              [-75.26204672735834, -11.560631746980073],
              [-75.26148988899457, -11.556662118403409],
              [-75.2610091214872, -11.553424949153234],
              [-75.25901805953677, -11.550578004346683],
              [-75.25747679883544, -11.547830125303273],
              [-75.25621024793654, -11.544901122805738],
              [-75.25431832733365, -11.541592831059033],
              [-75.25249474099493, -11.53901699735049],
              [-75.24964143725626, -11.535736636850563],
              [-75.24660498866929, -11.534328427893605],
              [-75.24288952534407, -11.53276701347593],
              [-75.23853309312922, -11.532987668597915],
              [-75.23574842144835, -11.534762488753657],
              [-75.23402404211697, -11.538218541911743],
              [-75.23220054600053, -11.542126844478325],
              [-75.23148353979117, -11.545328853963344],
              [-75.23077396954518, -11.54853085536098],
              [-75.22991189895116, -11.552420289333659],
              [-75.22937763830753, -11.555902499039549],
              [-75.23033153823845, -11.560206496338367],
              [-75.23214710163566, -11.564943892362397],
              [-75.23333728846022, -11.56929290429173],
              [-75.23421472599752, -11.57285537031671],
              [-75.2341920072613, -11.576210559338415],
              [-75.23347465813787, -11.579412567710534],
              [-75.23134613431478, -11.58253431309296],
              [-75.22828677053926, -11.584490156627107],
              [-75.22402947899973, -11.584249306988005],
              [-75.22018437708485, -11.58218127619083],
              [-75.21614088426047, -11.578865325260264],
              [-75.21320353135668, -11.57700452241461],
              [-75.21012129768683, -11.57798348412814],
              [-75.20753469822067, -11.581005911662405],
              [-75.20590968893474, -11.58400954657331],
              [-75.2058791816921, -11.587364742247697],
              [-75.20678713626991, -11.591885891043278],
              [-75.20597088248957, -11.595549127701661],
              [-75.20412444229432, -11.59849864738866],
              [-75.1994398819968, -11.599116881798901],
              [-75.19546518966175, -11.598703591823314],
              [-75.19154367076416, -11.598064121889543],
              [-75.18766787559346, -11.597189435239446],
              [-75.18243405528966, -11.596008073946301],
              [-75.17774954920193, -11.594464535843798],
              [-75.17443860191443, -11.593236621884435],
              [-75.17079169781199, -11.592415830486756],
              [-75.16761010042939, -11.591703246795813],
              [-75.16282654682072, -11.590620746028883],
              [-75.15748599664647, -11.589891170385238],
              [-75.15429675987741, -11.591339867804434],
              [-75.15094761993721, -11.593466885018929],
              [-75.14884179742134, -11.597555690705265],
              [-75.14746856225241, -11.601571758802976],
              [-75.14741505267659, -11.6061207222402],
              [-75.14642326030364, -11.609503536243931],
              [-75.14315047094192, -11.61236295712432],
              [-75.14006817400936, -11.613350222121422],
              [-75.13644421972757, -11.613496655336219],
              [-75.13052366042115, -11.61215189476441],
              [-75.12564080631805, -11.609368627760416],
              [-75.12288672934581, -11.60778717997827],
              [-75.11972032582067, -11.605871262750991],
              [-75.11734759131255, -11.603656549403688],
              [-75.11454010446225, -11.60230113269704],
              [-75.11031339312436, -11.600864840945745],
              [-75.10666656748688, -11.600043240022876],
              [-75.10347751792209, -11.60148233603961],
              [-75.10041044819437, -11.603436841299752],
              [-75.0975723908661, -11.60543645494545],
              [-75.09420011542683, -11.608756584618984],
              [-75.0912932091076, -11.610023625517975],
              [-75.08827977965103, -11.61174285100005],
              [-75.08467875144822, -11.612856295269228],
              [-75.08083344667158, -11.612947833722531],
              [-75.07733915004198, -11.611438505459152],
              [-75.07494350589968, -11.608246736709571],
              [-75.07537841254324, -11.603064623581076],
              [-75.07563780447013, -11.599754586287741],
              [-75.07640080047399, -11.596326848129959],
              [-75.0777741696656, -11.59231110730249],
              [-75.0802918434918, -11.588557309298393],
              [-75.08211517092846, -11.584649944797675],
              [-75.08361811087339, -11.581140581134363],
              [-75.08486188176151, -11.576618396813432],
              [-75.08618150606043, -11.57282871897426],
              [-75.08689879963386, -11.569627052913827],
              [-75.08811962559082, -11.566298620691112],
              [-75.08997339211086, -11.563358864103167],
              [-75.09262841748918, -11.561079027235415],
              [-75.09615312047188, -11.55922393071291],
              [-75.09946429521685, -11.558300361175124],
              [-75.10430149591126, -11.556987332046432],
              [-75.10812387256782, -11.555927824852768],
              [-75.11109167614644, -11.55443450063449],
              [-75.11453247393118, -11.551846677749309],
              [-75.11648570297473, -11.54844548609156],
              [-75.1176833312366, -11.544149266109093],
              [-75.11753852030483, -11.540513773845783],
              [-75.115768528287, -11.537710949719218],
              [-75.11205286179646, -11.536156896558468],
              [-75.10795602876617, -11.535226950194444],
              [-75.10377491773023, -11.535725875340116],
              [-75.10048681394666, -11.535464764823658],
              [-75.09719842933681, -11.535203617135668],
              [-75.09345233661898, -11.53483405571199],
              [-75.08956134695961, -11.5329903721103],
              [-75.08677668546447, -11.53044090102012],
              [-75.08584600422685, -11.527113104741892],
              [-75.08514404550274, -11.523830456095226],
              [-75.08517455848963, -11.520475241911688],
              [-75.08543405260124, -11.51716517823173],
              [-75.0884552742088, -11.513284535271609],
              [-75.09118659242642, -11.511737222363475],
              [-75.09576408803488, -11.511572966208691],
              [-75.1005477725954, -11.512656595164513],
              [-75.1061859013474, -11.514164887616136],
              [-75.11093890037452, -11.51644212151855],
              [-75.11470808770768, -11.51777912412079],
              [-75.11779774539453, -11.516792129145461],
              [-75.12071216957345, -11.515524820327903],
              [-75.12372580777928, -11.513805256571645],
              [-75.12752531189604, -11.511777833616472],
              [-75.13129422797348, -11.508791745570813],
              [-75.13486491151151, -11.50671006840618],
              [-75.13813771902636, -11.503850733020629],
              [-75.14034271685753, -11.501462148530651],
              [-75.14128103771372, -11.498314495268188],
              [-75.14131174541725, -11.494959275285462],
              [-75.1388626288652, -11.492003168107926],
              [-75.13577256397052, -11.49081990065798],
              [-75.13119501047665, -11.488832384842336],
              [-75.1265182896657, -11.487287980098364],
              [-75.12287890620328, -11.486466559981775],
              [-75.12242885519134, -11.48204438308054],
              [-75.12384791882124, -11.47780229097431],
              [-75.12597658008117, -11.474672260843308],
              [-75.12924968544897, -11.47181302176643],
              [-75.13284300936394, -11.470699034609396],
              [-75.13696276426079, -11.470434864680099],
              [-75.14058688918985, -11.47028844373577],
              [-75.1446609015333, -11.470250279748692],
              [-75.14792631187473, -11.46738179325622],
              [-75.14917000278534, -11.462859312896917],
              [-75.14981086030879, -11.458915932606038],
              [-75.14978801133101, -11.45578682389712],
              [-75.14930728035573, -11.452558467835278],
              [-75.14579783006492, -11.447920816569063],
              [-75.14282236616239, -11.444123925568988],
              [-75.13910664048561, -11.442570196191376],
              [-75.13568870272293, -11.441794040733077],
              [-75.13121041884439, -11.441506682931013],
              [-75.12666334220194, -11.440477701344546],
              [-75.12208551525978, -11.438480983353436],
              [-75.11917867146548, -11.43542540205646],
              [-75.11632536630353, -11.432134632517368],
              [-75.11499031537306, -11.428472449197661],
              [-75.11471563240727, -11.424321484048486],
              [-75.1150284731956, -11.420794305297584],
              [-75.11695090778105, -11.41642542070825],
              [-75.12009431654602, -11.413050836970422],
              [-75.12290954357992, -11.41008332535988],
              [-75.12619783727808, -11.408182745064359],
              [-75.1297912334659, -11.407068798737273],
              [-75.13384240813728, -11.406063125213834],
              [-75.1366730675074, -11.404063155407439],
              [-75.13941204863671, -11.400353935335797],
              [-75.14134228158201, -11.395984886552487],
              [-75.1426392319458, -11.391236295148966],
              [-75.14284508070241, -11.38816132196747],
              [-75.14234172427676, -11.383956223550381],
              [-75.14219670280227, -11.380320706297178],
              [-75.14217366797574, -11.377191579306558],
              [-75.14293671811708, -11.373763627187664],
              [-75.1438063538161, -11.369874388903677],
              [-75.14578255686445, -11.367440646877755],
              [-75.1479873085775, -11.365051995685818],
              [-75.15145121068176, -11.36343140310743],
              [-75.15454107954902, -11.36245307323871],
              [-75.15769945325711, -11.360045760947127],
              [-75.1588745424662, -11.354781662562774],
              [-75.15872188472937, -11.3511461527551],
              [-75.15953838357163, -11.347482988257358],
              [-75.16114055787648, -11.343520948731618],
              [-75.1628112691127, -11.34030045118178],
              [-75.16442879950735, -11.33729702402969],
              [-75.16738888256404, -11.335803139541852],
              [-75.17106627984239, -11.33326877786655],
              [-75.1735687177587, -11.329514154556735],
              [-75.17484294919718, -11.325959201939003],
              [-75.17608648911883, -11.32143657801994],
              [-75.17750541981235, -11.317194199898765],
              [-75.18019107820939, -11.313710722995586],
              [-75.18360913913662, -11.310163462033763],
              [-75.1865921246882, -11.307475595311516],
              [-75.1895598146432, -11.303820024530822],
              [-75.19209303695513, -11.301023865228226],
              [-75.19509883816477, -11.299303583282336],
              [-75.19811262090317, -11.297583265877915],
              [-75.20294179363027, -11.296268650546038],
              [-75.20683299851585, -11.29594942828574],
              [-75.21092983544342, -11.29686900543599],
              [-75.21492768539795, -11.2982588731828],
              [-75.2189178347015, -11.29963964810788],
              [-75.22257989219376, -11.301427584448538],
              [-75.22650136523863, -11.304228177085111],
              [-75.22937772831018, -11.306324114499107],
              [-75.23349761188099, -11.30821103847489],
              [-75.23777019082408, -11.30941950017933],
              [-75.24179081077922, -11.309606162185183],
              [-75.2463150300671, -11.309665738476557],
              [-75.2494810755626, -11.30941890616601],
              [-75.25296776416435, -11.308764798051204],
              [-75.25721729639149, -11.306834820546975],
              [-75.25959787854599, -11.304734596216694],
              [-75.26119242585503, -11.300772037462444],
              [-75.26210785960015, -11.296647284123585],
              [-75.26291660533983, -11.292992896580543],
              [-75.264816142845, -11.289816852588917],
              [-75.2690200074927, -11.288121889015617],
              [-75.27250667674063, -11.28746755648537],
              [-75.2748106912818, -11.28462569690697],
              [-75.27628331389157, -11.28014767834162],
              [-75.27704624290908, -11.276719389376511],
              [-75.27671809122094, -11.272803754711061],
              [-75.27578723216965, -11.269467478467718],
              [-75.27349096015168, -11.265824970569211],
              [-75.270553645062, -11.263964646618302],
              [-75.26565539186997, -11.262377364539269],
              [-75.26186365978596, -11.262245073202697],
              [-75.25867458744382, -11.263685824200579],
              [-75.25566095410922, -11.265406747266306],
              [-75.25199903858739, -11.2657806699641],
              [-75.24739843548326, -11.26498868471877],
              [-75.2440644671651, -11.262793805445009],
              [-75.24221794357483, -11.259250156601933],
              [-75.24073039228377, -11.256275962198737],
              [-75.23868573243372, -11.253654914219329],
              [-75.23565665089073, -11.252246499508665],
              [-75.2318878648671, -11.250920024833281],
              [-75.22870650260181, -11.250198984539354],
              [-75.22344203922202, -11.25021202877704],
              [-75.21892541866225, -11.247990613047804],
              [-75.21692664417758, -11.245134236732692],
              [-75.216102728888, -11.241345483706354],
              [-75.2150649137732, -11.236308836527677],
              [-75.21486657091559, -11.232899464678674],
              [-75.21558390814812, -11.229697438475062],
              [-75.2168503306618, -11.22614230262056],
              [-75.2190246418303, -11.222785459490998],
              [-75.2210006475347, -11.220351216459848],
              [-75.22297662028743, -11.217916960568324],
              [-75.22505182341588, -11.215021382544894],
              [-75.22641761507133, -11.211004896400095],
              [-75.22586834820665, -11.207035077485177],
              [-75.22511284985102, -11.203987881302401],
              [-75.22256470790064, -11.201484652515994],
              [-75.21952824201814, -11.200085106589034],
              [-75.21594246217914, -11.199029592069138],
              [-75.21182239051585, -11.199303856165919],
              [-75.20775595725543, -11.199342886669616],
              [-75.20408621204842, -11.199716212985795],
              [-75.20092761238938, -11.199962537373182],
              [-75.19594560339237, -11.19980305722533],
              [-75.1922835634703, -11.20017623385264],
              [-75.18932355741666, -11.201670357814399],
              [-75.18508143196921, -11.201428832477799],
              [-75.1819153517164, -11.20167496101103],
              [-75.17754353594833, -11.200926952147988],
              [-75.17357641359563, -11.200513266459556],
              [-75.16812146714572, -11.199277378457246],
              [-75.1649399601447, -11.198555643496208],
              [-75.16149913803402, -11.196821102950583],
              [-75.15677648889, -11.19551225844821],
              [-75.15296167427483, -11.194410884259991],
              [-75.14914717414214, -11.193309460783087],
              [-75.14419561353593, -11.191946266701466],
              [-75.13928978895535, -11.190356871951108],
              [-75.13429263259366, -11.189228674323143],
              [-75.12973779841488, -11.188199683715503],
              [-75.12564846267581, -11.187269910744314],
              [-75.12200916389862, -11.186448424809718],
              [-75.11837017141222, -11.185626894222715],
              [-75.11314387710824, -11.18444416849241],
              [-75.10833744243229, -11.184563518116224],
              [-75.10416400765811, -11.185062413848865],
              [-75.1010818611712, -11.186040210438225],
              [-75.09835052852891, -11.187587624777327],
              [-75.09589375087505, -11.191115536191996],
              [-75.09409330028427, -11.193829272103049],
              [-75.09186543517428, -11.197411340862375],
              [-75.0894545139374, -11.200713087294673],
              [-75.08705126437178, -11.2040057678364],
              [-75.08464824947143, -11.207307472512925],
              [-75.08256539205053, -11.210202094905148],
              [-75.07975769111457, -11.213169248042385],
              [-75.07755265820485, -11.215557410886012],
              [-75.07517231739592, -11.217656197720826],
              [-75.07209001559481, -11.218642740178334],
              [-75.06882469907676, -11.219348933204639],
              [-75.0650864911951, -11.218978973672046],
              [-75.06164560973757, -11.219404766504068],
              [-75.0578002935067, -11.221657459994333],
              [-75.05567159122891, -11.224777999581551],
              [-75.05407719716223, -11.228739506568422],
              [-75.05399312403236, -11.23232089205312],
              [-75.05423734615295, -11.235504287179669],
              [-75.05468736349428, -11.239926652342348],
              [-75.05427556567314, -11.243924112616497],
              [-75.05330668188901, -11.24826533806012],
              [-75.05087278014068, -11.252760558854067],
              [-75.04887384687042, -11.256396521858898],
              [-75.04692063525358, -11.259797323495766],
              [-75.04433427757007, -11.262809321459258],
              [-75.04135127207975, -11.265504817412332],
              [-75.03770434046555, -11.26899622631901],
              [-75.03415690909722, -11.272044430257319],
              [-75.03089141382526, -11.274902641566378],
              [-75.02772516670274, -11.277308615394903],
              [-75.02529158063778, -11.279642142946402],
              [-75.02275834775864, -11.282436893550539],
              [-75.0200500944607, -11.284942231274439],
              [-75.01640323368767, -11.288442418931101],
              [-75.01204677052874, -11.292982631189728],
              [-75.00860585406441, -11.295569286195601],
              [-75.00622550188366, -11.297667513802688],
              [-75.00290675439186, -11.300760553519668],
              [-74.9991303414076, -11.303754072381965],
              [-74.99578855337168, -11.305879342080054],
              [-74.99275195471532, -11.30879139376446],
              [-74.98931124947107, -11.311377821906621],
              [-74.98558811838954, -11.314136028449045],
              [-74.98153699859135, -11.31731018275034],
              [-74.97714984471052, -11.320882182741595],
              [-74.97274778214013, -11.323486427523175],
              [-74.96961214459522, -11.324697992423362],
              [-74.96586614387353, -11.326488257790569],
              [-74.96337892011803, -11.329047357546536],
              [-74.96192940519938, -11.332330075672896],
              [-74.95947260279503, -11.335856832023858],
              [-74.95696272410895, -11.339609654153179],
              [-74.95447530757028, -11.342168676024896],
              [-74.95082864468388, -11.34566804017744],
              [-74.94779203086554, -11.348579620155792],
              [-74.94373313592726, -11.35175324474699],
              [-74.93988800281518, -11.3540043896812],
              [-74.93588255685482, -11.356933732980039],
              [-74.93221279595167, -11.359465171771863],
              [-74.92874143064321, -11.36324465390674],
              [-74.92488858671268, -11.367657061064085],
              [-74.9226303360256, -11.370270127896852],
              [-74.9201506542266, -11.372828854094474],
              [-74.91588604850683, -11.376915451289213],
              [-74.91206355088056, -11.380133915589758],
              [-74.90801241102479, -11.383297994944009],
              [-74.90367898406969, -11.386633756323901],
              [-74.90078749118675, -11.388866603822573],
              [-74.89727021907807, -11.390710322674574],
              [-74.89463033209958, -11.393956096577877],
              [-74.89224236008457, -11.398223881908946],
              [-74.89046468526273, -11.401904030571696],
              [-74.88828290638102, -11.405249395124471],
              [-74.88629909177537, -11.409851899174017],
              [-74.88348396557353, -11.414978595710568],
              [-74.88159931831984, -11.41911082876909],
              [-74.87974548134437, -11.422058331756164],
              [-74.87771611692365, -11.42472539327102],
              [-74.87574010163742, -11.427157325570574],
              [-74.87092585583731, -11.431586670716442],
              [-74.8683090460846, -11.433638431384708],
              [-74.8659285237713, -11.435744538551727],
              [-74.86184685720733, -11.440101713547998],
              [-74.85915385037278, -11.443582260343723],
              [-74.85752879543037, -11.446574942302083],
              [-74.85546865046408, -11.450426551822863],
              [-74.85391991932009, -11.454160832894255],
              [-74.85266870752815, -11.458682056383726],
              [-74.85063939999276, -11.461348918018851],
              [-74.84804542453307, -11.466529618757313],
              [-74.84621419082778, -11.470435535718],
              [-74.84522242116019, -11.47381735177726],
              [-74.84429927880268, -11.477931738556357],
              [-74.84444427591026, -11.481567377044774],
              [-74.84665671437222, -11.486633010292426],
              [-74.84791561817332, -11.489563821909163],
              [-74.84864030085438, -11.493814727957446],
              [-74.84880833986578, -11.498418046302906],
              [-74.84899907848818, -11.503989047798902],
              [-74.84900657442216, -11.50927959845755],
              [-74.84876242972983, -11.515709518252857],
              [-74.84839617701492, -11.521632926294732],
              [-74.8493194482144, -11.527131950604714],
              [-74.85057053848004, -11.532215130989377],
              [-74.85071563002845, -11.535859797860036],
              [-74.84838093832892, -11.539892056779406],
              [-74.84551242742829, -11.543082952431005],
              [-74.84262088510008, -11.545315179614422],
              [-74.83970655626136, -11.548732081371691],
              [-74.83713517789548, -11.55271889397071],
              [-74.8360670821585, -11.557520462302667],
              [-74.8353501251273, -11.560721501749315],
              [-74.83448026862811, -11.564609766968676],
              [-74.83350377903277, -11.568959191837276],
              [-74.83121484469838, -11.5727652326101],
              [-74.82918536951165, -11.575422867091815],
              [-74.82606505288707, -11.579761945523614],
              [-74.82243348931017, -11.584227284997825],
              [-74.81885527650755, -11.588457475556968],
              [-74.81426235640888, -11.592940189968452],
              [-74.81154643946456, -11.595452553348386],
              [-74.80898286521204, -11.597277677493908],
              [-74.80361182399287, -11.599896720326269],
              [-74.8008729256394, -11.601441300101435],
              [-74.79590595805725, -11.604395087391898],
              [-74.79233556148056, -11.606472567050456],
              [-74.78959654497392, -11.608007993530258],
              [-74.78490438516353, -11.610789958646883],
              [-74.78131107510868, -11.611899619096317],
              [-74.77695449009262, -11.612113332203082],
              [-74.77219368986673, -11.611992048827197],
              [-74.76902758724606, -11.612233709707795],
              [-74.76451876979408, -11.613134423794692],
              [-74.76052087660757, -11.6138998377772],
              [-74.75593581860979, -11.614067825415937],
              [-74.75110628635862, -11.613204564380228],
              [-74.74713121355902, -11.612785106716801],
              [-74.74409476921846, -11.613533045854608],
              [-74.74130275169108, -11.615303092552358],
              [-74.73831204682206, -11.617986329014347],
              [-74.73509231268812, -11.618462699082635],
              [-74.73099499487537, -11.617527424440139],
              [-74.7266160230412, -11.6167726962017],
              [-74.7229767450533, -11.61594626460852],
              [-74.71951322133057, -11.615400307841702],
              [-74.71540855185886, -11.614464806685998],
              [-74.71176934003499, -11.613638235119408],
              [-74.70813015071657, -11.612811618139942],
              [-74.70402555128408, -11.611885000286199],
              [-74.69850899096798, -11.610866418689263],
              [-74.69486988066481, -11.610039636255637],
              [-74.69100209967651, -11.609158302774963],
              [-74.6864471129806, -11.608122433115017],
              [-74.683029314496, -11.607350004266712],
              [-74.67848184277044, -11.606314018567563],
              [-74.6751859851558, -11.608209466689898],
              [-74.67309576685872, -11.611091984426967],
              [-74.67136373892647, -11.614544633466931],
              [-74.66745759148372, -11.617027119823023],
              [-74.66348290288194, -11.61875886193205],
              [-74.65950012875406, -11.620499581718258],
              [-74.65633386309297, -11.620740001668205],
              [-74.65309129125194, -11.62240916384068],
              [-74.64997075606387, -11.624575827248067],
              [-74.6468964579015, -11.62868685672603],
              [-74.64541613989095, -11.633152509936636],
              [-74.64464576784249, -11.636588075251934],
              [-74.64574436420877, -11.640197767232623],
              [-74.64735402089653, -11.643690516262955],
              [-74.64817049010514, -11.649642107514985],
              [-74.64884204943024, -11.654119435896972],
              [-74.64893352049174, -11.657990143538791],
              [-74.64843743625163, -11.661245178223835],
              [-74.64683545477641, -11.665204243078994],
              [-74.64510332679549, -11.66865670349379],
              [-74.6427385729888, -11.67171949791225],
              [-74.64138793514111, -11.674539365929538],
              [-74.639808531884, -11.677304678903612],
              [-74.6388628493772, -11.68045060333005],
              [-74.63719953361084, -11.684635610616953],
              [-74.6362760325118, -11.68875824045853],
              [-74.63603992064009, -11.693026433003794],
              [-74.63526925830584, -11.696452907254569],
              [-74.63176724327727, -11.699260931182328],
              [-74.62869241617179, -11.701210340306526],
              [-74.62537403534772, -11.702137492738554],
              [-74.62239833732262, -11.705787119247777],
              [-74.62091833136391, -11.710252599817844],
              [-74.62059807311218, -11.713788140225521],
              [-74.62023161380803, -11.717549702468622],
              [-74.62105577837313, -11.721339972106051],
              [-74.62086500915414, -11.725382110482439],
              [-74.61994169950255, -11.72949562192457],
              [-74.61851515609487, -11.733744107947077],
              [-74.61727901891388, -11.739222737291865],
              [-74.61599717798401, -11.744945467086797],
              [-74.61562371869779, -11.748707002781263],
              [-74.61576838907003, -11.752342627545794],
              [-74.61624173191964, -11.755571742577448],
              [-74.61845417193987, -11.758477626474729],
              [-74.62164299155961, -11.761366703955497],
              [-74.62345099342411, -11.76394645328943],
              [-74.62594599583154, -11.76883314983646],
              [-74.62810493956285, -11.77413538145627],
              [-74.62938699101923, -11.778034737800533],
              [-74.62911982772385, -11.781335213899718],
              [-74.62450392011665, -11.784847027424727],
              [-74.62030776163469, -11.78966156278292],
              [-74.61776706887656, -11.792452552562892],
              [-74.61532564249016, -11.79478244200224],
              [-74.61311359081463, -11.797157837578105],
              [-74.61072518304967, -11.799261673400649],
              [-74.60739904766233, -11.80234991625114],
              [-74.60433208291553, -11.8042899773927],
              [-74.6016084826558, -11.806800213781381],
              [-74.60020419496475, -11.809845836276512],
              [-74.60012040158104, -11.813426866850449],
              [-74.60010552462091, -11.817749544131189],
              [-74.60023481185539, -11.823546480592888],
              [-74.60047920280469, -11.826730064887089],
              [-74.59988399079072, -11.830436976540073],
              [-74.59848012890606, -11.833482581752502],
              [-74.59510031493257, -11.836796662301957],
              [-74.59248370935425, -11.83884573839272],
              [-74.58969133050873, -11.840614192907765],
              [-74.58580791592739, -11.844054031105438],
              [-74.58371722000108, -11.846944812777968],
              [-74.581878546948, -11.850857837614226],
              [-74.58085650037798, -11.855432215509632],
              [-74.5798263975041, -11.859997531935417],
              [-74.57828533522205, -11.86469772962019],
              [-74.57657651334475, -11.869117323998003],
              [-74.57403579158007, -11.871898826638558],
              [-74.5705568673653, -11.873512303456152],
              [-74.56732937643307, -11.87613896251628],
              [-74.56506365029121, -11.878748977516974],
              [-74.56343081403766, -11.881748809511409],
              [-74.56137076347657, -11.885598029520931],
              [-74.5606459850493, -11.888798200936053],
              [-74.55896733588988, -11.89202401029847],
              [-74.55664823095285, -11.894859944484246],
              [-74.5527422511678, -11.897331618772695],
              [-74.54824837295624, -11.899196401537019],
              [-74.54380820134041, -11.900826074356175],
              [-74.54009983922131, -11.902384523878585],
              [-74.53667466769166, -11.903771567979376],
              [-74.53286730025769, -11.905790958257564],
              [-74.52969340960851, -11.908191165110777],
              [-74.52762577799875, -11.912049138612588],
              [-74.52591698958149, -11.91645933024282],
              [-74.52483364654714, -11.92125941943419],
              [-74.52390297061773, -11.925381523612463],
              [-74.5225751055469, -11.929159309645414],
              [-74.52071371850798, -11.932104210091161],
              [-74.51867658943401, -11.934759415229095],
              [-74.51532766686961, -11.936878801918347],
              [-74.51179494520935, -11.938717490681352],
              [-74.50781234862689, -11.940455871784613],
              [-74.50398248760112, -11.941507187286243],
              [-74.50042729931067, -11.942378075407916],
              [-74.49720746277303, -11.942851621000631],
              [-74.49323277274955, -11.942419459981656],
              [-74.48915102816046, -11.942457289626534],
              [-74.48461144447667, -11.942385704127885],
              [-74.48053718361761, -11.942414379326737],
              [-74.47640220381086, -11.942678002373992],
              [-74.47318292510697, -11.943142229007123],
              [-74.46988699832598, -11.942873785386167],
              [-74.46628591338613, -11.943970226718292],
              [-74.4630355381157, -11.945637003287915],
              [-74.46145617147002, -11.948401156386133],
              [-74.45936565365356, -11.951290918433424],
              [-74.45770245236915, -11.955474642872275],
              [-74.45806879397811, -11.959164911019727],
              [-74.4606931352451, -11.962407372804197],
              [-74.46435537867735, -11.96420490722483],
              [-74.46823127786074, -11.965089452382328],
              [-74.47164933104914, -11.965864566813396],
              [-74.47664659509938, -11.96604574759659],
              [-74.48163585027851, -11.966217781795788],
              [-74.48629769993929, -11.966805109866742],
              [-74.48948689313015, -11.967525332354297],
              [-74.49267551373924, -11.968254561261388],
              [-74.49660480976135, -11.968912739599148],
              [-74.50112136304188, -11.968016496604188],
              [-74.50419607622773, -11.9660686369598],
              [-74.50833127288097, -11.963652359754875],
              [-74.51201654967485, -11.961126719548714],
              [-74.51480893890312, -11.959359108408737],
              [-74.5175549698008, -11.957817466281528],
              [-74.52225482605748, -11.955040217695277],
              [-74.52516960418141, -11.953788164593051],
              [-74.529205498202, -11.951823553169316],
              [-74.5335766811471, -11.94945249663261],
              [-74.53870396261483, -11.947977882775454],
              [-74.54326636860274, -11.94684589597899],
              [-74.54840859762317, -11.946338743984734],
              [-74.55332933403626, -11.945776886378345],
              [-74.55898259934428, -11.945143746603888],
              [-74.56256080928827, -11.945230725652378],
              [-74.5670698880543, -11.944342430336123],
              [-74.57057175725062, -11.943696691282634],
              [-74.57571390803402, -11.943179992661724],
              [-74.58132196412848, -11.942772411860402],
              [-74.58692197129434, -11.942364706828176],
              [-74.59019481887096, -11.941673168371924],
              [-74.59513843406921, -11.939907858666245],
              [-74.59833541124186, -11.938474570386171],
              [-74.60141743774493, -11.934373329860252],
              [-74.60490412633061, -11.930598176292643],
              [-74.60697198442028, -11.926739609358789],
              [-74.60931380780224, -11.922709583001934],
              [-74.61153404194494, -11.918163901195559],
              [-74.61240392780513, -11.914276501745102],
              [-74.61368539952919, -11.91072426256801],
              [-74.6150130819078, -11.906936954063479],
              [-74.61634817431336, -11.903158690551374],
              [-74.61734750745305, -11.899777867155793],
              [-74.61702700435123, -11.895861697962518],
              [-74.616584977836, -11.891438939470053],
              [-74.61679817774005, -11.888364516725925],
              [-74.61808004748708, -11.884803203099498],
              [-74.62001778865167, -11.880437912637301],
              [-74.62248226266975, -11.87691433522338],
              [-74.62469489889999, -11.874538897115928],
              [-74.62769294835378, -11.871857003459414],
              [-74.63076799708894, -11.869907683455398],
              [-74.63401821397686, -11.868247940851045],
              [-74.63858008403433, -11.867114350200701],
              [-74.64185358641544, -11.864260827718557],
              [-74.6425779235643, -11.861060410961034],
              [-74.64304362743766, -11.856837770387491],
              [-74.6427308212541, -11.85292161919656],
              [-74.6425629419462, -11.84831835728958],
              [-74.64245618242207, -11.843489087949843],
              [-74.64363871095823, -11.838227360737582],
              [-74.64537820737098, -11.832613632262955],
              [-74.64756799684382, -11.82927032667925],
              [-74.65028396174809, -11.82675963835894],
              [-74.65403746892954, -11.824973656552542],
              [-74.65748571405013, -11.824552801347075],
              [-74.66123189859947, -11.824928074805326],
              [-74.66748819200204, -11.82586693915542],
              [-74.67138670503687, -11.825554962340643],
              [-74.67524732919, -11.823307608195673],
              [-74.67652123579431, -11.819745975691655],
              [-74.67744462394656, -11.81563229217959],
              [-74.6772612365984, -11.810061365300621],
              [-74.67671968897811, -11.806099750651263],
              [-74.67528539752813, -11.800726348895523],
              [-74.6743392653406, -11.796420617653741],
              [-74.67442323178237, -11.792839533907935],
              [-74.67560605654607, -11.787577633424593],
              [-74.67744467823417, -11.783672977465711],
              [-74.68043490490876, -11.780990460200057],
              [-74.68502806130036, -11.778662379023269],
              [-74.68959053528552, -11.777536961209364],
              [-74.69309231838317, -11.776889629519989],
              [-74.69850901209746, -11.776199069939404],
              [-74.70172889866001, -11.775732201083294],
              [-74.7058560814327, -11.775474232247344],
              [-74.71049510323519, -11.775081072562973],
              [-74.7136613756916, -11.774840099261443],
              [-74.71703337341783, -11.773685908636873],
              [-74.72007727710205, -11.77076788589592],
              [-74.7210235294024, -11.767621698031627],
              [-74.72200022245578, -11.763272758100259],
              [-74.72259535314961, -11.75956552656225],
              [-74.72476221100962, -11.75525390166507],
              [-74.72798165177169, -11.752616300837127],
              [-74.73105638058871, -11.750674871625577],
              [-74.73447412771078, -11.749285378196538],
              [-74.73696141643421, -11.746728381152298],
              [-74.73828885379903, -11.742949433247414],
              [-74.73941809145529, -11.740074643449331],
              [-74.74104298898621, -11.737082732533466],
              [-74.74249259711516, -11.733810305578352],
              [-74.74407190351097, -11.731044420554014],
              [-74.74578117070033, -11.726623684620412],
              [-74.74713160370675, -11.723803315520449],
              [-74.7480773790023, -11.72065701761243],
              [-74.74942775865748, -11.717836635426064],
              [-74.750984246042, -11.714112076105616],
              [-74.75276203989725, -11.710423871624512],
              [-74.75550846726202, -11.706727438920934],
              [-74.75799575042892, -11.704170240000893],
              [-74.7607879919379, -11.702400057669745],
              [-74.76506833503855, -11.7014449862943],
              [-74.76834078811727, -11.700742255951813],
              [-74.77139276105875, -11.703123149262883],
              [-74.7714387189728, -11.70721989515871],
              [-74.77117161130093, -11.7105205615644],
              [-74.76995068674499, -11.713847592696371],
              [-74.76781453454129, -11.716965876046007],
              [-74.7664108142772, -11.720012392632317],
              [-74.76506059429249, -11.722832857723624],
              [-74.76429772970772, -11.726259713790212],
              [-74.76393146921409, -11.730021503364846],
              [-74.76619700149308, -11.73486163054519],
              [-74.76887539518442, -11.737875289705416],
              [-74.77155327330668, -11.740888923769212],
              [-74.7720413918967, -11.74508549054228],
              [-74.77213286266179, -11.748947130469734],
              [-74.77139272703216, -11.753341669823403],
              [-74.76937860440039, -11.756975526877916],
              [-74.76729564197076, -11.759867747070121],
              [-74.76383962325941, -11.76244229581725],
              [-74.75980396243675, -11.76657178909295],
              [-74.75739291708159, -11.769870610907743],
              [-74.75477583002174, -11.771921236382697],
              [-74.75150285606684, -11.774785176361217],
              [-74.74739063683697, -11.778172853938019],
              [-74.74348453964708, -11.780647267337521],
              [-74.73929595394296, -11.783302235655633],
              [-74.736000089589, -11.785198320855397],
              [-74.73201762974958, -11.786939982896964],
              [-74.72962962617713, -11.789035800645241],
              [-74.72682958096168, -11.792967017356416],
              [-74.72525031118886, -11.795732784127463],
              [-74.72235099253594, -11.800125070487669],
              [-74.71983318251513, -11.803875617905758],
              [-74.71708700677908, -11.807580696285592],
              [-74.71363853327333, -11.810154613142515],
              [-74.7084881792808, -11.81283526586585],
              [-74.70546734283536, -11.814550395364062],
              [-74.70158402940835, -11.815830504002754],
              [-74.69793700447825, -11.817165070338918],
              [-74.69481686156313, -11.819341190370839],
              [-74.69242886024945, -11.82144572169679],
              [-74.6898119644833, -11.823486678077726],
              [-74.68706519050203, -11.827191446683493],
              [-74.68498255470418, -11.830083020962023],
              [-74.68289184188222, -11.832974569269602],
              [-74.68093132000837, -11.83637267531875],
              [-74.67874129593193, -11.839716245671838],
              [-74.67691031976175, -11.84362993788053],
              [-74.67608663063126, -11.847282512115111],
              [-74.67655965406064, -11.850520572119406],
              [-74.67631534554737, -11.854788753416978],
              [-74.67503355025319, -11.860502678659639],
              [-74.67420976894242, -11.864164284447202],
              [-74.67232491865607, -11.868303955908331],
              [-74.67000571317548, -11.87330222194481],
              [-74.6680909160787, -11.876474188483147],
              [-74.66534446476861, -11.880169669225436],
              [-74.66353628762798, -11.882889561128424],
              [-74.66122463096129, -11.88572639968523],
              [-74.65913380695947, -11.88861774401871],
              [-74.6575544397425, -11.891383085645469],
              [-74.65628051668632, -11.894935556667658],
              [-74.65428900300721, -11.899527121418332],
              [-74.6522369812688, -11.906515067220003],
              [-74.65070318655151, -11.911206639344462],
              [-74.64970421617151, -11.91458758910891],
              [-74.649009552141, -11.918755678757195],
              [-74.64760574550564, -11.921801511828926],
              [-74.64381432457033, -11.926951409093382],
              [-74.6418303574079, -11.92938153181576],
              [-74.63948837144073, -11.933411838739866],
              [-74.6378251328217, -11.93760577650097],
              [-74.63684872938131, -11.941945281694872],
              [-74.63602473770665, -11.945606734662558],
              [-74.6359407403195, -11.949187753941427],
              [-74.63543696580302, -11.954604007237867],
              [-74.63537576799847, -11.959152680575777],
              [-74.63500216202112, -11.965075518974785],
              [-74.63432304373794, -11.9702111943536],
              [-74.6340333963372, -11.97471434765288],
              [-74.63378883224101, -11.978982433846994],
              [-74.63735949117701, -11.981229796503532],
              [-74.6420288918743, -11.97965323140106],
              [-74.64624026632083, -11.977967490497436],
              [-74.65045953557022, -11.974120358307939],
              [-74.6535645609026, -11.96881582090909],
              [-74.65614297452917, -11.962669576171258],
              [-74.6581801636569, -11.960013344413735],
              [-74.66097278932546, -11.958244249523586],
              [-74.66508500313222, -11.957010255303178],
              [-74.66842642347095, -11.957050392873482],
              [-74.67281345619259, -11.957806129699394],
              [-74.67546824302046, -11.959852991131582],
              [-74.67922983906645, -11.963357063009425],
              [-74.68102278049412, -11.967121110516057],
              [-74.68111411078695, -11.970991737233957],
              [-74.68033624020012, -11.974418253224874],
              [-74.6795196082797, -11.978079846493342],
              [-74.68242662926221, -11.981139779873956],
              [-74.68630243329817, -11.982021341221838],
              [-74.69088730256402, -11.981863655501837],
              [-74.69538108185286, -11.979996615476697],
              [-74.69939432577124, -11.977061879166524],
              [-74.70120221612997, -11.974350824496023],
              [-74.7030031812286, -11.971639749498642],
              [-74.7057569831683, -11.967934899636077],
              [-74.70809946227767, -11.963904033808854],
              [-74.71031957399104, -11.959357551062281],
              [-74.71200549023246, -11.953978515905938],
              [-74.7121732346109, -11.948968692393837],
              [-74.71202840444738, -11.945333131125828],
              [-74.71130390770148, -11.941082028753625],
              [-74.70842753074965, -11.936819658338509],
              [-74.70716845897209, -11.93388831499071],
              [-74.70553566763364, -11.92943729720071],
              [-74.70557429904406, -11.926082264652754],
              [-74.70695499576358, -11.922068467999283],
              [-74.708717432604, -11.91742202421474],
              [-74.71176131889915, -11.914513200621784],
              [-74.71316552710117, -11.911467028304411],
              [-74.71218850758724, -11.908355123598673],
              [-74.70948030231912, -11.906543678269404],
              [-74.7102282102431, -11.902140340368941],
              [-74.71308152690597, -11.897983317522446],
              [-74.71650687750066, -11.894441785764666],
              [-74.72069567035702, -11.891787214927122],
              [-74.72399924273184, -11.889891358201528],
              [-74.72661597189965, -11.887841060351056],
              [-74.7301177745755, -11.885031941284499],
              [-74.73413095794355, -11.882096651215415],
              [-74.73801426995774, -11.878654752274144],
              [-74.74098982161112, -11.875003969137635],
              [-74.74350744620087, -11.871253259930917],
              [-74.74636050721713, -11.867095874371596],
              [-74.74930546475512, -11.864638696707395],
              [-74.7533874879633, -11.862444738681384],
              [-74.75754569419898, -11.860983300990757],
              [-74.76193219704747, -11.859576253765757],
              [-74.76677706476788, -11.858268993101328],
              [-74.76999666708284, -11.857801370041702],
              [-74.77367409524206, -11.857433554086846],
              [-74.77593253645203, -11.854821803613456],
              [-74.77564233520293, -11.849712042861785],
              [-74.77403278093199, -11.84622000356074],
              [-74.77001177330864, -11.843865479357548],
              [-74.7668687068998, -11.842913352309079],
              [-74.76525872936118, -11.839421263834966],
              [-74.76676159519982, -11.835922700031562],
              [-74.77019523728093, -11.832371436942461],
              [-74.77437568286551, -11.829716024378433],
              [-74.77989946959694, -11.828571847139521],
              [-74.78363032773743, -11.827968806340081],
              [-74.7867738119827, -11.826759364972407],
              [-74.79148870067776, -11.824945136339766],
              [-74.79599772546645, -11.824053111865249],
              [-74.80015570635207, -11.822591031004466],
              [-74.80393221829327, -11.82176166688723],
              [-74.8072586650847, -11.820832468964959],
              [-74.81146230817878, -11.819144165084085],
              [-74.81476582576562, -11.817247212762743],
              [-74.81872561951691, -11.814545772442605],
              [-74.82154089634804, -11.81158129820931],
              [-74.8235779405247, -11.808914747083586],
              [-74.82601175359204, -11.80658303192938],
              [-74.82888036700069, -11.80339242690258],
              [-74.832130371073, -11.801721321087651],
              [-74.83535007235488, -11.801252942704705],
              [-74.83863818092759, -11.801517088493743],
              [-74.84276586166703, -11.803418532011966],
              [-74.84542076870373, -11.805454771217965],
              [-74.84926618670117, -11.807527793598618],
              [-74.85131069248382, -11.81016052352071],
              [-74.85155482972814, -11.813334912404574],
              [-74.84947199680354, -11.81622772894146],
              [-74.84685513460902, -11.81827919859659],
              [-74.8430023307113, -11.818367529418715],
              [-74.83920301600507, -11.81822975147286],
              [-74.83596032193972, -11.819891895052494],
              [-74.83422865520504, -11.823345493410159],
              [-74.8331833679324, -11.826953222972246],
              [-74.83127592983756, -11.830126349932598],
              [-74.82987202116131, -11.833173156858185],
              [-74.82677447712122, -11.83631840422985],
              [-74.82269283919273, -11.838513440095266],
              [-74.81914506423881, -11.839388413700746],
              [-74.81622302125075, -11.840652617069107],
              [-74.81227118510348, -11.841192634156894],
              [-74.8091811627211, -11.842176306044966],
              [-74.80644217889396, -11.843720877064044],
              [-74.80637356146437, -11.848269679467663],
              [-74.80725100706641, -11.851833391509532],
              [-74.80846416178153, -11.854990373084892],
              [-74.81103511344877, -11.85845573550865],
              [-74.8126755379543, -11.862915237614514],
              [-74.81286631250128, -11.866324736651134],
              [-74.81224836262447, -11.871225864237347],
              [-74.81147760477765, -11.874652808857224],
              [-74.8080291324099, -11.877236906244594],
              [-74.80480207451426, -11.87987537364726],
              [-74.80165863483802, -11.881075958857238],
              [-74.79863730723834, -11.882801113454068],
              [-74.79550925636312, -11.887139702340905],
              [-74.79393011003566, -11.88990583632955],
              [-74.79270183002667, -11.895385238407524],
              [-74.79266349628999, -11.900910726242165],
              [-74.79340365508455, -11.90612026455232],
              [-74.79388418125383, -11.90935815058702],
              [-74.79435726363413, -11.912586986421175],
              [-74.7951279365779, -11.916602812125833],
              [-74.79625693477708, -11.921188627602296],
              [-74.7967528760376, -11.925385115961625],
              [-74.79679115287256, -11.92948179523294],
              [-74.79711139484738, -11.933397809222683],
              [-74.79624176170825, -11.937285832448477],
              [-74.79592887521736, -11.940812521204217],
              [-74.79472338087908, -11.945107244671771],
              [-74.79208386970652, -11.948351873503892],
              [-74.78981790372127, -11.95096372010857],
              [-74.7875596436394, -11.953575553086251],
              [-74.7839506740593, -11.956846496031716],
              [-74.7806244824019, -11.959936744443405],
              [-74.77784730643049, -11.962665652562984],
              [-74.7751313914311, -11.965177540540143],
              [-74.77251426395733, -11.967228270573138],
              [-74.76916510023104, -11.969350720683071],
              [-74.76622031752767, -11.971808070256035],
              [-74.76271085591044, -11.974617600352365],
              [-74.75933061661097, -11.977933621954493],
              [-74.75554643521272, -11.980914645609833],
              [-74.75277682320514, -11.98365234311345],
              [-74.74998450784176, -11.985422356523058],
              [-74.74652888080118, -11.98800564016788],
              [-74.74409477055335, -11.990336601799557],
              [-74.74223301029869, -11.993273969984013],
              [-74.74082925567654, -11.996320268331958],
              [-74.74061561332188, -11.999394793517068],
              [-74.74154654274146, -12.002732632801358],
              [-74.74513990952099, -12.005955343578279],
              [-74.7495729711447, -12.006474814723495],
              [-74.75481441800304, -12.007664130183766],
              [-74.75907150652883, -12.007911998098889],
              [-74.76358797268416, -12.00701152017162],
              [-74.76792896178705, -12.00583048048584],
              [-74.77153035082463, -12.004730159657498],
              [-74.77530659933419, -12.00390123194862],
              [-74.77883156448861, -12.004220556324883],
              [-74.78253171435847, -12.00482031567025],
              [-74.78582766115866, -12.005085114199325],
              [-74.78896318103396, -12.006037045886337],
              [-74.79166406209458, -12.010018133735219],
              [-74.79380810977325, -12.014342431881827],
              [-74.79658495145482, -12.019065080725424],
              [-74.79863739425237, -12.021689130339642],
              [-74.80108639245972, -12.024648050475623],
              [-74.80422206623953, -12.027761167908693],
              [-74.80725854478034, -12.029164994514453],
              [-74.8110885122751, -12.03027994002381],
              [-74.81460583733144, -12.030589770201352],
              [-74.81856530036339, -12.030049771203315],
              [-74.82060231198588, -12.02738330554396],
              [-74.82136537807169, -12.023956372565452],
              [-74.8212279642873, -12.020320865187557],
              [-74.82074750517371, -12.017092087188193],
              [-74.82065590333998, -12.013221480497121],
              [-74.8207701611574, -12.008446670682067],
              [-74.82347094151135, -12.004966700955787],
              [-74.82626356771875, -12.003195952992188],
              [-74.83039094347971, -12.002936227735967],
              [-74.83419798590705, -12.003074159318437],
              [-74.837768513783, -12.003166685396215],
              [-74.84015646019382, -12.00106095820708],
              [-74.84012598631269, -11.994802938604868],
              [-74.83952335521653, -11.99106768675465],
              [-74.83910371965037, -11.987603838816986],
              [-74.83878339494619, -11.98368787845678],
              [-74.83881380322076, -11.980332807347084],
              [-74.83922577754038, -11.97634491860001],
              [-74.8408890594335, -11.972149742890416],
              [-74.8447188341051, -11.968932447253321],
              [-74.84888451344695, -11.967478737567369],
              [-74.85437773215239, -11.967517815210316],
              [-74.85929866608757, -11.96911196073836],
              [-74.86357121033559, -11.972487252037086],
              [-74.86673745775776, -11.97439692745709],
              [-74.87210087166753, -11.976099580181486],
              [-74.87539685925212, -11.976363322018528],
              [-74.87857061670037, -11.973959173980557],
              [-74.87979889749151, -11.97063173683626],
              [-74.88056946124955, -11.965043251210213],
              [-74.88164533172592, -11.960241665158902],
              [-74.88362892491864, -11.955639405271002],
              [-74.88497911098797, -11.952827464121647],
              [-74.88825975427896, -11.949962029458113],
              [-74.89105225781157, -11.94819061861558],
              [-74.89449316025544, -11.949928265162878],
              [-74.89889539189237, -11.953809504076801],
              [-74.9011916578177, -11.95745481246706],
              [-74.90296159051968, -11.96025888397844],
              [-74.90500630936647, -11.962882169173492],
              [-74.90816511957364, -11.966961793049066],
              [-74.9115600801334, -11.968934344066337],
              [-74.91512292227341, -11.971178206792946],
              [-74.91884619411938, -11.972743816070134],
              [-74.91833507593958, -11.97719301783637],
              [-74.9144210257229, -11.979669739953184],
              [-74.91135415749629, -11.981622159176997],
              [-74.90668476912862, -11.983203236496712],
              [-74.90143598911303, -11.98417812553973],
              [-74.89808645267756, -11.986302122648572],
              [-74.89402770012468, -11.987304432475483],
              [-74.88938902580706, -11.987700548461941],
              [-74.88550578130189, -11.988983148244968],
              [-74.88086697999469, -11.991540489313227],
              [-74.87806696404091, -11.995473151363674],
              [-74.87618251973396, -11.999605144104326],
              [-74.87475584663032, -12.003845848024476],
              [-74.87403093870262, -12.00704687323022],
              [-74.8731612911766, -12.010935125395633],
              [-74.87229161814639, -12.014823373526657],
              [-74.8715744018128, -12.018024392809812],
              [-74.87020106423205, -12.022039007023904],
              [-74.868110589017, -12.02709326026498],
              [-74.86647783108806, -12.030085832037805],
              [-74.86208338901723, -12.033655816587942],
              [-74.85903941250062, -12.034413936852065],
              [-74.85503389590252, -12.035189612710585],
              [-74.85152421947839, -12.03800024536017],
              [-74.84860242058544, -12.04141705526964],
              [-74.84638965302996, -12.045964640203048],
              [-74.84394082825985, -12.050457813275356],
              [-74.84266652569804, -12.054011122580029],
              [-74.84139247620193, -12.05756442490092],
              [-74.84001159321454, -12.06157886781418],
              [-74.83853162274445, -12.066045410364614],
              [-74.83781443879798, -12.069246325284707],
              [-74.83704368643411, -12.072682332022888],
              [-74.83556360364689, -12.077148853829856],
              [-74.83335107943165, -12.079534968960612],
              [-74.83052823657552, -12.082499465427095],
              [-74.82773595945442, -12.08427023589934],
              [-74.82225037413878, -12.085189199552763],
              [-74.81867991962618, -12.085096461427561],
              [-74.81335441917108, -12.085337106683719],
              [-74.80977621423, -12.085244249046974],
              [-74.8046263973507, -12.087926533373645],
              [-74.8021849712255, -12.090257973102887],
              [-74.80020145712324, -12.092689195989658],
              [-74.79836268361979, -12.096594562386201],
              [-74.79647830470145, -12.100735006295348],
              [-74.79611217788971, -12.104496737171456],
              [-74.79508978384318, -12.109071876403178],
              [-74.7943192346975, -12.112498702604762],
              [-74.79354865825128, -12.115934568756657],
              [-74.79122915959347, -12.1209337528746],
              [-74.78822328567247, -12.125778655674893],
              [-74.78470597516322, -12.130749730977568],
              [-74.78203569763541, -12.133026540169308],
              [-74.7793731295647, -12.1353033286498],
              [-74.77733602981496, -12.137969453095678],
              [-74.77512335599017, -12.140346035408665],
              [-74.77146124593128, -12.143842766456213],
              [-74.7680281972621, -12.147393897980711],
              [-74.76436589831253, -12.150890529953362],
              [-74.75999448368736, -12.15325609467538],
              [-74.75641658961352, -12.155332905812847],
              [-74.75247955122582, -12.156839626024395],
              [-74.74808528607613, -12.158246397762172],
              [-74.74369786966358, -12.15965310499905],
              [-74.73995996923901, -12.162416832785496],
              [-74.73719003105914, -12.165145257416821],
              [-74.73497031830982, -12.169691861864669],
              [-74.73394029558439, -12.174266723777368],
              [-74.73179612382108, -12.179545867467679],
              [-74.73061363798594, -12.18480784497838],
              [-74.72973642455749, -12.188695551697883],
              [-74.72949998755698, -12.192963696397923],
              [-74.72872154601434, -12.198551608728751],
              [-74.72811916605423, -12.204429070876415],
              [-74.72838614134962, -12.208571101216894],
              [-74.72837828766806, -12.212893722356894],
              [-74.72892010342194, -12.216864201066853],
              [-74.72941573611088, -12.221060711253623],
              [-74.72986575919496, -12.225483253504095],
              [-74.73074360918189, -12.229047114282453],
              [-74.73168968897424, -12.233352576242725],
              [-74.73335240611488, -12.238771036949682],
              [-74.73597717595675, -12.244172326934821],
              [-74.73741178483691, -12.247384016756548],
              [-74.73895261980854, -12.250134600005717],
              [-74.741577417165, -12.253374526732022],
              [-74.74319461883358, -12.256866695574075],
              [-74.745490996569, -12.260513219029104],
              [-74.74774182075292, -12.264385759949551],
              [-74.75080127522256, -12.26891916743379],
              [-74.75320461150766, -12.272104524466734],
              [-74.75496696748512, -12.274909476801831],
              [-74.75769779121129, -12.277697189851743],
              [-74.76007860138354, -12.27991485251156],
              [-74.76377856232139, -12.28267622368548],
              [-74.7677231279114, -12.286450584091154],
              [-74.77015659960196, -12.28844212693781],
              [-74.77324704015753, -12.291790663347593],
              [-74.77638258918441, -12.294904080806425],
              [-74.7783812462388, -12.297754293374188],
              [-74.78095995424522, -12.301228932585179],
              [-74.78234866456201, -12.304666430479761],
              [-74.7824859208948, -12.308301876832502],
              [-74.7822724381839, -12.311376369316024],
              [-74.7807692967168, -12.314883898863108],
              [-74.77774809594158, -12.31659965031929],
              [-74.77452098826426, -12.317067247682244],
              [-74.77088919604041, -12.317208915488209],
              [-74.76761616562538, -12.317911513296343],
              [-74.76633463344628, -12.321464361930717],
              [-74.7668077340327, -12.32469315567082],
              [-74.7664947724031, -12.328228742182919],
              [-74.76235973598222, -12.328487423018677],
              [-74.75954427038023, -12.327128492009718],
              [-74.75635544693877, -12.324249958592372],
              [-74.75435655641745, -12.321390547787967],
              [-74.75174727749942, -12.319118369986041],
              [-74.74817636718349, -12.31686336908119],
              [-74.7435150017171, -12.314128016494697],
              [-74.74047854317227, -12.312714425953702],
              [-74.73674790508255, -12.31115544477253],
              [-74.73312354901726, -12.309126276190657],
              [-74.72945417508666, -12.307332136237136],
              [-74.72663853427169, -12.305972871207958],
              [-74.7231367924694, -12.304459151184457],
              [-74.7186583921597, -12.302003902541728],
              [-74.71599591873671, -12.299957412419692],
              [-74.71288297633117, -12.2978109505341],
              [-74.70870977411272, -12.293981312930594],
              [-74.70509344486345, -12.289799533322604],
              [-74.7029648958731, -12.286433203169764],
              [-74.70208724866345, -12.282869272437337],
              [-74.70250726095172, -12.278881725385004],
              [-74.70275114535197, -12.274604606183487],
              [-74.70349859590436, -12.270210470084836],
              [-74.70455145776367, -12.26660340920246],
              [-74.7066420599027, -12.261550557383558],
              [-74.70838141751061, -12.25809794383369],
              [-74.7098389409551, -12.254825881898396],
              [-74.71070863785555, -12.250938259862794],
              [-74.71159387140479, -12.244889343654659],
              [-74.7122648715434, -12.239762595751495],
              [-74.71237922884924, -12.23497890080312],
              [-74.71224237730459, -12.23134341907466],
              [-74.71138789970249, -12.22658583566312],
              [-74.70942714382358, -12.222532447146683],
              [-74.70786312535618, -12.218814062226409],
              [-74.70670329158844, -12.215430695392861],
              [-74.70513935088314, -12.211712294903522],
              [-74.70287342519674, -12.206871779719497],
              [-74.7014925574791, -12.203433893260431],
              [-74.70079067702406, -12.200150471213357],
              [-74.70171342910393, -12.196027796972926],
              [-74.70248427132212, -12.192601276505272],
              [-74.70422388131777, -12.18698734976267],
              [-74.70487227474928, -12.183054270993734],
              [-74.70376573976483, -12.179435822133524],
              [-74.69991309225117, -12.17736077791099],
              [-74.69593797710968, -12.176940443621373],
              [-74.69266497819609, -12.175471828998411],
              [-74.68894939915896, -12.171750933180892],
              [-74.68893408099764, -12.16862198461162],
              [-74.6901627442084, -12.165295483400428],
              [-74.69151315495893, -12.162475528151527],
              [-74.69314579781089, -12.159484058345262],
              [-74.69586933692331, -12.156973067222339],
              [-74.69904307801164, -12.15457105596554],
              [-74.70291110582397, -12.152323487091987],
              [-74.70626077782431, -12.150201917012163],
              [-74.70863314085702, -12.1471387864572],
              [-74.70788573476527, -12.141920080649783],
              [-74.7069853830038, -12.137388492133704],
              [-74.70674127848739, -12.1342140740442],
              [-74.70756553583205, -12.130552452276596],
              [-74.70864087025438, -12.12575164773848],
              [-74.70832059080915, -12.12183560572564],
              [-74.70602415511581, -12.1181887710231],
              [-74.70380392938614, -12.115283541620531],
              [-74.70063781795912, -12.11336298490734],
              [-74.69722004497852, -12.112581561341484],
              [-74.69272594651059, -12.11228723887578],
              [-74.6888198835475, -12.112599397726914],
              [-74.6851730392469, -12.111772353827464],
              [-74.68150324205301, -12.109977615130486],
              [-74.67824577266572, -12.10635658344913],
              [-74.67503347385569, -12.102500444733309],
              [-74.67214993548532, -12.100408079613928],
              [-74.66860209271164, -12.099119737011534],
              [-74.66563435825982, -12.100608305285306],
              [-74.66336818467312, -12.10321904612331],
              [-74.6630936945283, -12.108680790725833],
              [-74.66500844096494, -12.11296052912875],
              [-74.668220713848, -12.116816780494041],
              [-74.67125685979522, -12.118231130497184],
              [-74.67366021287584, -12.121417166126347],
              [-74.6731108813718, -12.12489814027007],
              [-74.67125716127823, -12.127834977887892],
              [-74.66840336265541, -12.12983916065832],
              [-74.66718264759643, -12.133165580683785],
              [-74.66726689031552, -12.137027110894902],
              [-74.66938809140353, -12.140393706220307],
              [-74.6708219371469, -12.14360573560161],
              [-74.67086057293797, -12.147693287805021],
              [-74.66925069160087, -12.151652268681108],
              [-74.66660302991781, -12.154895580256456],
              [-74.66525240017926, -12.157715409702794],
              [-74.66399381940786, -12.162235453375247],
              [-74.66368088247134, -12.16577093954958],
              [-74.66320793723926, -12.169993507381914],
              [-74.66365047520563, -12.1744161381479],
              [-74.66582505096984, -12.177556744768617],
              [-74.66792303988123, -12.179955706001586],
              [-74.67002106632121, -12.182354651852274],
              [-74.67292782255373, -12.185414690648582],
              [-74.67606381680388, -12.18852922784888],
              [-74.67752094778804, -12.192708861914898],
              [-74.67715443661771, -12.196470374641917],
              [-74.67709377357188, -12.201018995971276],
              [-74.6772080097915, -12.205848157492477],
              [-74.67648304242232, -12.209048571506884],
              [-74.67520143243259, -12.212601007924844],
              [-74.67298866112519, -12.214985770632484],
              [-74.66979217731115, -12.216419809441359],
              [-74.66568000926397, -12.217653751116183],
              [-74.6619946810072, -12.218020017199951],
              [-74.65603634501639, -12.217867945898048],
              [-74.65184807018801, -12.218360049967956],
              [-74.64837653568986, -12.217804008251719],
              [-74.64604923872166, -12.215359391504014],
              [-74.64267719239355, -12.214351252716135],
              [-74.63903027785989, -12.213523574673417],
              [-74.63583372163049, -12.212795918778047],
              [-74.63172905127936, -12.211868065453066],
              [-74.62761695440055, -12.210931098542048],
              [-74.6237105467094, -12.21124229589192],
              [-74.61934633666468, -12.211453331369492],
              [-74.61579132187403, -12.212325571280847],
              [-74.61162579995089, -12.213784702831566],
              [-74.60842902109621, -12.215218020363968],
              [-74.60550711534658, -12.216479875756209],
              [-74.60202787979235, -12.218093572670405],
              [-74.59745761656669, -12.219217306288256],
              [-74.59329246865015, -12.220676154079975],
              [-74.58996586060809, -12.221602649417807],
              [-74.58739460164023, -12.223416454193629],
              [-74.5838625294425, -12.225255910851978],
              [-74.58131436599842, -12.228046336780983],
              [-74.57922338485203, -12.230936910033885],
              [-74.5760876888695, -12.235272737495258],
              [-74.57392133916032, -12.239582900424294],
              [-74.57157148381741, -12.243612423662876],
              [-74.56899234369898, -12.247596348625645],
              [-74.56642175962723, -12.251571216271309],
              [-74.5635073371876, -12.255961486648244],
              [-74.55966953748147, -12.259174654546412],
              [-74.55536633549755, -12.26227849535847],
              [-74.55234519760127, -12.264000787348815],
              [-74.54850796963898, -12.265043480367407],
              [-74.54393784582749, -12.266166287234736],
              [-74.54053477198359, -12.266359551137418],
              [-74.53723881684486, -12.266091763327521],
              [-74.53236372758786, -12.26641808162876],
              [-74.52828221418685, -12.266456305340382],
              [-74.5232850402113, -12.266275847657496],
              [-74.51886776868682, -12.26671123587016],
              [-74.51541886791924, -12.267130179651513],
              [-74.51135282517072, -12.268126726795382],
              [-74.507362385043, -12.269864866536171],
              [-74.50293753694625, -12.272461233936475],
              [-74.49823008199452, -12.275237866686567],
              [-74.4936525965337, -12.27852105597875],
              [-74.48930363208333, -12.281858847198068],
              [-74.48662562835419, -12.284132643846515],
              [-74.48268101524937, -12.287805665528262],
              [-74.47911860763385, -12.290837310202951],
              [-74.47471593558292, -12.294400820952683],
              [-74.47064214308779, -12.297557964198198],
              [-74.46656825431681, -12.300715042063118],
              [-74.46395124996938, -12.302762647097408],
              [-74.4595946222347, -12.306099915401305],
              [-74.45669552021688, -12.308318701507371],
              [-74.4532317655619, -12.31089803771897],
              [-74.44898216841152, -12.313774155508755],
              [-74.44506843739917, -12.316243959721742],
              [-74.4421616136722, -12.31847160763774],
              [-74.43898019794224, -12.320870471074345],
              [-74.43610398895643, -12.324056646577944],
              [-74.43388357191151, -12.32643934267214],
              [-74.43191500170862, -12.329835332667148],
              [-74.43109143782009, -12.333495935695813],
              [-74.43036656680611, -12.336695559809753],
              [-74.43111414997891, -12.339753572360658],
              [-74.43181586828923, -12.343028513465411],
              [-74.4330899624785, -12.346928594014189],
              [-74.43460083840651, -12.350874374325858],
              [-74.43565379795156, -12.354719728301333],
              [-74.43602013403908, -12.35840993033244],
              [-74.43605044643697, -12.362506359099966],
              [-74.43506611370778, -12.366844836285122],
              [-74.43418907634519, -12.370731393339],
              [-74.43301417726934, -12.373830606439125],
              [-74.43058779336411, -12.377126134864861],
              [-74.4279634155522, -12.379173325520252],
              [-74.42430109282854, -12.380503917633195],
              [-74.4206387548976, -12.381825415960082],
              [-74.41599302052849, -12.38221335310225],
              [-74.41143039289351, -12.381172643494677],
              [-74.40777571578626, -12.3803418202942],
              [-74.40390041695245, -12.379456209093378],
              [-74.40108466662907, -12.378093617406511],
              [-74.3978730215008, -12.376395542019516],
              [-74.39475272137256, -12.3742454995932],
              [-74.3923266027572, -12.37225070050726],
              [-74.38944255659035, -12.370155384362825],
              [-74.38597879479404, -12.367434787447664],
              [-74.38163776942315, -12.366448349262138],
              [-74.37762433921641, -12.367216868513184],
              [-74.37441991822415, -12.368647302331054],
              [-74.37078106290826, -12.370944786217123],
              [-74.36856828829717, -12.373326916332138],
              [-74.36517350848374, -12.376637660608194],
              [-74.36262506800223, -12.379416828383077],
              [-74.36042761408982, -12.382766480467074],
              [-74.35840594399976, -12.386387804937607],
              [-74.35585788800908, -12.3891759430252],
              [-74.35231804273906, -12.391012217555994],
              [-74.34901431761142, -12.39290326501603],
              [-74.34621451729957, -12.39466888724184],
              [-74.34325429541204, -12.397112284380745],
              [-74.34017970327739, -12.399058026423589],
              [-74.3376085008942, -12.400869321576796],
              [-74.33419011193425, -12.403221067129795],
              [-74.33065798462323, -12.405057059893101],
              [-74.32711775996096, -12.406892983921464],
              [-74.32421873445664, -12.409119286636672],
              [-74.32115920796136, -12.412023344160003],
              [-74.31919082608988, -12.415418401956842],
              [-74.31772642025993, -12.418688115768136],
              [-74.31694810789753, -12.422122314734102],
              [-74.31652081536458, -12.42610898911185],
              [-74.31986974026651, -12.428314751196082],
              [-74.32319634018303, -12.427391703389425],
              [-74.32646157207402, -12.428855690340024],
              [-74.32688162688905, -12.432320025298944],
              [-74.32719414518911, -12.436236218600781],
              [-74.32717152015213, -12.440558491612531],
              [-74.32980326287175, -12.443802248657349],
              [-74.33240533914967, -12.446078358233168],
              [-74.33478562386456, -12.448299643936515],
              [-74.33827992311473, -12.449818320556885],
              [-74.34226235948728, -12.45025303603782],
              [-74.34587083985444, -12.449149555014102],
              [-74.34986135800702, -12.449575135689068],
              [-74.35335519662857, -12.451093621213285],
              [-74.35557584734403, -12.454010629509149],
              [-74.35588823143895, -12.457926804981328],
              [-74.35536196139518, -12.462374490783874],
              [-74.35463689411444, -12.46557382544247],
              [-74.35314951778243, -12.470037288943018],
              [-74.35107409205132, -12.473893476710712],
              [-74.34841925694623, -12.477133353456665],
              [-74.34602372643894, -12.479234469576204],
              [-74.34225437364853, -12.481015727848083],
              [-74.33915705373086, -12.481993793596583],
              [-74.33933995093521, -12.485394229229472],
              [-74.34067535029394, -12.489068760366424],
              [-74.34371209304545, -12.490477760302777],
              [-74.34748868850656, -12.49182522163374],
              [-74.34892259121071, -12.495038786737798],
              [-74.34865550154694, -12.498338663766619],
              [-74.34718321016688, -12.50376965622099],
              [-74.345672560514, -12.507274486729578],
              [-74.34365078959648, -12.510895612797267],
              [-74.34168245292085, -12.514290788167367],
              [-74.34006522512216, -12.51825648472712],
              [-74.33828745341953, -12.522899960091443],
              [-74.33585350592699, -12.528346580112814],
              [-74.33390027434218, -12.53270926560856],
              [-74.33161917660819, -12.537478028119022],
              [-74.32998632599546, -12.540467017718697],
              [-74.3285218176051, -12.543745774725053],
              [-74.32656833581309, -12.548108389819857],
              [-74.32534006424699, -12.551432928396963],
              [-74.32354761943618, -12.555108692625948],
              [-74.32187633560444, -12.559291124459818],
              [-74.3201292512403, -12.562740912501598],
              [-74.3184282233429, -12.568125883096823],
              [-74.31755094176665, -12.572011894251425],
              [-74.31626141757803, -12.575562278694544],
              [-74.31487282296726, -12.5795735640428],
              [-74.31302648227638, -12.583475155824415],
              [-74.31003577216897, -12.587120596219709],
              [-74.30758649567281, -12.589447209806904],
              [-74.30450415419655, -12.59138333083524],
              [-74.30050687281394, -12.593118090763026],
              [-74.29588343089739, -12.594462318608523],
              [-74.29242708214448, -12.594878190106893],
              [-74.28866563716278, -12.594506519944659],
              [-74.28649878502627, -12.591363051530601],
              [-74.28425576449087, -12.587486932998857],
              [-74.28299693899811, -12.58455384066588],
              [-74.2797546887072, -12.581895728470869],
              [-74.27523825635039, -12.580617613576683],
              [-74.27173598586026, -12.581259160189695],
              [-74.2688067380048, -12.582517104013982],
              [-74.26607529236904, -12.585023397393606],
              [-74.26299272101558, -12.586959026984415],
              [-74.25991065410376, -12.588903662054966],
              [-74.2572862516354, -12.590948994935045],
              [-74.2537458353691, -12.59278377324272],
              [-74.2499009801009, -12.59383096914749],
              [-74.24640654722333, -12.592302044902022],
              [-74.24377446462256, -12.5890665246356],
              [-74.24262217106411, -12.585672411220727],
              [-74.24277489557021, -12.581857000193375],
              [-74.24332419342906, -12.578377273049774],
              [-74.24425504502447, -12.574265659345475],
              [-74.24523945293372, -12.569928133000548],
              [-74.24623132916079, -12.565581578570084],
              [-74.24517821007802, -12.561735637940632],
              [-74.24242425169294, -12.557984360862326],
              [-74.23999773876575, -12.55598819192113],
              [-74.23690786049089, -12.554803923129862],
              [-74.2335817304448, -12.553564691778764],
              [-74.23008733469399, -12.552044627473117],
              [-74.22671515510459, -12.551031239215506],
              [-74.2237245835214, -12.549385955191322],
              [-74.22111505778571, -12.547108787128147],
              [-74.21813204779619, -12.545463466767957],
              [-74.2155226119453, -12.543186248919056],
              [-74.21274552399669, -12.540628206722202],
              [-74.2102434352044, -12.537890102056995],
              [-74.20774082367613, -12.535151974998483],
              [-74.20506307414284, -12.5321420418211],
              [-74.20289602934369, -12.5289979661139],
              [-74.20113353575755, -12.526189640341709],
              [-74.19895908008105, -12.523054557349532],
              [-74.19719724224468, -12.520246211293053],
              [-74.19569378318887, -12.516299306374155],
              [-74.19482419805426, -12.51273406546468],
              [-74.19407672765935, -12.509675552999212],
              [-74.19185634479696, -12.506766340140707],
              [-74.1893005968659, -12.50425396005412],
              [-74.18654645739868, -12.502663266047206],
              [-74.18366223008036, -12.50056578714413],
              [-74.17960351996257, -12.499396054028683],
              [-74.17571995909442, -12.498507106072893],
              [-74.17196652642558, -12.498124866654834],
              [-74.16843436850849, -12.497797511724002],
              [-74.16361248776693, -12.49790013534666],
              [-74.15914139402734, -12.498555339794866],
              [-74.15496850287006, -12.500007114286502],
              [-74.15180982366745, -12.501208836325139],
              [-74.14768227701757, -12.504595653873213],
              [-74.14449312696095, -12.506990749441158],
              [-74.14211273618669, -12.51004845694306],
              [-74.14054855947597, -12.513777863952265],
              [-74.13971736916011, -12.517437265909093],
              [-74.1394498964732, -12.520736782479563],
              [-74.13984655418908, -12.526362245442833],
              [-74.13993096755618, -12.530223496437412],
              [-74.14046484899418, -12.534194689874111],
              [-74.14123559111454, -12.538211849959763],
              [-74.14215873712122, -12.541551337078813],
              [-74.1433638009725, -12.54471087916442],
              [-74.14512633229569, -12.547519550621812],
              [-74.14695724605194, -12.551060840576097],
              [-74.1486742121947, -12.554104442520288],
              [-74.1502075014896, -12.556858105839444],
              [-74.15064228620106, -12.561281075942906],
              [-74.15033697674748, -12.565783079123857],
              [-74.15019215000409, -12.569589346727405],
              [-74.14968898053388, -12.572842907723171],
              [-74.15037527539288, -12.578288429816407],
              [-74.15068804163162, -12.582204650628013],
              [-74.1499098085833, -12.585638176943705],
              [-74.15067274404352, -12.589655288465039],
              [-74.15347296569014, -12.593181537170102],
              [-74.15618158383607, -12.595007556455936],
              [-74.1597443005795, -12.599422277381391],
              [-74.16139998067678, -12.602691675678734],
              [-74.16206381436687, -12.60716959995597],
              [-74.16141520691596, -12.611100880168589],
              [-74.16057584741307, -12.614760294166619],
              [-74.15898903658517, -12.617522183025804],
              [-74.15775309143964, -12.620845776305927],
              [-74.15697483010808, -12.62427026996877],
              [-74.15625022675516, -12.627468876937414],
              [-74.15666182558897, -12.630933294374696],
              [-74.15638744862277, -12.634232784790786],
              [-74.15560908749075, -12.637666309338394],
              [-74.15368641140167, -12.640824937209628],
              [-74.1511155166999, -12.64264325926021],
              [-74.14762106152261, -12.644250634790716],
              [-74.14297510488879, -12.644624543588655],
              [-74.13967160277588, -12.644351681207146],
              [-74.1364743958422, -12.64361797935599],
              [-74.13269054754515, -12.64227651675994],
              [-74.1292650844412, -12.641496778319103],
              [-74.12544994272965, -12.638211083596662],
              [-74.12287909608486, -12.634739552547918],
              [-74.1191940654852, -12.629808562281172],
              [-74.11621079763508, -12.626001060118876],
              [-74.11358647545019, -12.622755324602046],
              [-74.11040511863153, -12.619869390804059],
              [-74.10649869595458, -12.618011783593087],
              [-74.10330193303686, -12.617277710576003],
              [-74.09941869326396, -12.616387573048376],
              [-74.09622194457985, -12.615662459756866],
              [-74.09071376934764, -12.615598554324993],
              [-74.08608981456736, -12.614778040126394],
              [-74.08233661946082, -12.614403494899703],
              [-74.07784267639778, -12.614089634786048],
              [-74.0727309286117, -12.61437032143606],
              [-74.06752033112866, -12.615093617019676],
              [-74.06259170662486, -12.615646003069186],
              [-74.05823506756973, -12.616815161245773],
              [-74.05406206840536, -12.618265193685886],
              [-74.05080433839046, -12.619917333561725],
              [-74.04911050770558, -12.623139244211302],
              [-74.04753847393158, -12.626867917966134],
              [-74.04571526552668, -12.629573953399673],
              [-74.04233550821759, -12.630719196689922],
              [-74.04099272166678, -12.63450289389142],
              [-74.03873456115328, -12.638075320528397],
              [-74.0373917103229, -12.641849949274047],
              [-74.0354692992786, -12.645016670300024],
              [-74.03360009058815, -12.647948479964453],
              [-74.03114326399503, -12.650272337156665],
              [-74.02825955926058, -12.653462621221621],
              [-74.02547482348179, -12.656183058944272],
              [-74.02256013536476, -12.65840568936737],
              [-74.01998879322119, -12.660222669685467],
              [-74.01725770885564, -12.662717162988717],
              [-74.01357259584458, -12.666202679258177],
              [-74.01061250817403, -12.668651036619634],
              [-74.0060731117197, -12.671699254073069],
              [-74.00316617759668, -12.673912632608992],
              [-74.00030496654611, -12.675900105177657],
              [-73.99694078243513, -12.678012229771259],
              [-73.99237839673656, -12.680092643135842],
              [-73.98832694052061, -12.68204833477069],
              [-73.9850691238202, -12.683708618017425],
              [-73.98139935948711, -12.685032738736739],
              [-73.97811888596114, -12.685716348109224],
              [-73.97478508274574, -12.686634791877987],
              [-73.97099295019525, -12.687451959472295],
              [-73.96788795356585, -12.688425448798883],
              [-73.96470650799019, -12.688657191866094],
              [-73.96153254177689, -12.688888925623786],
              [-73.96011353625312, -12.691930385234892],
              [-73.96123513725527, -12.696509811685635],
              [-73.96215056218604, -12.699849733065403],
              [-73.96377579340475, -12.704304538777574],
              [-73.96520981293637, -12.707519895375745],
              [-73.96691915542384, -12.710555487129186],
              [-73.96875021119277, -12.714106913725075],
              [-73.97018437705314, -12.717313208701766],
              [-73.97106926326528, -12.721846478535392],
              [-73.9710235118369, -12.725200727598514],
              [-73.9700696297389, -12.7283525313812],
              [-73.96731590619542, -12.72987887599201],
              [-73.96526330431645, -12.732538125034846],
              [-73.96384405750094, -12.735579579474896],
              [-73.96735401659708, -12.740231615781324],
              [-73.97016930940715, -12.744727203400323],
              [-73.97193913415245, -12.747536973263323],
              [-73.9744341118591, -12.750268172654463],
              [-73.97740955607946, -12.754077158333008],
              [-73.9784777495843, -12.758891407861926],
              [-73.97814922502891, -12.762425393715391],
              [-73.98154441852505, -12.764400526289503],
              [-73.98474137826524, -12.765136159180223],
              [-73.98886099288289, -12.766083244919164],
              [-73.99176031919825, -12.769150390241485],
              [-73.99400303329696, -12.77302875162188],
              [-73.99550626474169, -12.776976617215087],
              [-73.99626936764412, -12.781003036393187],
              [-73.99719242225042, -12.784333858242105],
              [-74.0002745805601, -12.78769095447951],
              [-74.00345611863445, -12.787458872007848],
              [-74.00630941356067, -12.785471438125692],
              [-74.00922403704341, -12.78324911769478],
              [-74.01180251114974, -12.781432376469688],
              [-74.01502975592048, -12.780974277858478],
              [-74.01725018688354, -12.783884929839424],
              [-74.01869938544975, -12.788067571583968],
              [-74.02014923782956, -12.79224116813756],
              [-74.02163709428946, -12.795230442240538],
              [-74.02472690477282, -12.796417309742695],
              [-74.02866345461827, -12.797082847870529],
              [-74.03205127491688, -12.795937976588794],
              [-74.03485869840442, -12.794176099907581],
              [-74.03782645478782, -12.791727606256751],
              [-74.04032872024685, -12.789177922416732],
              [-74.04202276071594, -12.785956189578007],
              [-74.04275511665932, -12.782758079781818],
              [-74.0434874513534, -12.779559965881983],
              [-74.04608944761591, -12.781838858799535],
              [-74.04750816322307, -12.78721477234474],
              [-74.04781362164397, -12.791130943698697],
              [-74.04907226982965, -12.79406500636651],
              [-74.05455789023647, -12.795323456488203],
              [-74.05935666487491, -12.796416593907795],
              [-74.06288921063857, -12.796745639356407],
              [-74.06755805826997, -12.79733183028453],
              [-74.07081588805404, -12.797840634351815],
              [-74.0743639528479, -12.799137053810258],
              [-74.07779718571732, -12.803046103194536],
              [-74.07974264698763, -12.806136157799004],
              [-74.08048996812973, -12.809185852004315],
              [-74.08061956361492, -12.812830146110159],
              [-74.08070345544967, -12.81669128265803],
              [-74.07997144150212, -12.819889516234841],
              [-74.07908603059394, -12.823774384028825],
              [-74.07711050248008, -12.827167166737773],
              [-74.07450110684756, -12.83017794566699],
              [-74.07077803825729, -12.83172903713934],
              [-74.06709272761873, -12.83208669642196],
              [-74.06317149735054, -12.832389212245758],
              [-74.05790714079188, -12.833346904495427],
              [-74.05421431465766, -12.83370435343257],
              [-74.05019400194286, -12.834467443234798],
              [-74.04560854268627, -12.835581044206897],
              [-74.04267880388055, -12.836836224482116],
              [-74.03989432957806, -12.839565694073306],
              [-74.03789534728985, -12.841990590925855],
              [-74.03562150346414, -12.8445952936915],
              [-74.03304311647261, -12.846403186239211],
              [-74.02998325425706, -12.847151399807103],
              [-74.02622223261022, -12.846766772380745],
              [-74.02239984744118, -12.843488541842937],
              [-74.02058420726026, -12.840904914114601],
              [-74.02040099000254, -12.837504578562523],
              [-74.01654044198574, -12.837580574582413],
              [-74.01277948254163, -12.840324162880059],
              [-74.00964383522796, -12.84249138714584],
              [-74.00643157374212, -12.84391686961249],
              [-74.00302147892882, -12.844093820818978],
              [-74.00044227661881, -12.8459104160552],
              [-73.99771118877909, -12.848404520897846],
              [-73.99495679023407, -12.84994009867128],
              [-73.99229441903469, -12.847886502146213],
              [-73.99186686913124, -12.843463541231074],
              [-73.98936455440139, -12.840723376938545],
              [-73.9863202419183, -12.839310208737789],
              [-73.9818040324656, -12.838026931108907],
              [-73.97859981070481, -12.837300214765285],
              [-73.97479265831741, -12.837140724622436],
              [-73.97126033300573, -12.836810467045352],
              [-73.96698768328952, -12.836558626955075],
              [-73.96152485590468, -12.83626585153027],
              [-73.95788586559604, -12.836396109799749],
              [-73.95301804664547, -12.834550386033932],
              [-73.94985179265126, -12.832620965853142],
              [-73.94780742101695, -12.82999077012326],
              [-73.94609071812216, -12.82695500939418],
              [-73.94489284369675, -12.823794741285418],
              [-73.93968964501352, -12.822363354343429],
              [-73.93605033040036, -12.822484281516045],
              [-73.93260956847925, -12.82386272045455],
              [-73.92797865010722, -12.825200097323256],
              [-73.92453781479296, -12.826578423981662],
              [-73.92104357944208, -12.828182518040135],
              [-73.91934201865053, -12.831394259897834],
              [-73.91937232762444, -12.835490126242282],
              [-73.91990648961173, -12.83946148410812],
              [-73.92044066330793, -12.843432841046608],
              [-73.9208526550748, -12.84688833694959],
              [-73.921440221673, -12.850633877313996],
              [-73.92192105635998, -12.854831046502404],
              [-73.92227908657091, -12.858521393929319],
              [-73.92202762259626, -12.862787883575185],
              [-73.92124180920125, -12.866211327079348],
              [-73.9189149527703, -12.869040727734852],
              [-73.91492487139372, -12.868608342227386],
              [-73.9103471233747, -12.867558645143012],
              [-73.90665462630173, -12.864785672102954],
              [-73.90441894565416, -12.86090666003139],
              [-73.9027406189948, -12.856677353167232],
              [-73.89885702930457, -12.855784078560994],
              [-73.89556895444774, -12.856475481578808],
              [-73.89161676264185, -12.857968711434005],
              [-73.88779471975506, -12.85997778204388],
              [-73.8848112137701, -12.861456924090092],
              [-73.88222477314648, -12.863263206821717],
              [-73.8781201509722, -12.86545174367739],
              [-73.87629678137905, -12.868156306198621],
              [-73.87545802973584, -12.871814407076272],
              [-73.87508417410064, -12.875573951977996],
              [-73.87408453067076, -12.87990944352185],
              [-73.8719866091517, -12.882793605359495],
              [-73.8675919003467, -12.882024221290186],
              [-73.8650742627877, -12.878324518108958],
              [-73.86435674922174, -12.874072048686761],
              [-73.86445641620809, -12.870483126233042],
              [-73.8624190511339, -12.86785234731741],
              [-73.8587796682473, -12.867981190359824],
              [-73.85567500915414, -12.86894410481731],
              [-73.84964747536394, -12.869008137570166],
              [-73.84621445241686, -12.868215512838276],
              [-73.84180439176528, -12.866478252868227],
              [-73.83856952995268, -12.866943124042637],
              [-73.8348464505876, -12.868490718690847],
              [-73.83158126696672, -12.87014879108783],
              [-73.82775867162711, -12.87214788814722],
              [-73.8245619795525, -12.874538498648032],
              [-73.8225095554638, -12.877196400659917],
              [-73.82179261275704, -12.881361109494286],
              [-73.82159402274229, -12.885401571548437],
              [-73.8213653875687, -12.888474503123534],
              [-73.82098377353091, -12.892233864557372],
              [-73.81895430602233, -12.895850174850654],
              [-73.81285884276848, -12.895171636235885],
              [-73.80805966409733, -12.894073938187946],
              [-73.80439750505751, -12.893225459519899],
              [-73.79982743574205, -12.892173867861837],
              [-73.79489145912792, -12.89272980917798],
              [-73.79165626528165, -12.893185022371153],
              [-73.78735377976368, -12.894114421658726],
              [-73.78205118667678, -12.896268661714936],
              [-73.77866342672901, -12.89741010835914],
              [-73.7755506868988, -12.898371977495467],
              [-73.77181248825764, -12.898960194541143],
              [-73.7672957293486, -12.90080137238007],
              [-73.76436638738193, -12.902053273264809],
              [-73.7596205065557, -12.903848021551735],
              [-73.75445534909252, -12.904338798745954],
              [-73.75069419457469, -12.903959222936566],
              [-73.74661237106406, -12.901815096660526],
              [-73.74266052255501, -12.900186863468939],
              [-73.73841872978238, -12.898728951881392],
              [-73.73522167138195, -12.897990250808519],
              [-73.7308501003327, -12.898186058022432],
              [-73.72740167667487, -12.899552542657489],
              [-73.72421236873825, -12.90195088826221],
              [-73.72119153856192, -12.904621235386713],
              [-73.71804828079387, -12.906775619455669],
              [-73.71444694254183, -12.908837300989294],
              [-73.71042656154295, -12.909594998760818],
              [-73.70761121689128, -12.911353069985077],
              [-73.70598611262797, -12.915304849801595],
              [-73.70520007304492, -12.918727374217788],
              [-73.70523850233766, -12.923790345217899],
              [-73.70496343245232, -12.92708882783219],
              [-73.70551286937963, -12.932027761371401],
              [-73.70639795141521, -12.936561528228674],
              [-73.70713803990965, -12.939611904317852],
              [-73.7085115457908, -12.94305416617982],
              [-73.70993809508873, -12.946270668850259],
              [-73.71085361533889, -12.949611208625207],
              [-73.71182278841232, -12.952716954111336],
              [-73.7129591881797, -12.958264507460195],
              [-73.71326458180148, -12.962180630606122],
              [-73.71442440134447, -12.966543967225602],
              [-73.71595777912134, -12.96929990294355],
              [-73.71777327061636, -12.971876408607063],
              [-73.71976477061219, -12.974743072681411],
              [-73.72185488761667, -12.977149134041836],
              [-73.72541030989822, -12.981578444263523],
              [-73.72779069291406, -12.983805075110041],
              [-73.73176586198504, -12.98640110161346],
              [-73.7368852416646, -12.989264818671831],
              [-73.7408902364748, -12.990667486681865],
              [-73.74432386280056, -12.991461605490455],
              [-73.74768056337412, -12.991513967041874],
              [-73.75370797186964, -12.991452536981285],
              [-73.75910975742443, -12.991017307060662],
              [-73.7631302406451, -12.99025896395906],
              [-73.76738729619534, -12.989546882748241],
              [-73.77056906808936, -12.989317850905717],
              [-73.77561170867489, -12.988320039047933],
              [-73.78070847346835, -12.987087317015671],
              [-73.78540061096119, -12.985518087615718],
              [-73.78935248479756, -12.984026690605791],
              [-73.79386149741666, -12.982176177657081],
              [-73.79730966748951, -12.980808887011902],
              [-73.80064385811644, -12.979893068657782],
              [-73.80409258605283, -12.978516643732842],
              [-73.80821980888628, -12.977306016509905],
              [-73.81168346245775, -12.976896936701252],
              [-73.81237010424702, -12.977053769015463],
              [-73.8123094704739, -12.985696660769266],
              [-73.8101730957015, -12.989773406058262],
              [-73.80663280431381, -12.994729694997202],
              [-73.80316946888905, -12.998266850563443],
              [-73.79802700896694, -13.002853970939329],
              [-73.79480005310664, -13.006437292265977],
              [-73.79110730517854, -13.009918945681573],
              [-73.78635412961088, -13.013874745079182],
              [-73.78157038003148, -13.016862928468449],
              [-73.77641287838159, -13.020482108782364],
              [-73.77191157899624, -13.023290608221275],
              [-73.76857740169432, -13.027334117501272],
              [-73.76593043844716, -13.031534540889549],
              [-73.7643736165051, -13.03622833336656],
              [-73.76316088052069, -13.041484282195881],
              [-73.76229075804743, -13.046325981781681],
              [-73.76113897423609, -13.05135618163284],
              [-73.75650013902356, -13.055818144066144],
              [-73.75157912010643, -13.057331365604847],
              [-73.74575822160462, -13.056471457208186],
              [-73.74027255940206, -13.055206222327977],
              [-73.7355805900538, -13.0567744914702],
              [-73.73107935428374, -13.059582177996742],
              [-73.72770678692007, -13.064818229530726],
              [-73.72474676098388, -13.070390762733417],
              [-73.72128298718124, -13.07391757248642],
              [-73.71668259808727, -13.07719458440527],
              [-73.71200586808067, -13.07972075257649],
              [-73.70664221514375, -13.082089697050987],
              [-73.70221703077482, -13.085638513414857],
              [-73.69835643860199, -13.09196553770049],
              [-73.69529743068595, -13.09895649911634],
              [-73.69442737923757, -13.103806857916044],
              [-73.69360344032211, -13.108431429835615],
              [-73.69351948956621, -13.112978384056934],
              [-73.69168076168367, -13.117841886026415],
              [-73.68549346535575, -13.118578819525833],
              [-73.68024471029565, -13.118335001907449],
              [-73.67498789806133, -13.117123708396734],
              [-73.66996028721178, -13.115967738339634],
              [-73.66485602818834, -13.114069962495854],
              [-73.66127018561403, -13.117088928456932],
              [-73.65901924174281, -13.121624537626706],
              [-73.65740950053112, -13.126543159965534],
              [-73.6549528459267, -13.131981680093348],
              [-73.65247362854575, -13.136461767913017],
              [-73.65010055886493, -13.140490361787938],
              [-73.64796468694935, -13.144565374951368],
              [-73.64467596355415, -13.148372115107797],
              [-73.64017491839503, -13.151186994449306],
              [-73.63575720080111, -13.152573860537235],
              [-73.6307828517977, -13.154310250229418],
              [-73.62645704697688, -13.157397005203313],
              [-73.62195565119934, -13.160211526725277],
              [-73.61803423882549, -13.16363477747607],
              [-73.61485286061084, -13.16698963087596],
              [-73.61167139509942, -13.170344435376425],
              [-73.60855128553786, -13.176601424885817],
              [-73.60678836667279, -13.183164064352464],
              [-73.60688039421981, -13.187992018597622],
              [-73.6080091311959, -13.193548821041192],
              [-73.60949691764839, -13.199659021889188],
              [-73.61029076128517, -13.205611768123633],
              [-73.61140432569309, -13.210201182082745],
              [-73.61286139136168, -13.215343907542769],
              [-73.61323550488306, -13.223129527689267],
              [-73.61332701648955, -13.227957475943759],
              [-73.61228208477219, -13.232526126957035],
              [-73.61003131659953, -13.23706108753783],
              [-73.60612483284228, -13.241451382033537],
              [-73.60123450403597, -13.244886999492769],
              [-73.59626035083086, -13.246622509792985],
              [-73.59052300165202, -13.24746777395088],
              [-73.58390034291565, -13.246906784982343],
              [-73.57818595095031, -13.24558228569813],
              [-73.57324226528385, -13.246124217377032],
              [-73.5685961308618, -13.247463240345413],
              [-73.56416310285672, -13.25100912890689],
              [-73.55962347244882, -13.255006323253868],
              [-73.55640417779456, -13.259553297028607],
              [-73.55191803318827, -13.263333668512399],
              [-73.54706597325321, -13.265575075444684],
              [-73.54146583293412, -13.267893459038708],
              [-73.53642296349663, -13.272013844664059],
              [-73.53256965344147, -13.276177129065768],
              [-73.52912142538126, -13.280677155533427],
              [-73.52567312084983, -13.285168080977703],
              [-73.52127083973959, -13.287520009404338],
              [-73.51632686524864, -13.288060737543992],
              [-73.51041422235548, -13.288622817580668],
              [-73.50602723387597, -13.291932763094007],
              [-73.50279214491661, -13.295520595915137],
              [-73.5014191159413, -13.301451607089966],
              [-73.50009931380802, -13.307156922947303],
              [-73.49813067166512, -13.311511472311103],
              [-73.49228644684463, -13.315933415609338],
              [-73.48509243526155, -13.317879326798266],
              [-73.47849295590608, -13.318282810023211],
              [-73.47319015240686, -13.31826001885937],
              [-73.46796403985385, -13.318978825563885],
              [-73.46376053223355, -13.322569253627355],
              [-73.46069347130422, -13.326428641168485],
              [-73.45826710272421, -13.330680574981807],
              [-73.45510114816088, -13.335000287681021],
              [-73.45252968852361, -13.34089645193372],
              [-73.45047001593767, -13.346678191135913],
              [-73.4500959946259, -13.351403560160094],
              [-73.44944736785855, -13.35629899460447],
              [-73.45021813676053, -13.36128447158237],
              [-73.44934068567922, -13.366133300938396],
              [-73.44492342102984, -13.367516352588614],
              [-73.4396364846076, -13.368460066754306],
              [-73.43409746092405, -13.370550112316113],
              [-73.43016830454236, -13.373969774932217],
              [-73.42976355422118, -13.379888068767036],
              [-73.43146515520871, -13.386054463548271],
              [-73.43109869675042, -13.390779793178117],
              [-73.42669704820896, -13.396248360901177],
              [-73.42104353753271, -13.39878929220229],
              [-73.4164274307994, -13.402050700484098],
              [-73.41278081152397, -13.405300030692038],
              [-73.41007226824313, -13.409721279482723],
              [-73.40735611758328, -13.414142439276072],
              [-73.40624985197512, -13.418944348900292],
              [-73.40517416173435, -13.425662766261494],
              [-73.4045868935115, -13.431299551495268],
              [-73.40348051562528, -13.436092388878546],
              [-73.40407556885229, -13.440805613571497],
              [-73.40892001473746, -13.443846353804412],
              [-73.41530612330178, -13.444365722121736],
              [-73.42105125805435, -13.443525012856036],
              [-73.42557537083857, -13.438563634515686],
              [-73.42725385801508, -13.433421694151313],
              [-73.42742898648451, -13.427447774253695],
              [-73.42854292109838, -13.422654821765882],
              [-73.42959589004226, -13.418087462687728],
              [-73.43174732957867, -13.414015052052017],
              [-73.4350205077188, -13.409244501638822],
              [-73.43958255619869, -13.403080755197475],
              [-73.44460301063118, -13.397028196551766],
              [-73.44837203783312, -13.391166694723374],
              [-73.45137760133697, -13.387533188968545],
              [-73.45553611083467, -13.384159790591891],
              [-73.46185312456079, -13.380808492256525],
              [-73.46715569586321, -13.37770397721976],
              [-73.47062693642444, -13.374181489273239],
              [-73.47125997692787, -13.368318640597439],
              [-73.47178626660745, -13.362907118741438],
              [-73.47437290129571, -13.357969031533873],
              [-73.47895054103175, -13.355899383168484],
              [-73.48645004208005, -13.35475108343682],
              [-73.49190506940242, -13.354087570574176],
              [-73.49655126118782, -13.35275020202947],
              [-73.50220510326324, -13.353335116847765],
              [-73.50931560408728, -13.355944341497162],
              [-73.51410675474895, -13.356090013959871],
              [-73.51711265733684, -13.352455575895112],
              [-73.5195767453006, -13.34700997573136],
              [-73.52114123597008, -13.342318432674492],
              [-73.5163727541791, -13.340012552050565],
              [-73.51232146848422, -13.336680268761762],
              [-73.5161056808606, -13.331775774465717],
              [-73.522758238496, -13.331154697274306],
              [-73.52907548739509, -13.33092924958881],
              [-73.5339739786703, -13.33061409046367],
              [-73.53819289771276, -13.327980722337772],
              [-73.54097744300564, -13.324290410013647],
              [-73.5424730337144, -13.318866048094469],
              [-73.54368599490817, -13.313611805932092],
              [-73.54681377796554, -13.307365082029085],
              [-73.55096444758925, -13.303989782235627],
              [-73.55536675867947, -13.301646383318342],
              [-73.56064626144003, -13.30070001723743],
              [-73.565750401354, -13.302591094141494],
              [-73.57022108245766, -13.304107874407737],
              [-73.57431811570065, -13.300966893509404],
              [-73.57783484219884, -13.297207870821634],
              [-73.5829241681956, -13.295012437579778],
              [-73.58908085380884, -13.295471548193959],
              [-73.59362813690228, -13.297720588176567],
              [-73.59874721007152, -13.297459566031584],
              [-73.60265366014943, -13.293069498863469],
              [-73.60638447438447, -13.288398151894675],
              [-73.61032115923608, -13.285942689212893],
              [-73.61508957328513, -13.285118909392587],
              [-73.6201780078055, -13.286050562392722],
              [-73.62496922754163, -13.286194016432214],
              [-73.63034834310412, -13.284785632680178],
              [-73.63452159228892, -13.282376240753715],
              [-73.63945742044133, -13.27871425724697],
              [-73.6445619385076, -13.274356962389986],
              [-73.6482922677675, -13.26968489152053],
              [-73.650871439018, -13.26474449265047],
              [-73.65333561923785, -13.259297193132669],
              [-73.65549484570798, -13.253061744636245],
              [-73.65721142525555, -13.247691805702244],
              [-73.6609192536525, -13.242052049325313],
              [-73.66506959332413, -13.238683659792713],
              [-73.67085251773291, -13.234482939563113],
              [-73.6764677803727, -13.23000094895819],
              [-73.6790619950185, -13.226036602143422],
              [-73.68166366790912, -13.222063218408817],
              [-73.68345621780658, -13.217425684918776],
              [-73.6911313209745, -13.219670949854025],
              [-73.69416022472663, -13.22325741272561],
              [-73.69546494130395, -13.229086121670925],
              [-73.69431292398903, -13.234115761746017],
              [-73.69199379415804, -13.241028863428431],
              [-73.68904897001691, -13.247568058485742],
              [-73.68614964927882, -13.253872386571866],
              [-73.68261715217825, -13.2597936986929],
              [-73.68041992799775, -13.26409459437399],
              [-73.68354036382901, -13.269381271374732],
              [-73.68865944960302, -13.27224635239161],
              [-73.69544216949141, -13.275246432782875],
              [-73.7010576711801, -13.277019936926413],
              [-73.70598589562069, -13.278636144696375],
              [-73.71009059417345, -13.281748807296],
              [-73.71249397574547, -13.288071215409358],
              [-73.71408060893971, -13.293729527125505],
              [-73.71533983630762, -13.299783959164126],
              [-73.71636223748375, -13.305791989285702],
              [-73.71652964061948, -13.311361756937615],
              [-73.71600320931918, -13.31677432809584],
              [-73.71536234017613, -13.321671009511231],
              [-73.71420260409846, -13.326700663598016],
              [-73.7130434355257, -13.331721268827275],
              [-73.71298194569934, -13.337235618799623],
              [-73.71659085955619, -13.341439631059112],
              [-73.72307588734621, -13.34365112698036],
              [-73.72863796186611, -13.342521904107231],
              [-73.73359656355301, -13.33981649557589],
              [-73.737800378914, -13.33621221450279],
              [-73.74031090269015, -13.330538211629124],
              [-73.74192826618177, -13.325619150451427],
              [-73.7441251136657, -13.321317695226908],
              [-73.75100679779933, -13.320736885727886],
              [-73.75375358928781, -13.324493177483644],
              [-73.75877408834565, -13.324689548778073],
              [-73.76367952959826, -13.32220924476095],
              [-73.76724988380315, -13.318221617621948],
              [-73.77046177819092, -13.313671675945807],
              [-73.77523049141317, -13.312844893587428],
              [-73.77856421473773, -13.31720949553075],
              [-73.78056340677378, -13.323204012635168],
              [-73.7816771468164, -13.327783928575185],
              [-73.78098269615691, -13.33291567901845],
              [-73.78061683315399, -13.337642174762502],
              [-73.77996850077311, -13.342539079921167],
              [-73.77996041614763, -13.347827809634964],
              [-73.78028097051137, -13.352711291622144],
              [-73.78034185733947, -13.35874168104856],
              [-73.77993032748392, -13.363693957860125],
              [-73.7788617450257, -13.37042406114903],
              [-73.77744272808872, -13.376591940641998],
              [-73.77539076554015, -13.382367992766149],
              [-73.77362062407221, -13.38797361452103],
              [-73.77139269294197, -13.393468501903609],
              [-73.76937125058143, -13.398060309325043],
              [-73.76816539953633, -13.403306993588158],
              [-73.76665524105556, -13.407774691633783],
              [-73.76281728911114, -13.412899969567883],
              [-73.75683619799761, -13.415853936155518],
              [-73.75228137347374, -13.41889617358284],
              [-73.74848202173293, -13.422828036980182],
              [-73.74577324882047, -13.427253446559838],
              [-73.74359157115718, -13.43253124953267],
              [-73.74238608572314, -13.43777773842011],
              [-73.74122611843232, -13.442807472027312],
              [-73.7380597630941, -13.447121988088366],
              [-73.73477178817323, -13.450938618858261],
              [-73.7313079593817, -13.454474057405417],
              [-73.72744001362399, -13.457663859384624],
              [-73.72299968911369, -13.461212301205908],
              [-73.71659086367318, -13.462870216587682],
              [-73.71067799206334, -13.463427584271948],
              [-73.70558190918062, -13.462497364937787],
              [-73.7004240156495, -13.460834461476706],
              [-73.69524378095835, -13.45819499096323],
              [-73.68962080126339, -13.456420982869957],
              [-73.68407468400842, -13.4553885588568],
              [-73.67738319354088, -13.457215750558644],
              [-73.67076089028193, -13.459775377557591],
              [-73.66538989710561, -13.462151618834046],
              [-73.65910329083175, -13.46430588911194],
              [-73.65429697652932, -13.466323279336335],
              [-73.64783505850284, -13.468205105860898],
              [-73.64209764694756, -13.469051060905132],
              [-73.63511635514219, -13.467919389800187],
              [-73.62802137057797, -13.466289706462591],
              [-73.6217882472852, -13.465089439928526],
              [-73.6173096104593, -13.463582279685898],
              [-73.61257175587859, -13.46008481162315],
              [-73.60908493900676, -13.456395307513322],
              [-73.60552228054841, -13.45196406397345],
              [-73.60203568648663, -13.448274481752131],
              [-73.59634425402096, -13.445756810286298],
              [-73.59070583624312, -13.446141140306475],
              [-73.5851595557236, -13.448234406157503],
              [-73.5806045276645, -13.451273284470885],
              [-73.57602686184156, -13.456463434082167],
              [-73.57453134980005, -13.461896970538854],
              [-73.57497396818329, -13.467287247257856],
              [-73.57733164915005, -13.471675908142522],
              [-73.58003235915083, -13.475668737909208],
              [-73.58358736577915, -13.480091241241984],
              [-73.5861816395543, -13.484544455362965],
              [-73.58657064462186, -13.490160426255498],
              [-73.58551780877256, -13.494728681352504],
              [-73.5827485152023, -13.499377630773637],
              [-73.57986426852987, -13.503528694016557],
              [-73.57616408136296, -13.506997090322903],
              [-73.57294463695068, -13.511552940171091],
              [-73.57018296962399, -13.516201747420071],
              [-73.56780258534612, -13.52021990655115],
              [-73.56428499517575, -13.523978400636041],
              [-73.56065366162258, -13.5281972087592],
              [-73.55688504183412, -13.530932636827618],
              [-73.55027748268505, -13.534456101905308],
              [-73.54541791499216, -13.536705904245935],
              [-73.54023756513149, -13.537190988320756],
              [-73.53366869263846, -13.539520960921354],
              [-73.52967065069173, -13.542209386104956],
              [-73.52685551506032, -13.547092307283371],
              [-73.5255659345673, -13.55160452568395],
              [-73.52211026834219, -13.556094885573396],
              [-73.5181658261961, -13.558548371294275],
              [-73.51387016415252, -13.56044828114453],
              [-73.50734690067817, -13.56255175299633],
              [-73.50150314138733, -13.563845563172642],
              [-73.49690275045285, -13.564947849967616],
              [-73.49149339426815, -13.565385276210282],
              [-73.48619089876695, -13.565362217298258],
              [-73.48097256887532, -13.56391132172581],
              [-73.48020155033926, -13.558925882116299],
              [-73.48162840422702, -13.552760597715421],
              [-73.48326132516743, -13.545683557020343],
              [-73.48458863839691, -13.539978644726705],
              [-73.48620595764135, -13.535061909036234],
              [-73.49022656202506, -13.530213903199451],
              [-73.49475121933877, -13.525251332308704],
              [-73.4974439704862, -13.519861853809356],
              [-73.49700140333408, -13.51447161947749],
              [-73.49285144006589, -13.511581148965524],
              [-73.48680877811698, -13.51476279906071],
              [-73.4822996005737, -13.517564885507324],
              [-73.47595199995925, -13.523076580273097],
              [-73.47176336075657, -13.527634053562489],
              [-73.46748343184473, -13.53361911154898],
              [-73.46395900330472, -13.537375976420705],
              [-73.45738960123158, -13.539703866731719],
              [-73.45172874978249, -13.542245104284177],
              [-73.44820395650896, -13.546001704464365],
              [-73.44734219025227, -13.551817696025086],
              [-73.44880675847524, -13.556961145926774],
              [-73.45059964631241, -13.561708897528943],
              [-73.45249952785706, -13.56599629771983],
              [-73.45442208544688, -13.571251041443697],
              [-73.45475786921531, -13.57710158946957],
              [-73.45484154121756, -13.581929126388493],
              [-73.45817566622608, -13.586306741116966],
              [-73.46195974625182, -13.590786532681914],
              [-73.46381350998983, -13.595299580993107],
              [-73.46195984837429, -13.600169406636034],
              [-73.4566571848508, -13.603273257482789],
              [-73.45249171687566, -13.60663715393961],
              [-73.44965334446525, -13.610551597671543],
              [-73.44849372944039, -13.615570229550817],
              [-73.44844049475473, -13.622051082697073],
              [-73.44796014615831, -13.627236645708473],
              [-73.44593040966934, -13.63181592568342],
              [-73.4425127805573, -13.635111944545718],
              [-73.43944541287195, -13.638979580230188],
              [-73.43602758953438, -13.6422754873017],
              [-73.43179317305022, -13.644906316659654],
              [-73.42761972340622, -13.647302430379497],
              [-73.4262769074401, -13.652048542616113],
              [-73.42681903514514, -13.656978345263049],
              [-73.42691059043958, -13.661814897639825],
              [-73.42665864959329, -13.667046993525528],
              [-73.42676519715758, -13.672841791749589],
              [-73.42623123195101, -13.678252873907416],
              [-73.42517071025473, -13.682819958792981],
              [-73.42062356931186, -13.686813427072828],
              [-73.41535194956562, -13.685595615827866],
              [-73.41049181922031, -13.684714786732222],
              [-73.40689106610448, -13.68772878560978],
              [-73.40331258502675, -13.691710047849838],
              [-73.39844510105814, -13.693956443543945],
              [-73.39363849788865, -13.692840603691522],
              [-73.38894630556494, -13.69224064437694],
              [-73.38249212970457, -13.691945454697585],
              [-73.37776929517095, -13.692538233658798],
              [-73.37290982782802, -13.691656670344871],
              [-73.3681638108029, -13.688154468409556],
              [-73.36665335923807, -13.683236360708586],
              [-73.36656943372866, -13.678399970495715],
              [-73.36814137813833, -13.673719112177341],
              [-73.37233706214184, -13.669154906812462],
              [-73.3775556110778, -13.664353253822767],
              [-73.38272099569576, -13.65977709600611],
              [-73.39118196231783, -13.654517152477535],
              [-73.39578994335234, -13.650289579707158],
              [-73.39721685385693, -13.64412509885641],
              [-73.3961639245205, -13.639309503376285],
              [-73.39674380298246, -13.63367285058666],
              [-73.40014653166169, -13.629410243213352],
              [-73.4049603846444, -13.624270797004643],
              [-73.40894323767363, -13.620626419408461],
              [-73.41201771937344, -13.616759227181946],
              [-73.41201780172837, -13.610513159619206],
              [-73.41415380663095, -13.605473875956209],
              [-73.4186246396404, -13.603857180446564],
              [-73.4235457578615, -13.60139356211898],
              [-73.42823040567082, -13.598865068635298],
              [-73.4314042801453, -13.594546233491446],
              [-73.43204522853088, -13.588683785169371],
              [-73.43232713591428, -13.582258637380637],
              [-73.43280011323802, -13.577073066239366],
              [-73.43361638081674, -13.571491916862785],
              [-73.43437221307576, -13.56613636184843],
              [-73.43717203902828, -13.560287398722968],
              [-73.43965901467047, -13.555810410282842],
              [-73.44151318554746, -13.550949788172675],
              [-73.44151293611974, -13.544694569685685],
              [-73.43933101093042, -13.540586142648543],
              [-73.43478402742275, -13.538334051692017],
              [-73.42724625096857, -13.537554580578721],
              [-73.42470525415914, -13.542257088419133],
              [-73.42543022793679, -13.54747731425964],
              [-73.41947148482421, -13.54609285599628],
              [-73.41531395273367, -13.543210135545658],
              [-73.41054525869563, -13.540901981372334],
              [-73.40451829192814, -13.538793622434365],
              [-73.3996887085381, -13.539838425441555],
              [-73.39812484297128, -13.544528778379682],
              [-73.3969571438998, -13.54954706468682],
              [-73.39582837480805, -13.556499966079402],
              [-73.39476751712436, -13.561066955969759],
              [-73.3925097253269, -13.565599113207337],
              [-73.38967107232368, -13.569503793049343],
              [-73.38484212741905, -13.573684803795144],
              [-73.37989062166439, -13.577349678838086],
              [-73.3757475806573, -13.581679556465886],
              [-73.36972066720678, -13.58581625391507],
              [-73.36568434495766, -13.589694674190326],
              [-73.35935183765179, -13.593033643392944],
              [-73.3548432500811, -13.59584214694745],
              [-73.3500138262342, -13.600013310654484],
              [-73.34729754140551, -13.604433409615675],
              [-73.34613060711847, -13.609460335164936],
              [-73.34626787006579, -13.614062052906531],
              [-73.34688576488155, -13.619733555728972],
              [-73.34526086031217, -13.624648950230762],
              [-73.34294903119859, -13.629406085157921],
              [-73.3417282604773, -13.634658570635827],
              [-73.34651935181762, -13.634798968862805],
              [-73.3524094060313, -13.632316239303021],
              [-73.3569409551133, -13.630475182852742],
              [-73.36231241740943, -13.628115311434025],
              [-73.36774431157534, -13.625520695136585],
              [-73.37296301427912, -13.623846531182553],
              [-73.37644971933202, -13.627539166647631],
              [-73.3773575551825, -13.633998957397326],
              [-73.3766553331122, -13.639128532148227],
              [-73.37439745759605, -13.64366038446215],
              [-73.36977397426598, -13.64692026236031],
              [-73.36508962112832, -13.649438444036692],
              [-73.35964186018886, -13.651065665283312],
              [-73.35424817098392, -13.652467144379834],
              [-73.34874730780709, -13.6543197306093],
              [-73.34423050035241, -13.657118797622067],
              [-73.33873005075337, -13.658971143689405],
              [-73.33377834192744, -13.659507376523402],
              [-73.33460221303503, -13.664267334140707],
              [-73.33486147370137, -13.669385051744337],
              [-73.33051312930675, -13.671497999653914],
              [-73.32530950302684, -13.671019991316289],
              [-73.3201978501687, -13.672241786857766],
              [-73.31642152500906, -13.67497297888919],
              [-73.3136137408428, -13.68082002208037],
              [-73.30968498070206, -13.684236990420297],
              [-73.30409245856649, -13.684388563732737],
              [-73.29873648386551, -13.684586828452936],
              [-73.29408245112886, -13.685910605346283],
              [-73.28909318129242, -13.68764774891629],
              [-73.2835081977953, -13.68778985979231],
              [-73.27758049857654, -13.68834523389228],
              [-73.27386460961668, -13.684595464508455],
              [-73.27372714798972, -13.6799938781336],
              [-73.27186583749102, -13.674512571356201],
              [-73.26852412899078, -13.66916553798435],
              [-73.26570901308877, -13.664662742185174],
              [-73.26121546591413, -13.662181831125704],
              [-73.25550095382853, -13.66494363086137],
              [-73.25129711142381, -13.662283819551106],
              [-73.25212887967884, -13.65767101807],
              [-73.254089341478, -13.65235211145211],
              [-73.25349431204675, -13.647647905901081],
              [-73.25119008376089, -13.643022052930599],
              [-73.24682621392829, -13.641048008024688],
              [-73.24210384482907, -13.638510972955347],
              [-73.24253092781825, -13.633560919894053],
              [-73.24697860643943, -13.630022140342371],
              [-73.25059495125258, -13.624841447404355],
              [-73.25313562271737, -13.620141208507572],
              [-73.2561340203595, -13.615543563548398],
              [-73.25949081888157, -13.611517803318211],
              [-73.25741560582811, -13.60694785489702],
              [-73.25320459770475, -13.604288128130166],
              [-73.2481690246376, -13.603113893743762],
              [-73.24332406400647, -13.603197257059579],
              [-73.23831932837017, -13.603957279709224],
              [-73.23512270505925, -13.607305979997895],
              [-73.22956867039478, -13.609381527042654],
              [-73.2236481860943, -13.609935872750395],
              [-73.2184675163295, -13.610414026362035],
              [-73.21379824135177, -13.610778242338977],
              [-73.20726006074536, -13.611906206989822],
              [-73.20248397913677, -13.61272135026765],
              [-73.197547901093, -13.61422214774617],
              [-73.19232914857973, -13.615901550744326],
              [-73.18739303290914, -13.617393092337869],
              [-73.18297573172353, -13.618779860873982],
              [-73.17807002414723, -13.619078384651802],
              [-73.17193614573826, -13.620542745693577],
              [-73.16721357223747, -13.621131615534411],
              [-73.16222403636658, -13.622857221888731],
              [-73.15794362473979, -13.625708697652307],
              [-73.15451789562768, -13.629009300088564],
              [-73.15133665485806, -13.63331488635804],
              [-73.15074929274712, -13.63895025904245],
              [-73.15157343795228, -13.643710408948044],
              [-73.15667736084508, -13.645619235251045],
              [-73.16126228616626, -13.646683579451452],
              [-73.16578653384228, -13.647982386162289],
              [-73.16938037265491, -13.651217452332562],
              [-73.16873151743094, -13.657078405251854],
              [-73.16698475207465, -13.661485139354316],
              [-73.16252153756542, -13.664055078006184],
              [-73.16002657358955, -13.66852849800314],
              [-73.16229269489784, -13.674347756960218],
              [-73.16436755155641, -13.678918332808532],
              [-73.16360448828154, -13.684272257814936],
              [-73.16012585885323, -13.687798391652716],
              [-73.15541814190303, -13.689345013716],
              [-73.14958195893271, -13.688469955254952],
              [-73.14204380230015, -13.687681413629285],
              [-73.13700093068135, -13.686513768792906],
              [-73.13115697955914, -13.684671174689788],
              [-73.12634264926102, -13.683550240635176],
              [-73.12081127193723, -13.683472172289594],
              [-73.11328137600789, -13.683649896313073],
              [-73.10501852829417, -13.68388504593162],
              [-73.09736623410865, -13.683555230018172],
              [-73.09259031423836, -13.68436803459245],
              [-73.08718086940776, -13.684796264592162],
              [-73.08233640242787, -13.684867242423808],
              [-73.07775131316116, -13.68380139113795],
              [-73.07286053355928, -13.680979775146143],
              [-73.06794715315135, -13.677190872742242],
              [-73.06480409246437, -13.673090369647824],
              [-73.0614089163309, -13.6679756545334],
              [-73.05955509807369, -13.662493397006655],
              [-73.05742634158035, -13.658147718943885],
              [-73.05290987562603, -13.653729078633056],
              [-73.04808042931381, -13.651639605035172],
              [-73.0430296724099, -13.649503114980066],
              [-73.03709438936569, -13.649085703940878],
              [-73.03251664346173, -13.64801918221372],
              [-73.02691633480403, -13.644070541998676],
              [-73.0253601197811, -13.639376766694552],
              [-73.02461268354064, -13.63223123183312],
              [-73.02374249505064, -13.627696546467646],
              [-73.02156823959777, -13.62358528438525],
              [-73.01826500547766, -13.620169937340279],
              [-73.01378648451427, -13.6186430322841],
              [-73.00920889431768, -13.617576167760626],
              [-73.00388327510477, -13.619702449692637],
              [-73.00038896323537, -13.625385978919697],
              [-72.99738299955838, -13.629979854791069],
              [-72.99386599167121, -13.634687036218894],
              [-72.99021165030675, -13.63792899639149],
              [-72.98661060062405, -13.640936352153913],
              [-72.98216233755645, -13.644469955805778],
              [-72.97753903339724, -13.647721881399782],
              [-72.97180170745125, -13.652636479842881],
              [-72.96723202803116, -13.655662681147978],
              [-72.96273037649765, -13.659430386096009],
              [-72.95813731935186, -13.664607025710675],
              [-72.95482663604686, -13.66841146300082],
              [-72.95198801605108, -13.672319150901613],
              [-72.94730348768192, -13.675795799524003],
              [-72.94317610469174, -13.677957562284277],
              [-72.93640913640463, -13.679030900439104],
              [-72.93163320007581, -13.679840536081521],
              [-72.92485816407286, -13.680904439380187],
              [-72.91724379605525, -13.682494266954631],
              [-72.91093440759398, -13.683670128329956],
              [-72.90591433221307, -13.683465057158873],
              [-72.89993311343913, -13.684236746019014],
              [-72.89413429134532, -13.685280939419497],
              [-72.8897780551501, -13.687394590671973],
              [-72.88590262036118, -13.69154552448704],
              [-72.88226346048327, -13.695743583983655],
              [-72.87816643589206, -13.699838239728223],
              [-72.87486271266617, -13.70364141701072],
              [-72.87065102195159, -13.708186755271356],
              [-72.86734763332942, -13.711989800045977],
              [-72.86553981962722, -13.716619004977453],
              [-72.86096193854675, -13.71964280766737],
              [-72.85600280451966, -13.723286720356809],
              [-72.85200484611765, -13.726929839854783],
              [-72.84841914716482, -13.73090178488612],
              [-72.84391035174156, -13.7346577561816],
              [-72.84048484889055, -13.73795321453716],
              [-72.83622003186846, -13.742732259753065],
              [-72.83263382051979, -13.74670389589248],
              [-72.82817855401011, -13.750234059199515],
              [-72.82422663650347, -13.75267516784957],
              [-72.8202665547382, -13.755116128921694],
              [-72.81578837608473, -13.756721080752952],
              [-72.81545237461931, -13.749904722837014],
              [-72.81600169528207, -13.744496836233198],
              [-72.8194275138076, -13.741210820178466],
              [-72.82389803318026, -13.738647833366418],
              [-72.82862859000437, -13.734939418197266],
              [-72.83285526617358, -13.731352976088017],
              [-72.83499915069225, -13.72632042790984],
              [-72.83129120100408, -13.722573916205862],
              [-72.82648474969345, -13.721447289859015],
              [-72.82028966995703, -13.72312766767565],
              [-72.8140638350908, -13.72600039929017],
              [-72.80918149733648, -13.727267383569757],
              [-72.80352044359236, -13.726665865061477],
              [-72.80207848841303, -13.721520436688051],
              [-72.80466472673174, -13.715624681360921],
              [-72.80854061405651, -13.711484323146594],
              [-72.81349956325579, -13.707832391823553],
              [-72.81973248104356, -13.70495063068677],
              [-72.82502749197, -13.700903783728895],
              [-72.82740754958158, -13.69591853408951],
              [-72.82851390095225, -13.690172049229888],
              [-72.82909372814431, -13.683571508924038],
              [-72.83027647802194, -13.678548582101584],
              [-72.83185567032264, -13.673872495618378],
              [-72.83303077540184, -13.668849470395292],
              [-72.8351671187412, -13.662849850907406],
              [-72.83660877427671, -13.65669955332251],
              [-72.83815740266378, -13.650080268427903],
              [-72.84007248936824, -13.645000354246775],
              [-72.84370407608118, -13.639826885138916],
              [-72.84785442829367, -13.635507270396417],
              [-72.85191327392681, -13.63260551603842],
              [-72.85820744069113, -13.630472996899629],
              [-72.86309047550483, -13.629204726267833],
              [-72.86936925749735, -13.629222493494508],
              [-72.87484729715639, -13.629540503154574],
              [-72.88072948587806, -13.630196200144297],
              [-72.88622267745237, -13.631481026446608],
              [-72.89080029689616, -13.632541084931908],
              [-72.8976669941523, -13.634144581991434],
              [-72.9029310699579, -13.635372902359293],
              [-72.90858480157517, -13.63597181829983],
              [-72.91325358474037, -13.63562249857496],
              [-72.91806025877057, -13.636738226455108],
              [-72.92166115987368, -13.640952487190974],
              [-72.92491142344646, -13.644594452651873],
              [-72.92951939960095, -13.64759705616149],
              [-72.93450937373625, -13.648994183012476],
              [-72.93965141513307, -13.649714702791828],
              [-72.94592295153902, -13.649730544482013],
              [-72.95126322142436, -13.648572757123409],
              [-72.95518499341814, -13.644194992598269],
              [-72.95648168019487, -13.639687112008781],
              [-72.95526133499345, -13.634589172609829],
              [-72.95336935792321, -13.63029904142213],
              [-72.95679454827693, -13.627010603744177],
              [-72.96139507741923, -13.625918736920381],
              [-72.96246329372815, -13.621354694207147],
              [-72.96210464156731, -13.61670665562182],
              [-72.95947289876469, -13.612482804636551],
              [-72.95457450852886, -13.61278599145365],
              [-72.94954659433631, -13.612572897824036],
              [-72.94507625920124, -13.611053933470693],
              [-72.94239039667835, -13.607055428053442],
              [-72.93840048606339, -13.610691449693263],
              [-72.93164070354302, -13.608638169968373],
              [-72.9286195681665, -13.605043427601167],
              [-72.93174717425033, -13.600966630458554],
              [-72.93605033164138, -13.599086486895908],
              [-72.93866746599234, -13.595122824679384],
              [-72.93370830867741, -13.591557292543916],
              [-72.9282836330632, -13.591015207730212],
              [-72.92308070734629, -13.593655872391421],
              [-72.91880804157549, -13.597469954147414],
              [-72.91355160226452, -13.600326823771509],
              [-72.90628831122942, -13.602479890870068],
              [-72.89929189851681, -13.603496337133704],
              [-72.89268509349156, -13.603874263112417],
              [-72.88757335023878, -13.605087050099849],
              [-72.88096644897752, -13.605473691671556],
              [-72.87539659395378, -13.606573873421821],
              [-72.87023184523422, -13.608020784659015],
              [-72.86534878318051, -13.60928022632323],
              [-72.86029028430863, -13.610266956858332],
              [-72.85500360168876, -13.611197391609906],
              [-72.84851848276445, -13.612090237049097],
              [-72.84191156340053, -13.612466766804639],
              [-72.8373336008503, -13.611396848968745],
              [-72.83565496239397, -13.60619529510012],
              [-72.83535000432944, -13.601312926915218],
              [-72.83561692819247, -13.596083104990681],
              [-72.835014259608, -13.590411971367834],
              [-72.83379381456449, -13.585313806665228],
              [-72.83132173592666, -13.580403560670058],
              [-72.82755266752262, -13.575915759935127],
              [-72.82391347689975, -13.572901998254816],
              [-72.81964119500465, -13.56949409667326],
              [-72.81527689442107, -13.567513088124503],
              [-72.81005095843848, -13.568217300424733],
              [-72.80772406655645, -13.572968077814455],
              [-72.8051224343676, -13.577888091487942],
              [-72.80234537238356, -13.582535424001293],
              [-72.79837061239236, -13.587135984718744],
              [-72.79393014249797, -13.591632935622192],
              [-72.78962696342116, -13.593510481999127],
              [-72.78389725778868, -13.595302964974428],
              [-72.77797713323788, -13.595837571658306],
              [-72.77216341381654, -13.595921176629789],
              [-72.76584626087921, -13.59612663521469],
              [-72.76058187523094, -13.594895622870633],
              [-72.75417341477151, -13.5934011839751],
              [-72.74753589257143, -13.591841268063307],
              [-72.74272910022432, -13.590722473671349],
              [-72.73942594789344, -13.594514749330433],
              [-72.7403719061259, -13.600758170984989],
              [-72.74027278511147, -13.605302375814997],
              [-72.73529797893288, -13.6079860189757],
              [-72.73040008407608, -13.60828476051284],
              [-72.72458622345478, -13.608367203505354],
              [-72.71968834176306, -13.608665730649115],
              [-72.71320343655724, -13.60954594411783],
              [-72.70791611782226, -13.61047316008297],
              [-72.70121789399015, -13.612272729384818],
              [-72.69605262224903, -13.613706805595113],
              [-72.69105538933705, -13.615431467537137],
              [-72.6850282377765, -13.61642357849707],
              [-72.68002309739178, -13.61717207415823],
              [-72.67450692067877, -13.618042183992785],
              [-72.66767097236173, -13.618366690238114],
              [-72.66150638150343, -13.617884121701703],
              [-72.65699020426305, -13.616585673549265],
              [-72.6516037342897, -13.61484523128536],
              [-72.64643834721521, -13.612185159536526],
              [-72.6405642126527, -13.60840692041432],
              [-72.63670338080745, -13.605334385900889],
              [-72.63167598051263, -13.601030934236494],
              [-72.62683127739835, -13.597009244510982],
              [-72.62091058657452, -13.59345608124671],
              [-72.6156235651363, -13.590288350893111],
              [-72.61062609377392, -13.587909373389525],
              [-72.60482782158375, -13.584863043556743],
              [-72.59920516348902, -13.582107418653095],
              [-72.59432995426795, -13.580235311174505],
              [-72.58908072059512, -13.579967757720913],
              [-72.58213065219181, -13.579782991404606],
              [-72.57472976965191, -13.58045197706275],
              [-72.56829827629652, -13.581103089147032],
              [-72.562782397808, -13.581970788005085],
              [-72.55709092180112, -13.582565588726021],
              [-72.55081201967725, -13.581564339438652],
              [-72.54611216663473, -13.579982186833957],
              [-72.54070261156053, -13.576305905871859],
              [-72.53585787474758, -13.572282755901597],
              [-72.53374456589623, -13.567934194944142],
              [-72.53378298008833, -13.562649209449694],
              [-72.53238697548372, -13.556311046102115],
              [-72.52944919929826, -13.550328067236215],
              [-72.52571842767128, -13.544635372267512],
              [-72.52207920648165, -13.540651124393563],
              [-72.51689885512695, -13.537030999579189],
              [-72.50847648498103, -13.533848315830417],
              [-72.50276199572536, -13.532508396577422],
              [-72.49555960875031, -13.531298016159363],
              [-72.48836540980659, -13.530087521767307],
              [-72.4817354204342, -13.528521131634317],
              [-72.47254947686251, -13.527570217779745],
              [-72.46547721582955, -13.526866259415828],
              [-72.46044948260727, -13.525685977175272],
              [-72.45548270899884, -13.525238004137758],
              [-72.44846325987517, -13.524308262375369],
              [-72.44098634132546, -13.523265243244712],
              [-72.43549374174202, -13.521980360179164],
              [-72.4281923074939, -13.521218836745886],
              [-72.42179104762245, -13.519716547934262],
              [-72.41722088838162, -13.518639542774107],
              [-72.41224647124713, -13.517224021219752],
              [-72.40715009100754, -13.515301240451869],
              [-72.4027936797683, -13.513313773080952],
              [-72.39745345476845, -13.510376484950573],
              [-72.39325701213009, -13.507703960046772],
              [-72.38979314151642, -13.504000109411251],
              [-72.3864135713387, -13.498869746905413],
              [-72.38515449146084, -13.494005400108543],
              [-72.38503263627138, -13.489405862987217],
              [-72.38358305287682, -13.483283818657894],
              [-72.38366681603539, -13.476807371553809],
              [-72.3833691571765, -13.471925951686195],
              [-72.38352210642108, -13.466191002917826],
              [-72.38435343176944, -13.460616688999165],
              [-72.38497184779773, -13.455943543465501],
              [-72.3862153086494, -13.45070774929518],
              [-72.39289877421386, -13.447949483973327],
              [-72.39926904599228, -13.447527592474316],
              [-72.40498346822645, -13.448869449036767],
              [-72.40822596606986, -13.45252555433337],
              [-72.41184981418499, -13.455544137898661],
              [-72.41641993608461, -13.456621037383774],
              [-72.42250083504376, -13.459493090043392],
              [-72.42646794684634, -13.462108490689563],
              [-72.429084663606, -13.466336187685869],
              [-72.4331057163203, -13.468726209320694],
              [-72.4387970179269, -13.46814287278812],
              [-72.44326021906639, -13.465586460577017],
              [-72.44860099291998, -13.464430153498542],
              [-72.45345316399604, -13.465337644202142],
              [-72.45906039842664, -13.46713805696334],
              [-72.463600264896, -13.47037309154748],
              [-72.46694935196516, -13.473559916119205],
              [-72.46997822533432, -13.478125757042767],
              [-72.47352577263348, -13.48352797459203],
              [-72.47821050562145, -13.488236909580722],
              [-72.48362717325676, -13.492880855226396],
              [-72.48805258399297, -13.495608491600022],
              [-72.49279765990495, -13.496966003890462],
              [-72.49880208973953, -13.49813724146739],
              [-72.50427240609822, -13.49846280260517],
              [-72.51196299268231, -13.49858442560246],
              [-72.51795224652115, -13.498788355564326],
              [-72.5237043791517, -13.498935592576856],
              [-72.53147908792565, -13.500765034257306],
              [-72.53738401584465, -13.503352885522549],
              [-72.54186241064772, -13.50584531187331],
              [-72.54533365609194, -13.509547519531138],
              [-72.54627965026862, -13.515790882126199],
              [-72.54636405084557, -13.52158309044924],
              [-72.54734775827175, -13.526625181960432],
              [-72.55155931021679, -13.530262338314518],
              [-72.55794499473996, -13.530794581161784],
              [-72.5628968875602, -13.530273672720607],
              [-72.56813040016743, -13.529583734720388],
              [-72.5735245330973, -13.532292427419597],
              [-72.57727837636713, -13.535816364199682],
              [-72.5820008933363, -13.539331562313214],
              [-72.58683035237053, -13.54238693228413],
              [-72.59176664291806, -13.544991498472776],
              [-72.59705365405696, -13.544066591970157],
              [-72.60197446910443, -13.54161109871323],
              [-72.60632298186756, -13.53854465034436],
              [-72.60962654149608, -13.534745298417828],
              [-72.60896326174898, -13.528341977066683],
              [-72.60752109694425, -13.523187116581056],
              [-72.60641475693697, -13.51763791457486],
              [-72.60569743377616, -13.511450928349346],
              [-72.60562902841386, -13.506625436757089],
              [-72.60669713100135, -13.502063787268044],
              [-72.60988625340104, -13.497757273292446],
              [-72.61556250089006, -13.500296043468463],
              [-72.61898024757477, -13.504222771958355],
              [-72.62156671315581, -13.50964145103912],
              [-72.62300858614064, -13.51479626666894],
              [-72.62644187920004, -13.519689875223992],
              [-72.6315691207976, -13.5235423506695],
              [-72.63605490249205, -13.52603336876708],
              [-72.63780954573102, -13.521631601184714],
              [-72.63888525444288, -13.517069805347823],
              [-72.64452390191235, -13.516707283246273],
              [-72.64978034358096, -13.517949368372346],
              [-72.65416698192777, -13.521857725978586],
              [-72.65624245165871, -13.52739789847836],
              [-72.65666933873005, -13.532787092243865],
              [-72.65878313561889, -13.537134926963125],
              [-72.66313954063045, -13.535025208749069],
              [-72.66744222597364, -13.533149827612204],
              [-72.67303452710063, -13.53397898741418],
              [-72.67817665583925, -13.538797620056998],
              [-72.68193816874339, -13.542320305754687],
              [-72.68687466158214, -13.544923149895544],
              [-72.69139111693335, -13.542136722669074],
              [-72.69421392524276, -13.536298562433306],
              [-72.69713614773049, -13.530009472698488],
              [-72.70104989722229, -13.524669158377698],
              [-72.70482641144174, -13.52194789111892],
              [-72.70939641803267, -13.518926595267338],
              [-72.71558397584161, -13.517248238307733],
              [-72.72093203925215, -13.517053005462422],
              [-72.72692106044926, -13.517252023108394],
              [-72.73181928488623, -13.516962111585643],
              [-72.73715214446817, -13.511715250173692],
              [-72.74304967957747, -13.506111882639924],
              [-72.74652875104117, -13.502601048364978],
              [-72.74838993742306, -13.49774731708781],
              [-72.74677248961706, -13.492311129158681],
              [-72.74510977376782, -13.488076332815439],
              [-72.74629202493107, -13.483053849192627],
              [-72.75088484524939, -13.48196553483659],
              [-72.75547050602584, -13.47991019769489],
              [-72.75819391545981, -13.47549777511786],
              [-72.76022311343206, -13.469958630630748],
              [-72.76139847822397, -13.464944984606218],
              [-72.76394646343758, -13.460250769141227],
              [-72.76625051299912, -13.454533251086854],
              [-72.76957684686111, -13.448571744464052],
              [-72.77275848770975, -13.44426252415704],
              [-72.77646642855508, -13.440807306641576],
              [-72.78115861719657, -13.438291435202887],
              [-72.7863690921862, -13.43662943523478],
              [-72.79332709615205, -13.436807987099549],
              [-72.79798889680472, -13.436460236425292],
              [-72.80431345974488, -13.436253412943826],
              [-72.80934168988486, -13.437426770476511],
              [-72.81574258512137, -13.438928225669471],
              [-72.82229591438484, -13.438768124100283],
              [-72.83042915675007, -13.438025056270288],
              [-72.83535021970955, -13.439657860226712],
              [-72.84006528653721, -13.442201509741718],
              [-72.84423091740769, -13.446059729994658],
              [-72.84786238758842, -13.449072919102418],
              [-72.85309598982238, -13.448376627955817],
              [-72.85702535527602, -13.44496713740663],
              [-72.86032857251598, -13.441163666831034],
              [-72.86048117745149, -13.436384525029771],
              [-72.86068703319377, -13.43138895096899],
              [-72.85850553152892, -13.426300800479467],
              [-72.8567197203654, -13.421559403321286],
              [-72.85618599707482, -13.416620806352983],
              [-72.85776514406028, -13.41193511983877],
              [-72.85559074048486, -13.407822999130838],
              [-72.85167722563148, -13.404979284267567],
              [-72.8466644952315, -13.401647200119617],
              [-72.84190362997107, -13.399329295680893],
              [-72.8426742237388, -13.393977132476376],
              [-72.84083552601565, -13.389461155543604],
              [-72.84452845088047, -13.385028567696295],
              [-72.84852572976558, -13.382360656016504],
              [-72.85286693923669, -13.379279899411276],
              [-72.85587327555012, -13.37468763280824],
              [-72.8604200795875, -13.370704775806951],
              [-72.8648073405316, -13.367398229217716],
              [-72.86972814540201, -13.3649365070223],
              [-72.87465654193504, -13.363441683013185],
              [-72.87936379544605, -13.361899716755282],
              [-72.88453648798153, -13.361418864339194],
              [-72.88942727958481, -13.361116291509077],
              [-72.89466068238873, -13.360418845402084],
              [-72.89865889312752, -13.35774097060173],
              [-72.89852913089261, -13.353140061688991],
              [-72.89942186147863, -13.348294596306818],
              [-72.90212226141038, -13.342912833745448],
              [-72.90647911975168, -13.34080708242082],
              [-72.91182719994728, -13.340607198214911],
              [-72.9157944894904, -13.343224460654026],
              [-72.91780854534082, -13.34802164453984],
              [-72.92130280167734, -13.35268638169818],
              [-72.92626213372557, -13.353124672979419],
              [-72.93103024495433, -13.352314178158075],
              [-72.93563064133573, -13.351213031909133],
              [-72.94138332871556, -13.351359262803724],
              [-72.94322210409966, -13.355874741263012],
              [-72.94409165179219, -13.360409455642968],
              [-72.94379435839544, -13.366832508232124],
              [-72.94241315134377, -13.372767766787982],
              [-72.94118486685788, -13.378008386918514],
              [-72.94011687750908, -13.38257250887881],
              [-72.93947626935736, -13.388432423217504],
              [-72.93981171979266, -13.395249198611737],
              [-72.93921679440518, -13.400883544974292],
              [-72.93879712716426, -13.406799468102433],
              [-72.93915553653642, -13.411456540251926],
              [-72.94406864215189, -13.412120201324244],
              [-72.9483337661397, -13.40830507611264],
              [-72.9522859709023, -13.4058609813175],
              [-72.95500167454608, -13.401436674490157],
              [-72.9577252715165, -13.397021424702437],
              [-72.96014385946974, -13.391817423118674],
              [-72.96177698374946, -13.386905278390342],
              [-72.96437075692033, -13.381973739398868],
              [-72.96855925763452, -13.376458511297736],
              [-72.97388473238212, -13.371205342254349],
              [-72.97753120900362, -13.367962989487841],
              [-72.98242976253462, -13.364540770260165],
              [-72.98673225724491, -13.362650061683489],
              [-72.99114983455101, -13.36127531949284],
              [-72.99614720986243, -13.360519632993723],
              [-73.00180030584438, -13.361115610619086],
              [-73.00545501521805, -13.36509383871748],
              [-73.00839997744674, -13.371072777233787],
              [-73.00998693725043, -13.377691716893828],
              [-73.01058933584983, -13.383372038811338],
              [-73.01095559548696, -13.388020221394147],
              [-73.01155865868752, -13.393691510998856],
              [-73.0136029647296, -13.400422501231633],
              [-73.01521299256021, -13.404890681812663],
              [-73.01750954768481, -13.40951789309809],
              [-73.019965859896, -13.413459492147496],
              [-73.02316260360017, -13.417325544671703],
              [-73.02785464514697, -13.422025694578004],
              [-73.03279886620457, -13.424621980394281],
              [-73.03913903766036, -13.425376329437885],
              [-73.0443267947189, -13.42585948963293],
              [-73.04996466752947, -13.42548744649403],
              [-73.0572358286639, -13.424296398497987],
              [-73.06295776934357, -13.422505773884613],
              [-73.0682983744821, -13.421344732473049],
              [-73.07511153566563, -13.420041175995292],
              [-73.0800095996587, -13.419735089053297],
              [-73.08713532771965, -13.42019596264782],
              [-73.0931551177292, -13.422311252363183],
              [-73.0975342735464, -13.425254560005909],
              [-73.10163110421364, -13.42837643635941],
              [-73.10546116685379, -13.432635036947923],
              [-73.10948959629597, -13.435015182437565],
              [-73.11575323838596, -13.435025972405867],
              [-73.1215364613321, -13.432999462940922],
              [-73.12664791274429, -13.428663104738336],
              [-73.1294325885405, -13.424978178431383],
              [-73.13237770716064, -13.420607502588377],
              [-73.1357806057873, -13.416348619930154],
              [-73.13961015994349, -13.41339431504702],
              [-73.14475226161056, -13.410982847912678],
              [-73.15082529555578, -13.409744014956162],
              [-73.15584569093117, -13.40995248955268],
              [-73.15976707571856, -13.412782977718516],
              [-73.1636126521775, -13.41800799991316],
              [-73.16909009807983, -13.418318982015002],
              [-73.173546016575, -13.415748270630843],
              [-73.17877956394851, -13.415035940480491],
              [-73.18625639328047, -13.41605692766759],
              [-73.19091801984212, -13.418820001559682],
              [-73.1967468752723, -13.419693290263313],
              [-73.20144664688463, -13.418136099858229],
              [-73.20778671798753, -13.415758940226597],
              [-73.2109602398751, -13.411443010427515],
              [-73.21137217698194, -13.405525733415082],
              [-73.2100754112659, -13.399695765453949],
              [-73.20874793663472, -13.395058655777595],
              [-73.20660383655273, -13.389746901545989],
              [-73.2047574068142, -13.38523263708232],
              [-73.20235454471515, -13.38106690909554],
              [-73.19781486434827, -13.378811131954816],
              [-73.19378681341297, -13.376432400212618],
              [-73.18981188125562, -13.373828033567788],
              [-73.1854475621754, -13.371844469366616],
              [-73.17969511836984, -13.371712754544822],
              [-73.17226408316347, -13.37046611112657],
              [-73.16563443703436, -13.368926806542607],
              [-73.16159059714117, -13.365580407798783],
              [-73.15888196961878, -13.360625823462597],
              [-73.16004961683575, -13.35560903374581],
              [-73.16137707164926, -13.349897443089429],
              [-73.16510016044712, -13.344275498247685],
              [-73.16764050970703, -13.33956683847178],
              [-73.17137914507889, -13.33490293637474],
              [-73.1757428821294, -13.330632105617768],
              [-73.18023657047635, -13.326859222852269],
              [-73.18525676286895, -13.323939617991625],
              [-73.19006355864927, -13.321931258588965],
              [-73.19745628848165, -13.321242711734099],
              [-73.2030331665146, -13.321092444455603],
              [-73.20817567074447, -13.321806685056762],
              [-73.21443960386247, -13.321814671794725],
              [-73.21943664389602, -13.321054380729995],
              [-73.2239073935501, -13.319449717590969],
              [-73.22904971810328, -13.317027140290463],
              [-73.2342454359934, -13.314387890259054],
              [-73.24045574558247, -13.311502467673808],
              [-73.24695562262791, -13.308438099493811],
              [-73.25185382189753, -13.305010022032853],
              [-73.25611104237355, -13.301179732085643],
              [-73.25965904506576, -13.29527462618341],
              [-73.26242044980499, -13.290620466061565],
              [-73.26467900536099, -13.286098415231658],
              [-73.2664797305152, -13.281464750120298],
              [-73.26786808038162, -13.276493822267692],
              [-73.2681732853371, -13.271027421192404],
              [-73.26434336602561, -13.266771200009089],
              [-73.26036859580762, -13.264168043214275],
              [-73.25588206525585, -13.261687875264382],
              [-73.2505337760795, -13.258758828190135],
              [-73.24597953501628, -13.255536868450694],
              [-73.24079152189304, -13.255058234839227],
              [-73.23517632832915, -13.256402354151158],
              [-73.22923296659313, -13.258141763667293],
              [-73.22139756075056, -13.25969609707011],
              [-73.21622450143786, -13.260175152812984],
              [-73.2095717206963, -13.260797367689948],
              [-73.20468132325887, -13.261106436292776],
              [-73.19968437520161, -13.261866582536996],
              [-73.19474040060601, -13.262401039831156],
              [-73.19001019832427, -13.262024031296322],
              [-73.18493677009715, -13.262051222858274],
              [-73.1805800835792, -13.26007689952319],
              [-73.18190791175165, -13.254374081642709],
              [-73.18393725399208, -13.249787970906489],
              [-73.18453989033411, -13.245119379676364],
              [-73.1823577520191, -13.241009429815719],
              [-73.17717745956791, -13.237402311757167],
              [-73.17286683924847, -13.235193244617157],
              [-73.16835048988078, -13.233904554211335],
              [-73.16297884455774, -13.236261138511942],
              [-73.15880559757558, -13.238662395291453],
              [-73.15270260068945, -13.241094806508995],
              [-73.14756034864541, -13.240379668512965],
              [-73.14872737697077, -13.235353803333005],
              [-73.15132128120202, -13.230428638444803],
              [-73.15393092301541, -13.226461609002742],
              [-73.15430436291216, -13.221737259508652],
              [-73.15077227547926, -13.218277169489545],
              [-73.1458513007583, -13.21665078856597],
              [-73.1398695587086, -13.216462730142224],
              [-73.13497899435608, -13.216770559182441],
              [-73.12944034857244, -13.215717819305105],
              [-73.12805965493011, -13.211306103971907],
              [-73.12886021894252, -13.204759249107559],
              [-73.13133996086852, -13.200285363752078],
              [-73.13557446032156, -13.194531752289821],
              [-73.13902259012094, -13.190046730385271],
              [-73.1431274069643, -13.186913111386218],
              [-73.14905532881214, -13.184199310964305],
              [-73.15488456146437, -13.181945602339656],
              [-73.16084277640947, -13.181165929978423],
              [-73.16562661396857, -13.181317707949516],
              [-73.17092158995585, -13.181346506963829],
              [-73.17697147938449, -13.182275374282572],
              [-73.18214406529243, -13.181787879664208],
              [-73.18811034693599, -13.181007618503868],
              [-73.19635799900566, -13.180758472170073],
              [-73.20340735979435, -13.180473547031712],
              [-73.20619219172606, -13.176796263787383],
              [-73.20621514058614, -13.17150884481008],
              [-73.20310954872394, -13.16621761253073],
              [-73.19852446589306, -13.161060595632373],
              [-73.19396979060045, -13.157837982607486],
              [-73.18956005784402, -13.156089605498998],
              [-73.18509678963265, -13.154575783085564],
              [-73.17549132062322, -13.152338489187777],
              [-73.16931904145981, -13.150911689882525],
              [-73.16524526372132, -13.148758595043223],
              [-73.15946182659037, -13.146692626290466],
              [-73.15402993937077, -13.14518944390185],
              [-73.14785751989731, -13.143753125910365],
              [-73.14237222575372, -13.14247528675856],
              [-73.1364819664007, -13.140869023078524],
              [-73.12955458025162, -13.141659269975356],
              [-73.12281819851471, -13.143707034120506],
              [-73.11830901688408, -13.146503020712915],
              [-73.11370111304299, -13.149759137303237],
              [-73.10812397758056, -13.153035131731345],
              [-73.1027146548144, -13.156583369104979],
              [-73.09822863487423, -13.160355227847326],
              [-73.09247578656613, -13.1633403014341],
              [-73.08775301238953, -13.167055950344384],
              [-73.08143623177607, -13.17039805459105],
              [-73.07668280680089, -13.17217005909189],
              [-73.07175448495637, -13.173669511980028],
              [-73.06621567891683, -13.175751725660977],
              [-73.06082926477582, -13.177139039660059],
              [-73.05527490038314, -13.17825382143666],
              [-73.04965991637125, -13.17959396142065],
              [-73.04068746905457, -13.18087217689578],
              [-73.0357971888892, -13.181178186307028],
              [-73.0303420724111, -13.181832233808727],
              [-73.025611854395, -13.181443367718549],
              [-73.02037793277745, -13.181186092287536],
              [-73.02029396723572, -13.176359299653031],
              [-73.02120225441607, -13.169353031463187],
              [-73.02062244039713, -13.161521881601505],
              [-73.02066781190379, -13.154075135443748],
              [-73.02215597660349, -13.147687874341237],
              [-73.02640528212295, -13.142903417405234],
              [-73.02962481107313, -13.138364034698442],
              [-73.03114328870366, -13.133910993250568],
              [-73.03300503105606, -13.129044819431655],
              [-73.03478260481418, -13.123445913894779],
              [-73.03648366810901, -13.116147285636789],
              [-73.03953540607122, -13.11132624628118],
              [-73.04270921542913, -13.10701220164111],
              [-73.04586050296152, -13.101739914142087],
              [-73.04833239273442, -13.096299744630949],
              [-73.05127692194856, -13.091929581330845],
              [-73.04762249119297, -13.087952379530714],
              [-73.04058067947929, -13.085115332212013],
              [-73.03472875711189, -13.082305251431343],
              [-73.02780888173491, -13.079974941322902],
              [-73.02445241188681, -13.076786046222267],
              [-73.02374259251842, -13.071565801400673],
              [-73.02206405206529, -13.066365257206321],
              [-73.02084338594294, -13.061267631498335],
              [-73.01775385138133, -13.056941973589375],
              [-73.01406846249239, -13.054157176530222],
              [-73.00926989257646, -13.053044794253678],
              [-73.0042342219204, -13.050900199018438],
              [-73.00154857445274, -13.046911910993343],
              [-73.00015261887253, -13.04153272875222],
              [-72.99650549756883, -13.037555056504031],
              [-72.99089818994355, -13.03576734082154],
              [-72.9853136863679, -13.034946719028504],
              [-72.98063658529378, -13.034340923171849],
              [-72.97595189958108, -13.032758926642655],
              [-72.9723509433448, -13.028555403613467],
              [-72.9710770680899, -13.023692255679652],
              [-72.96875019504917, -13.01713083501991],
              [-72.96602632481793, -13.010240943473516],
              [-72.96484374784605, -13.003950571296365],
              [-72.96334070319485, -12.999031393593489],
              [-72.9641037909251, -12.993678108448462],
              [-72.96797915737221, -12.990491077426553],
              [-72.97177858238568, -12.986571335758276],
              [-72.97135181130128, -12.981172558407602],
              [-72.96763587264266, -12.976462068262904],
              [-72.96466846802502, -12.972643082328899],
              [-72.96147929082137, -12.96876811157739],
              [-72.95572673392125, -12.964538605643643],
              [-72.95091225042066, -12.962458272316665],
              [-72.9421313218788, -12.960888789027884],
              [-72.93659966359846, -12.959832814947603],
              [-72.93168615288633, -12.958203246051823],
              [-72.92697149922847, -12.958789336076684],
              [-72.92221062022973, -12.95960090255551],
              [-72.91760277220021, -12.962853682174872],
              [-72.91395553878093, -12.966095988970713],
              [-72.90852372004946, -12.96771525489268],
              [-72.90103928859757, -12.968847909947149],
              [-72.89395159870794, -12.970326965011314],
              [-72.8889771904844, -12.972048901142083],
              [-72.88366699700974, -12.974174671746448],
              [-72.8760530587016, -12.974799429416311],
              [-72.87026979112339, -12.975854661332193],
              [-72.86508179079843, -12.978487091596168],
              [-72.86148807300926, -12.981503039275685],
              [-72.86123655716273, -12.986733408428158],
              [-72.86329624267483, -12.99130501745569],
              [-72.86321280169673, -12.99681692006047],
              [-72.86215193549899, -13.001380921750815],
              [-72.85881786757597, -13.00637801595579],
              [-72.85611741573179, -13.011759836518285],
              [-72.85391237295894, -13.016052237409088],
              [-72.85176824906902, -13.021086142141145],
              [-72.850372375216, -13.026044890312527],
              [-72.84660332195618, -13.028778895288799],
              [-72.84185801146704, -13.03055582674851],
              [-72.83663180305425, -13.031253257654923],
              [-72.83176408547878, -13.032522900509406],
              [-72.82960531349478, -13.036589465694346],
              [-72.83335880897722, -13.040109122048108],
              [-72.83831025287819, -13.043674894893304],
              [-72.84458930675089, -13.04875178110135],
              [-72.84999071885727, -13.052420532698484],
              [-72.85499596784841, -13.055751540683634],
              [-72.86009972780475, -13.058631437179288],
              [-72.86547847762816, -13.060365821078435],
              [-72.86888128376425, -13.056110347687095],
              [-72.8740236888888, -13.057797421237066],
              [-72.87837246143553, -13.062902940273787],
              [-72.88082139946245, -13.066845125015831],
              [-72.8832394974746, -13.071979939985757],
              [-72.88626877693201, -13.076532337412154],
              [-72.88996871764144, -13.080285893318917],
              [-72.89493562058851, -13.084808868975985],
              [-72.89909359971335, -13.088665474128339],
              [-72.90509006806, -13.086698941723927],
              [-72.90949231949423, -13.084366894679423],
              [-72.91354379849746, -13.081462578641409],
              [-72.91728951508036, -13.077769476360253],
              [-72.92076116825835, -13.074245804911307],
              [-72.92470537818643, -13.071801308268197],
              [-72.92984007848106, -13.07251127432763],
              [-72.93460828177814, -13.075793858150805],
              [-72.93746976958447, -13.080073330117918],
              [-72.940567239739, -13.085366852853943],
              [-72.9444272698161, -13.08842515823085],
              [-72.94943977534534, -13.08863675733339],
              [-72.95490999082007, -13.085824342243669],
              [-72.96175404802314, -13.08332992086501],
              [-72.96766638823642, -13.08278910619726],
              [-72.9725645496733, -13.083451218973583],
              [-72.97464727685508, -13.088989516166743],
              [-72.97286250388201, -13.094578773674655],
              [-72.97300710393603, -13.100147033493053],
              [-72.97445687162214, -13.10530079030367],
              [-72.97669980152828, -13.11015349992934],
              [-72.98304739821056, -13.111866192446517],
              [-72.98822766302867, -13.11235858521213],
              [-72.99309551442687, -13.114213124141385],
              [-72.9963457192465, -13.117853489299826],
              [-72.99705493672732, -13.123073728233399],
              [-72.99713109123425, -13.127900421361206],
              [-72.99545314087452, -13.133047840228443],
              [-72.99388150748845, -13.137726110305131],
              [-72.99169174285588, -13.14299601373994],
              [-72.98715977247674, -13.147949303860038],
              [-72.98444357141383, -13.152365452541492],
              [-72.98322309089808, -13.157615728051384],
              [-72.97863785105065, -13.155582374727537],
              [-72.97394574536209, -13.154009019287612],
              [-72.97106913072332, -13.159110566092773],
              [-72.97025313084522, -13.16468928082368],
              [-72.96586589320935, -13.16799840122226],
              [-72.96067817483171, -13.16751438253737],
              [-72.9557339668069, -13.168044391502553],
              [-72.95114128795481, -13.169137427514144],
              [-72.94458031625985, -13.1714527277011],
              [-72.93746927999042, -13.175082865970872],
              [-72.92983224606192, -13.174750205413131],
              [-72.92653682362248, -13.17133460931205],
              [-72.92325566250099, -13.16575919756218],
              [-72.92038743225613, -13.160512448635558],
              [-72.91872401198437, -13.156278583165987],
              [-72.91683979104108, -13.15198869965425],
              [-72.91313156347488, -13.148235269930929],
              [-72.90818034217072, -13.144679541368518],
              [-72.90293119437095, -13.143443853874983],
              [-72.89791884091494, -13.143240235353227],
              [-72.8933945620912, -13.145073868790407],
              [-72.89038103762913, -13.148700126991857],
              [-72.88893883414696, -13.154851562649116],
              [-72.8872601936104, -13.159997917827257],
              [-72.8855746292075, -13.165135156087631],
              [-72.88083631205613, -13.163785698924263],
              [-72.87644982900608, -13.159872564659008],
              [-72.87295524808691, -13.155207657739538],
              [-72.87112454584303, -13.150692071341636],
              [-72.86547872611347, -13.1500939614817],
              [-72.86060344683953, -13.151363756015277],
              [-72.85626977949055, -13.154436314725311],
              [-72.8520050500751, -13.158250384182288],
              [-72.84922027864332, -13.161932179561836],
              [-72.84697701052504, -13.16741680737247],
              [-72.84437535705558, -13.172347140527137],
              [-72.83945470898999, -13.170715257410107],
              [-72.83637222688753, -13.166387764877749],
              [-72.83354183782136, -13.15994777334837],
              [-72.83277877718449, -13.154962071501606],
              [-72.83178684805848, -13.149920217843343],
              [-72.82983414452393, -13.144888325543786],
              [-72.82501956043555, -13.142805315528484],
              [-72.82013675066318, -13.143107339947917],
              [-72.81543718612964, -13.144648853177616],
              [-72.81063091150406, -13.146650231607847],
              [-72.80480931476384, -13.145778223692615],
              [-72.79968260962228, -13.145056626624756],
              [-72.79405232866681, -13.14542403352391],
              [-72.78948975973432, -13.148448208879024],
              [-72.78636937639753, -13.152523919794698],
              [-72.78227991835038, -13.156618316496782],
              [-72.7792584620835, -13.160242936098667],
              [-72.77542866206146, -13.1663184144625],
              [-72.77223949922556, -13.16966133185534],
              [-72.76774616069163, -13.173426593877632],
              [-72.76296212478657, -13.173267430983481],
              [-72.75613378670903, -13.169502871974332],
              [-72.75215162578144, -13.16592576708992],
              [-72.74815363612596, -13.161381593719693],
              [-72.7447356533338, -13.157456942336683],
              [-72.74015825270118, -13.155419675330474],
              [-72.73548147437278, -13.154800150591807],
              [-72.73194886911433, -13.158546108547332],
              [-72.72765354056965, -13.160432829323463],
              [-72.7223968586264, -13.162319973478278],
              [-72.71653741248213, -13.162638384209997],
              [-72.71180716411575, -13.162252878169271],
              [-72.70610037152537, -13.160909773192873],
              [-72.70112609146798, -13.159509860002371],
              [-72.69577034298587, -13.158729919505879],
              [-72.69052113437202, -13.161592329683524],
              [-72.68813337192518, -13.165600869844893],
              [-72.68644726876222, -13.170736162007328],
              [-72.6846007522287, -13.176556712615385],
              [-72.68367021334444, -13.18259334142481],
              [-72.68399795399279, -13.189409390824405],
              [-72.68476840434454, -13.195361995022045],
              [-72.68673732216628, -13.201361579951302],
              [-72.68897243910324, -13.206216002886011],
              [-72.6925508706943, -13.209456069358767],
              [-72.69841788013478, -13.213240795842808],
              [-72.7031859527042, -13.216518562478688],
              [-72.70792391158187, -13.220997782397857],
              [-72.71215074073818, -13.225598867985761],
              [-72.71654524586299, -13.230472488215641],
              [-72.7203138989929, -13.234969990281208],
              [-72.722938565927, -13.23919520589843],
              [-72.72297657739114, -13.245213596613977],
              [-72.72005446971353, -13.251503730185409],
              [-72.71505748362134, -13.256347517062256],
              [-72.71095272455855, -13.259473306696819],
              [-72.70317847267972, -13.260785875417374],
              [-72.69458791394361, -13.261413114230221],
              [-72.68909459149964, -13.263251865502875],
              [-72.68585229939966, -13.266827759796119],
              [-72.68219750725862, -13.270056480582124],
              [-72.67940548568056, -13.273735753928314],
              [-72.67328617059289, -13.279273076130206],
              [-72.66873180611195, -13.283261360097589],
              [-72.66595447589012, -13.287898352448071],
              [-72.66238421439746, -13.292835223531227],
              [-72.66059094451944, -13.298429916770873],
              [-72.66020994152782, -13.307245270918278],
              [-72.66064480501893, -13.313601384556682],
              [-72.66199505815678, -13.32017375400742],
              [-72.6640317997959, -13.326906054413314],
              [-72.66571821123887, -13.333074912743163],
              [-72.66676309607465, -13.337891541414884],
              [-72.66633605299084, -13.343806014286768],
              [-72.66596197054321, -13.34948604014073],
              [-72.66489438934286, -13.35404827251574],
              [-72.66051495639093, -13.358317869773906],
              [-72.65577698996576, -13.36105701862076],
              [-72.65093217722722, -13.361120523232833],
              [-72.64590446076919, -13.359944355826386],
              [-72.64064781122673, -13.35871174856688],
              [-72.63673403486887, -13.355865181583283],
              [-72.63236228748585, -13.35291489532718],
              [-72.62597671602575, -13.352385263296284],
              [-72.62135332356297, -13.351537720372445],
              [-72.61632546674304, -13.35036101572975],
              [-72.61117529853267, -13.348668047068475],
              [-72.60703302682862, -13.345773766615839],
              [-72.60355367676, -13.34110573739559],
              [-72.60169232887299, -13.334654956900778],
              [-72.60132624836686, -13.329031502583153],
              [-72.60255426057972, -13.323793565498281],
              [-72.60493470240587, -13.318810444627177],
              [-72.60503360477186, -13.314266578458918],
              [-72.6026153309543, -13.30816294814912],
              [-72.59810658614448, -13.30373820737346],
              [-72.59304047569037, -13.299660511267405],
              [-72.5884931754, -13.296427944828999],
              [-72.58321406084299, -13.297353619728362],
              [-72.57821661125439, -13.302194119182062],
              [-72.57577522809845, -13.306435462959488],
              [-72.57169324112775, -13.31149232374981],
              [-72.56754294869172, -13.315807551761901],
              [-72.56178267072406, -13.318788095726198],
              [-72.5556257417218, -13.318304225738675],
              [-72.55152104466589, -13.31420791824255],
              [-72.54986554194676, -13.309972575382545],
              [-72.54997235431397, -13.305428970092892],
              [-72.54734817620353, -13.301202351627456],
              [-72.54399874453375, -13.298007741999434],
              [-72.53956579583172, -13.294323762716754],
              [-72.53498089492938, -13.292282620459469],
              [-72.52950291392709, -13.290991668951843],
              [-72.52169020022569, -13.290364520014801],
              [-72.51442708478965, -13.291540444919553],
              [-72.5078962315353, -13.29265104963329],
              [-72.4999924338488, -13.293440797658088],
              [-72.49346153062287, -13.294551010391231],
              [-72.4873735307953, -13.294807127949804],
              [-72.47986604068946, -13.294958683016809],
              [-72.47376272520118, -13.294247615636314],
              [-72.46897140038105, -13.293115812292015],
              [-72.46394367978786, -13.29193639548458],
              [-72.45783997986835, -13.291224961407755],
              [-72.45253736110753, -13.290214240735356],
              [-72.44699835903337, -13.289155871569502],
              [-72.44037631619041, -13.287589600694513],
              [-72.43427289821265, -13.28687763217914],
              [-72.428794818033, -13.285584613819188],
              [-72.42348496370118, -13.284582287966293],
              [-72.4180070342261, -13.283289056633814],
              [-72.41312427389983, -13.280456916648332],
              [-72.40753925303571, -13.27553065215821],
              [-72.40319810457456, -13.270418238571418],
              [-72.39996327514065, -13.266771572554758],
              [-72.3959959635196, -13.264147285715838],
              [-72.38974773727377, -13.260993686845781],
              [-72.38476578182141, -13.258620852824164],
              [-72.37844102665282, -13.257851218861225],
              [-72.37212382220973, -13.25804814704849],
              [-72.36644747405319, -13.258630964535856],
              [-72.36002341010524, -13.259278176744646],
              [-72.35391224988784, -13.261690070358966],
              [-72.34825119153254, -13.264205852626104],
              [-72.34425362988314, -13.26687434464698],
              [-72.33894371988373, -13.269944599748106],
              [-72.33367945882955, -13.271831781495077],
              [-72.32698032191753, -13.273622474394998],
              [-72.32238765636626, -13.274703415327831],
              [-72.31540656755226, -13.275704885060913],
              [-72.31069204728335, -13.276278433508384],
              [-72.30539699605545, -13.276231465560166],
              [-72.30020907538768, -13.27572481606562],
              [-72.29476955031623, -13.277329217391017],
              [-72.29072581851094, -13.281188640766063],
              [-72.28810121564422, -13.28514440971834],
              [-72.28572059682011, -13.290114477968125],
              [-72.2822419204281, -13.293618396932938],
              [-72.27767162319255, -13.296631792836223],
              [-72.27149181492648, -13.29829991608197],
              [-72.2646636297473, -13.298614942515194],
              [-72.25975812003693, -13.297938803160624],
              [-72.25514230221927, -13.297085039239573],
              [-72.2491455529997, -13.295909128607994],
              [-72.24207304335565, -13.29424266376611],
              [-72.23697670853156, -13.292317479806547],
              [-72.23509952977474, -13.288024445618397],
              [-72.23754885134765, -13.283787461189881],
              [-72.24268339905394, -13.280428658064169],
              [-72.24890906967664, -13.277569310430565],
              [-72.25382216743522, -13.275120184516755],
              [-72.25823236265215, -13.272791994262043],
              [-72.26262670145591, -13.269506018195797],
              [-72.26593034614606, -13.26571140881966],
              [-72.26816535068333, -13.258301002821495],
              [-72.26882957448099, -13.252445520347797],
              [-72.26990519012136, -13.247886193533317],
              [-72.27304092643486, -13.243809582451593],
              [-72.27490975122683, -13.23896058588349],
              [-72.27535262380152, -13.234015044868743],
              [-72.27550523240397, -13.228271467353906],
              [-72.27622997589657, -13.223157288080417],
              [-72.27752681174184, -13.217687860075168],
              [-72.27911389564309, -13.213007405960935],
              [-72.28110513689074, -13.2086654622119],
              [-72.28388203317535, -13.20403372919247],
              [-72.29003154992634, -13.204514486409854],
              [-72.29495244696534, -13.206156463669718],
              [-72.29943091035787, -13.209627490205365],
              [-72.30387864388143, -13.214281530518116],
              [-72.30744935025268, -13.217526080935263],
              [-72.31217181391402, -13.22201128467024],
              [-72.31695569947983, -13.226271235486827],
              [-72.32173178582777, -13.230522009871597],
              [-72.32605733496669, -13.234668633976142],
              [-72.33026118837061, -13.238308049241686],
              [-72.33704375588292, -13.239191949047427],
              [-72.34362806265351, -13.23785129965947],
              [-72.34975411002178, -13.236406336865862],
              [-72.35548420177366, -13.235598516936717],
              [-72.36088555336703, -13.23518465479638],
              [-72.3684079794291, -13.235994065634607],
              [-72.37467944807165, -13.236998180986106],
              [-72.38158442259231, -13.238379046655293],
              [-72.38677205960303, -13.23888392108366],
              [-72.39133462877608, -13.239960487425146],
              [-72.3962404458629, -13.240633945518923],
              [-72.40240499704221, -13.242079001486454],
              [-72.40994256230786, -13.243854060997286],
              [-72.41469568053114, -13.24617890328145],
              [-72.41899867631929, -13.248399740158678],
              [-72.42403399935385, -13.250546607495371],
              [-72.42912286060648, -13.248375514661705],
              [-72.43150312925238, -13.243403496355482],
              [-72.43484487545999, -13.238413999727415],
              [-72.4380340652383, -13.234109518340253],
              [-72.44206971936357, -13.230256143706027],
              [-72.44658639850044, -13.227464523708912],
              [-72.45171352830712, -13.224100687346665],
              [-72.45677953621036, -13.220004317355565],
              [-72.4622877135635, -13.215044887658173],
              [-72.46852885705421, -13.210020278947978],
              [-72.47370171887623, -13.205454726875393],
              [-72.47819519315401, -13.201704497402043],
              [-72.48202500752177, -13.198760760026461],
              [-72.48704507283628, -13.19584670538855],
              [-72.49189780488705, -13.19265984910124],
              [-72.4966887688668, -13.189698144902454],
              [-72.5006254501723, -13.186294325047454],
              [-72.50373848201282, -13.181247094628526],
              [-72.50526403177867, -13.176798729591395],
              [-72.50519537383815, -13.171973444536576],
              [-72.50695025214368, -13.167572457457625],
              [-72.51242058158556, -13.16380379896936],
              [-72.51718124337455, -13.158907796632736],
              [-72.52194978044302, -13.154011748398121],
              [-72.5250244849244, -13.150165391413925],
              [-72.5278546599985, -13.14529567025492],
              [-72.5215912262978, -13.141178616552072],
              [-72.51428226933913, -13.139463664833256],
              [-72.5072328910697, -13.139729681278013],
              [-72.50241830379463, -13.140758087785873],
              [-72.49563623852112, -13.143980761259963],
              [-72.49085255897555, -13.14790939663528],
              [-72.48709857189438, -13.151595020957119],
              [-72.48153688749841, -13.152687945823661],
              [-72.47697449663171, -13.151622235283996],
              [-72.47240476249665, -13.150547343165757],
              [-72.47067238249606, -13.156872617454132],
              [-72.46990975070182, -13.16318935737258],
              [-72.4655076392708, -13.165522016257711],
              [-72.4602583084881, -13.164286569962863],
              [-72.45584882541728, -13.162526314821108],
              [-72.44898980164136, -13.159946617992272],
              [-72.44348152855004, -13.160813322701365],
              [-72.44036888787214, -13.165859662455219],
              [-72.43679803177675, -13.17079294770893],
              [-72.43263218975294, -13.174139177394236],
              [-72.42707850728706, -13.175239901793699],
              [-72.42383589346699, -13.17976968284],
              [-72.41895321155899, -13.180063857616597],
              [-72.41007231117763, -13.179892949678377],
              [-72.40409835163229, -13.179687958012337],
              [-72.3981244289638, -13.179482828755281],
              [-72.39276106502857, -13.178705588309796],
              [-72.38728338162002, -13.177412131403656],
              [-72.38166786226093, -13.178737041522474],
              [-72.37761672535376, -13.181631687213853],
              [-72.37200167054455, -13.18295636292906],
              [-72.36686725020418, -13.182226098088744],
              [-72.36196909885267, -13.181552371685633],
              [-72.35806297496974, -13.178702577088488],
              [-72.3507001263817, -13.17720825205519],
              [-72.34767921944736, -13.172651047377197],
              [-72.34746544557184, -13.165376580429886],
              [-72.34841183184204, -13.159351741138929],
              [-72.35357642021664, -13.15888108475134],
              [-72.35838330956034, -13.1609813104552],
              [-72.36331172316851, -13.16357957719083],
              [-72.36859129420445, -13.162658110122672],
              [-72.37436690884928, -13.160657518712432],
              [-72.37979131328265, -13.158084047824786],
              [-72.38040898233648, -13.15341973307556],
              [-72.37611372146765, -13.151198664816325],
              [-72.37192523106583, -13.148535963111115],
              [-72.36962905092852, -13.142938468855892],
              [-72.37310770007049, -13.139423680199668],
              [-72.37815103252899, -13.138454330744441],
              [-72.38360623869944, -13.13781429578553],
              [-72.38832871994939, -13.134113155278667],
              [-72.38940414178408, -13.129552796448023],
              [-72.3877563181712, -13.125317118876684],
              [-72.38540669022623, -13.119954077525206],
              [-72.38528423198375, -13.11535445938624],
              [-72.38681009406788, -13.110898050434033],
              [-72.39186088455556, -13.10992839211487],
              [-72.39744554703069, -13.106669331248398],
              [-72.40016937103175, -13.102261025770044],
              [-72.39958201553445, -13.096590632764665],
              [-72.39774331531368, -13.091106370209364],
              [-72.39656806597655, -13.084815723006194],
              [-72.39505777695366, -13.077961575915923],
              [-72.39527157864758, -13.072958774656374],
              [-72.39781935257962, -13.068268589103313],
              [-72.4037630165906, -13.066539611350574],
              [-72.40959153502988, -13.06430340513351],
              [-72.41745758983967, -13.061581493946933],
              [-72.42276745022681, -13.05946624530375],
              [-72.42875657120236, -13.056544527964732],
              [-72.43292976157844, -13.054164761764133],
              [-72.43932352961076, -13.051581047883376],
              [-72.44371781026341, -13.049248435338892],
              [-72.44953143347311, -13.046044340680245],
              [-72.4548875437624, -13.042736207966366],
              [-72.45983864491605, -13.03908968194759],
              [-72.46457645847481, -13.036353449677673],
              [-72.47168726300302, -13.032736818879123],
              [-72.4772186145206, -13.029700872464966],
              [-72.48097225879329, -13.0260150692464],
              [-72.48461169996312, -13.021822133022528],
              [-72.48390940035021, -13.016602225764146],
              [-72.48021681335354, -13.012844027234303],
              [-72.47496814133436, -13.011609421498994],
              [-72.4695052948705, -13.011285136688791],
              [-72.46494312704324, -13.014303348994856],
              [-72.45891558693943, -13.018418365187877],
              [-72.453468350927, -13.02315326244532],
              [-72.44642666896243, -13.02751091188948],
              [-72.44037638075947, -13.029691767649318],
              [-72.4332887928651, -13.031148459278024],
              [-72.42625416645868, -13.032379606837956],
              [-72.4186708756544, -13.03492410310935],
              [-72.41239156095324, -13.037056771763774],
              [-72.40792097040176, -13.038647259771171],
              [-72.40194695813376, -13.038442818521698],
              [-72.39472939609347, -13.038424480933926],
              [-72.3888471718304, -13.040894786215846],
              [-72.38306416862416, -13.046021982443387],
              [-72.37927989266912, -13.05186489520435],
              [-72.37663288629062, -13.05798125203677],
              [-72.37431352556361, -13.06272770388631],
              [-72.37181849954023, -13.067192274017776],
              [-72.3676528502079, -13.070537624802512],
              [-72.3643571916894, -13.074334436737823],
              [-72.36312079605396, -13.079570572667398],
              [-72.36107630970594, -13.08414797953422],
              [-72.35644529006306, -13.086422299049584],
              [-72.35200475883003, -13.089945157811451],
              [-72.34932711966376, -13.09412745378391],
              [-72.35087590009688, -13.098822942986962],
              [-72.3515319424536, -13.105234735541686],
              [-72.3510359173544, -13.110406068797102],
              [-72.34297926081588, -13.107784553945528],
              [-72.33560944478657, -13.105323496144148],
              [-72.3310013781993, -13.101355132657874],
              [-72.32364682402138, -13.099860554996754],
              [-72.31862653421288, -13.09867012289292],
              [-72.31803877344552, -13.092999875705855],
              [-72.31822951124215, -13.086073006094743],
              [-72.31935900204398, -13.08127880779677],
              [-72.31826015726097, -13.07572972410037],
              [-72.31269836259604, -13.072735941167117],
              [-72.30699900211282, -13.071393784291491],
              [-72.30477159709034, -13.066537554359686],
              [-72.30606054374644, -13.061067580249409],
              [-72.30734998627673, -13.055597592589923],
              [-72.3051758716097, -13.050507066579074],
              [-72.30160544912914, -13.047262902518094],
              [-72.2993240059727, -13.042631954481347],
              [-72.29988070010599, -13.037235664518384],
              [-72.30111692564995, -13.031990988211307],
              [-72.30173467580343, -13.027327039400685],
              [-72.30208612596142, -13.01970631193955],
              [-72.30284093571613, -13.012424017042775],
              [-72.30371099459057, -13.0056578247081],
              [-72.30438230655523, -13.00075952235034],
              [-72.30521402058639, -12.995185351540625],
              [-72.30446637508597, -12.99020003960409],
              [-72.30377193291262, -12.984980406327722],
              [-72.3023374740764, -12.979834398513411],
              [-72.29814918427992, -12.977162108179742],
              [-72.2924498472167, -12.975811008667659],
              [-72.28877256437053, -12.973017448409285],
              [-72.28791029512585, -12.968491672954977],
              [-72.28788751556273, -12.962466043771267],
              [-72.28768920638494, -12.956167576840596],
              [-72.28763597007165, -12.948217475213376],
              [-72.28751362568097, -12.94361810979708],
              [-72.2873993262365, -12.939018827625148],
              [-72.28803267760283, -12.931229430955565],
              [-72.28801711529341, -12.9261704579956],
              [-72.28744513346409, -12.921467045438199],
              [-72.28567476470016, -12.916715044601498],
              [-72.2829207228256, -12.911013321080006],
              [-72.2798001751599, -12.906906713047288],
              [-72.27707663272193, -12.903138462928734],
              [-72.27372724486052, -12.898975232096122],
              [-72.26829528239777, -12.900580688944926],
              [-72.26426704894018, -12.905407588011698],
              [-72.260841143854, -12.912779100596303],
              [-72.25869762365318, -12.9178062805743],
              [-72.25558465031443, -12.922841299060241],
              [-72.25130473041763, -12.925677893790937],
              [-72.25296761647247, -12.930880550564305],
              [-72.25681289512349, -12.933956235197854],
              [-72.25414297393884, -12.938137679574947],
              [-72.24908444938134, -12.942239676629221],
              [-72.24496474226223, -12.944391248086928],
              [-72.24102031332954, -12.946824627724258],
              [-72.2365491350214, -12.948421287616986],
              [-72.2325665656318, -12.943871440249147],
              [-72.22999590672228, -12.938451313372198],
              [-72.2279202716613, -12.931943428529385],
              [-72.22652440528938, -12.925596335605283],
              [-72.22356420771013, -12.920813415042494],
              [-72.2187194976449, -12.91990368972421],
              [-72.21315025800013, -12.92412597049122],
              [-72.2112885699772, -12.92897490429966],
              [-72.20999144288434, -12.93444409809741],
              [-72.2080231594875, -12.939752522906586],
              [-72.20408621114089, -12.943151899166823],
              [-72.19829548216931, -12.94322519993093],
              [-72.19339750484137, -12.94664113938087],
              [-72.19107080759089, -12.951376408954392],
              [-72.18939230979092, -12.95748260531382],
              [-72.18775204798898, -12.96237877972283],
              [-72.18645467871977, -12.967847709591673],
              [-72.18698868036127, -12.973751950988822],
              [-72.19019317372268, -12.979558617616501],
              [-72.1919629388704, -12.984310984755757],
              [-72.19322200492186, -12.989175260981488],
              [-72.19255850315544, -12.995039475611415],
              [-72.18537874569759, -12.997906445978883],
              [-72.18054950364488, -12.99797146168781],
              [-72.1726453690996, -12.99778612475094],
              [-72.16707624131503, -12.997915090073827],
              [-72.16230039246386, -12.997745496911959],
              [-72.158943196847, -13.001764437361645],
              [-72.15923304578726, -13.006645247089532],
              [-72.1607744394979, -13.011332012328044],
              [-72.16277335025389, -13.016141211121367],
              [-72.16255972474555, -13.022109471624903],
              [-72.16075904112363, -13.027689996242243],
              [-72.15710458988677, -13.0309197237894],
              [-72.15131375473378, -13.030991573337456],
              [-72.14402749239083, -13.030224842224246],
              [-72.13764957246202, -13.029684735038327],
              [-72.13129407307963, -13.03010216343941],
              [-72.12557242486085, -13.031872296222685],
              [-72.11947633453052, -13.035245880274301],
              [-72.1142807662601, -13.037861582151612],
              [-72.10857403280127, -13.040606930331288],
              [-72.10327168871298, -13.043672783265745],
              [-72.09784721891185, -13.046240359370533],
              [-72.09175107485741, -13.049613139912937],
              [-72.08711988164242, -13.051882269502611],
              [-72.08153517434141, -13.055125032088736],
              [-72.07685858997765, -13.058594686164101],
              [-72.07252473656969, -13.06165263972923],
              [-72.06788628534285, -13.063921259499397],
              [-72.06330101530523, -13.064998167149614],
              [-72.05795299635187, -13.06518117189967],
              [-72.05202499853135, -13.063776870397275],
              [-72.04746251314677, -13.062695136707191],
              [-72.04379283121791, -13.059898473535675],
              [-72.04045853387463, -13.05669918515313],
              [-72.0375594151676, -13.052646840834237],
              [-72.03755924425458, -13.047589054768226],
              [-72.04066489522576, -13.041591378923732],
              [-72.04476147626154, -13.037510661928051],
              [-72.04811849830104, -13.033493674892856],
              [-72.05194852844954, -13.030556799438173],
              [-72.05966201705462, -13.029497447509689],
              [-72.06443798805007, -13.029669046804885],
              [-72.06925978552863, -13.028639821546381],
              [-72.07381440681169, -13.024672068152208],
              [-72.07721704397636, -13.019462852692342],
              [-72.08021531784465, -13.013914821167042],
              [-72.08248166172264, -13.00940621826513],
              [-72.08356488034552, -13.004848151353803],
              [-72.08406810743217, -12.999669370013182],
              [-72.08450327973428, -12.993758215231514],
              [-72.08432029331358, -12.988427203761063],
              [-72.08499912978935, -12.983530372864696],
              [-72.08623502196153, -12.97829655733177],
              [-72.08913419073475, -12.974165210525346],
              [-72.09399393339561, -12.971952489802334],
              [-72.09936510101805, -12.969609881010632],
              [-72.10449235616346, -12.966252809165857],
              [-72.10836036857067, -12.962122902262632],
              [-72.11009979321186, -12.955792495367678],
              [-72.11031347765865, -12.950790952441736],
              [-72.10523959269958, -12.944782316884359],
              [-72.09971636315014, -12.939626694863772],
              [-72.09362042275735, -12.933850424760177],
              [-72.09008042537066, -12.928436782498236],
              [-72.0867308501999, -12.92428072036582],
              [-72.08236697068001, -12.920357168178281],
              [-72.07975796095342, -12.916128047645973],
              [-72.07717125349714, -12.909740490933908],
              [-72.07579795575712, -12.904369078417513],
              [-72.07482136361361, -12.899327264407876],
              [-72.07344823130856, -12.89394682784466],
              [-72.07196046357997, -12.889025747970415],
              [-72.07160924179988, -12.88340385598784],
              [-72.07625605445261, -12.878009440141547],
              [-72.08128383691302, -12.876069433193145],
              [-72.08911886225246, -12.87552439849952],
              [-72.09576436199853, -12.875896255382008],
              [-72.10115821523628, -12.875486346146348],
              [-72.10550691408551, -12.873393787612928],
              [-72.10800177799761, -12.86893226549764],
              [-72.10953500756239, -12.864478160499287],
              [-72.11168689217251, -12.859452778251443],
              [-72.11318961082831, -12.853074385517981],
              [-72.114768900579, -12.84743746725182],
              [-72.11581416897641, -12.840945607470486],
              [-72.11737839807786, -12.834342005037678],
              [-72.1192858686448, -12.828302150492426],
              [-72.12194818532684, -12.823155711178519],
              [-72.12541220144924, -12.818677360646868],
              [-72.12888343723418, -12.815174531829078],
              [-72.13229393578239, -12.810930304323234],
              [-72.13603215867431, -12.806283108308929],
              [-72.13756545655109, -12.801828648127914],
              [-72.14112882146084, -12.795932925828511],
              [-72.14592725356032, -12.789860814820871],
              [-72.14945965861212, -12.786123383811566],
              [-72.15258045674284, -12.782047227406448],
              [-72.15611264918027, -12.778309661559796],
              [-72.15915674436405, -12.772534395182614],
              [-72.16279610520243, -12.768346204946354],
              [-72.16558065469806, -12.76467266679885],
              [-72.16681691011067, -12.759437858289278],
              [-72.1664047991768, -12.754040831527655],
              [-72.16423788515618, -12.748959197594386],
              [-72.157760565181, -12.748871550164893],
              [-72.15271761667626, -12.753929800660442],
              [-72.14791893661432, -12.760011246851047],
              [-72.14553830651732, -12.764989632737633],
              [-72.1444090234157, -12.769773770301514],
              [-72.14322653068265, -12.774783133545645],
              [-72.13966356900394, -12.779712473069914],
              [-72.13367437585131, -12.786719956510856],
              [-72.129692088498, -12.790343835218673],
              [-72.12442044490261, -12.795353597894671],
              [-72.11030587768226, -12.801449549847291],
              [-72.10856621237767, -12.803381447455205],
              [-72.1057128524563, -12.806321741792006],
              [-72.10108210980015, -12.808592085379583],
              [-72.09526042876438, -12.810822086243897],
              [-72.09051513933544, -12.812585134870051],
              [-72.08588419274137, -12.81485515577022],
              [-72.08142098650409, -12.816440492355346],
              [-72.07588170199338, -12.818501512995816],
              [-72.0718229143254, -12.820425346203876],
              [-72.06726088813981, -12.823427340267541],
              [-72.06316369713448, -12.827509242595733],
              [-72.06008128569692, -12.830392119599138],
              [-72.05722030720959, -12.833322586547544],
              [-72.05407703122933, -12.836430480926174],
              [-72.05061347768509, -12.840898548976545],
              [-72.0471345213069, -12.844408986669487],
              [-72.04480009043837, -12.84818514513669],
              [-72.04257221731835, -12.851501842428371],
              [-72.03945140894682, -12.856533515596253],
              [-72.03649891271883, -12.860889639056298],
              [-72.03405758038502, -12.865116015957929],
              [-72.03195979852504, -12.869915248734136],
              [-72.02968573078391, -12.874432461781357],
              [-72.02721404717764, -12.880816968625746],
              [-72.02517691931041, -12.886348349414611],
              [-72.0226288425688, -12.891042958727754],
              [-72.02051561826903, -12.89486640225225],
              [-72.01805904872555, -12.898134964898441],
              [-72.01445772551479, -12.901128497538888],
              [-72.0100558064991, -12.903453493416974],
              [-72.00553901937191, -12.905271311884908],
              [-72.00119786101192, -12.90832837791204],
              [-71.99732191787947, -12.911499076622833],
              [-71.99487288805668, -12.915733778941384],
              [-71.99295063278414, -12.920805406955617],
              [-71.99147807769353, -12.92599056724897],
              [-71.99018850740063, -12.931457792409887],
              [-71.9891054414014, -12.93601520621723],
              [-71.98853307518694, -12.940452067848428],
              [-71.98693061345735, -12.944163528220106],
              [-71.98225403323735, -12.94665625689335],
              [-71.9774628100626, -12.94552597520381],
              [-71.97367105428796, -12.942221642723988],
              [-71.97174857447826, -12.938153275567847],
              [-71.96698784503056, -12.938946862164487],
              [-71.96452322498475, -12.942214581828447],
              [-71.96157068650926, -12.94656032266059],
              [-71.9586104457062, -12.950914948573184],
              [-71.95639050399512, -12.95519689352398],
              [-71.95360571346838, -12.959824406889625],
              [-71.95177469123348, -12.96348767945769],
              [-71.94946296279035, -12.96918632706112],
              [-71.9465715151776, -12.974273036789045],
              [-71.94319893667118, -12.977322034704203],
              [-71.93859883180436, -12.977439482539753],
              [-71.934997753909, -12.980431465240713],
              [-71.93437190941611, -12.985093118150502],
              [-71.93348699816633, -12.991864959646026],
              [-71.93200656453796, -12.99608300626937],
              [-71.92784137336935, -12.999420350334068],
              [-71.92200484659243, -13.00067873133193],
              [-71.91781603853137, -13.00209201072726],
              [-71.9140317913289, -13.00383513545063],
              [-71.91068283373782, -13.008816412759344],
              [-71.9082946344482, -13.013781984769095],
              [-71.90529643465317, -13.019327229401267],
              [-71.90272549457745, -13.023044275514593],
              [-71.89838386007364, -13.026107928950518],
              [-71.89392074044112, -13.02768899673816],
              [-71.88989248526308, -13.0284172866015],
              [-71.88298044569194, -13.030140298935283],
              [-71.87907416652996, -13.031375584489687],
              [-71.87489330688635, -13.03375436562525],
              [-71.8698199155557, -13.036880829606194],
              [-71.865585516368, -13.039475503454174],
              [-71.85924555813136, -13.041818773974217],
              [-71.85466008512716, -13.042891860255374],
              [-71.8498380262379, -13.043916823409267],
              [-71.84461970343798, -13.044593702516702],
              [-71.84045411110726, -13.042881283373267],
              [-71.839980957399, -13.036762019793448],
              [-71.84037761807734, -13.032044035546384],
              [-71.8415677646366, -13.02702869565256],
              [-71.84326923797323, -13.021902175786753],
              [-71.84514605782016, -13.01705770226681],
              [-71.84691636236867, -13.012663418787374],
              [-71.8487474413669, -13.009010335091663],
              [-71.85169238789528, -13.003700130904512],
              [-71.85323350612308, -12.999248792347826],
              [-71.85288238875091, -12.993636668644868],
              [-71.8522494283144, -12.988192707367363],
              [-71.85211186957343, -12.981662076095951],
              [-71.85227223439543, -12.975920695387641],
              [-71.85395801353343, -12.969827524926064],
              [-71.85549920338359, -12.965385142001539],
              [-71.85715480364043, -12.961449822957073],
              [-71.86006953935974, -12.958288232934715],
              [-71.8641206441682, -12.954444937933697],
              [-71.86934686994492, -12.949667476770722],
              [-71.8751450929536, -12.945519837657107],
              [-71.87982174894253, -12.942053956337302],
              [-71.88555117091799, -12.937164669326503],
              [-71.88953421960036, -12.932579484501042],
              [-71.89328017604792, -12.927937197757466],
              [-71.89617181372644, -12.922851188327115],
              [-71.89969608014103, -12.91815192786966],
              [-71.90418233432611, -12.913445936271989],
              [-71.90905772207874, -12.908103244268705],
              [-71.91354361447102, -12.903397008107003],
              [-71.91644312325221, -12.898310710555318],
              [-71.91797622428312, -12.893867510637298],
              [-71.91812880401805, -12.888125633005595],
              [-71.91787722041514, -12.881087567778136],
              [-71.91838830205107, -12.876885165719292],
              [-71.91973861289974, -12.871184460615899],
              [-71.92147060835723, -12.863898766863361],
              [-71.92501854068742, -12.85704073720869],
              [-71.9273069108612, -12.853500312397003],
              [-71.92976389288609, -12.850232758095641],
              [-71.93515005330588, -12.844777752473213],
              [-71.94061263055723, -12.841021328249274],
              [-71.9454577299837, -12.837844597483567],
              [-71.95085921760923, -12.834312962719222],
              [-71.95529182941915, -12.829830704799889],
              [-71.95970943520416, -12.82438181581818],
              [-71.96344763084099, -12.818780462678001],
              [-71.9666746919663, -12.813290532362656],
              [-71.96984081000764, -12.808025613373944],
              [-71.97266381160401, -12.802196733955531],
              [-71.97507477773124, -12.795071569208655],
              [-71.97674575782321, -12.788010113053588],
              [-71.97836331175124, -12.78117379770629],
              [-71.98005648160695, -12.776045264193986],
              [-71.98254386085733, -12.770618718012011],
              [-71.98555005829354, -12.766047012216031],
              [-71.98873923019069, -12.761748281129297],
              [-71.99233219320894, -12.758745600577654],
              [-71.99642200941337, -12.754664711209326],
              [-72.00124380741292, -12.749553399743974],
              [-72.005950749268, -12.744901272121107],
              [-72.01026126201172, -12.739910367558926],
              [-72.01436618619628, -12.736795637670246],
              [-72.01937884955011, -12.733889750045655],
              [-72.02240743050245, -12.731241409171474],
              [-72.02462756144286, -12.72695820378373],
              [-72.026283513251, -12.72302086876886],
              [-72.02780913813407, -12.718567233614504],
              [-72.02843495967382, -12.71390478557379],
              [-72.0260619267924, -12.710698950439319],
              [-72.02124799223394, -12.706670887292987],
              [-72.01639532564806, -12.70479189391493],
              [-72.01229091927478, -12.707915812395571],
              [-72.00920878330463, -12.710789265223186],
              [-72.0057680430514, -12.708049934153674],
              [-72.00535568523242, -12.70266238775227],
              [-72.00627153170036, -12.698789098799152],
              [-72.0090942939089, -12.692959356777484],
              [-72.01171101603718, -12.689005839461363],
              [-72.0148085580456, -12.683007472634719],
              [-72.01663971725554, -12.67935211494465],
              [-72.01869206722125, -12.67575340575558],
              [-72.02247618117445, -12.669907085599746],
              [-72.02628299200944, -12.666002778175086],
              [-72.03006755984404, -12.664256765449835],
              [-72.03379037365914, -12.662726764983226],
              [-72.03788007948552, -12.658644745545873],
              [-72.03973386660753, -12.652830727337518],
              [-72.04064944200266, -12.648957158178582],
              [-72.04110745549256, -12.644979143441331],
              [-72.04139681177402, -12.640719259049408],
              [-72.04236613351104, -12.636611431770232],
              [-72.04338848016249, -12.632287413442898],
              [-72.0440751626858, -12.6283570705288],
              [-72.0452422642713, -12.62238177321022],
              [-72.0466460135594, -12.616463268803697],
              [-72.04722590617666, -12.608900829462172],
              [-72.04835495309239, -12.604117289484599],
              [-72.05084986907464, -12.59965590489274],
              [-72.05670940226933, -12.596234117150999],
              [-72.06139383698277, -12.594696006079703],
              [-72.06654362909448, -12.59327129422534],
              [-72.07198329169702, -12.592635450582994],
              [-72.0767362382341, -12.59183843333239],
              [-72.08301519048004, -12.590678621117922],
              [-72.09021009935105, -12.589736362774152],
              [-72.09580223991762, -12.587448731410156],
              [-72.10117362674708, -12.586079880061039],
              [-72.10639207567836, -12.5863531683006],
              [-72.1139070619906, -12.588132486677004],
              [-72.11817951499732, -12.589388927712386],
              [-72.12178043722638, -12.591441982464683],
              [-72.12711325320903, -12.595347244700577],
              [-72.13011142422764, -12.598947355158815],
              [-72.1344758236773, -12.602869218395558],
              [-72.13915255163931, -12.60445490740765],
              [-72.14594248493907, -12.603172630124414],
              [-72.15136735003229, -12.601568498097059],
              [-72.15753149223012, -12.599899844325867],
              [-72.16238417430935, -12.597684168073654],
              [-72.16429895724964, -12.592600900325852],
              [-72.1627047234013, -12.588139799602402],
              [-72.1583937242108, -12.583984071661897],
              [-72.15417455215766, -12.582512031937197],
              [-72.14883402640494, -12.58172351439396],
              [-72.1429369403621, -12.58225677011312],
              [-72.13743602834586, -12.5821528064226],
              [-72.13365943266207, -12.579818067961433],
              [-72.13211840267417, -12.575122625292307],
              [-72.13058488208138, -12.570427264483262],
              [-72.13018052921599, -12.566005952458394],
              [-72.1342012334878, -12.561180369430522],
              [-72.1377410205413, -12.558409022834084],
              [-72.14231119516045, -12.556362188110569],
              [-72.14745318360539, -12.554935575592621],
              [-72.1533433246521, -12.55440201593257],
              [-72.1574327601125, -12.55440927448372],
              [-72.16172037765331, -12.552539723369469],
              [-72.16535186413914, -12.548341691036018],
              [-72.16654203515226, -12.54429847290027],
              [-72.16677065188422, -12.540263157851092],
              [-72.17075371180144, -12.536628752397409],
              [-72.17504880848156, -12.535725517226524],
              [-72.17981724858484, -12.535893078804968],
              [-72.18543271623771, -12.535536485786606],
              [-72.18966687423398, -12.533891647275517],
              [-72.19427511885547, -12.530651771482827],
              [-72.19830323855297, -12.526791486596451],
              [-72.20173645236784, -12.524478633927187],
              [-72.20536059445301, -12.520279763942938],
              [-72.20734386228266, -12.515937316939853],
              [-72.21051781896585, -12.511634292081215],
              [-72.21719346586927, -12.509842578981257],
              [-72.22498317904963, -12.507357570734154],
              [-72.22960669357252, -12.505083631102355],
              [-72.23664846374618, -12.501696218255859],
              [-72.24143192301946, -12.498737129324153],
              [-72.24563575957217, -12.495157725020892],
              [-72.2492754924436, -12.491933813543985],
              [-72.25297564421427, -12.488475577014691],
              [-72.25600412751415, -12.485823455996865],
              [-72.2602693801921, -12.48297608818764],
              [-72.26370212344447, -12.480662205347157],
              [-72.26730365095443, -12.478630021116837],
              [-72.27287266937402, -12.475371026311503],
              [-72.27629832599195, -12.473056867885608],
              [-72.28034190007065, -12.47016168723633],
              [-72.2843324814023, -12.467491739550168],
              [-72.28793312798292, -12.465459200777264],
              [-72.2923967981657, -12.463859851727179],
              [-72.29741664609408, -12.461922697654918],
              [-72.30215430993911, -12.460154260609995],
              [-72.30786127215505, -12.45837732397463],
              [-72.3124924968628, -12.457059317433172],
              [-72.31678006988142, -12.455186732256866],
              [-72.32072456637812, -12.453708163172237],
              [-72.32563769902946, -12.452230083013829],
              [-72.32969647162386, -12.450300788534644],
              [-72.33340473882149, -12.447807845554573],
              [-72.33221457684398, -12.443676530631494],
              [-72.32613371525728, -12.442956968968684],
              [-72.32041917344436, -12.443776698422793],
              [-72.31508633861009, -12.443958674812787],
              [-72.31065394796076, -12.443399681153432],
              [-72.30583200090179, -12.443460080504282],
              [-72.30097180136391, -12.44472152450812],
              [-72.29424311334026, -12.445774462749105],
              [-72.28896322590056, -12.445730594105866],
              [-72.28490431090407, -12.443575940196554],
              [-72.28102130126402, -12.441694020963524],
              [-72.27576421464697, -12.442616752310885],
              [-72.27199527129628, -12.445334040129744],
              [-72.26856991138023, -12.447657248660303],
              [-72.26554894720523, -12.450309706566747],
              [-72.26111581356378, -12.45383310339438],
              [-72.25627878811197, -12.457018055698368],
              [-72.24929028505755, -12.460180987460427],
              [-72.24528518979328, -12.461874596518456],
              [-72.24151606455014, -12.463633781448431],
              [-72.23490920413445, -12.466158791469436],
              [-72.2298204264521, -12.467362254298815],
              [-72.22494527365308, -12.467655435047991],
              [-72.22084823025479, -12.46669186811743],
              [-72.21730022798367, -12.464406004183711],
              [-72.21298212723268, -12.463385854434405],
              [-72.20635965829345, -12.464943387573259],
              [-72.20078293110268, -12.467234248642175],
              [-72.19578549867086, -12.471102770831196],
              [-72.1912457563713, -12.475084333912704],
              [-72.18821692080823, -12.477735615047088],
              [-72.18406649340687, -12.481079718357785],
              [-72.17974081060196, -12.484150909069202],
              [-72.1746213017758, -12.48751177623784],
              [-72.1679307412465, -12.491461215580646],
              [-72.16432163437592, -12.4934917933295],
              [-72.15818789533652, -12.497094505045148],
              [-72.15385446391164, -12.49919855853198],
              [-72.14990979511326, -12.501631826741578],
              [-72.14786501174132, -12.505232334130634],
              [-72.1437757118808, -12.505234122898107],
              [-72.1393586010846, -12.505629806534081],
              [-72.13499454013575, -12.505800160899447],
              [-72.129875126603, -12.505068148881401],
              [-72.12288662025006, -12.504135414666614],
              [-72.118072282403, -12.504192290448206],
              [-72.1138612823511, -12.503677465112386],
              [-72.10977959192938, -12.503678821840202],
              [-72.10545363815937, -12.502656813297971],
              [-72.10174552967324, -12.501054283037565],
              [-72.0978011727674, -12.498437526633017],
              [-72.09374213145925, -12.496271113104235],
              [-72.08957690705607, -12.494564157904893],
              [-72.08515140335639, -12.493992413262808],
              [-72.0813674961161, -12.495739837459354],
              [-72.07682012984829, -12.498752466700797],
              [-72.0755689278691, -12.503029226034917],
              [-72.0740127940073, -12.509632986336912],
              [-72.07267010339915, -12.515335814194437],
              [-72.07090778223667, -12.519724368945575],
              [-72.06943515780537, -12.52394444538695],
              [-72.0676194807412, -12.52856720862572],
              [-72.06637592311696, -12.532843939089947],
              [-72.0642165460317, -12.536902898631597],
              [-72.06117224656042, -12.542677283551821],
              [-72.05834212533347, -12.54754170401415],
              [-72.05445086462953, -12.553839556365606],
              [-72.05206300546047, -12.557841632554233],
              [-72.0491714296787, -12.561964573000521],
              [-72.04621903585314, -12.56632162461616],
              [-72.04326606160267, -12.57066958295266],
              [-72.04037464725359, -12.574801398740554],
              [-72.03775767800616, -12.578746528935563],
              [-72.03514109150102, -12.582700651806181],
              [-72.03174618202753, -12.587910566191717],
              [-72.02946459647065, -12.591452842681836],
              [-72.02618382508362, -12.596212308448779],
              [-72.02218624262595, -12.602959760529094],
              [-72.0200728469895, -12.606792791887202],
              [-72.01757074213992, -12.610287272282834],
              [-72.01523599969198, -12.614063571779456],
              [-72.01317568154502, -12.617662271645257],
              [-72.01112381326662, -12.621270068989006],
              [-72.00740065647337, -12.626882198846104],
              [-72.00353251245762, -12.631011409091673],
              [-71.99990086529446, -12.635206419963554],
              [-71.99638340706413, -12.639899383399959],
              [-71.993041746903, -12.64488326877076],
              [-71.98987598401663, -12.650149051722458],
              [-71.98540497218404, -12.655824465893698],
              [-71.98097997061754, -12.660308120883307],
              [-71.97721091882671, -12.663019920377147],
              [-71.97367106535673, -12.665788446517627],
              [-71.9704131944046, -12.66837949357134],
              [-71.96575948769268, -12.67281502350485],
              [-71.96133442986593, -12.677298208239929],
              [-71.95716104133265, -12.683762031217874],
              [-71.95429214252647, -12.690782593417543],
              [-71.95063771135737, -12.69809209020312],
              [-71.94858542241118, -12.70169002404717],
              [-71.94647220132337, -12.705522039261622],
              [-71.94396222549781, -12.709015292822773],
              [-71.94145217522245, -12.712508506135258],
              [-71.93808723727184, -12.715558302896522],
              [-71.93178549731097, -12.719838388230702],
              [-71.92523946249322, -12.723094927404201],
              [-71.91959409827608, -12.725603144493423],
              [-71.91542049204696, -12.72797484753542],
              [-71.91153691721647, -12.73017826437955],
              [-71.90738677059883, -12.734491730859078],
              [-71.90256499538015, -12.739600983293478],
              [-71.8978650703454, -12.745217261505541],
              [-71.89311204395338, -12.751067585366767],
              [-71.88966344815167, -12.75649966282865],
              [-71.88710766812466, -12.761183606927126],
              [-71.88550561506457, -12.764894410159078],
              [-71.883316160196, -12.771108796772426],
              [-71.88083628289779, -12.77750031840419],
              [-71.8791577373055, -12.784560517071519],
              [-71.87746430665251, -12.78968794188527],
              [-71.87627433256074, -12.794694882349576],
              [-71.87798321496815, -12.799672985248955],
              [-71.88030995166584, -12.804080451615503],
              [-71.88223269074345, -12.808139960104873],
              [-71.8844070735929, -12.814189205123757],
              [-71.88522312927766, -12.820881603388163],
              [-71.88454455879383, -12.825777237172478],
              [-71.8834077672988, -12.830559057313828],
              [-71.88204958673205, -12.835284049029617],
              [-71.88113383869565, -12.839156364567657],
              [-71.87888347913734, -12.84559556825376],
              [-71.87715160841728, -12.852889779791493],
              [-71.87618282812876, -12.856987178062225],
              [-71.87566370315146, -12.86118926258193],
              [-71.87519842001079, -12.865175248698009],
              [-71.87541981500378, -12.869313912055125],
              [-71.87465650005147, -12.876592506949397],
              [-71.87400062091871, -12.883411810197744],
              [-71.87299324660715, -12.889667002732262],
              [-71.87142963439662, -12.896276745211255],
              [-71.86905677525945, -12.90220850179614],
              [-71.86757680220042, -12.906426087479973],
              [-71.86563893311487, -12.910538821870299],
              [-71.86347962295727, -12.91556094746211],
              [-71.8614881405318, -12.91989873670931],
              [-71.85955071384181, -12.924002353273753],
              [-71.85738365196227, -12.928058021659707],
              [-71.85549941467724, -12.931945488598252],
              [-71.85187528787976, -12.938060491201998],
              [-71.84857947730983, -12.942806758058031],
              [-71.8454359732682, -12.945911276615483],
              [-71.84063719085711, -12.947893966197574],
              [-71.8313905930589, -12.944250812970143],
              [-71.82636282283428, -12.94209460585982],
              [-71.81911473527634, -12.93916172553994],
              [-71.814956567927, -12.938415620217171],
              [-71.81204225026106, -12.94157663732219],
              [-71.8101044238254, -12.945679661082918],
              [-71.80799887021118, -12.950475913150692],
              [-71.80571004245844, -12.954023736424537],
              [-71.8041226989862, -12.95869083098169],
              [-71.80314621372315, -12.962787488744544],
              [-71.80399347553144, -12.967321932591906],
              [-71.8035276321225, -12.971307468525131],
              [-71.7980802522891, -12.97193574756163],
              [-71.79398340512165, -12.970955177637563],
              [-71.78984854904506, -12.972132224904737],
              [-71.78997819505085, -12.978662437043536],
              [-71.79003881894849, -12.983485146650997],
              [-71.78923773312714, -12.987872798265139],
              [-71.78515611507864, -12.98882461464581],
              [-71.77887727078569, -12.990932014381187],
              [-71.77327754117766, -12.993210215556719],
              [-71.76905836332324, -12.996778651460573],
              [-71.76613594064658, -12.9999297320666],
              [-71.76482412211476, -13.00442821687819],
              [-71.76448797322, -13.009886975222553],
              [-71.76586172655875, -13.015258714926048],
              [-71.76792121960905, -13.02176772625316],
              [-71.76935554004316, -13.027889696121923],
              [-71.7700879175534, -13.031908021790981],
              [-71.76992774166499, -13.037639884182898],
              [-71.76959205629025, -13.043098660358298],
              [-71.76741818143938, -13.047152916172914],
              [-71.7671051240131, -13.054535274163335],
              [-71.76784489098031, -13.059528907177429],
              [-71.76937846632563, -13.064216435149056],
              [-71.76999660519361, -13.06869386182937],
              [-71.76840209362172, -13.073369339394645],
              [-71.76487748521525, -13.078065748109124],
              [-71.76062022222257, -13.083791281936524],
              [-71.75815561962924, -13.08704708344289],
              [-71.75563836905815, -13.090536952396048],
              [-71.75093064655326, -13.09615723049298],
              [-71.747184965828, -13.101762435334221],
              [-71.74484273290565, -13.105534252309928],
              [-71.74209609779255, -13.108966824634235],
              [-71.73867779643538, -13.11320348747031],
              [-71.7352444970073, -13.116473743414003],
              [-71.73056039552915, -13.118969574237378],
              [-71.72438054271338, -13.120624588787207],
              [-71.71919257341047, -13.12419726121111],
              [-71.71663646026843, -13.129844023162468],
              [-71.7152865308172, -13.135533240665895],
              [-71.71392832817702, -13.141231358830266],
              [-71.71289070369957, -13.14555209279014],
              [-71.71095291305994, -13.14966263976378],
              [-71.706283718243, -13.154081222250877],
              [-71.70120212805242, -13.157203219093065],
              [-71.69543457504845, -13.160153726604667],
              [-71.68792705043913, -13.160291653739588],
              [-71.68006877532603, -13.158898932502236],
              [-71.67551414705946, -13.157810656646701],
              [-71.67044853533727, -13.157808166798493],
              [-71.66602321430304, -13.158193107494824],
              [-71.66146066228598, -13.157104494747315],
              [-71.6556624071667, -13.15716440983113],
              [-71.65129086938765, -13.157324076668488],
              [-71.64605705621608, -13.157039021895764],
              [-71.64180770138344, -13.158671783649684],
              [-71.63742078598104, -13.162911911360943],
              [-71.63393405048956, -13.16641438107859],
              [-71.62950163229337, -13.171854499689763],
              [-71.62680821376361, -13.1750602823583],
              [-71.62470231707294, -13.179844710782962],
              [-71.62619018180892, -13.185732807156057],
              [-71.62851728416176, -13.191108314994397],
              [-71.62788377425558, -13.195767684351395],
              [-71.62541957568679, -13.199030576125907],
              [-71.62262734402155, -13.203652387269084],
              [-71.62113931742819, -13.207867049731945],
              [-71.62050612441817, -13.212526359186828],
              [-71.62026222727104, -13.216558823726823],
              [-71.61933918828473, -13.220428814668002],
              [-71.61526494073287, -13.222343034723282],
              [-71.61029055906613, -13.219956783596992],
              [-71.60684186911939, -13.216245513171396],
              [-71.60435470646794, -13.212519912342366],
              [-71.60057063465639, -13.209210414677871],
              [-71.59544377941744, -13.20846506691158],
              [-71.59275063490671, -13.21167029328561],
              [-71.58814983939854, -13.20672470020804],
              [-71.58581546664466, -13.201357926967296],
              [-71.58177168701629, -13.200148319034636],
              [-71.57769040942475, -13.201095854954124],
              [-71.57521810801734, -13.204357985798241],
              [-71.57414982054263, -13.2108348090715],
              [-71.57305935715011, -13.215388353432445],
              [-71.5720289944715, -13.219716989865718],
              [-71.57026669094762, -13.22506497014863],
              [-71.56814547002473, -13.228891398916227],
              [-71.56505591793821, -13.232722817298573],
              [-71.56144711379507, -13.235707583825858],
              [-71.55716686738369, -13.239495722474949],
              [-71.55355832293851, -13.24248936171176],
              [-71.55051395260755, -13.245129451099865],
              [-71.54702781346731, -13.248621141243532],
              [-71.54337338256104, -13.252805611502414],
              [-71.53996251024329, -13.258004408442439],
              [-71.53852072884649, -13.261993108131948],
              [-71.53835312933926, -13.267723401324329],
              [-71.53982540490934, -13.272645449309756],
              [-71.54293823606204, -13.276759252910075],
              [-71.54566209231997, -13.281499699623918],
              [-71.54407522547298, -13.287138515533755],
              [-71.53985587243572, -13.291658150474749],
              [-71.53515651304146, -13.294148942455198],
              [-71.53066240447811, -13.293789708540741],
              [-71.52716854356164, -13.291277455261467],
              [-71.52299475112066, -13.289550292533312],
              [-71.51898196888466, -13.291237672078154],
              [-71.51622761289511, -13.294675504788115],
              [-71.51639569171289, -13.300004015461704],
              [-71.51895147573337, -13.305428467994112],
              [-71.51254259470161, -13.30701961617005],
              [-71.5079420123465, -13.307127892243765],
              [-71.50371529088571, -13.305634363915434],
              [-71.49824513074222, -13.304322231539015],
              [-71.49299602670517, -13.303067211074767],
              [-71.4885633367785, -13.30344828487925],
              [-71.48317691646423, -13.304809058829344],
              [-71.47897316911805, -13.30019208832104],
              [-71.47825598828081, -13.296165068554894],
              [-71.47689061187127, -13.290793050645116],
              [-71.47492198947306, -13.286947283617431],
              [-71.46932237061735, -13.284161452028489],
              [-71.46353926143478, -13.280136273442611],
              [-71.45930498274625, -13.277676182272813],
              [-71.45497119411706, -13.276640910466197],
              [-71.44876838609011, -13.276355802221836],
              [-71.44351204248689, -13.279189012257538],
              [-71.43836972936701, -13.282529181160172],
              [-71.43289194020144, -13.28627070763199],
              [-71.42662065390955, -13.29029879933076],
              [-71.42022702824546, -13.293819470080894],
              [-71.41469591511895, -13.297785385591185],
              [-71.41070577690448, -13.302359647478534],
              [-71.40932442134697, -13.307088070346305],
              [-71.40834811401771, -13.311181322630569],
              [-71.4070358813987, -13.316641833229026],
              [-71.4066392776013, -13.323288742929016],
              [-71.4070437189469, -13.32964010173989],
              [-71.4065475897701, -13.33673691492341],
              [-71.40500657983335, -13.34213995999033],
              [-71.40226717961076, -13.347498868018114],
              [-71.39939905120556, -13.35041875125067],
              [-71.39503483030121, -13.351539076549374],
              [-71.39070904609918, -13.351468387749975],
              [-71.38989264665773, -13.355843539476371],
              [-71.38973246557643, -13.36253878924465],
              [-71.38938152626577, -13.36702888711739],
              [-71.38604741733674, -13.369833549489705],
              [-71.38201149862995, -13.369586253034553],
              [-71.37635013991412, -13.367031963895997],
              [-71.37184926389936, -13.365712764528807],
              [-71.36776733598226, -13.367621843550493],
              [-71.36428046538806, -13.37110994229507],
              [-71.36289243897463, -13.374871896646805],
              [-71.3590394741342, -13.37682920554317],
              [-71.35577390203916, -13.37436314217114],
              [-71.3547666282377, -13.369546773918781],
              [-71.35580470235183, -13.365229254789435],
              [-71.35723885644109, -13.360276578899112],
              [-71.35985559781192, -13.354411214422493],
              [-71.36235828293792, -13.348995455567131],
              [-71.3642346519313, -13.343191553664075],
              [-71.36345674253266, -13.338432652900035],
              [-71.36066424035333, -13.331984027052735],
              [-71.35810825700136, -13.326558746921366],
              [-71.35631582948821, -13.323003805092755],
              [-71.3542864003763, -13.319391299595937],
              [-71.3492430117279, -13.315293160478705],
              [-71.34564214682662, -13.313228526832983],
              [-71.34170539723645, -13.311556154592564],
              [-71.33634952842765, -13.310756811388632],
              [-71.33021566260457, -13.311209727249983],
              [-71.32419569223791, -13.312169580666415],
              [-71.31816843724876, -13.312163422788638],
              [-71.31293468349389, -13.311870806391713],
              [-71.30788420949473, -13.312826285350798],
              [-71.30299400604872, -13.31308902054111],
              [-71.29834730934304, -13.314384120565217],
              [-71.29610471109872, -13.317690822271304],
              [-71.29454801299848, -13.322135697925662],
              [-71.29641709086393, -13.327397795503364],
              [-71.29907214604046, -13.331398781856581],
              [-71.30273454420494, -13.33420504178923],
              [-71.30697644148675, -13.337624433386663],
              [-71.3088380601411, -13.341920682228896],
              [-71.30781539640728, -13.348178559754151],
              [-71.30430627796171, -13.355753940801305],
              [-71.30217747373683, -13.359576734678724],
              [-71.30096426458626, -13.363611452520356],
              [-71.29969787944373, -13.367880038596372],
              [-71.29555496811633, -13.369046814742045],
              [-71.29216751730142, -13.372083839090822],
              [-71.28929917604314, -13.375002066403098],
              [-71.28830699613437, -13.379103073760733],
              [-71.28795596969346, -13.383583536865622],
              [-71.28975671844982, -13.388113687426781],
              [-71.29087853564407, -13.39247140336177],
              [-71.29085514226544, -13.396550548575275],
              [-71.2901536050233, -13.400475310607675],
              [-71.28906268993187, -13.405026110959303],
              [-71.2856751291574, -13.408062897037746],
              [-71.2816392309083, -13.408779566001456],
              [-71.27690901340614, -13.40740106683347],
              [-71.2731324671983, -13.405052840833234],
              [-71.27336913282392, -13.400056348662186],
              [-71.27641273558875, -13.396455093227994],
              [-71.2797926877233, -13.393418307202761],
              [-71.28118149408412, -13.38965716840633],
              [-71.28085302294593, -13.385013308461817],
              [-71.27690892545806, -13.382373936218055],
              [-71.2724688380991, -13.381793985636534],
              [-71.26837925166217, -13.382735378184847],
              [-71.26431283327408, -13.385608427057416],
              [-71.26126115199718, -13.388243715985219],
              [-71.25608048846597, -13.387715662327944],
              [-71.25129718061935, -13.386570680283524],
              [-71.24718447608083, -13.384614312094993],
              [-71.24224102875962, -13.385109347555916],
              [-71.23760210345647, -13.388325323383308],
              [-71.2347336018729, -13.39124271137453],
              [-71.2315291484729, -13.395526810821357],
              [-71.22991972632167, -13.40019568553084],
              [-71.22808861672735, -13.405772724599956],
              [-71.22715761458532, -13.409639465796388],
              [-71.22470838475525, -13.41578477258341],
              [-71.22213742517233, -13.420457158406409],
              [-71.21949772660727, -13.424397455280562],
              [-71.21702596049028, -13.427654347535942],
              [-71.21290613160602, -13.431716670556925],
              [-71.20786290254132, -13.433626133394217],
              [-71.20353715548725, -13.433551868986793],
              [-71.19979090183018, -13.428080573933396],
              [-71.19815827278819, -13.4228668031997],
              [-71.1972504334377, -13.416635185317256],
              [-71.19719694589631, -13.41084964714245],
              [-71.19704451165173, -13.406479468551755],
              [-71.1951753579763, -13.401217034888276],
              [-71.19303880674015, -13.397096683451647],
              [-71.18981158408086, -13.392472539779645],
              [-71.1865235623889, -13.387107461115592],
              [-71.18347185586651, -13.3827657071289],
              [-71.18053437622979, -13.378931036793865],
              [-71.1762922558828, -13.37454417188652],
              [-71.17216497414788, -13.370664375137666],
              [-71.16831999619629, -13.366608337789575],
              [-71.16400881597615, -13.361480338951772],
              [-71.16038528369398, -13.356516163365006],
              [-71.15744013019665, -13.351715687534064],
              [-71.15427408788584, -13.34686672875799],
              [-71.1512222238673, -13.342524848766724],
              [-71.14651514462726, -13.337056837848737],
              [-71.1420362211921, -13.332611984016644],
              [-71.13694004002652, -13.327769650675151],
              [-71.13355998607936, -13.324794336234376],
              [-71.13000497027164, -13.321527560568097],
              [-71.12644167644565, -13.317304098382563],
              [-71.1216428095992, -13.314225807741348],
              [-71.11730970561804, -13.313184944795033],
              [-71.11248034343387, -13.313219265548724],
              [-71.1096038164579, -13.316134954437146],
              [-71.10942095499439, -13.320905709075308],
              [-71.11002371574251, -13.325381712536945],
              [-71.11154911150213, -13.331044969095956],
              [-71.1141510253242, -13.336237469307253],
              [-71.11668399495615, -13.339732437622306],
              [-71.12030042401268, -13.343731293649453],
              [-71.12534316638398, -13.347833185044022],
              [-71.13032533751435, -13.352168714188569],
              [-71.13348378842748, -13.356052188990178],
              [-71.13369734085384, -13.361153991070248],
              [-71.13425424520709, -13.366829555707959],
              [-71.13605521780903, -13.37038547255538],
              [-71.14018997372551, -13.375231398528538],
              [-71.14311208482172, -13.37810056064248],
              [-71.14603426532847, -13.380969707135689],
              [-71.14976487461615, -13.384518952298228],
              [-71.15355654337033, -13.388800055171494],
              [-71.15689101839195, -13.392965879740778],
              [-71.1594239386048, -13.396460717803093],
              [-71.16217781951352, -13.40001305674549],
              [-71.16301725033536, -13.404546966935659],
              [-71.16197198375215, -13.408871699751556],
              [-71.15888236022627, -13.412695998700315],
              [-71.15452551176811, -13.415733837862007],
              [-71.15014664681375, -13.415883526087411],
              [-71.1456986870094, -13.414335557027558],
              [-71.14163947308673, -13.412152772244557],
              [-71.13727581188358, -13.413268047675807],
              [-71.13359831516203, -13.416469274987397],
              [-71.13050863887312, -13.420302105952636],
              [-71.12837965992627, -13.424113554838854],
              [-71.12435124071231, -13.425793167724484],
              [-71.11997232040552, -13.425942275131252],
              [-71.11587510664542, -13.424949633874224],
              [-71.11108387933248, -13.42380183389411],
              [-71.10595684242304, -13.423045801162894],
              [-71.10082991636591, -13.421324145846746],
              [-71.09677906922151, -13.420115383813435],
              [-71.09090418883513, -13.417488634380137],
              [-71.08496864260567, -13.415095446936283],
              [-71.0801773374639, -13.413947141277891],
              [-71.07483668265562, -13.4140985856019],
              [-71.07046494055726, -13.415212480751055],
              [-71.06575783624025, -13.41671807484218],
              [-71.06122568275883, -13.418515045055143],
              [-71.05509187748565, -13.419925898827765],
              [-71.05107142389093, -13.4216043418554],
              [-71.04906471890392, -13.426887473990362],
              [-71.04869850338886, -13.431375234138272],
              [-71.0492551915253, -13.436075996570953],
              [-71.05060560896949, -13.4414569429402],
              [-71.05336739518152, -13.445975795107586],
              [-71.05682375640794, -13.45065893829424],
              [-71.06084466355004, -13.455955477660819],
              [-71.06452967526238, -13.460696313698968],
              [-71.06705451023568, -13.464191790335827],
              [-71.07051077737631, -13.468874875690357],
              [-71.07281470908839, -13.472321760818236],
              [-71.07637757765076, -13.477511799870861],
              [-71.07880418722318, -13.481465900647631],
              [-71.08042921295605, -13.485705236941417],
              [-71.07971970353542, -13.489628339860806],
              [-71.07646943908381, -13.49509074291662],
              [-71.07255526259901, -13.496319739098356],
              [-71.06771062784325, -13.495386517477115],
              [-71.06189711850563, -13.494465051296052],
              [-71.05764011314196, -13.495128969106089],
              [-71.05516056288225, -13.498374358891036],
              [-71.05319992138192, -13.502476060440838],
              [-71.05216205446987, -13.507756266893479],
              [-71.05351282869721, -13.51217183397159],
              [-71.05581637044673, -13.516584385410514],
              [-71.05778476940012, -13.520423193574429],
              [-71.05952462902422, -13.524213283692337],
              [-71.06046271520367, -13.52828850374562],
              [-71.06056227532115, -13.532882876399512],
              [-71.06053932986832, -13.536970018486285],
              [-71.05697626237723, -13.539719996796633],
              [-71.05300122850007, -13.54116406233014],
              [-71.04760748189442, -13.542513413086162],
              [-71.04202268824048, -13.541648965910602],
              [-71.03746022940581, -13.539582528911017],
              [-71.03271465933764, -13.537233409285491],
              [-71.02820570361581, -13.534942122163867],
              [-71.02397182004405, -13.53247469631132],
              [-71.019867194077, -13.530523578573302],
              [-71.01620466500212, -13.528678708287377],
              [-71.01186371014634, -13.526669588053252],
              [-71.00731638472534, -13.526533829974339],
              [-71.00090775397057, -13.529074998037427],
              [-70.99722262284597, -13.531316753497522],
              [-70.9945909531201, -13.5362099104041],
              [-70.99217206778668, -13.540195151670035],
              [-70.98879266963628, -13.544191983331677],
              [-70.98516861208881, -13.54620888779152],
              [-70.9807433199903, -13.548501537366475],
              [-70.97621164977295, -13.550296140503255],
              [-70.97132865379118, -13.551525587226585],
              [-70.96683502181413, -13.55212063857705],
              [-70.96292106169312, -13.553338494034518],
              [-70.95906082440358, -13.555296966164445],
              [-70.95732887629286, -13.559446164570314],
              [-70.95684818024296, -13.563426164782898],
              [-70.95615370167556, -13.569270275257816],
              [-70.95562736676096, -13.574440442897687],
              [-70.95458207138354, -13.57971946020918],
              [-70.95320881194512, -13.585399123625209],
              [-70.95182058424867, -13.590122175150622],
              [-70.94952396063536, -13.595579246720652],
              [-70.9472809070966, -13.599846230812604],
              [-70.94474798856815, -13.603323528839386],
              [-70.94129197454484, -13.605621873850831],
              [-70.93724032644833, -13.603435532291028],
              [-70.9352721192543, -13.599596108909308],
              [-70.93489094863253, -13.594203789357705],
              [-70.93582908622753, -13.59033968448095],
              [-70.93871312801814, -13.58742756544668],
              [-70.93896512100413, -13.58339885665801],
              [-70.93664523564847, -13.577063684399954],
              [-70.93428060824684, -13.572884032097758],
              [-70.93192263657487, -13.56966983092565],
              [-70.92877194943847, -13.566740398590746],
              [-70.92473611114787, -13.567450320868632],
              [-70.92024998981437, -13.569009869905091],
              [-70.9168625965168, -13.572039830892589],
              [-70.9137571196089, -13.575868258323576],
              [-70.91013349197915, -13.577874751923343],
              [-70.90468608799507, -13.579445215630065],
              [-70.89933750148114, -13.579591758858589],
              [-70.89445505760011, -13.580819431616295],
              [-70.88993846447119, -13.586464536111805],
              [-70.88621505851835, -13.590859614388075],
              [-70.88233170678689, -13.59592855605308],
              [-70.87911959915725, -13.600205615491337],
              [-70.87647244875272, -13.604140141305278],
              [-70.8741608781417, -13.607665205582126],
              [-70.87099446740478, -13.610752025963567],
              [-70.86719510243185, -13.612475069278382],
              [-70.86299111482154, -13.612901270808957],
              [-70.85955051217823, -13.609181366058422],
              [-70.85866562542112, -13.604881574707836],
              [-70.85823829352611, -13.600688642450757],
              [-70.85894010798181, -13.595801843312895],
              [-70.86181633571111, -13.59192549462359],
              [-70.86526495281966, -13.58867204030211],
              [-70.86648538503235, -13.584641778879188],
              [-70.86748501138096, -13.580544686385652],
              [-70.86755388624776, -13.574312144588236],
              [-70.86769086100561, -13.569767704411474],
              [-70.86776727793826, -13.56545681073686],
              [-70.86887345526299, -13.56091940572522],
              [-70.87088759192366, -13.555630207332467],
              [-70.87393967319913, -13.553001382088851],
              [-70.8773270972566, -13.54997208072735],
              [-70.88247660501649, -13.54568172526709],
              [-70.88506296997453, -13.541971459729899],
              [-70.88673421718256, -13.537082304917622],
              [-70.88755037950331, -13.531746014441625],
              [-70.88801602122938, -13.525844611879473],
              [-70.88843564624976, -13.521142301532382],
              [-70.8887479793735, -13.516880274355497],
              [-70.88809203119155, -13.512629393475896],
              [-70.88720701433897, -13.508329644171083],
              [-70.88609309645936, -13.503972008729521],
              [-70.88531492925634, -13.49824863456437],
              [-70.8850476927673, -13.493372655942224],
              [-70.88407873332105, -13.485435902932322],
              [-70.88314034725327, -13.481360841033624],
              [-70.88099677536584, -13.476273633575959],
              [-70.87834917289904, -13.47226071842739],
              [-70.8749236040196, -13.469507022161165],
              [-70.8716052075865, -13.467260257472107],
              [-70.86761463654304, -13.465814314459575],
              [-70.8621595503554, -13.466419063695108],
              [-70.8582459243878, -13.467635510730895],
              [-70.85330967183783, -13.469087578298305],
              [-70.84950994495252, -13.470810928169502],
              [-70.8452603745991, -13.47339277609882],
              [-70.84220878723093, -13.476021454436491],
              [-70.83866132224968, -13.480698632825085],
              [-70.83541106645933, -13.487130726118577],
              [-70.83364115286089, -13.494399240198469],
              [-70.83253488346662, -13.498945420824448],
              [-70.83159656249208, -13.502808792207267],
              [-70.83147429047142, -13.509274746070732],
              [-70.8310011950962, -13.514210316670304],
              [-70.83029150882943, -13.519096830253709],
              [-70.8294219320833, -13.524657231135281],
              [-70.82843044127199, -13.528745179852917],
              [-70.82748430696282, -13.53260835093635],
              [-70.82518783466207, -13.537107460431585],
              [-70.82231149170626, -13.54098340679595],
              [-70.82018307680369, -13.54479063310255],
              [-70.81713130829849, -13.547418669385022],
              [-70.81265259743921, -13.54995053986662],
              [-70.80873895477117, -13.552130971360729],
              [-70.80465675940538, -13.554028847204972],
              [-70.80120098760233, -13.556316049054214],
              [-70.79660776463875, -13.558331437863234],
              [-70.79235090160785, -13.558981283005934],
              [-70.78592674986736, -13.559594427869236],
              [-70.78171512668105, -13.55905415092596],
              [-70.77561962766067, -13.558292472329388],
              [-70.77298750550979, -13.555253132496762],
              [-70.77159113786787, -13.551062097249906],
              [-70.77149937972571, -13.545504054733337],
              [-70.76953136181862, -13.540698988946849],
              [-70.76609043726828, -13.536978448580989],
              [-70.76349638674799, -13.531774902852222],
              [-70.76118481898926, -13.526404768826243],
              [-70.75696571080846, -13.524898966542276],
              [-70.75140374601105, -13.526924191668401],
              [-70.7472077490634, -13.528305038519896],
              [-70.74267555122037, -13.531060128191033],
              [-70.73853302192884, -13.532216206658985],
              [-70.73475674541355, -13.529860701367705],
              [-70.73104867161423, -13.527271808073571],
              [-70.724945042584, -13.52458779584898],
              [-70.71901688111582, -13.523151399140634],
              [-70.71446217474589, -13.522045100836628],
              [-70.71002962168393, -13.522420046980928],
              [-70.70703860712557, -13.525778103018963],
              [-70.70456672786288, -13.530957959415145],
              [-70.70135508076265, -13.535232092418454],
              [-70.69727347711307, -13.537128051591962],
              [-70.69316852330945, -13.536128278657676],
              [-70.68889645007776, -13.534845960736376],
              [-70.68563862374576, -13.53237250865394],
              [-70.67921421192398, -13.532017936203475],
              [-70.67541520740879, -13.533738004834005],
              [-70.67098985421593, -13.536033391304741],
              [-70.66822802150101, -13.539457882620013],
              [-70.66712197262427, -13.543993370564394],
              [-70.66743459228555, -13.548643672345658],
              [-70.66895296642558, -13.555272080783563],
              [-70.6712112971662, -13.561832180455038],
              [-70.67421697685198, -13.56833311534879],
              [-70.67691808728529, -13.573079034123555],
              [-70.67926770149185, -13.576303613042585],
              [-70.68032097865425, -13.58088565211585],
              [-70.68120577500798, -13.58519419444058],
              [-70.68366982195775, -13.58891682955079],
              [-70.68710346699574, -13.591673162830029],
              [-70.69223007415363, -13.592438446591151],
              [-70.69605998947719, -13.595535280237566],
              [-70.69728827994177, -13.600399991561702],
              [-70.69772328532017, -13.606522725800396],
              [-70.69633473852322, -13.612198983944554],
              [-70.6939161658347, -13.616188760601737],
              [-70.68939197024403, -13.619898419617034],
              [-70.68451711306408, -13.623042660891471],
              [-70.67827625361275, -13.625865350592905],
              [-70.67424000406982, -13.626561668853029],
              [-70.66786974050082, -13.625981557177546],
              [-70.66125487031391, -13.624377856888545],
              [-70.65486899282297, -13.621858072475801],
              [-70.65087119481967, -13.61847810402237],
              [-70.6497574221232, -13.614120472413603],
              [-70.64927680082357, -13.610152730964295],
              [-70.64879638139416, -13.605228986740821],
              [-70.64728547502467, -13.600530718310033],
              [-70.64453144757313, -13.59697412806726],
              [-70.63790142037429, -13.591509740657555],
              [-70.63395701495227, -13.58887018454578],
              [-70.6306457453922, -13.58662049575592],
              [-70.62603772926191, -13.58573687620033],
              [-70.62229170886836, -13.587240256686801],
              [-70.6170425901836, -13.585001420827544],
              [-70.61355577847614, -13.581503907279377],
              [-70.60949694855876, -13.578348010209325],
              [-70.60470585919695, -13.576225298559939],
              [-70.60099814123636, -13.57459963437212],
              [-70.59519946254483, -13.575597382680368],
              [-70.59077460209757, -13.576925935063825],
              [-70.58658615957545, -13.580233590902576],
              [-70.58301541433967, -13.582974936870686],
              [-70.57875847667702, -13.583629829277],
              [-70.57363884202142, -13.583827409697683],
              [-70.56733712683078, -13.584941710540567],
              [-70.5629579169923, -13.586036013120095],
              [-70.55967693928058, -13.58861074290585],
              [-70.55867765918232, -13.592696089345312],
              [-70.55858628316783, -13.597005053397718],
              [-70.56043232045869, -13.601303535458518],
              [-70.56381197338935, -13.605250646787656],
              [-70.56537653787491, -13.60972447117413],
              [-70.56449099613752, -13.614316882223],
              [-70.56327834590918, -13.621239103381214],
              [-70.56115749676925, -13.626981728987525],
              [-70.55969999627044, -13.630959638261643],
              [-70.55921961341859, -13.636857815412512],
              [-70.55816666459756, -13.641167580547506],
              [-70.55699902749203, -13.645935189040719],
              [-70.55485563337952, -13.64974740805319],
              [-70.55329136943436, -13.65417418518719],
              [-70.55524439727141, -13.65802382373665],
              [-70.55800616972725, -13.662546288064801],
              [-70.56092055082401, -13.665421196627682],
              [-70.56529978592467, -13.664318339218624],
              [-70.56949639773008, -13.662932562784144],
              [-70.57392878037639, -13.661605024447748],
              [-70.57807155869168, -13.659487638288816],
              [-70.58331296857365, -13.66076257684172],
              [-70.5870969743997, -13.666015558518767],
              [-70.58911151464346, -13.669631732506765],
              [-70.5893630181565, -13.674506095384704],
              [-70.58819572283737, -13.678309029923952],
              [-70.58736426026384, -13.682677074836826],
              [-70.58990461709197, -13.68907128611617],
              [-70.59323869774478, -13.694208085850864],
              [-70.59627514296038, -13.697599028938177],
              [-70.5981215865038, -13.70189764038879],
              [-70.5978624413257, -13.70688920163527],
              [-70.595894067994, -13.710975475477742],
              [-70.59216288995582, -13.71439893945611],
              [-70.58778377650785, -13.715502049021822],
              [-70.58393068534649, -13.717453181614609],
              [-70.58029916195731, -13.719453297175926],
              [-70.5757520633812, -13.721238515801074],
              [-70.57092301828534, -13.72222497416178],
              [-70.5664899620197, -13.723552114005686],
              [-70.5612641461103, -13.724206573755666],
              [-70.55597702853599, -13.725085256201975],
              [-70.55187984789552, -13.725047430267084],
              [-70.54772214385376, -13.72427799887077],
              [-70.5429303875744, -13.723109277854157],
              [-70.537750261584, -13.72257370584924],
              [-70.53211235867501, -13.721921523714311],
              [-70.52783971021184, -13.721600585662301],
              [-70.5235212703829, -13.722478080582853],
              [-70.52126335205227, -13.725773561981802],
              [-70.51918801125082, -13.730316740464506],
              [-70.51705162541674, -13.7350932213858],
              [-70.51513667004957, -13.738953909415294],
              [-70.51351950641515, -13.744578392481568],
              [-70.51263401043451, -13.75013502784332],
              [-70.51324457672933, -13.75653952573694],
              [-70.51309229578288, -13.761072518731568],
              [-70.51346560041237, -13.766462741345208],
              [-70.5130998785909, -13.771902596184116],
              [-70.51368725768631, -13.77637695318514],
              [-70.5146792436808, -13.780227483346854],
              [-70.5149383673154, -13.786066506034032],
              [-70.51365686778213, -13.790326371861623],
              [-70.51111597207478, -13.794761561884958],
              [-70.50635550165887, -13.79840760311535],
              [-70.50128941825214, -13.799342713481364],
              [-70.49668893029406, -13.800385445754669],
              [-70.49213438391466, -13.800238654018424],
              [-70.4874036703254, -13.799809005831765],
              [-70.4806979057842, -13.800578224599601],
              [-70.47540288165432, -13.801454430883409],
              [-70.47073370366535, -13.800800171166154],
              [-70.46787257110482, -13.797690576159589],
              [-70.46557611638207, -13.794248763866731],
              [-70.46333289355576, -13.789608688459502],
              [-70.45870220645587, -13.78487036442049],
              [-70.45510091182379, -13.78182818134042],
              [-70.45308664609887, -13.778211235087175],
              [-70.4518125675743, -13.773562051071421],
              [-70.45144667986625, -13.769146086894741],
              [-70.45045489201618, -13.764330823118675],
              [-70.44815800601675, -13.759924215221975],
              [-70.44614394544244, -13.756307298713322],
              [-70.44538113892702, -13.751550397889162],
              [-70.44649527465108, -13.747008569191106],
              [-70.44880690509241, -13.742515912257332],
              [-70.45225535070672, -13.739270482976515],
              [-70.4566269212128, -13.736249393499952],
              [-70.46121980913763, -13.73424215962474],
              [-70.46524817556043, -13.73161127608981],
              [-70.46704856793487, -13.727235125606551],
              [-70.46746825022603, -13.721571188619029],
              [-70.46743770954276, -13.714825954440098],
              [-70.46620178979639, -13.708022508724097],
              [-70.46509535073078, -13.702700229519074],
              [-70.46256999160053, -13.699200354828253],
              [-70.4569778492762, -13.695427677978614],
              [-70.45191209126344, -13.695397561647685],
              [-70.44719668639368, -13.696897625497172],
              [-70.44391646442325, -13.70042576986896],
              [-70.44120816616044, -13.703612755272873],
              [-70.43701199675408, -13.706925465305543],
              [-70.43161760873737, -13.709215048709064],
              [-70.42559030655944, -13.709184529494319],
              [-70.41915911863391, -13.708821773528667],
              [-70.41175053953745, -13.706529221757489],
              [-70.40933975750106, -13.702580332660204],
              [-70.40942387520685, -13.697307363549978],
              [-70.41048412825768, -13.692990226625627],
              [-70.41136924480575, -13.688399330991688],
              [-70.40839365327628, -13.684782619102796],
              [-70.40468593878566, -13.682189157572852],
              [-70.40023785843347, -13.680627769077809],
              [-70.39649942522385, -13.683082704582851],
              [-70.39218891821892, -13.685878521918461],
              [-70.38907627153922, -13.689688357916008],
              [-70.38693980137263, -13.693498325834478],
              [-70.38291940951127, -13.697092715948354],
              [-70.37667058957716, -13.698932254212808],
              [-70.36960598816462, -13.698168756160461],
              [-70.36460100379276, -13.696947872232762],
              [-70.35997789644641, -13.694129275591184],
              [-70.35581197304785, -13.692392087170061],
              [-70.35057850133451, -13.692077256723186],
              [-70.34513069650795, -13.694598183239505],
              [-70.34122493861558, -13.697734061441206],
              [-70.33822650052805, -13.7020499793943],
              [-70.33540370696419, -13.70665764187336],
              [-70.33298512876608, -13.711597440970216],
              [-70.33049752421105, -13.7157966156694],
              [-70.32675907500145, -13.719214741513737],
              [-70.3226778681287, -13.72206732472671],
              [-70.31859614810038, -13.723955167673314],
              [-70.31217970006412, -13.726474660605898],
              [-70.30529774022395, -13.726956675835366],
              [-70.30085743084648, -13.726358182553897],
              [-70.29465487108789, -13.72604149822408],
              [-70.28902429826525, -13.726348610341228],
              [-70.28498869289736, -13.727046706173963],
              [-70.27964777831392, -13.728143421096055],
              [-70.27566531884021, -13.729581573218006],
              [-70.27175170544643, -13.731751158599497],
              [-70.26801312479269, -13.73515903159439],
              [-70.2659303768909, -13.739707457767047],
              [-70.26492316897331, -13.743789745418756],
              [-70.26540370091405, -13.748720980260229],
              [-70.26638784668994, -13.75257114003903],
              [-70.26833359546833, -13.756412764734735],
              [-70.27005789461938, -13.760205059127127],
              [-70.27207949722312, -13.76575192999129],
              [-70.27346055005984, -13.76993428123877],
              [-70.27633689902233, -13.77689489926962],
              [-70.27783965147744, -13.781593450528588],
              [-70.27500941686749, -13.786190602664774],
              [-70.27207206967734, -13.790289815197244],
              [-70.26935573975487, -13.79442928188358],
              [-70.26686842180627, -13.797671644925636],
              [-70.2647782539937, -13.80125517237722],
              [-70.26245877348866, -13.804771128299594],
              [-70.25894141308315, -13.807281284349775],
              [-70.25421880991321, -13.806847332460453],
              [-70.25028253233047, -13.805166510763387],
              [-70.24549088327956, -13.802062809211032],
              [-70.24195080214616, -13.798794037603708],
              [-70.23818200212531, -13.795466717907072],
              [-70.23360461851728, -13.79049208359399],
              [-70.22983552585276, -13.786200183909125],
              [-70.22589128932758, -13.782589937942676],
              [-70.2222366350335, -13.778805017011056],
              [-70.21750620121193, -13.776450396379706],
              [-70.21226476045851, -13.776122917963905],
              [-70.20795443723202, -13.77795882263625],
              [-70.20597852885341, -13.782048635671666],
              [-70.20440671752834, -13.786470808522056],
              [-70.20345335914347, -13.79032815476408],
              [-70.20358304824389, -13.794693418540867],
              [-70.2043989186939, -13.799216326613319],
              [-70.20429976620859, -13.80352303555638],
              [-70.2039796542267, -13.807780349218131],
              [-70.20269006949293, -13.81202769086752],
              [-70.20003483218152, -13.816915276500698],
              [-70.19606017976302, -13.820271607965465],
              [-70.19260434837366, -13.823511835345831],
              [-70.18858317065346, -13.827101419476838],
              [-70.18501278527741, -13.830798920296983],
              [-70.1825178712461, -13.834030778752512],
              [-70.17906191450412, -13.838235145051282],
              [-70.17589578238106, -13.842273884417049],
              [-70.1722643822078, -13.846195197093397],
              [-70.17065456967683, -13.840989646452838],
              [-70.17046330048001, -13.836857685302467],
              [-70.16965476964481, -13.832325959161786],
              [-70.16724386325659, -13.828366504380508],
              [-70.16387918700467, -13.825370672324922],
              [-70.15868364800666, -13.821933019493533],
              [-70.15621955697551, -13.817242412424735],
              [-70.15643291442909, -13.812478575682986],
              [-70.15368650495624, -13.808927096335985],
              [-70.14862082441819, -13.80792552988086],
              [-70.1436157224169, -13.808619550800037],
              [-70.13864911505141, -13.80524028842942],
              [-70.13806168926568, -13.799802736004288],
              [-70.13713093440526, -13.79379957005044],
              [-70.13642886170581, -13.789774817639051],
              [-70.13545227160677, -13.785924890852518],
              [-70.13333157763083, -13.781799923489363],
              [-70.13075994345246, -13.77852225726627],
              [-70.12710577078523, -13.775700797040843],
              [-70.12141408622381, -13.77430795495816],
              [-70.11731737794432, -13.774262593475854],
              [-70.11277004424933, -13.775073427797027],
              [-70.10708642694301, -13.77656452422491],
              [-70.1030581374443, -13.779179023172135],
              [-70.09947973816641, -13.781910561126478],
              [-70.09710703195147, -13.785657500025094],
              [-70.09467345216606, -13.789637502335692],
              [-70.0924149809507, -13.79485568098252],
              [-70.0899351256794, -13.800015297527247],
              [-70.08790570124255, -13.805292032522198],
              [-70.08683761219226, -13.809605638907046],
              [-70.08521252691853, -13.815214862699072],
              [-70.08238246667194, -13.819817492621604],
              [-70.07938376283877, -13.824128266587646],
              [-70.07432567272424, -13.826018260996822],
              [-70.06767298360941, -13.826551183824067],
              [-70.06231681324151, -13.825713185483064],
              [-70.05645772887361, -13.82595539175614],
              [-70.05122366965085, -13.826597611278897],
              [-70.04667660244719, -13.82740681963105],
              [-70.04225163129891, -13.829687356615485],
              [-70.0385056583279, -13.834054298611568],
              [-70.03647637988237, -13.839330144266633],
              [-70.03376030975217, -13.843474519592746],
              [-70.03081537985798, -13.847560109093582],
              [-70.0268475889934, -13.85092189223372],
              [-70.02263638317967, -13.852287023518455],
              [-70.01615119112518, -13.852136783006575],
              [-70.00920885463793, -13.850913668495938],
              [-70.00254832414876, -13.850480139885184],
              [-69.99606338346904, -13.850329384919974],
              [-69.99117307006554, -13.850571608444504],
              [-69.9860002653699, -13.851943352667783],
              [-69.9822464960482, -13.853425221545592],
              [-69.9766234654354, -13.855652549226296],
              [-69.97151198107171, -13.858719266647169],
              [-69.96736923771626, -13.86275196439375],
              [-69.9661332847199, -13.867745622398134],
              [-69.96728516069919, -13.872833584762054],
              [-69.96906297437839, -13.877366774176393],
              [-69.97129052812993, -13.881044184568614],
              [-69.97295391280618, -13.885061395452656],
              [-69.97399916513754, -13.890606766169439],
              [-69.97560908189327, -13.897740745104576],
              [-69.97744730646578, -13.903969327189708],
              [-69.97922498789222, -13.908493563610925],
              [-69.9813462200285, -13.913583589865326],
              [-69.98278788344246, -13.918506417872083],
              [-69.98427593915237, -13.923204932163927],
              [-69.98463465569561, -13.927627841256756],
              [-69.98475668569247, -13.931982790237791],
              [-69.98403184084916, -13.936860929805333],
              [-69.98086534362263, -13.941850806593477],
              [-69.97392246497854, -13.943509431843715],
              [-69.96600337123245, -13.940362738201582],
              [-69.96144082553151, -13.936348357906049],
              [-69.95790074486943, -13.93307603246667],
              [-69.95333125803563, -13.92714213077917],
              [-69.95082120741631, -13.9236298461282],
              [-69.9476778503838, -13.919734960181636],
              [-69.94380167060585, -13.916852059675872],
              [-69.93981159175094, -13.915390447598062],
              [-69.93531056543623, -13.914044297574467],
              [-69.93001568258352, -13.912014755973134],
              [-69.92488807856193, -13.910276952374259],
              [-69.92010468313214, -13.909095886533365],
              [-69.91577883631162, -13.908032424861213],
              [-69.91145325242903, -13.906959893191521],
              [-69.90508317358956, -13.907322192295991],
              [-69.89939828063777, -13.90977102145279],
              [-69.89308982844169, -13.912783236538823],
              [-69.88712304523862, -13.915405973712305],
              [-69.8826294322947, -13.91694228438752],
              [-69.879279647605, -13.91972793093676],
              [-69.87696132781431, -13.924202074522043],
              [-69.87406210330616, -13.927096197103895],
              [-69.86940728605549, -13.932205654453334],
              [-69.86538697933025, -13.937706779216086],
              [-69.8618692590263, -13.943101284828671],
              [-69.8596036696847, -13.94735094906934],
              [-69.85756729143112, -13.951659480345658],
              [-69.8520511679011, -13.954389548223737],
              [-69.84647380610488, -13.95349631856171],
              [-69.84077501085957, -13.95112284127131],
              [-69.83684486824632, -13.949435576359901],
              [-69.83263361836676, -13.948877458537073],
              [-69.82649284013593, -13.95025118007337],
              [-69.82126601724029, -13.95473438505615],
              [-69.81757343875579, -13.958871815240443],
              [-69.81428500300773, -13.96431510151367],
              [-69.81213388390249, -13.970043903611645],
              [-69.8103793828518, -13.974177534931254],
              [-69.80959365282092, -13.979277844648895],
              [-69.80903597494849, -13.98346410718554],
              [-69.80876104307303, -13.987485253624833],
              [-69.80848745290118, -13.992470391959447],
              [-69.80878467333338, -13.999044357262504],
              [-69.80999728472858, -14.005836177399674],
              [-69.81137056157118, -14.01194677414107],
              [-69.8139491099105, -14.019083272455594],
              [-69.81583350096743, -14.024114774388517],
              [-69.81681026188372, -14.027964623793679],
              [-69.81864218412696, -14.033229257939789],
              [-69.82144145932163, -14.036568684273655],
              [-69.82588141396384, -14.037177458542017],
              [-69.83134494856414, -14.037564835238697],
              [-69.83698302057483, -14.037271113872743],
              [-69.84364285525169, -14.037710839603594],
              [-69.85059403961581, -14.038949446926809],
              [-69.85668942742322, -14.042611243404359],
              [-69.85920697200349, -14.04707964928426],
              [-69.86001616463902, -14.05257478292079],
              [-69.85940571275184, -14.058922591960886],
              [-69.85810806101992, -14.065093544499085],
              [-69.85692615886572, -14.069852426859095],
              [-69.85676609123578, -14.075344884337273],
              [-69.85774180159605, -14.081122894860705],
              [-69.85968836141781, -14.084975579277078],
              [-69.86112259803335, -14.088934447114964],
              [-69.86369357503708, -14.095097961231485],
              [-69.8674086689754, -14.101547214993191],
              [-69.86975136940684, -14.104777700787642],
              [-69.87420620103065, -14.110206621560286],
              [-69.8776323544356, -14.114892787544582],
              [-69.8810040642658, -14.117892930002668],
              [-69.88527655572886, -14.120146403161433],
              [-69.89070138077476, -14.125577881402686],
              [-69.89350093373403, -14.129872224217273],
              [-69.89726997090168, -14.134169179503791],
              [-69.90058183285014, -14.137393194235777],
              [-69.90667720739359, -14.13913560501271],
              [-69.91180379461477, -14.13992025178734],
              [-69.91590868937502, -14.14092640931426],
              [-69.91938733923871, -14.143469022050157],
              [-69.91815195511302, -14.148461614547186],
              [-69.91399421805006, -14.151527000602512],
              [-69.90859208654317, -14.152835853068167],
              [-69.90249595649395, -14.153021537561736],
              [-69.89537058346781, -14.152473327973212],
              [-69.89093035597321, -14.151856067061185],
              [-69.88613930723506, -14.150672621030202],
              [-69.88204148516567, -14.14966608458867],
              [-69.8772428242723, -14.14559015351715],
              [-69.87387850001211, -14.141634960211535],
              [-69.86703469969227, -14.137064693711647],
              [-69.86122093084133, -14.135147217670236],
              [-69.85598030706007, -14.13673765634414],
              [-69.85222648615516, -14.139179760132034],
              [-69.84864772743028, -14.143832874600221],
              [-69.84455927155233, -14.146673025408953],
              [-69.84040046418164, -14.146853779482827],
              [-69.83657822680674, -14.14470828581249],
              [-69.83155792748634, -14.139617901388542],
              [-69.82864334078575, -14.134807149208882],
              [-69.8248747416056, -14.129545530876689],
              [-69.82065541052795, -14.126103039741935],
              [-69.81609396374412, -14.12401349926308],
              [-69.8118740283849, -14.122489843570186],
              [-69.80595421660159, -14.121028442383984],
              [-69.80168205337077, -14.122620604751932],
              [-69.79685166224658, -14.126479809311379],
              [-69.7930373034155, -14.12818079007593],
              [-69.78900133647434, -14.129831832154677],
              [-69.7842790362255, -14.132278643511935],
              [-69.77921324393078, -14.134150303607163],
              [-69.77340740389359, -14.13608677194039],
              [-69.76885187063209, -14.137843160575486],
              [-69.76373344192503, -14.138028679890999],
              [-69.7580947226199, -14.137355920428137],
              [-69.75319698852006, -14.137582071943163],
              [-69.74921389673945, -14.140936007596894],
              [-69.74334728743953, -14.139239969010031],
              [-69.73970039703129, -14.136412471054385],
              [-69.73496976157132, -14.13500244220108],
              [-69.72950041644083, -14.133657171843026],
              [-69.72232793012796, -14.133319232127942],
              [-69.71788024858472, -14.132707836906324],
              [-69.71377528854073, -14.128807064372989],
              [-69.70961722215162, -14.124166459161039],
              [-69.70699287473907, -14.121108998822596],
              [-69.70413941974083, -14.117037615900012],
              [-69.70156886115761, -14.113756141349091],
              [-69.69985955176168, -14.109007416993627],
              [-69.70053047911502, -14.103401457849547],
              [-69.70308734146175, -14.097053396488588],
              [-69.70809160388674, -14.092515653158852],
              [-69.71377554219332, -14.089109650510109],
              [-69.72032190715029, -14.087118614738955],
              [-69.72708924113883, -14.086150107908658],
              [-69.73078901652259, -14.083934150907734],
              [-69.7334593256954, -14.080029085042357],
              [-69.73453537206801, -14.07571975063023],
              [-69.7331007869487, -14.068869254712292],
              [-69.7318949320476, -14.064969412091651],
              [-69.73052162351628, -14.060777656546078],
              [-69.72955341993183, -14.056928067205936],
              [-69.72845445519785, -14.049679606277302],
              [-69.72645583578021, -14.044141024778563],
              [-69.72274753487602, -14.041537681376841],
              [-69.71887951690458, -14.039615410357413],
              [-69.71739216322689, -14.035880610447919],
              [-69.71669781706557, -14.031865919331311],
              [-69.7103276238131, -14.030285139876478],
              [-69.70417798061105, -14.031654573747153],
              [-69.69860814506636, -14.032685265463806],
              [-69.69444983507991, -14.033818909854904],
              [-69.69081163590793, -14.035801612377464],
              [-69.68506607305126, -14.036548836237758],
              [-69.67549873956756, -14.035148913333112],
              [-69.67094418353622, -14.03402153373385],
              [-69.6648028187108, -14.032498716930416],
              [-69.6600187087477, -14.03227580758165],
              [-69.6553573009993, -14.033523673505485],
              [-69.65085615640587, -14.033135710149738],
              [-69.64534035811026, -14.033932082261657],
              [-69.64107458443414, -14.03552166514071],
              [-69.63669548571923, -14.037567979973309],
              [-69.63476575654262, -14.042379728417794],
              [-69.63465837948793, -14.046682584270721],
              [-69.63449093580678, -14.05217299705925],
              [-69.63433089259486, -14.05862729735287],
              [-69.63297319463065, -14.064064159053807],
              [-69.63053165779996, -14.068990454946626],
              [-69.62797499121035, -14.073409745475361],
              [-69.62427526572576, -14.078514963551024],
              [-69.62206214456963, -14.082536494671688],
              [-69.6187137786137, -14.086271384945334],
              [-69.6138152205079, -14.08843135103293],
              [-69.60795551146872, -14.086732887088928],
              [-69.6039127430734, -14.084534702075176],
              [-69.59981543343659, -14.088324457125399],
              [-69.59839617228208, -14.092066217905101],
              [-69.59692426809649, -14.09603192810941],
              [-69.59590186165588, -14.1001067926508],
              [-69.59476479035536, -14.104638379108573],
              [-69.59431459623379, -14.109338516923948],
              [-69.5930713243552, -14.115281656252975],
              [-69.59255944112944, -14.121169203641466],
              [-69.59427686436668, -14.126881845718827],
              [-69.59769427755167, -14.130615408410353],
              [-69.60344733363597, -14.132762393497481],
              [-69.6075440006843, -14.133782239638343],
              [-69.61187719208732, -14.134852376197626],
              [-69.61546340539938, -14.137905116571769],
              [-69.6175152768255, -14.141301647650783],
              [-69.61979654372611, -14.14475737285363],
              [-69.62077314495832, -14.148598102400044],
              [-69.62134585566517, -14.153078241437601],
              [-69.62071949716243, -14.158450113828115],
              [-69.61873584792191, -14.163494233608745],
              [-69.61646259977624, -14.166775513978234],
              [-69.61492932269165, -14.17096505906097],
              [-69.61555462577479, -14.175212206690478],
              [-69.61772156489253, -14.179115805885234],
              [-69.61904101207932, -14.183531714650988],
              [-69.61859081014741, -14.189195712420416],
              [-69.61699657181798, -14.193609022835707],
              [-69.6146700062461, -14.197123144245552],
              [-69.60948231577565, -14.196564751148559],
              [-69.60424031215373, -14.193339137218599],
              [-69.6011046057758, -14.190395241529718],
              [-69.60053303486777, -14.183996833592126],
              [-69.60007421450814, -14.180041695308027],
              [-69.59597012350791, -14.175175609464363],
              [-69.59033230238471, -14.173535063950412],
              [-69.5854335052203, -14.173766409061766],
              [-69.58236712298832, -14.177335407220518],
              [-69.58259618929425, -14.18220388903403],
              [-69.58339670891104, -14.18768874071609],
              [-69.58442637328332, -14.193241842009863],
              [-69.58551005563743, -14.198562021187655],
              [-69.5868230135504, -14.202977792324548],
              [-69.59007296682717, -14.210274454069701],
              [-69.59218568654882, -14.2144021781383],
              [-69.59423855575159, -14.217799045755472],
              [-69.59885355394982, -14.222550893276708],
              [-69.60404230720725, -14.22696445958459],
              [-69.60734578204118, -14.229227805123653],
              [-69.61389955663707, -14.231096301943934],
              [-69.61834732645691, -14.23363760250382],
              [-69.62239087101833, -14.234881778513357],
              [-69.62746414669044, -14.23782437467044],
              [-69.63196514451548, -14.241105264253214],
              [-69.63504000512674, -14.24427290835645],
              [-69.63880887284434, -14.2495277460021],
              [-69.64080804324627, -14.254112234734585],
              [-69.64251661738975, -14.257906588159214],
              [-69.64622501567447, -14.260512039957456],
              [-69.6508937848608, -14.262148303554495],
              [-69.65551034653745, -14.262089998900665],
              [-69.66052195394165, -14.26236473062016],
              [-69.6655349074303, -14.263612163878],
              [-69.66918156570334, -14.265477392119177],
              [-69.67300421560495, -14.270508078435494],
              [-69.67494986423428, -14.274361770972037],
              [-69.67614751226503, -14.278261754672208],
              [-69.67301999405927, -14.28110097764529],
              [-69.66635116131886, -14.281608810564467],
              [-69.66202548040904, -14.281502603482442],
              [-69.6570047832949, -14.279300050070573],
              [-69.65113803076582, -14.275682287569877],
              [-69.64577523220963, -14.271949699558617],
              [-69.64189909652433, -14.269061186618275],
              [-69.63802307010789, -14.266172644354256],
              [-69.63409429627127, -14.263517055567455],
              [-69.62930293310139, -14.262337060439949],
              [-69.62463389556628, -14.262618824843123],
              [-69.61904894018146, -14.262681618404443],
              [-69.61426532372384, -14.264383603780532],
              [-69.61164854520045, -14.268061985395834],
              [-69.61114548439224, -14.272022428172235],
              [-69.61120580842802, -14.276608085385666],
              [-69.61286126336064, -14.281590197241027],
              [-69.61650890668756, -14.286347315981162],
              [-69.61930055525654, -14.289679996101503],
              [-69.6226118741836, -14.29194365467875],
              [-69.62300861107072, -14.297095421870578],
              [-69.6201629108442, -14.301687300320259],
              [-69.6150974800616, -14.305481156564209],
              [-69.6114503302243, -14.307460770115387],
              [-69.60803961010404, -14.311427096176134],
              [-69.60616333948043, -14.316013686008898],
              [-69.6057055545239, -14.320713603349722],
              [-69.60559900144005, -14.32597991133526],
              [-69.60497238763837, -14.33039677099833],
              [-69.60240932053796, -14.33481449060006],
              [-69.59962474355042, -14.338209379985937],
              [-69.59358254933028, -14.335270444850876],
              [-69.59079013825294, -14.33192837486897],
              [-69.58913411596427, -14.326946134801691],
              [-69.58918812677247, -14.321912920811322],
              [-69.59123238046044, -14.316645961538605],
              [-69.59607696102681, -14.311830093064179],
              [-69.59772500656412, -14.308148073441261],
              [-69.59572657801316, -14.300681364147604],
              [-69.5945811091143, -14.294621076043255],
              [-69.5929799679902, -14.29037868436128],
              [-69.59178183871047, -14.286469707320128],
              [-69.59053081611737, -14.281830031201897],
              [-69.58516713817316, -14.276169007153403],
              [-69.57946749339948, -14.271869203354404],
              [-69.57177723922864, -14.26972132273801],
              [-69.56477326934454, -14.266787414427922],
              [-69.55987510512982, -14.26700855723784],
              [-69.5537181532722, -14.26644427045138],
              [-69.54830962676971, -14.26294315309505],
              [-69.54369329812789, -14.259144952549708],
              [-69.5385058449609, -14.252812056656497],
              [-69.53520237304294, -14.248620688599717],
              [-69.52922115348103, -14.244493850718802],
              [-69.52323902542666, -14.241321474305364],
              [-69.5189672022309, -14.239061890343937],
              [-69.51549525560073, -14.236514506298109],
              [-69.51270297737629, -14.232208741913357],
              [-69.50899550165616, -14.22672022815033],
              [-69.50511910632822, -14.222875747902517],
              [-69.50159483511169, -14.220552212819882],
              [-69.4972072351663, -14.220667074660554],
              [-69.49298901435195, -14.222028442786028],
              [-69.48849491372806, -14.225481464994559],
              [-69.48536714631734, -14.228308639137111],
              [-69.48308622524837, -14.232560105494093],
              [-69.48155220081192, -14.23674779050552],
              [-69.48058370084445, -14.240597375727187],
              [-69.48018592620802, -14.246027006684434],
              [-69.48075924180202, -14.252424661473748],
              [-69.48241410126441, -14.259333684067087],
              [-69.48435178797408, -14.265105686085795],
              [-69.48770937295885, -14.271955214281196],
              [-69.48970774625224, -14.275576502473909],
              [-69.49323986322032, -14.282709260726577],
              [-69.49574284169182, -14.287179724907773],
              [-69.50041965657266, -14.293627662514416],
              [-69.50292209179167, -14.29713458722091],
              [-69.50515024914961, -14.300815256672744],
              [-69.50805618314644, -14.305628263367703],
              [-69.51090251007318, -14.310665022907433],
              [-69.51375623836584, -14.314738408122885],
              [-69.516319533472, -14.318985172914191],
              [-69.51871510162917, -14.323912457093593],
              [-69.5208204263531, -14.329004133137381],
              [-69.52270536228654, -14.33404569388829],
              [-69.52446745939503, -14.339534689561711],
              [-69.52618373021497, -14.345247772822248],
              [-69.52806072613572, -14.35028919097478],
              [-69.52971636364008, -14.355271465332633],
              [-69.53148630115241, -14.3588335440966],
              [-69.53262346420259, -14.362966334158857],
              [-69.53245547493266, -14.367492170240366],
              [-69.52681009998773, -14.367775628397697],
              [-69.52162921873534, -14.36625065752719],
              [-69.51877572244163, -14.363140670875051],
              [-69.5150147694556, -14.359793482869406],
              [-69.51034507086892, -14.355272504186175],
              [-69.50652258796063, -14.351194424346437],
              [-69.5018544202256, -14.344737349697589],
              [-69.49940504868611, -14.340042820415432],
              [-69.49769570517363, -14.336247996132427],
              [-69.49541434766087, -14.330873016400744],
              [-69.49205019337373, -14.326913642656168],
              [-69.48789201884225, -14.323232936461663],
              [-69.48378743763769, -14.32029184107831],
              [-69.48031612002603, -14.3138896434736],
              [-69.47911882004307, -14.309989721391446],
              [-69.47700506890949, -14.305852342989278],
              [-69.47450266880877, -14.298500260156272],
              [-69.47330452959318, -14.294591345011279],
              [-69.47045865664255, -14.288590995710344],
              [-69.46612541941266, -14.28366355277658],
              [-69.46099879199996, -14.279987216764848],
              [-69.45632901762734, -14.278347480544705],
              [-69.45018051811303, -14.278744114630193],
              [-69.44551037592288, -14.281912462870302],
              [-69.44112369104234, -14.28490722853872],
              [-69.43651606105551, -14.286888229336986],
              [-69.43451743531185, -14.291928688207225],
              [-69.43548545941754, -14.29769602516161],
              [-69.4379351962026, -14.302399636674119],
              [-69.43969754316993, -14.305961609475895],
              [-69.44317619050386, -14.311400701433756],
              [-69.44596813316927, -14.314734708355832],
              [-69.4498369997792, -14.31858918556254],
              [-69.45354465617402, -14.322151727380588],
              [-69.45701587615434, -14.325663691797018],
              [-69.46186068841811, -14.329513937796708],
              [-69.46710207035868, -14.334661062213787],
              [-69.47205372239958, -14.339017976655981],
              [-69.47438825021611, -14.342251162964827],
              [-69.47518971995387, -14.347735713238968],
              [-69.4744493054172, -14.35357142732384],
              [-69.47160375927555, -14.3581512528921],
              [-69.46766683808247, -14.36030122707258],
              [-69.46208980639564, -14.362286595789401],
              [-69.45519286005695, -14.362737078028621],
              [-69.45006594935332, -14.362904793775494],
              [-69.44613646689878, -14.361200656986142],
              [-69.44175006062635, -14.359395680935547],
              [-69.43775978869158, -14.357915118129801],
              [-69.43177797703866, -14.353785400891015],
              [-69.429786627449, -14.351127296360039],
              [-69.42443035822872, -14.347390405526426],
              [-69.42044077991994, -14.343991838416178],
              [-69.41662553183237, -14.340876479284992],
              [-69.41304064115798, -14.336857149003306],
              [-69.41053745660395, -14.333349566538741],
              [-69.40825620781305, -14.327974447216922],
              [-69.40637996282952, -14.32293304516236],
              [-69.40472420623787, -14.318914284159803],
              [-69.40193936903295, -14.313653356536046],
              [-69.3994904965402, -14.30894960149546],
              [-69.39772774390778, -14.305387523450879],
              [-69.39675929678204, -14.301538103094716],
              [-69.39567620795899, -14.29621839685004],
              [-69.39504972028948, -14.291008529967346],
              [-69.39425629146706, -14.286478998730294],
              [-69.39266229088268, -14.280309928654447],
              [-69.39044169298725, -14.275674682263954],
              [-69.38725247261546, -14.271988935613901],
              [-69.38275893463187, -14.269667874608484],
              [-69.37865448799324, -14.269615805710055],
              [-69.37597609102508, -14.27351387116495],
              [-69.3750078573422, -14.277362360813386],
              [-69.37461135801779, -14.281836608348652],
              [-69.37500785895247, -14.286023535610946],
              [-69.3761442915991, -14.29015598145191],
              [-69.37642690897147, -14.294790386655064],
              [-69.37591596645224, -14.298757735616446],
              [-69.37374898623128, -14.30350507825022],
              [-69.36952944710937, -14.30679010378552],
              [-69.36441024629076, -14.306956529589794],
              [-69.3601910789845, -14.305424669565248],
              [-69.35803256296562, -14.301519984592161],
              [-69.35512504339236, -14.298632824785525],
              [-69.35125767165346, -14.296704118418994],
              [-69.34692403135908, -14.298509470267975],
              [-69.34436070106618, -14.301968470133689],
              [-69.34271298332214, -14.306601637446795],
              [-69.34265101217186, -14.310678452009386],
              [-69.34355971676675, -14.314751438974238],
              [-69.34560382993689, -14.320075941196842],
              [-69.3486785744794, -14.324209670038998],
              [-69.35215016962039, -14.328676975440034],
              [-69.35540032104065, -14.332130653846145],
              [-69.35687294582269, -14.336829360916626],
              [-69.35698742921946, -14.341189569535983],
              [-69.35726944287194, -14.348713851097182],
              [-69.35760541339073, -14.354087695845388],
              [-69.35800927156335, -14.358274762432664],
              [-69.35857431758794, -14.362753782432142],
              [-69.35987886905805, -14.3681237769318],
              [-69.36107590965322, -14.373959378018075],
              [-69.36254885075857, -14.37961252209985],
              [-69.36351763423424, -14.38346200698857],
              [-69.3647158368598, -14.387371001388658],
              [-69.36733269823283, -14.3942759577716],
              [-69.37165876839441, -14.400159397920408],
              [-69.37603774641491, -14.404864664157888],
              [-69.38037081692588, -14.408830538037233],
              [-69.38390373017893, -14.41211104200581],
              [-69.38817598023455, -14.41533712828117],
              [-69.39273086453596, -14.419362223603187],
              [-69.3960876354894, -14.422359349916702],
              [-69.39894139014, -14.42547953518983],
              [-69.40184043116722, -14.429330086749873],
              [-69.40383192054954, -14.433915345270435],
              [-69.40502876322508, -14.437815424457277],
              [-69.40508245774303, -14.442399588679336],
              [-69.40342670233898, -14.44896886084945],
              [-69.40177889234621, -14.452639099140784],
              [-69.39943745168006, -14.456148703071461],
              [-69.39630098582994, -14.458982017170785],
              [-69.3922499178777, -14.46157732433519],
              [-69.38758130199513, -14.462825245427185],
              [-69.3837584736851, -14.464516460719953],
              [-69.37823523024869, -14.465302533493832],
              [-69.37413084571233, -14.466203518515737],
              [-69.36871373150497, -14.465578379212722],
              [-69.36455554012066, -14.46189528272539],
              [-69.36222126657383, -14.456743448489458],
              [-69.3614880018916, -14.45007253720482],
              [-69.36211358931061, -14.444694687062642],
              [-69.36348761933434, -14.439262752089375],
              [-69.36525722492453, -14.43512772042665],
              [-69.36645568184207, -14.430375756383643],
              [-69.36007654083328, -14.423983693379915],
              [-69.35511754126595, -14.42154196438545],
              [-69.35181468398437, -14.419274970365903],
              [-69.34914412591716, -14.413557002303152],
              [-69.34664200544312, -14.408122184127103],
              [-69.34447433866363, -14.403253589409903],
              [-69.34282674179198, -14.399234760110268],
              [-69.33827178082181, -14.399062322975052],
              [-69.33462518517535, -14.401045294154088],
              [-69.33205438746536, -14.404494509760674],
              [-69.32954446786653, -14.409637638778229],
              [-69.32920116006437, -14.413887633135122],
              [-69.3292536927109, -14.418462255623405],
              [-69.33044386713227, -14.424297668309315],
              [-69.33072672283632, -14.429895157018485],
              [-69.32969661966244, -14.43685639574888],
              [-69.32832370966821, -14.444214451774346],
              [-69.32666751701686, -14.44885583139754],
              [-69.3233037249116, -14.451619366998814],
              [-69.31737479060148, -14.453033391492484],
              [-69.31327016881168, -14.455859807963945],
              [-69.31053206237836, -14.459979583633626],
              [-69.30978408217648, -14.464849753847986],
              [-69.3100657048306, -14.471410336042096],
              [-69.31011910805582, -14.476957114616349],
              [-69.30976891881943, -14.482161006014325],
              [-69.30754070647363, -14.487139219789904],
              [-69.30548915967258, -14.49143749546843],
              [-69.30320708464419, -14.49472185850966],
              [-69.29961344400662, -14.49838834967146],
              [-69.29328898457032, -14.496577871874152],
              [-69.28924570749132, -14.494363791756047],
              [-69.28485906095915, -14.491583161752853],
              [-69.27990014448045, -14.49107501786014],
              [-69.27482606293567, -14.491005054176476],
              [-69.27043920843288, -14.4911138258419],
              [-69.26479380803138, -14.493316117752467],
              [-69.26165752529671, -14.497101032530347],
              [-69.258743011272, -14.501908499478857],
              [-69.2554319798806, -14.504446737232492],
              [-69.25132749507618, -14.506312792423408],
              [-69.246940820004, -14.50930600495481],
              [-69.24254650680055, -14.513257562053731],
              [-69.23860938130471, -14.516365112634247],
              [-69.23416178216156, -14.518622842842637],
              [-69.23187282396603, -14.521901372578471],
              [-69.23010222297229, -14.526029775449967],
              [-69.22907227315463, -14.530099927420267],
              [-69.22872151611936, -14.534344367425371],
              [-69.22769144499114, -14.53841448829153],
              [-69.22546409049038, -14.54339110700522],
              [-69.22260330303178, -14.54700630996188],
              [-69.21798699415456, -14.549943368909341],
              [-69.21662635939153, -14.550378438816637],
              [-69.21687717912178, -14.551030570115312],
              [-69.21371990505544, -14.552259840182042],
              [-69.21321747597119, -14.551412459786208],
              [-69.20517751370345, -14.554542505025609],
              [-69.18619552047853, -14.576511976183099],
              [-69.16083556948342, -14.591898803494644],
              [-69.13392664278005, -14.621287935349926],
              [-69.12152058787852, -14.653311834651845],
              [-69.1058955468785, -14.67996125763267],
              [-69.10750554284195, -14.681850035695987],
              [-69.11177863708477, -14.690855384838017],
              [-69.11640963859351, -14.699174088471267],
              [-69.1178136073767, -14.706780330333942],
              [-69.11779064096199, -14.711616185556466],
              [-69.11631056449788, -14.720591695123147],
              [-69.11413562825989, -14.72541631859545],
              [-69.1072926425011, -14.728488956664364],
              [-69.09866364081199, -14.72947701541159],
              [-69.09076654690887, -14.728053097698819],
              [-69.0815736107736, -14.727067218246418],
              [-69.07666064204177, -14.741721299581684],
              [-69.07218956724577, -14.75299546227734],
              [-69.0776515506361, -14.76598255070752],
              [-69.07942164095034, -14.771519133036207],
              [-69.0836945675551, -14.779834651546224],
              [-69.09081264737638, -14.794381612158816],
              [-69.08788250166157, -14.807148927087553],
              [-69.0867995597703, -14.808524732676034],
              [-69.07815563829354, -14.81262163931396],
              [-69.07058761790938, -14.816728268959565],
              [-69.0655286364241, -14.822572802411173],
              [-69.05796061603995, -14.827024766467929],
              [-69.04896549281982, -14.829391313012678],
              [-69.04573058110527, -14.830065385666671],
              [-69.03601059089785, -14.834503100488007],
              [-69.02987654651028, -14.838616938570368],
              [-69.02230064713714, -14.845139400351428],
              [-69.01652551584388, -14.850635078992553],
              [-69.00645449153114, -14.853345786482038],
              [-68.99278260161088, -14.856379700158243],
              [-68.98198654267279, -14.859085713781923],
              [-68.97119853036176, -14.860753544877326],
              [-68.96472954512299, -14.861063340018973],
              [-68.94784554226484, -14.859590639629658],
              [-68.93741661068618, -14.861607325535573],
              [-68.93201464172265, -14.865033009366556],
              [-68.92655148486587, -14.88020207483342],
              [-68.92077652121067, -14.88569892694099],
              [-68.91175859921388, -14.892903340365052],
              [-68.90924050785956, -14.89323626955948],
              [-68.89951364449153, -14.898019151153903],
              [-68.88909158607349, -14.8983108413847],
              [-68.88047063101145, -14.897916053744751],
              [-68.87181849526958, -14.904433486383937],
              [-68.86711859451667, -14.909935199995346],
              [-68.8624346193798, -14.91198424004753],
              [-68.85489660620904, -14.910907333126545],
              [-68.84986863776554, -14.909495317716278],
              [-68.84159854917075, -14.910488237967371],
              [-68.83514364552933, -14.907344353721413],
              [-68.83370950189467, -14.90733630709434],
              [-68.82181558127765, -14.91556280979259],
              [-68.81782562772436, -14.922449549085997],
              [-68.81060763561713, -14.92932002748725],
              [-68.8084186177818, -14.936562494751769],
              [-68.80767849573071, -14.941742343280936],
              [-68.80368753634905, -14.949666762188457],
              [-68.79896550737173, -14.960004163384355],
              [-68.78852852916602, -14.964093861585525],
              [-68.77594762776408, -14.965062809593519],
              [-68.76911151516589, -14.966406596311856],
              [-68.75978061290424, -14.963592623775156],
              [-68.74829857900963, -14.960419905782231],
              [-68.73931150241656, -14.961406958701104],
              [-68.73319254545471, -14.962411781254701],
              [-68.73139160973676, -14.96343789384241],
              [-68.72454057735091, -14.968582538378229],
              [-68.71695763717908, -14.976832845681486],
              [-68.71550756792838, -14.978897643711662],
              [-68.71401257167662, -14.991325826837596],
              [-68.71470659326019, -14.99720154096903],
              [-68.718284492453, -15.001022180078024],
              [-68.7193525145566, -15.0017193867848],
              [-68.73120854897124, -15.003165097445844],
              [-68.74416361853127, -14.999435318167372],
              [-68.75601160631885, -15.001572368202972],
              [-68.76892057541137, -15.007168797320361],
              [-68.7771604891547, -15.01205058537235],
              [-68.7850346166431, -15.019693204694846],
              [-68.7911076407755, -15.026981772426865],
              [-68.80432154350582, -15.045707279414358],
              [-68.81218762436718, -15.054042914492044],
              [-68.81900060291258, -15.057533809529815],
              [-68.82510363425837, -15.057912503915617],
              [-68.83805048955328, -15.056600903705515],
              [-68.85063960522034, -15.05390394253726],
              [-68.86000856132245, -15.047737376365347],
              [-68.86791252838619, -15.048817133133412],
              [-68.87543461594089, -15.053692886215117],
              [-68.87538952130177, -15.06371328383264],
              [-68.87464152026169, -15.069234778735563],
              [-68.87676951784185, -15.07581155524926],
              [-68.88033249724698, -15.082049199960181],
              [-68.88642161463349, -15.08691673877675],
              [-68.89286059265886, -15.092826315797055],
              [-68.89643061286267, -15.098027119084207],
              [-68.89783458164587, -15.104597525351494],
              [-68.89637763923456, -15.10942717796567],
              [-68.89204453056495, -15.113205069368433],
              [-68.8837885235675, -15.111086291881776],
              [-68.87840264785808, -15.110365280569965],
              [-68.87442761409245, -15.114836858280142],
              [-68.8751226415044, -15.121403576510033],
              [-68.87832654017717, -15.126948037827702],
              [-68.89408150067653, -15.139815432680422],
              [-68.89980349070362, -15.146064812055783],
              [-68.91519953810867, -15.15720218209151],
              [-68.92308053875769, -15.163118799910507],
              [-68.92880252878476, -15.169369352752312],
              [-68.93416560571754, -15.17458021432327],
              [-68.93772154432399, -15.182891541881702],
              [-68.94127664474014, -15.190510021323036],
              [-68.94194753227092, -15.200876423903935],
              [-68.94155157116455, -15.208474786777629],
              [-68.93901051339552, -15.213987732139316],
              [-68.93215948100965, -15.220169218098903],
              [-68.92639155815313, -15.22290305964114],
              [-68.91345258184721, -15.22386681086914],
              [-68.90087855360589, -15.222764087686357],
              [-68.89693453288206, -15.221705956228504],
              [-68.89046454181491, -15.221326088376259],
              [-68.88255353395249, -15.223010012725751],
              [-68.87353561195569, -15.22952358968945],
              [-68.87100964161239, -15.232619529449238],
              [-68.86880453052294, -15.242627689488103],
              [-68.87344357865874, -15.249906198936287],
              [-68.87771650526349, -15.258219873427635],
              [-68.88343061630158, -15.265853272656614],
              [-68.89663664004291, -15.285615956344003],
              [-68.90378556526791, -15.293253546524596],
              [-68.91022454329328, -15.299852115986567],
              [-68.91593949252169, -15.306794678755182],
              [-68.91986859345786, -15.313033329294484],
              [-68.92307249213063, -15.318577790612153],
              [-68.9241485608613, -15.322994550675531],
              [-68.91875463852482, -15.319935658927818],
              [-68.91158257924704, -15.317133923969806],
              [-68.89797254777227, -15.31279327158731],
              [-68.88682562236693, -15.308362429926603],
              [-68.87033858644354, -15.30032804044933],
              [-68.85636863604608, -15.290578713580743],
              [-68.84775555997304, -15.287076922068849],
              [-68.84596250324408, -15.287067031423106],
              [-68.83985863370798, -15.285998003491102],
              [-68.82188448052182, -15.28659093932228],
              [-68.816497598984, -15.285872107305295],
              [-68.80143749825854, -15.278534253982542],
              [-68.78961951768434, -15.270178837613372],
              [-68.78247059245935, -15.26150541183739],
              [-68.77711455632526, -15.254222040885338],
              [-68.76817358019969, -15.245536377530726],
              [-68.75886564435275, -15.237883197010234],
              [-68.74666561663147, -15.233327464992072],
              [-68.73660263894578, -15.233965160186273],
              [-68.72977457297465, -15.234964283045713],
              [-68.71467558021841, -15.235226133701161],
              [-68.69850957118695, -15.234103293950739],
              [-68.69419859074173, -15.234080159897928],
              [-68.67802453508321, -15.235028153147965],
              [-68.66544363368128, -15.23496025973219],
              [-68.65254254357775, -15.228326486276842],
              [-68.64034251585647, -15.223770083706427],
              [-68.63495664014704, -15.223049910584905],
              [-68.62129950237639, -15.222974976370466],
              [-68.59792354786475, -15.227339600996032],
              [-68.58497652493178, -15.230033544679117],
              [-68.57165550147876, -15.23514315285965],
              [-68.56446064342433, -15.236140096424265],
              [-68.54289249779126, -15.23809492388466],
              [-68.53533151820578, -15.241508705413139],
              [-68.51487749514382, -15.235180200871696],
              [-68.50267059426193, -15.232694463663677],
              [-68.48796856844045, -15.225705968065625],
              [-68.48581659861718, -15.225004905683363],
              [-68.47683756865115, -15.22288093141674],
              [-68.47504451192219, -15.223217045734373],
              [-68.46747548570966, -15.227669847981446],
              [-68.46311957826339, -15.237317921459578],
              [-68.46343959932692, -15.244918631266131],
              [-68.46698749130633, -15.254956798518407],
              [-68.4777066043732, -15.267797203642886],
              [-68.4855886108506, -15.27371164216703],
              [-68.49417855287085, -15.280669292361381],
              [-68.50135061214863, -15.285199040479682],
              [-68.5031205348248, -15.290044618709913],
              [-68.51028454747552, -15.294574366828215],
              [-68.51851658222986, -15.302908996077491],
              [-68.5253146409876, -15.308129413018094],
              [-68.53321056142428, -15.311281176253061],
              [-68.54180955589997, -15.315818970998407],
              [-68.54502854199848, -15.319982765223727],
              [-68.55648056867969, -15.330062171439636],
              [-68.56434648190297, -15.339087469511242],
              [-68.57005355214238, -15.347756033783355],
              [-68.57466862803508, -15.359526069871322],
              [-68.58179458684535, -15.372345520237843],
              [-68.58750953607375, -15.380668917736898],
              [-68.59178950347719, -15.387600416393298],
              [-68.59642754578459, -15.394536944191628],
              [-68.60248548249126, -15.404933186347876],
              [-68.60460660691082, -15.413233785070247],
              [-68.60888657431424, -15.420167295383436],
              [-68.60883360068613, -15.431914700332811],
              [-68.60379054481689, -15.433612371003562],
              [-68.59196451761565, -15.426985973623005],
              [-68.58586852706853, -15.424877924972407],
              [-68.57901749468267, -15.428988745569626],
              [-68.574531500099, -15.433020441000764],
              [-68.5725245372008, -15.43482523239416],
              [-68.57250961741313, -15.438279079419857],
              [-68.57675153097611, -15.453503130171583],
              [-68.58137549168619, -15.462508646951676],
              [-68.58314558200043, -15.468739250863905],
              [-68.58168059296206, -15.475293899153229],
              [-68.581672546335, -15.47667557207393],
              [-68.56979354550563, -15.480757391286119],
              [-68.56153854433659, -15.478984618762865],
              [-68.5550765998965, -15.477223748542116],
              [-68.55077349844028, -15.475818606292478],
              [-68.54180955589997, -15.470933130203093],
              [-68.52600061594411, -15.470846964238376],
              [-68.52239958269853, -15.471171008615443],
              [-68.50404355000335, -15.47832764519137],
              [-68.49971748213241, -15.480377355795781],
              [-68.49539158189953, -15.48415306790372],
              [-68.50681259553896, -15.500108020613027],
              [-68.51038362157115, -15.50634382130525],
              [-68.51214549762027, -15.512572413560719],
              [-68.51286349144695, -15.513612440107693],
              [-68.51641859186309, -15.522268599163084],
              [-68.51781451401924, -15.52987634976833],
              [-68.5174405134992, -15.533675866481303],
              [-68.5113066367497, -15.53916869527535],
              [-68.5052414916063, -15.530498119346476],
              [-68.50129663269213, -15.528058314967893],
              [-68.49591863597169, -15.525267644122067],
              [-68.49053963342287, -15.522127783189632],
              [-68.48082752220445, -15.525184663280555],
              [-68.47722648895886, -15.52723957066496],
              [-68.47074157810404, -15.52996620377047],
              [-68.45924361859339, -15.529558340361575],
              [-68.45210257235739, -15.519848073161825],
              [-68.44709757032861, -15.514294224112604],
              [-68.4384995816813, -15.508718749753172],
              [-68.42878763810093, -15.5100474490458],
              [-68.42085249035735, -15.51760390240355],
              [-68.41795352532239, -15.521389672795294],
              [-68.41504651366037, -15.529318282654401],
              [-68.41320752410196, -15.53725577733087],
              [-68.41062153933187, -15.552786773377164],
              [-68.4095385974406, -15.554509757395522],
              [-68.40413662847706, -15.556897593974384],
              [-68.39263950715672, -15.556835400252737],
              [-68.38834361413724, -15.553356742793625],
              [-68.38224058279145, -15.55125087343788],
              [-68.3804475260625, -15.550204979558686],
              [-68.36677563614224, -15.553584898198295],
              [-68.36531852609286, -15.5577223732476],
              [-68.3663716284088, -15.563603116520966],
              [-68.36849962598896, -15.569832714604814],
              [-68.37171157128878, -15.57399533536369],
              [-68.37744160794291, -15.57920720276303],
              [-68.3824466099717, -15.584762895830949],
              [-68.38996148908967, -15.591713337588573],
              [-68.39173862020259, -15.595521068566654],
              [-68.39352463613287, -15.597604977336061],
              [-68.40068848114554, -15.603517571841536],
              [-68.40893560332559, -15.607015340039908],
              [-68.41502354724565, -15.611538885549834],
              [-68.42542263924898, -15.615741907082054],
              [-68.43870560886154, -15.618577504928936],
              [-68.44911157402548, -15.622780358823093],
              [-68.45303363416298, -15.62867384258928],
              [-68.4555124982104, -15.63628729288871],
              [-68.45545164559329, -15.64941469437818],
              [-68.45680264074836, -15.667731499766461],
              [-68.45489458430772, -15.691558065393224],
              [-68.45342255447069, -15.699496398260038],
              [-68.45232351932529, -15.70432823016904],
              [-68.44940963450267, -15.712255666561703],
              [-68.446166508523, -15.71396540717302],
              [-68.43682051883562, -15.714951286625421],
              [-68.42746748834956, -15.716974175139683],
              [-68.42278250738431, -15.720057039130467],
              [-68.41664863063482, -15.724861881311227],
              [-68.41484048648013, -15.728304496586674],
              [-68.41446648596009, -15.731067507151977],
              [-68.40977463183424, -15.735878049026923],
              [-68.40721848663947, -15.743810682199552],
              [-68.40253451150261, -15.746548379417248],
              [-68.39715550895379, -15.74479153251005],
              [-68.3957215329572, -15.744091643594231],
              [-68.38961062262243, -15.744404456221048],
              [-68.37562558479924, -15.737074146611178],
              [-68.34980060581552, -15.727261620192593],
              [-68.34190351191239, -15.724799855227673],
              [-68.33473949926167, -15.720616112072804],
              [-68.32218961090152, -15.715021359336049],
              [-68.31140863938916, -15.713579504350463],
              [-68.30062062707812, -15.715248341274247],
              [-68.28767360414516, -15.717598124012625],
              [-68.26788359545337, -15.723019036077375],
              [-68.25387559121546, -15.722941922568083],
              [-68.24524658952637, -15.722549817137178],
              [-68.23879252407528, -15.720440092105946],
              [-68.22764559866994, -15.722107085011032],
              [-68.22331249000032, -15.726230646101072],
              [-68.21862750903507, -15.728276668668087],
              [-68.21895557672566, -15.735189056585256],
              [-68.22395353795578, -15.743160581788658],
              [-68.23074355008646, -15.751144009294563],
              [-68.23681657421885, -15.759122742934693],
              [-68.24073762852798, -15.765707063161273],
              [-68.24250755120416, -15.771590656281717],
              [-68.24284349788374, -15.77608268583569],
              [-68.24137850884537, -15.782985183107087],
              [-68.23199463295559, -15.790532584009412],
              [-68.22407557846613, -15.79429253813936],
              [-68.21327951952804, -15.797342209793555],
              [-68.20355248852194, -15.801779924614891],
              [-68.199218541662, -15.80728264405468],
              [-68.1988375003433, -15.811771320847384],
              [-68.20742761000162, -15.8194198075021],
              [-68.21675163910267, -15.823271459652858],
              [-68.22031361267942, -15.829852091842042],
              [-68.22315960408626, -15.836433729859579],
              [-68.22636450858741, -15.843015367877143],
              [-68.22380853103071, -15.851291826718352],
              [-68.21694961965585, -15.857471468659213],
              [-68.21045649453592, -15.862619130680173],
              [-68.1999895091168, -15.873961354429696],
              [-68.19458049935459, -15.877733043224083],
              [-68.18412055473415, -15.885966586720997],
              [-68.18267048548346, -15.888033564045998],
              [-68.17762759725228, -15.890769249606933],
              [-68.15927156455709, -15.897577869562696],
              [-68.14812463915176, -15.897864865927687],
              [-68.1359175706318, -15.896415802505373],
              [-68.12657962757147, -15.894637833202125],
              [-68.10684158667942, -15.88865751291894],
              [-68.10038752122833, -15.885513628672982],
              [-68.0835264847849, -15.880583728496703],
              [-68.07312756041964, -15.87569104397059],
              [-68.06021155052844, -15.870441625644986],
              [-68.04909563816489, -15.866234748437307],
              [-68.04190061247238, -15.866541693731875],
              [-68.0400845893287, -15.872059500597402],
              [-68.04181662580248, -15.884850452493112],
              [-68.0468135812042, -15.891786309739189],
              [-68.05430549390746, -15.904955285468446],
              [-68.06394149744499, -15.919171496181434],
              [-68.07358554760957, -15.930970701292495],
              [-68.07929949100959, -15.939638259736228],
              [-68.0842895732507, -15.947956460455288],
              [-68.09069854406268, -15.960080883409859],
              [-68.09606162099546, -15.965637414668095],
              [-68.10250059902081, -15.972928161694938],
              [-68.11033650503072, -15.987132973019584],
              [-68.11026760578655, -16.003371066418453],
              [-68.10807054132415, -16.01061252785459],
              [-68.10374464109128, -16.01369857696858],
              [-68.09727448238607, -16.014009377938606],
              [-68.08613560360779, -16.014985366745265],
              [-68.08433550608015, -16.015321481062927],
              [-68.07604949186931, -16.019077076603224],
              [-68.07495163019037, -16.024254745837567],
              [-68.08245863031935, -16.03292716578514],
              [-68.09142257285964, -16.037124990537393],
              [-68.10002860813401, -16.039933766294098],
              [-68.10612459868113, -16.044112480307064],
              [-68.11543253452807, -16.05176297861854],
              [-68.11934654803852, -16.05869347144659],
              [-68.11934654803852, -16.059729642318104],
              [-68.12181853892533, -16.06803493490625],
              [-68.1242675633974, -16.08324691571741],
              [-68.1263806411899, -16.091896536888328],
              [-68.12525964545817, -16.102254892842183],
              [-68.12233754637043, -16.112603190512232],
              [-68.12232949974337, -16.113639193745655],
              [-68.11834759281713, -16.12018059862794],
              [-68.11581458167515, -16.123625560836246],
              [-68.10178361102253, -16.127001120886206],
              [-68.09568057967674, -16.126276253898936],
              [-68.08993562323494, -16.12451655714463],
              [-68.07955161865735, -16.116861867881568],
              [-68.07524851720112, -16.114421057674605],
              [-68.06843553865572, -16.1116191550785],
              [-68.05981458359368, -16.111573222249064],
              [-68.04902657128265, -16.112897060037824],
              [-68.03212748099878, -16.1145322017108],
              [-68.02458158883903, -16.11448995691876],
              [-68.01453353094101, -16.110635119644797],
              [-68.00736951829029, -16.106105706802623],
              [-67.99661251902103, -16.100865843846663],
              [-67.99159963800325, -16.096693332442044],
              [-67.98548151923174, -16.099078989726053],
              [-67.98257450756972, -16.105971428713644],
              [-67.99043254180403, -16.116378064429824],
              [-68.00541653484859, -16.139260492482038],
              [-68.01110063867328, -16.154491416394364],
              [-68.01215357335116, -16.158989145642522],
              [-68.01643354075459, -16.165921650127302],
              [-68.01784555616484, -16.171111556940303],
              [-68.02105750146465, -16.177346184166083],
              [-68.0206605345299, -16.183906532149535],
              [-68.01811260360026, -16.1918389976841],
              [-68.00946851448543, -16.196284926770574],
              [-68.00550856814554, -16.19660695949088],
              [-68.00155650079463, -16.196586004732893],
              [-67.99201957135274, -16.190744321128363],
              [-67.97051948677351, -16.21640266787682],
              [-67.92887852703527, -16.265378631090726],
              [-67.91730462748177, -16.29280589468695],
              [-67.90394555018831, -16.31782319347994],
              [-67.89784955964119, -16.32039191152947],
              [-67.8888395166334, -16.32517764297097],
              [-67.88307159377688, -16.329981311685287],
              [-67.87908951921257, -16.335835232868476],
              [-67.87438961845966, -16.341682616167162],
              [-67.87329058331427, -16.347202267051415],
              [-67.87470259872453, -16.352738849380074],
              [-67.87580850703053, -16.354465018521637],
              [-67.85952749828738, -16.37414354790107],
              [-67.8278965426067, -16.409743502377097],
              [-67.79504350544188, -16.445420067448197],
              [-67.77223953200347, -16.467708889116977],
              [-67.74672652745623, -16.485504507765313],
              [-67.72460953480272, -16.499608736251787],
              [-67.71206651960318, -16.511984951577972],
              [-67.70613850239586, -16.533276829682137],
              [-67.69400050075814, -16.566815674165383],
              [-67.64807856778808, -16.567729469250423],
              [-67.59284249636272, -16.576029062144414],
              [-67.54079456563215, -16.60045560440105],
              [-67.5308375352027, -16.61950817325075],
              [-67.50954448363211, -16.654187962395326],
              [-67.5119016424453, -16.668022125961215],
              [-67.5106425129491, -16.668246258052335],
              [-67.50381461461603, -16.66924504563565],
              [-67.4966276355506, -16.668864171954993],
              [-67.48477948012494, -16.665688268838863],
              [-67.47760758848523, -16.662886701518886],
              [-67.4725345254026, -16.66071260347121],
              [-67.46899451241218, -16.660075746467356],
              [-67.46468353196698, -16.660052612414574],
              [-67.45855753420646, -16.66416460647821],
              [-67.45672659127511, -16.670717913662997],
              [-67.45414747966564, -16.674587670724634],
              [-67.45133250130057, -16.672762092211343],
              [-67.45028660742136, -16.665503531692707],
              [-67.44905849096695, -16.661389693610374],
              [-67.4474255285888, -16.6623777523576],
              [-67.4409635841487, -16.66130553930239],
              [-67.43878948610103, -16.663712654258575],
              [-67.43300664345685, -16.671277992433716],
              [-67.42364456051534, -16.676065735531978],
              [-67.42220354372009, -16.67640268803993],
              [-67.41608458675825, -16.67844368146507],
              [-67.40637951633849, -16.679426543432328],
              [-67.39300551925736, -16.676460858448024],
              [-67.39451560293486, -16.680742334594044],
              [-67.39877360975194, -16.69147787619113],
              [-67.39982553860145, -16.697701439304694],
              [-67.40080253323649, -16.709968522251444],
              [-67.3736495255124, -16.72158500187311],
              [-67.3341975836095, -16.750671211747374],
              [-67.29980462319186, -16.779449135222535],
              [-67.30025456375473, -16.78076107070879],
              [-67.3038405095746, -16.782510206265073],
              [-67.30958563365446, -16.784268059000652],
              [-67.31352261357964, -16.787399873306043],
              [-67.3268055831922, -16.78954161720742],
              [-67.3357924921472, -16.789247747681827],
              [-67.34191848990771, -16.785826925354684],
              [-67.35272259547286, -16.780704241405203],
              [-67.35955854043299, -16.77832126633021],
              [-67.36388360247554, -16.775926556590747],
              [-67.37037655995742, -16.770780738588485],
              [-67.3732684841937, -16.76768697812355],
              [-67.38013460400529, -16.759087648371747],
              [-67.38806153748375, -16.753946859511387],
              [-67.39455449496563, -16.74880120914719],
              [-67.39817849462594, -16.74329362820353],
              [-67.40447950652491, -16.737923007557896],
              [-67.40465552649177, -16.74125665809194],
              [-67.40745558506916, -16.756470818197926],
              [-67.41100364468663, -16.766854990413577],
              [-67.41489452414429, -16.779311336734224],
              [-67.41522963263355, -16.785187889055976],
              [-67.41696954809633, -16.797979176227813],
              [-67.41796163015711, -16.815601960032524],
              [-67.42323250615486, -16.84119543085032],
              [-67.42174555653015, -16.851551942785477],
              [-67.42167648964792, -16.86709769098138],
              [-67.42375956022703, -16.883344836835676],
              [-67.4193956061537, -16.89334109457201],
              [-67.41178852610071, -16.90746443379774],
              [-67.40634950912509, -16.916761640808602],
              [-67.4073635517722, -16.9189687635548],
              [-67.39266957257777, -16.922905240565797],
              [-67.38438456419533, -16.926314998780725],
              [-67.37357358546957, -16.932820696755442],
              [-67.3663715189784, -16.936926320572667],
              [-67.36204561874553, -16.93897519298676],
              [-67.35125760643449, -16.940993387635245],
              [-67.33978261333856, -16.93643447049388],
              [-67.33403748925869, -16.934332624451628],
              [-67.32900952081519, -16.934651472048728],
              [-67.32217458168344, -16.935996599871572],
              [-67.3146055554709, -16.940791216130464],
              [-67.3045274903595, -16.944882087798078],
              [-67.29695863178502, -16.94864086846158],
              [-67.2886426103608, -16.95999700617037],
              [-67.2818064977626, -16.962376796122157],
              [-67.27675656873276, -16.965458821922624],
              [-67.2644955207563, -16.974720154387853],
              [-67.25294458761753, -16.98537053584863],
              [-67.24282058967667, -16.999821607488798],
              [-67.23953253669595, -17.011550907527322],
              [-67.23483263594304, -17.01739644680731],
              [-67.2175365787244, -17.026631963010743],
              [-67.20819058903702, -17.027962673960104],
              [-67.19920351244394, -17.027912717817145],
              [-67.18877458086529, -17.029583734035754],
              [-67.18261757006299, -17.039915100261368],
              [-67.18150361512994, -17.04785561242298],
              [-67.18506659453507, -17.05512926036735],
              [-67.1882785398349, -17.05894604380086],
              [-67.19123852512503, -17.06792842652817],
              [-67.18572960307687, -17.0668763300406],
              [-67.17749052752386, -17.06165172214841],
              [-67.17067754897846, -17.058504988055375],
              [-67.16170455398272, -17.055347189850096],
              [-67.15668463216628, -17.05393919775338],
              [-67.14411160975334, -17.05248845795043],
              [-67.1268695319912, -17.051702403069896],
              [-67.12219259765301, -17.0520244357902],
              [-67.11249557386031, -17.051625289560604],
              [-67.10674257079145, -17.051593438328524],
              [-67.09488653637682, -17.051185407281565],
              [-67.08986661456036, -17.04874057376108],
              [-67.08448761201156, -17.04663872771883],
              [-67.0784305134952, -17.03554930216933],
              [-67.07702654471201, -17.0272517209321],
              [-67.07853662838951, -17.012058850860228],
              [-67.08148152625391, -16.996874697967627],
              [-67.08371748274708, -16.980304848840802],
              [-67.08628854772951, -16.968571357850692],
              [-67.08847052476617, -16.962020062322665],
              [-67.08994255460321, -16.95477239827818],
              [-67.08891258634006, -16.94405814671515],
              [-67.09075157589845, -16.93577665873204],
              [-67.09796152137862, -16.93097969554026],
              [-67.10483552017921, -16.92099936341998],
              [-67.10736853132119, -16.91617792507091],
              [-67.10175349437873, -16.888162419509285],
              [-67.0885395916484, -16.86978057055228],
              [-67.07707951834013, -16.862465180730084],
              [-67.07313549761629, -16.859333366424693],
              [-67.063102527144, -16.85340954016897],
              [-67.05630463602432, -16.847496945663494],
              [-67.0484236353753, -16.841927506274345],
              [-67.03229551254623, -16.83251194679056],
              [-67.02297953007223, -16.826242283209467],
              [-67.0172955938856, -16.812394373322377],
              [-67.01451162856233, -16.793722510515238],
              [-67.0239255116655, -16.777884401536284],
              [-67.02716863764516, -16.775481477531713],
              [-67.04118351504368, -16.776595600102837],
              [-67.0641705492476, -16.77810048700036],
              [-67.07816363369786, -16.7837059685732],
              [-67.09429158888886, -16.79208233970033],
              [-67.10610956946306, -16.801128089615702],
              [-67.12437457468968, -16.814010068979968],
              [-67.13260660944401, -16.82096771917432],
              [-67.13906050725704, -16.824108418297072],
              [-67.14873456463502, -16.83072609849833],
              [-67.15251949683646, -16.82837111897996],
              [-67.15127562240406, -16.825210303289566],
              [-67.15021547928944, -16.821062602318364],
              [-67.14524853110112, -16.80755969156627],
              [-67.14311952769256, -16.802365761439745],
              [-67.13921356080917, -16.791979577567304],
              [-67.13350649056976, -16.781930010926715],
              [-67.12922652316634, -16.775691528025504],
              [-67.11814162384455, -16.763538271323966],
              [-67.11421956370704, -16.757298614956312],
              [-67.1106565843019, -16.75071613874843],
              [-67.1095885621983, -16.74932809558129],
              [-67.10674257079145, -16.742404643551936],
              [-67.09923557066247, -16.733381189499028],
              [-67.09278150521138, -16.730581298559684],
              [-67.08488457894632, -16.72950254762],
              [-67.07912453507879, -16.73119921246237],
              [-67.07048061360203, -16.734952963983943],
              [-67.06508652362749, -16.735615134335433],
              [-67.06184356528588, -16.736978869983346],
              [-67.05465658622043, -16.738666985284453],
              [-67.050376618817, -16.731388140560114],
              [-67.04860652850276, -16.72446871184428],
              [-67.04972853006288, -16.715493369915663],
              [-67.0497514964776, -16.711694523754943],
              [-67.05012549699764, -16.707204673495795],
              [-67.0530705625001, -16.693056523836674],
              [-67.0538175577118, -16.687188018141967],
              [-67.05313862355395, -16.67751178146915],
              [-67.04846956820475, -16.67748680339767],
              [-67.04127454251226, -16.678830590116007],
              [-67.0301286229353, -16.681186407824697],
              [-67.02543660117138, -16.684270110005798],
              [-67.01535753023158, -16.68801598253839],
              [-67.00887261937676, -16.691780798172175],
              [-66.99805459985234, -16.700012330012328],
              [-66.99121060826515, -16.703084968081242],
              [-66.98579455770427, -16.709619499802898],
              [-66.9843295686659, -16.714793480999845],
              [-66.98246761269277, -16.72894733035315],
              [-66.98350562758299, -16.736898906626976],
              [-66.98958552487598, -16.743840631205273],
              [-66.99278255038814, -16.752495952070376],
              [-66.99560557538025, -16.762528251990403],
              [-66.99053150646924, -16.77182964995285],
              [-66.98403150818869, -16.779738143244316],
              [-66.97899649894653, -16.7807471567495],
              [-66.97360257661003, -16.781409159462925],
              [-66.96353959892436, -16.78170118496982],
              [-66.95024154188607, -16.780593097369007],
              [-66.94665559606621, -16.7795342953589],
              [-66.93805660159052, -16.77465351313529],
              [-66.93090063556684, -16.768395919494793],
              [-66.92480464501973, -16.764908041942192],
              [-66.91334557753986, -16.756208799904414],
              [-66.90367152016188, -16.749937962856905],
              [-66.89005260386973, -16.742612514750874],
              [-66.87389363563693, -16.739066802066304],
              [-66.85379047904223, -16.735159158802247],
              [-66.8440936228876, -16.73372518280567],
              [-66.82971949711865, -16.73295605936957],
              [-66.8135685755129, -16.729416381655284],
              [-66.80674755034045, -16.72764847063587],
              [-66.80029247906096, -16.72485075899138],
              [-66.79386154766264, -16.725657768629844],
              [-66.79415156151279, -16.731381267399513],
              [-66.79376951436569, -16.73656111592868],
              [-66.79517348314889, -16.744168363619735],
              [-66.79475354979941, -16.75763975841585],
              [-66.79290047864366, -16.769030094289633],
              [-66.7925796193898, -16.7763074302714],
              [-66.79141252319056, -16.779386270948663],
              [-66.7859875878123, -16.787991635670778],
              [-66.7794795429047, -16.79624713975403],
              [-66.7747804803421, -16.80278971810276],
              [-66.7693635915909, -16.80690355618509],
              [-66.75891856675813, -16.812028251791332],
              [-66.74630749050473, -16.820597574329753],
              [-66.73802952292095, -16.822626497814298],
              [-66.72687555671695, -16.82601932458479],
              [-66.72183250084771, -16.82806417368539],
              [-66.71211955143896, -16.829738039751078],
              [-66.70455957768186, -16.833150815451177],
              [-66.69303848411843, -16.837926656246935],
              [-66.68583658526532, -16.84065161297181],
              [-66.68043562213016, -16.843385286875986],
              [-66.67214156129226, -16.84921490053995],
              [-66.66815948672797, -16.856445633139998],
              [-66.66671763174239, -16.857132278648777],
              [-66.66381062008037, -16.86436804039073],
              [-66.65830253622254, -16.871903371352488],
              [-66.66165160945843, -16.864357143916607],
              [-66.65880561805159, -16.85777634408936],
              [-66.65559350511371, -16.85292255159402],
              [-66.64986464192603, -16.84909084837281],
              [-66.64737655778511, -16.842513233668797],
              [-66.65063460355243, -16.838044673443733],
              [-66.65139752438017, -16.82906514056353],
              [-66.64277656931814, -16.827635355518538],
              [-66.63049356075534, -16.842423379666684],
              [-66.61965961561486, -16.853765771054242],
              [-66.61566949442351, -16.86065318089993],
              [-66.61060363977762, -16.868226900978243],
              [-66.60336251361761, -16.87958588853411],
              [-66.5986704918537, -16.88405277237854],
              [-66.58641849633268, -16.891931426094658],
              [-66.57702657381584, -16.902243681581012],
              [-66.5683895254997, -16.905306429004185],
              [-66.56047851763729, -16.90630035508363],
              [-66.55406150019826, -16.89520992370575],
              [-66.5426636206116, -16.87303844868154],
              [-66.53192154113002, -16.865726076344487],
              [-66.51826457099743, -16.865651812682273],
              [-66.50746951788773, -16.868703831269357],
              [-66.49416358186043, -16.87139241053444],
              [-66.47470063503087, -16.883724872326013],
              [-66.43975061332767, -16.903229728671477],
              [-66.4224626027361, -16.91142505068987],
              [-66.40444151089211, -16.922382377445217],
              [-66.39002949892078, -16.93059479854611],
              [-66.37416054453813, -16.94329103493584],
              [-66.36260961139935, -16.953937393083066],
              [-66.34530651338204, -16.96628075134879],
              [-66.3319625235143, -16.9769178894025],
              [-66.31895448032617, -16.99101205960514],
              [-66.30129951001322, -17.00197156565531],
              [-66.29372361064007, -17.007111181049197],
              [-66.28723953797558, -17.011220828179944],
              [-66.2717365374859, -17.022193242361027],
              [-66.25371561327998, -17.033150569116344],
              [-66.23064459240616, -17.049260587034553],
              [-66.21587349970244, -17.05747116411675],
              [-66.20037049921277, -17.067406401597907],
              [-66.18169360726372, -17.065924648753168],
              [-66.16371157508858, -17.06928126570193],
              [-66.15509749318716, -17.067507487350298],
              [-66.13029460347761, -17.068062201702958],
              [-66.10983253378859, -17.06449553426043],
              [-66.10264555472315, -17.063076813327626],
              [-66.09726755800271, -17.060629800512316],
              [-66.09083562077602, -17.052648384663172],
              [-66.08585358516196, -17.04225784220108],
              [-66.07044949112985, -17.033194657927083],
              [-66.06467452747465, -17.037651315849644],
              [-66.0596085051907, -17.04522470065183],
              [-66.05450459670435, -17.060396783603835],
              [-66.0537495548656, -17.068339307422207],
              [-66.04827851901983, -17.08627222164469],
              [-66.04498258705011, -17.10042037130384],
              [-66.04096262628343, -17.11490731748964],
              [-66.03589660399948, -17.121789027641142],
              [-66.02901455857183, -17.13349737292171],
              [-66.01817357263269, -17.145875432266592],
              [-66.00302160624832, -17.15926569023989],
              [-65.99580361414111, -17.165100333045757],
              [-65.98643449040094, -17.170576062757306],
              [-65.97598259240755, -17.178466451137894],
              [-65.96903248592606, -17.20468286500025],
              [-65.97579148501497, -17.218194157655546],
              [-65.97686755374563, -17.219234854754774],
              [-65.96786455153651, -17.22298692989571],
              [-65.95958758978112, -17.22605152133758],
              [-65.9477005423247, -17.231514175280154],
              [-65.93688956359894, -17.238362860733133],
              [-65.92323259346635, -17.239327785427577],
              [-65.9134975158332, -17.245837339077752],
              [-65.90088660721787, -17.252677977903687],
              [-65.88105049805858, -17.268808112389507],
              [-65.87166561634042, -17.27705037306572],
              [-65.8601145155636, -17.288387735311403],
              [-65.84605454352595, -17.29867300106949],
              [-65.8327175944569, -17.30620263233706],
              [-65.82625548237873, -17.305476591883348],
              [-65.81764961474242, -17.301628963046113],
              [-65.81083663619702, -17.297794242339762],
              [-65.80474047801184, -17.296031192824188],
              [-65.7950436218572, -17.29425422934935],
              [-65.78605654526413, -17.294548434151096],
              [-65.76877557547122, -17.302399930500883],
              [-65.750045542256, -17.311627232439207],
              [-65.73598456438997, -17.3212228352034],
              [-65.73310051914268, -17.323970423066868],
              [-65.71939862200902, -17.332876865751302],
              [-65.71181450837075, -17.34147552495088],
              [-65.70710756681918, -17.349047065734368],
              [-65.70421564258288, -17.352487836991088],
              [-65.695526626467, -17.366602961951685],
              [-65.68286861155579, -17.38415483485548],
              [-65.66950249346365, -17.399629001598186],
              [-65.6594006237472, -17.40786640077056],
              [-65.64421848251138, -17.42712935539018],
              [-65.60375249796135, -17.47354732939064],
              [-65.59290363303322, -17.487651390239023],
              [-65.58496848528964, -17.495210022891598],
              [-65.57773557339475, -17.504497339256716],
              [-65.56365162911399, -17.5199674826859],
              [-65.55030059844759, -17.53129478664775],
              [-65.53443164406494, -17.542610020669002],
              [-65.52146148707918, -17.5504846510716],
              [-65.50704159611884, -17.560425085332753],
              [-65.4825436400471, -17.573074383064665],
              [-65.4713665397903, -17.582341582862114],
              [-65.46054064127688, -17.59092012549405],
              [-65.44214655474124, -17.606367134870453],
              [-65.40936258445866, -17.62276934593362],
              [-65.39027363814914, -17.633375470945595],
              [-65.37588459259251, -17.63640737296504],
              [-65.37048362945735, -17.638105378911888],
              [-65.35791748020503, -17.63596346737245],
              [-65.34098855034583, -17.6451979777475],
              [-65.31439260390731, -17.644709815706108],
              [-65.29602047795801, -17.65532013166964],
              [-65.2830196432066, -17.668722459583535],
              [-65.27326947814772, -17.679032703413128],
              [-65.26352652152558, -17.687617448653413],
              [-65.2505494913792, -17.696876601823817],
              [-65.2364735937255, -17.709926051613678],
              [-65.22563948094697, -17.721268107725137],
              [-65.2137296347139, -17.73225762098869],
              [-65.20111855846051, -17.73875309304151],
              [-65.17733759038705, -17.752443255510713],
              [-65.16834263480499, -17.75584798458374],
              [-65.15141253147934, -17.764394675983567],
              [-65.12836447702023, -17.774977834580824],
              [-65.11433451219598, -17.77800990423833],
              [-65.10858150912712, -17.778324896159944],
              [-65.09599256109814, -17.779984680628303],
              [-65.09288756888293, -17.781952416219582],
              [-65.09812156450668, -17.786213440521834],
              [-65.10349252042845, -17.791081985166784],
              [-65.11104562102493, -17.795136814650704],
              [-65.09701548856262, -17.792426945351565],
              [-65.08588448877333, -17.790983078709218],
              [-65.06756550409021, -17.789503169883204],
              [-65.05463457441135, -17.7880520948041],
              [-65.04602853913698, -17.78455047093027],
              [-65.04029062349386, -17.780375780230827],
              [-65.02955659063933, -17.770642378978323],
              [-65.02420055450523, -17.764394675983567],
              [-65.01955463320881, -17.75849683362773],
              [-65.01132963925316, -17.749815361224705],
              [-65.007034584424, -17.746336703765593],
              [-65.00451649306969, -17.746323627996617],
              [-64.9976956355353, -17.745249906198836],
              [-64.9930265801861, -17.744190768912603],
              [-64.98801453735864, -17.741051913808548],
              [-64.98336056943516, -17.735845243189175],
              [-64.9781875940666, -17.724301015572962],
              [-64.97550957599955, -17.72543693109236],
              [-64.96797960945584, -17.7209051713173],
              [-64.96153258480342, -17.717417629040796],
              [-64.95350657722933, -17.714798787210242],
              [-64.94248957132334, -17.71593168524447],
              [-64.93782051597414, -17.716252712136395],
              [-64.9324185470106, -17.71794954461683],
              [-64.92414057942682, -17.72101413605867],
              [-64.91981551738427, -17.7237550183996],
              [-64.90937049255149, -17.728189715735795],
              [-64.90504459231862, -17.73127559721175],
              [-64.89952058284473, -17.73817725629283],
              [-64.89131151450512, -17.747782749702765],
              [-64.88138549711745, -17.760411763228973],
              [-64.8736194961801, -17.76565145854687],
              [-64.86316659235834, -17.77354000290876],
              [-64.85991659321806, -17.77628574675353],
              [-64.85197457231388, -17.78349736861432],
              [-64.83469360252097, -17.79031420283519],
              [-64.82247161421334, -17.79128298320512],
              [-64.81168360190232, -17.79329681926393],
              [-64.80162062421664, -17.79324283980742],
              [-64.79515851213847, -17.7921707943903],
              [-64.78005951938223, -17.7927779794569],
              [-64.75563063019273, -17.792648059957543],
              [-64.744132503044, -17.79223986127252],
              [-64.73046161895212, -17.795274613139043],
              [-64.72109953601063, -17.798678671659843],
              [-64.71066255780491, -17.80173236662756],
              [-64.69987454549388, -17.803746370324433],
              [-64.68981156780819, -17.804383227328287],
              [-64.67180656921832, -17.81257452603316],
              [-64.66510758455621, -17.817991247146296],
              [-64.65196258107005, -17.82974150194275],
              [-64.63899963252102, -17.834851277761345],
              [-64.63108862465862, -17.836882212902623],
              [-64.61921649698986, -17.83923601895455],
              [-64.60878756541119, -17.839870864301645],
              [-64.59890748085297, -17.8406798855969],
              [-64.58757749468211, -17.841828038694928],
              [-64.57498150585445, -17.845558656163746],
              [-64.56348455217218, -17.845844646700357],
              [-64.54660054931404, -17.844024767881194],
              [-64.52791561073794, -17.84461468622723],
              [-64.50524155679886, -17.851402351425037],
              [-64.49555257963323, -17.85404918881224],
              [-64.48257454365847, -17.85715099590425],
              [-64.47358662887508, -17.85813888701341],
              [-64.4588545958402, -17.858403922792064],
              [-64.4502255941511, -17.85973698067434],
              [-64.44213051969479, -17.859532965150862],
              [-64.42616249121652, -17.855463048241205],
              [-64.41072051098202, -17.853998059202837],
              [-64.38591762127245, -17.855589112065076],
              [-64.36687460779237, -17.854795010557496],
              [-64.3474656404193, -17.855381073228074],
              [-64.33274048054504, -17.8532291034048],
              [-64.31586452431395, -17.851064393088677],
              [-64.31227857849409, -17.850008273287614],
              [-64.2871095672535, -17.85263616757362],
              [-64.27345259712091, -17.85325206981952],
              [-64.25473748369335, -17.85867884921649],
              [-64.23783152024886, -17.86169583144826],
              [-64.23548861067108, -17.861638834506607],
              [-64.22562461936697, -17.859212944087318],
              [-64.21379054553867, -17.854656038602712],
              [-64.19836449092021, -17.849737537814775],
              [-64.18218255627269, -17.85068536342675],
              [-64.1707005223781, -17.848203649532252],
              [-64.16133860707465, -17.851953210102238],
              [-64.15303750543809, -17.85847097801752],
              [-64.145477531681, -17.862231770337786],
              [-64.13862649929514, -17.8670304099102],
              [-64.12924161757698, -17.87630465050634],
              [-64.1241605078673, -17.887680234230515],
              [-64.11980460042102, -17.896294651408084],
              [-64.11328850888637, -17.9059319960501],
              [-64.10787162013516, -17.912466527771755],
              [-64.10135653442889, -17.92175803508846],
              [-64.09557352414663, -17.92967155752183],
              [-64.08979051386439, -17.93724125428662],
              [-64.08542655979106, -17.94758234351991],
              [-64.08109261293113, -17.952737046339564],
              [-64.07745352584509, -17.962048502585844],
              [-64.0727465842935, -17.969967054161117],
              [-64.06697061480993, -17.97580991123209],
              [-64.0572506246025, -17.979556454316935],
              [-64.04718747927876, -17.97881163840006],
              [-64.04182456998406, -17.97429278675591],
              [-64.03180651929944, -17.96352656739316],
              [-64.01892856324869, -17.951709089733157],
              [-64.01141351649265, -17.944070661362275],
              [-63.9931676220053, -17.92739084166567],
              [-63.98852153307082, -17.921835986788068],
              [-63.97991952110998, -17.916954366374142],
              [-63.976684609395434, -17.916936429101327],
              [-63.962299587152344, -17.91996732529239],
              [-63.95437952683449, -17.922686917599236],
              [-63.94213457211215, -17.928494570676833],
              [-63.93349048299733, -17.932250501493257],
              [-63.91796853940646, -17.947710419000572],
              [-63.90930550719045, -17.955608015817887],
              [-63.90029563182071, -17.961087768842958],
              [-63.890201641093256, -17.967942153990094],
              [-63.87940558215516, -17.971339171712202],
              [-63.871135493560374, -17.972329074478154],
              [-63.865379640644434, -17.974025739320496],
              [-63.85961557346339, -17.97675656337759],
              [-63.82505061639243, -17.9910807330036],
              [-63.81499048855383, -17.99102490952839],
              [-63.807090544803614, -17.989943979293884],
              [-63.790943478873345, -17.985021119916297],
              [-63.778003496739046, -17.984954232328874],
              [-63.75926960784835, -17.99521368182519],
              [-63.74881754221691, -18.0020662229536],
              [-63.743411549939836, -18.0058370735577],
              [-63.73514162898313, -18.00613781624392],
              [-63.72723062112071, -18.00816774555682],
              [-63.72112658394654, -18.006406875336097],
              [-63.716457528597346, -18.005692066632633],
              [-63.707492580228674, -18.001843264328954],
              [-63.69601456964759, -17.997633369636134],
              [-63.68452851243946, -17.99446165747159],
              [-63.67090657866217, -17.987480202672202],
              [-63.66104862232835, -17.981294358122966],
              [-63.662258633871886, -17.965189536984724],
              [-63.66233457391472, -17.947919631304018],
              [-63.65917157892949, -17.933047285209852],
              [-63.64483651282937, -17.9239918122868],
              [-63.62073150037887, -17.929045596992097],
              [-63.60669348892756, -17.93346118358903],
              [-63.593006511581564, -17.93995078830963],
              [-63.58147854485752, -17.94645363643727],
              [-63.56815349809098, -17.952253075249757],
              [-63.55698058878578, -17.95910779567302],
              [-63.53253560634215, -17.960358878542138],
              [-63.50918949140585, -17.956780811711297],
              [-63.47616948672963, -17.946241574286745],
              [-63.462585606792786, -17.930279580778745],
              [-63.44826562811839, -17.918113415946323],
              [-63.435745579333584, -17.9052649641948],
              [-63.4311405617247, -17.89038591257807],
              [-63.4236645746375, -17.87480177526558],
              [-63.41977654502692, -17.86096358838617],
              [-63.414459568561654, -17.84435367376213],
              [-63.41236861899357, -17.830525712804587],
              [-63.410991639938615, -17.81670043405606],
              [-63.40891259267305, -17.799762619379493],
              [-63.40395754678724, -17.78315454877415],
              [-63.39682052386476, -17.7720610999111],
              [-63.39116257157801, -17.751652339126338],
              [-63.38222461293758, -17.741930840176337],
              [-63.37832652504318, -17.72947264983702],
              [-63.38344552095525, -17.710501888362387],
              [-63.38637952234552, -17.697391585955387],
              [-63.389358618374914, -17.674953231133827],
              [-63.39625156027668, -17.660826036232663],
              [-63.40678057177935, -17.63773523406732],
              [-63.41294462338031, -17.62636803224632],
              [-63.42163850100005, -17.61087006089855],
              [-63.42996256905133, -17.598478758146626],
              [-63.43831262100248, -17.580559757883435],
              [-63.443069518697044, -17.56158497309525],
              [-63.44306549538351, -17.541761269152687],
              [-63.44224960092765, -17.540598196266956],
              [-63.4317324917275, -17.52559928343473],
              [-63.413074542879656, -17.518935670404062],
              [-63.403465529070374, -17.498503943204554],
              [-63.41034656866964, -17.486449425322547],
              [-63.42402650521696, -17.482377832032256],
              [-63.442401481013334, -17.47211150937534],
              [-63.45217561831532, -17.456964236856777],
              [-63.45978152490187, -17.44387623031224],
              [-63.47276660167529, -17.433928922890487],
              [-63.49176049720272, -17.445772049174252],
              [-63.50825155643963, -17.455188614486417],
              [-63.51656757786387, -17.444523313237994],
              [-63.51880252852865, -17.427262124736615],
              [-63.51742152616018, -17.414473016859603],
              [-63.51784162714772, -17.4013489681314],
              [-63.52441756547111, -17.37789422372984],
              [-63.531669588105245, -17.36411403962046],
              [-63.55401959766725, -17.349378989100444],
              [-63.55754049991842, -17.365287170789998],
              [-63.57151061795393, -17.37572532246216],
              [-63.584785540939436, -17.380630915119184],
              [-63.598785498550285, -17.38484986226743],
              [-63.604957596778306, -17.37072082334757],
              [-63.60646063965713, -17.35691063202478],
              [-63.605812550902996, -17.341708374221326],
              [-63.610938587613745, -17.320661918242365],
              [-63.61928159876622, -17.303778753574534],
              [-63.632263490416435, -17.294177451116184],
              [-63.64951361480563, -17.29461230425335],
              [-63.661712636698525, -17.29916703044313],
              [-63.67427861831278, -17.302342765921196],
              [-63.695075628853004, -17.31281729505318],
              [-63.717346513248955, -17.315698993367562],
              [-63.72314863427047, -17.303982936736077],
              [-63.71893656028283, -17.28219820272477],
              [-63.71504249570195, -17.2693968572691],
              [-63.71078851219839, -17.256246657002976],
              [-63.71158261370597, -17.240014766212482],
              [-63.70984253060513, -17.22722465250709],
              [-63.712066584795764, -17.212727480399423],
              [-63.717185580707834, -17.193754707268],
              [-63.73947959915658, -17.19145488067258],
              [-63.758541555737864, -17.188791782393196],
              [-63.776168530494175, -17.184741311498925],
              [-63.78664758585387, -17.172357049545695],
              [-63.797126641213566, -17.160669491385022],
              [-63.808669527725286, -17.150714304974287],
              [-63.82556157721048, -17.15149432488451],
              [-63.839580477922524, -17.150531244208764],
              [-63.85039849744696, -17.142642699846874],
              [-63.86374651062822, -17.13166039502005],
              [-63.879604568536735, -17.122415993999255],
              [-63.89221162147659, -17.115920521946435],
              [-63.909896599002934, -17.09977731169161],
              [-63.925411501795125, -17.087424230418208],
              [-63.93973952709656, -17.097172551458385],
              [-63.95271655724294, -17.089294903570647],
              [-63.96850554826922, -17.09490876704666],
              [-63.983577551297216, -17.09982341215911],
              [-64.00874354505267, -17.098228503621414],
              [-64.0418705028135, -17.08389628736839],
              [-64.05020161166345, -17.07046512570753],
              [-64.05531356677686, -17.052877210620068],
              [-64.06761954175437, -17.034285478807362],
              [-64.07775862712096, -17.018108405663696],
              [-64.08607448090713, -17.006060090390037],
              [-64.09405556148015, -16.991247255808474],
              [-64.10379751227391, -16.983007509703214],
              [-64.11571456694372, -16.969252471303378],
              [-64.13051549922277, -16.955167353556163],
              [-64.14712558148489, -16.939021461092352],
              [-64.15798953383876, -16.921459529904723],
              [-64.16705355630306, -16.903544552955054],
              [-64.16671790438481, -16.902229944146715],
              [-64.16352058411132, -16.889707371904024],
              [-64.14624749330741, -16.895836051873545],
              [-64.13217964228078, -16.90612131763166],
              [-64.12386362085654, -16.916441619745058],
              [-64.11193064057068, -16.93227168209694],
              [-64.09786262190597, -16.943595968573646],
              [-64.08348061714804, -16.94455603176425],
              [-64.06546757193111, -16.954479366942877],
              [-64.05322261720876, -16.959594171903376],
              [-64.03919248474645, -16.962630767788596],
              [-64.02303351651365, -16.959780920706294],
              [-64.00760662370489, -16.95348141754988],
              [-63.99036052262923, -16.953044552755983],
              [-63.97448352161952, -16.966431625606077],
              [-63.964382490093385, -16.974324025643426],
              [-63.94314157396059, -16.982847750628537],
              [-63.926593517782024, -16.98552543341944],
              [-63.912582496058974, -16.984758656916256],
              [-63.894275581316435, -16.979480907757875],
              [-63.88533359936248, -16.969757564789177],
              [-63.88145462220734, -16.954195388063056],
              [-63.87829263305049, -16.937943548342986],
              [-63.87444248964229, -16.915121972907883],
              [-63.86371952089996, -16.902629752041634],
              [-63.8475645759807, -16.898743734087816],
              [-63.82924257381244, -16.896922849440273],
              [-63.810546571124135, -16.89854776519138],
              [-63.79254961916132, -16.904324405227214],
              [-63.76952352528855, -16.909730229866227],
              [-63.754768525838955, -16.913450956689275],
              [-63.7335435353222, -16.91782983055026],
              [-63.711952590912475, -16.923935376467],
              [-63.69609855631748, -16.932831593229565],
              [-63.680614498929, -16.939312313132803],
              [-63.666919642594024, -16.947185770068955],
              [-63.643142530196045, -16.959152110329484],
              [-63.632320487358086, -16.967730485323358],
              [-63.622573507422416, -16.977696065294055],
              [-63.59952159728785, -16.987939421536225],
              [-63.585876529457764, -16.98579231321682],
              [-63.58558651560763, -16.970244553364125],
              [-63.590675504306304, -16.957836319167768],
              [-63.59902153294392, -16.940260138744776],
              [-63.60770451408952, -16.92787302694444],
              [-63.61638263373126, -16.91686457057969],
              [-63.622901575113005, -16.90549938041545],
              [-63.62548856571148, -16.889969390197535],
              [-63.611125504054726, -16.887127589742278],
              [-63.597133593070936, -16.881527975501683],
              [-63.592552547705154, -16.862159241263896],
              [-63.587222495470925, -16.84831300775741],
              [-63.58155062922489, -16.831355914703522],
              [-63.57405452557005, -16.820261795288218],
              [-63.5658535038575, -16.80605446939262],
              [-63.56198156750102, -16.788416598162172],
              [-63.564926633003495, -16.773232612907663],
              [-63.57221553601164, -16.75081722450082],
              [-63.579463535332266, -16.737729720870504],
              [-63.59427251423837, -16.721571758466098],
              [-63.60115858297952, -16.709514558375048],
              [-63.6102375252315, -16.68883807960262],
              [-63.617145554559, -16.671950053430947],
              [-63.63098156214359, -16.63229459891872],
              [-63.6314165829188, -16.615716367888723],
              [-63.63112254575513, -16.601895447729845],
              [-63.63190457732213, -16.58808307711223],
              [-63.63700848580848, -16.572566330301356],
              [-63.632045560933676, -16.557338088598016],
              [-63.62455750390589, -16.544517800041177],
              [-63.61237356943872, -16.536162551310042],
              [-63.59655758868418, -16.53642339613711],
              [-63.58212663361165, -16.548441704197387],
              [-63.57524861149753, -16.559110190569015],
              [-63.56943861148703, -16.572552081065936],
              [-63.560028584059324, -16.587011199333176],
              [-63.55135750521626, -16.596638820967513],
              [-63.53945151465864, -16.606247164224527],
              [-63.526424528369304, -16.6258688643004],
              [-63.51308456181509, -16.634779162660323],
              [-63.47562063970919, -16.6542711108749],
              [-63.45969351491844, -16.678022742287197],
              [-63.45496763026566, -16.69008983302399],
              [-63.447723486620504, -16.70214116578282],
              [-63.4304125096142, -16.71517737216564],
              [-63.41488654270981, -16.7309863121215],
              [-63.39867057753537, -16.74057185660186],
              [-63.38853450965394, -16.75640778628599],
              [-63.39099158075307, -16.768858097636326],
              [-63.39524858174178, -16.780971121202555],
              [-63.404190563695735, -16.790691614324174],
              [-63.40772253005909, -16.80452661608038],
              [-63.40477763219468, -16.81936375818117],
              [-63.40040563149431, -16.832472048931407],
              [-63.40070352433344, -16.84559710348799],
              [-63.39953257245874, -16.866318006347285],
              [-63.39587051895798, -16.881151963324868],
              [-63.38151952724181, -16.875205170654425],
              [-63.375827544428134, -16.862392258172378],
              [-63.371215486020574, -16.849242057906253],
              [-63.366970554972454, -16.835055686768612],
              [-63.364162617406066, -16.81983968264393],
              [-63.364231516650236, -16.80498661492709],
              [-63.35640751294285, -16.786636952478318],
              [-63.34818654230072, -16.776576321725514],
              [-63.33309157285801, -16.776496358369144],
              [-63.3136445516445, -16.78537497313505],
              [-63.28450352412342, -16.791782602842517],
              [-63.26614363575277, -16.798250079338686],
              [-63.25031256757251, -16.802310943792918],
              [-63.25455062546001, -16.81822499281469],
              [-63.26785253817377, -16.81760305559851],
              [-63.280448527001425, -16.813870091196804],
              [-63.29520453227941, -16.809804365238733],
              [-63.306671478748285, -16.8167730795453],
              [-63.302993499631484, -16.835061721738896],
              [-63.29074452159561, -16.841906383878325],
              [-63.27635162036353, -16.844594292591125],
              [-63.261611540701594, -16.84624418641374],
              [-63.227451597192456, -16.849516816692585],
              [-63.223442532899895, -16.861931253497318],
              [-63.222656478019374, -16.876780130262546],
              [-63.206825577477176, -16.88015200227511],
              [-63.19029260872435, -16.88040798559834],
              [-63.176258620586566, -16.88378991589471],
              [-63.15502960675629, -16.889204290074957],
              [-63.13954957268133, -16.89499417351783],
              [-63.12512264092233, -16.905972455031105],
              [-63.1160735382457, -16.919397581721654],
              [-63.10990160765574, -16.933527794107988],
              [-63.10444264175055, -16.94835286626818],
              [-63.096828520898896, -16.96247603785585],
              [-63.0881344756411, -16.977286022590306],
              [-63.080181558262765, -16.987605486513417],
              [-63.07007549759473, -16.996533722146154],
              [-63.057811599771185, -17.006487232176255],
              [-63.04411657579814, -17.01470568824743],
              [-63.03150549954475, -17.021892332036742],
              [-63.01925652150888, -17.028046995906124],
              [-63.00483663054855, -17.037297264259166],
              [-62.989707630578906, -17.045160830549577],
              [-62.97025658605186, -17.054731119966135],
              [-62.95441462139746, -17.06051865647612],
              [-62.94180656262921, -17.067017313652144],
              [-62.92456062919162, -17.065949626824647],
              [-62.92568263075172, -17.05587508211258],
              [-62.935462635385946, -17.039002143366645],
              [-62.94739159235829, -17.024902105831785],
              [-62.954624504253175, -17.01457560111001],
              [-62.96474850219403, -17.001503352543494],
              [-62.977046598182554, -16.984641477909747],
              [-62.99831752152875, -16.96990156588589],
              [-63.013507541753555, -16.949596573062507],
              [-63.017875519140404, -16.936841495712457],
              [-63.022972554466136, -16.922702230870698],
              [-63.029155549168294, -16.90684819627569],
              [-63.033920493489916, -16.88718274266523],
              [-63.03902456961431, -16.870972644823013],
              [-63.04847751238633, -16.84753315548525],
              [-63.049976531951614, -16.8337227965244],
              [-63.0539964927183, -16.818889845375196],
              [-63.06236263792357, -16.797171328399003],
              [-63.066440601460286, -16.770247984822163],
              [-63.073333543362054, -16.75612179574938],
              [-63.08311857713818, -16.738555673610165],
              [-63.09614154011399, -16.72031480826473],
              [-63.11853463265837, -16.69625338171076],
              [-63.129737549176966, -16.682494319997403],
              [-63.13771862974998, -16.66698930785097],
              [-63.154437509115425, -16.62597179407149],
              [-63.16422656620507, -16.607369165784633],
              [-63.184047587938636, -16.595381703128083],
              [-63.19700651317413, -16.590963099046007],
              [-63.209609542800465, -16.586193293220532],
              [-63.23012559194596, -16.58008372399027],
              [-63.23952455526147, -16.568041611324958],
              [-63.253166605606395, -16.571223214135273],
              [-63.2672346242711, -16.55955292269516],
              [-63.280219533406466, -16.54994960858005],
              [-63.28927953255723, -16.53376147132417],
              [-63.29258351115401, -16.518579665364484],
              [-63.276485563176394, -16.502255741277054],
              [-63.28409163740099, -16.489519439390108],
              [-63.30249360292562, -16.473033409295084],
              [-63.31230160311648, -16.450630929019155],
              [-63.31598259971844, -16.431305110125663],
              [-63.33152348641049, -16.41238732227916],
              [-63.33985560108883, -16.398960016293785],
              [-63.35863860793218, -16.377641483737506],
              [-63.36839262866653, -16.366986073134825],
              [-63.38285057346731, -16.34944308504842],
              [-63.3900945494744, -16.33704591496425],
              [-63.397346572108546, -16.32292089935791],
              [-63.404956502008616, -16.309143732733673],
              [-63.42194762558944, -16.28816198504731],
              [-63.43349855872822, -16.276823952249373],
              [-63.445777543977485, -16.264105755273306],
              [-63.45771052426335, -16.248279716234947],
              [-63.46998548619909, -16.235907524222284],
              [-63.48441660890968, -16.223894077665875],
              [-63.50569155556941, -16.208810004697284],
              [-63.51902749881009, -16.200934703742433],
              [-63.533077580201976, -16.19375191562858],
              [-63.565101479503895, -16.185631527824654],
              [-63.57452055938704, -16.169444564035217],
              [-63.57998254277737, -16.153929493604977],
              [-63.589733546026565, -16.143271065517155],
              [-63.59447854141861, -16.127752139411427],
              [-63.58986648301105, -16.114254928353517],
              [-63.577362527480346, -16.097611150840606],
              [-63.56842456883993, -16.087887640233845],
              [-63.556949575744, -16.081954090970413],
              [-63.5386424933634, -16.077022514413528],
              [-63.51422148316287, -16.073091569458626],
              [-63.5002175022385, -16.0702547981453],
              [-63.48657964284516, -16.065691019500093],
              [-63.460376640027874, -16.057953517033553],
              [-63.42454148934853, -16.035311146688542],
              [-63.41235755488137, -16.027991565914732],
              [-63.40160357309725, -16.02102788075007],
              [-63.38905351709904, -16.015086284859592],
              [-63.37472951511113, -16.003611124125598],
              [-63.36112954192018, -15.99110079834847],
              [-63.353271507685875, -15.98000651129513],
              [-63.34471157287902, -15.965798514847279],
              [-63.3379475446482, -15.951944402351813],
              [-63.33517062012359, -15.92982171000412],
              [-63.33596757147825, -15.91289797692491],
              [-63.34070954938514, -15.898414886414571],
              [-63.35589554629642, -15.878114084542801],
              [-63.36888162889824, -15.867818760500882],
              [-63.382221595452435, -15.858561283711111],
              [-63.399185561666954, -15.843453406137485],
              [-63.415389624538875, -15.835937688829205],
              [-63.43194958301996, -15.83015417563277],
              [-63.47119549774263, -15.814816969188058],
              [-63.48847160603168, -15.809381304973726],
              [-63.50179262948468, -15.8046158577379],
              [-63.518341523853564, -15.80090485392256],
              [-63.52272458866615, -15.785726903638334],
              [-63.50802256284467, -15.778050421426997],
              [-63.4936715711285, -15.772445778044471],
              [-63.49445762600902, -15.75794055930973],
              [-63.5102845032377, -15.754916871555423],
              [-63.52572262779674, -15.758797692729246],
              [-63.54223263013485, -15.763722228487467],
              [-63.55764359732757, -15.773130747172615],
              [-63.564899475637176, -15.758314559829785],
              [-63.572162562383525, -15.7424627045296],
              [-63.577983626506224, -15.7266028026024],
              [-63.588096560334876, -15.714807285528735],
              [-63.589343619890485, -15.713711435506553],
              [-63.57954450451702, -15.700358393183393],
              [-63.58643761405685, -15.68623019245382],
              [-63.597282623309525, -15.672470292550145],
              [-63.612770536373475, -15.665298568548508],
              [-63.64480248230245, -15.654758157657511],
              [-63.657424622668046, -15.645843835984039],
              [-63.665740476454204, -15.635178534735616],
              [-63.669410576582024, -15.627988035270846],
              [-63.6715436033041, -15.623811332914613],
              [-63.65719948474853, -15.616134683065212],
              [-63.64357352765772, -15.60949923322923],
              [-63.63211462781591, -15.60011183694013],
              [-63.619934549024194, -15.591756252932868],
              [-63.60052859913628, -15.59096131323497],
              [-63.580394597137854, -15.592584217329318],
              [-63.56636463231361, -15.5952731318705],
              [-63.52426551774737, -15.606104394801946],
              [-63.50591652585085, -15.610497182622225],
              [-63.48435257116937, -15.610729193702326],
              [-63.460250576204004, -15.614745969345819],
              [-63.42715463148494, -15.621826330602772],
              [-63.41740463406413, -15.631790737107025],
              [-63.40586862071302, -15.638985259885317],
              [-63.392528486520746, -15.648588574000428],
              [-63.382423599319154, -15.65751513325253],
              [-63.37231049785244, -15.668862386143957],
              [-63.35213056302457, -15.679809822253532],
              [-63.34020261188061, -15.69460086388682],
              [-63.3347396226619, -15.710114760850615],
              [-63.3271335484373, -15.723548939996618],
              [-63.31877159418363, -15.743883437119166],
              [-63.31113450691724, -15.763880143543446],
              [-63.304954529700225, -15.778702198218525],
              [-63.29805756448492, -15.79386439052476],
              [-63.29367047635881, -15.810421499158764],
              [-63.284233626840916, -15.830062142335805],
              [-63.26976361209955, -15.851058642171779],
              [-63.26215351456142, -15.864145978164032],
              [-63.256336641390305, -15.879313031974135],
              [-63.250141576747566, -15.897587927846502],
              [-63.2363395996899, -15.929297841055188],
              [-63.22613563839268, -15.959986670818068],
              [-63.217426505709156, -15.977905671081288],
              [-63.20045449286759, -15.99508656095017],
              [-63.185321637222486, -16.003642975357707],
              [-63.16947548161649, -16.010469532586256],
              [-63.13818751341407, -16.01479342116238],
              [-63.118068598841376, -16.012960634212334],
              [-63.104473487154266, -16.000105141662118],
              [-63.10561359362525, -15.985950286480431],
              [-63.10786061423062, -15.966269577806173],
              [-63.116249558212544, -15.939716043797773],
              [-63.12713949446628, -15.915592758798311],
              [-63.13441850682868, -15.895941051509055],
              [-63.13915662906011, -15.881456787532272],
              [-63.14352460644696, -15.86939070262386],
              [-63.149719503451635, -15.851459800058137],
              [-63.156299632726615, -15.826623215097783],
              [-63.16031657600816, -15.812825261353652],
              [-63.167594582542165, -15.7935195590278],
              [-63.173770536445645, -15.779042335849681],
              [-63.17926453870615, -15.756619906644175],
              [-63.18365062100388, -15.740751790451839],
              [-63.188381534798566, -15.72799570727338],
              [-63.19276459961115, -15.712819433369788],
              [-63.19643050878737, -15.697291454808635],
              [-63.19904750659924, -15.674507933213988],
              [-63.200675607473556, -15.633407773869124],
              [-63.20436448306451, -15.612355953472132],
              [-63.2090985819824, -15.599251853673508],
              [-63.21993252712288, -15.587911473942711],
              [-63.22718857307056, -15.573096460066324],
              [-63.23622158249309, -15.562089344806083],
              [-63.24417852318494, -15.551421864262807],
              [-63.255382613169985, -15.537663808377829],
              [-63.262309585598686, -15.516283249737995],
              [-63.26706648329325, -15.497999469048267],
              [-63.268573549485595, -15.482808275357002],
              [-63.26475559258563, -15.453078167680218],
              [-63.26482750931494, -15.43753426350301],
              [-63.263481543301765, -15.417837293936543],
              [-63.276184485214046, -15.390270387833283],
              [-63.28416858327222, -15.373384373318373],
              [-63.28890955535073, -15.358554104378214],
              [-63.28753257629579, -15.34507717752598],
              [-63.282924541201766, -15.331232955676285],
              [-63.27508964102026, -15.315993985136885],
              [-63.26688761347931, -15.301440989553981],
              [-63.258361541561314, -15.279977282434572],
              [-63.242248506157964, -15.266419218759552],
              [-63.22755855027707, -15.257016902682778],
              [-63.2114336125712, -15.246222520125315],
              [-63.1938245750877, -15.245783643674656],
              [-63.17587657343948, -15.240505894516275],
              [-63.138824538055985, -15.248255466923382],
              [-63.12261159036669, -15.256805678722543],
              [-63.10854759501552, -15.267094297241925],
              [-63.092632540165354, -15.288772748720902],
              [-63.080726549607746, -15.299073101904725],
              [-63.07599664164144, -15.31148519177654],
              [-63.0701826183174, -15.325272249046549],
              [-63.06651654150312, -15.340795366103862],
              [-63.06608554404144, -15.356338264452688],
              [-63.06669960226864, -15.37914207025301],
              [-63.06908760648555, -15.407135279952172],
              [-63.06687159892196, -15.420251282053329],
              [-63.06499857883664, -15.437166130315177],
              [-63.0642125239561, -15.45132618227683],
              [-63.06160759608481, -15.471693871736022],
              [-63.05289460772583, -15.489956865305857],
              [-63.04347653367107, -15.506143829095294],
              [-63.036571521828705, -15.522687694322258],
              [-63.030406632037426, -15.534746067879752],
              [-63.02208759312805, -15.546793209686967],
              [-63.00763652148788, -15.562606005318287],
              [-62.99643360496928, -15.576364396479391],
              [-62.986297537087836, -15.591855327028497],
              [-62.961006485336895, -15.621431375324761],
              [-62.95018360430862, -15.630007738661874],
              [-62.93143848366766, -15.642002074479052],
              [-62.91268162836225, -15.656754224081567],
              [-62.89468350293299, -15.662531702307717],
              [-62.874511614732185, -15.672788972509181],
              [-62.85072762917359, -15.686825139941789],
              [-62.8114735001858, -15.703890191908698],
              [-62.7974165456333, -15.712451467820046],
              [-62.783367637707855, -15.719631070810692],
              [-62.761718522890035, -15.737825835688653],
              [-62.75626358029838, -15.752306076351886],
              [-62.74863453965905, -15.76988393315554],
              [-62.738815475356006, -15.794703251395333],
              [-62.728317476895114, -15.811228173521101],
              [-62.71566750861095, -15.826361196804271],
              [-62.70270154257679, -15.832511837360158],
              [-62.68685957792238, -15.838989372140162],
              [-62.669979598377765, -15.836481674345805],
              [-62.66609961539423, -15.819880644539126],
              [-62.66763652116191, -15.798471084514262],
              [-62.67493062095005, -15.775020698702377],
              [-62.68363958599551, -15.757448709230943],
              [-62.69562956322301, -15.729185434611225],
              [-62.70504361396425, -15.713345481613572],
              [-62.71264247975212, -15.701984147124818],
              [-62.71808954335478, -15.68992309135831],
              [-62.73185748988553, -15.666506400797203],
              [-62.758521497377885, -15.651103647869604],
              [-62.76970262094821, -15.642525943427984],
              [-62.794593520641186, -15.622967275264074],
              [-62.808303632039966, -15.61095014067024],
              [-62.81806150844977, -15.598910710213971],
              [-62.82927649490895, -15.583079977309808],
              [-62.83868752816504, -15.568620859042596],
              [-62.846355628473205, -15.542752294162312],
              [-62.85362257089503, -15.525518598303364],
              [-62.86015358221735, -15.511736402537224],
              [-62.865253635028225, -15.496907474701544],
              [-62.86675248695545, -15.48344529999892],
              [-62.87693750515149, -15.45724397356227],
              [-62.88022958144573, -15.444480681947084],
              [-62.8828355151454, -15.424460170917769],
              [-62.8919184807109, -15.403090341114023],
              [-62.89817456560881, -15.37134036240812],
              [-62.89968850496177, -15.354768669262569],
              [-62.899391617951025, -15.341294256981314],
              [-62.90178263965308, -15.289836579872912],
              [-62.90330848130856, -15.270844863640292],
              [-62.90373260560963, -15.256681794193497],
              [-62.90239351275707, -15.234914159264662],
              [-62.90282451021875, -15.219716092412796],
              [-62.90145155447733, -15.205544138148639],
              [-62.90222956273081, -15.192766932574159],
              [-62.905559525227446, -15.17136843666151],
              [-62.90529650110555, -15.1502936498498],
              [-62.900352519331946, -15.13161491388206],
              [-62.8961635793971, -15.1039558068438],
              [-62.897697635317684, -15.083583926433022],
              [-62.896007508359816, -15.060084422668467],
              [-62.892829593586924, -15.047284250679269],
              [-62.89150961147362, -15.022059918877659],
              [-62.88837863535855, -14.998899379277844],
              [-62.883766576951004, -14.98574716735493],
              [-62.87339061900046, -14.97567597540413],
              [-62.856838539508374, -14.98007865387018],
              [-62.84955550383245, -15.000765190926415],
              [-62.84442561144624, -15.022502818641868],
              [-62.840740591530746, -15.041827799345043],
              [-62.83419063710723, -15.060102527579346],
              [-62.825488545222385, -15.076290497197164],
              [-62.818607505623135, -15.088000854134464],
              [-62.812786609138485, -15.103170590153582],
              [-62.798652541076706, -15.128657107886767],
              [-62.78776159899458, -15.152089724063899],
              [-62.77399449065416, -15.176543926601028],
              [-62.76532357944916, -15.185824537443608],
              [-62.75234252598925, -15.19542785155872],
              [-62.73574853698125, -15.208813918580432],
              [-62.715591568568115, -15.215613486080741],
              [-62.69290963564005, -15.223784835856009],
              [-62.67957352476131, -15.23200446539363],
              [-62.66804153472373, -15.238851977380165],
              [-62.65543749926901, -15.244314631322737],
              [-62.63454057644286, -15.25560388144413],
              [-62.60537758833544, -15.266847198736087],
              [-62.58914552990689, -15.279544273316134],
              [-62.56716164187593, -15.292899494934119],
              [-62.54619548452948, -15.319041645134291],
              [-62.5349804980703, -15.335217712449605],
              [-62.52694359402207, -15.363847946790685],
              [-62.52220161611518, -15.379024053056213],
              [-62.512767616444364, -15.397971848116129],
              [-62.504409517866165, -15.417964531226886],
              [-62.49642156413252, -15.436229368815418],
              [-62.48988351201153, -15.450703239232269],
              [-62.482620592903245, -15.467591097765876],
              [-62.472854502228316, -15.481012368780966],
              [-62.4616465565678, -15.495462602230816],
              [-62.44678460403358, -15.523020623516686],
              [-62.43377253753191, -15.538496801916182],
              [-62.426174509934356, -15.550199950416754],
              [-62.40848550909449, -15.567032991303563],
              [-62.39691563285453, -15.582171881918953],
              [-62.37923853431718, -15.596931072320132],
              [-62.354362554411864, -15.613383071888222],
              [-62.333496476989424, -15.617416778976121],
              [-62.318759582450696, -15.618029496098814],
              [-62.30801062980851, -15.6110629610871],
              [-62.302703543989, -15.592036208499195],
              [-62.303871478378554, -15.57269647564641],
              [-62.30861664140865, -15.556831376939215],
              [-62.31731051902838, -15.542023403861492],
              [-62.33103957352836, -15.52620658491665],
              [-62.339740491946756, -15.509671604507048],
              [-62.33800560562588, -15.496534480009899],
              [-62.34385650932393, -15.474111715528238],
              [-62.353305596420455, -15.451362224460524],
              [-62.36236157225771, -15.436210258076159],
              [-62.37357354123175, -15.421069355804008],
              [-62.38119151775888, -15.40522035035093],
              [-62.39582850001166, -15.34864703009174],
              [-62.395908631006094, -15.330682096999084],
              [-62.40316752680084, -15.315867083122669],
              [-62.41186559537216, -15.300023106811523],
              [-62.42306851189076, -15.286607870766716],
              [-62.4367756058044, -15.275973582560084],
              [-62.449760514939754, -15.265333427021204],
              [-62.46350063355193, -15.247096584989265],
              [-62.472206581112246, -15.22987143867158],
              [-62.48089559722813, -15.216444467962333],
              [-62.48886460786056, -15.202323475669488],
              [-62.50438655145143, -15.187551209499333],
              [-62.515598520425485, -15.172067319748919],
              [-62.527133527948195, -15.164528803663984],
              [-62.54047751781593, -15.155272332702594],
              [-62.55202861859277, -15.143933126438213],
              [-62.5643045863569, -15.13225294435236],
              [-62.57877359526988, -15.111602281841726],
              [-62.58531952637986, -15.09505539912962],
              [-62.59184249107514, -15.082998869590824],
              [-62.59660357972129, -15.064025258269112],
              [-62.598464529866035, -15.049527247971099],
              [-62.60218056282329, -15.022947897700902],
              [-62.60406162953568, -15.004648023757028],
              [-62.60673864177434, -14.969428440047466],
              [-62.60787556312212, -14.956653078491684],
              [-62.61019148297164, -14.922118463910266],
              [-62.61061057813082, -14.908994415182065],
              [-62.6117935999461, -14.885856841996969],
              [-62.611133608889446, -14.873418768225264],
              [-62.61119848482008, -14.859253687121708],
              [-62.61022149018504, -14.836796053922825],
              [-62.60993147633491, -14.82193996872084],
              [-62.61001160732934, -14.803977214923009],
              [-62.607925519265095, -14.78876623994023],
              [-62.604774594220444, -14.770440382096524],
              [-62.60414159289205, -14.75178545073382],
              [-62.60389751187135, -14.72691030901882],
              [-62.60258859387025, -14.698230118534752],
              [-62.59983061244684, -14.672653746799455],
              [-62.59773647775553, -14.659515448835833],
              [-62.592414639786426, -14.644287542408648],
              [-62.585288513338085, -14.630779267238523],
              [-62.58284351217954, -14.614876449967],
              [-62.57468456762096, -14.591686070791837],
              [-62.56611256287351, -14.579897762154928],
              [-62.56006652846938, -14.566392504469945],
              [-62.5532875804509, -14.554955229938344],
              [-62.54364755359984, -14.541087203483585],
              [-62.52859851698656, -14.530991033461305],
              [-62.51101261355586, -14.526060295094737],
              [-62.490909624599226, -14.520773661118994],
              [-62.470443531596686, -14.516173840290094],
              [-62.44172260506315, -14.509459265288058],
              [-62.420520580961124, -14.508654267306326],
              [-62.40759250112934, -14.505822525134903],
              [-62.37488162104252, -14.507377368175383],
              [-62.354701518576604, -14.518668797591602],
              [-62.33594550146151, -14.533769802004628],
              [-62.32296763312482, -14.541992113751263],
              [-62.311431619773714, -14.549530797474262],
              [-62.299529484891565, -14.559139140731304],
              [-62.284007541300696, -14.573221240993348],
              [-62.27281954456976, -14.583871622454097],
              [-62.253642588276804, -14.611403659840136],
              [-62.240974515081774, -14.631372370707766],
              [-62.23048758073308, -14.645482298888396],
              [-62.223567481465, -14.664789174680692],
              [-62.21988648486304, -14.68376999443916],
              [-62.214050500952666, -14.702736732600272],
              [-62.20785560394799, -14.72135947745474],
              [-62.20240049371827, -14.735492539688153],
              [-62.190727520069146, -14.773085207826966],
              [-62.1863055632258, -14.796552525083285],
              [-62.18332663483447, -14.818989035886148],
              [-62.17242848431562, -14.844841507512314],
              [-62.165126505538495, -14.86967373388299],
              [-62.15824161026377, -14.882074089090366],
              [-62.150272599631336, -14.895158072321351],
              [-62.14160152078826, -14.904784688127307],
              [-62.127517576507515, -14.91956567147676],
              [-62.11562348825241, -14.927445163383226],
              [-62.09544355342456, -14.938739442646522],
              [-62.0839045225883, -14.94731496779329],
              [-62.06555150737826, -14.951708426165823],
              [-62.05226552028056, -14.949565508798003],
              [-62.04800751346346, -14.937797316728734],
              [-62.04629861104246, -14.918097832591314],
              [-62.04817951011678, -14.89979997030423],
              [-62.048599611104336, -14.88632974897456],
              [-62.052638514972216, -14.868042950799662],
              [-62.05628950436078, -14.855969657454523],
              [-62.06177562763227, -14.835274067942805],
              [-62.06869857674744, -14.814238508437995],
              [-62.078212539774626, -14.77766994123013],
              [-62.081508639382406, -14.76386964055311],
              [-62.08375549234971, -14.744191111173649],
              [-62.082076597142105, -14.71862161259898],
              [-62.07855250976773, -14.703057759492197],
              [-62.07431461951829, -14.686799717163751],
              [-62.06504054656021, -14.671548844321848],
              [-62.052879578507756, -14.658704751159974],
              [-62.037776562438, -14.660005454895924],
              [-62.0240785209798, -14.66926175821925],
              [-62.00855255407541, -14.685067848328032],
              [-61.998069475402175, -14.698141102722957],
              [-61.9872165871605, -14.712934994203323],
              [-61.97237056024234, -14.737385508703056],
              [-61.96369160241028, -14.748737623098322],
              [-61.94955854017688, -14.774225984850204],
              [-61.94015153023432, -14.788340271620456],
              [-61.93038963051097, -14.800722186640826],
              [-61.91770563169989, -14.823802930522334],
              [-61.905082485505915, -14.833060072035977],
              [-61.89641157430091, -14.84268853186066],
              [-61.88270548621567, -14.853669830859076],
              [-61.865756607426874, -14.865671207474918],
              [-61.84307853017428, -14.873495714096492],
              [-61.81644452989532, -14.881992114077235],
              [-61.799926480930154, -14.878448245411334],
              [-61.791713556915084, -14.867352114339297],
              [-61.78244048978539, -14.851410907951191],
              [-61.7778586062293, -14.831351504891103],
              [-61.77972760300109, -14.815818329549984],
              [-61.784458516795794, -14.803061240543144],
              [-61.79425863799764, -14.782039427359564],
              [-61.81420153260348, -14.743456018264482],
              [-61.827232542206346, -14.724179484961724],
              [-61.833766571013825, -14.709361285962103],
              [-61.841384547540954, -14.694200434760376],
              [-61.867759547011545, -14.663940255526285],
              [-61.888660493151235, -14.651616175637884],
              [-61.898052583306125, -14.641992744955132],
              [-61.90890848903294, -14.625468661019681],
              [-61.9295004782213, -14.602088180280333],
              [-61.94286357882828, -14.587996357010582],
              [-61.96629753610995, -14.572230164760953],
              [-61.98146861323356, -14.55607337582299],
              [-62.01575847624204, -14.52343726231257],
              [-62.03343959809291, -14.508677401359108],
              [-62.04503663169919, -14.4873226589811],
              [-62.04800064030286, -14.468338151185236],
              [-62.05131551537377, -14.450394172850537],
              [-62.05175053614897, -14.43381242142118],
              [-62.05290958572115, -14.416202545747353],
              [-62.057277563108016, -14.404135119734434],
              [-62.063827517531536, -14.386898406390344],
              [-62.07290260410804, -14.366909746593109],
              [-62.08198959298706, -14.345194247102086],
              [-62.08672352426689, -14.331747998015516],
              [-62.09075153166063, -14.315532032841077],
              [-62.09370749363724, -14.297240205524275],
              [-62.09415860766656, -14.277207624554364],
              [-62.09495958233475, -14.259246882413294],
              [-62.09863253230965, -14.242686756294148],
              [-62.101219522908124, -14.227153748591093],
              [-62.10598362903943, -14.206453129937472],
              [-62.11072560694632, -14.191625207930201],
              [-62.120593621563955, -14.155403483876029],
              [-62.12138353211995, -14.139863603012344],
              [-62.1248246386528, -14.094974655790594],
              [-62.12524457200227, -14.08219527092129],
              [-62.12495455815214, -14.066992342565584],
              [-62.121448575688646, -14.0472858176295],
              [-62.11756557521997, -14.032064784362916],
              [-62.1122435696128, -14.017182715261043],
              [-62.10258459966056, -14.007458366463965],
              [-62.092925629708304, -13.99704318120655],
              [-62.07609158299313, -13.984864946433504],
              [-62.06967154806895, -13.973777532540794],
              [-62.05712853286941, -13.965764936011794],
              [-62.04242348956279, -13.959814455303956],
              [-62.02949155405554, -13.958018548727921],
              [-62.015838607236475, -13.95656562963012],
              [-62.00218951609287, -13.955457877305435],
              [-61.97916057237303, -13.96086135501156],
              [-61.957950501643936, -13.962130207515429],
              [-61.931690501885015, -13.967172089918222],
              [-61.918373501745535, -13.970902707386983],
              [-61.90327048567576, -13.972549751362521],
              [-61.878822485746994, -13.974840357864423],
              [-61.85612462720289, -13.986808709781712],
              [-61.84098456312104, -13.996747132386076],
              [-61.81612752631693, -14.009051095706866],
              [-61.80249050511391, -14.00517932698844],
              [-61.785571633538524, -14.010961163804268],
              [-61.76323654376418, -14.022244211317286],
              [-61.73226155582661, -14.036587324044461],
              [-61.717494486436436, -14.043075084746363],
              [-61.700195579370714, -14.053689256385383],
              [-61.675647499517936, -14.077052638042233],
              [-61.65612453826158, -14.102855153525411],
              [-61.64384454718393, -14.115227345538102],
              [-61.63404861693367, -14.13555698115681],
              [-61.62712063867659, -14.15693737215858],
              [-61.612461528199404, -14.219384227254409],
              [-61.609508583707935, -14.23560522157075],
              [-61.59896062910407, -14.26318436525267],
              [-61.58592559618768, -14.284189079353752],
              [-61.57395556788974, -14.307960659695652],
              [-61.566657612426155, -14.332447886931305],
              [-61.55940659562039, -14.346227065212332],
              [-61.5496255851578, -14.36275684884194],
              [-61.54273951641663, -14.37550320901272],
              [-61.53333250647407, -14.388924312389719],
              [-61.52069863144402, -14.401294325107557],
              [-61.50408150838322, -14.419170410026481],
              [-61.48459660096732, -14.436338224126416],
              [-61.47448349950061, -14.446992461262624],
              [-61.439479498340916, -14.47789938814637],
              [-61.43220853260557, -14.496168249048452],
              [-61.422805545976544, -14.508900527621847],
              [-61.41196858335091, -14.520932582003354],
              [-61.37877658202137, -14.548391864469721],
              [-61.35208860228592, -14.568287652779674],
              [-61.33982051351079, -14.578931663994041],
              [-61.3243106398605, -14.591285918733888],
              [-61.30916554663675, -14.601912160313475],
              [-61.298698561217634, -14.61187472279903],
              [-61.26626160172685, -14.631050505625524],
              [-61.248992534236464, -14.634757318489278],
              [-61.23640056872233, -14.638145786670151],
              [-61.22379653326762, -14.643605423127582],
              [-61.20039358902774, -14.652118084000506],
              [-61.182422620964786, -14.652711858022002],
              [-61.16231158538109, -14.649497230513163],
              [-61.143646595734566, -14.64387163237268],
              [-61.1285975591213, -14.63412029384736],
              [-61.11714150912657, -14.623695720858365],
              [-61.1139676176672, -14.61055189083865],
              [-61.11654253832509, -14.597784575909913],
              [-61.12596161820824, -14.581943617083908],
              [-61.13357557142183, -14.566785448091196],
              [-61.139381548118806, -14.554726236343384],
              [-61.149528512474376, -14.536468439553488],
              [-61.15248162460391, -14.519558620433543],
              [-61.159763486813375, -14.499560908180882],
              [-61.165275593984745, -14.472299606267683],
              [-61.1664195561312, -14.458488744392668],
              [-61.16575956507455, -14.445702654000797],
              [-61.171237474080925, -14.426733904182925],
              [-61.17675360456583, -14.399127603134701],
              [-61.187278592754964, -14.377074145307319],
              [-61.19742958042407, -14.357437190167673],
              [-61.220973508275506, -14.317836050388081],
              [-61.23974260115956, -14.300318040373156],
              [-61.259586589307844, -14.284188241163434],
              [-61.27581361859448, -14.271837003908729],
              [-61.29564251931703, -14.258468874159831],
              [-61.31154651005498, -14.238863937890358],
              [-61.331378595900745, -14.224461146012516],
              [-61.337543485692024, -14.212747939228109],
              [-61.342285631236976, -14.197572671152898],
              [-61.34738551640979, -14.182745754973979],
              [-61.35570153783402, -14.172427296879277],
              [-61.36369351488119, -14.153815616136995],
              [-61.37569053290737, -14.123825669461525],
              [-61.38368250995454, -14.105213821081179],
              [-61.39237957269748, -14.08902484563501],
              [-61.39499657050935, -14.067274309788672],
              [-61.39758255527944, -14.051399320435706],
              [-61.39982253508613, -14.033445619093328],
              [-61.39237554938394, -14.01164495946594],
              [-61.38309460326526, -13.99743109568584],
              [-61.37343563331301, -13.988054595870892],
              [-61.35657459686959, -13.981400035295621],
              [-61.35378660823278, -13.987255632859444],
              [-61.35590756501426, -13.973408561162671],
              [-61.354133619024566, -13.96856298293244],
              [-61.352703498703434, -13.967518933071915],
              [-61.348747575677066, -13.96818780894597],
              [-61.34732449615461, -13.964725244740976],
              [-61.341899560776355, -13.9722966178864],
              [-61.332527587189105, -13.978465195715046],
              [-61.328922530629995, -13.980516079785957],
              [-61.32488262093372, -13.982004873429332],
              [-61.32926954142178, -13.978838358044754],
              [-61.33544549532526, -13.964707307468132],
              [-61.323272624970286, -13.954280722822375],
              [-61.30426448020745, -13.94588926426951],
              [-61.28990963281581, -13.940632637507122],
              [-61.28064360648479, -13.923655930799782],
              [-61.27784757122093, -13.90636708201788],
              [-61.28907060430717, -13.88880917414383],
              [-61.30671350467952, -13.881993681027438],
              [-61.328300593413786, -13.876580145037508],
              [-61.3458975609567, -13.87978370843416],
              [-61.364925487011064, -13.884029645310648],
              [-61.384323557909994, -13.886896423837413],
              [-61.40443459349369, -13.890111051346253],
              [-61.418098604424955, -13.888455122553353],
              [-61.43575659222304, -13.878186788239702],
              [-61.44078053735302, -13.859537221295],
              [-61.442672500539544, -13.86195003594537],
              [-61.4437485692702, -13.862300064222268],
              [-61.44985562392952, -13.863023925381185],
              [-61.45454060489476, -13.860287233991869],
              [-61.45956455002474, -13.86169489081243],
              [-61.46207057143846, -13.863781817067036],
              [-61.46133061702545, -13.867578483932903],
              [-61.45987652446122, -13.871023278503174],
              [-61.46231850813463, -13.887620117358267],
              [-61.46950163152461, -13.888003337971782],
              [-61.473464595349654, -13.885607454765875],
              [-61.47814555300137, -13.883906598971919],
              [-61.49076450824374, -13.87533794698578],
              [-61.497970598048454, -13.871231484978239],
              [-61.50842249604183, -13.864033944714777],
              [-61.514198633163474, -13.858537092607207],
              [-61.5203365332265, -13.85235074514378],
              [-61.52718354229883, -13.848588779357044],
              [-61.53186449995056, -13.846541918599712],
              [-61.542674640485984, -13.839689545109366],
              [-61.54663861013941, -13.837986510020585],
              [-61.55276460789992, -13.834219682730009],
              [-61.555652508822675, -13.831124916436693],
              [-61.55927248516946, -13.825617503131127],
              [-61.56108062932415, -13.823209549984654],
              [-61.564716531286976, -13.813901111223515],
              [-61.56653557191581, -13.80872964459752],
              [-61.567295475258405, -13.800095949042657],
              [-61.56661654110056, -13.791800547100252],
              [-61.568080524310545, -13.786282069682443],
              [-61.56845050151705, -13.783865064080544],
              [-61.57099558259961, -13.777315612571215],
              [-61.57282652553096, -13.770069960183491],
              [-61.57428363558033, -13.765934161514792],
              [-61.5797196350708, -13.755944106386835],
              [-61.585136523822, -13.75044826010759],
              [-61.592346636940235, -13.745649788173296],
              [-61.59811053648323, -13.742225948360954],
              [-61.60280658156067, -13.73741607703829],
              [-61.60462159887598, -13.73327944017933],
              [-61.604640541977176, -13.729134924331333],
              [-61.60287849828998, -13.72152432387901],
              [-61.600402484089656, -13.712874702708064],
              [-61.601879543068605, -13.704936537479341],
              [-61.60441959500926, -13.699422753927308],
              [-61.60803554804251, -13.694606009444044],
              [-61.61200354100947, -13.6911714407957],
              [-61.62247454974211, -13.680174886733425],
              [-61.625381561404126, -13.673625435224096],
              [-61.626846550442494, -13.667415786069853],
              [-61.6258125588658, -13.657737537740275],
              [-61.625144521182094, -13.64737029696903],
              [-61.626964567639305, -13.641852657741595],
              [-61.630214566779586, -13.63876090893342],
              [-61.62913849804892, -13.63806487569309],
              [-61.63636755426835, -13.62946655176961],
              [-61.641418489126565, -13.62500369123876],
              [-61.64718657962115, -13.621236025757867],
              [-61.653675513789494, -13.616433195233867],
              [-61.65982062228926, -13.608865677763902],
              [-61.66198751190019, -13.606458898083872],
              [-61.66489050024869, -13.600948299655045],
              [-61.6713864752157, -13.59441879707532],
              [-61.67214151705444, -13.586824122239022],
              [-61.670459604361696, -13.580230749557018],
              [-61.66609162697485, -13.574354197235266],
              [-61.65786361553404, -13.569825287307253],
              [-61.64957056052452, -13.571846164164754],
              [-61.64236061504437, -13.576644803737167],
              [-61.638027506374755, -13.581457692544973],
              [-61.630817560894585, -13.586599319595678],
              [-61.61642851533796, -13.589630886338966],
              [-61.61173649357404, -13.593749753563202],
              [-61.61027955116273, -13.59857756215871],
              [-61.6077495575059, -13.601330179164108],
              [-61.60161953643187, -13.605785998896295],
              [-61.594062580159914, -13.607819783884679],
              [-61.58509863761962, -13.603970143390711],
              [-61.57827358913363, -13.60289725978322],
              [-61.57215848784726, -13.604244063986698],
              [-61.558551473857634, -13.610857553236372],
              [-61.55741857582339, -13.602820146273928],
              [-61.54415555514041, -13.5951515430516],
              [-61.53015157421602, -13.59265893268298],
              [-61.51575850534587, -13.596382509353077],
              [-61.50064056948844, -13.60114041287602],
              [-61.48049952669135, -13.604487139179014],
              [-61.45821355486966, -13.605059120252236],
              [-61.442775597948696, -13.600833132305354],
              [-61.44338261537722, -13.613610337879834],
              [-61.44096359811854, -13.610787648163807],
              [-61.44062061064028, -13.607677794444783],
              [-61.44063553042794, -13.604221097572008],
              [-61.441364588366824, -13.602155126075388],
              [-61.441738588886864, -13.598700440859375],
              [-61.442317610758764, -13.596514608147231],
              [-61.44428249650298, -13.592495988485098],
              [-61.44466052033654, -13.588354154846115],
              [-61.447215492064856, -13.580076522538434],
              [-61.448306480583184, -13.577662869697804],
              [-61.45049248093338, -13.57111224472203],
              [-61.45520763675009, -13.561811014397676],
              [-61.45453658158124, -13.551440420865163],
              [-61.45240053737402, -13.547286014371423],
              [-61.44524356552196, -13.541029426559305],
              [-61.440933590905146, -13.540657270057977],
              [-61.43445957652446, -13.541660248592848],
              [-61.43049258938589, -13.5447479740875],
              [-61.428325532136896, -13.54715475376753],
              [-61.42432753195655, -13.556113164251713],
              [-61.418201534196044, -13.560571833831034],
              [-61.417121609789916, -13.560222140830206],
              [-61.41172751981537, -13.561228807402529],
              [-61.40488051074303, -13.565336778152641],
              [-61.394081601957865, -13.569077453905265],
              [-61.38004258467818, -13.5741852180671],
              [-61.37031153035855, -13.581039603214208],
              [-61.36060763340524, -13.581334646206244],
              [-61.354507619544606, -13.579227771022147],
              [-61.35128762761772, -13.576100818220596],
              [-61.34987259472233, -13.571257251647125],
              [-61.34918259645228, -13.565378520030492],
              [-61.34560754710657, -13.561216066909708],
              [-61.34345255979815, -13.560858997834089],
              [-61.33768849261709, -13.563591833547946],
              [-61.33049363456266, -13.565624780346013],
              [-61.315364634593024, -13.573142174034956],
              [-61.301689559549544, -13.57755977228868],
              [-61.29988862383159, -13.578587896533122],
              [-61.29592163669302, -13.58167344273295],
              [-61.2833554874407, -13.578841700561497],
              [-61.28047948882046, -13.57951778487228],
              [-61.28291661099003, -13.595766774745243],
              [-61.28216961577834, -13.60198111776532],
              [-61.27962855800931, -13.607839732814284],
              [-61.27385359435411, -13.612991585786801],
              [-61.26800956381669, -13.621632825054576],
              [-61.26803554771655, -13.616143181383734],
              [-61.26845162539057, -13.603364969980873],
              [-61.2544245104134, -13.605707879558622],
              [-61.238258501381935, -13.604585207446291],
              [-61.224941501242455, -13.608314651448609],
              [-61.20408263225674, -13.611314702235973],
              [-61.185012629048416, -13.61605014225836],
              [-61.165557561207834, -13.626654423251637],
              [-61.15401853037159, -13.634539111938068],
              [-61.13381160581547, -13.652394912651289],
              [-61.12046459846259, -13.662688057398384],
              [-61.097728518439965, -13.683292954717615],
              [-61.072822531321265, -13.706652313060943],
              [-61.05984147786137, -13.716257471194751],
              [-61.04360154044382, -13.729988872627644],
              [-61.02450153002209, -13.741285834099926],
              [-61.00465050107513, -13.759488813243024],
              [-60.990241506588944, -13.767014085920948],
              [-60.97184758769137, -13.781078416548212],
              [-60.950588566647696, -13.793058670768062],
              [-60.93365058433305, -13.802985861622176],
              [-60.90341152749497, -13.813189152367102],
              [-60.88687855874214, -13.813792314120178],
              [-60.869262480459966, -13.814733937123776],
              [-60.8496136230178, -13.810506272796204],
              [-60.85069656490907, -13.809681325884924],
              [-60.86761862160766, -13.802867006235033],
              [-60.88022651273785, -13.796716365679174],
              [-60.889957567057465, -13.789858627770741],
              [-60.89249761899812, -13.784689172801507],
              [-60.897926577689915, -13.776429645404733],
              [-60.9048005764905, -13.76644713398963],
              [-60.908073542045486, -13.758174866099978],
              [-60.9127845069106, -13.749910477199364],
              [-60.918590483607574, -13.737848080328376],
              [-60.92176856601854, -13.728494714566182],
              [-60.922603571213656, -13.724742974701371],
              [-60.917224568664835, -13.721951298027136],
              [-60.90573163829609, -13.720851424691432],
              [-60.90068455911333, -13.724278114350795],
              [-60.89239854490249, -13.728726725646311],
              [-60.88373148937295, -13.73766032569705],
              [-60.875469615043286, -13.737270064284871],
              [-60.86936557786912, -13.736201203990902],
              [-60.860011541554684, -13.737877081713407],
              [-60.854602531792466, -13.742338936415877],
              [-60.85134163617805, -13.748538527286371],
              [-60.848426577888986, -13.756814818489488],
              [-60.84516149132298, -13.763705413458354],
              [-60.83723858115805, -13.767464194121885],
              [-60.82825049873658, -13.767762254599063],
              [-60.82322353612146, -13.767386074784213],
              [-60.8135484729151, -13.761116746479274],
              [-60.80245552696627, -13.750691335299905],
              [-60.797103514145704, -13.742717966077805],
              [-60.793170557534054, -13.738551992557689],
              [-60.7917474780116, -13.73543543331607],
              [-60.790679623546055, -13.733700546995237],
              [-60.788177625445854, -13.730577785145272],
              [-60.78281354268471, -13.72467709294233],
              [-60.77457849044522, -13.718413464331547],
              [-60.77064151052005, -13.714937656719542],
              [-60.75917456405118, -13.708311259338984],
              [-60.74948860437068, -13.704457427893374],
              [-60.74265651508601, -13.705111383979727],
              [-60.73156356913718, -13.695033151230291],
              [-60.72549054500479, -13.687054417590105],
              [-60.71439759905594, -13.677668027129414],
              [-60.706451554838225, -13.671628530609723],
              [-60.70241952413096, -13.671696424025527],
              [-60.69789162003137, -13.672050475616004],
              [-60.6885335604034, -13.675110205554006],
              [-60.67917248329027, -13.67920409470679],
              [-60.67448062916442, -13.683668799256338],
              [-60.674022474336425, -13.686230644145269],
              [-60.65878652128217, -13.670413657562392],
              [-60.6623426275267, -13.665984995196482],
              [-60.67067356873859, -13.651867020388806],
              [-60.682933610886664, -13.642950351782474],
              [-60.69804350011705, -13.639577809217656],
              [-60.71641562606635, -13.629658497352523],
              [-60.72541862827548, -13.625561590714597],
              [-60.733734482061635, -13.61524346789605],
              [-60.74093252523929, -13.612172673845805],
              [-60.75030550465492, -13.606004934207476],
              [-60.758590513037376, -13.601905345360535],
              [-60.77625654746251, -13.58956299292322],
              [-60.79175150132512, -13.579974766233818],
              [-60.8403705631015, -13.557092338181633],
              [-60.84577554955018, -13.55401332986628],
              [-60.86161047340592, -13.549261796589747],
              [-60.87965050835109, -13.533814787213316],
              [-60.87321052449734, -13.527215211922964],
              [-60.866767523158444, -13.52199965648623],
              [-60.86865261318435, -13.502662941118615],
              [-60.882347637157395, -13.494792501667575],
              [-60.90212658173698, -13.491790439223507],
              [-60.91542061546174, -13.491861517762459],
              [-60.92545358593402, -13.497789032055607],
              [-60.93370858710307, -13.500251970487],
              [-60.946346485446654, -13.487192797689431],
              [-60.95393361657007, -13.478944334404844],
              [-60.95003854616081, -13.466140306740158],
              [-60.95009654893083, -13.45404991431252],
              [-60.95841558784021, -13.441657941008373],
              [-60.970661548390936, -13.435851293759129],
              [-60.98288353669855, -13.435571170554738],
              [-60.987865572312614, -13.445616546243741],
              [-60.99540760879691, -13.447039458128131],
              [-61.011222583723054, -13.446088615031044],
              [-61.02669155368581, -13.442718922313304],
              [-61.03924563299755, -13.447278677644988],
              [-61.04853462574329, -13.458728860307474],
              [-61.05783451496316, -13.468451197447791],
              [-61.07147555947971, -13.471636991209664],
              [-61.08262248488505, -13.470313823973186],
              [-61.08620859834298, -13.471715948737653],
              [-61.09337948415431, -13.474863688659127],
              [-61.11102255216473, -13.46770420223612],
              [-61.12722762086503, -13.46053666918607],
              [-61.13656254644022, -13.46266148164301],
              [-61.13906052122688, -13.466130416094416],
              [-61.139766612751046, -13.468205104770334],
              [-61.1458545566711, -13.472730661937021],
              [-61.165283640611804, -13.46765307262666],
              [-61.182605514092245, -13.452547206709824],
              [-61.191974637832416, -13.44672346037811],
              [-61.217151528062004, -13.441678895766302],
              [-61.23659553179037, -13.433493464393678],
              [-61.240566542242476, -13.429716746457359],
              [-61.25030161987563, -13.422513171223613],
              [-61.26033056703439, -13.4298218555233],
              [-61.2642595003325, -13.435026179209785],
              [-61.27893453642572, -13.447543378147486],
              [-61.286815537074744, -13.45276731548745],
              [-61.296150630288, -13.45489112211601],
              [-61.31876349633383, -13.460538513204767],
              [-61.348617488539674, -13.473640768984694],
              [-61.354122554912365, -13.464695434269458],
              [-61.40101259770009, -13.367274752865399],
              [-61.456783602186604, -13.37591129826734],
              [-61.47486152333417, -13.35292895792918],
              [-61.4975504970609, -13.315875246165035],
              [-61.49573849723075, -13.288506152976936],
              [-61.49743247986409, -13.259916989961425],
              [-61.51439259040313, -13.229851103243107],
              [-61.54204549483305, -13.204112793138336],
              [-61.54026450804467, -13.17788749445856],
              [-61.49901548609927, -13.17537275586551],
              [-61.48344056888028, -13.163152611576606],
              [-61.44620564036934, -13.129703788733536],
              [-61.42488056992859, -13.123312252280186],
              [-61.38389960124698, -13.132190531769936],
              [-61.348026564365256, -13.159235915857153],
              [-61.32012957891463, -13.174682757595463],
              [-61.29215263010765, -13.18670408314091],
              [-61.247604491069325, -13.19450243822456],
              [-61.22475458480146, -13.17212979752395],
              [-61.198558622782855, -13.157821050599807],
              [-61.16212047798851, -13.159721730965657],
              [-61.134628506099716, -13.139717145552368],
              [-61.1633685433725, -13.108269250637193],
              [-61.145770570001204, -13.095044954346918],
              [-61.089343597771574, -13.095426163303728],
              [-61.05676263718409, -13.096345993359023],
              [-61.002296527385155, -13.079508258606438],
              [-60.972629619258356, -13.053394774515198],
              [-60.99189357970636, -12.98795239485969],
              [-61.02263655656381, -12.968724476595412],
              [-61.05868963672593, -12.959142284876293],
              [-61.09603151832148, -12.950742779696384],
              [-61.128200592186545, -12.9375887237548],
              [-61.14612948309551, -12.910141511228971],
              [-61.157634483404834, -12.877978639972298],
              [-61.15924447936827, -12.84976968008516],
              [-61.17164952844142, -12.806559460432936],
              [-61.19144456627511, -12.798651972969878],
              [-61.21745260602435, -12.789341522551979],
              [-61.19047561826713, -12.728884698909951],
              [-61.15972157729746, -12.667302018032274],
              [-61.138244626771026, -12.700968770358088],
              [-61.11156452602455, -12.728659728628486],
              [-61.07964355649372, -12.749146441112828],
              [-61.02553149828525, -12.781273270185864],
              [-60.98608357969586, -12.801961986536924],
              [-60.93824353200134, -12.835126662862137],
              [-60.92132147530275, -12.855190256873811],
              [-60.91748457530157, -12.893261531684345],
              [-60.89296750849057, -12.91108263131838],
              [-60.86590150492155, -12.927747363589276],
              [-60.84511555849353, -12.95912015665192],
              [-60.8070146117457, -12.92846116646433],
              [-60.78772751724492, -12.902539125041756],
              [-60.74481552570795, -12.869050404339532],
              [-60.71028560499231, -12.843398930751732],
              [-60.65496051775514, -12.835259599846609],
              [-60.62283352104407, -12.811832012811351],
              [-60.58851247735575, -12.796439318167586],
              [-60.576095525980094, -12.758929630870284],
              [-60.62710560945851, -12.724873958236742],
              [-60.759906639475204, -12.736197406523104],
              [-60.81825256461961, -12.719127493052383],
              [-60.869029631189534, -12.674766270454995],
              [-60.8926966053797, -12.624050727054453],
              [-60.85834857196312, -12.606404138644677],
              [-60.85380558043781, -12.580439852430061],
              [-60.8665965323335, -12.582317566381164],
              [-60.891879537457385, -12.590205272552737],
              [-60.92296952510664, -12.583234714227501],
              [-60.95606647565407, -12.560284057483386],
              [-60.985897501445194, -12.553340488886363],
              [-61.040927545690295, -12.551826884809543],
              [-61.06408355906241, -12.531590120678118],
              [-61.06779053956423, -12.489848075187467],
              [-61.05922658144384, -12.455789385068783],
              [-61.060893574348924, -12.428799992094866],
              [-61.08324458973931, -12.423283358695755],
              [-61.10040653650701, -12.452200421763791],
              [-61.120983605907696, -12.469998387345015],
              [-61.14833459418496, -12.464338591039564],
              [-61.14937948223579, -12.417772927905048],
              [-61.13940853784706, -12.378859607100651],
              [-61.10482748752199, -12.352876377784867],
              [-61.06245461999778, -12.317302742484799],
              [-61.04424661171281, -12.294531290830776],
              [-61.03558357949679, -12.256798644908827],
              [-61.02791547918862, -12.210465998682764],
              [-61.00324653229194, -12.180516955694827],
              [-60.946849567275706, -12.17591830833237],
              [-60.904487596225636, -12.18073387934919],
              [-60.88104659814529, -12.191165325498844],
              [-60.87858550373264, -12.234105480230482],
              [-60.87351663160159, -12.273442757697865],
              [-60.870838613534545, -12.309039191774502],
              [-60.85532756641781, -12.333955404815129],
              [-60.83466349286209, -12.354108182276661],
              [-60.80535851531475, -12.378166423707398],
              [-60.78068554510455, -12.389849790916458],
              [-60.7319565127584, -12.392367546994649],
              [-60.69702157848094, -12.39574025719753],
              [-60.67648658623423, -12.420784545718789],
              [-60.681819488315554, -12.451054447960587],
              [-60.66674463544048, -12.452120458407421],
              [-60.626178570966445, -12.41166838781669],
              [-60.57984961277781, -12.375875649567433],
              [-60.59418853455338, -12.324189146502135],
              [-60.580478590792666, -12.276403581178272],
              [-60.56180153120556, -12.280144256930896],
              [-60.515842550223425, -12.263758139121876],
              [-60.51645660845064, -12.230589607121203],
              [-60.48928063431184, -12.216172398369906],
              [-60.439601597058925, -12.186102488338065],
              [-60.423526615496016, -12.13375599421613],
              [-60.378391575596794, -12.093302079606644],
              [-60.320079513341256, -12.106791076399475],
              [-60.291976500710405, -12.106082973218577],
              [-60.25897560677343, -12.091721085028269],
              [-60.23711359161476, -12.116074369451042],
              [-60.17779955665269, -12.140998629118712],
              [-60.16684357100186, -12.113705475973404],
              [-60.15913054369264, -12.068055619580662],
              [-60.15752457104273, -12.039478694143838],
              [-60.11521959693431, -12.027515371368452],
              [-60.09114459169719, -12.061050024900112],
              [-60.06351850935745, -12.092349057214733],
              [-60.027076508887646, -12.081433807613394],
              [-59.98043457043414, -12.093547837007975],
              [-59.96022462839288, -12.072091338325322],
              [-59.95810350397332, -12.00573801343188],
              [-59.98307051134721, -11.951599636047433],
              [-60.01419050620984, -11.919115905536898],
              [-60.052185505701445, -11.877380733206849],
              [-60.06806150088278, -11.842828181352616],
              [-60.079193506500445, -11.803763651014833],
              [-60.10874960586716, -11.74498974589386],
              [-60.10548049598762, -11.679817263520931],
              [-60.06757350647945, -11.643729649917702],
              [-60.001819487663624, -11.620596435322227],
              [-59.97080963100882, -11.574091624804794],
              [-59.91817060846576, -11.56906985896967],
              [-59.86331960167264, -11.57322040978795],
              [-59.822395629932686, -11.57031574505885],
              [-59.773761480730585, -11.512549847614707],
              [-59.712966531114105, -11.507585078721206],
              [-59.654323551320886, -11.487694319553157],
              [-59.62624350510474, -11.483454585285017],
              [-59.563285521552785, -11.49222406767143],
              [-59.52978858658544, -11.523540031430514],
              [-59.50323857297637, -11.550193980639051],
              [-59.440113621913014, -11.546347525268231],
              [-59.39791157757567, -11.533086180965938],
              [-59.331176540811285, -11.518942222258374],
              [-59.280696528890175, -11.495437856989952],
              [-59.27309749546424, -11.54822423339101],
              [-59.289909581593065, -11.58928633889542],
              [-59.303192551205626, -11.628093712443501],
              [-59.23272354421117, -11.701059856034362],
              [-59.19780352972137, -11.71404878848324],
              [-59.135879537746106, -11.715865817455324],
              [-59.086528568183766, -11.69117641871486],
              [-59.04410557687852, -11.657235745793002],
              [-58.96675854776994, -11.635097295467347],
              [-58.91400150803007, -11.617280554422962],
              [-58.87278349912644, -11.583289757720024],
              [-58.84561154830118, -11.551449925012037],
              [-58.78718951547587, -11.550208229874443],
              [-58.77083960748857, -11.5498570281311],
              [-58.72693653971922, -11.603006508578005],
              [-58.69472455050986, -11.65834165409899],
              [-58.65778751483805, -11.709131293523683],
              [-58.62985247554698, -11.721999358928656],
              [-58.587791582459246, -11.723520339080324],
              [-58.5164676213401, -11.720699493382995],
              [-58.45563847355862, -11.714335952486294],
              [-58.41937249305566, -11.710047267903576],
              [-58.36227463329524, -11.731162623126636],
              [-58.350944479486316, -11.775993735216446],
              [-58.35134848721975, -11.82646821508149],
              [-58.35532352098538, -11.883796241903326],
              [-58.326534533398004, -11.939098530363822],
              [-58.27979653833401, -11.945213966926303],
              [-58.21418752644327, -11.931964022012266],
              [-58.16152151417195, -11.927782122876124],
              [-58.12291748559505, -11.926925324732736],
              [-58.11043163497523, -11.97865776062747],
              [-58.13058458007484, -12.014078174737278],
              [-58.16482951608225, -12.055123684073408],
              [-58.18154152228708, -12.097448774749466],
              [-58.161941615159506, -12.135469087588717],
              [-58.1448405210089, -12.194123969684426],
              [-58.147376549636036, -12.219347295657656],
              [-58.13379652537466, -12.279125352779943],
              [-58.121166506020074, -12.311355111624039],
              [-58.09308260412847, -12.315018673867371],
              [-58.05876558375368, -12.269381055053316],
              [-58.04558152059869, -12.22931371381867],
              [-58.00919349958538, -12.216961638373618],
              [-57.965782617170944, -12.204656166310315],
              [-57.929294516233625, -12.177370221601734],
              [-57.87285949737695, -12.14217963927723],
              [-57.833042607409425, -12.139003903799164],
              [-57.79578353901732, -12.170245603895978],
              [-57.78544261742208, -12.200159610528658],
              [-57.77775959732625, -12.279417210648774],
              [-57.74662753252302, -12.355390948739853],
              [-57.71876156011419, -12.394605347506513],
              [-57.68741961245526, -12.44072928434332],
              [-57.67828350562358, -12.478672651311285],
              [-57.67037953855984, -12.525796381559758],
              [-57.642360512598884, -12.544344192199787],
              [-57.604751583567904, -12.528511447638891],
              [-57.6022495854677, -12.503265993441289],
              [-57.57519548420119, -12.48735999104656],
              [-57.564430605942945, -12.450681453268885],
              [-57.5408895279386, -12.43359963749566],
              [-57.52230047833491, -12.461267964627439],
              [-57.536685500578, -12.516296164853827],
              [-57.522861562934, -12.557717183452553],
              [-57.5195235538103, -12.588739277685988],
              [-57.53611351950478, -12.617344198679461],
              [-57.56207663225295, -12.644741287424267],
              [-57.598606475068124, -12.672066627077754],
              [-57.62114759202271, -12.712105805117687],
              [-57.60259257294595, -12.745522609090585],
              [-57.56172157483411, -12.776783419926744],
              [-57.48319655305217, -12.792198578071066],
              [-57.44919553042739, -12.79814302380862],
              [-57.41872060683373, -12.804063832579175],
              [-57.39884962895721, -12.817960860418964],
              [-57.40030254805498, -12.867332449463106],
              [-57.42506755156215, -12.886706045204733],
              [-57.476711474559295, -12.885248264603092],
              [-57.53776156930867, -12.886013029449543],
              [-57.582569547345685, -12.916724155074917],
              [-57.57578657601367, -12.956954272869382],
              [-57.54900756880964, -12.99272303887551],
              [-57.50579852262386, -13.032040199775224],
              [-57.45292262749685, -13.028929507865826],
              [-57.400150500331236, -13.043029545400714],
              [-57.340454585860144, -13.080129022356232],
              [-57.312461543799046, -13.117038565385599],
              [-57.269348554223754, -13.182745142629415],
              [-57.23791457326787, -13.238047598727974],
              [-57.213401529770394, -13.270344245159436],
              [-57.19239463637447, -13.300320445513705],
              [-57.170097600440585, -13.308478719520053],
              [-57.1218984720137, -13.311029667934804],
              [-57.07365056091028, -13.304382148158254],
              [-57.04774461274181, -13.296469463915173],
              [-57.04208749864537, -13.343591517783068],
              [-57.03055953192133, -13.3930420643552],
              [-57.0036425585909, -13.423038213639018],
              [-56.95904546923798, -13.447371381494179],
              [-56.933113537169646, -13.436004179673205],
              [-56.91873957903876, -13.376331399254866],
              [-56.916179578168524, -13.328091534778537],
              [-56.90189748569652, -13.286794400708857],
              [-56.85952763565746, -13.276631846013345],
              [-56.8349374786507, -13.303157049188997],
              [-56.79863763525887, -13.343510548598317],
              [-56.76697952221187, -13.370060562207357],
              [-56.71642256441953, -13.375992435090154],
              [-56.66941450443498, -13.388797300945157],
              [-56.643600589563476, -13.409567154119031],
              [-56.60253161089844, -13.444175697086507],
              [-56.55660649280517, -13.431678447078355],
              [-56.52475358432818, -13.398447721356149],
              [-56.491714468912704, -13.358317515847602],
              [-56.47397651775826, -13.318143221528373],
              [-56.43389157452694, -13.271126276726477],
              [-56.4091795446479, -13.260840005139983],
              [-56.35738357392701, -13.225327222457054],
              [-56.32322648026495, -13.186320024337022],
              [-56.276218587918464, -13.188703837602361],
              [-56.25270047632884, -13.18184928481719],
              [-56.2409326195357, -13.170372950616752],
              [-56.23268549735563, -13.15314730138482],
              [-56.234985491589114, -13.115210807577455],
              [-56.22085947015438, -13.093394054695978],
              [-56.220817560638466, -13.056612587147242],
              [-56.214916533159396, -13.0336310849994],
              [-56.251296507545646, -13.008290077105471],
              [-56.29589862604048, -12.987531455681847],
              [-56.30289449771335, -12.953034727302793],
              [-56.30286449049996, -12.931199031320148],
              [-56.315723503449505, -12.892097788246417],
              [-56.32273060687264, -12.86909834882573],
              [-56.36035562915772, -12.908107726240587],
              [-56.40964557846489, -12.896515386500084],
              [-56.41896860173755, -12.859720004992084],
              [-56.41418454667668, -12.810310362107487],
              [-56.41294452791975, -12.772385770602625],
              [-56.401115483233355, -12.714942744069106],
              [-56.398723623340985, -12.687365612043948],
              [-56.42448456458439, -12.660879468537132],
              [-56.451377565671706, -12.6148554439863],
              [-56.436092494664805, -12.5896000991429],
              [-56.407890575576374, -12.550577981235222],
              [-56.376186529699936, -12.51615199611905],
              [-56.3620715723774, -12.479396680108266],
              [-56.33977151895837, -12.454145358578444],
              [-56.31629162884727, -12.416248930268239],
              [-56.30450449367679, -12.350748380204664],
              [-56.31265656507475, -12.3024607388802],
              [-56.31846656508526, -12.255327788538182],
              [-56.34422650050027, -12.255293758011305],
              [-56.36647760360471, -12.256409724601099],
              [-56.395679483742896, -12.194300157289376],
              [-56.41080060472353, -12.117271137587522],
              [-56.40960350131091, -12.097732753629316],
              [-56.4259525711079, -12.068974611445697],
              [-56.45399858679711, -12.040196520332415],
              [-56.48438650623572, -12.01486020630432],
              [-56.526531553631415, -12.030874335250076],
              [-56.56980162007237, -12.018147924008872],
              [-56.66218947152909, -11.997260388914299],
              [-56.683185636088936, -11.968480453782377],
              [-56.697086519604156, -11.905247711444076],
              [-56.70519651384815, -11.869605176899881],
              [-56.70858347328644, -11.812139183951672],
              [-56.688655498468265, -11.785756976044297],
              [-56.65237862149115, -11.765150234706368],
              [-56.62194460158504, -11.741080929163388],
              [-56.58099347247877, -11.71587788739589],
              [-56.53897851222048, -11.757326063360892],
              [-56.52270152679087, -11.81826669045472],
              [-56.49468652414343, -11.852791414390424],
              [-56.4888725008194, -11.878085651264541],
              [-56.46086453897064, -11.924103473207026],
              [-56.45508152868838, -11.973533065021229],
              [-56.41062559058507, -11.971301131841585],
              [-56.38959153982283, -11.990871031755773],
              [-56.368598560386204, -12.056414497163644],
              [-56.33472058410014, -12.116227423003181],
              [-56.30432562386285, -12.156494588809664],
              [-56.27977352069655, -12.196753875627223],
              [-56.24232854169183, -12.231277426096426],
              [-56.21189150430057, -12.241650701837955],
              [-56.19315359209635, -12.239369985981796],
              [-56.19900147830924, -12.226718844231186],
              [-56.20601662835942, -12.202572592817035],
              [-56.19897063290553, -12.166942966403724],
              [-56.191932516440616, -12.12901435158534],
              [-56.19074647714018, -12.097980522687408],
              [-56.16850258247247, -12.065806754956611],
              [-56.13105056266909, -12.048584961400138],
              [-56.088924626012655, -12.042852242537037],
              [-56.05615959883127, -12.05895840477973],
              [-56.030410559890385, -12.093449936378818],
              [-56.020835576608036, -12.09871947127209],
              [-55.94427460237999, -12.101343342244547],
              [-55.927741633627164, -12.105764963811794],
              [-55.90267555215766, -12.134272151814116],
              [-55.89627060465921, -12.177948070007233],
              [-55.91225455875353, -12.220723101246165],
              [-55.92821856391829, -12.265799132546988],
              [-55.89474861867919, -12.306875319648725],
              [-55.86421150136391, -12.298478831953958],
              [-55.84296455026083, -12.301694800567304],
              [-55.793106475555916, -12.33221666281878],
              [-55.750644592219885, -12.335185532926289],
              [-55.68486760698934, -12.318298512582999],
              [-55.63433847711558, -12.307335318495404],
              [-55.62460356712049, -12.33139758323972],
              [-55.620582600525424, -12.36934698517797],
              [-55.633132488885565, -12.401740023496131],
              [-55.62566354259704, -12.432738816038693],
              [-55.60290147867455, -12.461251871373292],
              [-55.57559558503641, -12.475894050406055],
              [-55.54149649414438, -12.468568434661961],
              [-55.50966655208212, -12.468177502697472],
              [-55.48361257950344, -12.477066678661402],
              [-55.47581858300947, -12.531090223972285],
              [-55.479080484452254, -12.551857897851335],
              [-55.467674558238556, -12.611593542543517],
              [-55.450500541530275, -12.660895226515152],
              [-55.410995625999234, -12.703008590316813],
              [-55.36442962758858, -12.742721879960982],
              [-55.296153494104914, -12.81439184604352],
              [-55.26001760073936, -12.865739384944106],
              [-55.22850751210257, -12.922904635206123],
              [-55.20196956843408, -12.961710164735507],
              [-55.16828957270121, -13.00387130538536],
              [-55.13590961015207, -13.036830960358657],
              [-55.10219156057876, -13.08013723662134],
              [-55.07319252249761, -13.12350134801602],
              [-55.04767650046523, -13.170368927303173],
              [-55.02124048073945, -13.198794978482738],
              [-54.95828652050102, -13.217488801876186],
              [-54.910781581295765, -13.230634140638472],
              [-54.85954652753591, -13.255233350100696],
              [-54.82238351575428, -13.286938401805514],
              [-54.764812581378195, -13.33676496055449],
              [-54.70429959898479, -13.347376282346431],
              [-54.63105349982766, -13.340500774803274],
              [-54.569831576063024, -13.322270973570028],
              [-54.51798263171402, -13.309935829569383],
              [-54.48804448520022, -13.331345221956212],
              [-54.415512524194284, -13.35206377788262],
              [-54.37715559412324, -13.380232839910548],
              [-54.29131652362359, -13.41567068837901],
              [-54.20249148393394, -13.418775345318068],
              [-54.14356251360411, -13.40509239128562],
              [-54.079391501023466, -13.418947341971375],
              [-54.04552458884962, -13.457515831278783],
              [-54.00053455587546, -13.457865859555739],
              [-53.940311587332204, -13.449871200299526],
              [-53.90458657486069, -13.460740684709549],
              [-53.872547588133045, -13.464760477838126],
              [-53.85417562982181, -13.435623305992522],
              [-53.83326361956992, -13.41565358929654],
              [-53.7942384841771, -13.413775707707316],
              [-53.76403060801886, -13.385563730335093],
              [-53.731231550310554, -13.368815849584621],
              [-53.68284651127105, -13.362136310937899],
              [-53.654121561424006, -13.320114142242915],
              [-53.60466347113899, -13.308789688128115],
              [-53.58772247133919, -13.32688940222397],
              [-53.587928498519446, -13.374121426661588],
              [-53.599891486018706, -13.425041991413991],
              [-53.60627749041595, -13.459723624577293],
              [-53.60309957564306, -13.498824029460707],
              [-53.61396453382531, -13.546265769115905],
              [-53.61552055033225, -13.585461392419518],
              [-53.60673162193042, -13.609480741819482],
              [-53.58257263002341, -13.632047172121702],
              [-53.5461735448979, -13.616357422829083],
              [-53.513793582348754, -13.578856620349143],
              [-53.50022160471444, -13.548637847716805],
              [-53.51510652366338, -13.516681003640315],
              [-53.50851449208588, -13.492360408639968],
              [-53.469970477935775, -13.467402118445364],
              [-53.472701469630934, -13.45017848087025],
              [-53.465228500028886, -13.412016010953039],
              [-53.4383085092133, -13.397651943467906],
              [-53.4004514758481, -13.396885837516948],
              [-53.37935657246875, -13.387245643027825],
              [-53.34658048117517, -13.37044881196283],
              [-53.32966948858878, -13.386227744705252],
              [-53.332416573538055, -13.423147010742355],
              [-53.33506357856332, -13.464668276903126],
              [-53.311267523064146, -13.469937979434462],
              [-53.26715054912556, -13.483999963128838],
              [-53.21608346870549, -13.490994493697201],
              [-53.179115587629965, -13.449900201684557],
              [-53.12083856172973, -13.409498087236784],
              [-53.08505655231656, -13.369566868109871],
              [-53.06660463064895, -13.3484370960133],
              [-53.0204086094448, -13.299056454513675],
              [-52.97168360041219, -13.308360702323228],
              [-52.95074460043206, -13.292926768715745],
              [-52.90360662094817, -13.284971504404552],
              [-52.866271612513216, -13.31293554508062],
              [-52.83516653743824, -13.32721462006748],
              [-52.78065449480985, -13.330588336098742],
              [-52.7134095030558, -13.32214390391772],
              [-52.66390950325487, -13.314090571339307],
              [-52.61224747534685, -13.297915509852373],
              [-52.54654358031206, -13.275644122542246],
              [-52.467559565511806, -13.264552685335957],
              [-52.41961256473266, -13.242673235818643],
              [-52.37805559166431, -13.202512017268361],
              [-52.32921256543489, -13.170231128814862],
              [-52.284259580472806, -13.12536799785488],
              [-52.25006862392189, -13.07615834718024],
              [-52.23125058072324, -13.027327558529464],
              [-52.186908468865155, -13.005497226964849],
              [-52.153236519759346, -12.982779587129187],
              [-52.122146532110094, -12.952058738496135],
              [-52.07658753554787, -12.932486994563249],
              [-52.01304650681048, -12.92281260190913],
              [-51.9248735791885, -12.906729908995317],
              [-51.85432846451312, -12.89453356931142],
              [-51.797443505093554, -12.858500102802793],
              [-51.77252947134781, -12.81984947084419],
              [-51.74440751561576, -12.769598117241912],
              [-51.723941590251286, -12.741422852605638],
              [-51.66031657484399, -12.694830199742853],
              [-51.616504534543225, -12.657964577886162],
              [-51.56447957022738, -12.619723150440961],
              [-51.53005962008149, -12.585404621323562],
              [-51.47839356885993, -12.53564696181877],
              [-51.44503761750414, -12.50595490798247],
              [-51.395389593293004, -12.468903207875087],
              [-51.32104160914338, -12.430009836000295],
              [-51.26340848104567, -12.42494833994408],
              [-51.20708862953873, -12.415306133798197],
              [-51.14665561050171, -12.42510139349622],
              [-51.09720657267212, -12.42253954860729],
              [-51.06581148374701, -12.407827632140027],
              [-51.038055481907946, -12.389765804246565],
              [-51.03373360498858, -12.337854163261795],
              [-51.039310588090615, -12.309244213126362],
              [-51.050914494857494, -12.27620241550187],
              [-51.093448630198964, -12.272817802996485],
              [-51.12194860976456, -12.265573156437142],
              [-51.136592465177955, -12.248725363400752],
              [-51.129310602968474, -12.216294438880254],
              [-51.113513565315145, -12.19282628343359],
              [-51.12250550341207, -12.16776087251634],
              [-51.146285465657144, -12.160378259830622],
              [-51.16007252292712, -12.172277377227601],
              [-51.16663354146286, -12.197888952956305],
              [-51.1666415880899, -12.180486110291099],
              [-51.16197152691234, -12.163244200167014],
              [-51.153785592625496, -12.154041708662135],
              [-51.140922556362426, -12.147135188077186],
              [-51.12922661629858, -12.147126303259824],
              [-51.11752346779801, -12.150564895221748],
              [-51.10816155249458, -12.155156837061668],
              [-51.09996757158069, -12.158597440680296],
              [-51.06026450785842, -12.079239928274205],
              [-51.04040559992245, -12.056218528267209],
              [-51.018196573972006, -12.040101469550336],
              [-50.99716956400843, -12.023987763594732],
              [-50.9878316209481, -12.009034783591915],
              [-50.985504469348314, -11.994082809417534],
              [-50.98902855672267, -11.97684576079729],
              [-50.997230584263605, -11.959609215091291],
              [-51.00542456517749, -11.952726499111407],
              [-51.01827955481352, -11.952739574880354],
              [-51.031135550277924, -11.959641904513717],
              [-51.04866060109151, -11.96771837114494],
              [-51.067367500253965, -11.979230747529073],
              [-51.07554656138018, -11.98268660621153],
              [-51.08606752625579, -11.983841297194033],
              [-51.09191859759187, -11.976950031672914],
              [-51.090751501392646, -11.971202225384047],
              [-51.09075954801969, -11.957408294953439],
              [-51.083763508708785, -11.932103999795459],
              [-51.075591488381235, -11.919449840559707],
              [-51.06624549869386, -11.909096513747727],
              [-51.04755351931908, -11.889546730401207],
              [-51.0370484800595, -11.875742909324856],
              [-51.033561608335276, -11.85388944370743],
              [-51.02305556324734, -11.830892015943505],
              [-51.01956952971341, -11.813647926524652],
              [-51.0032275007151, -11.796381373605186],
              [-50.98338351256683, -11.777967338140002],
              [-50.96587354917895, -11.759562858044376],
              [-50.96705657099423, -11.744621947982182],
              [-50.97406753009284, -11.73543353807463],
              [-50.979911560630256, -11.730843775529593],
              [-50.9916075006941, -11.725091275374893],
              [-51.035987498754565, -11.730888702530649],
              [-51.06985457856649, -11.75161329342734],
              [-51.092040470464156, -11.770016935332592],
              [-51.110725576678334, -11.77807160901557],
              [-51.13293460262878, -11.771185037360226],
              [-51.15630351634175, -11.726364151192342],
              [-51.16915850597778, -11.684967775388941],
              [-51.184341485403905, -11.666573185939114],
              [-51.206535591566706, -11.665430229621052],
              [-51.239234569351, -11.66544330539],
              [-51.269599522374904, -11.672336582567937],
              [-51.28127249602403, -11.67003675597249],
              [-51.29425857862583, -11.670034744315728],
              [-51.285819510862865, -11.65321796432113],
              [-51.28477847848751, -11.650174159999153],
              [-51.27308656173719, -11.63209858578449],
              [-51.26608247579921, -11.596447836975187],
              [-51.259063470073556, -11.564259149456689],
              [-51.24388149647581, -11.527457565340285],
              [-51.23220047619964, -11.499868531012623],
              [-51.219352527362275, -11.479168079997066],
              [-51.210014584301945, -11.45617366971834],
              [-51.20651262515199, -11.448128048490844],
              [-51.229331518378075, -11.442927580479818],
              [-51.23982951683897, -11.4337271006317],
              [-51.27249949323823, -11.410735707838114],
              [-51.3004834828439, -11.386583253815559],
              [-51.33197747822658, -11.385409452093768],
              [-51.36000052750106, -11.403804209181658],
              [-51.39852157523646, -11.418733049303285],
              [-51.42885551521857, -11.421020135405854],
              [-51.462684541190015, -11.408336807147066],
              [-51.483673497313134, -11.396819569259094],
              [-51.5081325613541, -11.371493481152811],
              [-51.52911347085015, -11.348470069489053],
              [-51.537223465094144, -11.313947524848231],
              [-51.54532658617751, -11.27138539395014],
              [-51.546432494483525, -11.236874919249885],
              [-51.56036355285019, -11.203506059763185],
              [-51.59295658337825, -11.168955519565714],
              [-51.62439760513283, -11.148202933112373],
              [-51.64070158029068, -11.141277301788136],
              [-51.66748058749471, -11.12742419512108],
              [-51.70007361802277, -11.105521779189075],
              [-51.719863626714556, -11.096278383996662],
              [-51.750160518684595, -11.089311848984892],
              [-51.781585614823115, -11.073148186886328],
              [-51.79319756821707, -11.055857326447665],
              [-51.80477951439761, -11.029376212082752],
              [-51.80706760632856, -11.014417029471645],
              [-51.80702251168944, -10.9971643905115],
              [-51.80348953949772, -10.984510398913812],
              [-51.79647858039911, -10.973026185724393],
              [-51.78944348141934, -10.956931255231893],
              [-51.78705547720244, -10.935080639461546],
              [-51.78701055020139, -10.917818780407913],
              [-51.791618585295396, -10.899404242028481],
              [-51.79389158980064, -10.877543735612392],
              [-51.78802459284847, -10.860297802174784],
              [-51.784168582108066, -10.855135388004214],
              [-51.771690610477236, -10.848610579290266],
              [-51.734378568457004, -10.840755059626929],
              [-51.695682506583154, -10.840921188948016],
              [-51.66679360670983, -10.868968210465596],
              [-51.64200261930279, -10.875237874046661],
              [-51.61884358844554, -10.864283564776485],
              [-51.610885474287244, -10.898616175491213],
              [-51.593582543907985, -10.931557055001349],
              [-51.572235512880894, -10.94021522571353],
              [-51.54302558611565, -10.936001475345279],
              [-51.510772525580705, -10.914434000264464],
              [-51.46788048297333, -10.89603941081458],
              [-51.43053860137775, -10.889299857741094],
              [-51.39180347983506, -10.890577762700389],
              [-51.36681350604647, -10.903725783671689],
              [-51.33940853831271, -10.919112778621354],
              [-51.27975051004401, -10.955497279235317],
              [-51.236316493576794, -10.956627159784375],
              [-51.219612533999026, -10.925097289856126],
              [-51.21675447265159, -10.902368082994087],
              [-51.20454455428455, -10.855319119322019],
              [-51.205688516430996, -10.826573214716973],
              [-51.216148628689524, -10.807009685049195],
              [-51.23941763011652, -10.79091073124323],
              [-51.26735652508307, -10.775942663814703],
              [-51.283622614038535, -10.75178249844123],
              [-51.287029522406414, -10.738261482778285],
              [-51.23646150050186, -10.74253390646885],
              [-51.19654050729682, -10.744906990898073],
              [-51.16345562668997, -10.754370159591986],
              [-51.13690158976735, -10.781268525097346],
              [-51.1080054814573, -10.808105367433313],
              [-51.073242543833146, -10.834772560048918],
              [-51.03538551046796, -10.84638686037573],
              [-50.988651538717505, -10.840487341639232],
              [-50.97721862277555, -10.820768579124547],
              [-50.96928448086035, -10.816229778550792],
              [-50.96463051293688, -10.80818365440905],
              [-50.959976545013404, -10.792076486337919],
              [-50.95880861062386, -10.782882376736268],
              [-50.966628591017695, -10.760985828136427],
              [-50.978279604080484, -10.718445657824702],
              [-50.991073573461335, -10.686248923679159],
              [-51.002708493270006, -10.677052634782626],
              [-51.02483353255056, -10.681658322943747],
              [-51.08072657754741, -10.694291527421512],
              [-51.116813520598384, -10.688529807173381],
              [-51.129600616818635, -10.663219477045118],
              [-51.12980647636081, -10.660289666606445],
              [-51.09829353787694, -10.654762136733211],
              [-51.0824886212346, -10.633611577516717],
              [-51.059658496258265, -10.61227309603089],
              [-51.03429452194962, -10.597765027449043],
              [-51.018398577838724, -10.580062280287962],
              [-51.02101859313575, -10.572564835528624],
              [-51.015800523128036, -10.563296462264702],
              [-51.02045449105151, -10.539154234164073],
              [-51.02394052458541, -10.526499236738005],
              [-51.04138158872911, -10.519602439160735],
              [-51.06348449978529, -10.518446574711788],
              [-51.09954059743254, -10.519591710324676],
              [-51.11699658136391, -10.517284675292501],
              [-51.13909949242006, -10.511522284492116],
              [-51.15769155950889, -10.498858066972502],
              [-51.17163854349164, -10.486204913565132],
              [-51.19836457706754, -10.452840412668081],
              [-51.213470610622466, -10.440179212633666],
              [-51.228584523166376, -10.437866477907335],
              [-51.249511620843975, -10.434406595911355],
              [-51.273933469234834, -10.422877288082816],
              [-51.298324472221964, -10.403305711787993],
              [-51.32619446794433, -10.382569050950679],
              [-51.34825848696971, -10.366439419379049],
              [-51.356330595011315, -10.32848599412722],
              [-51.35979450032082, -10.306620458569228],
              [-51.35977958053314, -10.296273166727588],
              [-51.36004662796856, -10.281843385121476],
              [-51.33900050726575, -10.272202855356227],
              [-51.3157806237914, -10.22672298396003],
              [-51.28524048899098, -10.233971653832953],
              [-51.2629585404828, -10.237987423648008],
              [-51.24159256635451, -10.209810314993035],
              [-51.2484015215864, -10.17547485443123],
              [-51.23989456040766, -10.146487886290686],
              [-51.20149655901099, -10.143170999562983],
              [-51.16960157338005, -10.157278916086852],
              [-51.121917596722795, -10.19281852085993],
              [-51.08317962533303, -10.241241949016],
              [-51.037074463959385, -10.261853551857826],
              [-50.96612148587511, -10.254171034676176],
              [-50.94346251936176, -10.270803077524647],
              [-50.87806355796479, -10.272453977175587],
              [-50.858348483487475, -10.307563590315397],
              [-50.8247685676786, -10.337683288852134],
              [-50.79629859532639, -10.35299233210219],
              [-50.77229349516182, -10.370991128083688],
              [-50.76687660641062, -10.378124630606806],
              [-50.75292258162921, -10.383863216802183],
              [-50.74246246937071, -10.378112560666239],
              [-50.736648613684736, -10.37351190164702],
              [-50.734329508711994, -10.368905040019456],
              [-50.729667494161475, -10.363167459652459],
              [-50.72851548538796, -10.360858580601587],
              [-50.71922246932871, -10.344762979556833],
              [-50.71595352708724, -10.335069476163483],
              [-50.69793360870969, -10.324930223158844],
              [-50.6900294740079, -10.32326038040668],
              [-50.68202257717306, -10.324049285134265],
              [-50.6738815698873, -10.324029336204717],
              [-50.669235480952835, -10.32172950960927],
              [-50.664302563291415, -10.317822704535558],
              [-50.62255448283048, -10.308996225207466],
              [-50.59647754383707, -10.322051542329575],
              [-50.506824539750994, -10.352828382075927],
              [-50.465183580012734, -10.3780684718555],
              [-50.39968856200528, -10.381913083207564],
              [-50.39608350544617, -10.37588280678142],
              [-50.39362358449995, -10.376540618543231],
              [-50.375030511582736, -10.371914813814499],
              [-50.363403470763046, -10.363843543963185],
              [-50.34716051586037, -10.339691089940686],
              [-50.348365498261984, -10.319006732179218],
              [-50.36934657539612, -10.281127235313477],
              [-50.39262362345019, -10.255887983724222],
              [-50.39260048939741, -10.269676214460674],
              [-50.39698456003836, -10.283603081875754],
              [-50.42108554917536, -10.267882151903336],
              [-50.42201258766741, -10.240487074815348],
              [-50.44728855199264, -10.227240147386453],
              [-50.46261955582898, -10.217789886823482],
              [-50.471870494734276, -10.200054953154165],
              [-50.47497951026301, -10.174847720435025],
              [-50.448322543569304, -10.131539767791708],
              [-50.437980616145694, -10.088703716297573],
              [-50.40649047643848, -10.051007447835445],
              [-50.384181538202085, -10.018172683219575],
              [-50.344551564675555, -9.980239374535415],
              [-50.311138616378116, -9.929841002351282],
              [-50.29848462478043, -9.886938063269781],
              [-50.29098550364043, -9.828095258904682],
              [-50.27441448104713, -9.79887728551239],
              [-50.24525048711135, -9.762395890097594],
              [-50.235240483053786, -9.747125738878367],
              [-50.226028603817326, -9.747763769348637],
              [-50.20774046453792, -9.699888182384655],
              [-50.21132657799586, -9.696811353364126],
              [-50.20853054273198, -9.669373360931843],
              [-50.24638355278367, -9.612995171378714],
              [-50.2855225144217, -9.589979806342058],
              [-50.32811347906676, -9.568212506689349],
              [-50.33833353361808, -9.538615671273135],
              [-50.31980550426957, -9.481142637526261],
              [-50.31909153375642, -9.480167654547984],
              [-50.30866947533838, -9.45602525880929],
              [-50.3005755743485, -9.429592424206703],
              [-50.297111501400934, -9.412353363929697],
              [-50.296283537004484, -9.409206797474724],
              [-50.28938254847566, -9.387775276863522],
              [-50.276515488899065, -9.351771649930242],
              [-50.23059858507091, -9.292984836678386],
              [-50.179904499342484, -9.276593186813216],
              [-50.11832852398737, -9.273669243706763],
              [-50.052982536218536, -9.279822901747764],
              [-50.01853559634438, -9.265028004439046],
              [-49.974883482756354, -9.2097438208894],
              [-49.95090855744323, -9.195253019028144],
              [-49.94624836085079, -9.183814146487842],
              [-49.92517965901159, -9.17821487761382],
              [-49.91087041934451, -9.161605938714528],
              [-49.9001384895942, -9.120977918945494],
              [-49.88838447129624, -9.090315262516032],
              [-49.88605458739124, -9.08094414381435],
              [-49.87101762071856, -9.06254335175612],
              [-49.859451600154046, -9.051040027827469],
              [-49.846725524189, -9.040674798712985],
              [-49.83054358954146, -9.023413945487732],
              [-49.8236085704857, -9.011923026775719],
              [-49.816680592228636, -8.999280937480592],
              [-49.807449602252916, -8.976294573828909],
              [-49.801704478173065, -8.948719453460512],
              [-49.79478454654304, -8.929188780853224],
              [-49.78554550994028, -8.91309183870402],
              [-49.768211566519255, -8.894693393578677],
              [-49.73696852531788, -8.877411417957433],
              [-49.65946961612363, -8.846259571862674],
              [-49.58890555834705, -8.817402858497587],
              [-49.56118056954978, -8.79552424717059],
              [-49.57858257402464, -8.768013332180601],
              [-49.58786754345684, -8.756541188931749],
              [-49.59133949539341, -8.754265166941366],
              [-49.59628649465216, -8.752174552649421],
              [-49.57287550378521, -8.721114739851657],
              [-49.55427153439388, -8.684957388813928],
              [-49.51510658885596, -8.637852769304686],
              [-49.445236552662976, -8.570307537778604],
              [-49.414932619894245, -8.537247802881325],
              [-49.40308362627829, -8.508184894697933],
              [-49.39043851949796, -8.467615645100693],
              [-49.38678332928917, -8.460319343283633],
              [-49.36532549937064, -8.417486332008764],
              [-49.328800518059325, -8.398026737940427],
              [-49.304847553332536, -8.384678557121106],
              [-49.28564461313971, -8.368026900619213],
              [-49.2692375405727, -8.336536257997807],
              [-49.254028577246686, -8.303930486976924],
              [-49.21092950163069, -8.262648607971016],
              [-49.20142358523057, -8.24604875163078],
              [-49.19452662001527, -8.23111873804271],
              [-49.18762261400127, -8.219622454912724],
              [-49.1876294871619, -8.213887892030868],
              [-49.18188453072008, -8.20125586101949],
              [-49.18305950590832, -8.186334061696584],
              [-49.18657655248401, -8.161099839249232],
              [-49.18778254071401, -8.14045839683206],
              [-49.18319646620634, -8.119786611925406],
              [-49.15441854273115, -8.066935359593742],
              [-49.14521755996884, -8.043973303461314],
              [-49.14409656423712, -8.026751509904898],
              [-49.14067859175705, -7.999214275738893],
              [-49.14768251005697, -7.969390626022573],
              [-49.1453935799357, -7.946442316211346],
              [-49.13735147910751, -7.925765669800853],
              [-49.132797591108044, -7.895932967629108],
              [-49.130508493348685, -7.879859997722974],
              [-49.136314470045676, -7.858075431349675],
              [-49.137496486032575, -7.843162684482195],
              [-49.12600757897735, -7.816747619514388],
              [-49.12371848121799, -7.799537895898538],
              [-49.12145251751144, -7.769698991118446],
              [-49.12610648543492, -7.755942779252109],
              [-49.15843950932623, -7.746818574722965],
              [-49.17114261887656, -7.735362357090196],
              [-49.17808551692133, -7.723904295438672],
              [-49.19656359012686, -7.714752765905132],
              [-49.215049542321395, -7.706750395298002],
              [-49.22891253963422, -7.699898859997973],
              [-49.24277453111867, -7.686149018378103],
              [-49.267051540222525, -7.65518827967594],
              [-49.301704507276895, -7.627701336929078],
              [-49.325958549966, -7.604768785095814],
              [-49.3294294960742, -7.593309717615966],
              [-49.33404557779525, -7.586427001636025],
              [-49.33520546555775, -7.581829360101949],
              [-49.32336451856884, -7.577677635817224],
              [-49.31182062622875, -7.574218927287689],
              [-49.28182246528817, -7.583366265869643],
              [-49.24717754486085, -7.599406211077223],
              [-49.22059651820999, -7.625759417599568],
              [-49.188232481276884, -7.664720347614036],
              [-49.16394859901246, -7.689937638616925],
              [-49.124679550237005, -7.709381139431116],
              [-49.120056595355294, -7.710518228416959],
              [-49.1108324785402, -7.702474786484231],
              [-49.09584848549565, -7.688671635960134],
              [-49.07853650266097, -7.684057230619715],
              [-49.05773949212073, -7.698940976102222],
              [-49.042686599832024, -7.718414484129767],
              [-49.040344528444535, -7.739053076699804],
              [-49.04376954172329, -7.76430423059162],
              [-49.07137249001025, -7.828601642272247],
              [-49.09088857810599, -7.8894423570801],
              [-49.10579646346966, -7.943406390878408],
              [-49.09764053639623, -7.975508074241816],
              [-49.08566680437368, -7.996559566338362],
              [-49.07966739184303, -8.007107370918838],
            ],
          ],
        ],
      },
    },
  ],
};
